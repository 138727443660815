import { useMemo } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux"
import { checkUsersCategory } from "../services/util";


/**
 * Helpers hook that help to check user category, accept string or array of string
 * @param {string | Array<string>} acceptCategoryType - initial value accept category type 
 */
const useCheckUserCategory = (acceptCategoryTypeInitialValue) => {
  const { user_category } = useSelector((state) => state.auth);

  /**
   * function hook that help to check user category, accept string or array of string
   * @param {string | Array<string>} acceptCategoryType 
   */
  const hasUserCategory = useCallback((accept) => {
    return checkUsersCategory(user_category, accept)
  }, [user_category]); 

  const isAccepted = useMemo(() => {
    return checkUsersCategory(user_category, acceptCategoryTypeInitialValue);
  }, [user_category, acceptCategoryTypeInitialValue]) 

  return {
    userCategory: user_category,
    hasUserCategory,
    isAccepted
  }
}

export default useCheckUserCategory;