import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement, Select as SelectChakra, Stack,
  Tag
} from "@chakra-ui/react";
import { Pagination, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ButtonCustom from "../../../../../../components/button/ButtonCustom";
import {
  DetailIcon, SearchIcon,
  StatusActive,
  StatusNonActive
} from "../../../../../../components/icons";
import ModalContactPerson from "../../../../../../components/ModalContactPerson";
import {
  GRAY_COLOR,
  GREEN_COLOR, LABEL_STATUS_ACTIVE, LABEL_STATUS_INACTIVE, RED_COLOR,
  STATUS_ACTIVE
} from "../../../../../../constant/propertiesConstant";
import {
  getInitiateListContactPerson, getListContactPerson
} from "../../../../../../services/main/distributorReducer";
import StringUtil from "../../../../../../utils/stringUtil";
const BodyContactPerson = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { contact_person_loading } = useSelector((state) => state.distributor);
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isClose, setIsClose] = useState(true);

  const [selectedData, setSelectedData] = useState({});

  //pagination
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  // buat modal
  const openModal = (data) => {
    setIsOpen(true);
    setSelectedData(data);
  };
  const closeModal = () => {
    setIsOpen(false);
    // setSelectedData(null);
  };

  // search
  const [filterFirstName, setFilterFirstName] = useState("");
  const handleSearch = (event) => {
    if (event.target.value.length > 3) {
      setFilterFirstName(event.target.value);
    } else {
      setFilterFirstName("");
    }
  };

  const fetchDataContactPerson = () => {
    const filter = StringUtil.formatFilter({
      company_profile_id: `eq ${state?.company_profile_id}`,
      first_name: `like ${filterFirstName}`,
    });
    const params = { page, limit, filter, order };
    dispatch(getListContactPerson(params)).then((response) => {
      setData(response.payload.response);
    });
  };

  useEffect(() => {
    const page = 1;
    const limit = 10;
    const filter = StringUtil.formatFilter({
      company_profile_id: `eq ${state?.company_profile_id}`,
    });
    const params = { page, limit, filter };
    dispatch(getInitiateListContactPerson(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  }, []);

  useEffect(() => {
    fetchDataContactPerson();
  }, [page, limit, order, filterFirstName, dispatch]);

  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      first_name: item.first_name,
      last_name: item.last_name,
      phone: item.phone,
      email: item.email,
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            text={<DetailIcon />}
            onClick={() => openModal(item)}
            backgroundcolor="transparent"
          />
        </div>
      ),
    }));

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_FIRST_NAME")}
        </Box>
      ),
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_LAST_NAME")}
        </Box>
      ),
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_PHONE")}
        </Box>
      ),
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_EMAIL")}
        </Box>
      ),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_STATUS")}
        </Box>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? LABEL_STATUS_ACTIVE
                  : LABEL_STATUS_INACTIVE}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <Box padding={4}>
      <Box minHeight="96px">
        <Stack direction="row" alignItems="center" marginBottom={5}>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            textColor={GRAY_COLOR}
            borderRadius={5}
          >
            <Input
              type="text"
              placeholder={
                t("COMMON:SEARCH_BY") + " " + t("DISTRIBUTOR:FIELD_FIRST_NAME")
              }
              fontSize="14px"
              id="SCH-UserSeller-ProductPrincipal-Search"
              onChange={(event) => handleSearch(event)}
            />
            <InputRightElement
              pointerEvents="none"
              children={<Icon as={SearchIcon} color="gray.300" />}
            />
          </InputGroup>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          size="small"
          loading={contact_person_loading}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>Row Per Page</Box>
                <SelectChakra
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </SelectChakra>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  // showQuickJumper
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalContactPerson
        data={selectedData}
        isOpen={isOpen}
        onClose={() => closeModal()}
      />
    </Box>
  );
};
export default BodyContactPerson;
