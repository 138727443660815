import {
  Box, Text
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import VirtualList from "react-tiny-virtual-list";
const BodyDataScope2 = ({ detail }) => {
  const { t } = useTranslation();

  const {
    scope,
    principal_scope,
    distributor_scope,
    customer_scope,
    store_scope,
    warehouse_scope,
  } = detail;

  const scopes = JSON.parse(scope);
  let status_scope = scopes["farmindo.po_status"];
  const isHaveWarehouse = scopes["farmindo.warehouse"];

  let principalName = t("COMMON:ALL");
  let principalBranch = t("COMMON:ALL");
  let customerScope = t("COMMON:ALL");
  let storeScope = [t("COMMON:ALL")];
  let warehouseScope = t("COMMON:ALL");
  let poStatusScope = t("COMMON:ALL");

  if (principal_scope) {
    principalName = principal_scope[0]?.principal_name;
  }

  if (distributor_scope) {
    principalBranch = distributor_scope.reduce((prev, current, index, arr) => {
      if (index === arr.length - 1) {
        prev += current.distributor_name;
      } else {
        prev += `${current.distributor_name}, `;
      }
      return prev;
    }, "");
  }

  if (customer_scope) {
    customerScope = customer_scope.reduce((prev, current, index, arr) => {
      if (index === arr.length - 1) {
        prev += current.customer_name;
      } else {
        prev += `${current.customer_name}, `;
      }
      return prev;
    }, "");
  }

  if (store_scope) {
    storeScope = store_scope;
  }

  if (warehouse_scope) {
    warehouseScope = warehouse_scope.reduce((prev, current, index, arr) => {
      if (index === arr.length - 1) {
        prev += current.warehouse_name;
      } else {
        prev += `${current.warehouse_name}, `;
      }
      return prev;
    }, "");
  }

  if (!isHaveWarehouse) {
    warehouseScope = "";
  }

  if (status_scope) {
    poStatusScope = status_scope.reduce((prev, curr, index, arr) => {
      if (index === arr.length - 1) {
        prev += curr;
      } else {
        prev += `${curr}, `;
      }
      return prev;
    }, "");

    if (poStatusScope.includes("all")) {
      poStatusScope = t("COMMON:ALL");
    }
  }

  return (
    <Box padding={1}>
      {/* Principal Name */}
      <Box padding={1} marginBottom={2}>
        <Box>Principal Name</Box>
        <Box>
          <Text>
            <b>{principalName}</b>
          </Text>
        </Box>
      </Box>
      {/* PO Status */}
      <Box padding={1} marginBottom={2}>
        <Box>PO Status</Box>
        <Box>
          <Text>
            <b>{poStatusScope}</b>
          </Text>
        </Box>
      </Box>
      {/* Principal Branch */}
      <Box padding={1} marginBottom={2}>
        <Box>Principal Branch</Box>
        <Box>
          <Text>
            <b>{principalBranch}</b>
          </Text>
        </Box>
      </Box>
      {/* Customer */}
      <Box padding={1} marginBottom={2}>
        <Box>Customer</Box>
        <Box>
          <Text>
            <b>{customerScope}</b>
          </Text>
        </Box>
      </Box>
      {/* Store */}
      <Box padding={1} marginBottom={2}>
        <Box>Store</Box>
        <Box>
          <VirtualList
            width="50%"
            style={{ overflowY: "auto" }}
            height={storeScope?.length > 10 ? 250 : storeScope?.length * 24}
            itemCount={storeScope?.length ?? 0}
            itemSize={24}
            renderItem={({ index, style }) => {
              return (
                <Text
                  key={index}
                  fontSize="md"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  style={style}
                >
                  {store_scope?.length > 0
                    ? store_scope[index]?.store_name
                      ? store_scope[index]?.store_name
                      : store_scope[index]
                    : t("COMMON:ALL")}
                </Text>
              );
            }}
          />
        </Box>
      </Box>
      {/* Warehouse */}
      <Box padding={1} marginBottom={2}>
        <Box>Warehouse</Box>
        <Box>
          <Text>
            <b>{warehouseScope}</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BodyDataScope2;
