import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list_country: [],
  list_island: [],
  list_province: [],
  list_district: [],
  list_sub_district: [],
  list_urban_village: [],
  list_postal_code:[],
  table_loading: false,
  status: "",
};
export const getListCountry = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/country",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/country`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  export const getListIsland = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/island",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/island`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  export const getListProvince = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/province",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/province`;
      const { auth } = getState();
      const token = auth.token;
      
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  export const getListDistrict = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/district",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/district`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  export const getListSubDistrict = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/subdistrict",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/sub-district`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  export const getListUrbanVillage = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/urbanvillage",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/urban-village`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  export const getListPostalCode = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/postalcode",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/postal-code`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  const regional = createSlice({
    name: "regional",
    initialState: INITIAL_STATE,
    reducers: {
      setReset(state, action) {
        state.status = null;
        state.errorNotification = null;
        state.successNotification = null;
      },
    },
    extraReducers: {
      [getListCountry.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListCountry.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_country = action.payload.response;
      },
      [getListCountry.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      [getListIsland.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListIsland.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_island = action.payload.response;
      },
      [getListIsland.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      [getListProvince.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListProvince.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_province = action.payload.response;
      },
      [getListProvince.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      [getListDistrict.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListDistrict.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_district = action.payload.response;
      },
      [getListDistrict.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      [getListSubDistrict.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListSubDistrict.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_sub_district = action.payload.response;
      },
      [getListSubDistrict.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      [getListUrbanVillage.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListUrbanVillage.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_urban_village = action.payload.response;
      },
      [getListUrbanVillage.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      [getListPostalCode.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListPostalCode.fulfilled]: (state, action) => {
        state.status = "success";
        state.list_postal_code = action.payload.response;
      },
      [getListPostalCode.rejected]: (state, action) => {
        state.status = "failed";
        state.errorNotification = action.payload;
        state.table_loading = false;
      },

      ['auth/resetRedux']:(state,action)=>{
        return INITIAL_STATE
      },
    },
  });
  
  export const regionalAction = regional.actions;
  
  export default regional.reducer;