import { Flex } from "@chakra-ui/react";
import { Button, message, Modal, Spin, Upload } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "../constant/propertiesConstant";
import ButtonCustom from "./button/ButtonCustom";
import ButtonPrimary from "./button/ButtonPrimary";
import style from "./ModalImport.module.css";
// import { ImportIcon, ImportIconHover } from "../../../../../components/icons";
// import * as SetupUpliftService from "../../../../../services/main/SetupUpliftService";
// import ButtonCancelUser from "../../../../../components/common/button/ButtonCancelUser";
// import ButtonSave from "../../../../../components/common/button/ButtonSave";
// import ProgressBar from "../../../../../components/common/progress_bar/ProgressBar";
const { Dragger } = Upload;
const ModalImportTxt = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        className={style.modalImport}
        title={t("COMMON:IMPORT")}
        visible={props.visible}
        // onOk={props.onOk}
        onCancel={props.onCancel}
        // onDownload={props?.onDownload}
        width="1000px"
        height="564px"
        closable={false}
        footer={
          <Flex gap={"0.5rem"} direction="row-reverse">
            <ButtonPrimary
              text={props.loading ? <Spin /> : t("COMMON:BUTTON_PROCESS")}
              width="9.375rem"
              onClick={props.onOk}
            />
            <ButtonCustom
              colorScheme="blue"
              variant="outline"
              marginRight={2}
              width="9.375rem"
              //   link={ROUTES.ROLE.LIST}
              text={t("COMMON:BUTTON_CANCEL")}
              onClick={props.onCancel}
            />
          </Flex>
        }
      >
        <div
        //   onMouseEnter={() => setState({ hover: true })}
        //   onMouseLeave={() => setState({ hover: false })}
        >
          <Dragger
            {...props}
            style={{
              // border: `3px dashed ${state.hover ? '#783F91' : '#CCCCCC'}`,
              background: "#FFFFFF",
              borderRadius: "8px",
              height: "305px",
              width: "860px",
              cursor: "default",
              margin: "auto",
            }}
            multiple={false}
            maxCount={1}
            beforeUpload={(file) => {
              const isTxt = file.type === "text/plain";
              if (!isTxt) {
                message.error(`${file.name} is not a txt file`);
              }
              return isTxt || Upload.LIST_IGNORE;
            }}
            onChange={props?.onChangeFile}
            disabledClick={false}
            customRequest={({onSuccess}) => { onSuccess()}}
          >
            <div className={style.icon}>
              {/* {state.hover ? <DownloadIcon /> : <PlusSquareIcon />} */}
            </div>
            <div className={style.text}>
              {t("COMMON:DRAG_AND_DROP_YOUR_FILE_HERE")}
            </div>
            <div className={style.text4}>{t("COMMON:FILE_SUPPORT_TXT")}</div>
            {/* <div className={style.text5}>{t("COMMON:OR")}</div> */}
           
            <Button
              style={{
                color: PRIMARY_COLOR,
                cursor: "pointer",
                border: "none",
                boxShadow: "none",
                fontWeight: 500,
                fontSize: "16px",
              }}
              //   onClick={props.handleDownload}
            >
              {/* {t("COMMON:DOWNLOAD_TEMPLATE_HERE")} */}
            </Button>
          </Dragger>
        </div>
      </Modal>
    </>
  );
};

export default ModalImportTxt;
