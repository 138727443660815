import { Box, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  inputTheme,
  multiSelectTheme,
} from "../../../../../constant/themeConstant";
import DropDownCustom from "../../../../../components/DropDownCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  addProductMapping,
  getListCSMForDistributor,
  getListCustomer,
  getListCustomerDDS,
  getListPrincipal,
  getListProductCustomer,
  getListProductPrincipal,
  getListProductPrincipalDDS,
  getListStoreOption,
  getListWarehouse,
  getListWarehouseDDS,
} from "../../../../../services/main/productMappingReducer";
import { useFormik } from "formik";
import { addProductMappingSchema } from "./productMappingSchema";
import StringUtil from "../../../../../utils/stringUtil";
import ModalCustom from "../../../../../components/ModalCustom";
import { SavedSuccess, UnSuccess } from "../../../../../components/icons";
import { Redirect } from "react-router-dom";
import { useRef } from "react";
import { useWithInstanceListAndInitiate } from "../../../../../services/serviceUtil";
import { ID_PRODUCT_MAPPING } from "../../../../../constant/idConstant";
import FieldMultiSelectSearchStore from "../../../../../components/field/FieldMultiSelectSearchStore";
import FieldMultiSelectSearchStoreProductMapping from "../../../../../components/field/FieldMultiSelectSearchStoreProductMapping";

const AddProductMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const warehouseActive = useSelector(
    (state) => state.productMapping.additional_data_warehouse_id
  );
  const qtyDpdActive = useSelector(
    (state) => state.productMapping.additional_data_qty_dpd
  );
  const loading = useSelector((state) => state.productMapping.add_loading);
  const [storeFilter, setStoreFilter] = useState({});

  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageUnlimited, setPageUnlimited] = useState(-99);
  const [limitUnlimited, setLimitUnlimited] = useState(-99);
  const [pageProductPrincipal, setPageProductPrincipal] = useState(1);
  const [limitProductPrincipal, setLimitProductPrincipal] = useState(10);
  const [pageProductCustomer, setPageProductCustomer] = useState(1);
  const [limitProductCustomer, setLimitProductCustomer] = useState(10);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  const [limitWarehouse, setLimitWarehouse] = useState(10);

  const initialFilterProductCustomer = useRef("");

  // Code(for disabled input)
  const [productCategoryCode, setproductCategoryCode] = useState("");
  const [principalCode, setPrincipalCode] = useState("");
  const [distributorCode, setDistributorCode] = useState([]);
  const [customerCode, setCustomerCode] = useState("");
  const [storeCode, setStoreCode] = useState([]);

  const [productPrincipalCode, setProductPrincipalCode] = useState("");
  const [productCustomerCode, setProductCustomerCode] = useState("");
  const [distributorId, setDistributorId] = useState([]);

  //new hooks
  const {
    limit,
    list: listProductCustomer,
    isLoading: isLoadingProductCustomer,
    setNextPage,
    setFilter,
    resetAllState,
  } = useWithInstanceListAndInitiate({
    isOption: true,
    skipFirstRun: true,
    pathInitiate: "product-customer/initiateGetList",
    pathList: "product-customer-dds",
  });

  //handle clear;
  const handleClear = (name) => {
    switch (name) {
      case "customer_id":
        setCustomerCode("");
        setFieldValue("distributor_id", []);
        setDistributorCode([]);
        setFieldValue("store_id", []);
        break;
      case "distributor_id":
        setDistributorCode([]);
        setFieldValue("store_id", []);
        break;
      case "store_id":
        setStoreCode([]);
        break;
      case "product_principal_id":
        setProductPrincipalCode("");
        break;
      case "product_customer_id":
        setProductCustomerCode("");
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "customer_id":
        if (value) {
          // setIsCustomerNew(true);
          const filter = StringUtil.formatFilter({
            product_name: `like ${filterProductCustomer ?? ""}`,
            principal_id: `eq ${values.principal_id ?? ""}`,
            customer_id: `eq ${value ?? ""}`,
          });
          setFilter(filter);
          setDataProductCustomer([]);
          setPageProductCustomer(1);
        } else {
          resetAllState();
        }
        setFieldValue("customer_id", value);
        setCustomerCode(option?.code);
        setFieldValue("distributor_id", []);
        setDistributorCode([]);
        setFieldValue("store_id", []);
        setStoreCode([]);
        setFieldValue("product_customer_id", null);
        setProductCustomerCode("");
        break;
      case "distributor_id":
        setFieldValue("distributor_id", value);
        setDistributorCode(
          option?.map((val) => {
            return val.code;
          })
        );
        setFieldValue("store_id", []);
        setStoreCode([]);
        break;
      case "store_id":
        setFieldValue("store_id", value);
        setStoreCode(
          value?.map((val) => {
            return val.code;
          })
        );
        break;
      case "product_principal_id":
        setFieldValue("product_principal_id", value);
        setProductPrincipalCode(option.code);
        break;
      case "product_customer_id":
        setFieldValue("product_customer_id", value);
        setProductCustomerCode(option.code);
        break;
      case "warehouse_id":
        setFieldValue("warehouse_id", value);
        setFieldValue("warehouse_name", option.name);
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (!values.customer_id) {
      setDataDistributor(null);
    } else {
      dispatch(getListCSMForDistributor(params)).then((response) => {
        setDataDistributor(response.payload.response);
      });
    }
  };
  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };
  // store
  const [dataStore, setDataStore] = useState([]);

  const [filterStore, setfilterStore] = useState("");

  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filterDistributorNull = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      distributor_id: `list_id ${0}`,
    });
    const filterDistributor = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      distributor_id: `list_id ${values?.distributor_id.join("_") ?? 0}`,
    });

    const filter =
      values?.distributor_id?.length > 0
        ? filterDistributor
        : filterDistributorNull;
    const params = { page, limit, filter };

    if (values?.distributor_id?.length === 0) {
      setDataStore([]);
    } else {
      dispatch(getListCSMForDistributor(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStore([...dataStore, ...resp]);
      });
    }
  };

  const fetchSearchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filterDistributorNull = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      distributor_id: `list_id ${0}`,
    });
    const filterDistributor = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      distributor_id: `list_id ${values?.distributor_id.join("_") ?? 0}`,
    });

    const filter =
      values?.distributor_id?.length > 0
        ? filterDistributor
        : filterDistributorNull;
    const params = { page, limit, filter };

    if (values?.distributor_id?.length === 0) {
      setDataStore([]);
    } else {
      dispatch(getListStoreOption(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };
  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };

  // Product Principal
  const [dataProductPrincipal, setDataProductPrincipal] = useState([]);
  const fetchDataProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const params = { page, limit };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductPrincipal([...dataProductPrincipal, ...resp]);
    });
  };
  const [filterProductPrincipal, setfilterProductPrincipal] = useState("");
  const fetchSearchProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const filter = `product_code like ${filterProductPrincipal}`;
    const params =
      filterProductPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductPrincipal(1);
      setDataProductPrincipal(response.payload.response);
    });
  };

  const handleScrollProductPrincipal = () => {
    setPageProductPrincipal(pageProductPrincipal + 1);
  };
  // Product Customer
  const [dataProductCustomer, setDataProductCustomer] = useState([]);
  const fetchDataProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${values?.principal_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (!values?.customer_id) {
      setDataProductCustomer([]);
    } else {
      dispatch(getListProductCustomer(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp && pageProductCustomer === 1) {
          setDataProductCustomer([]);
        }
        if (resp && pageProductCustomer !== 1) {
          // kalo response true dan page nya gak di 1 (ada data dan bisa discroll maka append)
          setDataProductCustomer([]);
          // setDataProductCustomer([...resp]);
          setDataProductCustomer([...dataProductCustomer, ...resp]);
        }
        if (pageProductCustomer === 1 && resp) {
          // jika filter tidak sama dengan initial filter awal maka set kaya fetch filter
          setDataProductCustomer([]);
          // setPageProductCustomer(1);
          setDataProductCustomer([...resp]);
        }
        if (
          resp &&
          pageProductCustomer === 1 &&
          filterProductCustomer !== initialFilterProductCustomer
        ) {
          fetchSearchProductCustomer();
        }
        if (
          resp &&
          pageProductCustomer !== 1 &&
          filterProductCustomer !== initialFilterProductCustomer
        ) {
          fetchSearchProductCustomer();
        }
      });
    }
  };
  const setArrEmpty = () => {};
  const [filterProductCustomer, setfilterProductCustomer] = useState("");
  const fetchSearchProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      product_code: `like ${filterProductCustomer ?? ""}`,
      principal_id: `eq ${values?.principal_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    // : null;
    // const params = { page, limit, filter };
    if (!values?.customer_id) return;
    setFilter(filter);
  };

  const handleScrollProductCustomer = () => {
    // setPageProductCustomer(pageProductCustomer + 1);
    setNextPage();
  };

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const fetchWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_name: `like ${filterWarehouse ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouseDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const [filterWarehouse, setfilterWarehouse] = useState("");
  const fetchSearchWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${filterWarehouse ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouseDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageWarehouse(1);
      setDataWarehouse(response.payload.response);
    });
  };

  const handleScrollWarehouse = () => {
    setPageWarehouse(pageWarehouse + 1);
  };

  const onSubmit = () => {
    const payload = {
      ...values,
      additional_data: {
        warehouse_id: values?.warehouse_id === null ? 0 : values?.warehouse_id,
        qty_dpd: Number(values?.qty_dpd),
      },
      store_id:
        values?.store_id[0]?.value === "_all"
          ? [-1]
          : values?.store_id?.map((val) => {
              return val?.value;
            }),
    };
    dispatch(addProductMapping(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: null,
      store_id: [],
      distributor_id: [],
      warehouse_id: null,
      warehouse_name: "",
      product_principal_id: null,
      product_customer_id: null,
      uom_1: "",
      uom_2: "",
      uom_3: "",
      uom_4: "",
      conversion_1_to_4: "",
      conversion_2_to_4: "",
      conversion_3_to_4: "",
      order_unit: "",
      barcode: "",
      qty_dpd: "",
      status: "",
      // store : null
    },
    validationSchema: addProductMappingSchema(t),
    onSubmit,
  });
  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);

  // customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor, values.customer_id]);

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, values?.distributor_id]);

  useEffect(() => {
    fetchSearchStore();
  }, [filterStore]);

  // product principal
  useEffect(() => {
    fetchDataProductPrincipal();
  }, [pageProductPrincipal]);
  useEffect(() => {
    fetchSearchProductPrincipal();
  }, [filterProductPrincipal]);

  // product customer
  // useEffect(() => {
  //   fetchDataProductCustomer();
  // }, [pageProductCustomer, values?.customer_id, filterProductCustomer]);

  useEffect(() => {
    fetchSearchProductCustomer();
  }, [filterProductCustomer]);

  // warehouse
  useEffect(() => {
    fetchWarehouse();
  }, [pageWarehouse]);

  useEffect(() => {
    fetchSearchWarehouse();
  }, [filterWarehouse]);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_MAPPING:ADD_PRODUCT_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_MAPPING:ADD_PRODUCT_MAPPING")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          marginTop={4}
        >
          <Box>
            <Box
              justifyContent="space-between"
              display="flex"
              borderRadius="10px"
            >
              {/* left side */}
              <Box padding={1}>
                {/* Principal Code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_CODE")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={principalCode ? principalCode : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.PRINCIPAL_CODE}
                    />
                  </Box>
                </Box>
                {/* Customer Code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={customerCode ? customerCode : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.CUSTOMER_CODE}
                    />
                  </Box>
                </Box>
                {/* Distributor Code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={distributorCode.length > 0 ? distributorCode : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.DISTRIBUTOR_CODE}
                    />
                  </Box>
                </Box>
                {/* Store Code*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_STORE_CODE")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={storeCode ? storeCode : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.STORE_ID}
                    />
                  </Box>
                </Box>
                {/* Customer Product Code*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={productCustomerCode ? productCustomerCode : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.CUSTOMER_PRODUCT_CODE}
                    />
                  </Box>
                </Box>
                {/* Principal Product Code*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_PRODUCT_CODE")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={productPrincipalCode ? productPrincipalCode : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.PRINCIPAL_PRODUCT_CODE}
                    />
                  </Box>
                </Box>
                {/* Warehouse*/}
                {warehouseActive ? (
                  <Box padding={1} display="flex">
                    <Box width="10rem">
                      <Text overflowWrap="break-word" width="120px">
                        {t("PRODUCT_MAPPING:FIELD_WAREHOUSE")}
                      </Text>
                    </Box>
                    <Box width="12rem">
                      <Input
                        type="text"
                        style={inputTheme}
                        disabled
                        id={ID_PRODUCT_MAPPING.ADD.WAREHOUSE_CODE}
                        value={
                          values?.warehouse_name ? values?.warehouse_name : ""
                        }
                        background={GRAY_COLOR}
                      />
                    </Box>
                  </Box>
                ) : (
                  ""
                )}

                {/* Barcode*/}
                {/* <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_BARCODE")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      onChange={handleChange}
                      value={values.barcode ? values.barcode : ""}
                      background={GRAY_COLOR}
                    />
                    {errors.barcode && touched.barcode && (
                      <ValidationError text={errors.barcode} />
                    )}
                  </Box>
                </Box> */}
                {/* Qty Dpd  */}
                {qtyDpdActive ? (
                  <Box padding={1} display="flex">
                    <Box width="10rem">
                      <Text overflowWrap="break-word" width="120px">
                        {t("PRODUCT_MAPPING:FIELD_QTY_DPD")}
                      </Text>
                    </Box>
                    <Box>
                      <Input
                        type="text"
                        // placeholder="Isi Jumlah dpd"
                        style={inputTheme}
                        name="qty_dpd"
                        onChange={handleChange}
                        value={values.qty_dpd ? values.qty_dpd : ""}
                        background={GRAY_COLOR}
                        id={ID_PRODUCT_MAPPING.ADD.QTY_DPD}
                      />
                      {errors.qty_dpd && touched.qty_dpd && (
                        <ValidationError text={errors.qty_dpd} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {/* Order Unit */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_ORDER_UNIT")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.order_unit}
                      name="order_unit"
                      onChange={handleChange}
                    />
                    {errors.order_unit && touched.order_unit && (
                      <ValidationError text={errors.order_unit} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 1*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_1")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.uom_1}
                      onChange={handleChange}
                      name="uom_1"
                      background={GRAY_COLOR}
                    />
                    {errors.uom_1 && touched.uom_1 && (
                      <ValidationError text={errors.uom_1} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 2*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_2")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      name="uom_2"
                      value={values?.uom_2}
                      style={inputTheme}
                      onChange={handleChange}
                    />
                    {errors.uom_2 && touched.uom_2 && (
                      <ValidationError text={errors.uom_2} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 3*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_3")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      onChange={handleChange}
                      name="uom_3"
                      value={values?.uom_3}
                    />
                    {errors.uom_3 && touched.uom_3 && (
                      <ValidationError text={errors.uom_3} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 4*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_4")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      name="uom_4"
                      value={values?.uom_4}
                      onChange={handleChange}
                    />
                    {errors.uom_4 && touched.uom_4 && (
                      <ValidationError text={errors.uom_4} />
                    )}
                  </Box>
                </Box>
              </Box>

              {/* end of left side  */}

              {/* right side */}
              <Box padding={1} marginRight={8}>
                {/* principal name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_NAME")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={principal_name ? principal_name : ""}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.ADD.PRINCIPAL_NAME}
                    />
                  </Box>
                </Box>
                {/* customer name  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      style={inputTheme}
                      size="large"
                      id={ID_PRODUCT_MAPPING.ADD.CUSTOMER_NAME}
                      onPopupScroll={handleScrollCustomer}
                      allowClear
                      onClear={() => handleClear("customer_id")}
                      value={values?.customer_id}
                      name="customer_id"
                      onBlur={handleBlur("customer_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("customer_id", value, opt)
                      }
                      dropdownRender={(menu) => (
                        <DropDownCustom
                          menu={menu}
                          search={(e) => setfilterCustomer(e.target.value)}
                        />
                      )}
                    >
                      {dataCustomer &&
                        dataCustomer?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.id}
                              name={data.name}
                              code={data.customer_code}
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors.customer_id && touched.customer_id && (
                      <ValidationError text={errors.customer_id} />
                    )}
                  </Box>
                </Box>
                {/* distributor name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_NAME")}</Text>
                  </Box>
                  <Box width="250px">
                    <Select
                      style={multiSelectTheme}
                      size="large"
                      showArrow
                      id={ID_PRODUCT_MAPPING.ADD.DISTRIBUTOR_NAME}
                      allowClear
                      maxTagCount={1}
                      mode="multiple"
                      onClear={() => handleClear("distributor_id")}
                      value={values?.distributor_id}
                      name="distributor_id"
                      onBlur={handleBlur("distributor_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("distributor_id", value, opt)
                      }
                    >
                      {dataDistributor &&
                        dataDistributor?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index.toString()}
                              value={data.distributor_id}
                              name={data.distributor_name}
                              code={data.distributor_code}
                              fulldata={data}
                            >
                              {data.distributor_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors.distributor_id && touched.distributor_id && (
                      <ValidationError text={errors.distributor_id} />
                    )}
                  </Box>
                </Box>
                {/* store name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_STORE_NAME")}
                    </Text>
                  </Box>
                  <Box width="250px">
                    {/* <Select
                      style={multiSelectTheme}
                      size="large"
                      id={ID_PRODUCT_MAPPING.ADD.STORE_NAME}
                      onClear={() => handleClear("store_id")}
                      showArrow
                      onPopupScroll={handleScrollStore}
                      allowClear
                      maxTagCount={1}
                      mode="multiple"
                      disabled={values?.distributor_id?.length === 0}
                      value={values?.store_id}
                      name="store_id"
                      onBlur={handleBlur("store_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("store_id", value, opt)
                      }
                      // dropdownRender={(menu) => (
                      //   <DropDownCustom
                      //     menu={menu}
                      //     search={(e) => setfilterStore(e.target.value)}
                      //   />
                      // )}
                    >
                      {dataStore &&
                        dataStore?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data?.store_id ? data?.store_id : data?.id}
                              name={data.store_name}
                              code={data.store_code}
                            >
                              {data.store_name}
                            </Select.Option>
                          );
                        })}
                    </Select> */}
                    <FieldMultiSelectSearchStoreProductMapping
                      isDisabled={values?.distributor_id?.length === 0}
                      // id={ID_DATA_GROUP.ADD.STORE}
                      name="store"
                      // onChange={(value) => setFieldValue("store_id", value)}
                      onChange={(value, opt) =>
                        handleChangeSelect("store_id", value, opt)
                      }
                      values={values.store_id}
                      initialFilter={{
                        principal_id: `eq ${principalId}`,
                        customer_id: `eq ${values?.customer_id ?? ""}`,
                        distributor_id: `list_id ${
                          values?.distributor_id.join("_") ?? 0
                        }`,
                      }}
                    />
                    {errors.store_id && touched.store_id && (
                      <ValidationError text={errors.store_id} />
                    )}
                  </Box>
                </Box>
                {/* customer product name  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      style={inputTheme}
                      id={ID_PRODUCT_MAPPING.ADD.CUSTOMER_PRODUCT_NAME}
                      value={values?.product_customer_id}
                      onPopupScroll={handleScrollProductCustomer}
                      size="large"
                      allowClear
                      onClear={() => handleClear("product_customer_id")}
                      onBlur={handleBlur("product_customer_id")}
                      name="product_customer_id"
                      onChange={(value, opt) =>
                        handleChangeSelect("product_customer_id", value, opt)
                      }
                      dropdownRender={(menu) => (
                        <DropDownCustom
                          menu={menu}
                          search={(e) =>
                            setfilterProductCustomer(e.target.value)
                          }
                        />
                      )}
                    >
                      {listProductCustomer &&
                        listProductCustomer?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.id}
                              code={data.product_code}
                              title={data.product_code}
                            >
                              {data.product_code}
                              {" - "}
                              {data.product_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors.product_customer_id &&
                      touched.product_customer_id && (
                        <ValidationError text={errors.product_customer_id} />
                      )}
                  </Box>
                </Box>
                {/* principal product name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      size="large"
                      name="product_principal_id"
                      id={ID_PRODUCT_MAPPING.ADD.PRINCIPAL_PRODUCT_NAME}
                      onClear={() => handleClear("product_principal_id")}
                      onPopupScroll={handleScrollProductPrincipal}
                      style={inputTheme}
                      value={values?.product_principal_id}
                      allowClear
                      onChange={(value, opt) =>
                        handleChangeSelect("product_principal_id", value, opt)
                      }
                      onBlur={handleBlur("product_principal_id")}
                      dropdownRender={(menu) => (
                        <DropDownCustom
                          menu={menu}
                          search={(e) =>
                            setfilterProductPrincipal(e.target.value)
                          }
                        />
                      )}
                    >
                      {dataProductPrincipal &&
                        dataProductPrincipal?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.id}
                              principal={data.principal_name}
                              code={data.product_code}
                              title={data.product_code}
                            >
                              {data.product_code}
                              {" - "}
                              {data.product_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors.product_principal_id &&
                      touched.product_principal_id && (
                        <ValidationError text={errors.product_principal_id} />
                      )}
                  </Box>
                </Box>
                {/* Warehouse Code*/}
                {warehouseActive ? (
                  <Box padding={1} display="flex">
                    <Box width="10rem">
                      <Text overflowWrap="break-word" width="120px">
                        {t("PRODUCT_MAPPING:FIELD_WAREHOUSE_CODE")}
                      </Text>
                    </Box>
                    <Box width="12rem">
                      <Select
                        style={inputTheme}
                        id={ID_PRODUCT_MAPPING.ADD.WAREHOUSE_NAME}
                        value={values?.warehouse_id}
                        onPopupScroll={handleScrollWarehouse}
                        size="large"
                        allowClear
                        onBlur={handleBlur("warehouse_id")}
                        name="warehouse_id"
                        onChange={(value, opt) =>
                          handleChangeSelect("warehouse_id", value, opt)
                        }
                        dropdownRender={(menu) => (
                          <DropDownCustom
                            menu={menu}
                            search={(e) => setfilterWarehouse(e.target.value)}
                          />
                        )}
                      >
                        {dataWarehouse &&
                          dataWarehouse?.map((data, index) => {
                            return (
                              <Select.Option
                                key={index.toString()}
                                value={data.id}
                                code={data.warehouse_code}
                                fulldata={data}
                                name={data.warehouse_name}
                              >
                                {data.warehouse_code}
                              </Select.Option>
                            );
                          })}
                      </Select>
                      {errors.warehouse_id && touched.warehouse_id && (
                        <ValidationError text={errors.warehouse_id} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {/* status */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {t("PRODUCT_MAPPING:FIELD_STATUS")}
                    </Text>
                  </Box>
                  <Box>
                    <Select
                      style={inputTheme}
                      size="large"
                      id={ID_PRODUCT_MAPPING.ADD.STATUS}
                      name="status"
                      value={values?.status}
                      onBlur={handleBlur("status")}
                      onChange={(value, opt) =>
                        handleChangeSelect("status", value, opt)
                      }
                    >
                      {selectStatus.map((value, index) => {
                        return (
                          <Select.Option value={value.value} key={index}>
                            {t(value.label)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {errors.status && touched.status && (
                      <ValidationError text={errors.status} />
                    )}
                  </Box>
                </Box>
                {/* Converstion to smallest */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {t("PRODUCT_MAPPING:FIELD_CONVERSION_TO_SMALLEST")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.conversion_1_to_4}
                      name="conversion_1_to_4"
                      onChange={handleChange}
                    />
                    {errors.conversion_1_to_4 && touched.conversion_1_to_4 && (
                      <ValidationError text={errors.conversion_1_to_4} />
                    )}
                  </Box>
                </Box>
                {/* kolom 5 */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {/* {t("PRODUCT_MAPPING:FIELD_STATUS")} */}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.conversion_2_to_4}
                      name="conversion_2_to_4"
                      onChange={handleChange}
                    />
                    {errors.conversion_2_to_4 && touched.conversion_2_to_4 && (
                      <ValidationError text={errors.conversion_2_to_4} />
                    )}
                  </Box>
                </Box>
                {/* kolom 6 */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {/* {t("PRODUCT_MAPPING:FIELD_STATUS")} */}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.conversion_3_to_4}
                      name="conversion_3_to_4"
                      onChange={handleChange}
                    />
                    {errors.conversion_3_to_4 && touched.conversion_3_to_4 && (
                      <ValidationError text={errors.conversion_3_to_4} />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST}
            text={
              loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="blue.500"
                  color="WhiteAlpha.500"
                  size="lg"
                />
              ) : (
                t("COMMON:BUTTON_CANCEL")
              )
            }
            id={ID_PRODUCT_MAPPING.ADD.BTN_CANCEL}
            disabled={loading ? true : false}
          />
          <ButtonPrimary
            text={
              loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="blue.500"
                  color="WhiteAlpha.500"
                  size="lg"
                />
              ) : (
                t("COMMON:BUTTON_SAVE")
              )
            }
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_MAPPING.ADD.BTN_SAVE}
            disabled={loading ? true : false}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <UnSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddProductMapping;
