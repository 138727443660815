import { Box, Icon } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const SidebarButtonService = ({ onClick, to, icon, label, isActive }) => {
  return (
    <Box
      as={Link}
      to={to}
      display="flex"
      height="40px"
      alignItems="center"
      borderRadius={4}
      cursor="pointer"
      color={isActive ? "white" : "#668AB0"}
      backgroundColor={isActive && "#00366F"}
      _hover={{ backgroundColor: "#194b7d", color: "white" }}
      onClick={onClick}
    >
      <Box width="8" textAlign="center" children={<Icon as={icon} width={4} height={6} />} />
      <Box fontWeight={500} fontSize={14} textAlign="left" children={label} />
    </Box>
  );
};

export default SidebarButtonService;
