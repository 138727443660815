import { REGEX_CONSTANT } from "../../constant/regexConstant";
import * as yup from "yup";

export const changePasswordSchema = (t) => {
  return () =>
    yup.object().shape({
      old_password: yup
        .string()
        .min(
          8,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: 8,
          })
        )
        .matches(REGEX_CONSTANT.PASSWORD, t("COMMON:ERROR_PASSWORD"))
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PASSWORD") })
        ),
      new_password: yup
        .string()
        .min(
          8,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: 8,
          })
        )
        .matches(REGEX_CONSTANT.PASSWORD, t("COMMON:ERROR_PASSWORD"))
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PASSWORD") })
        ),
      verify_new_password: yup
        .string()
        .min(
          8,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: 8,
          })
        )
        .matches(REGEX_CONSTANT.PASSWORD, t("COMMON:ERROR_PASSWORD"))
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("COMMON:FIELD_CONFIRM_NEW_PASSWORD"),
          })
        )
        .oneOf(
          [yup.ref("new_password"), null],
          t("USER:ERROR_PASSWORD_NOT_MATCH")
        ),
    });
};
