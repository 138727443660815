import { REGEX_CONSTANT } from "../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addSchema = yup.object().shape({
  // store_name: yup
  //   .string()
  //   .max(150, i18next.t("STORE:ERROR_150"))
  //   .matches(
  //     REGEX_CONSTANT.STORE_NAME,
  //     i18next.t("STORE:FIELD_ERROR_STORE_NAME_REGEX")
  //   )
  //   .required(i18next.t("STORE:FIELD_ERROR_STORE_NAME_EMPTY")),
  customer_id: yup
    .string()
    .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
  store_id: yup
    .string()
    .max(35, i18next.t("STORE:ERROR_35"))
    .matches(
      REGEX_CONSTANT.STORE_ID,
      i18next.t("STORE:FIELD_ERROR_STORE_ID_REGEX")
    )
    .required(
      i18next.t("COMMON:ERROR_REQUIRED", {
        field: i18next.t("STORE:FIELD_STORE_ID"),
      })
    ),
  store_code: yup
    .string()
    .max(50, i18next.t("STORE:ERROR_50"))
    .required(i18next.t("STORE:FIELD_ERROR_STORE_CODE_EMPTY")),
  phone: yup
    .string()
    .matches(REGEX_CONSTANT.PHONE_NUMBER, i18next.t("STORE:FIELD_ERROR_NUMBER"))
    .max(15, i18next.t("STORE:ERROR_15")),
  address_1: yup
    .string()
    .max(100, i18next.t("STORE:ERROR_100"))
    .required(i18next.t("STORE:FIELD_ERROR_ADDRESS_EMPTY")),
});

export const updateSchema = yup.object().shape({
  // store_name: yup
  //   .string()
  //   .max(150, i18next.t("STORE:ERROR_150"))
  //   .matches(
  //     REGEX_CONSTANT.STORE_NAME,
  //     i18next.t("STORE:FIELD_ERROR_STORE_NAME_REGEX")
  //   )
  //   .required(i18next.t("STORE:FIELD_ERROR_STORE_NAME_EMPTY")),
  phone: yup
    .string()
    .matches(REGEX_CONSTANT.PHONE_NUMBER, i18next.t("STORE:FIELD_ERROR_NUMBER"))
    .max(15, i18next.t("STORE:ERROR_15")),
  address_1: yup
    .string()
    .required(i18next.t("STORE:FIELD_ERROR_ADDRESS_EMPTY"))
    .max(100, i18next.t("STORE:ERROR_100")),
  status: yup.string().required(),
  customer_id: yup
  .object()
    .nullable()
    .shape({
      value: yup
        .string()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("STORE:FIELD_CUSTOMER_ID"),
          })
        ),
    }),
});
