import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_PRODUCT_CUSTOMER } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR, PRIMARY_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  inputTheme
} from "../../../../../constant/themeConstant";
import { getListCustomer, getListCustomerDDS, getListPrincipalDDS } from "../../../../../services/main/customerReducer";
import { addProductCustomer } from "../../../../../services/main/productCustomerReducer";
import { getListPrincipal } from "../../../../../services/main/warehouseReducer";
import StringUtil from "../../../../../utils/stringUtil";
import { addSchema } from "./productCustomerSchema";
const AddProductCustomer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { Option } = Select;

  const principalIdState = useSelector((state) => state.auth.principal_id);
  const role = useSelector((state) => state.auth.role);

  // modal response
  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  // searchcustomername
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);

  // principal
  const [principalId, setPrincipalId] = useState("");
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10); 
  // Submit funct
  const onSubmit = (values, action) => {
    values.conversion_1_to_4 = parseInt(values.conversion_1_to_4);
    values.conversion_2_to_4 = parseInt(values.conversion_2_to_4);
    values.conversion_3_to_4 = parseInt(values.conversion_3_to_4);
    let tempPriceUom1 = parseInt(values.price_uom_1);
    values.price_uom_1 = tempPriceUom1;
    dispatch(addProductCustomer(values)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
        action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };

  // modal success or failed
  const modalSuccess = () => {
    history.push(ROUTES_USER_SELLER.PRODUCT_CUSTOMER.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  //pengecekan apakah ini principal
  const roleToLowerCase = role.toLowerCase();
  const isPrincipal = roleToLowerCase.includes("principal");

  // Formik
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: isPrincipal ? principalIdState : principalId,
      customer_id: "",
      product_code: "",
      product_name: "",
      barcode: "",
      product_description: "",
      status: "",
      packaging: "",
      uom_1: "",
      uom_2: "",
      uom_3: "",
      uom_4: "",
      conversion_1_to_4: "",
      conversion_2_to_4: "",
      conversion_3_to_4: "",
      order_unit: "",
      price_uom_1: "",
    },
    validationSchema: addSchema,
    onSubmit,
  });

  const [idCustomer, setIdCustomer] = useState("");
  const handleChangeSelect = (name, value, option) => {
    if (name === "customer_id") {
      setFieldValue("customer_id", value);
      setIdCustomer(value);
    } else if (name === "principal_id") {
      setPrincipalId(value);
    }
    setFieldValue(name, value);
  };

  const dataCustomerCode = useMemo(() => {
    // find customer code by selected customer id
    return dataCustomer?.find((search) => search.id === idCustomer);
  }, [idCustomer]);

  // parameter customer
  const handleScrollStore = (event) => {
    setPageCustomer(pageCustomer + 1);
  };
 

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =  { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =  { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const params =  { page, limit, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const params = { page, limit, filter };

    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi princapl
  const regexFormatter = (onChangeCallback, regex) => {
    return (event) => {
      const value = event.target.value?.replace(regex, "");
      onChangeCallback(value, event.target.name);
    };
  };
  const renderPrincipal = () => {
    return (
      <Box padding={1} display="flex">
        <Box width="10rem" className="form-title">
          <Text>{t("PRODUCT_CUSTOMER:FIELD_PRINCIPAL_NAME")}</Text>
        </Box>
        <Box className="my-select-container" pb={1}>
          <Select
            style={{ width: 250, borderRadius: "6px" }}
            value={isPrincipal ? principalIdState : principalId}
            disabled={isPrincipal ? true : false}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
          {errors.principal_id && touched.principal_id && (
            <ValidationError text={errors.principal_id} />
          )}
        </Box>
      </Box>
    );
  };
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CUSTOMER:ADD_PRODUCT_CUSTOMER")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CUSTOMER:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CUSTOMER:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CUSTOMER:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CUSTOMER:BUTTON_1")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          // justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius={10}
        >
          {/* left side */}
          <Box padding={1} minWidth="37vw">
            {/* Customer Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  name="customer_code"
                  value={dataCustomerCode ? dataCustomerCode.customer_code : ""}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  disabled
                  backgroundColor={GRAY_COLOR}
                  id="tsl-UserSeller-ProductCustomer-AddProductCustomer-PrincipalCode"
                />
              </Box>
            </Box>
            {/* Product Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CUSTOMER:FIELD_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  name="product_code"
                  onChange={handleChange}
                  value={values.product_code}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id="tsl-UserSeller-ProductCustomer-AddProductCustomer-ProductCode"
                />
                {errors.product_code && touched.product_code && (
                  <ValidationError text={errors.product_code} />
                )}
              </Box>
            </Box>
            {/* Barcode*/}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_BARCODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  name="barcode"
                  onChange={handleChange}
                  value={values.barcode}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.BARCODE}
                />
                {errors.barcode && touched.barcode && (
                  <ValidationError text={errors.barcode} />
                )}
              </Box>
            </Box>

            {/* price uom 1 */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_PRICE_UOM_1")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.price_uom_1}
                  name="price_uom_1"
                  onChange={handleChange}
                  style={inputTheme}
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.PRICE_UOM_1}
                />
                {errors.price_uom_1 && touched.price_uom_1 && (
                  <ValidationError text={errors.price_uom_1} />
                )}
              </Box>
            </Box>

            {/* order unit */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_ORDER_UNIT")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.order_unit}
                  name="order_unit"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.ORDER_UNIT}
                />
                {errors.order_unit && touched.order_unit && (
                  <ValidationError text={errors.order_unit} />
                )}
              </Box>
            </Box>

            {/* UOM 1*/}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_1")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.uom_1}
                  name="uom_1"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.UOM1}
                />
                {errors.uom_1 && touched.uom_1 && (
                  <ValidationError text={errors.uom_1} />
                )}
              </Box>
            </Box>
            {/* UOM 2  */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                {" "}
                <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_2")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.uom_2}
                  name="uom_2"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.UOM2}
                />
                {errors.uom_2 && touched.uom_2 && (
                  <ValidationError text={errors.uom_2} />
                )}
              </Box>
            </Box>
            {/* UOM 3  */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_3")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.uom_3}
                  name="uom_3"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.UOM3}
                />
                {errors.uom_3 && touched.uom_3 && (
                  <ValidationError text={errors.uom_3} />
                )}
              </Box>
            </Box>
            {/* UOM 4 */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_4")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.uom_4}
                  name="uom_4"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.UOM4}
                />
                {errors.uom_4 && touched.uom_4 && (
                  <ValidationError text={errors.uom_4} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal */}
            {renderPrincipal()}

            {/* customer name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  value={values.customer_id}
                  onPopupScroll={handleScrollStore}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomerName(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear="true"
                >
                  {dataCustomer &&
                    dataCustomer.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* product name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_NAME")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.product_name}
                  name="product_name"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                />
                {errors.product_name && touched.product_name && (
                  <ValidationError text={errors.product_name} />
                )}
              </Box>
            </Box>
            {/* product description */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_DESCRIPTION")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.product_description}
                  name="product_description"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.PRODUCT_DESCRIPTION}
                />
                {errors.product_description && touched.product_description && (
                  <ValidationError text={errors.product_description} />
                )}
              </Box>
            </Box>

            {/* product status */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="status"
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  id={ID_PRODUCT_CUSTOMER.ADD.PRODUCT_STATUS}
                  onBlur={handleBlur}
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>

            {/* product packaging */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_PACKAGING")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.packaging}
                  name="packaging"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_CUSTOMER.ADD.PRODUCT_PACKAGING}
                />
                {errors.packaging && touched.packaging && (
                  <ValidationError text={errors.packaging} />
                )}
              </Box>
            </Box>

            {/* Converstion To Smallest*/}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="100px">
                  {t("PRODUCT_CUSTOMER:FIELD_CONVERSION_TO_SMALLEST")}
                </Text>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box paddingBottom={3}>
                  <Input
                    value={values.conversion_1_to_4}
                    name="conversion_1_to_4"
                    onChange={handleChange}
                    width="250px"
                    id={ID_PRODUCT_CUSTOMER.ADD.CONVERSTION_TO_SMALLEST_1}
                    style={inputTheme}
                    onBlur={handleBlur}
                  />
                  {errors.conversion_1_to_4 && touched.conversion_1_to_4 && (
                    <ValidationError text={errors.conversion_1_to_4} />
                  )}
                </Box>
                <Box paddingBottom={3}>
                  <Input
                    value={values.conversion_2_to_4}
                    name="conversion_2_to_4"
                    id={ID_PRODUCT_CUSTOMER.ADD.CONVERSTION_TO_SMALLEST_2}
                    onChange={handleChange}
                    width="250px"
                    style={inputTheme}
                    onBlur={handleBlur}
                  />
                  {errors.conversion_2_to_4 && touched.conversion_2_to_4 && (
                    <ValidationError text={errors.conversion_2_to_4} />
                  )}
                </Box>
                <Box paddingBottom={3}>
                  <Input
                    value={values.conversion_3_to_4}
                    id={ID_PRODUCT_CUSTOMER.ADD.CONVERSTION_TO_SMALLEST_3}
                    name="conversion_3_to_4"
                    onChange={handleChange}
                    width="250px"
                    style={inputTheme}
                    onBlur={handleBlur}
                  />
                  {errors.conversion_3_to_4 && touched.conversion_3_to_4 && (
                    <ValidationError text={errors.conversion_3_to_4} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_CUSTOMER.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_CUSTOMER.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_CUSTOMER.ADD.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default AddProductCustomer;
