import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  table_loading: false,
  export_loading : false,
  table_loading_product_ho: false,
  status: "",
  list_principal: [],
  list_customer: [],
  list_product_principal: [],
  list_product_customer: [],
  edit_product_mapping_ho: {},
  errorNotification: "",
  additional_barcode: false,
};

export const initiateInputProductMappingHo = createAsyncThunk(
  "initiateInputProductMappingHo",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/initiateInput`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitiateListProductMappingHo = createAsyncThunk(
  "initiateListProductMappingHo",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProductMappingHo = createAsyncThunk(
  "getListProductMappingHO",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailProductMappingHo = createAsyncThunk(
  "detailProductMappingHo",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const deleteProductMappingHo = createAsyncThunk(
  "deleteProductMappingHo",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      productId: object.productId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailProductMappingHoForEdit = createAsyncThunk(
  "detailProductMappingHoForEdit",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const addProductMappingHo = createAsyncThunk(
  "addProductMappingHo",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const editProductMappingHo = createAsyncThunk(
  "editProductMappingHo",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      product_principal_id: object?.product_principal_id?.value,
      product_customer_id: object?.product_customer_id?.value,
      barcode: object?.barcode,
      status: object.status,
      updated_at: object.updatedAt,
      productId: object.product_id,
      additional_data: {
        barcode: object?.additional_data?.barcode,
        alternative_barcode: object?.additional_data?.alternative_barcode,
      },
      uom_1: object?.uom_1,
      uom_2: object?.uom_2,
      uom_3: object?.uom_3,
      uom_4: object?.uom_4,
      conv_1_to_4: Number(object?.conv_1_to_4),
      conv_2_to_4: Number(object?.conv_2_to_4),
      conv_3_to_4: Number(object?.conv_3_to_4),
      order_unit: object?.order_unit,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response;
        result.message = response.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductPrincipal = createAsyncThunk(
  "getListProductPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductPrincipalDDS = createAsyncThunk(
  "getListProductPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCustomer = createAsyncThunk(
  "getListProductCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProductCustomerDDS = createAsyncThunk(
  "getListProductCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const exportProductMappingHo = createAsyncThunk(
  "exportProductMappingHo",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const token = auth.token;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-ho/export`;
    const body = object

    try {
      const response = await baseApi.doPostResponseBlob(url, token,body);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const productMappingHo = createSlice({
  name: "productMappingHo",
  initialState: INITIAL_STATE,
  reducers: {
    setAdditionalBarcode(state, action) {
      state.additional_barcode = action.payload;
    },
    resetReduxProductMappingHo() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // product mapping ho
    [getInitiateListProductMappingHo.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProductMappingHo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProductMappingHo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListProductMappingHo.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading_product_ho = true;
    },
    [getListProductMappingHo.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading_product_ho = false;
    },
    [getListProductMappingHo.rejected]: (state, action) => {
      state.table_loading_product_ho = false;
      state.status = "failed";
    },

    [addProductMappingHo.pending]: (state, action) => {
      state.status = "loading";
    },
    [addProductMappingHo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addProductMappingHo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editProductMappingHo.pending]: (state, action) => {
      state.status = "loading";
    },
    [editProductMappingHo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editProductMappingHo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [detailProductMappingHo.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductMappingHo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailProductMappingHo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [deleteProductMappingHo.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductMappingHo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductMappingHo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [getListProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListProductPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [getListProductCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListProductCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [detailProductMappingHoForEdit.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductMappingHoForEdit.fulfilled]: (state, action) => {
      state.status = "success";
      state.edit_product_mapping_ho = action.payload.response;
    },
    [detailProductMappingHoForEdit.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },


    [exportProductMappingHo.pending]: (state) => {
      state.export_loading = true;
    },
    [exportProductMappingHo.fulfilled]: (state) => {
      state.export_loading = false ;
    },
    [exportProductMappingHo.rejected]: (state, action) => {
      state.export_loading = false;
    },
  },
});

export const productMappingHoAction = productMappingHo.actions;

export default productMappingHo.reducer;
