import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import CustomCheckBox from "../../../../components/customCheckBox/CustomCheckBox";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import { DownloadIcon, SearchIcon } from "../../../../components/icons";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { ID_MONITORING } from "../../../../constant/idConstant";
import {
  GRAY_COLOR,
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { selectDropDownMonitoringUnmappedStore } from "../../../../constant/selectOptConstant";
import {
  selectOptFilterPrincipal,
  selectOptFilterUserTheme,
} from "../../../../constant/themeConstant";
import {
  downloadUnmappedStore,
  getInitListUnmappedStore,
  getListCustomerDDS,
  getListDistributorDDS,
  getListUnmappedStore,
  unmappedStoreReducerAction,
} from "../../../../services/main/monitoring/monitoringUnmappedStore";
import DateUtil, { FORMAT } from "../../../../utils/dateUtil";
import StringUtil from "../../../../utils/stringUtil";

const UnmappedStore = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.unmappedStore.list_unmapped);
  const table_loading = useSelector(
    (state) => state.unmappedStore.table_loading
  );
  const { status: statusCustomer } = useSelector((state) => state.customer);
  const { status: statusDistributor } = useSelector(
    (state) => state.distributor
  );
  const [order, setOrder] = useState("");
  const [total, setTotal] = useState(0);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState("");
  const [countSearch, setcountSearch] = useState("");
  const [popOverOpen, setPopOverOpen] = useState(false);

  const [transactionDate, setTransactionDate] = useState("");
  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);

  // filter distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");
  const [idDistributor, setidDistributor] = useState(null);

  // List id data di page yang aktif
  const [listID, setListID] = useState([]);

  const { token } = useSelector((state) => state.auth);
    
  const [objectFilter, setObjectFilter] = useState({
    idCustomer: null,
    idDistributor: null,
    form: "",
    updated_at_start: "",
    updated_at_end: "",
  });
  const dispatch = useDispatch();
  const filter = StringUtil.formatFilter({
    customer_id: `eq ${objectFilter.idCustomer}`,
    distributor_id: `eq ${objectFilter.idDistributor}`,
    [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
  });
  const updated_at_start = objectFilter.updated_at_start;
  const updated_at_end = objectFilter.updated_at_end;
  const params = {
    page,
    limit,
    filter,
    order,
    updated_at_start,
    updated_at_end,
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const fetchData = () => {
    dispatch(getListUnmappedStore(params)).then((response) => {
      if (!response.payload.ok) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListUnmappedStore(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    dispatch(unmappedStoreReducerAction.resetCBX());

    fetchData();
    setSubmit(false);
  }, [token, total, submit, order, dispatch]);

  useEffect(() => {
    dispatch(getInitListUnmappedStore(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });

    fetchData();
  }, [page, limit]);

  // fungsi customer
  const handleScrollCustomer = (event) => {
    if (statusCustomer === "success") {
      setPageCustomer(pageCustomer + 1);
    }
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  // distributor
  // fungsi untuk DDS filter distributor
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    // const filter = `distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };

  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };

  const handleDistributorClear = () => {
    setPageDistributor(1);
    setidDistributor("");
    setFilterDistributor("");
    setDataDistributor([]);
  };

  const handleScrollDistributor = (event) => {
    if (statusDistributor === "success") {
      setPageDistributor(pageDistributor + 1);
    }
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  // end fungsi customer

  // filter transaction date
  const handleChangeTransactionDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setTransactionDate([start, end]);
    } else setTransactionDate([]);
    setIsDisabled(false);
  };
  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    } else setForm("");
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    setObjectFilter({
      idCustomer: idCustomer,
      idDistributor: idDistributor,
      form : form,
      updated_at_start:
        transactionDate.length > 1
          ? moment(transactionDate[0]).format("YYYY-MM-DD")
          : "",
      updated_at_end:
        transactionDate.length > 1
          ? moment(transactionDate[1]).format("YYYY-MM-DD")
          : "",
    });
  };
  const resetFilter = () => {
    setForm("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSubmit(true);
    setIdCustomer(null);
    setfilterCustomerName("");
    setidDistributor(null);
    setFilterDistributor("");
    setTransactionDate([]);
    setPage(1);
    setLimit(10);
    setcountSearch("");
    setSelectDropDownFilter("");
    setPopOverOpen(false);
    dispatch(unmappedStoreReducerAction.resetCBX());
    setObjectFilter({
      idCustomer: null,
      idDistributor: null,
      updated_at_start: "",
      updated_at_end: "",
    });
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "select_drop_down") {
      setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
    } else if (name === "customer_id") {
      setIdCustomer(value);
    } else if (name === "distributor_id") {
      setidDistributor(value);
    }
  };

  const dataSelectedRowKeys = useSelector(
    (state) => state.unmappedStore.selectedRowKeys
  );

  // ngecek ada data yang di select apa tidak
  const hasSelected = dataSelectedRowKeys?.length > 0;
  // fungsi buat button print
  const print = () => {
    // setSelectedRowKeys([]);
    const body = {
      list_id: dataSelectedRowKeys,
    };
    dispatch(downloadUnmappedStore(body))
      .then((resp) => {
        if (resp.payload.ok) {
          const { data, headers } = resp.payload.response;
          const [, filename] = headers["content-disposition"].split(";");
          const [, name] = filename.trim().split("=");
          const newName = name.replace(
            ".",
            `_${DateUtil.formatDate(new Date(), FORMAT.DEFAULT_DATE_XLSX)}.`
          );
          FileSaver.saveAs(data, newName);
        } else {
          message.error(
            resp.payload.response.data.nexsoft.payload.status.message
          );
        }
      })
      .then(() => {
        dispatch(unmappedStoreReducerAction.resetCBX());
        dispatch(unmappedStoreReducerAction.setTableLoadingFalse());
      });
  };

  const handleOnChangeChecked = (event, { id: id, po_number: po_number }) => {
    dispatch(
      unmappedStoreReducerAction.setSingleCheck({
        id: id,
        po_number: po_number,
      })
    );
  };

  const handleChangeAllChecked = () => {
    dispatch(unmappedStoreReducerAction.setAllCheck(listID));
  };

  useEffect(() => {
    setListID(
      data?.map((item) => ({ id: item.id, po_number: item.po_number }))
    );
  }, [data]);

  const getCheckedAll = () => {
    // listID
    if (listID?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of listID) {
        isCheckedAll = false;
        for (const CheckedData of dataSelectedRowKeys) {
          if (ListIdData.id === CheckedData.id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) break;
      }
      return isCheckedAll;
    } else {
      return;
    }
  };

  const checkedAll = getCheckedAll();
  useEffect(() => {
    return () => {
      dispatch(unmappedStoreReducerAction.resetReduxUnmapped());
    };
  }, []);
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      store_code: item.store_code,
      po_number: item.po_number,
      store_name: item.store_name,
      customer_name: item.customer_name,
      transaction_date: moment(item.transaction_date)
        .subtract(7, "hours")
        .format("DD/MM/YYYY"),
      time_process: moment(item.time_process)
        .subtract(7, "hours")
        .format("DD/MM/YYYY HH:mm:ss"),
      supplier_code: item.supplier_code,
      check_box: (
        <CustomCheckBox
          onChange={(event) =>
            handleOnChangeChecked(event, {
              id: item.id,
              po_number: item.po_number,
            })
          }
          isChecked={dataSelectedRowKeys?.find((data) => data.id === item.id)}
        />
      ),
    }));

  const columns = [
    {
      title: (
        <CustomCheckBox
          onChange={handleChangeAllChecked}
          isChecked={checkedAll}
        />
      ),
      width: 50,
      dataIndex: "check_box",
      key: "check_box",
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "store_code",
      key: "store_code",
      width: 120,
      sorter: (a, b) => a.store_code - b.store_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_STORE")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_TRANSACTION_DATE")}
        </Box>
      ),
      dataIndex: "transaction_date",
      key: "transaction_date",
      sorter: (a, b) => a.transaction_date - b.transaction_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_TIME_PROCESS")}
        </Box>
      ),
      dataIndex: "time_process",
      key: "time_process",
      sorter: (a, b) => a.time_process - b.time_process,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_STORE:FIELD_SUPPLIER_CODE")}
        </Box>
      ),
      dataIndex: "supplier_code",
      key: "supplier_code",
      sorter: (a, b) => a.supplier_code - b.supplier_code,
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <Box p={4}>
      <HeadTitle
        title={t("UNMAPPED_STORE:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("UNMAPPED_STORE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("UNMAPPED_STORE:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("UNMAPPED_STORE:SUBTITLE_3")}
            </span>{" "}
            - {t("UNMAPPED_STORE:TITLE_LIST")}
          </>
        }
        button={
          <ButtonCustom
            id={ID_MONITORING.LIST.BTN_PRINT}
            onClick={print}
            disabled={!hasSelected}
            icon={<DownloadIcon />}
            width="156px"
            height="40px"
            text={t("COMMON:DOWNLOAD_XLSX")}
            fontSize="14px"
            marginStart={2}
            colorScheme="white"
            // variant="outline"
            backgroundcolor={hasSelected === true ? PRIMARY_COLOR : "grey"}
          />
        }
      />

      <Box marginTop={6}>
        <Box marginLeft={"18.8rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* Transaction date */}
          <Box width="200px">
            <InputDateRangePicker
              elementId={ID_MONITORING.LIST.DELIVERY_DATE}
              placeHolder={t("COMMON:TRANSACTION_DATE")}
              values={transactionDate}
              onChange={handleChangeTransactionDate}
            />
          </Box>
          {/* By Distributor */}
          <Select
            id={ID_MONITORING.LIST.DISTRIBUTOR}
            style={selectOptFilterPrincipal}
            name="distributor_id"
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={handleDistributorClear}
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By customer */}
          <Select
            id={ID_MONITORING.LIST.CUSTOMER}
            style={selectOptFilterPrincipal}
            size="large"
            value={idCustomer}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("COMMON:SELECT_CUSTOMER")}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>

          <Box>
            <ButtonPrimary
              id={ID_MONITORING.LIST.BTN_SUBMIT}
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_MONITORING.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        {/* baris kedua  */}
        <Box
        // marginLeft={isAccepted ? "29.8rem" : "18.9rem"}
        >
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            id={ID_MONITORING.LIST.DDL_PILIHAN}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            value={
              selectDropDownFilter.value ? selectDropDownFilter.value : null
            }
            placeholder={t("COMMON:SELECT_FILTER_BY")}
          >
            {selectDropDownMonitoringUnmappedStore?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              id={ID_MONITORING.LIST.SEARCH}
              style={{ border: "1px solid #d9d9d9" }}
              type="text"
              placeholder={
                selectDropDownFilter.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={
                selectDropDownFilter.value === undefined ? "none" : "auto"
              }
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
        </Stack>
        <Table
          rowKey="uid"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          onChange={handleTableChange}
          loading={table_loading}
          scroll={{ y: 350 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_MONITORING.LIST.ROW_PER_PAGE}
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_MONITORING.LIST.NEXT_PAGE}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default UnmappedStore;
