import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Divider,
  Text,
  Badge,
  Select as SelectChakra,
  Button,
} from "@chakra-ui/react";
import { Portal } from "@chakra-ui/portal";
import DayPicker, { DateUtils } from "react-day-picker";
import { format, set } from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Pagination, Select, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import {
  AddIcon,
  SearchIcon,
  DateIcon,
  EditIcon,
  DetailIcon,
} from "../../components/icons";
import "./DateRangePicker.css";
import "react-day-picker/lib/style.css";
import {addMinutes} from "../../services/serviceUtil";
import moment from "moment";
const DateRangePicker = (props) => {
  const today = new Date();
  const [selectedDay, setSelectedDay] = useState(today);
  const initialDate = {
    from: undefined,
    to: undefined,
  };

  const [initState, setInitState] = useState({ ...initialDate });
  const [initColor,setInitColor] = useState("#003C7C")
  function Navigation({ onPreviousClick, onNextClick, className }) {
    return (
      <Box className={className}>
        <Button
          variant="ghost"
          style={{ float: "left" }}
          onClick={() => onPreviousClick()}
        >
          <ChevronLeftIcon />
        </Button>
        <Button
          variant="ghost"
          style={{ float: "right" }}
          onClick={() => onNextClick()}
        >
          <ChevronRightIcon />
        </Button>
      </Box>
    );
  }
  const modifiers = {
    todayDate: today,
  };
  const modifiersStyles = {
    todayDate: {
      color: "white",
      backgroundColor: initColor,
    },
  };

  const handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, initState);
    setInitState(range);
    if(moment(day).format("MMM Do YY") === 
    moment(modifiers.todayDate).format("MMM Do YY")){
       setInitColor("#4A90E2")
      }
      else{
        setInitColor("#003C7C")
      }
  };
  const handleOk = (onClose) => {
    if (initState.to) {
      props.handleOk({ start: initState.from.toISOString(), end: addMinutes(initState.to, 1139).toISOString() });
      onClose();
    }
  };
  const handleReset = (onClose) => {
    setInitState({ ...initialDate }, () => props.handleReset());
    onClose();
  };
  return (
    <>
      <Popover placement="bottom-start">
        {({ isOpen, onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                size="sm"
                backgroundColor="white"
                border="1px solid #eaeaea"
                // boxShadow="md"
                fontWeight="normal"
                borderRadius="4"
                rightIcon={<Icon as={DateIcon} />}
                _focus={{ boxShadow: "none" }}
                _active={{ backgroundColor: "white" }}
                _hover={{ backgroundColor: "#eaeaea" }}
                width={props.width}
                height="2.5rem"
              >
                <Box
                  width="90%"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textAlign="left"
                >
                  {/* {this.state.to ? text : this.props.label} */}
                </Box>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent>
                <PopoverBody>
                  <Stack spacing="0">
                    <DayPicker
                      firstDayOfWeek={0}
                      selectedDays={[
                        initState.from,
                        { from: initState.from, to: initState.to },
                      ]}
                      weekdaysShort={["S", "M", "T", "W", "T", "F", "S"]}
                      onDayClick={handleDayClick}
                      navbarElement={Navigation}
                      modifiers={modifiers}
                      modifiersStyles={modifiersStyles}
                      numberOfMonths={1}
                    />
                  </Stack>
                  <Stack direction="row" paddingTop="2">
                    <Button
                      size="xs"
                      width="50%"
                      variant="ghost"
                      colorScheme="black"
                      fontWeight="normal"
                      onClick={() => handleReset(onClose)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="xs"
                      width="50%"
                      colorScheme="facebook"
                      fontWeight="normal"
                      onClick={() => handleOk(onClose)}
                    >
                      Done
                    </Button>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </>
        )}
      </Popover>
    </>
  );
};

export default DateRangePicker;
