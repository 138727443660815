import {
  Box, Input
} from "@chakra-ui/react";
import { Select } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import { SavedSuccess, UnSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ID_ROLE } from "../../../../constant/idConstant";
import { ROUTES } from "../../../../constant/routeConstant";
import {
  selectRoleLevel,
  selectStatus
} from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import "../../../../css/GlobalAntd.css";
import { addRoles, roleAction } from "../../../../services/main/roleReducer";
import { addSchema } from "../roleSchema";
const AddRole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company_name } = useSelector((state) => state.auth);
  const [modalOpen, setModalOpen] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const onSubmit = (values, action) => {
    dispatch(addRoles(values)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.response.nexsoft.payload.status.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      role_id: "",
      description: "",
      level: "",
      status: "",
    },
    validationSchema: addSchema(t),
    onSubmit,
  });

  const [newDataAdded, setNewDataAdded] = useState(false);
  const clickOk = () => {
    setNewDataAdded(true);
    resetReduxRoles();
  };
  const resetReduxRoles = () => {
    dispatch(roleAction.resetReduxRole());
  };
  return newDataAdded ? (
    <Redirect to={ROUTES.ROLE.LIST} />
  ) : (
    <Box padding={4}>
      {/* Head Tittle  */}
      <HeadTitle
        title={t("ROLE:ADD_ROLE")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("COMMON:HOME")}</span> -{" "}
            <span style={{ color: "#003C7C" }}>{t("ROLE:TITLE_LIST")} </span> -{" "}
            {t("ROLE:ADD_ROLE")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* White Background  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          marginTop={6}
        >
          {/* Role  */}
          <Box padding={1} display="flex">
            <Box width="10rem" marginTop={2}>
              {t("ROLE:TITLE_LIST")}
            </Box>
            <Box>
              <Input
                type="text"
                width="250px"
                value={values.role_id}
                id={ID_ROLE.ADD.ROLE}
                // onChange={(e) => handleChange(e)}
                onChange={handleChange}
                onBlur={handleBlur}
                name="role_id"
              />
              {errors.role_id && touched.role_id && (
                <ValidationError text={errors.role_id} />
              )}
            </Box>
          </Box>
          {/* FarmIndo Account  */}
          <Box padding={1} display="flex">
            <Box width="10rem" marginTop={2}>
              {t("ROLE:FIELD_FARMINDO_ACCOUNT")}
            </Box>
            <Box>
              <Input
                type="text"
                style={inputTheme}
                value={company_name}
                disabled
                backgroundColor="grey"
              />
            </Box>
          </Box>
          {/* Description  */}
          <Box padding={1} display="flex">
            <Box width="10rem" marginTop={2}>
              {t("ROLE:FIELD_DESCRIPTION")}
            </Box>
            <Box>
              <Input
                type="text"
                id={ID_ROLE.ADD.DESCRIPTION}
                style={inputTheme}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                name="description"
              />
              {errors.description && touched.description && (
                <ValidationError text={errors.description} />
              )}
            </Box>
          </Box>
          {/*Role Level   */}
          <Box padding={1} display="flex">
            <Box width="10rem" marginTop={2}>
              {t("ROLE:FIELD_ROLE_LEVEL")}
            </Box>
            <Box className="my-select-container">
              <Select
                style={inputTheme}
                id={ID_ROLE.ADD.LEVEL}
                name="level"
                value={values.level}
                onChange={(value) => setFieldValue("level", value)}
                size="large"
              >
                {selectRoleLevel.map((value) => {
                  return <option value={value}>{value}</option>;
                })}
              </Select>
              {errors.level && touched.level && (
                <ValidationError text={errors.level} />
              )}
            </Box>
          </Box>
          {/* Status   */}
          <Box padding={1} display="flex">
            <Box width="10rem" marginTop={2}>
              {t("ROLE:FIELD_STATUS")}
            </Box>
            <Box className="my-select-container">
              <Select
                id={ID_ROLE.ADD.STATUS}
                style={inputTheme}
                name="status"
                value={values.status}
                onChange={(value) => setFieldValue("status", value)}
                size="large"
              >
                {selectStatus.map((value) => {
                  return <option value={value.value}>{t(value.label)}</option>;
                })}
              </Select>
              {errors.status && touched.status && (
                <ValidationError text={errors.status} />
              )}
            </Box>
          </Box>
        </Box>

        {/* Button  */}
        <Box display="flex" marginTop={4} float="right">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES.ROLE.LIST}
            id={ID_ROLE.ADD.BTN_CANCEL}
            text={t("COMMON:BUTTON_CANCEL")}
            onClick={resetReduxRoles}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_ROLE.ADD.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={resp}
          isOpen={modalOpen}
          onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
          icon={err ? <UnSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};
export default AddRole;
