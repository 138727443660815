import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { HOVER_PRIMARY, PRIMARY_COLOR } from "../constant/propertiesConstant";

const ModalConfirm = ({
  isOpen,
  onClose,
  icon,
  colorOk = "red",
  message,
  onOk,
  messageModal,
  messageConfirm,
  confirmYes = "",
  confirmNo = "",
  idYes,
  idNo
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width={376} height={185}>
        <ModalBody>
          <Stack>
            <Center />
            <Center>
              <Text
                fontWeight={700}
                fontSize={20}
                textAlign="center"
                marginY={1}
              >
                {messageModal}
              </Text>
            </Center>
            <Center>
              <Text fontWeight={300} fontSize={14} textAlign="center">
                {messageConfirm}
              </Text>
            </Center>
            <Center>
              <Box display="flex" marginTop={2}>
                <Button
                  marginTop={2}
                  marginBottom={6}
                  width="150px"
                  height="40px"
                  color="blue"
                  background="white"
                  fontWeight="normal"
                  _hover={{ background: "white" }}
                  _focus={{ boxShadow: 0 }}
                  onClick={onClose}
                  variant="outline"
                  colorScheme="blue"
                  marginRight={2}
                  id={idNo}
                >
                  <Text fontWeight={500} fontSize={14}>
                    {confirmNo === "" ? t("COMMON:NO") : confirmNo}
                  </Text>
                </Button>
                <Divider orientation="vertical" />
                <Button
                  marginTop={2}
                  marginBottom={6}
                  width="150px"
                  height="40px"
                  color={colorOk}
                  background="white"
                  fontWeight="normal"
                  _hover={{ background: "white" }}
                  _focus={{ boxShadow: 0 }}
                  onClick={onOk}
                  variant="outline"
                  colorScheme="red"
                  marginLeft={2}
                  id={idYes}
                >
                  <Text fontWeight={500} fontSize={14}>
                    {confirmYes === "" ? t("COMMON:DELETE_TRUE") : confirmYes}
                  </Text>
                </Button>
              </Box>
            </Center>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
