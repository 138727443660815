import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  listCustomer: [],
  status : "",
};

export const getInitListCustomer = createAsyncThunk(
  "initiateGetListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const customer = createSlice({
  name: "customer",
  initialState: INITIAL_STATE,
  reducers: {
    resetStatus(state){
      state.status = "";
    },
    resetReduxUser() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.listCustomer = action.payload.response;
    },
    [getListCustomer.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload.response;
      state.table_loading = false;
    },

    [getListCustomerDDS.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListCustomerDDS.fulfilled]: (state, action) => {
      state.status = "success";
      state.listCustomer = action.payload.response;
    },
    [getListCustomerDDS.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload.response;
    },
  },
});

export const customerAction = customer.actions;

export default customer.reducer;
