import logo from "../../assets/white-logo.png";
import farmindoLogo from "../../assets/farmindo.svg";
import banner from "../../assets/banner.png"
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation, } from "react-router-dom";
import { Eye, HideEye } from "../../components/icons";
import { CRYPTO_RANDOM, HOVER_PRIMARY, PRIMARY_COLOR } from "../../constant/propertiesConstant";
import { ROUTES } from "../../constant/routeConstant";
import { authorize, verify } from "../../services/authService";
import classes from "./Login.module.css";
import { Box, Button, Center, FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import ModalNotificationWithLoading from "../../components/ModalNotificationWithLoading";
import { useMemo } from "react";
import ObjectUtil from "../../utils/objectUtil";
import { useWithConfirmationActivation } from "../../services/main/sessionReducer";
import { authActions } from "../../reducers/authReducer";

const Login = () => {
  const { is_authenticated, code_pkce, crypto_random } = useSelector((state) => state.auth);
  const { auth_loading } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [hide, setHide] = useState(true);
  const [incorrect, setIncorrect] = useState(false);
  const [username, password] = watch(['username', 'password']);

  const {
    is_submitting: isSubmittingConfirmationActivation,
    handleOnSubmit: handleOnConfirmationActivation,
    error: errorConfirmationActivation
  } = useWithConfirmationActivation();

  const confirmationData = useMemo(() => ({
    activationCode: searchParams.get('activation_code'),
    userId: searchParams.get('user_id'),
    email: searchParams.get('email')
  }), [searchParams]);

  const isConfirmationActivationProcess = useMemo(() => ObjectUtil.isPropertiesTruthy(confirmationData), [confirmationData]);

  useEffect(() => {
    !is_authenticated && authorize(CRYPTO_RANDOM, dispatch);
  }, []);

  useEffect(() => {
    setIncorrect(false);
  }, [username, password]);


  const onSubmit = async (data) => {
    const { ok, response } = await verify(code_pkce, data, dispatch, crypto_random, history);
    if (!ok) {
      if (response) setIncorrect(true);
    }
  };

  useEffect(() => {
    if (isConfirmationActivationProcess && is_authenticated) {
      dispatch(authActions.resetRedux());
      history.replace(`${ROUTES.LOGIN}?${searchParams.toString()}`);
    }
    if (isConfirmationActivationProcess) {
      handleOnConfirmationActivation(confirmationData);
    }
  }, []);

  return is_authenticated ? (
    <Redirect to={ROUTES.HOME} />
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.imgWrapper} style={{ backgroundImage: `url(${banner})`, backgroundSize: "cover", backgroundPosition: "center" }} />
        <div className={classes.formWrapper}>
          <Center>
            <img src={farmindoLogo} alt="logo" width="256px" />
          </Center>
          <div className={classes.formTitle}>
            <span>{t("COMMON:LOGIN_TITLE")}</span>
            <Box width={8} height="3px" backgroundColor={PRIMARY_COLOR} />
          </div>
          <div className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Username */}
              <FormControl marginTop={8} marginBottom={4} isInvalid={errors.username}>
                <FormLabel margin={0} fontWeight={400} fontSize={14} color="#666666">
                  {t("COMMON:FIELD_USERNAME")}
                </FormLabel>
                <Input borderRadius={5} pl={2} type="text" autoComplete="off" {...register("username", { required: true })} variant="flushed" placeholder="Type Your Username" />
              </FormControl>

              {/* Password */}
              <FormControl marginTop={4} marginBottom={8} isInvalid={errors.password}>
                <FormLabel margin={0} fontWeight={400} fontSize={14} color="#666666">
                  {t("COMMON:FIELD_PASSWORD")}
                </FormLabel>
                <InputGroup>
                  <Input
                    borderRadius={5}
                    pl={2}
                    type={hide ? "password" : "text"}
                    autoComplete="off"
                    {...register("password", { required: true })}
                    variant="flushed"
                    placeholder="Type Your Password"
                  />
                  <InputRightElement width="3rem">
                    {/* {hide ? <ViewIcon onClick={() => setHide(!hide)} /> : <ViewOffIcon onClick={() => setHide(!hide)} />} */}
                    {hide ? <Icon as={Eye} onClick={() => setHide(!hide)} /> : <Icon as={HideEye} onClick={() => setHide(!hide)} />}
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              {/* Submit */}
              <div className={classes.formButton}>
                {incorrect && (
                  <div style={{ border: "1px solid #FFD2D2", margin: ".5em 0", borderRadius: "6px", color: "#A10000", padding: "8px" }}>{t("COMMON:ERROR_INCORRECT")}</div>
                )}
                <Button type="submit" width="full" color="white" background={PRIMARY_COLOR} _hover={{ background: HOVER_PRIMARY }} isLoading={auth_loading}>
                  {t("COMMON:BUTTON_LOGIN")}
                </Button>
              </div>

              {/* Forgot Password */}
              <Center>
                <Box fontWeight={400} fontSize={12}>
                  {t("COMMON:BUTTON_FORGOT")}{" "}
                  <Link to={ROUTES.FORGOT_PASSWORD} style={{ textDecoration: "none", color: PRIMARY_COLOR }}>
                    Reset{" "}
                  </Link>
                  here
                </Box>
              </Center>
            </form>
          </div>
        </div>
      </div>
      <ModalNotificationWithLoading
        initialIsOpen={isConfirmationActivationProcess}
        isLoading={isSubmittingConfirmationActivation}
        isSuccess={ObjectUtil.isEmpty(errorConfirmationActivation)}
        successMessage={t('COMMON:SUCCESS_ACTIVATION_USER')}
        errorMessage={errorConfirmationActivation?.message}
        onClose={() => history.push({ pathname: ROUTES.LOGIN, search: '' })}
        buttonText={t('COMMON:BUTTON_CLOSE')}
        loadingMessage={t('COMMON:VERIFICATION_PROCESS')}
      />
    </React.Fragment>
  );

}


export default Login