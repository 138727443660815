import { REGEX_CONSTANT } from "../../../constant/regexConstant";
import * as yup from "yup";

export const addUserProfileSchema = (t) => {
  return () =>
    yup.object().shape({
      nik: yup
        .string()
        .max(
          16,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(16),
          })
        )
        .matches(REGEX_CONSTANT.NIK, t("USER:ERROR_IDENTIFICATION_NUMBER"))
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("USER:FIELD_IDENTIFICATION_NUMBER"),
          })
        ),
      email: yup
        .string()
        .matches(REGEX_CONSTANT.EMAIL, t("USER:ERROR_EMAIL"))
        .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_EMAIL") })),
      person_title_id: yup
        .number()
        .nullable()
        .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_TITLE") })),
      first_name: yup
        .string()
        .max(
          25,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(25),
          })
        )
        .matches(REGEX_CONSTANT.NAME, t("USER:ERROR_FIRST_NAME"))
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_FIRST_NAME") })
        ),
      last_name: yup
        .string()
        .max(
          30,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(30),
          })
        )
        .matches(REGEX_CONSTANT.NAME, t("USER:ERROR_LAST_NAME")),
      sex: yup
        .string()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_GENDER") })
        ),
      phone_code: yup
        .string()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PHONE_CODE") })
        ),
      phone: yup
        .string()
        .matches(REGEX_CONSTANT.PHONE_NUMBER, t("COMMON:ERROR_PHONE"))
        .max(
          15,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: 15,
          })
        )
        .matches(REGEX_CONSTANT.PHONE_NUMBER, t("USER:ERROR_PHONE_NUMBER"))
        .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PHONE") })),
      address: yup
        .string()
        .max(
          100,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(100),
          })
        )
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_ADDRESS") })
        ),
      locale: yup
        .string()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_LOCALE") })
        ),
      country_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_COUNTRY") })
        ),
      // island_id:yup
      // .number(),
      province_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PROVINCE") })
        ),
      district_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_DISTRICT") })
        ),
      sub_district_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_SUB_DISTRICT") })
        ),
      urban_village_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_URBAN_VILLAGE") })
        ),
      postal_code_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_POSTAL_CODE") })
        ),
      hamlet: yup
        .string()
        .min(3, t("USER:ERROR_HAMLET"))
        .max(3, t("USER:ERROR_HAMLET"))
        .matches(REGEX_CONSTANT.INTEGER, t("USER:ERROR_HAMLET")),
      neighbourhood: yup
        .string()
        .min(3, t("USER:ERROR_NEIGHBORHOOD"))
        .max(3, t("USER:ERROR_NEIGHBORHOOD"))
        .matches(REGEX_CONSTANT.INTEGER, t("USER:ERROR_NEIGHBORHOOD")),
      photo: yup
        .mixed()
        .nullable()
        .optional()
        .test({
          name: "testValidImageLogoCompany",
          test: (value, context) => {
            if (Array.isArray(value) && value.length > 0) {
              const photoFile = value[0];
              if (photoFile instanceof File) {
                if (!photoFile.type.match(REGEX_CONSTANT.VALID_IMAGE)) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_FILE"),
                  });
                }
                if (photoFile.size > 5000000) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_MAX_SIZE_MB", { size: 5 }),
                  });
                }
              }
            }
            return true;
          },
        }),
      images: yup
        .mixed()
        .nullable()
        .optional()
        .test({
          name: "testValidImageLogoCompany",
          test: (value, context) => {
            if (Array.isArray(value) && value.length > 0) {
              const photoFile = value[0];
              if (photoFile instanceof File) {
                if (!photoFile.type.match(REGEX_CONSTANT.VALID_IMAGE)) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_FILE"),
                  });
                }
                if (photoFile.size > 5000000) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_MAX_SIZE_MB", { size: 5 }),
                  });
                }
              }
            }
            return true;
          },
        }),
    });
};

export const addLoginAccountSchema = (t) => {
  return () =>
    yup.object().shape({
      username: yup
        .string()
        .matches(REGEX_CONSTANT.USERNAME, t("USER:ERROR_USERNAME"))
        .min(
          6,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: t(6),
          })
        )
        .max(
          20,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(20),
          })
        )
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_USERNAME") })
        ),
      password: yup
        .string()
        .matches(REGEX_CONSTANT.PASSWORD, t("USER:ERROR_PASSWORD"))
        .min(
          8,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: t(8),
          })
        )
        .max(
          50,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(50),
          })
        )
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PASSWORD") })
        ),
      repassword: yup
        .string()
        .matches(REGEX_CONSTANT.PASSWORD, t("USER:ERROR_CONFIRM_PASSWORD"))
        .min(
          8,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: t(8),
          })
        )
        .max(
          50,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(50),
          })
        )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("COMMON:FIELD_CONFIRM_NEW_PASSWORD"),
          })
        )
        .oneOf([yup.ref("password"), null], t("USER:ERROR_PASSWORD_NOT_MATCH")),
    });
};
export const addAccessSettingSchema = (t) => {
  return () =>
    yup.object().shape({
      role_id: yup
        .object()
        .nullable()
        .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_ROLE") }))
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_ROLE") })
            ),
        }),
      group_id: yup
        .object()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_DATA_GROUP") })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_DATA_GROUP") })
            ),
        }),
    });
};
export const editLoginAccountSchema = (t) => {
  return () =>
    yup.object().shape({
      username: yup
        .string()
        .matches(REGEX_CONSTANT.USERNAME, t("USER:ERROR_USERNAME"))
        .min(
          6,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: t(6),
          })
        )
        .max(
          20,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(20),
          })
        )
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_USERNAME") })
        ),
    });
};
export const editActivation = (t) => {
  return () =>
    yup.object().shape({
      status: yup
        .string()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_STATUS") })
        ),
    });
};

export const checkUserSchema = (t) => {
  return () =>
    yup.object().shape({
      nik: yup
        .string()
        .max(16, t("USER:ERROR_IDENTIFICATION_NUMBER"))
        .matches(REGEX_CONSTANT.NIK, t("USER:ERROR_IDENTIFICATION_NUMBER"))
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("USER:FIELD_IDENTIFICATION_NUMBER"),
          })
        ),
      email: yup
        .string()
        .max(
          100,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: t(100),
          })
        )
        .matches(REGEX_CONSTANT.EMAIL, t("USER:ERROR_EMAIL"))
        .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_EMAIL") })),
    });
};
