import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const BodyPreferences = (props) => {
  const { t } = useTranslation();
  const data = props?.data;
  const renderYesNo = (name) => {
    if (name === "activeEmailAlert") {
      let textActiveEmailAlert;
      if (data?.is_active_email_alert) {
        textActiveEmailAlert = "YES";
      } else {
        textActiveEmailAlert = "NO";
      }
      return textActiveEmailAlert;
    }
    if (name === "activeEmailNotification") {
      let textActiveEmailNotification;
      if (data?.is_active_email_notification) {
        textActiveEmailNotification = "YES";
      } else {
        textActiveEmailNotification = "NO";
      }
      return textActiveEmailNotification;
    }
  };

  return (
    <Box padding={1}>
      {/* Active Email Alert */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("DISTRIBUTOR:FIELD_ACTIVE_EMAIL_ALERT")}</Box>
        <Box>
          <Text>
            <b>{renderYesNo("activeEmailAlert")}</b>
          </Text>
        </Box>
      </Box>
      {/* Active Email Notification */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("DISTRIBUTOR:FIELD_ACTIVE_EMAIL_NOTIFICATION")}</Box>
        <Box>
          <Text>
            <b>{renderYesNo("activeEmailNotification")}</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BodyPreferences;
