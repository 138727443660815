import { Box, Stack, Text } from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import { ID_MONITORING } from "../../../../constant/idConstant";
import {
  GRAY_COLOR,
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { selectStatusReProcess } from "../../../../constant/selectOptConstant";
import {
  selectOptFilterPrincipal,
  selectOptFilterUserTheme,
} from "../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../hooks/useCheckUserCategory";
import {
  getInitListPMonitoringPO,
  getListCustomerDDS,
  getListDistributorDDS,
  getListMonitoringPO,
  getListPrincipalDDS,
} from "../../../../services/main/monitoring/monitoringPO";
import StringUtil from "../../../../utils/stringUtil";

const PO = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.monitoringPO.list_po);
  const role = useSelector((state) => state.auth.role);
  const table_loading = useSelector(
    (state) => state.monitoringPO.table_loading
  );
  const { status: statusCustomer } = useSelector((state) => state.customer);
  const { status: statusDistributor } = useSelector(
    (state) => state.distributor
  );
  const { status: statusPrincipal } = useSelector((state) => state.principal);

  const { isAccepted } = useCheckUserCategory([
    userCategoryType.SUPPORT,
    userCategoryType.CUSTOM,
  ]);
  const userPrincipal = useCheckUserCategory([
    userCategoryType.PRINCIPAL,
  ]).isAccepted;

  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [submit, setSubmit] = useState(false);
  const [order, setOrder] = useState("");

  // filter
  const [status, setstatus] = useState(null);
  // filter transactiondate
  const [transactionDate, setTransactionDate] = useState("");
  const [filterTransactionDate, setfilterTransactionDate] = useState("");

  // principal
  const [principalId, setPrincipalId] = useState(1);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);

  // distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(null);

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const principal_id_auth = useSelector((state) => state.auth.principal_id);
  const setFilterTransactionDate = useMemo(() => {
    const [start, end] = transactionDate;
    if (start && end) {
      setfilterTransactionDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterTransactionDate([]);
  }, [transactionDate]);
  const setPrincipal = useMemo(() => {
    if (userPrincipal) {
      return setPrincipalId(principal_id_auth);
    }
    return setPrincipalId(1);
  }, [role]);
  const filterData = StringUtil.formatFilter({
    transaction_date: `range ${filterTransactionDate}`,
    distributor_id: `eq ${idDistributor}`,
    customer_id: `eq ${idCustomer}`,
  });
  const filterCSBuyer = StringUtil.formatFilter({
    transaction_date: `range ${filterTransactionDate}`,
    principal_id: `eq ${principalId}`,
    distributor_id: `eq ${idDistributor}`,
    customer_id: `eq ${idCustomer}`,
    status: `eq ${status}`,
  });

  const filter = isAccepted ? filterCSBuyer : filterData;
  const params = { page, limit, filter, order };
  const fetchData = () => {
    dispatch(getListMonitoringPO(params)).then((response) => {
      if (!response.payload.ok) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  useEffect(() => {
    dispatch(getInitListPMonitoringPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });

    fetchData();
    setSubmit(false);
    setIsDisabled(true);
  }, [page, limit, token, total, order, dispatch, submit]);

  // filter transaction date
  const handleChangeTransactionDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setTransactionDate([start, end]);
    } else setTransactionDate([]);

    setIsDisabled(false);
  };

  // filter
  const [isDisabled, setIsDisabled] = useState(true);

  const submitOk = () => {
    setSubmit(true);
    setPage(1);
  };
  const resetFilter = () => {
    setIsDisabled(true);
    setSubmit(true);
    setstatus(null);
    setidDistributor(null);
    setFilterDistributor("");
    setIdCustomer(null);
    setfilterCustomerName("");
    setFilterPrincipalName("");
    setTransactionDate([]);
    setPage(1);
    setLimit(10);
    if (!userPrincipal) {
      return setPrincipalId(1);
    }
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "principal_id") {
      setPrincipalId(value);
    } else if (name === "select_drop_down") {
      setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
    } else if (name === "customer_id") {
      setIdCustomer(value);
    } else if (name === "distributor_id") {
      setidDistributor(value);
    } else if (name === "status") {
      setstatus(value);
    }
  };

  // const filter = `${selectDropDownFilter.value} like ${filterDataStore}`;
  // const params =
  //   filterDataStore === "" ? { page, limit } : { page, limit, filter };

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    if (statusPrincipal === "success") {
      setPagePrincipal(pagePrincipal + 1);
    }
  };
  // end fungsi princapl

  // fungsi customer
  const handleScrollCustomer = (event) => {
    if (statusCustomer === "success") {
      setPageCustomer(pageCustomer + 1);
    }
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = `distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };

    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    if (statusDistributor === "success") {
      setPageDistributor(pageDistributor + 1);
    }
  };

  const renderFilterButton = () => {
    if (isAccepted) {
      return (
        <>
          <Select
            id={ID_MONITORING.LIST.PRINCIPAL}
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={false}
            placeholder="Select Principal"
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
        </>
      );
    }
  };
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: item.id,
      distributor_name: item.distributor_name,
      po_number: item.po_number,
      store_name: item.store_name,
      transaction_date: moment(item.transaction_date)
        .subtract(7, "hours")
        .format("YYYY-MM-DD"),
      status:
        item.status === "SUCCESS" ? t("COMMON:SUCCESS") : t("COMMON:FAILED"),
      reason: item.reason,
      warehouse_id: item.warehouse_id,
      store_address: item.store_address,
      download_by: item.download_by,
      customer_name: item.customer_name,
      download_time: moment(item.download_time)
        .subtract(7, "hours")
        .format("DD/MM/YYYY"),
    }));

  const columns = [
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_PO:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number.localeCompare(b.po_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_PO:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_PO:FIELD_STORE")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name.localeCompare(b.store_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_PO:FIELD_TRANSACTION_DATE")}
        </Box>
      ),
      dataIndex: "transaction_date",
      key: "transaction_date",
      sorter: (a, b) => a.transaction_date.localeCompare(b.transaction_date),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_PO:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_PO:FIELD_REASON")}
        </Box>
      ),
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <Box p={4}>
      <HeadTitle
        title={t("MONITORING_PO:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_PO:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_PO:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_PO:SUBTITLE_3")}
            </span>{" "}
            - {t("MONITORING_PO:TITLE_LIST")}
          </>
        }
      />

      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* Transaction date */}
          <Box width="240px">
            <InputDateRangePicker
              elementId={ID_MONITORING.LIST.TRX_DATE}
              values={transactionDate}
              onChange={handleChangeTransactionDate}
              placeHolder={t("COMMON:TRANSACTION_DATE")}
            />
          </Box>
          {/* principal */}
          {renderFilterButton()}
          {/* By Distributor */}
          <Select
            id={ID_MONITORING.LIST.DISTRIBUTOR}
            style={selectOptFilterPrincipal}
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By customer */}
          <Select
            id={ID_MONITORING.LIST.CUSTOMER}
            style={selectOptFilterPrincipal}
            size="large"
            value={idCustomer}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("COMMON:SELECT_CUSTOMER")}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By Dwonload Status */}
          <Select
            id={ID_MONITORING.LIST.DOWNLOAD_STATUS}
            style={selectOptFilterPrincipal}
            onChange={(value, opt) => handleChangeSelect("status", value, opt)}
            value={status}
            size="large"
            placeholder={t("COMMON:SELECT_STATUS")}
            allowClear={true}
          >
            {selectStatusReProcess?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <Box>
            <ButtonPrimary
              id={ID_MONITORING.LIST.BTN_SUBMIT}
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_MONITORING.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          scroll={{ y: 400 }}
          onChange={handleTableChange}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_MONITORING.LIST.ROW_PER_PAGE}
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_MONITORING.LIST.NEXT_PAGE}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default PO;
