import { REGEX_CONSTANT } from "../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addSchema = (t) => {
  return () =>
    yup.object().shape({
      role_id: yup
        .string()
        .min(
          3,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: i18next.t(3),
          })
        )
        .max(
          35,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(35),
          })
        )
        .matches(REGEX_CONSTANT.ROLE_ID, i18next.t("ROLE:ERROR_ROLE_ID"))
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("ROLE:FIELD_ROLE_ID"),
          })
        ),
      description: yup
        .string()
        .max(
          256,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(256),
          })
        )
        .matches(
          REGEX_CONSTANT.DESCRIPTION_EXCEPT_UNDERSCORE,
          i18next.t("ROLE:ERROR_DESCRIPTION")
        )
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("ROLE:FIELD_DESCRIPTION"),
          })
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("ROLE:FIELD_STATUS"),
        })
      ),
      level: yup.number().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("ROLE:FIELD_ROLE_LEVEL"),
        })
      ),
    });
};

export const editRoleSchema = (t) => {
  return () =>
    yup.object().shape({
      role_id: yup
        .string()
        .min(
          3,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: i18next.t(3),
          })
        )
        .max(
          35,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(35),
          })
        )
        .matches(REGEX_CONSTANT.ROLE_ID, i18next.t("ROLE:ERROR_ROLE_ID"))
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("ROLE:FIELD_ROLE_ID"),
          })
        ),
      description: yup
        .string()
        .max(
          256,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(256),
          })
        )
        .matches(
          REGEX_CONSTANT.DESCRIPTION_EXCEPT_UNDERSCORE,
          i18next.t("ROLE:ERROR_DESCRIPTION")
        )
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("ROLE:FIELD_DESCRIPTION"),
          })
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("ROLE:FIELD_STATUS"),
        })
      ),
      level: yup.number().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("ROLE:FIELD_ROLE_LEVEL"),
        })
      ),
    });
};
