import farmindo from "../../assets/farmindo.svg";
import banner from "../../assets/banner.png";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Input,
  position,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { MiniSuccess } from "../../components/icons";
import {
  HOVER_PRIMARY,
  PRIMARY_COLOR,
} from "../../constant/propertiesConstant";
import { ROUTES } from "../../constant/routeConstant";
import { forgetPassword } from "../../services/authService";
import classes from "./ForgetPassword.module.css";
import { REGEX_CONSTANT } from "../../constant/regexConstant";

const ForgetPassword = () => {
  const { is_authenticated } = useSelector((state) => state.auth);
  const { auth_loading } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [messageEmail, setMessageEmail] = useState("");

  useEffect(() => {
    setMessageEmail("");
  }, [watch("email")]);
  const onSubmit = async (data) => {
    setIsSuccess(true);
    setEmail(data.email);
    const { ok, response } = await forgetPassword(data, dispatch);
    
    if (ok) {
      setIsSuccess(true);
      setEmail(data.email);
    } else {
    setIsSuccess(false);
        if (response.nexsoft.payload.status.message === "Unknown User data") {
          setMessageEmail(t("COMMON:ERROR_FORGOT"));
        } else {
          setMessageEmail(response.nexsoft.payload.status.message);
        }
    }
  };
  return is_authenticated ? (
    <Redirect to={ROUTES.HOME} />
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <div
          className={classes.imgWrapper}
        // style={{   }}
        >
          <img style={{ 
            height: '100vh', 
            width: '100vw', 
            objectFit: 'cover', 
            backgroundPosition: 'center' }} 
            src={banner} alt="Banner" />
        </div>

        <div className={classes.formWrapper}>
          <Center>
            <img src={farmindo} alt="logo" width="256px" />
          </Center>
          <Box className={classes.formTitle} marginBottom={2}>
            <span>{t("COMMON:FORGOT_TITLE")}</span>
            <Box width={8} height="3px" backgroundColor={PRIMARY_COLOR} />
          </Box>
          <div className={classes.formDescription}>
            <span>{t("COMMON:FORGOT_DESCRIPTION")}</span>
          </div>
          <div className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Email */}
              <Box>
                <FormControl
                  marginTop={8}
                  marginBottom={4}
                  isInvalid={errors.email}
                >
                  <FormLabel
                    margin={0}
                    fontWeight={400}
                    fontSize={14}
                    color="#666666"
                  >
                    {t("COMMON:FIELD_EMAIL")}
                  </FormLabel>
                  <Input
                    autoComplete="off"
                    {...register("email", {
                      required: t("PRODUCT_CUSTOMER:FIELD_EMPTY"),
                      pattern: {
                        value: REGEX_CONSTANT.EMAIL,
                        message: t("USER:ERROR_EMAIL")
                      }
                    })}
                    variant="flushed"
                    placeholder="Type Your Email"
                    name="email"
                  />
                </FormControl>
                {errors.email && (
                  <Box color="#BC2023" marginY={4}>
                    {errors.email.message}
                  </Box>
                )}
              </Box>

              {/* Submit */}
              <div className={classes.formButton}>
                <Button
                  isLoading={auth_loading}
                  type="submit"
                  width="full"
                  color="white"
                  background={PRIMARY_COLOR}
                  _hover={{ background: HOVER_PRIMARY }}
                >
                  {t("COMMON:BUTTON_RESET")}
                </Button>
              </div>
              <div className={classes.buttonCancel}>
                <Link
                  to={ROUTES.HOME}
                  style={{ textDecoration: "none", color: PRIMARY_COLOR }}
                >
                  <Button
                    width="full"
                    colorScheme={PRIMARY_COLOR}
                    variant="outline"
                    _hover={{ background: "white[500]" }}
                  >
                    {t("COMMON:BUTTON_CANCEL")}
                  </Button>
                </Link>
              </div>
            </form>

            {isSuccess && (
              <div className={classes.formMessage}>
                <div className={classes.iconMessage}>
                  <MiniSuccess />
                </div>
                <div className={classes.descMessage}>
                  {t("COMMON:SUCCESS_FORGOT", { email })}
                </div>
              </div>
            )}
            {messageEmail.length > 0 ? !isSuccess && (
              <div className={classes.formErrorMessage}>
                <div className={classes.descMessage}>
                  {messageEmail}
                </div>
              </div>
            ) : <></>}
            
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
