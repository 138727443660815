import {
  Box,
  Text,
  Select as SelectChakra,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  BlueEditIcon,
  RedDeleteIcon,
  DeleteSuccess,
  UnSuccess,
} from "../../../../../components/icons";
import { Redirect, useLocation } from "react-router-dom";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import {
  deleteBklMapping,
  detailBKLMapping,
} from "../../../../../services/main/bklMappingReducer";
import BackToList from "../../../../../components/BackToList";
import moment from "moment/moment";
import { RenderArrayDetail } from "../../../../../function/RenderArrayDetail";
import { RenderStatus } from "../../../../../function/RenderStatus";
import ModalCustom from "../../../../../components/ModalCustom";
import ModalConfirm from "../../../../../components/ModalConfirm";
import { ID_BKL_MAPPING } from "../../../../../constant/idConstant";
const DetailBKLMapping = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [dataDetail, setdataDetail] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const payload = { updated_at: updatedAt };
  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmOpen(false);
  };

  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      bklId: Number(location.state.id),
    };
    dispatch(deleteBklMapping(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };

  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailBKLMapping(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setdataDetail(response.payload.response);
        setUpdatedAt(response.payload.response.updated_at);
      }
    });
    setIsLoading(false);
  };
  useEffectOnce(() => {
    fetchData();
  });
  return isDeleted ? (
    <Redirect to={ROUTES_USER_SELLER.BKL_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("BKL_MAPPING:DETAIL_BKL_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("BKL_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("BKL_MAPPING:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              id={ID_BKL_MAPPING.DETAIL.BTN_EDIT}
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES_USER_SELLER.BKL_MAPPING.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              id={ID_BKL_MAPPING.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.BKL_MAPPING.LIST}
        name={dataDetail?.created_name}
        date={moment(dataDetail?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(dataDetail?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={dataDetail?.updated_name}
        id={ID_BKL_MAPPING.DETAIL.BTN_BACK}
      />
      {/* white box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        justifyContent="space-between"
        display="flex"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box padding={1} width="404px">
          {/* Store Code */}
          <Box padding={1}>
            <Box>{t("BKL_MAPPING:FIELD_STORE_CODE")}</Box>
            <Box>
              <Text>
                <b>{dataDetail?.store_code ? dataDetail?.store_code : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* Area Code */}
          <Box padding={1}>
            <Box>{t("BKL_MAPPING:FIELD_AREA_CODE")}</Box>
            <Box>
              <Text>
                <b>{dataDetail?.area_code ? dataDetail?.area_code : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* Store SAP Id*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_STORE_SAP_ID")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.store_sap_id ? dataDetail?.store_sap_id : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Frequency*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_FREQUENCY")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{dataDetail?.frequency ? dataDetail?.frequency : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* Open PO*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_OPEN_PO")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {RenderArrayDetail(dataDetail?.open_po)}
                  {/* {dataDetail?.open_po?.join(' , ')} */}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Depo Id*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_DEPO_ID")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{dataDetail?.id_depo ? dataDetail?.id_depo : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* status */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_STATUS")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{RenderStatus(dataDetail.status)}</b>
              </Text>
            </Box>
          </Box>
        </Box>
        {/* end of left side  */}

        {/* right side */}
        <Box padding={1} marginRight="7.5rem" width="404px">
          {/* store name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_STORE_NAME")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>{dataDetail?.store_name ? dataDetail?.store_name : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* Distribution centre  */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_DISTRIBUTION_CENTRE")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {dataDetail?.distribution_center
                    ? dataDetail?.distribution_center
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Store SAP name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_STORE_SAP_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.store_sap_name
                    ? dataDetail?.store_sap_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* customer product name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.customer_name ? dataDetail?.customer_name : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* jadwal kirim depo*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("BKL_MAPPING:FIELD_JADWAL_KIRIM_DEPO")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{RenderArrayDetail(dataDetail?.jwk_depo)}</b>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
        idYes={ID_BKL_MAPPING.DETAIL.BTN_YES}
        idNo={ID_BKL_MAPPING.DETAIL.BTN_NO}
      />
    </Box>
  );
};

export default DetailBKLMapping;
