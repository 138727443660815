// import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addCopyMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      distributor_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PROCCESS_APPROVE_PO:FIELD_DISTRIBUTOR"),
          })
        ),
      store_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("COMMON:FIELD_STORE"),
          })
        ),
      store_id_target: yup
        .array()
        .of(
          yup.object().shape({
            // Definisikan validasi untuk objek di dalam array di sini
            // Contoh: Jika objek harus memiliki properti 'name' dan 'address' sebagai string:
            value: yup.string().required('Name is required'),
            index: yup.string().required('Name is required'),
            code: yup.string().required('Name is required'),
            label: yup.string().required('Name is required'),
          })
        )
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("COMMON:FIELD_STORE"),
          })
        )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("COMMON:FIELD_STORE"),
          })
        ),
    });
};
