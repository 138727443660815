import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  table_loading: false,
  table_loading_detail : false,
  list_distributor: [],
  list_customer: [],
  list_store: [],
  list_principal: [],
  status: "",
  selectedRowKeys: [],
  ids: [],
};

export const getInitiateListBKLSummary = createAsyncThunk(
  "getInitiateListBKLSummary",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);

export const getListBKLSummary = createAsyncThunk(
  "getListBKLSummary",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);
export const detailBKLSummary = createAsyncThunk(
  "detailBKLSummary",
  async (id, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary/${id}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailInitiateListBKLSummary = createAsyncThunk(
  "detailInitiateListBKLSummary",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const id = object?.id;
    const params = object?.params;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary/detailList/initiateGetList/${id}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailListBKLSummary = createAsyncThunk(
  "detailListBKLSummary",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const id = object?.id;
    const params = object?.params;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary/detailList/${id}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const downloadPDFBklSummary = createAsyncThunk(
  "downloadPDFBklSummary",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary/pdf`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPostResponseBlob(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const downloadBklSummary = createAsyncThunk(
  "downloadBklSummary",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-summary`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPostResponseBlob(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListBKLMapping = createAsyncThunk(
  "getListBKLmapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStore = createAsyncThunk(
  "getListStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const bklSummary = createSlice({
  name: "bklSummary",
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSingleCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      const isExist = state.selectedRowKeys?.includes(action.payload);
      if (isExist) {
        tempSelected = tempSelected.filter((id) => id !== action.payload);
        state.selectedRowKeys = tempSelected;
        return;
      }
      state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
    },
    setAllCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      let tempsId = action.payload;
      let isExist = false;

      for (const data of tempsId) {
        isExist = false;
        for (const existingId of state.selectedRowKeys) {
          if (data === existingId) {
            isExist = true;
            break;
          }
        }
        if (!isExist) break;
      }

       if (isExist) {
        for (const data of tempsId) {
          tempSelected = tempSelected.filter((id) => id !== data);
          state.selectedRowKeys = tempSelected;
        }
      } else {
        for (const data of tempsId) {
          const isExistOnRedux = state.selectedRowKeys?.includes(data);
          if (!isExistOnRedux) {
            state.selectedRowKeys = [...state.selectedRowKeys, data];
          }
        }
        // state.selectedRowKeys = Array.from(new Set([...state.selectedRowKeys, ...tempsId]));
      }
    },
    resetReduxBklSummary() {
      return INITIAL_STATE;
    },
    resetCBX(state) {
      state.selectedRowKeys = [];
    },
  },
  extraReducers: {
    // bkl summary
    [getInitiateListBKLSummary.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListBKLSummary.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListBKLSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListBKLSummary.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
      state.list = [];
    },
    [getListBKLSummary.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading = false;
    },
    [getListBKLSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.list = [];
      state.table_loading = true;
      state.errorNotification = action.payload;
    },

    [detailListBKLSummary.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading_detail = true;
    },
    [detailListBKLSummary.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading_detail = false;
    },
    [detailListBKLSummary.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading_detail = false;
    },

    [downloadPDFBklSummary.pending]: (state, action) => {
      state.status = "loading";
    },
    [downloadPDFBklSummary.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [downloadPDFBklSummary.rejected]: (state, action) => {
      state.status = "failed";
    },

    [downloadBklSummary.pending]: (state, action) => {
      state.status = "loading";
    },
    [downloadBklSummary.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [downloadBklSummary.rejected]: (state, action) => {
      state.status = "failed";
    },

    // bkl mapping
    [getListBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getListBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    // distributor
    [getListDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_distributor = action.payload.response;
    },
    [getListDistributor.rejected]: (state, action) => {
      state.status = "failed";
    },

    // store
    [getListStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_store = action.payload.response;
    },
    [getListStore.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const bklSummaryAction = bklSummary.actions;

export default bklSummary.reducer;
