import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import FileSaver from "file-saver";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import {
  API_URL,
  API_VERSION,
  API_PREFIX,
} from "../../../constant/apiConstant";

const INITIAL_STATE = {
  list_sftpLog: [],
  table_loading: false
};

export const getListSftpLog = createAsyncThunk(
  "pooutgoing/list",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/pooutgoing/list`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doGet(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitListSftpLog = createAsyncThunk(
  "pooutgoing/initiate",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/pooutgoing/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);


const sftpLog = createSlice({
  name: "createMonitoringProduct",
  initialState: INITIAL_STATE,
  reducers: {
    
    resetRedux() {
      return INITIAL_STATE;
    },
    
  },
  extraReducers: {
    [getListSftpLog.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListSftpLog.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_sftpLog = action.payload.response;
    },
    [getListSftpLog.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
  },
});

export const sftpLogReducerAction = sftpLog.actions;

export default sftpLog.reducer;
