import {
  Box, Text
} from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_DATA_GROUP } from "../../../../../constant/idConstant";
import { ROUTES } from "../../../../../constant/routeConstant";
import {
  selectLevel,
  selectStatus
} from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import { dataGroupAction } from "../../../../../services/main/dataGroupReducer";
import { addDataGroupShema } from "../../dataGroupSchema";
const BodyDataGroup = () => {
  const { t } = useTranslation();
  const { data_group } = useSelector((state) => state.dataGroup);
  const { index_page } = useSelector((state) => state.dataGroup);
  const dispatch = useDispatch();
  // const handleChange2 = (event) => {
  //   let names = event.target.name;
  //   dispatch(
  //     dataGroupAction.setDataGroup({
  //       ...data_group,
  //       [names]: event.target.value,
  //     })
  //   );
  // };
  const onSubmit = () => {
    dispatch(dataGroupAction.setCurrPage(t("DATA_GROUP:DATASCOPE")));
    dispatch(dataGroupAction.setIndexPage(index_page + 1));
    dispatch(dataGroupAction.setDataGroup(values));
  };
  const {
    values,
    errors,
    isValid,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      group_id: data_group.group_id,
      description: data_group.description,
      status: data_group.status,
      level: data_group.level,
    },
    validationSchema: addDataGroupShema(t),
    onSubmit,
    // isInitialValid: true,
    enableReinitialize: true,
    isInitialValid: data_group.group_id !== null ? true : false,
  });

  const handleChangeSelect = (name, value) => {
    setFieldValue(name, value);
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="350px"
        padding={4}
        borderRadius="10px"
      >
        {/* Data Group Id  */}
        <Box padding={1} display="flex">
          <Box width="10rem" marginTop={2}>
            {t("DATA_GROUP:FIELD_DATA_GROUP_ID")}
          </Box>
          <Box>
            <Input
              type="text"
              style={inputTheme}
              onBlur={handleBlur}
              id={ID_DATA_GROUP.ADD.DATA_GROUP_ID}
              size="large"
              onChange={handleChange}
              name="group_id"
              value={values?.group_id}
            />
            {errors.group_id && touched.group_id && (
              <ValidationError text={errors.group_id} />
            )}
          </Box>
        </Box>
        {/* Description  */}
        <Box padding={1} display="flex">
          <Box width="10rem" marginTop={2}>
            {t("DATA_GROUP:FIELD_DESCRIPTION")}
          </Box>
          <Box>
            <Input
              type="text"
              onBlur={handleBlur}
              id={ID_DATA_GROUP.ADD.DESCRIPTION}
              size="large"
              style={inputTheme}
              onChange={handleChange}
              name="description"
              value={values?.description}
            />
            {errors.description && touched.description && (
              <ValidationError text={errors.description} />
            )}
          </Box>
        </Box>
        {/* Status */}
        <Box padding={1} display="flex">
          <Box width="10rem">
            <Text>{t("DATA_GROUP:FIELD_STATUS")}</Text>
          </Box>
          <Box>
            <Select
              value={values?.status}
              style={inputTheme}
              name="status"
              id={ID_DATA_GROUP.ADD.STATUS}
              size="large"
              onChange={(value, opt) =>
                handleChangeSelect("status", value, opt)
              }
            >
              {selectStatus.map((value, index) => {
                return (
                  <option value={value.value} key={index}>
                    {t(value.label)}
                  </option>
                );
              })}
            </Select>
            {errors.status && touched.status && (
              <ValidationError text={errors.status} />
            )}
          </Box>
        </Box>
        {/* Level */}
        <Box padding={1} display="flex">
          <Box width="10rem">
            <Text> {t("DATA_GROUP:FIELD_LEVEL")}</Text>
          </Box>
          <Box>
            <Select
              value={values?.level}
              onBlur={handleBlur}
              id={ID_DATA_GROUP.ADD.LEVEL}
              style={inputTheme}
              name="level"
              size="large"
              onChange={(value, opt) => handleChangeSelect("level", value, opt)}
            >
              {selectLevel.map((value) => {
                return <option value={value}>{value}</option>;
              })}
            </Select>
            {errors.level && touched.level && (
              <ValidationError text={errors.level} />
            )}
          </Box>
        </Box>
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          link={ROUTES.DATA_GROUP.LIST}
          onClick={() => dispatch(dataGroupAction.resetReduxDataGroup())}
          text={t("COMMON:BUTTON_CANCEL")}
          id={ID_DATA_GROUP.ADD.BTN_CANCEL}
        />
        <ButtonPrimary
          text={t("COMMON:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          id={ID_DATA_GROUP.ADD.BTN_SAVE}
          // isDisabled={!isValid}
        />
      </Box>
    </form>
  );
};
export default BodyDataGroup;
