import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ID_NOTIFICATION_MASTER } from "../../../../constant/idConstant";
import {
  PRIMARY_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import {
  selectDropDownCategory,
  selectDropDownSubCategory,
  selectStatus
} from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import { getListDistributor } from "../../../../services/main/distributorReducer";
import { addNotification, getListDistributorDDS, getListPrincipalDDS } from "../../../../services/main/notificationReducer";
import { getListPrincipal } from "../../../../services/main/principalReducer";
import { addSchema } from "./NotificationSchema";

const AddNotification = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const dispatch = useDispatch();

  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(null);

  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [idPrincipal, setidPrincipal] = useState(null);

  const onSubmit = (values, action) => {
    dispatch(addNotification(values)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
        action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };
  // modal success or failed
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.NOTIFICATION.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  // Formik
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      category: "",
      sub_category: "",
      notification_code: "",
      name: "",
      principal_id: "",
      distributor_id: "",
      notification_po_to: "",
      notification_po_cc: "",
      alert_to: "",
      alert_cc: "",
      status: "",
    },
    validationSchema: addSchema,
    onSubmit,
  });

  const handleChangeSelect = (name, value, option) => {
    if (name === "distributor_id") {
      setFieldValue(name, value);
      setidDistributor(value);
    } else if (name === "principal_id") {
      setFieldValue(name, value);
      setidPrincipal(value);
    } else if (name === "category") {
      setFieldValue(name, value);
      setFieldTouched("sub_category", false, false);
      setFieldValue("notification_po_to", "");
      setFieldValue("notification_po_cc", "");
      setFieldValue("alert_to", "");
      setFieldValue("alert_cc", "");
      setFieldValue("sub_category", "");
    } else setFieldValue(name, value);
  };

  // start fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = `name like ${filterPrincipalName}`;
    const params =
      filterPrincipalName === "" ? { page, limit } : { page, limit, filter };

    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };

  const dataPrincipalCode = useMemo(() => {
    return dataPrincipal?.find((search) => search.id === idPrincipal);
  }, [idPrincipal]);
  // end fungsi notification 

  // start fungsi distributor
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = "principal_id eq " + idPrincipal;
    const params =
      idPrincipal === null ? { page, limit } : { page, limit, filter };
    if (idPrincipal === null) {
      setDataDistributor("");
    } else {
      dispatch(getListDistributorDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataDistributor([...dataDistributor, ...resp]);
      });
    }
  };

  const fetchDistributorPrincipal = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = "principal_id eq " + idPrincipal;
    const params =
      idPrincipal === null ? { page, limit } : { page, limit, filter };
    if (idPrincipal === null) {
      setDataDistributor("");
    } else {
      dispatch(getListDistributorDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        setDataDistributor("");
        setPageDistributor(1);
        setDataDistributor(response.payload.response);
      });
    }
  };
  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filterPrincipal = "principal_id eq " + idPrincipal;
    const filter = `${filterPrincipal} , distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };

    if (idPrincipal === null) {
      setDataDistributor("");
    } else {
      dispatch(getListDistributorDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageDistributor(1);
        setDataDistributor(response.payload.response);
      });
    }
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorPrincipal();
  }, [idPrincipal]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };

  const dataDistributorCode = useMemo(() => {
    // find distributor code by selected distributor_id
    if (dataDistributor.length > 0) {
      return dataDistributor?.find((search) => search.id === idDistributor);
    }
  }, [idDistributor]);
  //   end parameter distributor

  const renderSuccess = () => {
    if (values.category === "SUCCESS") {
      return (
        <>
          {/* FIELD_NOTIFICATION_PO_TO */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_PO_TO")}</Text>
            </Box>
            <Box className="form-input-padding-bottom" pb={1}>
              <Input
                value={values.notification_po_to}
                name="notification_po_to"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.ADD.EMAIL_PO_TO}
              />
              {errors.notification_po_to && touched.notification_po_to && (
                <ValidationError text={errors.notification_po_to} />
              )}
            </Box>
          </Box>
          {/* notification po cc */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_PO_CC")}</Text>
            </Box>
            <Box className="form-input-padding-bottom" pb={1}>
              <Input
                value={values.notification_po_cc}
                name="notification_po_cc"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.ADD.EMAIL_PO_CC}
              />
              {errors.notification_po_cc && touched.notification_po_cc && (
                <ValidationError text={errors.notification_po_cc} />
              )}
            </Box>
          </Box>
        </>
      );
    }
  };

  const renderFailed = () => {
    if (values.category === "FAILED") {
      return (
        <>
          {/* email alert to */}
          <Box padding={1} display="flex">
            <Box width="10rem" className="form-title">
              <Text>{t("NOTIFICATION:FIELD_EMAIL_ALERT_TO")}</Text>
            </Box>
            <Box className="form-input-padding-bottom" pb={1}>
              <Input
                value={values.alert_to}
                name="alert_to"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.EDIT.EMAIL_ALERT_TO}
              />
              {errors.alert_to && touched.alert_to && (
                <ValidationError text={errors.alert_to} />
              )}
            </Box>
          </Box>
          {/* alert cc */}
          <Box padding={1} display="flex">
            <Box width="10rem" className="form-title">
              <Text>{t("NOTIFICATION:FIELD_EMAIL_ALERT_CC")}</Text>
            </Box>
            <Box className="form-input-padding-bottom">
              <Input
                value={values.alert_cc}
                name="alert_cc"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.EDIT.EMAIL_ALERT_CC}
              />
              {errors.alert_cc && touched.alert_cc && (
                <ValidationError text={errors.alert_cc} />
              )}
            </Box>
          </Box>
        </>
      );
    }
  };
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("NOTIFICATION:ADD_NOTIFICATION")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_3")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:TITLE_LIST")}
            </span>{" "}
            - {t("NOTIFICATION:SUBTITLE_4")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          // justifyContent="space-between"
          display="flex"
          flex-direction="column"
          align-items="flex-end"
          gap="20px"
          marginTop={4}
          borderRadius={10}
        >
          {/* left side */}
          <Box
            padding={1}
            width="402px"
            flexDirection="column"
            alignItems="flex-start"
          >
            {/* category */}
            <Box
              padding={1}
              width="402px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="28px"
            >
              <Box className="form-title">
                <Text width="92px">{t("NOTIFICATION:FIELD_CATEGORY")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={{ width: 250, borderRadius: "6px" }}
                  value={values.category === "" ? null : values.category}
                  onChange={(value, opt) =>
                    handleChangeSelect("category", value, opt)
                  }
                  allowClear="true"
                  placeholder="Select Category"
                  id={ID_NOTIFICATION_MASTER.ADD.NOTIFICATION_CATGEORY}
                  size="large"
                >
                  {selectDropDownCategory?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.category && touched.category && (
                  <ValidationError text={errors.category} />
                )}
              </Box>
            </Box>
            {/* sub category */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("NOTIFICATION:FIELD_SUB_CATEGORY")}
                </Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  disabled={values.category === "FAILED" ? false : true}
                  style={inputTheme}
                  value={values.sub_category}
                  onChange={(value, opt) =>
                    handleChangeSelect("sub_category", value, opt)
                  }
                  allowClear="true"
                  id={ID_NOTIFICATION_MASTER.EDIT.NOTIFICATION_SUB_CATEGORY}
                  size="large"
                >
                  {selectDropDownSubCategory.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.value}>
                        {t(data.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.sub_category && touched.sub_category && (
                  <ValidationError text={errors.sub_category} />
                )}
              </Box>
            </Box>
            {/* principal code*/}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  name="barcode"
                  onChange={handleChange}
                  value={
                    dataPrincipalCode ? dataPrincipalCode.principal_code : ""
                  }
                  style={inputTheme}
                  type="text"
                  id={ID_NOTIFICATION_MASTER.EDIT.PRINCIPAL_CODE}
                />
              </Box>
            </Box>

            {/* distributor code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_DISTRIBUTOR_CODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  value={
                    dataDistributorCode
                      ? dataDistributorCode.distributor_code
                      : ""
                  }
                  name="store_id"
                  onChange={handleChange}
                  style={inputTheme}
                  onBlur={handleBlur}
                  id={ID_NOTIFICATION_MASTER.EDIT.DISTRIBUTOR_CODE}
                />
              </Box>
            </Box>

            {/* Notification Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("NOTIFICATION:FIELD_NOTIFICATION_CODE")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.notification_code}
                  name="notification_code"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_NOTIFICATION_MASTER.EDIT.NOTIFICATION_CODE}
                />
                {errors.notification_code && touched.notification_code && (
                  <ValidationError text={errors.notification_code} />
                )}
              </Box>
            </Box>
            {renderSuccess()}
            {renderFailed()}
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  width="250px"
                  id={ID_NOTIFICATION_MASTER.EDIT.STATUS}
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={inputTheme}
                  value={values.principal_id}
                  onPopupScroll={handleScrollPrincipal}
                  onChange={(value, opt) =>
                    handleChangeSelect("principal_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setFilterPrincipalName(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear="true"
                  placeholder="Select Principal"
                  id={ID_NOTIFICATION_MASTER.EDIT.PRINCIPAL_NAME}
                  size="large"
                >
                  {dataPrincipal &&
                    dataPrincipal.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data.id}>
                          {data.company_profile_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.principal_id && touched.principal_id && (
                  <ValidationError text={errors.principal_id} />
                )}
              </Box>
            </Box>
            {/* distributor name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_DISTRIBUTOR_NAME")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  style={inputTheme}
                  value={values.distributor_id}
                  onPopupScroll={handleScrollDistributor}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setFilterDistributor(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear="true"
                  placeholder="Select Distributor"
                  id={ID_NOTIFICATION_MASTER.EDIT.DISTRIBUTOR_NAME}
                  size="large"
                >
                  {dataDistributor &&
                    dataDistributor.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && touched.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>
            {/* notification name */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2} className="form-title">
                <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_NAME")}</Text>
              </Box>
              <Box className="form-input-padding-bottom" pb={1}>
                <Input
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_NOTIFICATION_MASTER.EDIT.NOTIFICATION_NAME}
                />
                {errors.name && touched.name && (
                  <ValidationError text={errors.name} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_BUYER.NOTIFICATION.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_NOTIFICATION_MASTER.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_NOTIFICATION_MASTER.EDIT.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default AddNotification;
