import {
  Box,
  Icon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { useState} from "react";
import { Eye, HideEye } from "../../../../../components/icons";
import { useSelector, useDispatch } from "react-redux";
import { userAction } from "../../../../../services/main/userReducer";
import { inputTheme } from "../../../../../constant/themeConstant";
import "../../../../../css/GlobalAntd.css";
import { useFormik } from "formik";
import { addLoginAccountSchema } from "../../userSchema";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { GRAY_COLOR, WHITE_COLOR } from "../../../../../constant/propertiesConstant";
import { ID_USER } from "../../../../../constant/idConstant";
const BodyLoginAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const { login_account } = useSelector((state) => state.user);
  const { username_auth } = useSelector((state)=>state.user);
  const { index_page } = useSelector((state) => state.user);

  const toPreviousPage = () => {
    // set page 
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_USER_PROFILE")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1))
  };
  const onSubmit = (values) => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACCESS_SETTING")));
    // set index page 
    dispatch(userAction.setIndexPage(index_page + 1))
    dispatch(userAction.setLoginAccount(values));
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      username: username_auth !== '' ? username_auth : login_account.username,
      password: login_account.password,
      repassword: login_account.password,
    },
    validationSchema: addLoginAccountSchema(t),
    onSubmit,
    isInitialValid: login_account.username !== '' ? true : false,
  });
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* Username  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("COMMON:FIELD_USERNAME")}
          </Box>
          <Box className="my-select-container">
            <Input
              type="text"
              width="250px"
              id={ID_USER.ADD.USERNAME}
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="false"
              style={inputTheme}
              disabled={username_auth !== '' ? true : false}
              backgroundColor={username_auth !== '' ? GRAY_COLOR : WHITE_COLOR}
              value={values?.username}
            />
            {errors.username && touched.username && (
              <ValidationError text={errors.username} />
            )}
          </Box>
        </Box>
        {/* Password  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("COMMON:FIELD_PASSWORD")}
          </Box>
          <Box className="my-select-container">
            <InputGroup width="250px">
              <Input
                onBlur={handleBlur}
                type={hide ? "password" : "text"}
                autoComplete="false"
                id={ID_USER.ADD.PASSWORD}
                name="password"
                onChange={handleChange}
                style={inputTheme}
                value={values?.password}
              />
              <InputRightElement width="3rem">
                {hide ? (
                  <Icon as={Eye} onClick={() => setHide(!hide)} />
                ) : (
                  <Icon as={HideEye} onClick={() => setHide(!hide)} />
                )}
              </InputRightElement>
            </InputGroup>
            {errors.password && touched.password && (
              <ValidationError text={errors.password} />
            )}
          </Box>
        </Box>
        {/*Confirm Password  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("COMMON:FIELD_CONFIRM_NEW_PASSWORD")}
          </Box>
          <Box className="my-select-container">
            <InputGroup width="250px">
              <Input
                onBlur={handleBlur}
                type={hideConfirmPassword ? "password" : "text"}
                autoComplete="off"
                value={values?.repassword}
                name="repassword"
                onChange={handleChange}
                style={inputTheme}
              />
              <InputRightElement width="3rem">
                {hideConfirmPassword ? (
                  <Icon
                    as={Eye}
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  />
                ) : (
                  <Icon
                    as={HideEye}
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {errors.repassword && touched.repassword && (
              <ValidationError text={errors.repassword} />
            )}
          </Box>
        </Box>
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          onClick={toPreviousPage}
          type="button"
          id={ID_USER.ADD.BTN_PREVIOUS}
          text={t("USER:BUTTON_PREVIOUS")}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          id={ID_USER.ADD.BTN_NEXT}
        />
      </Box>
    </form>
  );
};

export default BodyLoginAccount;
