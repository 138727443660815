import i18next from "i18next";
// import * as ActionType from "../actions/actionType";
import * as ActionRole from "../actions/actionRole";

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = { 
  "User:Insert": true,
  "User:Update_Own": true,
  "User:Delete_Own": true,
  "User:View_Own": true,

  //level1
  "Master:Insert": true,
  //level2
  "Master.Product:Insert": true,
  "Master.Mapping:Insert": true,
  "Master.BranchDistributor:Insert": true,
  "Master.Warehouse:Insert": true,
  //level3
  "Master.Product.Product_Principal:Insert": true,
  "Master.Product.Product_Customer:Insert": true,
  "Master.Product.Product_Category:Insert": true,
  "Master.Mapping.Product_Mapping:Insert": true,
  "Master.Mapping.BKL_Mapping:Insert": true,
  "Master.Mapping.BKL_Product_Mapping:Insert": true,
  "Master.Mapping.Product_Category_Mapping:Insert": true,
  "Master.Mapping.Product_Mapping_HO:Insert": true,
  "Master.Mapping.Product_Substitute:Insert": true,



  "Master:Update": true,
  "Master:Update Own": true,
  "Master:Delete ": true,
  "Master:Delete Own": true,
  "Master:View": true,
  "Master:View Own": true,

  
  "Master.Product:Update": true,
  "Master.Product:Update Own": true,
  "Master.Product:Delete": true,
  "Master.Product:Delete Own": true,
  "Master.Product:View": true,
  "Master.Product:View Own": true,

  
  "Master.Product.Product_Principal:Update": true,
  "Master.Product.Product_Principal:Update Own": true,
  "Master.Product.Product_Principal:Delete": true,
  "Master.Product.Product_Principal:Delete Own": true,
  "Master.Product.Product_Principal:View": true,
  "Master.Product.Product_Principal:View Own": true,

  
  "Master.Product.Product_Customer:Update": true,
  "Master.Product.Product_Customer:Update Own": true,
  "Master.Product.Product_Customer:Delete": true,
  "Master.Product.Product_Customer:Delete Own": true,
  "Master.Product.Product_Customer:View": true,
  "Master.Product.Product_Customer:View Own": true,

  
  "Master.Product.Product_Category:Update": true,
  "Master.Product.Product_Category:Update Own": true,
  "Master.Product.Product_Category:Delete": true,
  "Master.Product.Product_Category:Delete Own": true,
  "Master.Product.Product_Category:View": true,
  "Master.Product.Product_Category:View Own": true,

  
  "Master.Mapping:Update": true,
  "Master.Mapping:Update Own": true,
  "Master.Mapping:Delete": true,
  "Master.Mapping:Delete Own": true,
  "Master.Mapping:View": true,
  "Master.Mapping:View Own": true,

  
  "Master.Mapping.Product_Mapping_HO:Update": true,
  "Master.Mapping.Product_Mapping_HO:Update Own": true,
  "Master.Mapping.Product_Mapping_HO:Delete": true,
  "Master.Mapping.Product_Mapping_HO:Delete Own": true,
  "Master.Mapping.Product_Mapping_HO:View": true,
  "Master.Mapping.Product_Mapping_HO:View Own": true,

  "Master.Mapping.Product_Mapping:Update": true,
  "Master.Mapping.Product_Mapping:Update Own": true,
  "Master.Mapping.Product_Mapping:Delete": true,
  "Master.Mapping.Product_Mapping:Delete Own": true,
  "Master.Mapping.Product_Mapping:View": true,
  "Master.Mapping.Product_Mapping:View Own": true,

  
  "Master.Mapping.BKL_Mapping:Update": true,
  "Master.Mapping.BKL_Mapping:Update Own": true,
  "Master.Mapping.BKL_Mapping:Delete": true,
  "Master.Mapping.BKL_Mapping:Delete Own": true,
  "Master.Mapping.BKL_Mapping:View": true,
  "Master.Mapping.BKL_Mapping:View Own": true,

  
  "Master.Mapping.BKL_Product_Mapping:Update": true,
  "Master.Mapping.BKL_Product_Mapping:Update Own": true,
  "Master.Mapping.BKL_Product_Mapping:Delete": true,
  "Master.Mapping.BKL_Product_Mapping:Delete Own": true,
  "Master.Mapping.BKL_Product_Mapping:View": true,
  "Master.Mapping.BKL_Product_Mapping:View Own": true,

  
  "Master.Mapping.Product_Category_Mapping:Update": true,
  "Master.Mapping.Product_Category_Mapping:Update Own": true,
  "Master.Mapping.Product_Category_Mapping:Delete": true,
  "Master.Mapping.Product_Category_Mapping:Delete Own": true,
  "Master.Mapping.Product_Category_Mapping:View": true,
  "Master.Mapping.Product_Category_Mapping:View Own": true,

  
  "Master.Mapping.Product_Substitute:Update": true,
  "Master.Mapping.Product_Substitute:Update Own": true,
  "Master.Mapping.Product_Substitute:Delete": true,
  "Master.Mapping.Product_Substitute:Delete Own": true,
  "Master.Mapping.Product_Substitute:View": true,
  "Master.Mapping.Product_Substitute:View Own": true,

  
  "Master.BranchDistributor:Update": true,
  "Master.BranchDistributor:Update Own": true,
  "Master.BranchDistributor:Delete": true,
  "Master.BranchDistributor:Delete Own": true,
  "Master.BranchDistributor:View": true,
  "Master.BranchDistributor:View Own": true,


  "Master.Warehouse:Update": true,
  "Master.Warehouse:Update Own": true,
  "Master.Warehouse:Delete": true,
  "Master.Warehouse:Delete Own": true,
  "Master.Warehouse:View": true,
  "Master.Warehouse:View Own": true,

  "Transaction:Insert": true,
  "Transaction:Update": true,
  "Transaction:Delete": true,
  "Transaction:View": true,

  "Transaction.PO:Insert": true,
  "Transaction.PO:Update": true,
  "Transaction.PO:Delete": true,
  "Transaction.PO:View": true,

  "Transaction.PO.Proccess_Approve_PO:Insert": true,
  "Transaction.PO.Proccess_Approve_PO:Update": true,
  "Transaction.PO.Proccess_Approve_PO:Delete": true,
  "Transaction.PO.Proccess_Approve_PO:View": true,

  "Transaction.PO.BKL_Summary:Insert": true,
  "Transaction.PO.BKL_Summary:Update": true,
  "Transaction.PO.BKL_Summary:Delete": true,
  "Transaction.PO.BKL_Summary:View": true,


  
};

const permissionSlice = createSlice({
  name:'posts',
  initialState:INITIAL_STATE,
  reducers:{
    setButton(state,action){
      let stateToChange = action.payload.payload
      let stateValue = state[action.payload.payload]
      //state[stateToChange] = !stateValue

      let splitNameOfAction = stateToChange.split(':')[0]
      let parent = splitNameOfAction.split('.')[0]
      
      switch(parent) {
        case "User":
          setUser(state,stateToChange,stateValue)
          break;
        case "Master":
          setMaster(state,stateToChange,stateValue)
          break;
        case "Transaction":
          setTransaction(state,stateToChange,stateValue)
          break;
        default:
          // code block
      } 

    }
  },
  extraReducers:{
    // ['auth/resetRedux']:(state,action)=>{
    //   return INITIAL_STATE
    // }
  }
})

export const setUser=(state,stateToChange,stateValue)=>{
  state[stateToChange] = !stateValue
}

export const setMaster=(state,stateToChange,stateValue)=>{
  let newStateValue = !stateValue

  let level = stateToChange.split('.').length
    
  let splitNameOfAction = stateToChange.split(':')[0]
   
  let splitAction = stateToChange.split(':')[1]
   let parent = splitNameOfAction.split('.')[0]
 
  if(level == 1){
    state[stateToChange] = newStateValue
    state[`Master.Product:${splitAction}`] = newStateValue
    state[`Master.Mapping:${splitAction}`] = newStateValue
    state[`Master.BranchDistributor:${splitAction}`] = newStateValue
    state[`Master.Warehouse:${splitAction}`] = newStateValue
    state[`Master.Product.Product_Principal:${splitAction}`] = newStateValue
    state[`Master.Product.Product_Customer:${splitAction}`] = newStateValue
    state[`Master.Product.Product_Category:${splitAction}`] = newStateValue
    state[`Master.Mapping.Product_Mapping_HO:${splitAction}`] = newStateValue
    state[`Master.Mapping.Product_Mapping:${splitAction}`] = newStateValue
    state[`Master.Mapping.BKL_Mapping:${splitAction}`] = newStateValue
    state[`Master.Mapping.BKL_Product_Mapping:${splitAction}`] = newStateValue
    state[`Master.Mapping.Product_Category_Mapping:${splitAction}`] = newStateValue
    state[`Master.Mapping.Product_Substitute:${splitAction}`] = newStateValue
  }

  if(level == 2){
    if(splitNameOfAction == "Master.Product"){
      state[stateToChange] = newStateValue
      state[`Master.Product.Product_Principal:${splitAction}`] = newStateValue
      state[`Master.Product.Product_Customer:${splitAction}`] = newStateValue
      state[`Master.Product.Product_Category:${splitAction}`] = newStateValue
    }

    else if(splitNameOfAction == "Master.Mapping"){
      state[stateToChange] = newStateValue
      state[`Master.Mapping.Product_Mapping_HO:${splitAction}`] = newStateValue
      state[`Master.Mapping.Product_Mapping:${splitAction}`] = newStateValue
      state[`Master.Mapping.BKL_Mapping:${splitAction}`] = newStateValue
      state[`Master.Mapping.BKL_Product_Mapping:${splitAction}`] = newStateValue
      state[`Master.Mapping.Product_Category_Mapping:${splitAction}`] = newStateValue
      state[`Master.Mapping.Product_Substitute:${splitAction}`] = newStateValue
    }

    else{
      state[stateToChange] = newStateValue
    }

    if(state[`Master.Product:${splitAction}`] == newStateValue &&
      state[`Master.Mapping:${splitAction}`] == newStateValue &&
      state[`Master.BranchDistributor:${splitAction}`] == newStateValue &&
      state[`Master.Warehouse:${splitAction}`] == newStateValue){
      state[`${parent}:${splitAction }`] = newStateValue
    }

  }

  if(level == 3){
    state[stateToChange] = newStateValue
    if(state[`Master.Mapping.Product_Mapping_HO:${splitAction}`] == newStateValue &&
    state[`Master.Mapping.Product_Mapping:${splitAction}`] == newStateValue &&
    state[`Master.Mapping.BKL_Mapping:${splitAction}`] == newStateValue &&
    state[`Master.Mapping.BKL_Product_Mapping:${splitAction}`] == newStateValue &&
    state[`Master.Mapping.Product_Category:${splitAction}`] == newStateValue &&
    state[`Master.Mapping.Product_Substitute:${splitAction}`] == newStateValue){
      state[`Master.Mapping:${splitAction}`] = newStateValue
    }
    if(
      state[`Master.Product.Product_Principal:${splitAction}`] == newStateValue &&
      state[`Master.Product.Product_Customer:${splitAction}`] == newStateValue &&
      state[`Master.Product.Product_Category:${splitAction}`] == newStateValue
    ){
      state[`Master.Product:${splitAction}`] = newStateValue 
    }

    if(state[`Master.Product:${splitAction}`] == newStateValue &&
      state[`Master.Mapping:${splitAction}`] == newStateValue &&
      state[`Master.BranchDistributor:${splitAction}`] == newStateValue &&
      state[`Master.Warehouse:${splitAction}`] == newStateValue){
      state[`${parent}:${splitAction }`] = newStateValue
    }

  }
    
}

export const setTransaction=(state,stateToChange,stateValue)=>{
  let newStateValue = !stateValue
  state[stateToChange] = !stateValue

  let level = stateToChange.split('.').length
   let splitNameOfAction = stateToChange.split(':')[0]
   let splitAction = stateToChange.split(':')[1]
   let parent = splitNameOfAction.split('.')[0]
 
  if(level == 1){
    state[stateToChange] = newStateValue
    state[`Transaction.PO:${splitAction}`] = newStateValue
    state[`Transaction.PO.Proccess_Approve_PO:${splitAction}`] = newStateValue
    state[`Transaction.PO.BKL_Summary:${splitAction}`] = newStateValue
  }

  if(level == 2){
    state[stateToChange] = newStateValue
    state[`Transaction:${splitAction}`] = newStateValue
    state[`Transaction.PO.Proccess_Approve_PO:${splitAction}`] = newStateValue
    state[`Transaction.PO.BKL_Summary:${splitAction}`] = newStateValue
  }

  if(level == 3){
    state[stateToChange] = newStateValue
    if(state[`Transaction.PO.Proccess_Approve_PO:${splitAction}`] == newStateValue &&
      state[`Transaction.PO.BKL_Summary:${splitAction}`] == newStateValue
    ){
      state[`Transaction:${splitAction}`] = newStateValue
      state[`Transaction.PO:${splitAction}`] = newStateValue
    }
  }
}



export const applicationAction = permissionSlice.actions;

export default permissionSlice.reducer


