import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Tag,
} from "@chakra-ui/react";
import { Pagination, Table, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import SortButton from "../../../../components/SortButton";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  LABEL_STATUS_ACTIVE,
  LABEL_STATUS_INACTIVE,
  STATUS_SUCCESS,
  LABEL_STATUS_SUCCESS,
  LABEL_STATUS_FAILED,
} from "../../../../constant/propertiesConstant";
import { ROUTES, ROUTES_USER_SELLER } from "../../../../constant/routeConstant";
import {
  EditIcon,
  DetailIcon,
  AddIcon,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  UploadIcon,
  PrintIcon,
  DeleteSuccess,
  SavedSuccess,
} from "../../../../components/icons";
import { getListProductPrincipal } from "../../../../services/main/productPrincipalList";
import { inputTheme } from "../../../../constant/themeConstant";
import DateRangePicker from "../../../../components/date/DateRangePicker";
import UploadDropZone from "../../../../components/button/UploadDropZone";
import UploadFile from "../../../../components/button/UploadFile";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { typeImportSO } from "../../../../constant/importEndPointConstant";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../utils/importFileUtil";
import FileSaver from "file-saver";
import ModalImport from "../../../../components/ModalImport";
import ModalImportPreview from "../../../../components/ModalImportPreview";
import ModalCustom from "../../../../components/ModalCustom";
import ModalImportTxt from "../../../../components/ModalImportTxt";
import {
  getInitiateListImportSo,
  getListImportSo,
} from "../../../../services/main/importSoReducer";
import { useCallback } from "react";
import StringUtil from "../../../../utils/stringUtil";
import {
  confirmFileImportSo,
  confirmInfoFileImportSo,
  validateFileImportSo,
} from "../../../../utils/importFileImportSoUtil";
import ModalImportPreviewSo from "../../../../components/ModalImportPreviewSo";
const ImportSO = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [data, setData] = useState([]);
  const data = useSelector((state) => state.importSo.list);
  const { table_loading } = useSelector((state) => state.importSo);
  const { token } = useSelector((state) => state.auth);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  //pagination
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [insertId, setInsertId] = useState(0);
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [proccessLoading, setProccessLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeImportSO },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFileImportSo(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setInsertId(response.response.insert_id);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
    setProccessLoading(false);
  };

  const importConfirm = async () => {
    const object = {
      type: typeImportSO,
      filename: filename,
      confirm: true,
      previous_insert_id: insertId,
    };
    const { ok, response } = await confirmFileImportSo(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
    setUploadLoading(true);
  };
  const confirmInfo = async (object) => {
    const response = await confirmInfoFileImportSo(object, token);
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setFileImport(null);
      setModalOpen(true);
      setErr(true);
      setResp(message);
      setProccessLoading(false);
      setIsModalPreviewOpen(false);
      setUploadLoading(false);
    } else {
      setModalOpen(true);
      setErr(false);
      setResp(message);
      // setResp(
      //   t("COMMON:UPLOAD_SUCCESS") +
      //     " " +
      //     totalData +
      //     " " +
      //     t("COMMON:SUCCESS_ADDED")
      // );
      setIsModalPreviewOpen(false);
      setFileImport(null);
      setUploadLoading(false);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
    setUploadLoading(false);
  };

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  const fetchInitiate = () => {
    const params = { page, limit };
    dispatch(getInitiateListImportSo(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };

  const fetchData = () => {
    const params = { page, limit, order };
    dispatch(getListImportSo(params));
  };
  useEffect(() => {
    fetchInitiate();
    fetchData();
  }, [page, limit, token, order, dataChanged, dispatch]);

  const renderStatusColumnImport = (value) => {
    if (value.toLowerCase() === "status") {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {/* status  */}
            {t(`IMPORT_SO:FIELD_${value}`)}
          </Box>
        ),
        dataIndex: value,
        key: value,
        sorter: (a, b) => a.value.localeCompare(b.value),
        sortDirections: ["descend", "ascend"],
        render: (status) => (
          <>
            <Stack direction="row">
              <Tag rounded="full" px="2" display="flex" alignItems="center">
                <Box marginRight={2}>
                  {status.toLowerCase() === STATUS_ACTIVE ? (
                    <StatusActive />
                  ) : (
                    <StatusNonActive />
                  )}
                </Box>
                <Box
                  color={
                    status.toLowerCase() === STATUS_ACTIVE
                      ? GREEN_COLOR
                      : RED_COLOR
                  }
                >
                  {status.toLowerCase() === STATUS_ACTIVE
                    ? LABEL_STATUS_ACTIVE
                    : LABEL_STATUS_INACTIVE}
                </Box>
              </Tag>
            </Stack>
          </>
        ),
      };
    } else {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {/* {t(`PRODUCT_PRINCIPAL:FIELD_${value}`)} */}
            {value}
            {/* <SortButton thisField="id" /> */}
          </Box>
        ),
        dataIndex: value,
        key: value,
      };
    }
  };
  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport.map((item, index) => {
      return columnImport.reduce((prev, accumulator, index) => {
        prev[accumulator] = item[index];
        return prev;
      }, {});
    });
  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      name: item.name,
      file_name: item.file_name,
      date: item.date,
      time: item.time,
      status: item.status,
    }));
  const columnsImport = columnImport?.map((value) => {
    return renderStatusColumnImport(value);
  });

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("IMPORT_SO:FIELD_NAME")}
        </Box>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("IMPORT_SO:FIELD_FILE_NAME")}
        </Box>
      ),
      dataIndex: "file_name",
      key: "file_name",
      sorter: (a, b) => a.file_name.localeCompare(b.file_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("IMPORT_SO:FIELD_DATE")}
        </Box>
      ),
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date.localeCompare(b.date),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("IMPORT_SO:FIELD_TIME")}
        </Box>
      ),
      dataIndex: "time",
      key: "time",
      sorter: (a, b) => a.time.localeCompare(b.time),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("IMPORT_SO:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_SUCCESS ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_SUCCESS ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_SUCCESS
                  ? LABEL_STATUS_SUCCESS
                  : LABEL_STATUS_FAILED}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("IMPORT_SO:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("IMPORT_SO:HOME")}</span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("IMPORT_SO:TRANSACTION")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("IMPORT_SO:IMPORT_SO")}
            </span>{" "}
            - {t("IMPORT_SO:IMPORT_SO")}
          </>
        }
        button={
          <div style={{ display: "flex" }}>
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("COMMON:BUTTON_UPLOAD_FILE")}
              marginStart={2}
              onClick={showModal}
              id="btn-UserSeller-ProductCustomer-UploadFile"
              variant="outline"
              colorScheme="blue"
            />
          </div>
        }
      />
      <Box marginTop={6} minHeight="96px">
        <Table
          dataSource={dataSource}
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          size="small"
          scroll={{ y: 350 }}
          loading={table_loading}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>Row Per Page</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="50">50</Select.Option>
                  <Select.Option value="100">100</Select.Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  showSizeChanger={false}
                  current={page}
                  pageSize={limit}
                  defaultCurrent={1}
                  total={total}
                  onChange={(value) => setPage(value)}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImportTxt
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
      />
      <ModalImportPreviewSo
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
        scrollXLength={2200}
        loading={uploadLoading}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <DeleteSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ImportSO;
