export const API_URL = process.env.REACT_APP_API_URL;
// export const API_VERSION = process.env.REACT_APP_API_VERSION;
// export const API_PREFIX = process.env.REACT_APP_API_PREFIX;


// export const API_URL = 'https://sandbox-web.farmindo.com';
export const API_VERSION = 'v1';
export const API_PREFIX = 'farmindo';

// API ERROR AXIOS STATUS
export const API_ERROR_NETWORK = 'ERR_NETWORK';
export const API_ERROR_CONNECTION_ABORT = 'ECONNABORTED';
export const API_ERROR_REQUEST_TIMEOUT = API_ERROR_CONNECTION_ABORT;

// API ERROR BACKEND
export const API_ERROR = {
  API_ERR_AUT_SRV_001: 'E-1-AUT-SRV-001',
  API_ERR_AUT_SRV_003: 'E-4-AUT-SRV-003',
  API_ERR_FARM_SRV_001: 'E-1-FARM-SRV-001',
}