import React, { Component } from "react";
import { Modal, Upload, message, Button, Table, Spin } from "antd";
import style from "./ModalImport.module.css";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DownloadIcon } from "./icons";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { PRIMARY_COLOR } from "../constant/propertiesConstant";
import ButtonCustom from "./button/ButtonCustom";
import ButtonPrimary from "./button/ButtonPrimary";
import { Flex, Spinner } from "@chakra-ui/react";
const ModalImportPreviewSo = ({
  visible,
  onOk,
  onCancel,
  onUpload,
  columns,
  dataSource,
  scrollXLength,
  scrollYLength,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        className={style.modalImport}
        title={t("COMMON:UPLOAD_NEW_FILE")}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width="1000px"
        height="564px"
        closable={false}
        footer={
          <Flex gap={"0.5rem"} direction="row-reverse">
            <ButtonPrimary
              text={
                loading ? (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="WhiteAlpha.500"
                    size="lg"
                  />
                ) : (
                  t("COMMON:BUTTON_UPLOAD")
                )
              }
              width="9.375rem"
              onClick={onUpload}
              disabled={loading ? true : false}
            />
            <ButtonCustom
              colorScheme="blue"
              variant="outline"
              marginRight={2}
              width="9.375rem"
              //   link={ROUTES.ROLE.LIST}
              text={
                loading ? (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="lg"
                  />
                ) : (
                  t("COMMON:BUTTON_CANCEL")
                )
              }
              onClick={onCancel}
              disabled={loading ? true : false}
            />
          </Flex>
        }
      >
        <Table
          dataSource={dataSource}
          rowKey="id"
          columns={columns}
          pagination={false}
          size="small"
          scroll={{
            x: scrollXLength,
            y: scrollYLength,
          }}
        />
        {/* test */}
      </Modal>
    </>
  );
};

export default ModalImportPreviewSo;
