import React, { useRef } from 'react';
import { Box, Flex, FormControl, FormErrorMessage, FormLabel, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ObjectUtil from '../../utils/objectUtil';
import StringUtil from '../../utils/stringUtil';
import MultiSelectDropdown from '../multiSelectDropdown/MultiSelectDropdown';
import { useWithInstanceListAndInitiate } from '../../services/serviceUtil';
import MapperUtil from '../../utils/mapperUtil';
import { GRAY_BG_MEDIUM } from '../../constant/propertiesConstant';

const FieldMultiSelectSearchWarehouse = ({
  id, 
  name, 
  control,
  label, 
  placeholder,
  searchPlaceholder,
  isDisabled,
  isOptional,
  isReadOnly,
  isClearable,
  initialFilter,
  values,
  onChange,
  onValueChange,
  isEdit
}) => {
  const { t } = useTranslation();
  const [isFirstFetchAll, setFirstFetchAll] = useState(false);
  const initialFilterRef = useRef({});

  const {
    list,
    isLoading,
    setNextPage,
    setFilter,
    getAllNoLimit
  } = useWithInstanceListAndInitiate({
    isOption: true,
    pathInitiate: "warehouse/initiateGetList",
    pathList: "warehouse",
  });

  const handleSearchValueChange = useCallback((value) => {
    if (value) {
      setFilter(StringUtil.formatFilter({ 
        warehouse_name: `like ${value}`,
        ...initialFilterRef.current
      }));
    }
  }, []);

  const handleOnFetchAll = () => {
    if (!isFirstFetchAll) {
      setFirstFetchAll(true);
      return getAllNoLimit(StringUtil.formatFilter(initialFilter));
    }
    return null;
  }

  const customerOptionList = useMemo(() => {
    if (ObjectUtil.isEmpty(list)) return [];
    return list.map(MapperUtil.WarehouseOption);
  }, [list]);

  useEffect(() => {
    setFilter(StringUtil.formatFilter(initialFilter));
    setFirstFetchAll(false);
    if (ObjectUtil.isPropertiesTruthy(initialFilter)) {
      initialFilterRef.current = initialFilter;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilter])

  return (
    <>
      <FormControl
        id={id}
        // isInvalid={error}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
      >
        <Flex minWidth='400px'>
          <Box width='15.625rem'>
            <MultiSelectDropdown 
              id={id}
              name={name}
              values={values}
              placeholder={placeholder}
              options={customerOptionList}
              isFirstFetchAll={isFirstFetchAll}
              onFetchAll={handleOnFetchAll}
              optionMapper={MapperUtil.WarehouseOption}
              onChange={onChange}
              onValueChange={onValueChange}
              onSearch={(value) => handleSearchValueChange(value)}
              onScrollToBottom={setNextPage}
              isDisabled={isDisabled}
              isLoading={isLoading}
            />

          </Box>
        </Flex>
      </FormControl>
    </>
  );
}

export default FieldMultiSelectSearchWarehouse;