import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../../constant/apiConstant";


const INITIAL_STATE = {
    list_po: [],
    table_loading: false,
}

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-connection-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListMonitoringPO = createAsyncThunk(
    "monitoring/po",
    async(object, { getState, rejectWithValue }) => {
        let result = { ok: false, response: null };
        const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/po`;
        const { auth } = getState();
        const token = auth.token;

        try {
            const response = await baseApi.doGet(url, token, object);
            if (response) {
              result.ok = true;
              result.response = response.data.nexsoft.payload.data.content;
            }
          } catch (error) {
            if (error.response) {
              result.response = error.response;
            } else {
              message.error(i18next.t("COMMON:ERROR_NETWORK"));
            }
          }
          return result;
    }
)

export const getInitListPMonitoringPO = createAsyncThunk(
    "monitoring/po/initiate",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/po/initiate`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

  const monitoringPO = createSlice({
    name: "createMonitoringProduct",
    initialState: INITIAL_STATE,
    reducers: {
    },
    extraReducers: {
      [getListMonitoringPO.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListMonitoringPO.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.list_po = action.payload.response;
      },
      [getListMonitoringPO.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },
    },
  });
  
  export const monitoringPOReducerAction = monitoringPO.actions;
  
  export default monitoringPO.reducer;