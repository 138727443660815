import React, { Component } from "react";
import { Modal, Upload, message, Button, Spin } from "antd";
import style from "./ModalImport.module.css";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { DownloadIcon } from "./icons";
import FileSaver from "file-saver";
import { PRIMARY_COLOR } from "../constant/propertiesConstant";
import ButtonCustom from "./button/ButtonCustom";
import ButtonPrimary from "./button/ButtonPrimary";
import { Flex, Spinner } from "@chakra-ui/react";
import { downloadTemplate } from "../utils/importFileUtil";
import DownloadFunctionCSV from "./downloadFunct/DownloadFunctionCSV";
// import { ImportIcon, ImportIconHover } from "../../../../../components/icons";
// import * as SetupUpliftService from "../../../../../services/main/SetupUpliftService";
// import ButtonCancelUser from "../../../../../components/common/button/ButtonCancelUser";
// import ButtonSave from "../../../../../components/common/button/ButtonSave";
// import ProgressBar from "../../../../../components/common/progress_bar/ProgressBar";
const { Dragger } = Upload;
const ModalImport = (props) => {
  const { t } = useTranslation();

  const proccessDownloadTemplate = async () => {
    const object = {
      file_name: props.template,
    };
    const response = await downloadTemplate(object, props?.token);
    DownloadFunctionCSV(
      response.response.file_content,
      response?.response?.file_name
    );
  };

  return (
    <>
      <Modal
        className={style.modalImport}
        title={t("COMMON:IMPORT")}
        visible={props.visible}
        // onOk={props.onOk}
        onCancel={props.onCancel}
        // onDownload={props?.onDownload}
        width="1000px"
        height="564px"
        closable={false}
        footer={
          <Flex gap={"0.5rem"} direction="row-reverse">
            <ButtonPrimary
              text={
                props.loading ? (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="blue.500"
                    color="WhiteAlpha.500"
                    size="lg"
                  />
                ) : (
                  t("COMMON:BUTTON_PROCESS")
                )
              }
              width="9.375rem"
              disabled={props?.loading ? true : false}
              onClick={props?.onOk}
            />
            <ButtonCustom
              colorScheme="blue"
              variant="outline"
              marginRight={2}
              width="9.375rem"
              text={
                props.loading ? (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="lg"
                  />
                ) : (
                  t("COMMON:BUTTON_CANCEL")
                )
              }
              onClick={props.onCancel}
              disabled={props?.loading ? true : false}
            />
          </Flex>
        }
      >
        <div
        //   onMouseEnter={() => setState({ hover: true })}
        //   onMouseLeave={() => setState({ hover: false })}
        >
          <Dragger
            {...props}
            style={{
              // border: `3px dashed ${state.hover ? '#783F91' : '#CCCCCC'}`,
              background: "#FFFFFF",
              borderRadius: "8px",
              height: "305px",
              width: "860px",
              cursor: "default",
              margin: "auto",
            }}
            fileList={props.fileList}
            multiple={false}
            maxCount={1}
            beforeUpload={(file) => {
              const isCSV =
                file.type === "text/csv" ||
                file.type === "application/vnd.ms-excel";
              if (!isCSV) {
                message.error(
                  `${t("COMMON:FORMAT")}
                  ${file.name} 
                  ${t("COMMON:ERROR_NOT_CSV")}`
                );
              }
              return isCSV || Upload.LIST_IGNORE;
            }}
            onChange={props?.onChangeFile}
            // onRemove={(event) => }
            disabledClick={false}
            customRequest={({ onSuccess }) => {
              onSuccess();
            }}
          >
            <div className={style.icon}>
              {/* {state.hover ? <DownloadIcon /> : <PlusSquareIcon />} */}
            </div>
            <div className={style.text}>
              {t("COMMON:DRAG_AND_DROP_YOUR_FILE_HERE")}
            </div>
            <div className={style.text4}>{t("COMMON:FILE_SUPPORT")}</div>
            <div className={style.text5}>{/* {t("COMMON:OR")} */}</div>
            {/* <Upload
              //  accept=".csv"
              //  listType="picture"
              multiple={false}
              maxCount={1}
              beforeUpload={(file) => {
                const isCSV = file.type === "text/csv";
                if (!isCSV) {
                  message.error(`${file.name} is not a csv file`);
                }
                return isCSV || Upload.LIST_IGNORE;
              }}
              onChange={(info) => {
                (info.fileList);
              }}
            >
            
              <Button
                style={{
                  border: `0.707246px solid ${PRIMARY_COLOR}`,
                  borderRadius: "3px",
                  color: PRIMARY_COLOR,
                  font: "normal normal 700 14px Roboto, sans-serif",
                  padding: "2px 24px",
                }}
              >
                {t("COMMON:BROWSE_FILE")}
              </Button>
            </Upload> */}
          </Dragger>
          <div className={style.downloadTemplate}>
            <Button
              style={{
                color: PRIMARY_COLOR,
                cursor: "pointer",
                border: "none",
                boxShadow: "none",
                fontWeight: 500,
                fontSize: "16px",
              }}
              onClick={
                props?.template ? proccessDownloadTemplate : null
              }
            >
              {t("COMMON:DOWNLOAD_TEMPLATE_HERE")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalImport;
