import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addBklMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_CUSTOMER_NAME"),
          })
        ),
      store_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_STORE_NAME"),
          })
        ),
      distributor_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      area_code: yup
        .string()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_AREA_CODE"),
          })
        ),
      distribution_center: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_DISTRIBUTION_CENTRE"),
        })
      ),
      store_sap_id: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_STORE_SAP_ID"),
        })
      ),
      store_sap_name: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_STORE_SAP_NAME"),
        })
      ),
      frequency: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_FREQUENCY"),
          })
        ),
      open_po: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_OPEN_PO"),
          })
        )
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_OPEN_PO"),
          })
        ),
      jwk_depo: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_JADWAL_KIRIM_DEPO"),
          })
        )
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_JADWAL_KIRIM_DEPO"),
          })
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_STATUS"),
        })
      ),
    });
};
export const editBklMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      customer_id: yup.object().shape({
        value: yup
          .number()
          .nullable()
          .required(
            i18next.t("COMMON:ERROR_REQUIRED", {
              field: i18next.t("BKL_MAPPING:FIELD_CUSTOMER_NAME"),
            })
          ),
      }),
      store_id: yup.object().shape({
        value: yup
          .number()
          .nullable()
          .required(
            i18next.t("COMMON:ERROR_REQUIRED", {
              field: i18next.t("BKL_MAPPING:FIELD_STORE_NAME"),
            })
          ),
      }),
      distributor_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("BKL_MAPPING:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      area_code: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_AREA_CODE"),
        })
      ),
      distribution_center: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_DISTRIBUTION_CENTRE"),
        })
      ),
      store_sap_id: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_STORE_SAP_ID"),
        })
      ),
      store_sap_name: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_STORE_SAP_NAME"),
        })
      ),
      frequency: yup.number().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_FREQUENCY"),
        })
      ),
      open_po: yup.array().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_OPEN_PO"),
        })
      ),
      jwk_depo: yup.array().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_JADWAL_KIRIM_DEPO"),
        })
      ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("BKL_MAPPING:FIELD_STATUS"),
        })
      ),
    });
};
