import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  table_loading: false,
  status: "",
};

export const getInitiateListImportSo = createAsyncThunk(
  "getInitiateListImportSo",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import-so/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListImportSo = createAsyncThunk(
  "getListImportSo",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import-so`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const importSo = createSlice({
  name: "importSo",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxProductBklMapping() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // product bkl mapping
    [getInitiateListImportSo.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListImportSo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListImportSo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListImportSo.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListImportSo.fulfilled]: (state, action) => {
      state.status = "succcess";
      state.table_loading = false;
      state.list = action.payload.response;
    },
    [getListImportSo.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
  },
});

export const importSoAction = importSo.actions;

export default importSo.reducer;
