import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Tag,
  Select as SelectChakra,
  CloseButton,
} from "@chakra-ui/react";
import { Pagination, Table, Select, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import SortButton from "../../../../../components/SortButton";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  LABEL_STATUS_ACTIVE,
  LABEL_STATUS_INACTIVE,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  EditIcon,
  DetailIcon,
  AddIcon,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  UploadIcon,
  DeleteSuccess,
  SavedSuccess,
} from "../../../../../components/icons";
import {
  getInitiateListProductCategory,
  getListPrincipalFilter,
  getListProductCategory,
} from "../../../../../services/main/productCategoryReducer";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { selectDropDownFilterProductCategory } from "../../../../../constant/selectOptConstant";
import { CapitalizeFirstLetter } from "../../../../../function/CapitalizeFirstLetter";
import ModalImport from "../../../../../components/ModalImport";
import ModalImportPreview from "../../../../../components/ModalImportPreview";
import ModalCustom from "../../../../../components/ModalCustom";
import { typeProductCategory } from "../../../../../constant/importEndPointConstant";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../../utils/importFileUtil";
import FileSaver from "file-saver";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import moment from "moment";
import { ID_PRODUCT_CATEGORY } from "../../../../../constant/idConstant";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import { subHours } from "date-fns";
import StringUtil from "../../../../../utils/stringUtil";
const ListProductCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.productCategory.list);
  const { company_name } = useSelector((state) => state.auth);
  const { table_loading } = useSelector((state) => state.productCategory);
  const { token } = useSelector((state) => state.auth);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  const fetchData = async () => {
    let filter;
    const filterPrin = `principal_id eq ${principalId}`;
    const filterCategoryName = `product_category_name like ${inputSearch}`;
    const filterCategoryCode = `product_category_code like ${inputSearch}`;
    if (principalId !== null) {
      filter = filterPrin;
    }
    if (selectDropDownFilter?.value?.includes("name")) {
      filter = filterCategoryName;
    }
    if (selectDropDownFilter?.value?.includes("code")) {
      filter = filterCategoryCode;
    }
    const params =
      inputSearch === "" && principalId === null
        ? { page, limit, order }
        : { page, limit, filter, order };
    dispatch(getListProductCategory(params));
  };

  const fetchInitiate = () => {
    let filter;
    const filterPrin = `principal_id eq ${principalId}`;
    const filterCategoryName = `product_category_name like ${inputSearch}`;
    const filterCategoryCode = `product_category_code like ${inputSearch}`;
    if (principalId !== null) {
      filter = filterPrin;
    }
    if (selectDropDownFilter?.value?.includes("name")) {
      filter = filterCategoryName;
    }
    if (selectDropDownFilter?.value?.includes("code")) {
      filter = filterCategoryCode;
    }
    const params =
      inputSearch === "" && principalId === null
        ? { page, limit, order }
        : { page, limit, filter, order };
    dispatch(getInitiateListProductCategory(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };
  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [proccessLoading, setProccessLoading] = useState(false);
  const [order, setOrder] = useState("");

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeProductCategory },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFile(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setProccessLoading(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
  };

  const importConfirm = async () => {
    const object = {
      type: typeProductCategory,
      filename: filename,
      confirm: true,
    };
    const { ok, response } = await confirmFile(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
  };

  const confirmInfo = async (object) => {
    const response = await confirmInfoFile(object, token);
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setModalOpen(true);
      setErr(true);
      // setResp(t("COMMON:UPLOAD_FAILED"));
      setResp(message);
      setProccessLoading(false);
      setIsModalPreviewOpen(false);
    } else {
      setModalOpen(true);
      setErr(false);
      setResp(message);
      // setResp(t("COMMON:UPLOAD_SUCCESS"));
      setIsModalPreviewOpen(false);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
  };

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipalFilter(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const handleScrollPrincipal = () => {
    setPagePrincipal(pagePrincipal + 1);
  };
  const [filterPrincipal, setfilterPrincipal] = useState("");
  // fungsi search di drop down principal
  const fetchSearchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = `name like ${filterPrincipal}`;
    const params =
      filterPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListPrincipalFilter(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };

  // for filter
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");
  const [principalId, setPrincipalId] = useState(null);
  const [inputSearch, setInputSearch] = useState("");
  const [form, setForm] = useState("");
  const [formPrincipal, setFormPrincipal] = useState(0);
  const [isDisabled, setisDisabled] = useState(true);
  const [dropDownPrincipalIsDisabled, setDropDownPrincipalIsDisabled] =
    useState(false);
  const [dropDownFilterIsDisabled, setDropDownFilterDisabled] = useState(false);
  // untuk handle select filter by
  const handleChangeSelectFilter = (name, value, option) => {
    setSelectDropDownFilter({
      ...selectDropDownFilter,
      ...option,
      value,
    });
    setPrincipalId(null);
    setDropDownPrincipalIsDisabled(true);
  };
  // untuk handle select principal
  const handleChangeSelectPrincipal = (name, option) => {
    setPrincipalId(option.value);
    setButtonSearchOrClose("close");
    setisDisabled(false);
    setDropDownFilterDisabled(true);
    setForm("");
  };
  // untuk handle inputan yg diketik oleh user
  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setisDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setisDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };
  const submitOk = () => {
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      setInputSearch(form);
      setFormPrincipal(principalId);
      setPage(1);
      setPopOverOpen(false);
    }
  };
  const resetFilter = () => {
    setForm("");
    setInputSearch("");
    setPrincipalId(null);
    setisDisabled(true);
    setSelectDropDownFilter("");
    setDropDownPrincipalIsDisabled(false);
    setDropDownFilterDisabled(false);
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
  };
  const handleCloseButtonSearch = () => {
    setForm("");
    setButtonSearchOrClose("search");
    setisDisabled(true);
    setPopOverOpen(false);
  };

  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  useEffect(() => {
    fetchInitiate();
    fetchData();
  }, [
    page,
    total,
    order,
    limit,
    inputSearch,
    selectDropDownFilter,
    dataChanged,
    formPrincipal,
    dispatch,
  ]);

  useEffect(() => {
    fetchDataPrincipal();
  }, [pagePrincipal]);

  useEffect(() => {
    fetchSearchPrincipal();
  }, [filterPrincipal]);

  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport.map((item, index) => {
      return columnImport.reduce((prev, accumulator, index) => {
        prev[accumulator] = item[index];
        return prev;
      }, {});
    });

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      principal_code: item.principal_code,
      principal_name: item.principal_name,
      product_category_code: item.product_category_code,
      product_category_name: item.product_category_name,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_CATEGORY.EDIT,
              state: item,
            }}
            text={<EditIcon />}
            backgroundcolor="transparent"
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_CATEGORY.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
          />
        </div>
      ),
    }));
  const columnsImport = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* distributor code  */}
          {t(`PRODUCT_CATEGORY:FIELD_${columnImport[0]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[0],
      key: columnImport[0],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* product category name  */}
          {t(`PRODUCT_CATEGORY:FIELD_${columnImport[1]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[1],
      key: columnImport[1],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* product category  code */}
          {t(`PRODUCT_CATEGORY:FIELD_${columnImport[2]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[2],
      key: columnImport[2],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* status  */}
          {t(`PRODUCT_CATEGORY:FIELD_${columnImport[3]}`)}
        </Box>
      ),
      dataIndex: columnImport[3],
      key: columnImport[3],
      sorter: (a, b) => a.columnImport[4].localeCompare(b.columnImport[3]),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? LABEL_STATUS_ACTIVE
                  : LABEL_STATUS_INACTIVE}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_NAME")}
        </Box>
      ),
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
      sortDirections: ["descend", "ascend"],
      dataIndex: "principal_name",
      key: "principal_name",
      ellipsis: true,
      width: 140,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_CODE")}
        </Box>
      ),
      sorter: (a, b) =>
        a.product_category_code.localeCompare(b.product_category_code),
      sortDirections: ["descend", "ascend"],
      dataIndex: "product_category_code",
      key: "product_category_code",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME")}
        </Box>
      ),
      sorter: (a, b) =>
        a.product_category_name.localeCompare(b.product_category_name),
      sortDirections: ["descend", "ascend"],
      dataIndex: "product_category_name",
      key: "product_category_name",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 110,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 105,
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_3")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_SELLER.PRODUCT_CATEGORY.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("PRODUCT_CATEGORY:BUTTON_1")}
              id={ID_PRODUCT_CATEGORY.LIST.BTN_ADD}
            />
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("COMMON:BUTTON_UPLOAD_FILE")}
              marginStart={2}
              onClick={showModal}
              id={ID_PRODUCT_CATEGORY.LIST.BTN_UPLOAD}
              variant="outline"
              colorScheme="blue"
            />
          </>
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"30rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={6}>
          <Select
            placeholder={company_name}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            textColor={GRAY_COLOR}
            size="large"
            value={principalId}
            onChange={(value, opt) =>
              handleChangeSelectPrincipal("select_drop_down_principal", opt)
            }
            // disabled={dropDownPrincipalIsDisabled}
            disabled
            onPopupScroll={handleScrollPrincipal}
            fontSize="14px"
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                search={(e) => setfilterPrincipal(e.target.value)}
              />
            )}
          >
            {dataPrincipal &&
              dataPrincipal?.map((data, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={data.id}
                    name={data.company_profile_name}
                    code={data.principal_code}
                    status={data.status}
                    alias={data.alias}
                  >
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            placeholder={t("PRODUCT_CATEGORY:PLACE_HOLDER_SELECT_FILTER_BY")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            // disabled={!dropDownFilterIsDisabled}
            value={selectDropDownFilter.value}
            size="large"
            onChange={(value, opt) =>
              handleChangeSelectFilter("select_drop_down", value, opt)
            }
            textColor={GRAY_COLOR}
            fontSize="14px"
            showSearch={false}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
          >
            {selectDropDownFilterProductCategory?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            marginStart={3}
            backgroundColor="white"
            textColor={GRAY_COLOR}
            borderRadius={5}
          >
            <Input
              type="text"
              id={ID_PRODUCT_CATEGORY.LIST.SEARCH}
              onChange={(e) => handleChange(e)}
              name="customer"
              placeholder={
                selectDropDownFilter.value
                  ? `Search By ${CapitalizeFirstLetter(
                      selectDropDownFilter.value
                    )}`
                  : ""
              }
              fontSize="14px"
              pointerEvents={
                selectDropDownFilter.value === undefined ? "none" : "auto"
              }
              value={form}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              id={ID_PRODUCT_CATEGORY.LIST.BTN_APPLY}
              onClick={submitOk}
              marginRight={2}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_PRODUCT_CATEGORY.LIST.RESET_FILTER}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          size="small"
          loading={table_loading}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <SelectChakra
                  border="1px solid"
                  borderColor="#CCCCCC"
                  id={ID_PRODUCT_CATEGORY.LIST.ROW_PER_PAGE}
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </SelectChakra>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  id={ID_PRODUCT_CATEGORY.LIST.PAGINATION}
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImport
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
      />
      <ModalImportPreview
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <DeleteSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ListProductCategory;
