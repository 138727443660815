import { Box, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import {
  SavedSuccess,
  UnSuccess
} from "../../../../components/icons";
import ModalAddNewUser from "../../../../components/ModalAddNewUser";
import ModalCustomLink from "../../../../components/ModalCustomLink";
import { GRAY_DISABLED } from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../constant/routeConstant";
import {
  editChangeProfile, userAction
} from "../../../../services/main/userReducer";
const BodyAuthentication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  // const userId = location.state.id;
  const { user_id } = useSelector((state) => state.auth);
  const { user_profile } = useSelector((state) => state.user);
  const { index_page } = useSelector((state) => state.user);
  const getAuthentication = useSelector(
    (state) => state.user.detail_user.authentication
  );
  // modal
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");

  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACTIVATION")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
  };
  const openModalPreview = () => {
    /// buka modal preview
    setModalPreviewOpen(true);
  };
  const closeModalPreview = () => {
    // tutup modal preview
    setModalPreviewOpen(false);
  };
  const resetReduxUser = () => {
    // buat reset redux supaya kembali seperti semula
    dispatch(userAction.resetReduxUser());
  };
  const clickOkOnModalSuccess = () => {
    // klik tombol ok di dalem modal sukses
    setNewDataAdded(true);
    // history.push(ROUTES.HOME.LIST);
    // dispatch(userAction.setIsChangeProfile(true));
    resetReduxUser();
  };
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
    // dispatch(userAction.setIsChangeProfile(false));
  };
  const saveUser = async () => {
    /// klik save di dalem tombol save user
    dispatch(editChangeProfile(user_id)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setResp(response.payload.response.nexsoft.payload.status.message);
        setErr(false);
      } else {
        // message.error(response.payload.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  return (
    <>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* Client id  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_CLIENT_ID")}
          </Box>
          <Box>
            <Input
              type="text"
              width="250px"
              pointerEvents="none"
              placeholder={
                getAuthentication?.client_id ? getAuthentication?.client_id : "-"
              }
              backgroundColor={GRAY_DISABLED}
              style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}
            />
          </Box>
        </Box>
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_PREVIEW")}
          width="9.375rem"
          onClick={() => openModalPreview()}
        />
      </Box>
      {/* modal  */}
      <ModalAddNewUser
        message={t("USER:FIELD_DETAIL_ACCOUNT")}
        isOpen={modalPreviewOpen}
        onOk={() => saveUser()}
        onClose={() => closeModalPreview()}
      />
      <ModalCustomLink
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
        link={err ? null : ROUTES.HOME}
      />
    </>
  );
};

export default BodyAuthentication;
