import {
  Box, Text
} from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_PRODUCT_CATEGORY_MAPPING } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR, PRIMARY_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import {
  addProductCategoryMapping,
  getListCustomer,
  getListDistributor,
  getListDistributorDDS,
  getListPrincipal,
  getListProductCategory,
  getListProductCustomer,
  getListProductCustomerDDS,
  getListProductPrincipal,
  getListProductPrincipalDDS,
  getListStore,
  getListStoreSupplierConnection
} from "../../../../../services/main/productCategoryMappingReducer";
import StringUtil from "../../../../../utils/stringUtil";
import { addProductCategoryMappingSchema } from "./productCategoryMappingSchema";

const AddProductCategoryMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const { store_loading } = useSelector(
    (state) => state.productCategoryMapping
  );
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // page and limit
  const [pageProductCategory, setPageProductCategory] = useState(1);
  const [limitProductCategory, setLimitProductCategory] = useState(10);
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageUnlimited, setPageUnlimited] = useState(-99);
  const [limitUnlimited, setLimitUnlimited] = useState(-99);
  const [pageProductPrincipal, setPageProductPrincipal] = useState(1);
  const [limitProductPrincipal, setLimitProductPrincipal] = useState(10);
  const [pageProductCustomer, setPageProductCustomer] = useState(1);
  const [limitProductCustomer, setLimitProductCustomer] = useState(10);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);
  const [pageStoreSupplierConnection, setPageStoreSupplierConnection] =
    useState(1);
  const [limitStoreSupplierConnection, setLimitStoreSupplierConnection] =
    useState(10);

  // Code(for disabled input)
  const [productCategoryCode, setproductCategoryCode] = useState("");
  const [principalCode, setPrincipalCode] = useState("");
  const [distributorCode, setDistributorCode] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [storeSupplierCode, setStoreSupplierCode] = useState("");
  const [productPrincipalCode, setProductPrincipalCode] = useState("");
  const [productCustomerCode, setProductCustomerCode] = useState("");

  const [distributorId, setDistributorId] = useState([]);
  const clearProductCategory = () => {
    setFieldValue("product_category_id", null);
    setFieldValue("product_category_code", "");
    setDistributorId(null);
  };
  const clearDistributor = () => {
    setFieldValue("distributor_id", null);
    setFieldValue("distributor_code", "");
  };
  const clearCustomer = () => {
    setFieldValue("customer_id", null);
    setIdCust(null);
    setFieldValue("customer_code", "");
  };
  const clearStore = () => {
    setFieldValue("store_id", null);
    setFieldValue("store_code", "");
  };
  const clearStoreSupplier = () => {
    setFieldValue("store_supplier_connection_id", null);
    setFieldValue("store_supplier_connection_code", "");
  };
  const clearProductPrincipal = () => {
    setFieldValue("product_principal_id", null);
    setFieldValue("product_principal_code", "");
  };
  const clearProductCustomer = () => {
    setFieldValue("product_customer_id", null);
    setFieldValue("product_customer_code", "");
  };
  const handleClear = (name) => {
    switch (name) {
      case (name = "product_category_id"):
        clearProductCategory();
        clearDistributor();
        clearCustomer();
        clearStore();
        clearStoreSupplier();
        clearProductCustomer();
        setDistributorId(null);
        break;
      case "distributor_id":
        clearDistributor();
        clearCustomer();
        clearStore();
        clearStoreSupplier();
        clearProductCustomer();
        break;
      case "customer_id":
        clearCustomer();
        clearStore();
        clearStoreSupplier();
        clearProductCustomer();
        break;
      case "store_id":
        clearStore();
        clearStoreSupplier();
        break;
      case "store_supplier_connection_id":
        clearStoreSupplier();
        break;
      case "product_principal_id":
        clearProductPrincipal();
        break;
      case "product_customer_id":
        clearProductCustomer();
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case (name = "product_category_id"):
        setFieldValue("product_category_id", value);
        setFieldValue("product_category_code", option?.code);
        setDistributorId(option.fulldata.distributor_id);
        clearDistributor();
        clearCustomer();
        clearStore();
        clearStoreSupplier();
        clearProductCustomer();
        break;
      case "distributor_id":
        setFieldValue("distributor_id", value);
        setFieldValue("distributor_code", option?.code);
        clearCustomer();
        clearStore();
        clearStoreSupplier();
        clearProductCustomer();
        break;
      case "customer_id":
        setFieldValue("customer_id", value);
        setIdCust(value);
        setFieldValue("customer_code", option?.code);
        clearStore();
        clearStoreSupplier();
        clearProductCustomer();
        break;
      case "store_id":
        setFieldValue("store_id", value);
        setFieldValue("store_code", option?.code);
        clearStoreSupplier();
        break;
      case "store_supplier_connection_id":
        setFieldValue("store_supplier_connection_id", value);
        setFieldValue("store_supplier_connection_code", value);
        break;
      case "product_principal_id":
        setFieldValue("product_principal_id", value);
        setFieldValue("product_principal_code", option?.code);
        break;
      case "product_customer_id":
        setFieldValue("product_customer_id", value);
        setFieldValue("product_customer_code", option?.code);
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };

  // product category
  const [dataProductCategory, setDataProductCategory] = useState([]);
  const fetchDataProductCategory = () => {
    const page = pageProductCategory;
    const limit = limitProductCategory;
    const is_check_status = true;
    const params = { page, limit, is_check_status };
    dispatch(getListProductCategory(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductCategory([...dataProductCategory, ...resp]);
    });
  };
  const [filterProductCategory, setFilterProductCategory] = useState("");
  const fetchSearchProductCategory = () => {
    const page = pageProductCategory;
    const limit = limitProductCategory;
    const is_check_status = true;
    const filter = `product_category_name like ${filterProductCategory}`;
    const params =
      filterProductCategory === ""
        ? { page, limit, is_check_status }
        : { page, limit, filter, is_check_status };
    dispatch(getListProductCategory(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductCategory(1);
      setDataProductCategory(response.payload.response);
    });
  };

  const handleScrollProductCategory = () => {
    setPageProductCategory(pageProductCategory + 1);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };

  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageUnlimited;
    const limit = limitUnlimited;
    const is_check_status = true;
    const updated_at_start = "2000-01-01T00:00:00Z";
    const params = { page, limit, updated_at_start, is_check_status };
    dispatch(getListDistributorDDS(params)).then((response) => {
      setDataDistributor(response.payload.response);
    });
  };
  const distributorMappingfilter = dataDistributor
    ?.map((value) => {
      let i;
      for (i = 0; i < distributorId.length; i++) {
        if (distributorId[i] === value.id) return value;
      }
      return null;
    })
    .filter((val) => val);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (!values?.distributor_id) {
      // jika distributor id null atau undefined maka set data kosong
      setDataCustomer([]);
    } else {
      dispatch(getListCustomer(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataCustomer([...dataCustomer, ...resp]);
      });
    }
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_name: `like ${filterCustomer}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
    });
    // const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    if (!values?.distributor_id) {
      setDataCustomer([]);
    } else {
      dispatch(getListCustomer(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      });
    }
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };

  // store
  const [dataStore, setDataStore] = useState([]);
  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    // const filter = `customer_id eq ${values?.customer_id}`;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params =
      values?.customer_id && values?.distributor_id
        ? { page, limit, filter }
        : { page, limit };
    if (!values?.customer_id && !values?.distributor_id) {
      setDataStore([]);
    } else {
      dispatch(getListStore(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStore(resp);
      });
    }
  };
  const fetchSearchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      store_name: `like ${filterStore ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (values?.customer_id === null) {
      setDataStore(null);
    } else {
      dispatch(getListStore(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };
  const [filterStore, setfilterStore] = useState("");

  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };

  // const fetchDataStoreUnlimited = () => {
  //   const page = pageUnlimited;
  //   const limit = limitUnlimited;
  //   const updated_at_start = "2000-01-01T00:00:00Z";
  //   const params = { page, limit, updated_at_start };
  //   dispatch(getListStore(params)).then((response) => {
  //     if (!response.payload.ok) return;
  //     const resp = response.payload.response;
  //     if (!resp) return;
  //     setDataStore([...dataStore, ...resp]);
  //   });
  // };
  // const [filterStoreUnlimited, setfilterStoreUnlimited] = useState("");
  // const fetchSearchStoreUnlimited = () => {
  //   const page = pageUnlimited;
  //   const limit = limitUnlimited;
  //   const updated_at_start = "2000-01-01T00:00:00Z";
  //   const filter = `store_name like ${filterStore}`;
  //   const params =
  //     filterStore === ""
  //       ? { page, limit, updated_at_start }
  //       : { page, limit, filter, updated_at_start };
  //   dispatch(getListStore(params)).then((response) => {
  //     if (!response.payload.ok) return;
  //     setDataStore(response.payload.response);
  //   });
  // };
  // Product Principal
  const [dataProductPrincipal, setDataProductPrincipal] = useState([]);
  const fetchDataProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const params = { page, limit };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductPrincipal([...dataProductPrincipal, ...resp]);
    });
  };
  const [filterProductPrincipal, setfilterProductPrincipal] = useState("");
  const fetchSearchProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const filter = `product_code like ${filterProductPrincipal}`;
    const params =
      filterProductPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductPrincipal(1);
      setDataProductPrincipal(response.payload.response);
    });
  };

  const handleScrollProductPrincipal = () => {
    setPageProductPrincipal(pageProductPrincipal + 1);
  };

  // Product Customer
  const [dataProductCustomer, setDataProductCustomer] = useState([]);
  const [idCust, setIdCust] = useState(null);
  const fetchDataProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${values?.principal_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (idCust === null) {
      setDataProductCustomer(null);
    } else {
      dispatch(getListProductCustomerDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataProductCustomer(resp);
      });
    }
  };
  const [filterProductCustomer, setfilterProductCustomer] = useState("");
  const fetchSearchProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      product_code: `like ${filterProductCustomer ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (idCust === null) {
      setDataProductCustomer(null);
    } else {
      dispatch(getListProductCustomerDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageProductCustomer(1);
        setDataProductCustomer(response.payload.response);
      });
    }
  };

  const handleScrollProductCustomer = () => {
    setPageProductCustomer(pageProductCustomer + 1);
  };

  // store supplier connection
  const [dataStoreSupplierConnection, setDataStoreSupplierConnection] =
    useState([]);
  const fetchDataStoreSupplierConnection = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      store_id: `eq ${values?.store_id ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (
      values?.customer_id === null &&
      values?.distributor_id === null &&
      values?.store_id === null
    ) {
      setDataStoreSupplierConnection(null);
    } else {
      dispatch(getListStoreSupplierConnection(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStoreSupplierConnection(resp);
      });
    }
  };
  const [filterStoreSupplierConnection, setfilterStoreSupplierConnection] =
    useState("");
  const fetchSearchStoreSupplierConnection = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      store_id: `eq ${values?.store_id ?? ""}`,
      store_name: `like ${filterStoreSupplierConnection ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (
      values?.customer_id === null &&
      values?.distributor_id === null &&
      values?.store_id === null
    ) {
      setDataStoreSupplierConnection(null);
    } else {
      dispatch(getListStoreSupplierConnection(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setPageStoreSupplierConnection(1);
        setDataStoreSupplierConnection(resp);
      });
    }
  };

  const handleScrollStoreSupplierConnection = () => {
    setPageStoreSupplierConnection(pageStoreSupplierConnection + 1);
  };

  const onSubmit = () => {
    dispatch(addProductCategoryMapping(values)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: null,
      customer_code: "",
      store_id: null,
      store_code: "",
      store_supplier_connection_id: null,
      store_supplier_connection_code: "",
      distributor_id: null,
      distributor_code: "",
      product_principal_id: null,
      product_principal_code: "",
      product_customer_id: null,
      product_customer_code: "",
      product_category_id: null,
      product_category_code: "",
      status: "",
    },
    validationSchema: addProductCategoryMappingSchema(t),
    onSubmit,
  });

  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  //product category
  useEffect(() => {
    fetchDataProductCategory();
  }, [pageProductCategory]);
  useEffect(() => {
    fetchSearchProductCategory();
  }, [filterProductCategory]);

  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);

  // customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer, values?.distributor_id]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, []);

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, values?.customer_id]);
  useEffect(() => {
    fetchSearchStore();
  }, [filterStore]);

  // product principal
  useEffect(() => {
    fetchDataProductPrincipal();
  }, [pageProductPrincipal]);
  useEffect(() => {
    fetchSearchProductPrincipal();
  }, [filterProductPrincipal]);

  // product customer
  useEffect(() => {
    fetchDataProductCustomer();
  }, [pageProductCustomer, idCust]);

  useEffect(() => {
    fetchSearchProductCustomer();
  }, [filterProductCustomer]);

  // store supplier connection
  useEffect(() => {
    fetchDataStoreSupplierConnection();
  }, [
    pageStoreSupplierConnection,
    values?.customer_id,
    values?.distributor_id,
    values?.principal_id,
    values?.store_id,
  ]);

  useEffect(() => {
    fetchSearchStoreSupplierConnection();
  }, [filterStoreSupplierConnection]);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY_MAPPING:ADD_PRODUCT_CATEGORY_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_CATEGORY_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY_MAPPING:ADD_PRODUCT_CATEGORY_MAPPING")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* Product Category  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.PRODUCT_CATEGORY}
                  style={inputTheme}
                  disabled
                  value={values?.product_category_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* principal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.PRINCIPAL_CODE}
                  disabled
                  value={principalCode ? principalCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* distributor code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.DISTRIBUTOR_CODE}
                  disabled
                  value={values?.distributor_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.CUSTOMER_CODE}
                  value={values?.customer_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* store code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.STORE_CODE}
                  value={values?.store_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* store supplier code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_SUPPLIER_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  // id={ID_PRODUCT_CATEGORY_MAPPING.ADD.}
                  disabled
                  value={values?.store_supplier_connection_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* principal product code  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.PRINCIPAL_PRODUCT_CODE}
                  disabled
                  value={values?.product_principal_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>

            {/* customer product code  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.CUSTOMER_PRODUCT_CODE}
                  value={values?.product_customer_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* Product Category Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY_NAME")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.PRODUCT_CATEGORY_NAME}
                  size="large"
                  onClear={() => handleClear("product_category_id")}
                  onPopupScroll={handleScrollProductCategory}
                  allowClear
                  name="product_category_id"
                  onBlur={handleBlur("product_category_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_category_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setFilterProductCategory(e.target.value)}
                    />
                  )}
                >
                  {dataProductCategory &&
                    dataProductCategory?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.product_category_name}
                          code={data.product_category_code}
                          fulldata={data}
                        >
                          {data.product_category_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_category_id && touched.product_category_id && (
                  <ValidationError text={errors.product_category_id} />
                )}
              </Box>
            </Box>
            {/* Principal Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.PRINCIPAL_NAME}
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Distributor Name*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.DISTRIBUTOR_NAME}
                  // onPopupScroll={handleScrollPrincipal}
                  allowClear
                  onClear={() => handleClear("distributor_id")}
                  value={values?.distributor_id}
                  name="distributor_id"
                  onBlur={handleBlur("distributor_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                >
                  {distributorMappingfilter &&
                    distributorMappingfilter?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.name}
                          code={data.distributor_code}
                          principalid={data.principal_id}
                          principalname={data.principal_name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && touched.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>
            {/* customer name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  onPopupScroll={handleScrollCustomer}
                  allowClear
                  value={values?.customer_id}
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.CUSTOMER_NAME}
                  onClear={() => handleClear("customer_id")}
                  name="customer_id"
                  onBlur={handleBlur("customer_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataCustomer &&
                    dataCustomer?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          name={data.name}
                          code={data.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* Store Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  onClear={() => handleClear("store_id")}
                  size="large"
                  onPopupScroll={handleScrollStore}
                  allowClear
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.STORE_NAME}
                  value={values?.store_id}
                  loading={store_loading}
                  name="store_id"
                  onBlur={handleBlur("store_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterStore(e.target.value)}
                    />
                  )}
                >
                  {dataStore &&
                    dataStore?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.store_name}
                          code={data.store_code}
                        >
                          {data.store_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Store Supplier Connection  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_SUPPLIER_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  onClear={() => handleClear("store_supplier_connection_id")}
                  size="large"
                  onPopupScroll={handleScrollStoreSupplierConnection}
                  allowClear
                  value={values?.store_supplier_connection_id}
                  name="store_supplier_connection_id"
                  onBlur={handleBlur("store_supplier_connection_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect(
                      "store_supplier_connection_id",
                      value,
                      opt
                    )
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) =>
                        setfilterStoreSupplierConnection(e.target.value)
                      }
                    />
                  )}
                >
                  {dataStoreSupplierConnection &&
                    dataStoreSupplierConnection?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.supplier_code}
                          name={data.store_name}
                          code={data.store_code}
                          alias={data.store_alias}
                        >
                          {data.supplier_code}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.store_supplier_connection_id &&
                  touched.store_supplier_connection_id && (
                    <ValidationError
                      text={errors.store_supplier_connection_id}
                    />
                  )}
              </Box>
            </Box>
            {/* Principal Product Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  size="large"
                  name="product_principal_id"
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.PRINCIPAL_PRODUCT_NAME}
                  onPopupScroll={handleScrollProductPrincipal}
                  style={inputTheme}
                  value={values?.product_principal_id}
                  allowClear
                  onClear={() => handleClear("product_principal_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_principal_id", value, opt)
                  }
                  onBlur={handleBlur("product_principal_id")}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductPrincipal(e.target.value)}
                    />
                  )}
                >
                  {dataProductPrincipal &&
                    dataProductPrincipal?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          principal={data.principal_name}
                          code={data.product_code}
                          title={data.product_code}
                        >
                          {data.product_code}
                          {" - "}
                          {data.product_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_principal_id &&
                  touched.product_principal_id && (
                    <ValidationError text={errors.product_principal_id} />
                  )}
              </Box>
            </Box>
            {/* Customer Product Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  onClear={() => handleClear("product_customer_id")}
                  style={inputTheme}
                  value={values?.product_customer_id}
                  onPopupScroll={handleScrollProductCustomer}
                  size="large"
                  allowClear
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.CUSTOMER_PRODUCT_NAME}
                  onBlur={handleBlur("product_customer_id")}
                  name="product_customer_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("product_customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataProductCustomer &&
                    dataProductCustomer?.map((data) => {
                      return (
                        <Select.Option
                          key={data.id}
                          value={data.id}
                          code={data.product_code}
                          title={data.product_code}
                        >
                          {data.product_code}
                          {" - "}
                          {data.product_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_customer_id && touched.product_customer_id && (
                  <ValidationError text={errors.product_customer_id} />
                )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  id={ID_PRODUCT_CATEGORY_MAPPING.ADD.STATUS}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_CATEGORY_MAPPING.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_CATEGORY_MAPPING.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddProductCategoryMapping;
