import * as yup from "yup";
import i18next from "i18next";
import { REGEX_CONSTANT } from "../../../../constant/regexConstant";

export const addSchema = yup.object().shape({
  supplier_code: yup
    .string()
    .max(
      50,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(50),
      })
    )
    .matches(
      REGEX_CONSTANT.CODE_SUPPLIER,
      i18next.t("STORE_SUPPLIER_CONNECTION:FIELD_ERROR_CODE_SUPPLIER")
    )
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  pm_handling: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  principal_connection_store_id: yup
    .string()
    .max(
      30,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(30),
      })
    ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  salesman_code: yup
    .string()
    .max(
      35,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(35),
      })
    )
    .matches(
      REGEX_CONSTANT.SALESMAN_CODE,
      i18next.t("STORE_SUPPLIER_CONNECTION:FIELD_ERROR_SALESMAN")
    ),
  salesman_name: yup
    .string()
    .max(
      100,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(100),
      })
    )
    .matches(
      REGEX_CONSTANT.SALESMAN_NAME,
      i18next.t("STORE_SUPPLIER_CONNECTION:FIELD_ERROR_SALESMAN")
    ),
    store_id: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    distributor_id: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    customer_id: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    status: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
});

export const updateSchema = yup.object().shape({
  supplier_code: yup
    .string()
    .max(
      50,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(50),
      })
    )
    .matches(
      REGEX_CONSTANT.CODE_SUPPLIER,
      i18next.t("STORE_SUPPLIER_CONNECTION:FIELD_ERROR_CODE_SUPPLIER")
    )
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  pm_handling: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  principal_connection_store_id: yup
    .string()
    .max(
      30,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(30),
      })
    ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    
  salesman_code: yup
    .string()
    .max(
      35,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(35),
      })
    )
    .matches(
      REGEX_CONSTANT.SALESMAN_NAME,
      i18next.t("STORE_SUPPLIER_CONNECTION:FIELD_ERROR_SALESMAN")
    ),
  salesman_name: yup
    .string()
    .max(
      100,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(100),
      })
    )
    .matches(
      REGEX_CONSTANT.SALESMAN_CODE,
      i18next.t("STORE_SUPPLIER_CONNECTION:FIELD_ERROR_SALESMAN")
    ),
    status: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
});
