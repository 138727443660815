import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  GRAY_DISABLED,
  LABEL_STATUS_INACTIVE,
  STATUS_INACTIVE,
} from "../../../../../constant/propertiesConstant";
import { DateIcon } from "../../../../../components/icons";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../../../services/main/userReducer";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { ID_USER } from "../../../../../constant/idConstant";
const BodyActivation = () => {
  const { t } = useTranslation();
  const { activation } = useSelector((state) => state.user);
  const { index_page } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const onSubmit = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_AUTHENTICATION")));
    // set index page
    dispatch(userAction.setIndexPage(index_page + 1));
    dispatch(
      userAction.setActivation({
        ...activation,
        status: STATUS_INACTIVE,
      })
    );
  };
  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACCESS_SETTING")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
  };

  return (
    <>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* Status  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_STATUS")}
          </Box>
          <Box>
            <Select
              value="N"
              placeholder={LABEL_STATUS_INACTIVE}
              disabled
              backgroundColor={GRAY_DISABLED}
              id={ID_USER.ADD.STATUS}
              height="2.5rem"
              width="250px"
            ></Select>
          </Box>
        </Box>
        {/* Active Period  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_ACTIVE_PERIOD")}
          </Box>
          <Box>
            <InputGroup width="250px">
              <Input
                type="text"
                id={ID_USER.ADD.ACTIVE_PERIOD}
                value="-"
                disabled
                backgroundColor={GRAY_DISABLED}
              />
              <InputRightElement width="3rem">
                <Icon as={DateIcon} />
              </InputRightElement>
            </InputGroup>
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          type="button"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
          id={ID_USER.ADD.BTN_PREVIOUS}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          onClick={onSubmit}
          id={ID_USER.ADD.BTN_NEXT}
        />
      </Box>
    </>
  );
};

export default BodyActivation;
