/* eslint-disable eqeqeq */
import { Box, Select } from "@chakra-ui/react";
import { Pagination, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import SortButton from "../../../components/SortButton";
import { ROUTES } from "../../../constant/routeConstant";
import { getListUserActivity } from "../../../services/main/userActivity";

const Home = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { token } = useSelector((state) => state.auth);
  const { company_name } = useSelector((state) => state.auth);
  const { first_name } = useSelector((state) => state.auth);
  const { last_name } = useSelector((state) => state.auth);
  const { table_loading } = useSelector((state) => state.page);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const params = { page, limit };
      const { ok, response } = await getListUserActivity(params, dispatch);
      if (ok) {
        setData(response.data);
        setTotal(response.count);
      }
    };

    fetchData();
  }, [page, limit, token, dispatch]);
  const dataSource =
    data.length > 0 &&
    data.map((item, index) => ({
      key: index,
      client_id: item.client_id,
      username: item.username,
      last_activity_date: item.time_activity,
      company: item.company_name,
      activity: item.activity,
    }));

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER_ACTIVITY:CLIENT_ID")}
          <SortButton thisField="client_id" />
        </Box>
      ),
      dataIndex: "client_id",
      key: "client_id",
      width: 160,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER_ACTIVITY:USERNAME")}
          <SortButton thisField="client_id" />
        </Box>
      ),
      dataIndex: "username",
      key: "username",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER_ACTIVITY:LAST_ACTIVITY_DATE")}
          <SortButton thisField="client_id" />
        </Box>
      ),
      dataIndex: "last_activity_date",
      key: "last_activity_date",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER_ACTIVITY:COMPANY")}
          <SortButton thisField="client_id" />
        </Box>
      ),
      dataIndex: "company",
      key: "company",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER_ACTIVITY:ACTIVITY")}
          <SortButton thisField="client_id" />
        </Box>
      ),
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("HOME:HI", { name: first_name + " " + last_name  })}
        subtitle="Welcome to Farmindo Website"
        // button={<ButtonPrimary link={ROUTES.USER_ACTIVITY.LIST} text="View All" />}
      />

      <Box marginTop={6}>
        {/* <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          footer={() => (
            <Box backgroundColor="#E5EBF1" display="flex" alignItems="center" justifyContent="end">
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>Row Per Page</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination size="small" current={page} total={total} onChange={(value) => setPage(value)} />
              </Box>
            </Box>
          )}
        /> */}
      </Box>
    </Box>
  );
};

export default Home;
