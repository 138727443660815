import { Box, Center, Divider, Stack } from "@chakra-ui/react";
import { Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  BlueEditIcon,
  DeleteSuccess,
  RedDeleteIcon,
  UnSuccess
} from "../../../../../components/icons";
import ModalConfirm from "../../../../../components/ModalConfirm";
import ModalCustom from "../../../../../components/ModalCustom";
import { ID_DISTRIBUTOR } from "../../../../../constant/idConstant";
import {
  PRIMARY_COLOR,
  WHITE_COLOR
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import {
  deleteDistributor,
  detailDistributor
} from "../../../../../services/main/distributorReducer";
import BodyCompanyProfile from "../detailDistributor/body/BodyCompanyProfile";
import BodyPreferences from "../detailDistributor/body/BodyPreferences";
import BodyContactPerson from "./body/BodyContactPerson";

const DetailDistributor = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dataDetail, setdataDetail] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [alias, setlias] = useState("");
  const payload = { updated_at: updatedAt };
  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };

  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const payload = updatedAt;
    const object = {
      updated_at: payload,
      distributorId: Number(location.state.id),
    };
    dispatch(deleteDistributor(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };

  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };

  const [currentPage, setCurrentPage] = useState(
    t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
  );
  const changePage = (page) => {
    setCurrentPage(page);
  };
  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailDistributor(location.state.id)).then((response) => { 
      if (response.payload.ok) {
        setdataDetail(response.payload.response);
        setUpdatedAt(response.payload.response.distributor.updated_at);
      }
    });
    setIsLoading(false);
  };

  useEffectOnce(() => {
    fetchData();
  });
  const renderBody = () => {
    if (isLoading) {
      return (
        <>
          <Center marginTop={100}>
            <Spin size="small" />
          </Center>
        </>
      );
    } else {
      if (currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE"))
        return (
          <BodyCompanyProfile
            loading={isLoading}
            data={dataDetail?.company_profile}
            datadistributor={dataDetail?.distributor}
            distributorcode={dataDetail?.distributor?.distributor_code}
          />
        );
      if (currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES"))
        return <BodyPreferences data={dataDetail?.preferences} />;
      if (currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON"))
        return <BodyContactPerson />;
    }
  };
  return isDeleted ? (
    <Redirect to={ROUTES_USER_SELLER.DISTRIBUTOR.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("DISTRIBUTOR:DETAIL_DISTRIBUTOR")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("COMMON:FIELD_HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:FIELD_MASTER")}
            </span>{" "}
            - {t("DISTRIBUTOR:DETAIL_DISTRIBUTOR")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              id={ID_DISTRIBUTOR.DETAIL.BTN_DELETE}
              link={{
                pathname: ROUTES_USER_SELLER.DISTRIBUTOR.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              id={ID_DISTRIBUTOR.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.DISTRIBUTOR.LIST}
        name={dataDetail?.distributor?.created_name}
        date={moment(dataDetail?.distributor?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(dataDetail?.distributor?.updated_at).format(
          "DD/MM/YYYY"
        )}
        modifiedBy={dataDetail?.distributor?.updated_name}
        id={ID_DISTRIBUTOR.DETAIL.BTN_BACK}
      />
      {/* body */}
      <Box backgroundColor="white" minHeight="300px" padding={4}>
        <Stack direction="row" marginBottom={4}>
          <ButtonCustom
            text={t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")}
            backgroundcolor={
              currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={() =>
              changePage(t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE"))
            }
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")}
            backgroundcolor={
              currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={() =>
              changePage(t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES"))
            }
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")}
            backgroundcolor={
              currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={() =>
              changePage(t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON"))
            }
          />
        </Stack>

        {/* content of body  */}
        {renderBody()}
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
        idYes={ID_DISTRIBUTOR.DETAIL.BTN_YES}
        idNo={ID_DISTRIBUTOR.DETAIL.BTN_NO}
      />
    </Box>
  );
};

export default DetailDistributor;
