import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE } from "../constant/propertiesConstant";
import { ID_LANGUAGE as idlng } from "../constant/propertiesConstant";
import { getLocalStorage, STORE_KEY } from "../helpers/storageHelper";
import { resources } from "../utils/locale";

const language = getLocalStorage(STORE_KEY.Language);

i18next
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: language ?? DEFAULT_LANGUAGE,
    react: { useSuspense: true },
  });

export default i18next;
