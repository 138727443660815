import React from "react";
import { Popover } from "antd";
import ValidationError from "../validationErrorMessage/ValidationError";

const CustomPopOver = ({ submitOk, isOpen, onChange }) => {
  const content = (
    <div>
      <ValidationError text={"Untuk Pencarian , Minimal 3 Karakter"} />
    </div>
  );
  return (
    <>
      <div>
        <Popover
          content={content}
          trigger="click"
          visible={isOpen}
          onVisibleChange={onChange}
        ></Popover>
      </div>
    </>
  );
};

export default CustomPopOver;
