import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { CheckStatus } from "../constant/permissionConstant";
// import { COLORS } from "../constant/themeConstant";
import {
  CorrectIcon as CheckedIcon,
  CloseIcon as UncheckedIcon,
} from "../components/icons";
import {
  BLACK_COLOR,
  GRAY_COLOR,
  GRAY_DISABLED,
  GRAY_MEDIUM,
  GREEN_COLOR,
  WHITE_COLOR,
} from "../constant/propertiesConstant";

const CustomCheckbox = ({
  name,
  isChecked,
  onChange,
  children,
  ...restProps
}) => {
  return (
    <Flex as="label">
      <input
        name={name}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        hidden
      />
      <Stack
        as="span"
        direction="row"
        alignItems="center"
        height="22px"
        backgroundColor={isChecked ? GREEN_COLOR : GRAY_COLOR}
        paddingY="0"
        paddingX="1.5"
        borderRadius="4px"
        color={WHITE_COLOR}
        fontWeight="400"
        fontSize="sm"
      >
        {isChecked ? <CheckedIcon /> : <UncheckedIcon />}
        <Box fontSize="12">{children}</Box>
      </Stack>
    </Flex>
  );
};

const _lineAfter = {
  content: '""',
  position: "absolute",
  left: "0px",
  top: "50px",
  height: "100%",
  borderLeft: `1px solid ${GRAY_DISABLED}`,
};

const RoleTree = ({
  data,
  onChange,
  onNodeClick,
  onHeaderClick,
  translate,
  translatePrefix,
  translateAfterfix,
  translateButtonAfterfix,
  level = 1,
  ...restProps
} = {}) => {
  const handleParentClick = (event, node, index) => {
    onNodeClick && onNodeClick(event, node, index);
  };

  const handleClickChange = (event, node, permission, index, arr) => {
    onChange && onChange(event, node, permission, index, arr);
  };

  if (!translatePrefix) translatePrefix = "COMMON";
  return (
    <>
      {data &&
        Array.isArray(data) &&
        data.map((node, index, arr) => {
          return (
            <Box
              key={node.key}
              // borderLeft='1px solid black'
              position="relative"
              _after={index !== arr.length - 1 ? _lineAfter : {}}
            >
              <Box position="relative">
                <Box
                  position="absolute"
                  top="40px"
                  left="-6.5px"
                  height="14px"
                  width="14px"
                  bgColor={WHITE_COLOR}
                  border={`3px solid ${GREEN_COLOR}`}
                  borderRadius="50%"
                  zIndex="5"
                />
              </Box>
              <Box
                my="1"
                padding="4"
                borderRadius="8px"
                onClick={(event) => handleParentClick(event, node, index)}
                maxWidth={`${755 - level * 34}px`}
              >
                <Text
                  fontSize="11px"
                  color={GRAY_MEDIUM}
                  fontWeight="500"
                  lineHeight="11px"
                >
                  {translate("COMMON:LEVEL").toUpperCase()} {String(level)}
                </Text>
                <Flex color={BLACK_COLOR} fontWeight="500">
                  {translate ? (
                    <Text>
                      {translate(
                        `${translatePrefix}:${
                          translateAfterfix ?? ""
                        }${node?.label?.toUpperCase()}`
                      )}
                    </Text>
                  ) : (
                    <Text>{node.label}</Text>
                  )}
                </Flex>

                <Stack direction="row">
                  {node?.permission &&
                    node.permission.map((permission, index) => {
                      return (
                        <Box
                          key={permission.key}
                          display={permission?.ignored ? "none" : "block"}
                        >
                          <CustomCheckbox
                            name={permission.key}
                            isChecked={
                              permission.status === CheckStatus.CHECKED
                            }
                            onChange={(event) =>
                              handleClickChange(event, node, permission, index)
                            }
                          >
                            {translate ? (
                              <Text>
                                {translate(
                                  `${translatePrefix}:${
                                    translateButtonAfterfix ?? ""
                                  }${permission.label.toUpperCase()}`
                                )}
                              </Text>
                            ) : (
                              <Text>{permission.label}</Text>
                            )}
                          </CustomCheckbox>
                        </Box>
                      );
                    })}
                </Stack>
              </Box>

              {node.children && Array.isArray(node.children) && (
                <Box marginLeft="18px" paddingLeft="4">
                  <RoleTree
                    data={node.children}
                    onChange={handleClickChange}
                    onNodeClick={handleParentClick}
                    translate={translate}
                    translatePrefix={translatePrefix}
                    translateAfterfix={translateAfterfix}
                    translateButtonAfterfix={translateButtonAfterfix}
                    level={level + 1}
                  />
                </Box>
              )}
            </Box>
          );
        })}
    </>
  );
};

export default RoleTree;
