import { Image } from "@chakra-ui/react"
import NoImagePlaceholder from '../assets/noimage_placeholder.png';

/**
 * 
 * @param {import("@chakra-ui/react").ImageProps} props 
 * @returns 
 */
const ImageCompanyLogo = ({ src, ...restProps} ) => {
  return (
    <Image 
      width='135px'
      height='90px'
      backgroundSize='cover'
      src={src ? src : NoImagePlaceholder}
      {...restProps} 
    />
  );
}

export default ImageCompanyLogo