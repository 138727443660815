import {
  Box,
  Icon,
  Input,
  InputGroup,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Pagination, Select, Table } from "antd";
import { subHours } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  StatusActive,
  StatusNonActive,
} from "../../../../../components/icons";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import { ID_WAREHOUSE } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { selectDropDownWarehouse } from "../../../../../constant/selectOptConstant";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import RenderSearchOrCloseButton from "../../../../../function/RenderSearchOrCloseButton";
import { pageActions } from "../../../../../reducers/pageReducer";
import {
  getInitiateListWarehouse,
  getListWarehouse,
} from "../../../../../services/main/warehouseReducer";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import StringUtil from "../../../../../utils/stringUtil";
const ListWarehouse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const { filters: objectFilter } = useSelector((state) => state.page);
  const { table_loading } = useSelector((state) => state.warehouse);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [filterWarehouse, setFilterWarehouse] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [dropdownValue, setDropdownValue] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [form, setForm] = useState(objectFilter?.form);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  // dropdown filter
  // const [selectDropDownFilter, setSelectDropDownFilter] = useState("");
  // const handleChangeSelect = (name, value, option) => {
  //   setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
  //   setDropdownValue(value);
  // };
  const handleChangeSelect = (name, value, option) => {
    setSelectDropDownFilter(option);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };
  // search funct
  const submitOk = () => {
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      setInputSearch(form);
      setFilterWarehouse(form);
      setPage(1);
      setPopOverOpen(false);
      dispatch(
        pageActions.setFilters({
          selectDropDownFilter: selectDropDownFilter,
          form: form,
        })
      );
      setIsSubmit(!isSubmit);
    }
  };
  const resetFilter = () => {
    setForm("");
    setInputSearch("");
    setFilterWarehouse("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSelectDropDownFilter(null);
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
    dispatch(pageActions.resetFilters());
    setIsSubmit(!isSubmit);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setFilterWarehouse("");
  };
  const fetchData = useRef(() => {});
  const fetchInitiate = useRef(() => {});
  fetchData.current = async () => {
    const filter = StringUtil.formatFilter({
      [selectDropDownFilter?.value]: `like ${objectFilter?.form}`,
    });
    const params = { page, limit, filter, order };
    dispatch(getListWarehouse(params)).then((response) => {
      setData(response.payload.response);
    });
  };
  fetchInitiate.current = () => {
    const filter = StringUtil.formatFilter({
      [selectDropDownFilter?.value]: `like ${objectFilter?.form}`,
    });
    const params = { page, limit, filter, order };
    dispatch(getInitiateListWarehouse(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };
  useEffect(() => {
    fetchInitiate.current();
    fetchData.current();
  }, [page, limit, isSubmit, order, dropdownValue, dispatch]);

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      warehouse_code: item.warehouse_code,
      warehouse_name: item.warehouse_name,
      principal_name: item.principal_name,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div key={index.toString()}>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.WAREHOUSE.EDIT,
              state: item,
            }}
            text={<EditIcon />}
            backgroundcolor="transparent"
            id={ID_WAREHOUSE.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.WAREHOUSE.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
            id={ID_WAREHOUSE.LIST.BTN_VIEW + "_" + item.id}
          />
        </div>
      ),
    }));
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("WAREHOUSE:FIELD_WAREHOUSE_CODE")}
        </Box>
      ),
      dataIndex: "warehouse_code",
      key: "warehouse_code",
      ellipsis: true,
      sorter: (a, b) => a.warehouse_code.localeCompare(b.warehouse_code),
      sortDirections: ["descend", "ascend"],
      width: 130,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("WAREHOUSE:FIELD_WAREHOUSE_NAME")}
        </Box>
      ),
      dataIndex: "warehouse_name",
      key: "warehouse_name",
      sorter: (a, b) => a.warehouse_name.localeCompare(b.warehouse_name),
      sortDirections: ["descend", "ascend"],
      // width: 130,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("WAREHOUSE:FIELD_PRINCIPAL_NAME")}
        </Box>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("WAREHOUSE:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 110,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 110,
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("WAREHOUSE:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("WAREHOUSE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("WAREHOUSE:SUBTITLE_3")}
            </span>{" "}
            - {t("WAREHOUSE:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_SELLER.WAREHOUSE.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("WAREHOUSE:BUTTON_ADD_WAREHOUSE")}
              id={ID_WAREHOUSE.LIST.BTN_ADD}
            />
          </>
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"18.5rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={6}>
          <Select
            placeholder={t("PRODUCT_SUBTITUTE:PLACE_HOLDER_SELECT_FILTER_BY")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            value={selectDropDownFilter}
            size="large"
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            textColor={GRAY_COLOR}
            fontSize="14px"
            showSearch={false}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
          >
            {selectDropDownWarehouse?.map((value, index) => {
              return (
                <>
                  <option key={index.toString()} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `Search By ${selectDropDownFilter?.children}`
                  : ""
              }
              fontSize="14px"
              id={ID_WAREHOUSE.LIST.SEARCH}
              onChange={(e) => handleSearch(e)}
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={form}
            />
            {
              <RenderSearchOrCloseButton
                buttonSearchOrClose={buttonSearchOrClose}
                handleCloseButtonSearch={handleCloseButtonSearch}
              />
            }
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              id={ID_WAREHOUSE.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_WAREHOUSE.LIST.RESET_FILTER}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          rowKey="uid"
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          onChange={handleTableChange}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="50">50</Select.Option>
                  <Select.Option value="100">100</Select.Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  // showQuickJumper
                  id={ID_WAREHOUSE.LIST.PAGINATION}
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListWarehouse;
