import { CheckStatus } from "../../constant/permissionConstant";
import PermissionPrefix from "./PermissionPrefix";
export default class PermissionParser {
  /**
   * Deep Permission Search Function to search our format permission object and execute callback function, usually change permission status
   * @param {object} permissionObj - Permission object, the default permission that we hardcoded for now,
   * @param {string} pathString - Path string that we search, separated with dot notation, ex: 'master.distributor'
   * @param {Function} fn - Callback function that execute after permission has been found, this function have permission object value
   */
  _deepPermissionSearch(permissionObj, pathString, fn) {
    const isFound = false;
    let output = [];
    for (const eachPermission of permissionObj) {
      if (eachPermission.key === pathString) {
        fn && fn(eachPermission);
        return true;
      } else if (eachPermission.children) {
        output = this._deepPermissionSearch(
          eachPermission.children,
          pathString,
          fn
        );
      }
    }

    return output;
  }

  /**
   * Function that parse permission from API to our format
   * @param {object} permissions - Permission array from API
   * @returns
   */
  parse(permissions, permissionObject) {
    const permissionArr = JSON.parse(JSON.stringify(permissions));
    permissionArr.forEach((value) => {
      const [keys] = value.key.split(":");
      const [, ...actualKey] = keys.split(".");
      const stringKeyPath = actualKey.join(".");
      this._deepPermissionSearch(
        permissionObject,
        stringKeyPath,
        ({ permission }) => {
          if (value?.permission) {

            value.permission.forEach((eachTargetPermission) => {
              const [, targetKey] = eachTargetPermission.value.split(":");
              const permissionIndex = permission.findIndex((eachPermission) => {
                const [, key] = eachPermission.key.split(":");
                return key === targetKey;
              });
              if (permission[permissionIndex]?.status) {
  
                permission[permissionIndex].status = CheckStatus.CHECKED;
              }
            });
          }
        }
      );
    });

    return permissionObject;
  }

  /**
   * Function that stringify from our format, to API format
   * @param {object} permissionObject  - Permission object, default permission that hardcoded
   * @param {PermissionPrefix} prefix - Permission prefix, default is SYSADMIN
   * @returns
   */
  stringify(permissionObject, prefix = PermissionPrefix.SYSADMIN) {
    return permissionObject.reduce((prev, current, index) => {
      let activePermission = [];
      for (const p of current.permission) {
        if (p.status === CheckStatus.CHECKED && !Boolean(p?.ignored))
          activePermission.push(`${prefix}${p.key}`);
      }

      if (current.children)
        activePermission = [
          ...activePermission,
          ...this.stringify(current.children, prefix),
        ];

      return [...prev, ...activePermission];
    }, []);
  }
}
