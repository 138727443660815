import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../constant/apiConstant";

export const validateFileImportSo = async (object, token) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import-so`;
  const formData = new FormData();
  formData.append("content", JSON.stringify(object.content));
  formData.append("file", object.file);
  try {
    const response = await baseApi.doPost(url, token, formData);
    if (response) {
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
      result.message = response.data.nexsoft.payload.status.message;
    }
  } catch (error) {
    if (error.response) {
      result.response = error.response;
    } else {
      message.error(i18next.t("COMMON:ERROR_NETWORK"));
    }
  }
  return result;
};
export const confirmFileImportSo = async (object, token) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import-so/confirm`;
  try {
    const response = await baseApi.doPost(url, token, object);
    if (response) {
       result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
      result.message = response.data.nexsoft.payload.status.message;
    }
  } catch (error) {
    if (error.response) {
      result.response = error.response;
    } else {
      message.error(i18next.t("COMMON:ERROR_NETWORK"));
    }
  }
  return result;
 
};

export const confirmInfoFileImportSo = async (object, token) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import/info`;
  let isTrue = false;
  do {
    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
         result.ok = true;
        const customResponse = {
          message_process: response.data.nexsoft.payload.data.content?.message_process,
          ...response.data.nexsoft.payload.data.content.output_data
        }
        result.response = customResponse;
         result.message = customResponse.message_process;
        if (result.message !== "ONPROGRESS") {
          isTrue = true;
        }
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      isTrue = true;
    }
  } while (!isTrue);
  return result;
};

export const downloadJobProccessFileImportSo = async (jobId, token) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/job_process/download/${jobId}`;
  try {
    const response = await baseApi.doGetResponseBlob(url, token);
    if (response) {
      result.ok = true;
      result.response = response;
      result.message = response;
    }
  } catch (error) {
    if (error.response) {
      result.response = error.response;
    } else {
      message.error(i18next.t("COMMON:ERROR_NETWORK"));
    }
  }
  return result;
};
