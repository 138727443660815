import {
  Box,
  Icon,
  Input,
  InputGroup,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import { subHours } from "date-fns";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  SavedSuccess,
  StatusActive,
  StatusNonActive,
  UnSuccess,
  UploadIcon,
} from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ModalImport from "../../../../../components/ModalImport";
import ModalImportPreview from "../../../../../components/ModalImportPreview";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import SortButton from "../../../../../components/SortButton";
import { ID_DISTRIBUTOR } from "../../../../../constant/idConstant";
import { typeDistributor } from "../../../../../constant/importEndPointConstant";
import {
  GREEN_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { selectDropDownDistributor } from "../../../../../constant/selectOptConstant";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import RenderSearchOrCloseButton from "../../../../../function/RenderSearchOrCloseButton";
import { pageActions } from "../../../../../reducers/pageReducer";
import {
  getInitListDistributor,
  getListDistributor,
} from "../../../../../services/main/distributorReducer";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../../utils/importFileUtil";
import StringUtil from "../../../../../utils/stringUtil";
const ListDistributor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { table_loading } = useSelector((state) => state.distributor);
  const { filters: objectFilter } = useSelector((state) => state.page);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [isDisabled, setIsDisabled] = useState(true);
  const [dropdownValue, setDropdownValue] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [form, setForm] = useState(objectFilter?.form);
  const [dataChanged, setDataChanged] = useState(false);
  const [proccessLoading, setProccessLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);

  // pagination , sort
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };

  const handleCancel = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeDistributor },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFile(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
    setProccessLoading(false);
  };

  const importConfirm = async () => {
    const object = {
      type: typeDistributor,
      filename: filename,
      confirm: true,
    };
    const { ok, response } = await confirmFile(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
    setUploadLoading(true);
  };
  const confirmInfo = async (object) => {
    const response = await confirmInfoFile(object, token);
    const totalData = response?.response?.total;
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setFileImport(null);
      setProccessLoading(false);
      setModalOpen(true);
      setErr(true);
      // setResp(t("COMMON:UPLOAD_FAILED"));
      setResp(message);
      setIsModalPreviewOpen(false);
      setUploadLoading(false);
    } else {
      setModalOpen(true);
      setErr(false);
      setResp(message);
      setIsModalPreviewOpen(false);
      setFileImport(null);
      setUploadLoading(false);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
    setUploadLoading(false);
  };
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  // dropdown filter
  const handleChangeSelect = (name, value, option) => {
    setSelectDropDownFilter(option);
  };
  const handleSearch = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };
  // search funct
  const submitOk = () => {
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      setInputSearch(form);
      setPage(1);
      setPopOverOpen(false);
      dispatch(
        pageActions.setFilters({
          selectDropDownFilter: selectDropDownFilter,
          form: form,
        })
      );
      setIsSubmit(!isSubmit);
    }
  };
  const resetFilter = () => {
    setForm("");
    setInputSearch("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSelectDropDownFilter(null);
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
    dispatch(pageActions.resetFilters());
    setIsSubmit(!isSubmit);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  const fetchData = () => {
    const filter = StringUtil.formatFilter({
      [selectDropDownFilter?.value]: `like ${objectFilter?.form}`,
    });
    const params = { page, limit, filter, order };
    dispatch(getListDistributor(params)).then((response) => {
      setData(response.payload.response);
    });
  };
  const fetchInitiate = () => {
    const filter = StringUtil.formatFilter({
      [selectDropDownFilter?.value]: `like ${objectFilter?.form}`,
    });
    const params = { page, limit, filter, order };
    dispatch(getInitListDistributor(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };
  useEffect(() => {
    fetchInitiate();
    fetchData();
  }, [
    page,
    limit,
    dataChanged,
    inputSearch,
    order,
    isSubmit,
    dispatch,
  ]);

  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport.map((item, index) => {
      return columnImport.reduce((prev, accumulator, index) => {
        prev[accumulator] = item[index];
        return prev;
      }, {});
    });
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_code: item.distributor_code,
      distributor_name: item.name,
      principal_name: item.principal_name,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.DISTRIBUTOR.EDIT,
              state: item,
            }}
            text={<EditIcon />}
            backgroundcolor="transparent"
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.DISTRIBUTOR.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
          />
        </div>
      ),
    }));
  const columnsImport = columnImport?.map((value) => {
    return {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t(`DISTRIBUTOR:FIELD_${value}`)}

          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: value,
      key: value,
    };
  });

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_DISTRIBUTOR_CODE")}
        </Box>
      ),
      dataIndex: "distributor_code",
      key: "distributor_code",
      width: 130,
      sorter: (a, b) => a.distributor_code.localeCompare(b.distributor_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_PRINCIPAL_NAME")}
        </Box>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DISTRIBUTOR:FIELD_STATUS")}
        </Box>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      dataIndex: "status",
      width: 120,
      key: "status",
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 110,
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("DISTRIBUTOR:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("COMMON:FIELD_HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:FIELD_MASTER")}
            </span>{" "}
            - {t("DISTRIBUTOR:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_SELLER.DISTRIBUTOR.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("DISTRIBUTOR:BUTTON_9")}
              id={ID_DISTRIBUTOR.LIST.BTN_ADD}
            />
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("COMMON:BUTTON_UPLOAD_FILE")}
              marginStart={2}
              onClick={showModal}
              id={ID_DISTRIBUTOR.LIST.BTN_UPLOAD}
              variant="outline"
              colorScheme="blue"
            />
          </>
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"18.5rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={3}>
          <Select
            placeholder={t("DISTRIBUTOR:PLACE_HOLDER_SELECT_FILTER_BY")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            value={selectDropDownFilter}
            size="large"
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            fontSize="14px"
            showSearch={false}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
          >
            {selectDropDownDistributor?.map((value, index) => {
              return (
                <>
                  <option key={index.toString()} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SEARCH_BY")} ${selectDropDownFilter?.children}`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              id={ID_DISTRIBUTOR.LIST.SEARCH}
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={form}
            />
            {
              <RenderSearchOrCloseButton
                buttonSearchOrClose={buttonSearchOrClose}
                handleCloseButtonSearch={handleCloseButtonSearch}
              />
            }
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              id={ID_DISTRIBUTOR.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              id={ID_DISTRIBUTOR.LIST.RESET_FILTER}
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={table_loading}
          onChange={handleTableChange}
          rowKey="uid"
          size="small"
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  id={ID_DISTRIBUTOR.LIST.ROW_PER_PAGE}
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  id={ID_DISTRIBUTOR.LIST.PAGINATION}
                  // showQuickJumper
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImport
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
      />
      <ModalImportPreview
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
        scrollXLength={3000}
        loading={uploadLoading}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ListDistributor;
