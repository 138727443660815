import i18next from "i18next";
import { API_ERROR_CONNECTION_ABORT, API_ERROR_NETWORK } from "../constant/apiConstant";

/**
 * Object helpers, that inject to formatError function as errorResponseFormatter, 
 * handling when backend error response
 * @param {Error} errorResponse - error object, usually from catch param
 * @returns 
 */
 export const formatErrorResponse = (errorResponse) => {

  const { success, code, message, detail } = errorResponse?.data?.nexsoft?.payload?.status;

  return {
    success,
    code,
    message,
    detail
  };
};

/**
 * Helper formatError for createAsyncThunk rejectWithValue, to standard response format
 * return { success, code, message, detail }
 * @param {Error} error - error object, usually supply from catch
 * @param {Function} errorResponseFormatter - function format error if not clien-side error
 * @returns 
 */
export const formatError = (error, errorResponseFormatter) => {
  const errorObj = { success: false, code: '', message: '', detail: null };

  if (error.code === API_ERROR_NETWORK || error.code === API_ERROR_CONNECTION_ABORT) {
    const localeMessage = i18next.t('COMMON:ERROR_NETWORK');
    errorObj.code = error.code;
    errorObj.message = localeMessage;
    return errorObj;
  }

  return errorResponseFormatter(error.response);
};

/**
 * Helper function that check if dispatch have error rejectValue
 * @param {object} withValue
 * @returns 
 */
 export const checkRejectErrorWithValue = (withValue) => {
  const { error, meta } = withValue ?? {};
  if (
    (error && error?.message.toLowerCase() === 'rejected') ||
    (meta && meta?.requestStatus === 'rejected')
  ) {
    return true;
  }

  return false;
};