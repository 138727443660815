import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import {
  API_URL,
  API_VERSION,
  API_PREFIX,
} from "../../../constant/apiConstant";

const INITIAL_STATE = {
  list_process_po: [],
  detail_process_po: [],
  printed_list_po: [],
  selectedRowKeys: [],
  ids: [],
  table_loading: false,
};

export const getListWarehouseDDS = createAsyncThunk(
  "getListWarehouseDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-connection-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);


export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProcessPO = createAsyncThunk(
  "getListProcessPO",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/process/po`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doGet(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);

export const getInitListProcessPO = createAsyncThunk(
  "monitoring/process/po/initiate?filter=schema eq 1",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/process/po/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const printListProcessPO = createAsyncThunk(
  "printListProcessPO",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/process/po/download`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const previewPo = createAsyncThunk(
  "previewPo",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const token = auth.token;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/process/po/preview`;
    const body = object

    try {
      const response = await baseApi.doPost(url, token,body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailProcessPO = createAsyncThunk(
  "Get Detail Process PO",
  async (ProcessPO, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/monitoring/process/po/${ProcessPO[0]}-${ProcessPO[1]}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

const monitoringListProcessPO = createSlice({
  name: "createmonitoringListProcessPO",
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setSingleCheck(state, action) {
      // if (!payload || typeof payload !== 'number')
      let tempSelected = [...state.selectedRowKeys];
      const isExist = state.selectedRowKeys?.includes(action.payload);
      if (isExist) {
        tempSelected = tempSelected.filter((id) => id !== action.payload);
        state.selectedRowKeys = tempSelected;
        return;
      }
      state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
    },
    setAllCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      let tempsId = action.payload;
      let isExist = false;

      for (const data of tempsId) {
        isExist = false;
        for (const existingId of state.selectedRowKeys) {
          if (data === existingId) {
            isExist = true;
            break;
          }
        }
        if (!isExist) break;
      }

       if (isExist) {
        for (const data of tempsId) {
          tempSelected = tempSelected.filter((id) => id !== data);
          state.selectedRowKeys = tempSelected;
        }
      } else {
        for (const data of tempsId) {
          const isExistOnRedux = state.selectedRowKeys?.includes(data);
          if (!isExistOnRedux) {
           
            state.selectedRowKeys = [...state.selectedRowKeys, data];
            
          }
        }
        // state.selectedRowKeys = Array.from(new Set([...state.selectedRowKeys, ...tempsId]));
      }
    },
    resetIds() {
      return INITIAL_STATE;
    },
    setTableLoadingFalse(state){
      state.table_loading = false
    },
    setTableLoadingTrue(state){
      state.table_loading = true
    },
    resetCBX(state){
      state.selectedRowKeys =[]
    }
  },
  extraReducers: {
    [getListProcessPO.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProcessPO.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_process_po = action.payload.response;
    },
    [getListProcessPO.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [printListProcessPO.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [printListProcessPO.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.printed_list_po = action.payload.response;
    },
    [printListProcessPO.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [detailProcessPO.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProcessPO.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_process_po = action.payload.response;
    },
    [detailProcessPO.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const monitoringListProcessPOReducerAction =
  monitoringListProcessPO.actions;

export default monitoringListProcessPO.reducer;
