import { background, extendTheme } from "@chakra-ui/react";
export const customChakraTheme = extendTheme({
  colors: {
    primaryColor: {
      500: "#003C7C", // you need this
    },
    primaryGray: {
      500: "#555555",
    },
    customgreen: {
      50: "#F0FFF4",
      100: "#C6F6D5",
      // 200: "#16AA83",
      // 300: "#19AC85",
      // 400: "#19AC85",
      500: "#13A780",
      600: "#13A780",
      // 700: "#24B790",
    },
    // greenlantern: {
    //   100: "#13A780",
    //   200: "#16AA83",
    //   300: "#19AC85",
    //   400: "#19AC85",
    //   500: "#13A780",
    //   600: "#13A780",
    //   700: "#24B790",
    // },
  },
  // colorsScheme :{
  //   customGreen :{
  //     500: "#13A780"
  //   },
  // },
  components: {
    Divider: {
      defaultProps: { size: "md" },
      sizes: {
        mid: { borderWidth: "2.5px" },
      },
    },
  },
  // Button: {
  //   sizes: {
  //     sm: {
  //       fontSize: 'md'
  //     }
  //   },
  //   colorsScheme :{
  //     customGreen :{
  //       500: "#13A780"
  //     },
  //   }
  // },
});

export const selectTheme = {
  width: "50%",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
  borderRadius: "6px",
};

export const inputTheme = {
  width: "250px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
  borderRadius: "6px",
};
export const multiSelectTheme = {
  width: "100%",
  maxWidth: "250px",
};

export const detailImageTheme = {
  maxHeight: "150px",
  width: "200px",
};

export const inputMultiTheme = {
  width: "250px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  minHeight: "40px",
  maxHeight: "80px",
  overFlowY: "scroll",
  cursor: "pointer",
  borderRadius: "6px",
};
export const inputForContactPerson = {
  width: "250px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
  borderRadius: "6px",
  // marginRight :"20px",
  // marginLeft :"150px"
};
export const selectOptFilterUserTheme = {
  width: "167px",
  borderRadius: "6px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
};

export const selectOptFilterWarehouse= {
  width: "240px",
  borderRadius: "6px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
};

export const selectOptFilterPrincipal = {
  width: "158px",
  borderRadius: "6px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
};

export const selectOptFilterDistributorTheme = {
  width: "173px",
  borderRadius: "6px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
  lineHeight: "16px",
};

export const selectFilterProcessTheme = {
  width: "111px",
  borderRadius: "6px",
  fontSize: "16px",
  fontWeight: "500",
  fontStyle: "normal",
  height: "40px",
  background: "#FFFFFF",
};

export const selectGeneralFormatOptions = {
  width: "123px",
  fontSize: "12px",
  fontWeight: "500",
  fontStyle: "normal",
  background: "#FFFFFF",
  color: "#999999",
  display: "flex",
  order: "0",
  flexGrow: "0",
};
export const selectGeneralFormatOptionsHeader = {
  width: "203px",
  fontSize: "12px",
  fontWeight: "500",
  fontStyle: "normal",
  background: "#FFFFFF",
  color: "#999999",
  display: "flex",
  order: "0",
  flexGrow: "0",
};
export const selectGeneralFormatOptionsGrid = {
  width: "132px",
  fontSize: "12px",
  fontWeight: "500",
  fontStyle: "normal",
  background: "#FFFFFF",
  color: "#999999",
  display: "flex",
  order: "0",
  flexGrow: "0",
};

export const selectGeneralFormatOptionsFontSize = {
  width: "102px",
  fontSize: "12px",
  fontWeight: "500",
  fontStyle: "normal",
  background: "#FFFFFF",
  color: "#999999",
  display: "flex",
  order: "0",
  flexGrow: "0",
};

export const selectGeneralFormatOptionsFontFamily = {
  width: "115px",
  fontSize: "12px",
  fontWeight: "500",
  fontStyle: "normal",
  background: "#FFFFFF",
  color: "#999999",
  display: "flex",
  order: "0",
  flexGrow: "0",
};

export const selectGeneralFormatOptionsGroupHeading = {
  width: "133px",
  fontSize: "12px",
  fontWeight: "500",
  fontStyle: "normal",
  background: "#FFFFFF",
  color: "#999999",
  display: "flex",
  order: "0",
  flexGrow: "0",
};

export const inputColumnSelection = {
  width: "250px",
  fontSize: "14px",
  fontWeight: "500",
  fontStyle: "normal",
  minHeight: "40px",
  maxHeight: "80px",
  borderRadius: "6px",
};
