import { Box, Flex, FormControl } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWithInstanceListAndInitiate } from "../../services/serviceUtil";
import MapperUtil from "../../utils/mapperUtil";
import ObjectUtil from "../../utils/objectUtil";
import StringUtil from "../../utils/stringUtil";
import MultiSelectDropdown from "../multiSelectDropdown/MultiSelectDropdown";
import MultiSelectDropdownProductMapping from "../multiSelectDropdown/MultiSelectDropdownProductMapping";

const FieldMultiSelectSearchStoreProductMapping = ({
  id,
  name,
  control,
  label,
  placeholder,
  searchPlaceholder,
  isDisabled,
  isOptional,
  isReadOnly,
  isClearable,
  initialFilter,
  values,
  onChange,
  onValueChange,
  isEdit,
}) => {
  const { t } = useTranslation();
  const [isFirstFetchAll, setFirstFetchAll] = useState(false);
  const initialFilterRef = useRef({});
  const { list, isLoading, setNextPage, setFilter } =
    useWithInstanceListAndInitiate({
      isOption: true,
      pathInitiate: "",
      pathList: "customer-store-mapping/filter-product-mapping",
      initialFilter: initialFilter,
    });
  const handleSearchValueChange = (value) => {
    return setFilter(
      StringUtil.formatFilter({
        "store_name||store_code": `like ${value}`,
        ...initialFilterRef.current,
      })
    );
  };

  const handleOnFetchAll = () => {
    if (!isFirstFetchAll) {
      setFirstFetchAll(true);
      //   return getAllNoLimit(StringUtil.formatFilter(initialFilter), "all-store");
    }
    return null;
  };

  const customerOptionList = useMemo(() => {
    const isAll = values[0]?.value === "_all"
    if (ObjectUtil.isEmpty(list)) return [];
    return list.map((data) => ({
      value: data.store_id,
      label: `${data.store_code} - ${data.store_name}`,
      code : data.store_code,
      disabled: isAll,
    }));
  }, [list,values]);
  useEffect(() => {
    setFilter(StringUtil.formatFilter(initialFilter));
    setFirstFetchAll(false);
    if (ObjectUtil.isPropertiesTruthy(initialFilter)) {
      initialFilterRef.current = initialFilter;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilter]);

  return (
    <>
      <FormControl
        id={id}
        // isInvalid={error}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
      >
        <Flex minWidth="400px">
          <Box width="15.625rem">
            <MultiSelectDropdownProductMapping
              id={id}
              name={name}
              values={values}
              placeholder={placeholder}
              options={customerOptionList}
              isFirstFetchAll={isFirstFetchAll}
              onFetchAll={handleOnFetchAll}
              optionMapper={MapperUtil.StoreMapping}
              onChange={onChange}
              onValueChange={onValueChange}
              onSearch={(value) => handleSearchValueChange(value)}
              onScrollToBottom={setNextPage}
              isDisabled={isDisabled}
              isLoading={isLoading}
              code
            />
          </Box>
        </Flex>
      </FormControl>
    </>
  );
};

export default FieldMultiSelectSearchStoreProductMapping;
