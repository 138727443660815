import {
  Box,
  Icon,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { Input } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import {
  DeleteSuccess,
  Eye,
  HideEye,
  SavedSuccess
} from "../../../components/icons";
import ModalCustom from "../../../components/ModalCustom";
import ValidationError from "../../../components/validationErrorMessage/ValidationError";
import { ROUTES } from "../../../constant/routeConstant";
import { inputTheme } from "../../../constant/themeConstant";
import { changePassword } from "../../../services/main/navbarMenuReducer";
import { changePasswordSchema } from "../navBarSchema";
const ChangePassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hideOldPassword, setHideOldPassword] = useState(true);
  const [hideNewPassword, setHideNewPassword] = useState(true);
  const [hideVerifyNewPassword, setHideVerifyNewPassword] = useState(true);

  const [dataChanged, setDataChanged] = useState(false);
  //modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const onSubmit = (values) => {
    dispatch(changePassword(values)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setDataChanged(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setDataChanged(false);
    setModalOpen(false);
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      verify_new_password: "",
    },
    validationSchema: changePasswordSchema(t),
    onSubmit,
    // isInitialValid: login_account.username !== '' ? true : false,
  });
  return dataChanged ? (
    <Redirect to={ROUTES.HOME} />
  ) : (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box padding={4}>
        {/* Head Tittle  */}
        <HeadTitle
          title={t("CHANGE_PASSWORD:TITLE_LIST")}
          subtitle={
            <>
              <span style={{ color: "#003C7C" }}>{t("COMMON:HOME")}</span> -{" "}
              {t("COMMON:MENU_CHANGE_PASSWORD")}
            </>
          }
        />
        {/* White Background  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          marginTop={6}
        >
          {/* Your Password  */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}>
              {t("COMMON:FIELD_PASSWORD")}
            </Box>
            <Box className="my-select-container">
              <InputGroup width="250px">
                <Input
                  onBlur={handleBlur}
                  type={hideOldPassword ? "password" : "text"}
                  autoComplete="off"
                  name="old_password"
                  onChange={handleChange}
                  style={inputTheme}
                  value={values?.old_password}
                />
                <InputRightElement width="3rem">
                  {hideOldPassword ? (
                    <Icon
                      as={Eye}
                      onClick={() => setHideOldPassword(!hideOldPassword)}
                    />
                  ) : (
                    <Icon
                      as={HideEye}
                      onClick={() => setHideOldPassword(!hideOldPassword)}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              {errors.old_password && touched.old_password && (
                <ValidationError text={errors.old_password} />
              )}
            </Box>
          </Box>
          {/* New Password  */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}>
              {t("COMMON:FIELD_NEW_PASSWORD")}
            </Box>
            <Box>
              <InputGroup width="250px">
                <Input
                  onBlur={handleBlur}
                  type={hideNewPassword ? "password" : "text"}
                  autoComplete="off"
                  name="new_password"
                  onChange={handleChange}
                  style={inputTheme}
                  value={values?.new_password}
                />
                <InputRightElement width="3rem">
                  {hideNewPassword ? (
                    <Icon
                      as={Eye}
                      onClick={() => setHideNewPassword(!hideNewPassword)}
                    />
                  ) : (
                    <Icon
                      as={HideEye}
                      onClick={() => setHideNewPassword(!hideNewPassword)}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              {errors.new_password && touched.new_password && (
                <ValidationError text={errors.new_password} />
              )}
            </Box>
          </Box>
          {/* Confirm New Password */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}>
              {t("COMMON:FIELD_CONFIRM_NEW_PASSWORD")}
            </Box>
            <Box>
              <InputGroup width="250px">
                <Input
                  onBlur={handleBlur}
                  type={hideVerifyNewPassword ? "password" : "text"}
                  autoComplete="off"
                  name="verify_new_password"
                  onChange={handleChange}
                  style={inputTheme}
                  value={values?.verify_new_password}
                />
                <InputRightElement width="3rem">
                  {hideVerifyNewPassword ? (
                    <Icon
                      as={Eye}
                      onClick={() =>
                        setHideVerifyNewPassword(!hideVerifyNewPassword)
                      }
                    />
                  ) : (
                    <Icon
                      as={HideEye}
                      onClick={() =>
                        setHideVerifyNewPassword(!hideVerifyNewPassword)
                      }
                    />
                  )}
                </InputRightElement>
              </InputGroup>
              {errors.verify_new_password && touched.verify_new_password && (
                <ValidationError text={errors.verify_new_password} />
              )}
            </Box>
          </Box>
        </Box>

        {/* Button  */}
        <Box display="flex" marginTop={4} float="right">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES.HOME}
            text={t("COMMON:BUTTON_CANCEL")}
          />
          <ButtonPrimary
            width="9.375rem"
            type="submit"
            text={t("COMMON:BUTTON_SAVE")}
          />
        </Box>
        <ModalCustom
          message={resp}
          isOpen={modalOpen}
          onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Box>
    </form>
  );
};
export default ChangePassword;
