import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, RedDeleteIcon, SavedSuccess } from "../../../../../components/icons";
import ModalConfirm from "../../../../../components/ModalConfirm";
import ModalCustom from "../../../../../components/ModalCustom";

import { PRIMARY_COLOR } from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../../constant/routeConstant";

import { deleteProcessedPO, detailProcessedPO, getInitTransactionDetailPO, getListTransactionDetailPO } from "../../../../../services/main/processedPOReducer";
import TransactionDetail from "./TransactionDetail";
import TransactionTotal from "./TransactionTotal";

const DetailProcessPO = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [ messageConfirm, setMessageConfirm] = useState("")
  const [deletedProduct, setDeletedProduct] = useState(false);

  const process_po = useSelector(
    (data) => data.processedPO?.detail_processed_po?.process_po
  );
  const data_management_history = useSelector(
    (data) => data.processedPO?.detail_processed_po?.data_management_history
  );
  const fetchData = async () => {
    dispatch(detailProcessedPO(location.state));
  };
  useEffect(() => {
    fetchData();
  }, []);

  // fungsi modal delete
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.PROCESS.LIST);
    setModalOpen(false)
  };
  const modalFailed = () => {
    setModalConfirm(false)
    setModalOpen(false);
  };
  const confirmModal = () => {
    setModalConfirm(true);
    setMessageConfirm(t("COMMON:DELETE_CONFIRM"))
    
  }

   // fungsi delete
   const confirmedDelete = () => {
    setModalConfirm(false);
    const Id = location.state[0]
    const Principal = location.state[1]
    const updated_at =data_management_history?.modified_on
    const params = {Id, updated_at, Principal}
    dispatch(deleteProcessedPO(params)).then((resp) => {
      if (resp.payload.ok) {
        setErr(true);
        setModalOpen(true);
        setResponse(resp.payload.response);
        setDeletedProduct(true);
        
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response);
        setErr(true);
      }
    });
  }
  return (
    <Box p={4}>
      <HeadTitle
        title={t("PROCESSED:DETAIL_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCESSED:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCESSED:SUBTITLE_3")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCESSED:TITLE_LIST")}
            </span>{" "}
            - {t("PROCESSED:SUBTITLE_4")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={confirmModal}
              id="btn-UserSeller-ProductPrincipal-DetailProductPrincipal-Delete"
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
          link={ROUTES_USER_BUYER.PROCESS.LIST}
          name={data_management_history?.created_name}
          date={moment(data_management_history?.created_on).format("DD/MM/YYYY")}
          modifiedDate={moment(data_management_history?.modified_on).format(
            "DD/MM/YYYY"
          )}
          modifiedBy={data_management_history?.modified_name}
        />
      {/* White Box section */}
      <Box
        backgroundColor="white"
        minHeight="277px"
        padding={4}
        display="flex"
        justifyContent="space-between"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box className="column-po">
          {/* PO Number */}
          <Box className="list-po">
            <Box>{t("PROCESSED:FIELD_PO_NUMBER")}</Box>
            <Box>
              <Text>
                <b>{process_po?.po_number === "" ? "-" : process_po?.po_number}</b>
              </Text>
            </Box>
          </Box>
          {/* Distributor*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCESSED:FIELD_DISTRIBUTOR")}</Text>
              <Text>
                <b>{process_po?.distributor_name === "" ? "-" : process_po?.distributor_name}</b>
              </Text>
            </Box>
          </Box>
          {/* Transaction Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("PROCESSED:FIELD_TRANSACTION_DATE")}</Text>
            </Box>
            <Text>
              <b>{moment(process_po?.transaction_date).format("DD/MM/YYYY") === "" ? "-" : moment(process_po?.transaction_date).format("DD/MM/YYYY")}</b>
            </Text>
          </Box>
          {/* Delivery Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("PROCESSED:FIELD_DELIVERY_DATE")}</Text>
            </Box>
            <Text>
              <b>{moment(process_po?.delivery_date).format("DD/MM/YYYY") === "" ? "-" : moment(process_po?.delivery_date).format("DD/MM/YYYY")}</b>
            </Text>
          </Box>
        </Box>
        {/* Middle side */}
        <Box className="column-po">
          {/* Expired Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("PROCESSED:FIELD_EXPIRED_DATE")}</Text>
            </Box>
            <Text>
              <b> {moment(process_po?.expired_date).format("DD/MM/YYYY") }</b>
            </Text>
          </Box>
          {/* Process Date*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCESSED:FIELD_PROCESS_DATE")}</Text>
            </Box>
            <Text>
              <b>{moment(process_po?.process_date).format("DD/MM/YYYY")}</b>
            </Text>
          </Box>
          {/* REMARK*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCESSED:FIELD_REMARK")}</Text>
            </Box>
            <Text>
              <b>{process_po?.remarks === "" ? "-" : process_po?.remarks}</b>
            </Text>
          </Box>
          {/* TOP*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("PROCESSED:FIELD_TOP")}</Text>
            </Box>
            <Text>
              <b>{process_po?.top === "" ? "-" : process_po?.top}</b>
            </Text>
          </Box>
        </Box>

        {/* Right side */}
        <Box className="column-po">
          {/* Address */}
          <Box className="list-po">
            <Box>{t("PROCESSED:FIELD_REPROCESS")}</Box>
            <Box>
              <Text display="flex">
                <b> {data_management_history?.reprocess_status } </b>
              </Text>
            </Box>
          </Box>
          <Box className="list-po">
            <Box>{t("PROCESSED:FIELD_REPROCESS_TIME")}</Box>
            <Box>
              <Text display="flex">
                <b> { moment(data_management_history?.modified_on).format("YYYY-MM-DD HH:MM:SS")} </b>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Transaction Total Section */}
      <TransactionTotal />
      {/* Transaction Detail Section */}
      <TransactionDetail />
      <ModalConfirm
        isOpen={modalConfirm}
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={messageConfirm}
        onOk={confirmedDelete}
        onClose={modalFailed}
        confirmYes={t("COMMON:YES")}
        confirmNo={t("COMMON:NO")}
        />
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={deletedProduct ? modalSuccess : modalFailed }
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
    </Box>
  );
};

export default DetailProcessPO;
