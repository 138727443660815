import * as yup from "yup";
import i18next from "i18next";
import { REGEX_CONSTANT } from "../../../../constant/regexConstant";

export const addSchema = yup.object().shape({
  notification_code: yup
    .string()
    .max(
      100,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(100),
      })
    )
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    sub_category:yup
    .string().when("category", {
    is: (valuecategory) => {
      return valuecategory === "FAILED"
    },
      then: yup
        .string()
        .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    }),
    name: yup
    .string()
    .max(
      100,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(100),
      })
    ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  distributor_id: yup
    .string()
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  principal_id: yup
    .string()
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  notification_po_to: yup
    .string().when("category", {
      is: (valuecategory) => valuecategory === "SUCCESS",
      then: yup
        .string()
        .max(
          5000,
          i18next.t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(5000),
          })
        ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
    }),
  notification_po_cc: yup
    .string()
    .max(
      5000,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(5000),
      })
    ),
  alert_to: yup.string().when("category", {
    is: (valuecategory) => {
      return valuecategory === "FAILED"
    },
    then: yup
      .string()
      .max(
        5000,
        i18next.t("COMMON:ERROR_MAX_LENGTH", {
          length: i18next.t(5000),
        })
      ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  }),

  alert_cc: yup
    .string()
    .max(
      5000,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(5000),
      })
    ),
    
  status: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
});

export const updateSchema = yup.object().shape({
  notification_code: yup
    .string()
    .max(
      100,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(100),
      })
    ),
  name: yup
    .string()
    .max(
      100,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(100),
      })
    ),
  distributor_id: yup
    .string()
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  principal_id: yup
    .string()
    .required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  notification_po_to:  yup
  .string().when("category", {
    is: (valuecategory) => valuecategory === "SUCCESS",
    then: yup
      .string()
      .max(
        5000,
        i18next.t("COMMON:ERROR_MAX_LENGTH", {
          length: i18next.t(5000),
        })
      ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  }),
  notification_po_cc: yup
    .string()
    .max(
      5000,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(5000),
      })
    ),
  alert_to: yup.string().when("category", {
    is: (valuecategory) => {
      return valuecategory === "FAILED"
    },
    then: yup
      .string()
      .max(
        5000,
        i18next.t("COMMON:ERROR_MAX_LENGTH", {
          length: i18next.t(5000),
        })
      ).required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
  }),
  alert_cc: yup
    .string()
    .max(
      5000,
      i18next.t("COMMON:ERROR_MAX_LENGTH", {
        length: i18next.t(5000),
      })
    ),
  status: yup.string().required(i18next.t("PRODUCT_CUSTOMER:FIELD_EMPTY")),
});
