import {
  Box, Text
} from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import CustomToggle from "../../../../../components/customToggle/CustomToggle";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_WAREHOUSE } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR, PRIMARY_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
import {
  selectDropDownGroup,
  selectStatus
} from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage
} from "../../../../../services/main/regionalReducer";
import {
  detailWarehouse,
  editWarehouse,
  getListPrincipal
} from "../../../../../services/main/warehouseReducer";
import { formatFilter } from "../../../../../utils/stringUtil";
import { editWarehouseSchema } from "../warehouseSchema";
const AddWarehouse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const { company_name } = useSelector((state) => state.auth);
  const principalToLowerCase = company_name?.toLowerCase();
  const isDanone = principalToLowerCase.includes("danone");

  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState({});
  const [isLoading, setIsLoading] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");

  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  // list regional
  const [list_country, setListCountry] = useState([]);
  const [list_island, setListIsland] = useState([]);
  const [list_province, setListProvince] = useState([]);
  const [list_district, setListDistrict] = useState([]);
  const [list_sub_district, setListSubDistrict] = useState([]);
  const [list_urban_village, setListUrbanVillage] = useState([]);
  const [list_postal_code, setListPostalCode] = useState([]);

  // for switch(toogle)
  const [isDPDRules, setIsDPDRules] = useState(false);
  const handleSwitchChange = () => {
    // const value = !isDPDRules;
    const value = !values.is_dpd_rules;
    setFieldValue("is_dpd_rules", value ? true : false);
    // setIsDPDRules(value);
    setIsDPDRules(!values.is_dpd_rules);
  };

  // Code(for disabled input)
  const [principalCode, setPrincipalCode] = useState("");

  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const onSubmit = () => {
    const warehouseId = location.state.id;
    const payload = {
      ...values,
      warehouseId,
    };
    dispatch(editWarehouse(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      warehouse_code: dataDetail?.warehouse_code,
      warehouse_name: dataDetail?.warehouse_name,
      address_1: dataDetail?.address,
      address_2: "",
      address_3: "",
      country_id: dataDetail?.country_id !== 0 ? dataDetail?.country_id : null,
      island_id: dataDetail?.island_id !== 0 ? dataDetail?.island_id : null,
      province_id:
        dataDetail?.province_id !== 0 ? dataDetail?.province_id : null,
      district_id:
        dataDetail?.district_id !== 0 ? dataDetail?.district_id : null,
      sub_district_id:
        dataDetail?.sub_district_id !== 0 ? dataDetail?.sub_district_id : null,
      urban_village_id:
        dataDetail?.urban_village_id !== 0
          ? dataDetail?.urban_village_id
          : null,
      postal_code_id:
        dataDetail?.postal_code_id !== 0 ? dataDetail?.postal_code_id : null,
      is_dpd_rules: dataDetail?.is_dpd_rules,
      depo_name: dataDetail?.additional_data?.depo_name,
      group: dataDetail?.additional_data?.group,
      status: dataDetail?.status,
      updated_at: updatedAt,
    },
    validationSchema: editWarehouseSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  }); 
  const fetchData = async () => {
    const params = { page, limit };
    await dispatch(getListCountry(params)).then((response) => {
      setListCountry(response.payload.response);
    });
    await dispatch(getListIsland(params)).then((response) => {
      setListIsland(response.payload.response);
    });
    await dispatch(getListProvince(params)).then((response) => {
      setListProvince(response.payload.response);
    });
    await dispatch(getListDistrict(params)).then((response) => {
      setListDistrict(response.payload.response);
    });
    await dispatch(getListSubDistrict(params)).then((response) => {
      setListSubDistrict(response.payload.response);
    });
    await dispatch(getListUrbanVillage(params)).then((response) => {
      setListUrbanVillage(response.payload.response);
    });
    await dispatch(getListPostalCode(params)).then((response) => {
      setListPostalCode(response.payload.response);
    });
  };

  // fetch data detail
  const fetchDataDetail = () => {
    setIsLoading(true);
    dispatch(detailWarehouse(location.state.id)).then((response) => { 
      setIsDPDRules(response.payload.response.is_dpd_rules);
      if (response.payload.ok) {
        setUpdatedAt(response.payload.response.updated_at);
        setDataDetail(response.payload.response);
      }
    });
    setIsLoading(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  // country
  const [inputSearchDDSCountry, setInputSearchDDSCountry] = useState("");
  const filterSearchDDSCountry = `name like ${inputSearchDDSCountry}`;
  const fetchDataCountry = () => {
    const page = 1;
    const limit = 100;
    const filter = filterSearchDDSCountry;
    const params =
      inputSearchDDSCountry === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCountry(params)).then((response) => {
      setListCountry(response.payload.response);
    });
  };
  // island
  const [searchIsland, setSearchIsland] = useState("");
  const ddsIsland = useMemo(() => {
    return list_island?.filter((values) =>
      values?.name.toLowerCase().includes(searchIsland.toLowerCase())
    );
  }, [list_island, searchIsland]);
  // province
  const [searchProvince, setSearchProvince] = useState("");
  const ddsProvince = useMemo(() => {
    return list_province?.filter((values) =>
      values?.name.toLowerCase().includes(searchProvince.toLowerCase())
    );
  }, [list_province, searchProvince]);
  // district
  const [searchDistrict, setSearchDistrict] = useState("");
  const ddsDistrict = useMemo(() => {
    return list_district?.filter((values) =>
      values?.name.toLowerCase().includes(searchDistrict.toLowerCase())
    );
  }, [list_district, searchDistrict]);
  // sub district
  const [searchSubDistrict, setSearchSubDistrict] = useState("");
  const ddsSubDistrict = useMemo(() => {
    return list_sub_district?.filter((values) =>
      values?.name.toLowerCase().includes(searchSubDistrict.toLowerCase())
    );
  }, [list_sub_district, searchSubDistrict]);
  // urban_viilage
  const [searchUrbanVillage, setSearchUrbanVillage] = useState("");
  const ddsUrbanVillage = useMemo(() => {
    return list_urban_village?.filter((values) =>
      values?.name.toLowerCase().includes(searchUrbanVillage.toLowerCase())
    );
  }, [list_urban_village, searchUrbanVillage]);
  // postal
  const [searchPostalCode, setSearchPostalCode] = useState("");
  const ddsPostalCode = useMemo(() => {
    return list_postal_code?.filter((values) =>
      values?.code.includes(searchPostalCode)
    );
  }, [list_postal_code, searchPostalCode]);

  const handleChangeSelect = (name, value, option) => {
    if (name === "country_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ country_id: `eq ${value}` }),
        };

        dispatch(getListProvince(params)).then((response) => {
          setListProvince(response.payload.response);
        });
        dispatch(getListIsland(params)).then((response) => {
          setListIsland(response.payload.response);
        });
      }
      setFieldValue("country_id", value);
      setFieldValue("province_id", null);
      setFieldValue("island_id", null);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "province_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ province_id: `eq ${value}` }),
        };
        dispatch(getListDistrict(params)).then((response) => {
          setListDistrict(response.payload.response);
        });
      }
      setFieldValue("province_id", value);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ district_id: `eq ${value}` }),
        };
        dispatch(getListSubDistrict(params)).then((response) => {
          setListSubDistrict(response.payload.response);
        });
      }
      setFieldValue("district_id", value);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "sub_district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ sub_district_id: `eq ${value}` }),
        };
        dispatch(getListUrbanVillage(params)).then((response) => {
          setListUrbanVillage(response.payload.response);
        });
      }
      setFieldValue("sub_district_id", value);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "urban_village_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ urban_village_id: `eq ${value}` }),
        };
        dispatch(getListPostalCode(params)).then((response) => {
          setListPostalCode(response.payload.response);
        });
      }
      setFieldValue("urban_village_id", value);
      setFieldValue("postal_code_id", null);
    } else {
      setFieldValue(name, value);
    }
  };

  //use effect once
  useEffectOnce(() => {
    fetchDataDetail();
  });
  useEffect(() => {
    fetchData();
  }, []);
  //country
  useEffect(() => {
    fetchDataCountry();
  }, [filterSearchDDSCountry]);
  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);
  // useEffect(() => {
  //   setIsDPDRules(dataDetail?.is_dpd_rules);
  // }, []);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.WAREHOUSE.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("WAREHOUSE:EDIT_WAREHOUSE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("WAREHOUSE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("WAREHOUSE:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("WAREHOUSE:TITLE_LIST")}
            </span>{" "}
            - {t("WAREHOUSE:EDIT_WAREHOUSE")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* Warehouse Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("WAREHOUSE:FIELD_WAREHOUSE_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  size="large"
                  name="warehouse_code"
                  value={values?.warehouse_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={inputTheme}
                  id={ID_WAREHOUSE.EDIT.WAREHOUSE_CODE}
                />
                {errors.warehouse_code && touched.warehouse_code && (
                  <ValidationError text={errors.warehouse_code} />
                )}
              </Box>
            </Box>
            {/* Warehouse Name*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("WAREHOUSE:FIELD_WAREHOUSE_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  size="large"
                  value={values?.warehouse_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="warehouse_name"
                  style={inputTheme}
                  id={ID_WAREHOUSE.EDIT.WAREHOUSE_NAME}
                />
                {errors.warehouse_name && touched.warehouse_name && (
                  <ValidationError text={errors.warehouse_name} />
                )}
              </Box>
            </Box>
            {/* Principal Code*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("WAREHOUSE:FIELD_PRINCIPAL_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_WAREHOUSE.EDIT.PRINCIPAL_CODE}
                  disabled
                  value={principalCode ? principalCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Principal Name*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("WAREHOUSE:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_WAREHOUSE.EDIT.PRINCIPAL_NAME}
                  disabled
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Depo Name*/}
            {isDPDRules ? (
              <Box padding={1} display="flex">
                <Box width="10rem">
                  <Text overflowWrap="break-word" width="120px">
                    {t("WAREHOUSE:FIELD_DEPO_NAME")}
                  </Text>
                </Box>
                <Box>
                  <Input
                    type="text"
                    size="large"
                    name="depo_name"
                    value={values?.depo_name}
                    onChange={handleChange}
                    id={ID_WAREHOUSE.EDIT.DEPO_NAME}
                    onBlur={handleBlur}
                    style={inputTheme}
                  />
                  {errors.depo_name ? (
                    <ValidationError text={errors.depo_name} />
                  ) : null}
                </Box>
              </Box>
            ) : (
              ""
            )}
            {/* Group*/}
            {isDPDRules ? (
              <Box padding={1} display="flex">
                <Box width="10rem">
                  <Text overflowWrap="break-word" width="120px">
                    {t("WAREHOUSE:FIELD_GROUP")}
                  </Text>
                </Box>
                <Box>
                  <Select
                    style={inputTheme}
                    size="large"
                    name="group"
                    value={values?.group}
                    onBlur={handleBlur("group")}
                    id={ID_WAREHOUSE.EDIT.GROUP}
                    onChange={(value, opt) =>
                      handleChangeSelect("group", value, opt)
                    }
                  >
                    {selectDropDownGroup.map((value, index) => {
                      return (
                        <Select.Option
                          value={value.value}
                          key={index.toString()}
                        >
                          {t(value.label)}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  {errors.group ? (
                    <ValidationError text={errors.group} />
                  ) : null}
                </Box>
              </Box>
            ) : null}
            {isDanone ? (
              <>
                {/* Is DPD Rules*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("WAREHOUSE:FIELD_IS_DPD_RULES")}
                    </Text>
                  </Box>
                  <Box>
                    <CustomToggle
                      onChange={() => handleSwitchChange()}
                      textChecked={t("COMMON:YES")}
                      textUnChecked={t("COMMON:NO")}
                      id={ID_WAREHOUSE.EDIT.IS_DPD_RULES}
                      isChecked={isDPDRules}
                      size="lg"
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <></>
            )}

            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("WAREHOUSE:FIELD_STATUS")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  id={ID_WAREHOUSE.EDIT.STATUS}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index.toString()}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* address */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:TITTLE_RIGHT")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  name="address_1"
                  style={inputTheme}
                  value={values.address_1}
                  onChange={handleChange}
                  id={ID_WAREHOUSE.EDIT.ADDRESS}
                  onBlur={handleBlur}
                />
                {errors.address_1 && touched.address_1 && (
                  <ValidationError text={errors.address_1} />
                )}
              </Box>
            </Box>
            {/* country */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:FIELD_COUNTRY")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  width="250px"
                  size="large"
                  id={ID_WAREHOUSE.EDIT.COUNTRY}
                  allowClear="true"
                  showSearch={false}
                  value={values?.country_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("country_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setInputSearchDDSCountry(e.target.value)}
                    />
                  )}
                >
                  {list_country?.map((value, index) => {
                    return (
                      <Select.Option value={value.id} key={index.toString()}>
                        {value.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            {/* island */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("USER:FIELD_ISLAND")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  width="250px"
                  size="large"
                  showSearch={false}
                  id={ID_WAREHOUSE.EDIT.ISLAND}
                  value={values?.island_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("island_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) => setSearchIsland(event.target.value)}
                    />
                  )}
                  disabled={values?.country_id === null ? true : false}
                  allowClear="true"
                >
                  {ddsIsland?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.name}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            {/* province */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:FIELD_PROVINCE")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  allowClear="true"
                  size="large"
                  disabled={values?.country_id ? false : true}
                  value={values?.province_id}
                  id={ID_WAREHOUSE.EDIT.PROVINCE}
                  onChange={(value, opt) =>
                    handleChangeSelect("province_id", value, opt)
                  }
                  showSearch={false}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) => setSearchProvince(event.target.value)}
                    />
                  )}
                >
                  {ddsProvince?.map((value, index) => {
                    return (
                      <Select.Option value={value.id} key={index.toString()}>
                        {value.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            {/* district */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:FIELD_DISTRICT")}</Text>
              </Box>
              <Box>
                <Select
                  allowClear="true"
                  style={inputTheme}
                  id={ID_WAREHOUSE.EDIT.DISTRICT}
                  showSearch={false}
                  size="large"
                  disabled={values?.province_id ? false : true}
                  value={values?.district_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("district_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) => setSearchDistrict(event.target.value)}
                    />
                  )}
                >
                  {ddsDistrict?.map((value, index) => {
                    return (
                      <Select.Option value={value.id} key={index.toString()}>
                        {value.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            {/* sub district */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:FIELD_SUB_DISTRICT")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_WAREHOUSE.EDIT.SUB_DISTRICT}
                  disabled={values?.district_id ? false : true}
                  value={values?.sub_district_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("sub_district_id", value, opt)
                  }
                  showSearch={false}
                  allowClear="true"
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) =>
                        setSearchSubDistrict(event.target.value)
                      }
                    />
                  )}
                >
                  {ddsSubDistrict?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.name}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>

            {/* urban village */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:FIELD_URBAN_VILLAGE")}</Text>
              </Box>
              <Box>
                <Select
                  showSearch={false}
                  style={inputTheme}
                  disabled={values?.sub_district_id ? false : true}
                  allowClear="true"
                  id={ID_WAREHOUSE.EDIT.URBAN_VILLAGE}
                  value={values?.urban_village_id}
                  size="large"
                  onChange={(value, opt) =>
                    handleChangeSelect("urban_village_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) =>
                        setSearchUrbanVillage(event.target.value)
                      }
                    />
                  )}
                >
                  {ddsUrbanVillage?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.name}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            {/* postal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("USER:FIELD_POSTAL_CODE")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_WAREHOUSE.EDIT.POSTAL_CODE}
                  showSearch={false}
                  disabled={values.urban_village_id ? false : true}
                  name="postal_code_id"
                  value={values?.postal_code_id}
                  allowClear="true"
                  onChange={(value, opt) =>
                    handleChangeSelect("postal_code_id", value, opt)
                  }
                  size="large"
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) =>
                        setSearchPostalCode(event.target.value)
                      }
                    />
                  )}
                >
                  {ddsPostalCode?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.code}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
          {/* end of right side */}
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.WAREHOUSE.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_WAREHOUSE.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_WAREHOUSE.EDIT.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddWarehouse;
