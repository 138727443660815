import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  table_loading: false,
  add_loading: false,
  status: "",
  list_distributor_option: [],
  list_store_option: [],
  list_principal: [],
  list_product_principal: [],
  list_product_customer: [],
  additional_data_qty_dpd: false,
  additional_data_warehouse_id: false,
};

export const getListProductMapping = createAsyncThunk(
  "getListProductMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getInitiateListProductMapping = createAsyncThunk(
  "getInitiateListProductMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailProductMapping = createAsyncThunk(
  "detailProductMapping",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addProductMapping = createAsyncThunk(
  "addProductMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      customer_id: object?.customer_id,
      store_id: object?.store_id,
      distributor_id: object?.distributor_id,
      product_principal_id: object?.product_principal_id,
      product_customer_id: object?.product_customer_id,
      additional_data: {
        warehouse_id: object?.warehouse_id,
        qty_dpd: Number(object?.qty_dpd),
      },
      uom_1: object?.uom_1,
      uom_2: object?.uom_2,
      uom_3: object?.uom_3,
      uom_4: object?.uom_4,
      conv_1_to_4: Number(object?.conversion_1_to_4),
      conv_2_to_4: Number(object?.conversion_2_to_4),
      conv_3_to_4: Number(object?.conversion_3_to_4),
      order_unit: object?.order_unit,
      status: object?.status,
    };
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editProductMapping = createAsyncThunk(
  "editProductMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      customer_id: object?.customer_id?.value,
      // store_id: object?.store_id?.map((val) => {
      //   return val.value;
      // }),
      store_id: [object?.store_id?.value],
      // distributor_id: object?.distributor_id?.map((val) => {
      //   return val.value;
      // }),
      distributor_id: [object?.distributor_id?.value],
      product_principal_id: object?.product_principal_id?.value,
      product_customer_id: object?.product_customer_id?.value,
      product_category_id: object?.product_category_id?.value,
      status: object?.status,
      additional_data: {
        warehouse_id: object?.warehouse_id?.value,
        qty_dpd: Number(object?.qty_dpd),
      },
      updated_at: object.updatedAt,
      uom_1: object?.uom_1,
      uom_2: object?.uom_2,
      uom_3: object?.uom_3,
      uom_4: object?.uom_4,
      conv_1_to_4: Number(object?.conversion_1_to_4),
      conv_2_to_4: Number(object?.conversion_2_to_4),
      conv_3_to_4: Number(object?.conversion_3_to_4),
      order_unit: object?.order_unit,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping/${object.productId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const deleteProductMapping = createAsyncThunk(
  "deleteProductMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      productId: object.productId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const initiateInputProductMapping = createAsyncThunk(
  "initiateInputProductMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping/initiateInput`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorOption = createAsyncThunk(
  "getListDistributorOption",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributorOptionDDS = createAsyncThunk(
  "getListDistributorOptionDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStoreOption = createAsyncThunk(
  "getListStoreOption",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStoreOptionDDS = createAsyncThunk(
  "getListStoreOptionDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);


export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCustomer = createAsyncThunk(
  "getListProductCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCustomerDDS = createAsyncThunk(
  "getListProductCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductPrincipal = createAsyncThunk(
  "getListProductPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductPrincipalDDS = createAsyncThunk(
  "getListProductPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListWarehouse = createAsyncThunk(
  "getListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListWarehouseDDS = createAsyncThunk(
  "getListWarehouseDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCSMForDistributor = createAsyncThunk(
  "getListCSMForDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/filter-product-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCSMForStore = createAsyncThunk(
  "getListCSMForStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/filter-product-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const productMapping = createSlice({
  name: "productMapping",
  initialState: INITIAL_STATE,
  reducers: {
    setQtyDpdActive(state, action) {
      state.additional_data_qty_dpd = action.payload;
    },
    setWarehouseActive(state, action) {
      state.additional_data_warehouse_id = action.payload;
    },
    setAddLoadingFalse(state) {
      state.add_loading = false;
    },
    resetReduxProductMapping() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // product mapping
    [getListProductMapping.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductMapping.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list = action.payload.response;
    },
    [getListProductMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [getInitiateListProductMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProductMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProductMapping.rejected]: (state, action) => {
      state.status = "failed";
    },

    [detailProductMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailProductMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [addProductMapping.pending]: (state, action) => {
      state.status = "loading";
      state.add_loading = true;
    },
    [addProductMapping.fulfilled]: (state, action) => {
      state.status = "success";
      state.add_loading = false;
    },
    [addProductMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.add_loading = false;
      state.errorNotification = action.payload;
    },

    [editProductMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [editProductMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editProductMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [deleteProductMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    // distributor
    [getListDistributorOption.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListDistributorOption.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_distributor_option = action.payload.response;
    },
    [getListDistributorOption.rejected]: (state, action) => {
      state.status = "failed";
    },

    // store
    [getListStoreOption.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListStoreOption.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_store_option = action.payload.response;
    },
    [getListStoreOption.rejected]: (state, action) => {
      state.status = "failed";
    },

    // principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    // product principal
    [getListProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_principal = action.payload.response;
    },
    [getListProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
    },
    // product customer
    [getListProductCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListProductCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
    // customer
    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
  },
});
export const productMappingAction = productMapping.actions;

export default productMapping.reducer;
