import { useCallback, useMemo, useState } from "react";

const useStateHistory = (initialValue, maxHistories = 10, { minuseMaxHistories = 1 } = {}) => {
  const [history, setHistory] = useState([ initialValue ]);
  const [index, setIndex] = useState(0);

  const state = useMemo(() => history[index], [index, history]);

  const setState = useCallback((newState) => {
    if (maxHistories > 0 && history.length > maxHistories - minuseMaxHistories ) {
      setHistory((history) => [...history.slice(1, history.length), newState]);
      // setIndex(history.length - 1);
    } else {
      setHistory((history) => [...history, newState]);
      setIndex(history.length);
    }
  }, [history, maxHistories]);

  const undo = useCallback(() => {
    if (index > 0) return setIndex((index) => index - 1);
  }, [index]);

  const redo = useCallback(() => {
    if (index < history.length - 1) return setIndex((index) => index + 1);
  }, [index, history]);

  const reset = useCallback(() => {
    setHistory([ initialValue ]);
    setIndex(0);
  }, [initialValue]);

  return [state, setState, { history,setHistory, index, setIndex, undo, redo, reset }];
}

export default useStateHistory;