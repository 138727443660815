import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_PRODUCT_PRINCIPAL } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR, PRIMARY_COLOR
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import {
  detailProductPrincipal,
  updateProductPrincipal
} from "../../../../../services/main/productPrincipalReducer";
import { editProductPrincipalSchema } from "./productPrincipalSchema";

const EditProductPrincipal = () => {
  const detailData = useSelector(
    (data) => data.productPrincipal.detail_product
  );
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();
  const { Option } = Select;
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  // submit data
  const onSubmit = (values, action) => {
    values.price_uom=parseInt(values.price_uom)
    values.conversion_1_to_4 = parseInt(values.conversion_1_to_4);
    values.conversion_2_to_4 = parseInt(values.conversion_2_to_4);
    values.conversion_3_to_4 = parseInt(values.conversion_3_to_4);
    const id = location?.state[0];
    const principalId = location?.state[1];
    const object = { id, principalId, values };
    dispatch(updateProductPrincipal(object)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(false);
        // action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };

  // fungsi modal
  const modalSuccess = () => {
    history.push(ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    dispatch(detailProductPrincipal(location.state));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initialDataStore = useMemo(() => {
    return {
      product_code: detailData?.product_code ?? "",
      product_name: detailData?.product_name ?? "",
      product_description: detailData?.product_description ?? "",
      barcode: detailData?.barcode ?? "",
      uom_1: detailData?.uom_1 ?? "",
      uom_2: detailData?.uom_2 ?? "",
      uom_3: detailData?.uom_3 ?? "",
      uom_4: detailData?.uom_4 ?? "",
      conversion_1_to_4: detailData?.conversion_1_to_4 ?? "",
      conversion_2_to_4: detailData?.conversion_2_to_4 ?? "",
      conversion_3_to_4: detailData?.conversion_3_to_4 ?? "",
      order_unit: detailData?.order_unit ?? "",
      status: detailData?.status ?? "",
      price_uom: detailData?.price_uom1 ?? "",
      updated_at: detailData?.updated_at ?? "",
      packaging: detailData?.packaging ?? "",
    };
  }, [detailData]);

  const handleChangeSelect = (name, value, option) => {
    setFieldValue(name, value);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialDataStore,
    validationSchema: editProductPrincipalSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  }); 
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_PRINCIPAL:EDIT_PRODUCT_PRINCIPAL")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:SUBTITLE_3")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_PRINCIPAL:SUBTITLE_5")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          // justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius={10}
        >
          {/* left side */}
          <Box padding={1} minWidth="37vw">
            {/* Principal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={detailData?.principal_code}
                  type="text"
                  width="250px"
                  disabled
                  backgroundColor={GRAY_COLOR}
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRINCIPAL_CODE}
                />
              </Box>
            </Box>
            {/* Product Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values?.product_code}
                  name="product_code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  width="250px"
                  // disabled
                />
                {errors.product_code && touched.product_code && (
                  <ValidationError text={errors.product_code} />
                )}
              </Box>
            </Box>
            {/* Barcode*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_BARCODE")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.barcode}
                  name="barcode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.BARCODE}
                />
                {errors.barcode && touched.barcode && (
                  <ValidationError text={errors.barcode} />
                )}
              </Box>
            </Box>
            {/* UOM 1*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_1")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.uom_1}
                  name="uom_1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.UOM1}
                />
                {errors.uom_1 && touched.uom_1 && (
                  <ValidationError text={errors.uom_1} />
                )}
              </Box>
            </Box>
            {/* UOM 2  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                {" "}
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_2")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.uom_2}
                  name="uom_2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.UOM2}
                />
                {errors.uom_2 && touched.uom_2 && (
                  <ValidationError text={errors.uom_2} />
                )}
              </Box>
            </Box>
            {/* UOM 3  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_3")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.uom_3}
                  name="uom_3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.UOM3}
                />
                {errors.uom_3 && touched.uom_3 && (
                  <ValidationError text={errors.uom_3} />
                )}
              </Box>
            </Box>
            {/* UOM 4 */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_4")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.uom_4}
                  name="uom_4"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.UOM4}
                />
                {errors.uom_4 && touched.uom_4 && (
                  <ValidationError text={errors.uom_4} />
                )}
              </Box>
            </Box>
            {/* Converstion To Smallest*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="100px">
                  {t("PRODUCT_PRINCIPAL:FIELD_CONVERSION_TO_SMALLEST")}
                </Text>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box mb={2}>
                  <Input
                    style={inputTheme}
                    value={values.conversion_1_to_4}
                    name="conversion_1_to_4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id={ID_PRODUCT_PRINCIPAL.EDIT.CONVERSTION_TO_SMALLEST_1}
                  />
                  {errors.conversion_1_to_4 && touched.conversion_1_to_4 && (
                    <ValidationError text={errors.conversion_1_to_4} />
                  )}
                </Box>
                <Box mb={2}>
                  <Input
                    style={inputTheme}
                    value={values.conversion_2_to_4}
                    name="conversion_2_to_4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id={ID_PRODUCT_PRINCIPAL.EDIT.CONVERSTION_TO_SMALLEST_2}
                  />
                  {errors.conversion_2_to_4 && touched.conversion_2_to_4 && (
                    <ValidationError text={errors.conversion_2_to_4} />
                  )}
                </Box>
                <Box mb={2}>
                  <Input
                    style={inputTheme}
                    value={values.conversion_3_to_4}
                    name="conversion_3_to_4"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id={ID_PRODUCT_PRINCIPAL.EDIT.CONVERSTION_TO_SMALLEST_3}
                  />
                  {errors.conversion_3_to_4 && touched.conversion_3_to_4 && (
                    <ValidationError text={errors.conversion_3_to_4} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={detailData?.principal_name}
                  name="principal_name"
                  disabled
                  onChange={handleChange}
                  type="text"
                  width="250px"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRINCIPAL_NAME}
                />
              </Box>
            </Box>
            {/* principal product name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.product_name}
                  name="product_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRINCIPAL_PRODUCT_NAME}
                />
                {errors.product_name && touched.product_name && (
                  <ValidationError text={errors.product_name} />
                )}
              </Box>
            </Box>
            {/* product description */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRODUCT_DESCRIPTION")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.product_description}
                  name="product_description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRODUCT_DESCRIPTION}
                />
                {errors.product_description && touched.product_description && (
                  <ValidationError text={errors.product_description} />
                )}
              </Box>
            </Box>

            {/* order unit */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_PRINCIPAL:FIELD_ORDER_UNIT")}
                </Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.order_unit}
                  name="order_unit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.ORDER_UNIT}
                />
                {errors.order_unit && touched.order_unit && (
                  <ValidationError text={errors.order_unit} />
                )}
              </Box>
            </Box>

            {/* price uom 1 */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRICE_UOM_1")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.price_uom}
                  name="price_uom"
                  onChange={handleChange}
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRICE_UOM_1}
                />
                {errors.price_uom && touched.price_uom && (
                  <ValidationError text={errors.price_uom} />
                )}
              </Box>
            </Box>

            {/* product status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRODUCT_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  style={{ width: 250, borderRadius: "6px", fontWeight: 500 }}
                  size="large"
                  value={values.status}
                  name="status"
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRODUCT_STATUS}
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>

            {/* product packaging */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRODUCT_PACKAGING")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.packaging}
                  name="packaging"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.EDIT.PRODUCT_PACKAGING}
                />
                {errors.packaging && touched.packaging && (
                  <ValidationError text={errors.packaging} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_PRINCIPAL.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_PRINCIPAL.EDIT.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default EditProductPrincipal;
