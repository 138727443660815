import { Box, Switch, Text } from "@chakra-ui/react";
import { Checkbox } from "antd";

const CustomCheckBox = ({
  onChange,
  isChecked,
  disabled = false
} = {}) => {
  return (
    <Box style={{ paddingLeft: "8px" }}>
      <Checkbox
      disabled={disabled}
      checked={isChecked}
      onChange={onChange}
      />
    </Box>
  );
};

export default CustomCheckBox;
