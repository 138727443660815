import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import { useFormik } from "formik";
import "./ProductMappingHO.css";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import {
  addProductMappingHo,
  getListCustomer,
  getListCustomerDDS,
  getListPrincipal,
  getListProductCustomer,
  getListProductCustomerDDS,
  getListProductPrincipal,
  getListProductPrincipalDDS,
} from "../../../../../services/main/productMappingHoReducer";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { addProductMappingHoSchema } from "./productMappingHoSchema";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { Redirect } from "react-router-dom";
import StringUtil from "../../../../../utils/stringUtil";
import { useWithInstanceListAndInitiate } from "../../../../../services/serviceUtil";
import { ID_PRODUCT_MAPPING_HO } from "../../../../../constant/idConstant";
const AddProductMappingHO = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const { principal_code } = useSelector((state) => state.auth);
  const additionalBarcode = useSelector(
    (state) => state.productMappingHo?.additional_barcode
  );

  /// modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  //page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageProductPrincipal, setPageProductPrincipal] = useState(1);
  const [limitProductPrincipal, setLimitProductPrincipal] = useState(10);
  const [pageProductCustomer, setPageProductCustomer] = useState(1);
  const [limitProductCustomer, setLimitProductCustomer] = useState(10);
  const [idCust, setIdCust] = useState(null);
  const [principalCode, setPrincipalCode] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [principalProductCode, setPrincipalProducCode] = useState("");
  const [customerProductCode, setCustomerProductCode] = useState("");
  const [isCustomerNew, setIsCustomerNew] = useState(false);

  //new hooks
  const { limit, list, isLoading, setNextPage, setFilter, resetAllState } =
    useWithInstanceListAndInitiate({
      isOption: true,
      skipFirstRun: true,
      pathInitiate: "product-customer/initiateGetList",
      pathList: "product-customer",
    });

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case (name = "principal_id"):
        setFieldValue("principal_id", value);
        setPrincipalCode(option.code);
        break;
      case (name = "customer_id"):
        if (value) {
          setIsCustomerNew(true);
          const filter = StringUtil.formatFilter({
            principal_id: `eq ${values.principal_id ?? ""}`,
            customer_id: `eq ${value ?? ""}`,
          });
          setFilter(filter);
          setDataProductCustomer([]);
          setPageProductCustomer(1);
        } else {
          resetAllState();
        }
        setFieldValue("customer_id", value);
        setFieldValue("product_customer_id", null);
        setIdCust(value);
        setCustomerCode(option?.code);
        break;
      case (name = "product_principal_id"):
        setFieldValue("product_principal_id", value);
        setPrincipalProducCode(option?.code);
        break;
      case (name = "product_customer_id"):
        setFieldValue("product_customer_id", value);
        setCustomerProductCode(option?.code);
        break;
      case (name = "status"):
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };
  const onSubmit = () => {
    const payload = {
      principal_id: values?.principal_id,
      customer_id: values?.customer_id,
      product_principal_id: values?.product_principal_id,
      product_customer_id: values?.product_customer_id,
      additional_data: {
        barcode: values?.barcode,
        alternative_barcode: values?.additional_barcode,
      },
      status: values?.status,
      uom_1: values?.uom_1,
      uom_2: values?.uom_2,
      uom_3: values?.uom_3,
      uom_4: values?.uom_4,
      conv_1_to_4: Number(values?.conversion_1_to_4),
      conv_2_to_4: Number(values?.conversion_2_to_4),
      conv_3_to_4: Number(values?.conversion_3_to_4),
      order_unit: values?.order_unit,
    };
    dispatch(addProductMappingHo(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: null,
      product_principal_id: null,
      product_customer_id: null,
      barcode: "",
      additional_barcode: "",
      status: "",
      uom_1: "",
      uom_2: "",
      uom_3: "",
      uom_4: "",
      conversion_1_to_4: "",
      conversion_2_to_4: "",
      conversion_3_to_4: "",
      order_unit: "",
    },
    validationSchema: addProductMappingHoSchema(t),
    onSubmit,
  });
  ///Principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const [filterPrincipal, setfilterPrincipal] = useState("");
  const fetchSearchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = `name like ${filterPrincipal}`;
    const params =
      filterPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  const handleScrollPrincipal = () => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // Customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };

  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };

  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };

  // Product Principal
  const [dataProductPrincipal, setDataProductPrincipal] = useState([]);
  const fetchDataProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const params = { page, limit };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductPrincipal([...dataProductPrincipal, ...resp]);
    });
  };
  const [filterProductPrincipal, setfilterProductPrincipal] = useState("");
  const fetchSearchProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const filter = `product_code like ${filterProductPrincipal}`;
    const params =
      filterProductPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductPrincipal(1);
      setDataProductPrincipal(response.payload.response);
    });
  };

  const handleScrollProductPrincipal = () => {
    setPageProductPrincipal(pageProductPrincipal + 1);
  };

  // Product Customer
  const [dataProductCustomer, setDataProductCustomer] = useState([]);
  const fetchDataProductCustomer = (isNew = false) => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${values.principal_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (isCustomerNew) {
      // setDataProductCustomer([]);
      dispatch(getListProductCustomerDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataProductCustomer([...resp]);
      });
    } else {
      dispatch(getListProductCustomerDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataProductCustomer([...dataProductCustomer, ...resp]);
      });
    }
    setIsCustomerNew(false);
  };
  const [filterProductCustomer, setfilterProductCustomer] = useState("");
  const fetchSearchProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${values.principal_id ?? ""}`,
      product_code: `like ${filterProductCustomer ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    // const params = { page, limit, filter };
    // if (idCust === null) {
    //   setDataProductCustomer([]);
    // } else {
    //   dispatch(getListProductCustomer(params)).then((response) => {
    //     if (!response.payload.ok) return;
    //     setPageProductCustomer(1);
    //     setDataProductCustomer(response.payload.response);
    //   });
    // }
    if (!values?.customer_id) return;
    setFilter(filter);
  };

  const handleScrollProductCustomer = () => {
    // setPageProductCustomer(pageProductCustomer + 1);
    setNextPage();
  };

  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  // Principal
  useEffect(() => {
    fetchDataPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchSearchPrincipal();
  }, [filterPrincipal]);
  // Customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // product principal
  useEffect(() => {
    fetchDataProductPrincipal();
  }, [pageProductPrincipal]);
  useEffect(() => {
    fetchSearchProductPrincipal();
  }, [filterProductPrincipal]);

  // product customer
  useEffect(() => {
    fetchDataProductCustomer();
  }, [pageProductCustomer]);

  useEffect(() => {
    fetchSearchProductCustomer();
  }, [filterProductCustomer]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_MAPPING_HO.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_MAPPING_HO:ADD_PRODUCT_MAPPING_HO")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING_HO:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING_HO:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_MAPPING_HO:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_MAPPING_HO:ADD_PRODUCT_MAPPING_HO")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* Principal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  backgroundColor={GRAY_COLOR}
                  value={principal_code ? principal_code : ""}
                  id={ID_PRODUCT_MAPPING_HO.ADD.PRINCIPAL_CODE}
                />
              </Box>
            </Box>
            {/* Customer Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  value={customerCode ? customerCode : ""}
                  backgroundColor={GRAY_COLOR}
                  id={ID_PRODUCT_MAPPING_HO.ADD.CUSTOMER_CODE}
                />
              </Box>
            </Box>
            {/* Principal Product Code*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  value={principalProductCode ? principalProductCode : ""}
                  background={GRAY_COLOR}
                  id={ID_PRODUCT_MAPPING_HO.ADD.PRINCIPAL_PRODUCT_CODE}
                />
              </Box>
            </Box>
            {/* Customer Product Code*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  value={customerProductCode ? customerProductCode : ""}
                  background={GRAY_COLOR}
                  id={ID_PRODUCT_MAPPING_HO.ADD.CUSTOMER_PRODUCT_CODE}
                />
              </Box>
            </Box>
            {/* Barcode*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_BARCODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  name="barcode"
                  style={inputTheme}
                  value={values?.barcode}
                  onBlur={handleBlur("barcode")}
                  onChange={handleChange}
                  id={ID_PRODUCT_MAPPING_HO.ADD.BARCODE}
                />
                {errors.barcode && touched.barcode && (
                  <ValidationError text={errors.barcode} />
                )}
              </Box>
            </Box>
            {/* Additional  Barcode*/}
            {additionalBarcode ? (
              <Box padding={1} display="flex">
                <Box width="10rem">
                  <Text overflowWrap="break-word" width="120px">
                    {t("PRODUCT_MAPPING_HO:FIELD_ALTERNATIVE_BARCODE")}
                  </Text>
                </Box>
                <Box>
                  <Input
                    type="text"
                    name="additional_barcode"
                    style={inputTheme}
                    value={values?.additional_barcode}
                    onBlur={handleBlur("additional_barcode")}
                    onChange={handleChange}
                    id={ID_PRODUCT_MAPPING_HO.ADD.BARCODE_CASE}
                  />
                  {errors.additional_barcode && touched.additional_barcode && (
                    <ValidationError text={errors.additional_barcode} />
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}

            {/* Order Unit */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING:FIELD_ORDER_UNIT")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  value={values?.order_unit}
                  name="order_unit"
                  onChange={handleChange}
                />
                {errors.order_unit && touched.order_unit && (
                  <ValidationError text={errors.order_unit} />
                )}
              </Box>
            </Box>
            {/* Principal UOM 1*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_UOM_1")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  value={values?.uom_1}
                  onChange={handleChange}
                  name="uom_1"
                />
                {errors.uom_1 && touched.uom_1 && (
                  <ValidationError text={errors.uom_1} />
                )}
              </Box>
            </Box>
            {/* Principal UOM 2*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_UOM_2")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  value={values?.uom_2}
                  onChange={handleChange}
                  name="uom_2"
                />
                {errors.uom_2 && touched.uom_2 && (
                  <ValidationError text={errors.uom_2} />
                )}
              </Box>
            </Box>
            {/* Principal UOM 3*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_UOM_3")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  onChange={handleChange}
                  name="uom_3"
                  value={values?.uom_3}
                />
                {errors.uom_3 && touched.uom_3 && (
                  <ValidationError text={errors.uom_3} />
                )}
              </Box>
            </Box>
            {/* Principal UOM 4*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_UOM_4")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  name="uom_4"
                  value={values?.uom_4}
                  onChange={handleChange}
                />
                {errors.uom_4 && touched.uom_4 && (
                  <ValidationError text={errors.uom_4} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                  id={ID_PRODUCT_MAPPING_HO.ADD.PRINCIPAL_NAME}
                />
              </Box>
            </Box>
            {/* customer name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box width="12rem" className="my-select">
                <Select
                  size="large"
                  id={ID_PRODUCT_MAPPING_HO.ADD.CUSTOMER_NAME}
                  allowClear
                  onPopupScroll={handleScrollCustomer}
                  name="customer_id"
                  value={values.customer_id}
                  style={inputTheme}
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  onBlur={handleBlur("customer_id")}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataCustomer &&
                    dataCustomer?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                          code={data.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* principal product name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box className="my-select">
                <Select
                  size="large"
                  id={ID_PRODUCT_MAPPING_HO.ADD.PRINCIPAL_PRODUCT_NAME}
                  name="product_principal_id"
                  onPopupScroll={handleScrollProductPrincipal}
                  style={inputTheme}
                  value={values?.product_principal_id}
                  allowClear
                  onChange={(value, opt) =>
                    handleChangeSelect("product_principal_id", value, opt)
                  }
                  onBlur={handleBlur("product_principal_id")}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductPrincipal(e.target.value)}
                    />
                  )}
                >
                  {dataProductPrincipal &&
                    dataProductPrincipal?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          principal={data.principal_name}
                          code={data.product_code}
                          title={data.product_code}
                        >
                          {data.product_code}
                          {" - "}
                          {data.product_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_principal_id &&
                  touched.product_principal_id && (
                    <ValidationError text={errors.product_principal_id} />
                  )}
              </Box>
            </Box>
            {/* customer product name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box width="12rem" className="my-select">
                <Select
                  style={inputTheme}
                  id={ID_PRODUCT_MAPPING_HO.ADD.CUSTOMER_PRODUCT_NAME}
                  value={values?.product_customer_id}
                  onPopupScroll={handleScrollProductCustomer}
                  size="large"
                  allowClear
                  onBlur={handleBlur("product_customer_id")}
                  name="product_customer_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("product_customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductCustomer(e.target.value)}
                    />
                  )}
                >
                  {list &&
                    list?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          code={data.product_code}
                          title={data.product_code}
                        >
                          {data.product_code}
                          {" - "}
                          {data.product_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_customer_id && touched.product_customer_id && (
                  <ValidationError text={errors.product_customer_id} />
                )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_MAPPING_HO:FIELD_STATUS")}
                </Text>
              </Box>
              <Box className="my-select">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_PRODUCT_MAPPING_HO.ADD.STATUS}
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>

            {/* Converstion to smallest */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_MAPPING:FIELD_CONVERSION_TO_SMALLEST")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  value={values?.conversion_1_to_4}
                  name="conversion_1_to_4"
                  onChange={handleChange}
                />
                {errors.conversion_1_to_4 && touched.conversion_1_to_4 && (
                  <ValidationError text={errors.conversion_1_to_4} />
                )}
              </Box>
            </Box>
            {/* kolom 5 */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {/* {t("PRODUCT_MAPPING:FIELD_STATUS")} */}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  value={values?.conversion_2_to_4}
                  name="conversion_2_to_4"
                  onChange={handleChange}
                />
                {errors.conversion_2_to_4 && touched.conversion_2_to_4 && (
                  <ValidationError text={errors.conversion_2_to_4} />
                )}
              </Box>
            </Box>
            {/* kolom 6 */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {/* {t("PRODUCT_MAPPING:FIELD_STATUS")} */}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  value={values?.conversion_3_to_4}
                  name="conversion_3_to_4"
                  onChange={handleChange}
                />
                {errors.conversion_3_to_4 && touched.conversion_3_to_4 && (
                  <ValidationError text={errors.conversion_3_to_4} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_MAPPING_HO.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_MAPPING_HO.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_MAPPING_HO.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddProductMappingHO;
