export const SET_TOKEN = `SET_TOKEN`;
export const SET_REFRESH_TOKEN = `SET_REFRESH_TOKEN`;
export const SET_CRYPTO_RANDOM = `SET_CRYPTO_RANDOM`;
export const SET_CODE_PKCE = `SET_CODE_PKCE`;
export const TRUE_AUTHENTICATED = `TRUE_AUTHENTICATED`;
export const FALSE_AUTHENTICATED = `FALSE_AUTHENTICATED`;
export const SET_LANGUAGE = `SET_LANGUAGE`;
export const SET_USER_ID = `SET_USER_ID`;
export const SET_FIRST_NAME = `SET_FIRST_NAME`;
export const SET_LAST_NAME = `SET_LAST_NAME`;
export const SET_ALIAS = `SET_ALIAS`;
export const SET_SCOPE = `SET_SCOPE`;
export const SET_ROLE = `SET_ROLE`;
export const SET_PERMISSION = `SET_PERMISSION`;
export const SET_MENU = `SET_MENU`;

export const AUTH_FETCHING = `AUTH_FETCHING`;
export const AUTH_SUCCESS = `AUTH_SUCCESS`;
export const AUTH_ERROR = `AUTH_ERROR`;

export const TABLE_FETCHING = `TABLE_FETCHING`;
export const TABLE_SUCCESS = `TABLE_SUCCESS`;
export const TABLE_ERROR = `TABLE_ERROR`;

export const SKELETON_FETCHING = `SKELETON_FETCHING`;
export const SKELETON_SUCCESS = `SKELETON_SUCCESS`;
export const SKELETON_ERROR = `SKELETON_ERROR`;

export const POST_FETCHING = `POST_FETCHING`;
export const POST_SUCCESS = `POST_SUCCESS`;
export const POST_ERROR = `POST_ERROR`;

export const PUT_FETCHING = `PUT_FETCHING`;
export const PUT_SUCCESS = `PUT_SUCCESS`;
export const PUT_ERROR = `PUT_ERROR`;

export const DELETE_FETCHING = `DELETE_FETCHING`;
export const DELETE_SUCCESS = `DELETE_SUCCESS`;
export const DELETE_ERROR = `DELETE_ERROR`;

export const PREPARE_COMPONENT = `PREPARE_COMPONENT`;
export const SET_MINIMIZE = `SET_MINIMIZE`;

export const RESET_STORE = `RESET_STORE`;
export const RESET_REDUX = `RESET_REDUX`;


