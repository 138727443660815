import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Pagination, Table } from "antd";
import { subHours } from "date-fns";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  SearchIcon,
  StatusActive,
  StatusNonActive,
} from "../../../components/icons";
import CustomPopOver from "../../../components/popOver/CustomPopOver";
import TableCustom from "../../../components/table/TableCustom";
import { ID_ROLE } from "../../../constant/idConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  WHITE_COLOR,
} from "../../../constant/propertiesConstant";
import { ROUTES } from "../../../constant/routeConstant";
import { selectPaginationOption } from "../../../constant/selectOptConstant";
import "../../../css/GlobalAntd.css";
import {
  getInitiateListRole,
  getListRole,
  roleAction,
} from "../../../services/main/roleReducer";
import DateUtil, { FORMAT } from "../../../utils/dateUtil";
import StringUtil from "../../../utils/stringUtil";
// import classes from "./ListRole.module.css";
// import classes from "../../../components/table/Table.module.css";
const ListRole = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const data = useSelector((state) => state.role.list);
  const { table_loading } = useSelector((state) => state.role);
  const { token } = useSelector((state) => state.auth);
  const getRole = useSelector((state) => state.role);

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  //pagination
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const datasource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      role_id: item.role_id,
      description: item.description,
      level: item.level,
      updated_at: DateUtil.formatDate(subHours(new Date(item.updated_at), 7), FORMAT.DATE_TIME),
      status: item.status,
      action: (
        <div key={index}>
          <ButtonCustom
            link={{ pathname: ROUTES.ROLE.EDIT, state: item }}
            text={<EditIcon />}
            backgroundcolor="transparent"
            id={ID_ROLE.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{ pathname: ROUTES.ROLE.DETAIL, state: item }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
            id={ID_ROLE.LIST.BTN_VIEW + "_" + item.id}
          />
        </div>
      ),
    }));
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("ROLE:TITLE_LIST")}
        </Box>
      ),
      dataIndex: "role_id",
      key: "role_id",
      // className: classes.columnRole,
      sorter: (a, b) => a.role_id.localeCompare(b.role_id),
      sortDirections: ["descend", "ascend"],
      align: "left",
      fixed: "left",
      responsive: ["sm"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("ROLE:FIELD_DESCRIPTION")}
        </Box>
      ),
      dataIndex: "description",
      key: "description",
      width: 250,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ["descend", "ascend"],
      responsive: ["sm"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("ROLE:FIELD_ROLE_LEVEL")}
        </Box>
      ),
      dataIndex: "level",
      key: "level",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.level - b.level,
      responsive: ["sm"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at-b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("ROLE:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:ACTION")}
        </Box>
      ),
      dataIndex: "action",
      key: "action",
      responsive: ["sm"],
      fixed: "right",
    },
  ];
  // for filter
  const [isDisabled, setisDisabled] = useState(true);
  const [filterRole, setFilterRole] = useState("");
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [form, setForm] = useState("");
  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setisDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setisDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };
  const submitOk = () => {
    /// klik tombol apply (tombol filtenya)
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      setFilterRole(form);
      setPage(1);
      setPopOverOpen(false);
    }
  };
  const resetFilter = () => {
    /// klik tombol reset filter
    setisDisabled(true);
    setFilterRole("");
    setForm("");
    setButtonSearchOrClose("search");
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setisDisabled(true);
  };
  const handleSelectPagination = (event) => {
    /// handle pagination biar ga nyangkut
    setPage(1);
    setLimit(event.target.value);
  };
  const filter = "role_id like " + filterRole;
  const params =
    filterRole === "" ? { page, limit, order } : { page, limit, filter, order };
  const fetchData = () => {
    dispatch(getListRole(params));
  };

  useEffect(() => {
    dispatch(getInitiateListRole(params)).then((response) => {
      setTotal(response.payload.response.count_data);
      dispatch(roleAction.setCountData(response.payload.response.count_data));
      fetchData();
    });
  }, [page, limit, token, total, order, filterRole, dispatch]);
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("ROLE:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("COMMON:HOME")}</span> -{" "}
            {t("ROLE:TITLE_LIST")}
          </>
        }
        button={
          <ButtonPrimary
            link={ROUTES.ROLE.ADD}
            icon={<Icon as={AddIcon} />}
            text={t("ROLE:ADD_ROLE")}
          />
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"18.5rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={5}>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor={WHITE_COLOR}
            borderRadius={6}
          >
            <Input
              type="text"
              placeholder={t("ROLE:SEARCH_ID_OR_ROLE_ID")}
              fontSize="14px"
              onChange={(e) => handleChange(e)}
              id={ID_ROLE.LIST.SEARCH}
              value={form}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>

          <Box marginRight="23px" marginLeft="12px">
            <ButtonCustom
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              id={ID_ROLE.LIST.BTN_APPLY}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              id={ID_ROLE.LIST.RESET_FILTER}
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <TableCustom
          dataSource={datasource}
          columns={columns}
          onChangeTable={handleTableChange}
          loading={table_loading}
          idSelect={ID_ROLE.LIST.ROW_PER_PAGE}
          onChangeSelect={handleSelectPagination}
          onChangePagination={(value) => setPage(value)}
          idPagination={ID_ROLE.LIST.PAGINATION}
          total={total}
          currentPage={page}
          limit={limit}
          key="null"
        />
      </Box>
    </Box>
  );
};
export default ListRole;
