import {
  Box, CloseButton, Icon, Input,
  InputGroup,
  InputRightElement, Select as SelectChakra, Stack,
  Text
} from "@chakra-ui/react";
import { Pagination, Select, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  DetailIcon, SavedSuccess,
  SearchIcon, UnSuccess, WhiteDownloadIcon
} from "../../../../../components/icons";
import {
  GRAY_COLOR, PRIMARY_COLOR, WHITE_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
// import "./ProcessPOStyle.css";
import FileSaver from "file-saver";
import CustomCheckBox from "../../../../../components/customCheckBox/CustomCheckBox";
import InputDateRangePicker from "../../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../../components/DropDownCustom";
import ModalCustom from "../../../../../components/ModalCustom";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import {
  selectDropDownBKLSummary
} from "../../../../../constant/selectOptConstant";
import {
  selectOptFilterUserTheme
} from "../../../../../constant/themeConstant";
import { pageActions } from "../../../../../reducers/pageReducer";
import {
  bklSummaryAction,
  downloadBklSummary,
  downloadPDFBklSummary,
  getInitiateListBKLSummary,
  getListBKLMapping,
  getListBKLSummary,
  getListDistributor,
  getListStore
} from "../../../../../services/main/bklSummaryReducer";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import StringUtil from "../../../../../utils/stringUtil";

const ListBKLSummary = () => {
  const { t } = useTranslation();
  //   const { list_BKL_SUMMARY_po: data } = useSelector((state) => state.BKL_SUMMARYPO);
  const data = useSelector((state) => state.bklSummary.list);
  const table_loading = useSelector((state) => state.bklSummary.table_loading);
  const { filters: objectFilter } = useSelector((state) => state.page);
  const [total, setTotal] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [countSearch, setcountSearch] = useState(objectFilter?.countSearch);
  const [popOverOpen, setPopOverOpen] = useState(false);

  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);

  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);

  const [pageDepo, setPageDepo] = useState(1);
  const [limitDepo, setLimitDepo] = useState(10);
  const [order, setOrder] = useState("");
  const dataSelectedRowKeys = useSelector(
    (state) => state.bklSummary.selectedRowKeys
  );

  const dispatch = useDispatch();
  // filter
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [isDisabled, setIsDisabled] = useState(true);
  const [form, setForm] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [formDistributor, setFormDistributor] = useState(0);
  const [formStore, setFormStore] = useState(0);
  const [formDepo, setFormDepo] = useState(0);
  const [formPoOnSchedule, setFormPoOnSchedule] = useState(0);
  const [inputSearch, setInputSearch] = useState("");
  const [inputPoOnSchedule, setInputPoOnSchedule] = useState(null);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [isSubmit, setIsSubmit] = useState(false);
  // modal response
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // filter
  const today = new Date();

  const [updatedDate, setUpdatedDate] = useState(
    objectFilter?.updatedDate ?? []
  );
  const [filterUpdatedDate, setfilterUpdatedDate] = useState(
    objectFilter?.filterUpdatedDate
  );

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setModalOpen(false);
    setErr(false);
  };

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  useMemo(() => {
    const [start, end] = updatedDate;
    if (start && end) {
      setfilterUpdatedDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterUpdatedDate([]);
  }, [updatedDate]);
  const filter = StringUtil.formatFilter({
    [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
    distributor_id: `eq ${objectFilter?.idDistributor}`,
    store_id: `eq ${objectFilter?.idStore}`,
    depo: `eq ${objectFilter?.idDepo}`,
    // po_on_schedule: `eq ${inputPoOnSchedule}`,
    transaction_date: `range ${objectFilter?.filterUpdatedDate}`,
  });
  const params = {
    page,
    limit,
    filter,
    order,
  };
  const fetchData = () => {
    dispatch(getListBKLSummary(params));
  };
  const fetchInitiate = () => {
    dispatch(getInitiateListBKLSummary(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };
  useEffect(() => {
    fetchInitiate();
    fetchData();
    setSubmit(false);
    dispatch(bklSummaryAction.resetCBX());
  }, [page, limit, isSubmit, order, dispatch]);

  // useEffect(() => {
  //   const params = { page, limit };
  //   dispatch(getInitiateListBKLSummary(params)).then((response) => {
  //     setTotal(response.payload.response.count_data);
  //   });
  //   fetchData();
  //   setSubmit(false);
  // }, [page, limit]);
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    } else setForm("");
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };
  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  // checkbox selected print
  const [listID, setListID] = useState([]);

  // ngecek ada data yang di select apa tidak
  const hasSelected = dataSelectedRowKeys?.length > 0;

  const submitOk = () => {
    setInputSearch(form);
    // setFormDistributor(distributorId);
    // setFormStore(storeId);
    // setFormDepo(depoId);
    // setFormPoOnSchedule(inputPoOnSchedule);
    setIsSubmit(!isSubmit);
    dispatch(
      pageActions.setFilters({
        idDistributor: distributorId,
        idStore: storeId,
        idDepo: depoId,
        countSearch: countSearch,
        filterUpdatedDate: filterUpdatedDate,
        updatedDate: updatedDate,
        selectDropDownFilter: selectDropDownFilter,
      })
    );
    setPage(1);
  };
  const resetFilter = () => {
    setForm("");
    setInputSearch("");
    setDistributorId(null);
    setInputDistributor("");
    setFormDistributor("");
    setFormDepo(null);
    setInputDepo("");
    setFormStore(null);
    setStoreId(null);
    setInputStore("");
    setFormPoOnSchedule(null);
    setIsDisabled(true);
    setDropdownValue("");
    setButtonSearchOrClose("search");
    setSelectDropDownFilter(null);
    setInputPoOnSchedule(null);
    setUpdatedDate([]);
    setcountSearch("");
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
    setIsSubmit(!isSubmit);
    dispatch(pageActions.resetFilters());
    setDepoId(null);
  };

  const handleSelectPagination = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const handleChangeSelect = (name, value, option) => {
    setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
    setDropdownValue(value);
  };

  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setUpdatedDate([start, end]);
    } else setUpdatedDate([]);
    setIsDisabled(false);
  };

  // ini yg buat search nya di distributor
  const [data_Distributor, setData_Distributor] = useState([]);
  const [inputDistributor, setInputDistributor] = useState("");
  const [distributorId, setDistributorId] = useState(
    objectFilter?.idDistributor
  );

  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return [];
      const resp = response.payload.response;
      if (!resp) return [];
      setData_Distributor([...data_Distributor, ...resp]);
    });
  };
  const fetchDataDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = `distributor_name like ${inputDistributor}`;
    const params =
      inputDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return [];
      setPageDistributor(1);
      setData_Distributor(response.payload.response);
    });
  };
  const handleScrollDistributor = () => {
    setPageDistributor(pageDistributor + 1);
  };
  const handleSearchDistributor = (event) => {
    setInputDistributor(event);
  };

  // ini buat yang depo
  const [depoId, setDepoId] = useState(objectFilter?.idDepo);
  const [data_Depo, setData_Depo] = useState([]);
  const [inputDepo, setInputDepo] = useState("");
  const fetchDataDepo = () => {
    const page = pageDepo;
    const limit = limitDepo;
    const filter = StringUtil.formatFilter({
      area_code: `like ${inputDepo}`,
    });
    const params = { page, limit, filter };
    dispatch(getListBKLMapping(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return [];
      setData_Depo([...data_Depo, ...resp]);
    });
  };

  const fetchDataDepoSearch = () => {
    const page = pageDepo;
    const limit = limitDepo;
    // const filter = `area_code like ${inputDepo}`;
    const filter = StringUtil.formatFilter({
      area_code: `like ${inputDepo}`,
    });
    const params = { page, limit, filter };
    dispatch(getListBKLMapping(params)).then((response) => {
      if (!response.payload.ok) return [];
      setPageDepo(1);
      setData_Depo(response.payload.response);
    });
  };

  const handleScrollDepo = () => {
    setPageDepo(pageDepo + 1);
  };
  const handleSearchDepo = (event) => {
    setInputDepo(event);
  };

  const [storeId, setStoreId] = useState(objectFilter?.idStore);
  const [data_Store, setData_Store] = useState([]);
  const [inputStore, setInputStore] = useState("");
  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idStore ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListStore(params)).then((response) => {
      if (!response.payload.ok) return [];
      const resp = response.payload.response;
      if (!resp) return [];
      setData_Store([...data_Store, ...resp]);
    });
  };

  const fetchDataStoreSearch = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = `store_name like ${inputStore}`;
    const params =
      inputStore === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListStore(params)).then((response) => {
      if (!response.payload.ok) return [];
      setPageStore(1);
      setData_Store(response.payload.response);
    });
  };

  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };
  const handleSearchStore = (event) => {
    setInputStore(event);
  };

  const downloadXlsx = () => {
    const body = {
      list_id: dataSelectedRowKeys,
      from_date: moment(updatedDate[0]).format("YYYY-MM-DD"),
      thru_date: moment(updatedDate[1]).format("YYYY-MM-DD"),
    };
    dispatch(downloadBklSummary(body)).then((response) => {
      if (response.payload.ok) {
        const { data, headers } = response.payload.response;
        const [, filename] = headers["content-disposition"].split(";");
        const [, name] = filename.trim().split("=");
        const newName = name.replace(
          ".",
          `_${DateUtil.formatDate(new Date(), FORMAT.DEFAULT_DATE_XLSX)}.`
        );
        setModalOpen(true);
        setErr(false);
        setResp(t("COMMON:DOWNLOAD_SUCCESS"));
        FileSaver.saveAs(data, newName);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(t("COMMON:DOWNLOAD_FAILED_XLSX"));
      }
    });
  };

  const downloadPdf = () => {
    const body = {
      list_id: dataSelectedRowKeys,
      from_date: updatedDate[0],
      thru_date: updatedDate[1],
    };
    dispatch(downloadPDFBklSummary(body)).then((response) => {
      if (response.payload.ok) {
        const { data, headers } = response.payload.response;
        const [, filename] = headers["content-disposition"].split(";");
        const [, name] = filename.trim().split("=");
        const newName = name.replace(
          ".",
          `_${DateUtil.formatDate(new Date(), FORMAT.DEFAULT_DATE)}.`
        );
        setModalOpen(true);
        setResp(t("COMMON:DOWNLOAD_SUCCESS"));
        FileSaver.saveAs(data, newName);
      } else {
        setModalOpen(true);
        setResp(t("COMMON:DOWNLOAD_FALSE"));
      }
    });
  };

  useEffect(() => {
    fetchDataStore();
  }, [pageStore]);

  useEffect(() => {
    fetchDataStoreSearch();
  }, [inputStore]);

  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor]);

  useEffect(() => {
    fetchDataDistributorSearch();
  }, [inputDistributor]);

  useEffect(() => {
    fetchDataDepo();
  }, [pageDepo]);

  useEffect(() => {
    fetchDataDepoSearch();
  }, [inputDepo]);

  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: item.id,
      nopo: item.po_number,
      distributor: item.distributor_name,
      store: item.store_name,
      customer_code: item.customer_code,
      po_created: moment(item.po_created_at).format("DD/MM/YYYY"),
      po_delivery: moment(item.po_delivery).format("DD/MM/YYYY"),
      po_qty: item.po_qty,
      po_expired: moment(item.po_expired).format("DD/MM/YYYY"),
      sap_id: item.sap_id,
      customer_name: item.customer_name,
      depo_cover: item.depo_cover,
      status: item.so_approval_status,
      delivery: item.delivery,
      week: item.week,
      bottle_guarantee: item.bottle_guarantee,
      swap: item.swap,
      po_on_schedule: item.is_on_scheduled,
      checkBox: (
        <CustomCheckBox
          isChecked={dataSelectedRowKeys?.includes(item.id)}
          onChange={(event) => handleOnChangeChecked(event, item.id)}
        />
      ),
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.BKL_SUMMARY.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));
  const handleOnChangeChecked = (event, id) => {
    dispatch(bklSummaryAction.setSingleCheck(id));
  };

  const handleChangeAllChecked = () => {
    dispatch(bklSummaryAction.setAllCheck(listID));
  };
  useEffect(() => {
    setListID(data?.map((item) => item.id));
  }, [data]);
  const getCheckedAll = () => {
    // listID
    if (listID?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of listID) {
        isCheckedAll = false;
        for (const id of dataSelectedRowKeys) {
          if (ListIdData === id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) {
          break;
        }
      }
      return isCheckedAll;
    }
  };
  const checkedAll = getCheckedAll();
  const columns = [
    {
      title: (
        <CustomCheckBox
          isChecked={checkedAll}
          onChange={handleChangeAllChecked}
        />
      ),
      dataIndex: "checkBox",
      key: "checkBox",
      width: 100,
      fixed: "left",
      render(text, record) {
        return {
          props: {
            style: {
              background:
                parseInt(record.bottle_guarantee) > 0 ? "#fcf803" : "white",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "nopo",
      key: "nopo",
      fixed: "left",
      sorter: (a, b) => a.nopo - b.nopo,
      sortDirections: ["descend", "ascend"],
      render(text, record) {
        return {
          props: {
            style: {
              background:
                parseInt(record.bottle_guarantee) > 0 ? "#fcf803" : "white",
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_DISTRIBUTOR")}
        </Box>
      ),
      dataIndex: "distributor",
      key: "distributor",
      sorter: (a, b) => a.distributor - b.distributor,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_STORE")}
        </Box>
      ),
      dataIndex: "store",
      key: "store",
      sorter: (a, b) => a.store - b.store,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_CUSTOMER_CODE")}
        </Box>
      ),
      dataIndex: "customer_code",
      key: "customer_code",
      sorter: (a, b) => a.customer_code - b.customer_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_SAP_ID")}
        </Box>
      ),
      dataIndex: "sap_id",
      key: "sap_id",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_DEPO_COVER")}
        </Box>
      ),
      dataIndex: "depo_cover",
      key: "depo_cover",
      sorter: (a, b) => a.depo_cover - b.depo_cover,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PO_CREATED")}
        </Box>
      ),
      dataIndex: "po_created",
      key: "po_created",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PO_EXPIRED")}
        </Box>
      ),
      dataIndex: "po_expired",
      key: "po_expired",
      sorter: (a, b) => a.po_expired - b.po_expired,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_DELIVERY")}
        </Box>
      ),
      dataIndex: "delivery",
      key: "delivery",
      sorter: (a, b) => a.delivery - b.delivery,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_WEEK")}
        </Box>
      ),
      dataIndex: "week",
      key: "week",
      sorter: (a, b) => a.delivery - b.delivery,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PO_QTY")}
        </Box>
      ),
      dataIndex: "po_qty",
      key: "po_qty",
      sorter: (a, b) => a.delivery - b.delivery,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_BOTTLE_GUARANTEE")}
        </Box>
      ),
      dataIndex: "bottle_guarantee",
      key: "bottle_guarantee",
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_SWAP")}
        </Box>
      ),
      dataIndex: "swap",
      key: "swap",
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PO_ON_SCHEDULE")}
        </Box>
      ),
      dataIndex: "po_on_schedule",
      key: "po_on_schedule",
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <Box p={4}>
      <HeadTitle
        title={t("BKL_SUMMARY:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_SUMMARY:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_SUMMARY:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_SUMMARY:FIELD_PO")}
            </span>{" "}
            - {t("BKL_SUMMARY:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              icon={<WhiteDownloadIcon />}
              text={t("COMMON:DOWNLOAD_XLSX")}
              marginStart={2}
              onClick={downloadXlsx}
              disabled={!hasSelected}
              id="btn-UserSeller-ProductCustomer-UploadFile"
            />
            <ButtonPrimary
              icon={<WhiteDownloadIcon />}
              text={t("COMMON:DOWNLOAD_PDF")}
              marginStart={2}
              disabled={!hasSelected}
              onClick={downloadPdf}
              id="btn-UserSeller-ProductCustomer-UploadFile"
            />
          </>
        }
      />

      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Box width="240px">
            <InputDateRangePicker
              values={updatedDate}
              onChange={handleChangeDate}
            />
          </Box>
          {/* By Distributor */}
          <Select
            placeholder={t("BKL_SUMMARY:FIELD_DISTRIBUTOR")}
            style={selectOptFilterUserTheme}
            onPopupScroll={handleScrollDistributor}
            value={distributorId}
            onChange={(value, opt) => setDistributorId(value, opt)}
            size="large"
            fontSize="14px"
            textColor={GRAY_COLOR}
            // id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            backgroundColor="white"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                value={inputDistributor}
                menu={menu}
                search={(e) => handleSearchDistributor(e.target.value)}
              />
            )}
            allowClear="true"
          >
            {data_Distributor?.map((value, index) => {
              return (
                <>
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                </>
              );
            })}
          </Select>
          {/* depo */}
          <Select
            placeholder={t("BKL_SUMMARY:FIELD_DEPO")}
            style={selectOptFilterUserTheme}
            onPopupScroll={handleScrollDepo}
            value={depoId}
            onChange={(value, opt) => setDepoId(value, opt)}
            size="large"
            fontSize="14px"
            textColor={GRAY_COLOR}
            // id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            backgroundColor="white"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                value={inputDepo}
                menu={menu}
                search={(e) => handleSearchDepo(e.target.value)}
              />
            )}
            allowClear="true"
          >
            {data_Depo?.map((value, index) => {
              return (
                <>
                  <option key={value.id} value={value.id} fulldata={value}>
                    {value.area_code}
                  </option>
                </>
              );
            })}
          </Select>
          {/* po on schedule  */}
          {/* <Select
            placeholder={t("BKL_SUMMARY:FIELD_PO_ON_SCHEDULE")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            value={inputPoOnSchedule}
            size="large"
            onChange={(value, opt) => setInputPoOnSchedule(value, opt)}
            textColor={GRAY_COLOR}
            fontSize="14px"
            showSearch={false}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
          >
            {selectDropDownPoOnSchedule?.map((value, index) => {
              return (
                <>
                  <option key={index.toString()} value={value}>
                    {t(value)}
                  </option>
                </>
              );
            })}
          </Select> */}
          {/* store  */}
          <Select
            placeholder={t("BKL_SUMMARY:FIELD_STORE")}
            style={selectOptFilterUserTheme}
            size="large"
            value={storeId}
            onPopupScroll={handleScrollStore}
            fontSize="14px"
            onChange={(value, opt) => setStoreId(value, opt)}
            backgroundColor="white"
            showSearch={false}
            textColor={GRAY_COLOR}
            allowClear="true"
            dropdownRender={(menu) => (
              <DropDownCustom
                value={inputStore}
                menu={menu}
                search={(e) => handleSearchStore(e.target.value)}
              />
            )}
          >
            {data_Store?.map((value, index) => {
              return (
                <option key={value.id} value={value.id}>
                  {value.store_name}
                </option>
              );
            })}
          </Select>

          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              //   backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              //   isDisabled={isDisabled}
              id="BTN-UserSeller-ProductPrincipal-Apply"
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Box marginLeft={"18.9rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            value={selectDropDownFilter}
            size="large"
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            onClear={handleClear}
            allowClear={true}
            textColor={GRAY_COLOR}
            fontSize="14px"
            showSearch={false}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
          >
            {selectDropDownBKLSummary?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              style={{ border: "1px solid #d9d9d9" }}
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `Search By ${selectDropDownFilter.children}`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="po_on_schedule"
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
        </Stack>
        <Table
          dataSource={dataSource}
          rowClassName={(record) =>
            record.bottle_guarantee > 0
              ? "table-row-yellow"
              : "table-row-normal"
          }
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          size="small"
          loading={table_loading}
          scroll={{
            x: 3000,
            y: 350,
          }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <SelectChakra
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={handleSelectPagination}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </SelectChakra>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  // showQuickJumper
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ListBKLSummary;
