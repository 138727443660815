import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list_notification: [],
  detail_notification: {},
};

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListNotification = createAsyncThunk(
  "getListNotification",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitListNotification = createAsyncThunk(
  "getInitListNotification",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailNotification = createAsyncThunk(
  "Get Detail Notification",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const addNotification = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/notification/add",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const updateNotification = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/notification/197",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification/${object.id}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, object.values);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const deleteNotification = createAsyncThunk(
  "Delete Norification",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification/${params.Id}`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      updated_at: params.updated_at,
    };
    try {
      const response = await baseApi.doDelete(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

const notification = createSlice({
  name: "createNotification",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: {
    [getListNotification.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListNotification.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_notification = action.payload.response;
    },
    [getListNotification.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [detailNotification.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailNotification.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_notification = action.payload.response;
    },
    [detailNotification.rejected]: (state, action) => {
      state.status = "failed";
    },
    [addNotification.pending]: (state, action) => {
      state.status = "loading";
    },
    [addNotification.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addNotification.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateNotification.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateNotification.fulfilled]: (state, action) => {
      state.status = "success";
      state.update_notification = action.payload.response;
    },
    [updateNotification.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [deleteNotification.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteNotification.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteNotification.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
  },
});

export const notificationAction = notification.actions;

export default notification.reducer;
