import {
  Badge, Box, Center, Divider, Flex, Stack, Text
} from "@chakra-ui/react";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  badgeColor, BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../constant/routeConstant";
import {
  addDataGroups, dataGroupAction
} from "../../../../services/main/dataGroupReducer";
import BodyDataGroup from "./body/BodyDataGroup";
import BodyDataScope2 from "./body/BodyDataScope2";
const AddDataGroup = () => {
  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { curr_page } = useSelector((state) => state.dataGroup);
  const { index_page } = useSelector((state) => state.dataGroup);
  const [modalOpen, setModalOpen] = useState(false);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [currentPage, setCurrentPage] = useState(t("DATA_GROUP:DATAGROUP"));
  const [indexPage, setIndexPage] = useState(0);
  const changePageDataGroup = () => {
    if (currentPage !== "DataGroup") {
      setCurrentPage("DataGroup");
      setIndexPage(indexPage - 1);
    } else {
      setCurrentPage("DataScope");
      setIndexPage(indexPage + 1);
    }
  };

  const onSubmitDataGroup = () => {
    dispatch(addDataGroups()).then((res) => {
      if (res.payload.ok) {
        setModalOpen(true);
      } else if (res.payload.ok === false) {
        message.error(res.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const clickOkOnModal = () => {
    setNewDataAdded(true);
    resetReduxDataGroup();
  };
  const resetReduxDataGroup = () => {
    dispatch(dataGroupAction.resetReduxDataGroup());
  };
  const setCurrPage = () => {
    dispatch(dataGroupAction.setCurrPage(t("DATA_GROUP:DATAGROUP")));
  };

  const renderMainBody = () => {
    if (curr_page === t("DATA_GROUP:DATAGROUP")) {
      return <BodyDataGroup />;
    }
    if (curr_page === t("DATA_GROUP:DATASCOPE")) {
      // return <BodyDataScope />;
      return <BodyDataScope2 />;
    }
  };

  useEffect(() => {
    resetReduxDataGroup();
    setCurrPage();
  }, []);
  return newDataAdded ? (
    <Redirect to={ROUTES.DATA_GROUP.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("DATA_GROUP:ADD_DATA_GROUP")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("COMMON:HOME")}</span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DATA_GROUP:TITLE_LIST")}
            </span>{" "}
            - {t("DATA_GROUP:ADD_DATA_GROUP")}
          </>
        }
      />
      <Box marginTop={6} marginBottom={3} minHeight="4.375rem">
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <Box
            backgroundColor="white"
            width={320}
            height="64px"
            borderRadius="8px"
            display="flex"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.31)"
          >
            <Stack direction="row" p={4} my={1}>
              {/* data group  */}
              <Badge
                borderRadius="full"
                width="24px"
                height="24px"
                variant="solid"
                colorScheme={
                  index_page >= 0
                    ? badgeColor.primaryColor
                    : badgeColor.primaryGray
                }
              >
                <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                  <Center>{t("DATA_GROUP:NUMBER_1")}</Center>
                </Text>
              </Badge>
              <Flex height="64x" justifyContent="center">
                <Text
                  fontWeight={700}
                  fontSize={16}
                  marginStart={2}
                  color={index_page >= 0 ? PRIMARY_COLOR : BLACK_COLOR}
                >
                  {t("DATA_GROUP:DATA_GROUP")}
                </Text>
              </Flex>
              {/* end of data group  */}
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* data scope  */}
              <Badge
                borderRadius="full"
                width="24px"
                height="24px"
                px="2"
                variant="solid"
                colorScheme={
                  index_page === 1
                    ? badgeColor.primaryColor
                    : badgeColor.primaryGray
                }
              >
                <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                  <Center>{t("DATA_GROUP:NUMBER_2")}</Center>
                </Text>
              </Badge>
              <Flex height="64x" justifyContent="center">
                <Text
                  fontWeight={700}
                  fontSize={16}
                  marginStart={2}
                  color={index_page === 1 ? PRIMARY_COLOR : BLACK_COLOR}
                >
                  {t("DATA_GROUP:DATA_SCOPE")}
                </Text>
              </Flex>

              {/* end of data scope  */}
            </Stack>
          </Box>
        </Stack>
      </Box>
      {/* render main body  */}
      {renderMainBody()}
    </Box>
  );
};
export default AddDataGroup;
