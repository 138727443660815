import { ChakraProvider } from "@chakra-ui/react";
import "antd/dist/antd.min.css";
// import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { persistStore } from 'redux-persist';
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import i18n from "./config/i18n";
import reportWebVitals from "./reportWebVitals";
// import { extendTheme } from "@chakra-ui/react"
import { customChakraTheme } from "./constant/themeConstant";
import store from './store';
import { injectStore } from "./api/apiConfig";
// import persistor from "./store";
injectStore(store);

let persistor = persistStore(store);
ReactDOM.render(
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
    {/* <PersistGate loading={<React.Fragment />}> */}
      {/* <React.Suspense fallback={<React.Fragment />}> */}
        <I18nextProvider i18n={i18n}>
          <ChakraProvider theme={customChakraTheme}>
            <App />
          </ChakraProvider>
        </I18nextProvider>
      {/* </React.Suspense> */}
    {/* </PersistGate> */}
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
