import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  BlueEditIcon, DeleteSuccess, RedDeleteIcon, SavedSuccess
} from "../../../../../components/icons";
import ModalConfirm from "../../../../../components/ModalConfirm";
import ModalCustom from "../../../../../components/ModalCustom";
import { ID_PRODUCT_CUSTOMER } from "../../../../../constant/idConstant";
import { PRIMARY_COLOR } from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  deleteProductCustomer,
  detailProductCustomer
} from "../../../../../services/main/productCustomerReducer";
const DetailProductCustomer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [deletedProduct, setDeletedProduct] = useState(false);

  const [dataProduct, setDataProduct] = useState("");

  const fetchData = async () => {
    dispatch(detailProductCustomer(location.state)).then((response) => {
      if (response.payload.ok) {
        setDataProduct(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  // fungsi modal delete
  const modalSuccess = () => {
    history.push(ROUTES_USER_SELLER.PRODUCT_CUSTOMER.LIST);
    setModalOpen(false);
  };
  const modalFailed = () => {
    setModalConfirm(false);
    setModalOpen(false);
  };
  const confirmModal = () => {
    setModalConfirm(true);
    setMessageConfirm(t("COMMON:DELETE_CONFIRM"));
  };

  // fungsi delete
  const confirmedDelete = () => {
    setModalConfirm(false);
    const Id = location.state;
    const updated_at = dataProduct.updated_at;
    const params = { Id, updated_at };
    dispatch(deleteProductCustomer(params)).then((resp) => {
      if (resp.payload.ok) {
        setErr(true);
        setModalOpen(true);
        setResponse(resp.payload.response);
        setDeletedProduct(true);
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response);
        setErr(true);
      }
    });
  };
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CUSTOMER:DETAIL_PRODUCT_CUSTOMER")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CUSTOMER:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CUSTOMER:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CUSTOMER:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CUSTOMER:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES_USER_SELLER.PRODUCT_CUSTOMER.EDIT,
                state: location.state,
              }}
              id={ID_PRODUCT_CUSTOMER.DETAIL.BTN_EDIT}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={confirmModal}
              id={ID_PRODUCT_CUSTOMER.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.PRODUCT_CUSTOMER.LIST}
        name={dataProduct.created_name}
        date={moment(dataProduct.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(dataProduct.updated_at).format("DD/MM/YYYY")}
        modifiedBy={dataProduct.updated_name}
        id={ID_PRODUCT_CUSTOMER.DETAIL.BTN_BACK}
      />
      {/* White Box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        justifyContent="space-between"
        display="flex"
        marginTop={2}
        borderRadius="10px"
      >
        <Box>
          {/* row 1 */}
          <Box display="flex">
            {/* left side */}
            <Box padding={1} width="13rem">
              {/* Principal Code */}
              <Box padding={1}>
                <Box>{t("PRODUCT_CUSTOMER:FIELD_CUSTOMER_CODE")}</Box>
                <Box>
                  <Text>
                    <b>
                      {dataProduct.principal_code === ""
                        ? "-"
                        : dataProduct.principal_code}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* Principal Product Code */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>
                    {t("PRODUCT_CUSTOMER:FIELD_PRINCIPAL_PRODUCT_CODE")}
                  </Text>
                  <Text>
                    <b>
                      {dataProduct.product_code === ""
                        ? "-"
                        : dataProduct.product_code}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* Product Description*/}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_DESCRIPTION")}</Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.product_description === ""
                      ? "-"
                      : dataProduct.product_description}
                  </b>
                </Text>
              </Box>
            </Box>

            {/* middle */}
            <Box padding={1} width="13rem">
              {/* Principal Name */}
              <Box padding={1}>
                <Box>{t("PRODUCT_CUSTOMER:FIELD_PRINCIPAL_NAME")}</Box>
                <Box>
                  <Text display="flex">
                    <b>
                      {dataProduct.principal_name === ""
                        ? "-"
                        : dataProduct.principal_name}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* Principal Product Name */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>
                    {t("PRODUCT_CUSTOMER:FIELD_PRINCIPAL_PRODUCT_NAME")}
                  </Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.product_name === ""
                      ? "-"
                      : dataProduct.product_name}
                  </b>
                </Text>
              </Box>
            </Box>
          </Box>
          {/* row 2  */}
          <Box display="flex">
            {/* left side  */}
            <Box padding={1} width="13rem">
              {/* Barcode  */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_BARCODE")}</Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.barcode === "" ? "-" : dataProduct.barcode}
                  </b>
                </Text>
              </Box>
              {/* UOM 1  */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_1")}</Text>
                </Box>
                <Box>
                  <Text>
                    <b>{dataProduct.uom_1 === "" ? "-" : dataProduct.uom_1}</b>
                  </Text>
                </Box>
              </Box>
              {/* Converstion To Smallest*/}
              <Box padding={1} display="flex">
                <Box width="10rem">
                  <Text>
                    {t("PRODUCT_CUSTOMER:FIELD_CONVERSION_TO_SMALLEST")}
                  </Text>
                  <Text>
                    <b>
                      {dataProduct.conversion_1_to_4 === ""
                        ? "-"
                        : dataProduct.conversion_1_to_4}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* Product Packaging */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_PACKAGING")}</Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.packaging === "" ? "-" : dataProduct.packaging}
                  </b>
                </Text>
              </Box>
            </Box>
            {/* middle-1 */}
            <Box padding={1} width="13rem">
              {/* Order Unit */}
              <Box padding={1}>
                <Box>{t("PRODUCT_CUSTOMER:FIELD_ORDER_UNIT")}</Box>
                <Box>
                  <Text display="flex">
                    <b>
                      {dataProduct.order_unit === ""
                        ? "-"
                        : dataProduct.order_unit}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* UOM 2 */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_2")}</Text>
                </Box>
                <Text>
                  <b>{dataProduct.uom_2 === "" ? "-" : dataProduct.uom_2}</b>
                </Text>
              </Box>
              {/* Invisible Text conversion 2 to 4*/}
              <Box padding={1}>
                <Box width="10rem">
                  <Text visibility="hidden">Invisible Text</Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.conversion_2_to_4 === ""
                      ? "-"
                      : dataProduct.conversion_2_to_4}
                  </b>
                </Text>
              </Box>
              {/* Product Status */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_PRODUCT_STATUS")}</Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.status === "N"
                      ? t("COMMON:STATUS_INACTIVE")
                      : t("COMMON:STATUS_ACTIVE")}
                  </b>
                </Text>
              </Box>
            </Box>
            {/* middle-2 */}
            <Box padding={1} width="13rem">
              {/* Price UOM 1 */}
              <Box padding={1}>
                <Box>{t("PRODUCT_CUSTOMER:FIELD_PRICE_UOM_1")}</Box>
                <Box>
                  <Text display="flex">
                    <b>
                      {dataProduct.price_uom_1 === ""
                        ? "-"
                        : dataProduct.price_uom_1}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* UOM 3 */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_3")}</Text>
                </Box>
                <Text>
                  <b>{dataProduct.uom_3}</b>
                </Text>
              </Box>
              {/* Invisible Text conversion 3 to 4*/}
              <Box padding={1}>
                <Box width="10rem">
                  <Text visibility="hidden">Invisible Text</Text>
                </Box>
                <Text>
                  <b>
                    {dataProduct.conversion_3_to_4 === ""
                      ? "-"
                      : dataProduct.conversion_3_to_4}
                  </b>
                </Text>
              </Box>
            </Box>
            {/* right side */}
            <Box padding={1} width="13rem">
              {/* Invisible Text 1 */}
              <Box padding={1} visibility="hidden">
                <Box>{t("PRODUCT_CUSTOMER:FIELD_PRICE_UOM_1")}</Box>
                <Box>
                  <Text display="flex">
                    <b>30000</b>
                  </Text>
                </Box>
              </Box>
              {/* UOM 4 */}
              <Box padding={1}>
                <Box width="10rem">
                  <Text>{t("PRODUCT_CUSTOMER:FIELD_UOM_4")}</Text>
                </Box>
                <Text>
                  <b>{dataProduct.uom_4 === "" ? "-" : dataProduct.uom_4}</b>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <ModalConfirm
          isOpen={modalConfirm}
          messageModal={t("COMMON:DELETE_TITLE")}
          messageConfirm={messageConfirm}
          onOk={confirmedDelete}
          onClose={modalFailed}
          confirmYes={t("COMMON:YES")}
          confirmNo={t("COMMON:NO")}
          idYes={ID_PRODUCT_CUSTOMER.DETAIL.BTN_YES}
          idNo={ID_PRODUCT_CUSTOMER.DETAIL.BTN_NO}
        />
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={deletedProduct ? modalSuccess : modalFailed}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Box>
    </Box>
  );
};

export default DetailProductCustomer;
