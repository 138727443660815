import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import * as actionType from "../../../actions/actionType";

export const getListActivityUser = async (params, dispatch) => {
  let result = { ok: false, response: null };
  const url = `https://622811a99fd6174ca81a32ce.mockapi.io/UserActivity`;

  dispatch({ type: actionType.TABLE_FETCHING });
  try {
    const response = await baseApi.doGet(url, null, params);
    if (response) {
      result.ok = true;
      result.response = response.data;
      dispatch({ type: actionType.TABLE_SUCCESS });
    }
  } catch (error) {
    dispatch({ type: actionType.TABLE_ERROR });

    if (error.response) {
      result.response = error.response;
    } else {
      message.error(i18next.t("COMMON:ERROR_NETWORK"));
    }
  }

  return result;
};
