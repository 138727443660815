import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import { subHours } from "date-fns";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  SavedSuccess,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  UnSuccess,
  UploadIcon,
} from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ModalImport from "../../../../components/ModalImport";
import ModalImportPreview from "../../../../components/ModalImportPreview";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import SortButton from "../../../../components/SortButton";
import { ID_CSM_MASTER } from "../../../../constant/idConstant";
import { typeStoreSupplierConnection } from "../../../../constant/importEndPointConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  HOVER_PRIMARY,
  LABEL_STATUS_ACTIVE,
  LABEL_STATUS_INACTIVE,
  PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { selectDropDownFilterStore } from "../../../../constant/selectOptConstant";
import { template_customer_store_mapping } from "../../../../constant/templateDownloadConstant";
import {
  selectOptFilterDistributorTheme,
  selectOptFilterUserTheme,
} from "../../../../constant/themeConstant";
import { pageActions } from "../../../../reducers/pageReducer";
import { getListCustomerDDS } from "../../../../services/main/customerReducer";
import { getListDistributor } from "../../../../services/main/distributorReducer";
import {
  getInitListStoreSupplier,
  getListDistributorDDS,
  getListStoreSupplier,
} from "../../../../services/main/storeSupplierConnectionReducer";
import DateUtil, { FORMAT } from "../../../../utils/dateUtil";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../utils/importFileUtil";
import StringUtil from "../../../../utils/stringUtil";

const ListStoreSuppConn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.storeSupplierConnection.list_connection_store
  );
  const { filters: objectFilter } = useSelector((state) => state.page);
  const { table_loading } = useSelector(
    (state) => state.storeSupplierConnection
  );
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  const [filterDataStore, setFilterDataStore] = useState(null);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [search, setSearch] = useState(false);
  const [reset, setReset] = useState(true);

  const [form, setForm] = useState("");
  const [countSearch, setcountSearch] = useState(objectFilter?.countSearch);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [proccessLoading, setProccessLoading] = useState(false);

  //   distributor parameter
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");
  const [idDistributor, setidDistributor] = useState(
    objectFilter?.idDistributor
  );

  // searchcustomername
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(100);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);
  const [customerId, setCustomerId] = useState(objectFilter?.idCustomer);

  const { token } = useSelector((state) => state.auth);

  const renderStatusColumnImport = (value) => {
    if (value.toLowerCase() === "status") {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {/* status  */}
            {t(`STORE_SUPPLIER_CONNECTION:FIELD_${value}`)}
          </Box>
        ),
        dataIndex: value,
        key: value,
        sorter: (a, b) => a.value.localeCompare(b.value),
        sortDirections: ["descend", "ascend"],
        render: (status) => (
          <>
            <Stack direction="row">
              <Tag rounded="full" px="2" display="flex" alignItems="center">
                <Box marginRight={2}>
                  {status.toLowerCase() === STATUS_ACTIVE ? (
                    <StatusActive />
                  ) : (
                    <StatusNonActive />
                  )}
                </Box>
                <Box
                  color={
                    status.toLowerCase() === STATUS_ACTIVE
                      ? GREEN_COLOR
                      : RED_COLOR
                  }
                >
                  {status.toLowerCase() === STATUS_ACTIVE
                    ? LABEL_STATUS_ACTIVE
                    : LABEL_STATUS_INACTIVE}
                </Box>
              </Tag>
            </Stack>
          </>
        ),
      };
    } else {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t(`STORE_SUPPLIER_CONNECTION:FIELD_${value}`)}

            <SortButton thisField="id" />
          </Box>
        ),
        dataIndex: value,
        key: value,
      };
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeStoreSupplierConnection },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFile(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
    setProccessLoading(false);
  };

  const importConfirm = async () => {
    const object = {
      type: typeStoreSupplierConnection,
      filename: filename,
      confirm: true,
    };
    const { ok, response } = await confirmFile(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
  };
  const confirmInfo = async (object) => {
    const response = await confirmInfoFile(object, token);
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setModalOpen(true);
      setFileImport(null);
      setErr(true);
      // setResp(t("COMMON:UPLOAD_FAILED"));
      setResp(message);
      setProccessLoading(false);
      setIsModalPreviewOpen(false);
    } else {
      setModalOpen(true);
      setErr(false);
      // setResp(t("COMMON:UPLOAD_SUCCESS"));
      setResp(message);
      setIsModalPreviewOpen(false);
      setFileImport(null);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
  };
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  // parameter customer
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPage(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);
  // filter dan parameter

  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };

  const handleSelectDistributor = (name, value, option) => {
    setidDistributor(value);
    setIsDisabled(false);
  };
  //   end parameter distributor

  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    }
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setFilterDataStore(null);
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    // cek filternya dipilih apa engga
    if (selectDropDownFilter != null) {
      setFilterDataStore(form);
    }
    setSearch(true);
    setPage(1);
    dispatch(
      pageActions.setFilters({
        idCustomer: customerId,
        idDistributor: idDistributor,
        selectDropDownFilter: selectDropDownFilter,
        countSearch: countSearch,
      })
    );
    setIsSubmit(!isSubmit);
  };
  const resetFilter = () => {
    setForm("");
    setFilterDataStore(null);
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setidDistributor(null);
    setSearch(false);
    setReset(!reset);
    setSelectDropDownFilter(null);
    setPage(1);
    setLimit(10);
    setCustomerId(null);
    setcountSearch("");
    setFilterDistributor("");
    setfilterCustomerName("");
    dispatch(pageActions.resetFilters());
    setIsSubmit(!isSubmit);
    setPageDistributor(1);
    setPageCustomer(1);
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeSelect = (name, value, option) => {
    if (name === "customer_id") {
      setCustomerId(value);
    } else {
      setSelectDropDownFilter(option);
    }
  };

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const filter = StringUtil.formatFilter({
    customer_id: `eq ${objectFilter?.idCustomer}`,
    distributor_id: `eq ${objectFilter?.idDistributor}`,
    [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
  });
  const params = { page, limit, filter, order };

  const fetchData = () => {
    dispatch(getListStoreSupplier(params));
  };

  useEffect(() => {
    dispatch(getInitListStoreSupplier(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    setSearch(false);
  }, [
    page,
    limit,
    token,
    isSubmit,
    search,
    dispatch,
    reset,
    order,
    dataChanged,
  ]);

  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport.map((item, index) => {
      return columnImport.reduce((prev, accumulator, index) => {
        prev[accumulator] = item[index];
        return prev;
      }, {});
    });

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_code: item.distributor_code,
      distributor_name: item.distributor_name,
      store_code: item.store_code,
      store_id: item.store_id,
      store_name: item.store_name,
      alias: item.principal_connection_store_id,
      store_alias: item.alias,
      supplier_code: item.supplier_code,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            id={ID_CSM_MASTER.LIST.BTN_EDIT + "_" + item.id}
            link={{
              pathname: ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.EDIT,
              state: item.id,
            }}
            text={<EditIcon />}
            backgroundColor="transparent"
          />
          <ButtonCustom
            id={ID_CSM_MASTER.LIST.BTN_VIEW + "_" + item.id}
            link={{
              pathname: ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.DETAIL,
              state: item.id,
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));
  const columnsImport = columnImport?.map((value) => {
    return renderStatusColumnImport(value);
  });
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_CODE")}
        </Box>
      ),
      dataIndex: "distributor_code",
      key: "distributor_code",
      sorter: (a, b) => a.distributor_code.localeCompare(b.distributor_code),
      sortDirections: ["descend", "ascend"],
      width: 140,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name),
      sortDirections: ["descend", "ascend"],
      width: 180,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "store_code",
      key: "store_code",
      sorter: (a, b) => a.store_code.localeCompare(b.store_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_ALIAS")}
        </Box>
      ),
      dataIndex: "store_alias",
      key: "store_alias",
      sorter: (a, b) => a.store_alias.localeCompare(b.store_alias),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name.localeCompare(b.store_name),
      sortDirections: ["descend", "ascend"],
      width: 180,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_ALIAS")}
        </Box>
      ),
      dataIndex: "alias",
      key: "alias",
      sorter: (a, b) => a.alias - b.alias,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_SUPPLIER_CODE")}
        </Box>
      ),
      dataIndex: "supplier_code",
      key: "supplier_code",
      sorter: (a, b) => a.supplier_code.localeCompare(b.supplier_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STATUS")}
        </Box>
      ),

      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === "A" ? <StatusActive /> : <StatusNonActive />}
              </Box>
              <Box color={status === "A" ? GREEN_COLOR : RED_COLOR}>
                {status === "A" ? "Active" : "Inactive"}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 110,
      align: "center",
    },
  ];

  return (
    <Box p={4}>
      <HeadTitle
        title={t("STORE_SUPPLIER_CONNECTION:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_3")}
            </span>{" "}
            - {t("STORE_SUPPLIER_CONNECTION:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("STORE_SUPPLIER_CONNECTION:BUTTON_1")}
              id={ID_CSM_MASTER.LIST.ADD_CONNECTION}
            />
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("STORE_SUPPLIER_CONNECTION:BUTTON_9")}
              marginStart={2}
              id={ID_CSM_MASTER.LIST.BTN_UPLOAD}
              colorScheme="blue"
              variant="outline"
              onClick={showModal}
            />
          </>
        }
      />

      <Box marginTop={6}>
        <Box marginLeft={"41.35rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            id={ID_CSM_MASTER.LIST.DISTRIBUTOR}
            style={selectOptFilterDistributorTheme}
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleSelectDistributor("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            id={ID_CSM_MASTER.LIST.CUSTOMER}
            style={selectOptFilterUserTheme}
            size="large"
            value={customerId}
            onPopupScroll={handleScrollCustomer}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                value={filterCustomerName}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_CUSTOMER")}
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            id={ID_CSM_MASTER.LIST.SEARCH}
            // value={selectDropDownFilter}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            value={selectDropDownFilter}
          >
            {selectDropDownFilterStore?.map((value, index) => {
              return (
                <>
                  <Select.Option key={index} value={value.value}>
                    {t(value.label)}
                  </Select.Option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              id={ID_CSM_MASTER.LIST.DDL}
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              // isDisabled={isDisabled}
              id={ID_CSM_MASTER.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_CSM_MASTER.LIST.BTN_RESET}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          onChange={handleTableChange}
          scroll={{
            x: 1300,
            y: 400,
          }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_CSM_MASTER.LIST.ROW_PAGE}
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  // onChange={handleSelectPagination}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="50">50</Select.Option>
                  <Select.Option value="100">100</Select.Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_CSM_MASTER.LIST.BTN_PAGINATION}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImport
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
        template={template_customer_store_mapping}
        token={token}
      />
      <ModalImportPreview
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
        scrollXLength={2200}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ListStoreSuppConn;
