import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import Headbar from "./components/headbar/Headbar";
import Sidebar from "./components/sidebar/Sidebar";
import { ROUTES } from "./constant/routeConstant";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  // Pengecekan token di pindah menggunakan apiConfig , karena kalau disini dia ngecek sebelum hit api, 
  // efeknya double login, semenntara di comment sampai benar benar tidak ada bug
  
  // const dispatch = useDispatch();
  // const history = useHistory()
  const { is_authenticated, menu, token } = useSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(true);

  // const handleCheckTokenExpiration = useCallback((token) => {
  //   if (checkIsTokenExpired(token)) {
  //     dispatch(authActions.resetRedux());
  //     history.replace(ROUTES.LOGIN);
  //   }
  // }, [dispatch, history])

  // useEffect(() => {
  //   handleCheckTokenExpiration(token);
  // }, [token, handleCheckTokenExpiration]);
  return (
    <Route
      {...rest}
      render={(props) =>
        is_authenticated ? (
          <Box height="100vh" display="flex" flexDirection="column">
            <Headbar collapsed={collapsed} setCollapsed={setCollapsed} />
            <Box height="full" width="full" display="flex" overflow="hidden">
              <Sidebar collapsed={collapsed} menu={menu} />
              <Box backgroundColor="#F2F2F2" height="full" width="full" overflow="hidden" overflowY="auto" className="content-wrapper">
                <Component {...props} />
              </Box>
            </Box>
          </Box>
        ) : (
          <Redirect to={{ pathname: ROUTES.LOGIN }} />
        )
      }
    />
  );
};
