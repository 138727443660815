import { Icon, InputRightElement, CloseButton } from "@chakra-ui/react";
import { SearchIcon } from "../components/icons";
const RenderSearchOrCloseButton = ({
  buttonSearchOrClose,
  handleCloseButtonSearch,
}) => {
  if (buttonSearchOrClose === "search") {
    return (
      <InputRightElement
        pointerEvents="none"
        children={<Icon as={SearchIcon} color="gray.300" />}
      />
    );
  } else {
    return (
      <InputRightElement
        onClick={handleCloseButtonSearch}
        children={<CloseButton />}
      />
    );
  }
};

export default RenderSearchOrCloseButton;
