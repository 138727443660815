import { REGEX_CONSTANT } from "../../../../constant/regexConstant";
import * as yup from "yup";
export const addCompanyProfileSchema = (t) => {
  return () =>
    yup.object().shape({
      npwp: yup
        .string()
        .max(
          32,
          t("DISTRIBUTOR:FIELD_NO_NPWP") +
            " " +
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 32,
            })
        )
        .matches(REGEX_CONSTANT.NIK_OR_NPWP, t("DISTRIBUTOR:ERROR_NPWP")),
      // .required(
      //   t("COMMON:ERROR_REQUIRED", { field: t("DISTRIBUTOR:FIELD_NO_NPWP") })
      // )
      company_name: yup
        .string()
        // .matches(
        //   REGEX_CONSTANT.DESCRIPTION_EXCEPT_UNDERSCORE,
        //   t("DISTRIBUTOR:ERROR_DISTRIBUTOR_NAME")
        // )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("DISTRIBUTOR:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      company_title_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("DISTRIBUTOR:FIELD_DISTRIBUTOR_TITLE"),
          })
        ),
      email: yup.string().matches(REGEX_CONSTANT.EMAIL, t("USER:ERROR_EMAIL")),
      phone_country_code: yup.string(),
      phone: yup
        .string()
        .matches(REGEX_CONSTANT.PHONE_NUMBER, t("USER:ERROR_PHONE_NUMBER")),
      // .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PHONE") }))
      address_1: yup
        .string()
        .max(
          500,
          t("COMMON:ERROR_MAX_LENGTH"),
          { field: t("USER:FIELD_ADDRESS") },
          { length: t("COMMON:MAX_LENGTH_ADDRESS") }
        )
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_ADDRESS") })
        ),
      country_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_COUNTRY") })
        ),
      // island_id: yup
      //   .number()
      //   .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("DISTRIBUTOR:FIELD_ISLAND") })
      //   ),
      province_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PROVINCE") })
        ),
      // district_id: yup
      //   .number()
      //   .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_DISTRICT") })
      //   ),
      // sub_district_id: yup
      //   .number()
      //   .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_SUB_DISTRICT") })
      //   ),
      // urban_village_id: yup
      //   .number()
      //   .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_URBAN_VILLAGE") })
      //   ),
      // postal_code_id: yup
      //   .number()
      //   .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_POSTAL_CODE") })
      //   ),
      alias: yup.string().required(
        t("COMMON:ERROR_REQUIRED", {
          field: t("DISTRIBUTOR:FIELD_ALIAS"),
        })
      ),
      status: yup.string().required(
        t("COMMON:ERROR_REQUIRED", {
          field: t("DISTRIBUTOR:FIELD_STATUS"),
        })
      ),
      hamlet: yup
        .string()
        .max(3)
        .matches(REGEX_CONSTANT.INTEGER, t("USER:ERROR_HAMLET")),
      neighbourhood: yup
        .string()
        .max(3)
        .matches(REGEX_CONSTANT.INTEGER, t("USER:ERROR_NEIGHBORHOOD")),
      latitude: yup.string().nullable(),
      longitude: yup.string().nullable(),
      images: yup
        .mixed()
        .nullable()
        .optional()
        .test({
          name: "testValidImageLogoCompany",
          test: (value, context) => {
            if (Array.isArray(value) && value.length > 0) {
              const photoFile = value[0];
              if (photoFile instanceof File) {
                if (!photoFile.type.match(REGEX_CONSTANT.VALID_IMAGE_JPEG)) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_FILE_JPEG"),
                  });
                }
                if (photoFile.size > 5000000) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_MAX_SIZE_MB", { size: 5 }),
                  });
                }
              }
            }

            return true;
          },
        }),
    });
};

export const editCompanyProfileSchema = (t) => {
  return () =>
    yup.object().shape({
      npwp: yup
        .string()
        .max(
          32,
          t("DISTRIBUTOR:FIELD_NO_NPWP") +
            " " +
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 32,
            })
        )
        .matches(REGEX_CONSTANT.NPWP, t("DISTRIBUTOR:ERROR_NPWP")),
      // .required(
      //   t("COMMON:ERROR_REQUIRED", { field: t("DISTRIBUTOR:FIELD_NO_NPWP") })
      // )
      company_name: yup
        .string()
        // .matches(
        //   REGEX_CONSTANT.DISTRIBUTOR_NAME,
        //   t("DISTRIBUTOR:ERROR_DISTRIBUTOR_NAME")
        // )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("DISTRIBUTOR:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      company_title_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("DISTRIBUTOR:FIELD_DISTRIBUTOR_TITLE"),
          })
        ),
      email: yup.string().matches(REGEX_CONSTANT.EMAIL, t("USER:ERROR_EMAIL")),
      // .required(t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_EMAIL") }))
      phone: yup
        .string()
        .matches(
          REGEX_CONSTANT.PHONE_NUMBER,
          t("DISTRIBUTOR:ERROR_PHONE_NUMBER")
        ),
      fax: yup
        .string()
        .matches(
          REGEX_CONSTANT.PHONE_NUMBER,
          t("DISTRIBUTOR:ERROR_FAX_NUMBER")
        ),
      address_1: yup
        .string()
        .max(
          500,
          t("COMMON:ERROR_MAX_LENGTH"),
          { field: t("USER:FIELD_ADDRESS") },
          { length: t("COMMON:MAX_LENGTH_ADDRESS") }
        )
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_ADDRESS") })
        ),
      status: yup.string().required(
        t("COMMON:ERROR_REQUIRED", {
          field: t("DISTRIBUTOR:FIELD_STATUS"),
        })
      ),
      alias: yup.string().required(
        t("COMMON:ERROR_REQUIRED", {
          field: t("DISTRIBUTOR:FIELD_ALIAS"),
        })
      ),
      country_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_COUNTRY") })
        ),
      // island_id: yup
      //   .object()
      //   .typeError(
      //     t("COMMON:ERROR_REQUIRED", {
      //       field: t("DISTRIBUTOR:FIELD_ISLAND"),
      //     })
      //   )
      //   .shape({
      //     value: yup
      //       .number()
      //       .nullable()
      //       .required(
      //         t("COMMON:ERROR_REQUIRED", {
      //           field: t("DISTRIBUTOR:FIELD_ISLAND"),
      //         })
      //       ),
      //   })
      //   ,
      province_id: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_PROVINCE") })
        ),
      // district_id: yup
      //   // .number()
      //   .string()
      //   // .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_DISTRICT") })
      //   ),
      // sub_district_id: yup
      //   // .number()
      //   .string()
      //   // .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_SUB_DISTRICT") })
      //   ),
      // urban_village_id: yup
      //   // .number()
      //   .string()
      //   // .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_URBAN_VILLAGE") })
      //   ),
      // postal_code_id: yup
      //   // .number()
      //   .string()
      //   // .nullable()
      //   .required(
      //     t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_POSTAL_CODE") })
      //   ),
      hamlet: yup
        .string()
        .min(3, t("USER:ERROR_HAMLET"))
        .max(3, t("USER:ERROR_HAMLET"))
        .matches(REGEX_CONSTANT.INTEGER, t("USER:ERROR_HAMLET")),
      neighbourhood: yup
        .string()
        .min(3, t("USER:ERROR_NEIGHBORHOOD"))
        .max(3, t("USER:ERROR_NEIGHBORHOOD"))
        .matches(REGEX_CONSTANT.INTEGER, t("USER:ERROR_NEIGHBORHOOD")),
      images: yup
        .mixed()
        .nullable()
        .optional()
        .test({
          name: "testValidImageLogoCompany",
          test: (value, context) => {
            if (Array.isArray(value) && value.length > 0) {
              const photoFile = value[0];
              if (photoFile instanceof File) {
                if (!photoFile.type.match(REGEX_CONSTANT.VALID_IMAGE_JPEG)) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_FILE_JPEG"),
                  });
                }
                if (photoFile.size > 5000000) {
                  return context.createError({
                    message: t("COMMON:ERROR_IMAGE_MAX_SIZE_MB", { size: 5 }),
                  });
                }
              }
            }

            return true;
          },
        }),
    });
};
