import * as ActionType from "../actions/actionType";
import {configureStore , createSlice} from '@reduxjs/toolkit';
import { addDataGroups, getListDataGroup } from "../services/main/dataGroupReducer";
import { getListRole } from "../services/main/roleReducer";
import { firstUrl } from "../constant/pageConstant";

const INITIAL_STATE = {
  auth_loading: false,
  table_loading: true,
  skeleton_loading: false,
  post_loading: false,
  put_loading: false,
  delete_loading: false,
  notification : "",
  prepare_component: false,
  currUrl : firstUrl,
  filters : {
    
  },
};

const pageSlice = createSlice({
  name :'page',
  initialState : INITIAL_STATE,
  reducers:{
    authFetching(state){
      state.auth_loading=true
    },

    authSuccess(state){
      state.auth_loading=false
    },

    authError(state){
      state.auth_loading=false
    },

    tableFetching(state){
      state.table_loading=true
    },

    tableSuccess(state){
      state.table_loading = false
    },

    tableError(state){
      state.table_loading = false
    },

    skeletonFetching(state){
      state.skeleton_loading = true
    },

    skeletonSuccess(state){
      state.skeleton_loading = false
    },

    skeletonError(state){
      state.skeleton_loading = false
    },

    postFetching(state){
      state.post_loading = true
    },

    postSuccess(state){
      state.post_loading = false
    },

    postError(state){
      state.post_loading = false
    },

    putFetching(state,payload){
      state.put_loading = true
    },

    putSuccess(state,payload){
      state.put_loading = false
    },

    putError(state){
      state.put_loading = false
    },

    deleteFetching(state){
      state.delete_loading = true
    },

    deleteSuccess(state){
      state.delete_loading = false
    },

    deleteError(state){
      state.delete_loading = false
    },

    prepareComponent(state,payload){
      state.prepare_component = payload.payload
    },

    setCurrUrl(state,action){
      state.currUrl = action.payload
    },

    resetRedux(state,payload){
      return state
    },
    setFilters(state, { payload }) {
      state.filters = payload;
    },
    resetFilters(state) {
      state.filters = {}
    }
  },
  extraReducers:{
    [getListDataGroup.pending] : (state,action)=>{
      state.table_loading = true;
    },
    [getListDataGroup.fulfilled] : (state,action)=>{
      state.table_loading = false;
    },
    [getListDataGroup.rejected] : (state,action)=>{
      state.table_loading = false;
      state.notification = action.payload.response
    },
    [addDataGroups.rejected]:(state,action)=>{
      state.popup_status = true;
      state.popup_notification = ''
      // message.error(i18next.t("COMMON:CONTOH_SALAH"));
    },
   

    [getListRole.pending]: (state, action) => {
      state.table_loading = true;
    },
    [getListRole.fulfilled]: (state, action) => {
      state.table_loading = false;
    },
    [getListRole.rejected]: (state, action) => {
      state.table_loading = false;
      state.notification = action.payload.response
    },
  }
})

export const pageActions = pageSlice.actions;

export default pageSlice;

