import {
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  inputMultiTheme,
  inputTheme,
} from "../../../../../constant/themeConstant";
import DropDownCustom from "../../../../../components/DropDownCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import {
  dayName,
  selectFrequency,
  selectStatus,
} from "../../../../../constant/selectOptConstant";
import { useFormik } from "formik";
import { editBklMappingSchema } from "./bklMappingSchema";
import {
  detailBKLMapping,
  editBKLMapping,
  getListCustomer,
  getListCustomerDDS,
  getListDistributor,
  getListPrincipal,
  getListStore,
  getListStoreDDS,
} from "../../../../../services/main/bklMappingReducer";
import StringUtil from "../../../../../utils/stringUtil";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { Redirect, useLocation } from "react-router-dom";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import { CapitalizeFirstLetter } from "../../../../../function/CapitalizeFirstLetter";
import { ID_BKL_MAPPING } from "../../../../../constant/idConstant";
const EditBKLMapping = () => {
  const { Text, 
    // Link 
  } = Typography;
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [dataDetail, setDataDetail] = useState({});

  // page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageUnlimited, setPageUnlimited] = useState(-99);
  const [limitUnlimited, setLimitUnlimited] = useState(-99);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);

  // open po dan jwk depo
  // const [isCheckedOpenPo, setisCheckedOpenPo] = useState([]);
  // const [isCheckedJwkDepo, setisCheckedJwkDepo] = useState([]);

  // Code(for disabled input)
  const [principalCode, setPrincipalCode] = useState("");
  const [idCust, setIdCust] = useState(null);

  const handleSelectClear = (name) => {
    switch (name) {
      case "customer_id":
        setFieldValue("store_id", null);
        break;
      case "frequency":
        setFieldValue("jwk_depo", []);
        setFieldValue("open_po", []);
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "distributor_id":
        setFieldValue("distributor_id", value);
        setFieldValue("distributor_code", option?.code);
        break;
      case "customer_id":
        setFieldValue("customer_id", {
          value: value,
          label: option?.name,
        });
        setIdCust(value);
        setFieldValue("customer_code", option?.code);
        setFieldValue("store_id", null);
        setFieldValue("store_code", "");
        break;
      case "store_id":
        setFieldValue("store_id", {
          value: value,
          label: option?.name,
        });
        setFieldValue("store_code", option?.code);
        break;
      case "frequency":
        setFieldValue("frequency", value);
        break;
      case "area_code":
        setFieldValue("area_code", value);
        break;
      case "id_depo":
        setFieldValue("id_depo", value);
        break;
      case "store_sap_id":
        setFieldValue("store_sap_id", value);
        break;
      case "store_sap_name":
        setFieldValue("store_sap_name", value);
        break;
      case "open_po":
        if (value?.length <= values?.frequency) {
          setFieldValue("open_po", value);
        }
        break;
      case "jwk_depo":
        if (value?.length <= values?.frequency) {
          setFieldValue("jwk_depo", value);
        }
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };

  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const fetchDataDetail = () => {
    setIsLoading(true);
    dispatch(detailBKLMapping(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(response.payload.response.updated_at);
        setDataDetail(response.payload.response);
      }
    });
    setIsLoading(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageUnlimited;
    const limit = limitUnlimited;
    const updated_at_start = "2000-01-01T00:00:00Z";
    const params = { page, limit, updated_at_start };
    dispatch(getListDistributor(params)).then((response) => {
      setDataDistributor(response.payload.response);
    });
  };
  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };
  // store
  const [dataStore, setDataStore] = useState([]);
  const [filterStore, setfilterStore] = useState("");
  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      customer_id: `eq ${values?.customer_id?.value ?? ""}`,
    });
    const params = values?.customer_id
      ? { page, limit, filter }
      : { page, limit };
    if (values?.customer_id?.value === null) {
      setDataStore([]);
    } else {
      dispatch(getListStoreDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        // setDataStore(resp);
        setDataStore([...dataStore, ...resp]);
      });
    }
  };
  const fetchSearchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      store_name: `like ${filterStore ?? ""}`,
      customer_id: `eq ${values?.customer_id?.value ?? ""}`,
    });
    const params = { page, limit, filter };
    if (values?.customer_id.value === null) {
      setDataStore([]);
    } else {
      dispatch(getListStoreDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };
  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };
  const onSubmit = () => {
    const bklId = location.state.id;
    const payload = {
      ...values,
      bklId,
    };
    dispatch(editBKLMapping(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: {
        value: dataDetail?.customer_id,
        label: dataDetail?.customer_name,
      },
      customer_code: dataDetail?.customer_code,
      store_id: {
        value: dataDetail?.store_id,
        label: dataDetail?.store_name,
      },
      store_code: dataDetail?.store_code,
      distributor_id: dataDetail?.distributor_id,
      distributor_code: dataDetail?.distributor_code,
      area_code: dataDetail?.area_code,
      distribution_center: dataDetail?.distribution_center,
      store_sap_id: dataDetail?.store_sap_id,
      store_sap_name: dataDetail?.store_sap_name,
      frequency: dataDetail?.frequency,
      open_po: dataDetail?.open_po?.map((val) => {
        return CapitalizeFirstLetter(val.toLowerCase());
      }),
      jwk_depo: dataDetail?.jwk_depo?.map((val) => {
        return CapitalizeFirstLetter(val.toLowerCase());
      }),
      id_depo: dataDetail?.id_depo,
      status: dataDetail?.status,
      updated_at: dataDetail?.updated_at,
    },
    validationSchema: editBklMappingSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  });

  //use effect once
  useEffectOnce(() => {
    fetchDataDetail();
  });

  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);
  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, []);

  // customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, values?.customer_id]);

  useEffect(() => {
    fetchSearchStore();
  }, [filterStore]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.BKL_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("BKL_MAPPING:EDIT_BKL_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("BKL_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("BKL_MAPPING:EDIT_BKL_MAPPING")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* principal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_BKL_MAPPING.EDIT.PRINCIPAL_CODE}
                  style={inputTheme}
                  disabled
                  value={
                    principalCode ? principalCode : dataDetail?.principal_code
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Principal Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  id={ID_BKL_MAPPING.EDIT.PRINCIPAL_NAME}
                  style={inputTheme}
                  disabled
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_BKL_MAPPING.EDIT.CUSTOMER_CODE}
                  value={values?.customer_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  onPopupScroll={handleScrollCustomer}
                  id={ID_BKL_MAPPING.EDIT.CUSTOMER_NAME}
                  allowClear
                  onClear={() => handleSelectClear()}
                  value={values?.customer_id}
                  name="customer_id"
                  onBlur={handleBlur("customer_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataCustomer &&
                    dataCustomer?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          name={data.name}
                          code={data.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors?.customer_id?.value && touched?.customer_id?.value && (
                  <ValidationError text={errors?.customer_id?.value} />
                )}
              </Box>
            </Box>
            {/* store code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  id={ID_BKL_MAPPING.EDIT.STORE_CODE}
                  type="text"
                  style={inputTheme}
                  disabled
                  value={values?.store_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Store Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_BKL_MAPPING.EDIT.STORE_NAME}
                  onPopupScroll={handleScrollStore}
                  allowClear
                  value={values?.store_id}
                  name="store_id"
                  onBlur={handleBlur("store_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterStore(e.target.value)}
                    />
                  )}
                >
                  {dataStore &&
                    dataStore?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.store_name}
                          code={data.store_code}
                        >
                          {data.store_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors?.store_id?.value && touched?.store_id?.value && (
                  <ValidationError text={errors?.store_id?.value} />
                )}
              </Box>
            </Box>
            {/* distribution centre  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_DISTRIBUTION_CENTRE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="distribution_center"
                  id={ID_BKL_MAPPING.EDIT.DISTRIBUTION_CENTRE}
                  style={inputTheme}
                  value={values?.distribution_center}
                  onBlur={handleBlur("distribution_center")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.distribution_center && touched.distribution_center && (
                  <ValidationError text={errors.distribution_center} />
                )}
              </Box>
            </Box>
            {/* Frequency  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_FREQUENCY")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_BKL_MAPPING.EDIT.FREQUENCY}
                  allowClear
                  onClear={() => handleSelectClear("frequency")}
                  value={values?.frequency}
                  name="frequency"
                  onBlur={handleBlur("frequency")}
                  onChange={(value, opt) =>
                    handleChangeSelect("frequency", value, opt)
                  }
                >
                  {selectFrequency.map((value, index) => {
                    return (
                      <Select.Option value={value} key={index.toString()}>
                        {value}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.frequency && touched.frequency && (
                  <ValidationError text={errors.frequency} />
                )}
              </Box>
            </Box>
            {/* Area Code  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_AREA_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="area_code"
                  id={ID_BKL_MAPPING.EDIT.AREA_CODE}
                  style={inputTheme}
                  value={values?.area_code}
                  onBlur={handleBlur("area_code")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.area_code && touched.area_code && (
                  <ValidationError text={errors.area_code} />
                )}
              </Box>
            </Box>
            {/* Depo Id  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_DEPO_ID")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="id_depo"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.EDIT.DEPO_ID}
                  value={values?.id_depo}
                  onBlur={handleBlur("id_depo")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.id_depo && touched.id_depo && (
                  <ValidationError text={errors.id_depo} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* Distributor Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_BKL_MAPPING.EDIT.DISTRIBUTOR_CODE}
                  style={inputTheme}
                  disabled
                  value={values?.distributor_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* distributor name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_BKL_MAPPING.EDIT.DISTRIBUTOR_NAME}
                  allowClear
                  value={values?.distributor_id}
                  name="distributor_id"
                  onBlur={handleBlur("distributor_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                >
                  {dataDistributor &&
                    dataDistributor?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.name}
                          code={data.distributor_code}
                          principalid={data.principal_id}
                          principalname={data.principal_name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>
            {/* store sap id */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_STORE_SAP_ID")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="store_sap_id"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.EDIT.STORE_SAP_ID}
                  value={values?.store_sap_id}
                  onBlur={handleBlur("store_sap_id")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.store_sap_id && touched.store_sap_id && (
                  <ValidationError text={errors.store_sap_id} />
                )}
              </Box>
            </Box>
            {/* store sap name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_STORE_SAP_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="store_sap_name"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.EDIT.STORE_SAP_NAME}
                  value={values?.store_sap_name}
                  onBlur={handleBlur("store_sap_name")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.store_sap_name && touched.store_sap_name && (
                  <ValidationError text={errors.store_sap_name} />
                )}
              </Box>
            </Box>
            {/* Open PO  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("BKL_MAPPING:FIELD_OPEN_PO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputMultiTheme}
                  size="large"
                  maxTagCount={2}
                  allowClear
                  showArrow
                  mode="multiple"
                  id={ID_BKL_MAPPING.EDIT.OPEN_PO}
                  value={values?.open_po}
                  disabled={
                    values?.frequency && values.frequency > 0 ? false : true
                  }
                  name="open_po"
                  onBlur={handleBlur("open_po")}
                  onChange={(value, opt) =>
                    handleChangeSelect("open_po", value, opt)
                  }
                >
                  {dayName?.map((val, index) => {
                    return (
                      <option
                        value={val}
                        key={index.toString()}
                        // checked={values?.open_po?.includes(val)}
                        className={
                          values?.open_po?.length >= values?.frequency
                            ? "ant-select-item-option-custom"
                            : "ant-select-item-option"
                        }
                        // disabled={values?.open_po?.length >= values?.frequency}
                      >
                        {val}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            {/* Jadwal Kirim Depo  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("BKL_MAPPING:FIELD_JADWAL_KIRIM_DEPO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputMultiTheme}
                  size="large"
                  allowClear
                  id={ID_BKL_MAPPING.EDIT.DELIVERY}
                  maxTagCount={2}
                  showArrow
                  mode="multiple"
                  value={values?.jwk_depo}
                  name="jadwal_kirim_depo"
                  disabled={
                    values?.frequency && values.frequency > 0 ? false : true
                  }
                  onBlur={handleBlur("jwk_depo")}
                  onChange={(value, opt) =>
                    handleChangeSelect("jwk_depo", value, opt)
                  }
                >
                  {dayName?.map((val, index) => {
                    return (
                      <option
                        value={val}
                        key={index.toString()}
                        // disabled={values?.jwk_depo?.length >= values?.frequency}
                        className={
                          values?.jwk_depo?.length >= values?.frequency
                            ? "ant-select-item-option-custom"
                            : "ant-select-item-option"
                        }
                      >
                        {val}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("BKL_MAPPING:FIELD_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_BKL_MAPPING.EDIT.STATUS}
                  size="large"
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.BKL_MAPPING.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_BKL_MAPPING.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_BKL_MAPPING.EDIT.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EditBKLMapping;
