import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ID_NOTIFICATION_MASTER } from "../../../../constant/idConstant";
import {
  PRIMARY_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import {
  selectDropDownCategory,
  selectStatus
} from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import { detailNotification, updateNotification } from "../../../../services/main/notificationReducer";
import { updateSchema } from "./NotificationSchema";

const EditNotification = () => {
  const detailData = useSelector(
    (data) => data.notification.detail_notification
  );

  const { t } = useTranslation();

  const location = useLocation();

  const history = useHistory();
  const { Option } = Select;
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);


  // submit data
  const onSubmit = (values, action) => {
    const id = location.state;
    const object = { id, values };
    dispatch(updateNotification(object)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
        action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };

  // fungsi modal
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.NOTIFICATION.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    dispatch(detailNotification(location.state));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const initialData = useMemo(() => {
    return {
      category: detailData?.category,
      sub_category: detailData?.sub_category,
      notification_code: detailData?.notification_code,
      name: detailData?.name,
      principal_id: detailData?.principal_id,
      distributor_id: detailData?.distributor_id,
      notification_po_to: detailData?.notification_po_to,
      notification_po_cc: detailData?.notification_po_cc,
      alert_to: detailData?.alert_to,
      alert_cc: detailData?.alert_cc,
      status: detailData?.status,
      updated_at: detailData?.updated_at,
    };
  }, [detailData]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: updateSchema,
    onSubmit,
  });

  const handleChangeSelect = (name, value, option) => {
    setFieldValue(name, value);
  };
  const renderSuccess = () => {
    if (values.category === "SUCCESS") {
      return (
        <>
          {/* FIELD_NOTIFICATION_PO_TO */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_PO_TO")}</Text>
            </Box>
            <Box className="form-input-padding-bottom" pb={1}>
              <Input
                value={values.notification_po_to}
                name="notification_po_to"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.EDIT.EMAIL_PO_TO}
              />
              {errors.notification_po_to && touched.notification_po_to && (
                <ValidationError text={errors.notification_po_to} />
              )}
            </Box>
          </Box>
          {/* notification po cc */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_PO_CC")}</Text>
            </Box>
            <Box className="form-input-padding-bottom" pb={1}>
              <Input
                value={values.notification_po_cc}
                name="notification_po_cc"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.EDIT.EMAIL_PO_CC}
              />
              {errors.notification_po_cc && touched.notification_po_cc && (
                <ValidationError text={errors.notification_po_cc} />
              )}
            </Box>
          </Box>
        </>
      );
    }
  };

  const renderFailed = () => {
    if (values.category === "FAILED") {
      return (
        <>
          {/* email alert to */}
          <Box padding={1} display="flex">
            <Box width="10rem" className="form-title">
              <Text>{t("NOTIFICATION:FIELD_EMAIL_ALERT_TO")}</Text>
            </Box>
            <Box className="form-input-padding-bottom" pb={1}>
              <Input
                value={values.alert_to}
                name="alert_to"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.EDIT.EMAIL_ALERT_TO}
              />
              {errors.alert_to && touched.alert_to && (
                <ValidationError text={errors.alert_to} />
              )}
            </Box>
          </Box>
          {/* alert cc */}
          <Box padding={1} display="flex">
            <Box width="10rem" className="form-title">
              <Text>{t("NOTIFICATION:FIELD_EMAIL_ALERT_CC")}</Text>
            </Box>
            <Box className="form-input-padding-bottom">
              <Input
                value={values.alert_cc}
                name="alert_cc"
                onChange={handleChange}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_NOTIFICATION_MASTER.EDIT.EMAIL_ALERT_CC}
              />
              {errors.alert_cc && touched.alert_cc && (
                <ValidationError text={errors.alert_cc} />
              )}
            </Box>
          </Box>
        </>
      );
    }
  };
  return (
    <Box p={4}>
      {/* Head Title session */}
      <HeadTitle
        title={t("NOTIFICATION:EDIT_NOTIFICATION")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:TITLE_LIST")}
            </span>{" "}
            - {t("NOTIFICATION:SUBTITLE_5")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          //   justifyContent="space-around"
          display="flex"
          flex-direction="column"
          align-items="flex-end"
          gap="60px"
          marginTop={4}
          borderRadius={10}
        >
          {/* left side */}
          <Box
            padding={1}
            width="402px"
            flexDirection="column"
            alignItems="flex-start"
          >
            {/* category */}
            <Box
              padding={1}
              width="402px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="28px"
            >
              <Box className="form-title">
                <Text width="92px">{t("NOTIFICATION:FIELD_CATEGORY")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  disabled
                  style={{ width: 250, borderRadius: "6px" }}
                  value={values.category}
                  id={ID_NOTIFICATION_MASTER.EDIT.NOTIFICATION_CATGEORY}
                  size="large"
                >
                  {selectDropDownCategory?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            {/* principal code*/}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  name="barcode"
                  onChange={handleChange}
                  value={detailData.principal_code}
                  style={inputTheme}
                  type="text"
                  id={ID_NOTIFICATION_MASTER.EDIT.PRINCIPAL_CODE}
                />
              </Box>
            </Box>

            {/* distributor code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_DISTRIBUTOR_CODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  value={detailData.distributor_code}
                  id={ID_NOTIFICATION_MASTER.EDIT.DISTRIBUTOR_CODE}
                  style={inputTheme}
                />
              </Box>
            </Box>

            {/* Notification Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("NOTIFICATION:FIELD_NOTIFICATION_CODE")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  value={values.notification_code}
                  name="notification_code"
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  id={ID_NOTIFICATION_MASTER.EDIT.NOTIFICATION_CODE}
                />
              </Box>
            </Box>
            {renderSuccess()}
            {renderFailed()}
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  value={values.status}
                  allowClear="true"
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  width="250px"
                  id={ID_NOTIFICATION_MASTER.EDIT.STATUS}
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={inputTheme}
                  value={detailData.principal_name}
                  placeholder="Select Principal"
                  size="large"
                  disabled
                  id={ID_NOTIFICATION_MASTER.EDIT.PRINCIPAL_NAME}
                >
                  <Select.Option
                    key={detailData}
                    value={detailData.principal_name}
                  >
                    {detailData.principal_name}
                  </Select.Option>
                </Select>
              </Box>
            </Box>
            {/* distributor name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("NOTIFICATION:FIELD_DISTRIBUTOR_NAME")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={inputTheme}
                  value={detailData.distributor_name}
                  size="large"
                  id={ID_NOTIFICATION_MASTER.EDIT.DISTRIBUTOR_NAME}
                  disabled
                >
                  <Select.Option
                    key={detailData.distributor_name}
                    value={detailData.distributor_name}
                  >
                    {detailData.distributor_name}
                  </Select.Option>
                </Select>
              </Box>
            </Box>
            {/* notification name */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2} className="form-title">
                <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_NAME")}</Text>
              </Box>
              <Box className="form-input-padding-bottom" pb={1}>
                <Input
                  disabled
                  value={values.name}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  id={ID_NOTIFICATION_MASTER.EDIT.NOTIFICATION_NAME}
                />

              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_BUYER.NOTIFICATION.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_NOTIFICATION_MASTER.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_NOTIFICATION_MASTER.EDIT.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default EditNotification;
