import {
  Box,
  Stack,
  Divider,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import { ROUTES } from "../../../../constant/routeConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  BlueEditIcon,
  RedDeleteIcon,
  DeleteSuccess,
  UnSuccess,
} from "../../../../components/icons";
import BodyUserProfile from "./body/BodyUserProfile";
import {
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import BodyLoginAccount from "./body/BodyLoginAccount";
import BodyAccessSetting from "./body/BodyAccessSetting";
import BodyActivation from "./body/BodyActivation";
import BodyAuthentication from "./body/BodyAuthentication";
import BackToList from "../../../../components/BackToList";
import { deleteUser, detailUsers } from "../../../../services/main/userReducer";
import Spin from "antd/lib/spin";
import moment from "moment";
import ModalCustom from "../../../../components/ModalCustom";
import ModalConfirm from "../../../../components/ModalConfirm";
import { ID_USER } from "../../../../constant/idConstant";
const DetailUser = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(
    t("USER:MULTIPLE_STEP_USER_PROFILE")
  );
  const dispatch = useDispatch();
  const changePageToUserProfile = () => {
    setCurrentPage(t("USER:MULTIPLE_STEP_USER_PROFILE"));
  };
  const changePageToLoginAccount = () => {
    setCurrentPage(t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT"));
  };
  const changePageToAccessSetting = () => {
    setCurrentPage(t("USER:MULTIPLE_STEP_ACCESS_SETTING"));
  };
  const changePageToActivation = () => {
    setCurrentPage(t("USER:MULTIPLE_STEP_ACTIVATION"));
  };
  const changePageToAuthentication = () => {
    setCurrentPage(t("USER:MULTIPLE_STEP_AUTHENTICATION"));
  };
  const [updatedAt, setUpdatedAt] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [detailUser, setDetailUser] = useState({});
  const [message, setMessage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const payload = { updated_at: updatedAt };

  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };
  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      userId: Number(location.state.id),
    };
    dispatch(deleteUser(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };
  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailUsers(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(
          response.payload.response.nexsoft.payload.data.content.updated_at
        );
        setDetailUser(response.payload.response.nexsoft.payload.data.content);
      } else {
      }
    });
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [dispatch, setUpdatedAt, setIsLoading]);
  const getDetailUser = useSelector((state) => state.user.detail_user);
  return isDeleted ? (
    <Redirect to={ROUTES.USER.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("USER:DETAIL_USER")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("USER:SUBTITLE_1")}</span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>{t("USER:SUBTITLE_2")}</span>{" "}
            - {t("USER:SUBTITLE_4")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              id={ID_USER.DETAIL.BTN_EDIT}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES.USER.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
              backgroundcolor="transparent"
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              id={ID_USER.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES.USER.LIST}
        name={getDetailUser?.created_name}
        date={moment(getDetailUser?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(getDetailUser?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={getDetailUser?.updated_name}
        id={ID_USER.DETAIL.BTN_BACK}
      />
      {/* body */}
      <Box backgroundColor="white" minHeight="300px" padding={4}>
        <Stack direction="row" marginBottom={4}>
          <ButtonCustom
            text={t("USER:MULTIPLE_STEP_USER_PROFILE")}
            backgroundcolor={
              currentPage === t("USER:MULTIPLE_STEP_USER_PROFILE")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("USER:MULTIPLE_STEP_USER_PROFILE")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={changePageToUserProfile}
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")}
            backgroundcolor={
              currentPage === t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={changePageToLoginAccount}
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("USER:MULTIPLE_STEP_ACCESS_SETTING")}
            backgroundcolor={
              currentPage === t("USER:MULTIPLE_STEP_ACCESS_SETTING")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("USER:MULTIPLE_STEP_ACCESS_SETTING")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={changePageToAccessSetting}
          />
          <Divider orientation="vertical" color="red" variant="dashed" />
          <ButtonCustom
            text={t("USER:MULTIPLE_STEP_ACTIVATION")}
            backgroundcolor={
              currentPage === t("USER:MULTIPLE_STEP_ACTIVATION")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("USER:MULTIPLE_STEP_ACTIVATION")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={changePageToActivation}
          />
          <Divider orientation="vertical" color="red" variant="dashed" />
          <ButtonCustom
            text={t("USER:MULTIPLE_STEP_AUTHENTICATION")}
            backgroundcolor={
              currentPage === t("USER:MULTIPLE_STEP_AUTHENTICATION")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              currentPage === t("USER:MULTIPLE_STEP_AUTHENTICATION")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={changePageToAuthentication}
          />
        </Stack>

        {/* content of body  */}
        {isLoading ? (
          <Spin size="small" />
        ) : currentPage === t("USER:MULTIPLE_STEP_USER_PROFILE") ? (
          <BodyUserProfile loading={isLoading} getDetailUser={getDetailUser} />
        ) : currentPage === t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT") ? (
          <BodyLoginAccount />
        ) : currentPage === t("USER:MULTIPLE_STEP_ACCESS_SETTING") ? (
          <BodyAccessSetting />
        ) : currentPage === t("USER:MULTIPLE_STEP_ACTIVATION") ? (
          <BodyActivation />
        ) : currentPage === t("USER:MULTIPLE_STEP_AUTHENTICATION") ? (
          <BodyAuthentication />
        ) : (
          <BodyUserProfile />
        )}
      </Box>
      <ModalCustom
       icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
       message={message}
       onOk={deleted ? clickOk : clickOnFailedDelete}
       isOpen={modalOpen}
      />
      <ModalConfirm
         messageModal={t("COMMON:DELETE_TITLE")}
         messageConfirm={t("COMMON:DELETE_CONFIRM")}
         onClose={closeModalConfirm}
         onOk={openModalDelete}
         isOpen={modalConfirmOpen}
         idYes={ID_USER.DETAIL.BTN_YES}
         idNo={ID_USER.DETAIL.BTN_NO}
      />
    </Box>
  );
};
export default DetailUser;
