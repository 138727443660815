import { REGEX_CONSTANT } from "../../../constant/regexConstant";
import * as yup from "yup";

export const addDataGroupShema = (t) => {
  return () =>
    yup.object().shape({
      group_id: yup
        .string()
        // .min(
        //     3,
        //     t("COMMON:ERROR_MIN_LENGTH", {
        //       length: t(3),
        //     })
        //   )
        .max(
          35,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: 35,
          })
        )
        .nullable()
        .matches(REGEX_CONSTANT.GROUP_ID, t("DATA_GROUP:ERROR_GROUP_ID"))
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("DATA_GROUP:FIELD_DATA_GROUP_ID"),
          })
        ),
      description: yup
        .string()
        .max(
          100,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: 100,
          })
        )
        .nullable()
        .matches(
          REGEX_CONSTANT.DESCRIPTION_EXCEPT_UNDERSCORE,
          t("DATA_GROUP:ERROR_DESCRIPTION")
        )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("DATA_GROUP:FIELD_DESCRIPTION"),
          })
        ),
      status: yup
        .string()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("DATA_GROUP:FIELD_STATUS") })
        ),
      level: yup
        .number()
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("DATA_GROUP:FIELD_LEVEL") })
        ),
    });
};

export const addDataScopeChema = (t) => {
  return () =>
    yup.object().shape({
      po_status: yup
        .object()
        .nullable()
        // .when('po_status', {
        //   is: (status) => typeof status === "object",
        //   then: (storeSchema) =>
        //     storeSchema
        //       .object()
        //       .min(
        //         1,
        //         t("COMMON:ERROR_REQUIRED", {
        //           field: t("DATA_GROUP:FIELD_STORE"),
        //         })
        //       )
        //       .required(
        //         t("COMMON:ERROR_REQUIRED", {
        //           field: t("PRODUCT_CATEGORY:FIELD_STORE"),
        //         })
        //       ),
        //   otherwise: (storeSchema) =>
        //     storeSchema
        //       .string()
        //       .required(
        //         t("COMMON:ERROR_REQUIRED", { field: t("DATA_GROUP:FIELD_PO_STATUS") })
        //       ),
        // })
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("DATA_GROUP:FIELD_PO_STATUS") })
        ),
      principal_branch: yup
        .array()
        .min(
          1,
          t("COMMON:ERROR_REQUIRED", {
            field: t("DATA_GROUP:FIELD_PRINCIPAL_BRANCH"),
          })
        )
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_BRANCH"),
          })
        ),
      customer: yup
        .array()
        .min(
          1,
          t("COMMON:ERROR_REQUIRED", {
            field: t("DATA_GROUP:FIELD_CUSTOMER"),
          })
        )
        .nullable()
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("PRODUCT_CATEGORY:FIELD_CUSTOMER"),
          })
        ),

      store: yup
        .array()
        .nullable()
        .when('customer', {
          is: (customer) => customer.length > 0,
          then: (storeSchema) =>
            storeSchema
              .min(
                1,
                t("COMMON:ERROR_REQUIRED", {
                  field: t("DATA_GROUP:FIELD_STORE"),
                })
              )
              .required(
                t("COMMON:ERROR_REQUIRED", {
                  field: t("PRODUCT_CATEGORY:FIELD_STORE"),
                })
              ),
        })
    });
};
