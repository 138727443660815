import axios from "axios";
import apiInstance from "./apiConfig";

export const doGet = (url, token, params, cancelToken) => {
  const method = "GET";
  const headers = { 'Authorization': token };
  const request = { url, method, headers, params, cancelToken };
  
  return apiInstance(request);
};

export const doGetResponseBlob = (url, token, params, cancelToken) => {
 
  const method = "GET";
  const headers = { Authorization: token }; // add header
  const request = { url, method, headers, params, cancelToken , responseType: 'blob' };

  return apiInstance(request);
};
export const doPost = (url, token, data) => {
  const config = {
    'Content-Type' : data instanceof FormData ? 'multipart/form-data' : 'application/json'
  }
  const method = "POST";
  const headers = { Authorization: token , ...config }; // add header
  const request = { url, method, headers, data };

  return apiInstance(request);
};

export const doPostResponseBlob = (url, token, data) => {
  const config = {
    'Content-Type' : data instanceof FormData ? 'multipart/form-data' : 'application/json'
  }
  const method = "POST";
  const headers = { Authorization: token , ...config }; // add header
  const request = { url, method, headers, data, responseType: 'blob' };

  return apiInstance(request);
};

export const doPut = (url, token, data) => {
  const method = "PUT";
  const headers = { Authorization: token };
  const request = { url, method, headers, data };

  return apiInstance(request);
};

export const doDelete = (url, token, data) => {
  const method = "DELETE";
  const headers = { Authorization: token };
  const request = { url, method, headers, data };

  return axios(request);
};

export const fetchWithCustomOption = (options) => {
  const request = { ...options };

  return axios(request);
};
