import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "../icons";
import VirtualList from "react-tiny-virtual-list";
import styles from "./MultiSelectDropdown.module.css";
import { useTranslation } from "react-i18next";
import LoadingIndicatorSpinner from "../spinner/LoadingIndicatorSpinner";
import { motion } from "framer-motion";
import InputSearch from "../input/InputSearch";

const COLORS = {
  DANGER: "#BC2023",
  WHITE: "#FFF",
  LIGHT_GRAY: "#DDD",
  MEDIUM_GRAY: "#999",
  BG_DANGER: "#BC20231A",
  BG_LIGHT_GRAY: "#777",
  BG_DISABLED: "#F2F2F2",
  BG_PRIMARY: "#E5EBF1",
};

const ValueOption = ({ id, index, value, label, onRemove, style }) => {
  const handleOnClick = () => {
    return onRemove({ index, value, label });
  };
  return (
    <Flex
      style={{ ...style, margin: "3px 2px" }}
      alignItems="center"
      justifyContent="space-between"
      backgroundColor={COLORS.BG_LIGHT_BLUE}
      borderRadius="4px"
      height="18px !important"
      paddingLeft="1"
      maxWidth="150px"
      className={`${id}_ValueOption`}
    >
      <Text
        fontSize="12px"
        fontWeight="400"
        marginRight="0.5"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        cursor="default"
        title={label}
      >
        {label}
      </Text>
      <Flex
        role="group"
        height="100%"
        width="18px"
        justifyContent="center"
        alignItems="center"
        borderRadius="0 4px 4px 0px"
        _hover={{
          backgroundColor: COLORS.BG_DANGER,
          cursor: "pointer",
          color: COLORS.DANGER,
        }}
        onClick={handleOnClick}
        className={`${id}_RemoveOption`}
      >
        <SmallCloseIcon pointerEvents="none" />
      </Flex>
    </Flex>
  );
};

const DropdownOption = ({
  index,
  value,
  label,
  disabled,
  selectedOptions,
  selectAll,
  onChange,
  style,
}) => {
  const isSelected = useMemo(() => {
    let isSelectedIndex = false;
    try {
      isSelectedIndex =
        selectedOptions?.findIndex((option) => option.value === value) >= 0;
    } catch (ignore) {}

    if (selectAll) {
      isSelectedIndex = true;
    }

    return isSelectedIndex;
  }, [selectedOptions, value, selectAll]);

  const handleOnClick = () => {
    onChange && onChange({ value, label, index, isSelected });
  };

  return (
    <Flex
      style={style}
      pointerEvents={disabled ? "none" : "all"}
      onClick={handleOnClick}
      alignItems="center"
      _hover={{
        backgroundColor: COLORS.BG_PRIMARY,
      }}
      color={disabled ? COLORS.MEDIUM_GRAY : ''}
      paddingLeft="3"
    >
      <input name="_all" type="checkbox" checked={isSelected} readOnly />
      <Text
        fontSize="sm"
        marginLeft="1"
        // fontSize='12px'
        fontWeight="400"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        cursor="default"
        title={label}
      >
        {label}
      </Text>
    </Flex>
  );
};

const MenuList = React.forwardRef(
  (
    { children, onClick, selectAll, onSearch, isLoading, isOption, values },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Box
        position="absolute"
        width="100%"
        transform="translateY(2px)"
        border={`1px solid ${COLORS.LIGHT_GRAY}`}
        borderRadius="2px"
        maxHeight="385px"
        backgroundColor="#fff"
        zIndex="4"
        ref={ref}
      >
        <Box>
          <Box marginRight="0" width="100%">
            <InputSearch
              // value={searchValue}
              onSearch={onSearch}
            />
          </Box>

          <Flex onClick={onClick} paddingLeft="3" marginTop="3">
            <input name="_all" type="checkbox" defaultChecked={selectAll} />
            <Text fontSize="sm" marginLeft="1">
              {t("COMMON:SELECT_ALL")}
            </Text>
          </Flex>
          {children}

          {isLoading && (
            <Flex
              as={motion.div}
              position="absolute"
              top="12"
              left="0"
              backgroundColor={COLORS.WHITE}
              opacity="0.5"
              width="100%"
              height="280px"
              zIndex="5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              justifyContent="center"
              alignItems="center"
              color={COLORS.MEDIUM_GRAY}
              fontSize="16px"
            >
              {t("COMMON:LOADING")}
            </Flex>
          )}

          {!isLoading && !isOption && (
            <Flex
              as={motion.div}
              position="absolute"
              top="12"
              left="0"
              backgroundColor={COLORS.WHITE}
              opacity="0.5"
              width="100%"
              height="280px"
              zIndex="5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              justifyContent="center"
              alignItems="center"
              color={COLORS.MEDIUM_GRAY}
              fontSize="16px"
            >
              <Text>{t("COMMON:NO_OPTIONS")}</Text>
            </Flex>
          )}
        </Box>
      </Box>
    );
  }
);

const Placeholder = ({ children, ...restProps }) => {
  return (
    <Text
      children={children}
      fontSize="sm"
      color="gray.500"
      marginTop="1"
      marginLeft="2.5"
      {...restProps}
    />
  );
};

const DropdownValueContainer = React.forwardRef(
  (
    {
      id,
      name,
      children,
      placeholder,
      onClick,
      isDisabled,
      isLoading,
      hasValue,
      ...restProps
    },
    ref
  ) => {
    const handleOnClick = useCallback(
      (event) => {
        if (!event.target.matches(`.${id}_RemoveOption`)) {
          onClick(event);
        }
      },
      [id, onClick]
    );

    return (
      <Flex
        id={`${id}_ValueContainer`}
        ref={ref}
        justifyContent="space-between"
        border={`1px solid ${COLORS.LIGHT_GRAY}`}
        borderRadius="4px"
        width="100%"
        minHeight="38px"
        onClick={handleOnClick}
        backgroundColor={isDisabled ? COLORS.BG_DISABLED : COLORS.WHITE}
        pointerEvents={isDisabled ? "none" : "auto"}
      >
        <Flex
          paddingY="1"
          paddingX="2"
          flexWrap="wrap"
          gap="4px"
          maxHeight="70px"
          width="100%"
        >
          {hasValue ? children : <Placeholder>{placeholder}</Placeholder>}
        </Flex>
        <Flex
          alignSelf="center"
          cursor="pointer"
          _hover={{ color: COLORS.PRIMARY }}
          paddingRight="2"
        >
          <LoadingIndicatorSpinner
            isLoading={isLoading}
            isDisabled={isDisabled}
            marginRight="1"
            marginTop="2px"
          />
          <ChevronDownIcon fill={COLORS.MEDIUM_GRAY} />
        </Flex>
      </Flex>
    );
  }
);

const MultiSelectDropdown = ({
  id = "multi-select-dropdown",
  name,
  options,
  values,
  placeholder,
  onChange,
  onValueChange,
  onBlur,
  onFetchAll,
  isFirstFetchAll,
  isLoading,
  isDisabled,
  onSearch,
  onScrollToBottom,
  optionMapper = { value: "id", label: "name" },
} = {}) => {
  const { t } = useTranslation();
  const [showMenuList, setShowMenuList] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [localSearchValue, setLocalSearchValue] = useState("");
  const containerRef = useRef();
  const valueContainerRef = useRef();

  const allOption = useMemo(
    () => ({
      value: "_all",
      label: t("COMMON:SELECT_ALL"),
    }),
    [t]
  );

  const handleShowMenuList = () => {
    // Check default is based on prev
    setShowMenuList((prev) => !prev);
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowMenuList(false);
      }
      if (!isFirstFetchAll && onSearch) {
        onSearch("");
      }
    },
    [isFirstFetchAll, onSearch]
  );

  const handleOnOptionClick = ({ isSelected, ...rest }) => {
    if (isSelected) {
      if (!isFirstFetchAll && selectAll && onFetchAll) {
        try {
          setShowMenuList(false);
          setSelectAll(false);
          onFetchAll()?.then((withValue) => {
            if (optionMapper) {
              const allOptions = withValue
                ?.map(optionMapper)
                .filter((opt) => opt.value !== rest.value);
              return onChange(allOptions);
            }
          });
          onChange(options);
        } catch (ignore) {}
      } else if (isFirstFetchAll && selectAll) {
        setSelectAll(false);
        setShowMenuList(false);
        onChange([
          ...values.filter(
            (option) => option.value !== rest.value && option.value !== "_all"
          ),
        ]);
      } else {
        onChange([
          ...values.filter(
            (option) => option.value !== rest.value && option.value !== "_all"
          ),
        ]);
      }
    } else {
      onChange([...values, rest]);
    }
    if (onValueChange) onValueChange();
  };

  const handleOnValueOptionRemove = ({ value, index }) => {
    if (index >= 0) {
      if (typeof value === "string" && value.includes("all")) {
        setSelectAll(false);
        return onChange([]);
      }
      onChange(values.filter((opt) => opt.value !== value));
    }
  };

  const handleOnSelectAll = async () => {
    setShowMenuList(false);
    if (selectAll) {
      onChange([]);
      setSelectAll(false);
    } else {
      setSelectAll(true);
      onChange([allOption, ...options]);
    }
  };

  const handleOnBackendSearch = (searchValue) => {
    onSearch && onSearch(searchValue?.toLowerCase() ?? "");
  };

  const handleOnLocalSearch = (searchValue) => {
    setLocalSearchValue(searchValue?.toLowerCase() ?? "");
  };

  const handleOnSearch = useCallback(
    (searchValue) => {
      if (isFirstFetchAll) {
        return handleOnLocalSearch(searchValue);
      }
      return handleOnBackendSearch(searchValue);
    },
    [isFirstFetchAll]
  );

  const handleOnScrollToBottom = useCallback(
    (offset, event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (
        onScrollToBottom &&
        scrollTop / (scrollHeight - clientHeight) >= 0.75 &&
        !isFirstFetchAll
      ) {
        onScrollToBottom(event);
      }
    },
    [onScrollToBottom, isFirstFetchAll]
  );

  const valueContainerVirtualHeight = (() => {
    let virtualHeight = 20;
    if (values && values?.length > 3) {
      virtualHeight = 60;
    } else if (values?.length) {
      virtualHeight = virtualHeight * values.length;
    }

    return virtualHeight;
  })();

  const filteredOptions = useMemo(() => {
    if (!localSearchValue) return options;
    return options.filter((opt) => opt?.label?.includes(localSearchValue));
  }, [localSearchValue, options]);

  // const isDefaultSelectAll = (() => {
  //   return values && Array.isArray(values) && typeof values[0].value === 'string' && values[0].value.includes('all')
  // })

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  });

  useEffect(() => {
    try {
      if (
        values &&
        Array.isArray(values) &&
        typeof values[0].value === "string" &&
        values[0].value.includes("all")
      ) {
        setSelectAll(true);
      }
    } catch (ignored) {}
  }, []);

  return (
    <Box
      id={`${id}_Container`}
      position="relative"
      textAlign="left"
      borderRadius="5px"
      onBlur={onBlur}
      ref={containerRef}
    >
      <DropdownValueContainer
        id={id}
        ref={valueContainerRef}
        onClick={handleShowMenuList}
        hasValue={values?.length}
        isDisabled={isDisabled}
        isLoading={isLoading}
        placeholder={placeholder}
      >
        {selectAll ? (
          <ValueOption
            id={id}
            onRemove={() =>
              handleOnValueOptionRemove({ index: 0, ...allOption })
            }
            label="Select All"
            value="_all"
          />
        ) : (
          <VirtualList
            className={values?.length > 3 ? styles.overflow : styles.default}
            height={valueContainerVirtualHeight}
            itemCount={values?.length ?? 0}
            itemSize={21}
            renderItem={({ index, style: { width, ...restStyle } }) => {
              return (
                <ValueOption
                  id={id}
                  key={index}
                  index={index}
                  style={restStyle}
                  onRemove={handleOnValueOptionRemove}
                  {...values[index]}
                />
              );
            }}
          />
        )}
      </DropdownValueContainer>
      {showMenuList && (
        <MenuList
          onClick={handleOnSelectAll}
          selectAll={selectAll}
          onSearch={handleOnSearch}
          values={values}
          isLoading={isLoading}
          isOption={options?.length}
        >
          <VirtualList
            width="100%"
            height={250}
            itemCount={filteredOptions?.length ?? 0}
            itemSize={30}
            onScroll={handleOnScrollToBottom}
            renderItem={({ index, style }) => {
              return (
                <DropdownOption
                  key={index}
                  index={index}
                  selectedOptions={values}
                  selectAll={selectAll}
                  onChange={handleOnOptionClick}
                  style={style}
                  {...filteredOptions[index]}
                />
              );
            }}
          />
        </MenuList>
      )}
    </Box>
  );
};

export default MultiSelectDropdown;
