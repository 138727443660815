import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addProductSubtituteSchema = (t) => {
  return () =>
    yup.object().shape({
      product_category_id: yup.number().nullable(),
      product_principal_id_from: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_FROM"),
          })
        )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_FROM"),
          })
        ),
      product_principal_id_to: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_TO"),
          })
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_SUBTITUTE:FIELD_STATUS"),
        })
      ),
    });
};
export const editProductSubtituteSchema = (t) => {
  return () =>
    yup.object().shape({
      product_category_id: yup.object().nullable().shape({
        value: yup.number().nullable(),
      }),
      product_principal_id_from: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_FROM"),
          })
        )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_FROM"),
          })
        ),
      product_principal_id_to: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_TO"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_TO"),
              })
            ),
        }),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_SUBTITUTE:FIELD_STATUS"),
        })
      ),
    });
};
