import { message } from "antd";
import i18next from "i18next";
import { sha256 } from "js-sha256";
import * as ActionType from "../actions/actionType";
import * as BaseApi from "../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../constant/apiConstant";
import { CRYPTO_RANDOM } from "../constant/propertiesConstant";
import { ROUTES } from "../constant/routeConstant";
import { authActions } from '../reducers/authReducer'
import { pageActions } from '../reducers/pageReducer'
import { setLocalStorage, STORE_KEY } from '../helpers/storageHelper';

export const errorReloadAuthorize = async (error, dispatch) => {
  if (error.response) {
    if (error.response.status === 502) {
      message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
    } else {
      error.response.data.nexsoft.payload && message.error({ content: error.response.data.nexsoft.payload.status.message });
      await authorize(CRYPTO_RANDOM, dispatch);
    }
  } else {
    message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
  }
};

export const authorize = async (crypto_random, dispatch) => {
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/session/login/authorize`;

  const data = { code_challenger: sha256(crypto_random) };

  try {
    dispatch(pageActions.authFetching())
    const response = await BaseApi.doPost(url, null, data);
    if (response) {
      dispatch(authActions.setCryptoRandom(crypto_random))
      dispatch(authActions.setCodePKCE(response.headers.authorization))
      // dispatch({ type: ActionType.AUTH_SUCCESS });
      dispatch(pageActions.authSuccess() )
    }
  } catch (error) {
    // dispatch({ type: ActionType.AUTH_ERROR });
    dispatch(pageActions.authError())
    if (error.response) {
      if (error.response.status === 502) {
        message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));

      } else {
        message.error(i18next.t("COMMON:ERROR_AUTH"));
      }
    } else {
      message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
    }
  }
};

export const verify = async (token, data, dispatch, crypto_random, history) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/session/login/verify`;

  try {
    dispatch(pageActions.authFetching() )
    const response = await BaseApi.doPost(url, token, data);
    if (response) {
      result.ok = true;
      const authentication_code = response.data.nexsoft.payload.data.content.authentication_code;
      await grantToken({ authorization_code: authentication_code, code_verifier: crypto_random }, dispatch, history);
    }
  } catch (error) {
    dispatch(pageActions.authError() )
    if (error.response) {
      // message.error(error.response.data.nexsoft.payload.status.message);
      if (error.response.status === 502) {
        message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
      }
      else {
        result.response = error.response.data;
        await authorize(CRYPTO_RANDOM, dispatch);
      }
    } else {
      message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
    }
  }

  return result;
};

export const grantToken = async (data, dispatch, history) => {
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/session/login/token`;

  try {
    const response = await BaseApi.doPost(url, null, data);
    if (response) {
      const token = response.headers.authorization;
      const refresh_token = response.data.nexsoft.payload.data.content.refresh_token;

      await userVerify(token, refresh_token, dispatch, history);
    }
  } catch (error) {
    dispatch(pageActions.authError() )
    errorReloadAuthorize(error, dispatch);
  }
};

export const userVerify = async (token, refresh_token, dispatch, history) => {
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/users/verify`;

  try {
    const response = await BaseApi.doGet(url, token);
    if (response) {
      dispatch(authActions.trueAuthenticated())
      // dispatch({ type: ActionType.SET_TOKEN, payload: token });
      dispatch(authActions.setToken(token) )
      // dispatch({ type: ActionType.SET_REFRESH_TOKEN, payload: refresh_token });
      dispatch(authActions.setRefreshToken(refresh_token) )
      setSession(response.data.nexsoft.payload.data.content, dispatch);

      setTimeout(async () => {

        dispatch(pageActions.authSuccess())
        await history.push(ROUTES.HOME);
      }, 512);
    }
  } catch (error) {
    dispatch(pageActions.authError())
    // errorReloadAuthorize(error, dispatch);
  }
};

const setSession = (obj, dispatch) => {
  dispatch(authActions.setUserId(obj.user_id) )
  dispatch(authActions.setFirstName(obj.first_name) )
  dispatch(authActions.setLastName(obj.last_name) )
  dispatch(authActions.setCompanyName(obj.company_name) )
  dispatch(authActions.setCompanyProfileAccess(obj.company_profile_access) )
  dispatch(authActions.setPrincipalId(obj.principal_id) )
  dispatch(authActions.setPrincipalCode(obj.principal_code))
  dispatch(authActions.setScope(obj.scope))
  dispatch(authActions.setRole(obj.role))
  dispatch(authActions.setPermission(obj.permission))
  dispatch(authActions.setPrincipalAlias(obj.principal_alias))
  dispatch(authActions.setUserCategory(obj.user_category))
  setLocalStorage(STORE_KEY.Language, obj.locale);
  i18next.changeLanguage(obj.locale)
  dispatch(authActions.setLanguage(obj.locale))
  dispatch(authActions.setMenu(obj.menu))
  
};

export const forgetPassword = async (data, dispatch) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/email/forget`;

  const options = { url, method: "POST", headers: { "X-NEXTOKEN": "Farmindo2021" }, data };

  try {
    // dispatch({ type: ActionType.AUTH_FETCHING });
    dispatch(pageActions.authFetching())
    const response = await BaseApi.fetchWithCustomOption(options);
    if (response) {
      result.ok = true
      dispatch(pageActions.authSuccess())
    }
  } catch (error) {
    // dispatch({ type: ActionType.AUTH_ERROR });
    dispatch(pageActions.authError())
    if (error.response) {
      if (error.response.status === 502) {
        message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
      } else if (error.response.status === 500) {
        error.response.data.nexsoft.payload && message.error({ content: error.response.data.nexsoft.payload.status.message });
      } else {
        result.response = error.response.data;
      }
    } else {
      message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
    }
  }

  return result;
};

export const resetPassword = async (data, dispatch) => {
  let result = { ok: false, response: null };
  const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/email/password`;

  const options = { url, method: "POST", headers: { "X-NEXTOKEN": "Farmindo2021" }, data };

  try {
    dispatch(pageActions.authFetching())
    const response = await BaseApi.fetchWithCustomOption(options);
    if (response) {
      result.ok = true;

      // dispatch({ type: ActionType.AUTH_SUCCESS });
      dispatch(pageActions.authSuccess())
    }
  } catch (error) {
    // dispatch({ type: ActionType.AUTH_ERROR });
    dispatch(pageActions.authError())
    if (error.response) {
      if (error.response.status === 502) {
        message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
      } else {
        error.response.data.nexsoft.payload && message.error({ content: error.response.data.nexsoft.payload.status.message });
      }
    } else {
      message.error(i18next.t("COMMON:ERROR_SERVER_OFF"));
    }
  }

  return result;
};

