import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Icon,Collapse } from "@chakra-ui/react";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { firstMenu, firstParent } from "../../constant/pageConstant";
import { exampleMenu, LANGUANGE, objIcon ,sellerDanone,sellerMenuDanone,sellerMenuExcludeDanone, sellerMenuNestle, userCategoryType } from "../../constant/propertiesConstant";
import { ROUTES } from "../../constant/routeConstant";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useSessionStorage } from "../../hooks/useStorage";
import SidebarButtonItem from "../button/SidebarButtonItem";
import SidebarButtonParent from "../button/SidebarButtonParent";
import SidebarButtonService from "../button/SidebarButtonService";
import { HomeIcon } from "../icons";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useCheckUserCategory from "../../hooks/useCheckUserCategory";
import { pageActions } from "../../reducers/pageReducer";
const Sidebar = ({ collapsed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { company_name } = useSelector((state)=>state.auth);
  const { menu } = useSelector((state) => state.auth);
  const { permission } = useSelector((state) => state.auth);
  const { language } = useSelector((state)=>state.auth);
  // pengecekan apakah ini danone
  const companyNameToLower = company_name.toLowerCase();
  const isDanone = companyNameToLower.includes("danone");
  const isNestle = companyNameToLower.includes("nestle");
  // const roleToLowerCase = role.toLowerCase();
  // const isPrincipal = roleToLowerCase.includes("principal");

  const { isAccepted } = useCheckUserCategory([userCategoryType.PRINCIPAL]);

  const checkMenu = () => {
    if(isDanone && isAccepted) return sellerMenuDanone; // jika danone dan dia principal
    if(isNestle && isAccepted) return sellerMenuNestle; // jika nestle dan principal 
    if(!isDanone && !isNestle && isAccepted ) return sellerMenuExcludeDanone; //  jika bukan danone dan nestle tapi principal
    return menu;
  }
// const checkAccount = () => {
//   const allowedMenu = [];

//   const checkMenuPermissions = (menu) => {
    
//     const stringPermission = JSON.stringify(permission);// jika permission mengandung apa yang ada di menu code maka return true
//     return stringPermission.includes(menu?.menu_code)
    
//   };

//   checkMenu()?.forEach((parentItem) => {
//     if (checkMenuPermissions(parentItem)) {
//       const allowedParentMenu = { ...parentItem };
//       if (parentItem.service_menu) {
//         allowedParentMenu.service_menu = parentItem.service_menu.filter((subMenu) => {
//           return checkMenuPermissions(subMenu);
//         });
//       }
//       allowedMenu.push(allowedParentMenu);
//     }
//   });

//   return allowedMenu.length > 0 ? allowedMenu : null;
// };

const checkAccount = () => {
  const allowedMenu = [];

  // const checkMenuItemPermissions = (menuItem) => {
  //   const stringPermission = JSON.stringify(permission);
  //   return stringPermission.includes(menuItem?.menu_code);
  // };
  const checkMenuItemPermissions = (menuItem) => {
    const permissionKeys = Object.keys(permission);
    for (const key of permissionKeys) {
      const keyParts = key.split(".");
      if (keyParts.length > 1) {
        const normalizedKey = keyParts.slice(1).join(".");
        if (normalizedKey === menuItem?.menu_code) {
          return true;
        }
      }
    }
    return false;
  };

  const checkSubMenuPermissions = (subMenu) => {
    let allowedSubMenu = { ...subMenu };
    if (subMenu.menu_item) {
      const allowedMenuItems = subMenu.menu_item.map((menuItem) => {
        if (menuItem.menu_code.includes('home')) {
          return { ...menuItem };
        }
        if (checkMenuItemPermissions(menuItem)) {
          return menuItem;
        }
        return null;
      }).filter(Boolean);

      if (allowedMenuItems.length > 0) {
        allowedSubMenu.menu_item = allowedMenuItems;
        return allowedSubMenu;
      } else {
        return null;
      }
    }
    if (checkMenuItemPermissions(subMenu)) {
      return allowedSubMenu;
    }
    return null;
  };

  const checkMenuPermissions = (menu) => {
    if (menu.menu_code.includes('home')) {
      return { ...menu };
    }
    
    if (menu.service_menu === null) {
      if (checkMenuItemPermissions(menu)) {
        return { ...menu };
      }
      return null;
    }

    const allowedMenuObj = { ...menu };
    const allowedSubMenus = menu.service_menu.map((subMenu) => {
      return checkSubMenuPermissions(subMenu);
    }).filter(Boolean);

    if (allowedSubMenus.length > 0) {
      allowedMenuObj.service_menu = allowedSubMenus;
      return allowedMenuObj;
    }

    return null;
  };

  checkMenu()?.forEach((parentItem) => {
    const allowedParentMenu = checkMenuPermissions(parentItem);
    if (allowedParentMenu) {
      allowedMenu.push(allowedParentMenu);
    }
  });


  return allowedMenu.length > 0 ? allowedMenu : null;
};
  const [selectedParent, setSelectedParent] = useSessionStorage("selectedParent", firstParent);
  const [selectedMenu, setSelectedMenu] = useSessionStorage("selectedMenu", firstMenu);
  const [selectedUrl, setSelectedUrl] = useSessionStorage("selectedUrl", ROUTES.HOME);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const getChild = (menu, fromClicked) => {
    if (menu) {
      let child = menu.filter((item) => item.menu_code === fromClicked);
      return child;
    }
  };
  const generateURLParent = (parent) => {
    let url = "";
    let menu_code = "";

    if (parent) {
      if (parent.url) {
        url = parent.url;
        menu_code = parent.menu_code;
      } else {
        if (parent.service_menu && parent.service_menu[0].url) {
          url = parent.service_menu[0].url;
          menu_code = parent.service_menu[0].menu_code;
        } else {
          url = parent.service_menu && parent.service_menu[0].menu_item && parent.service_menu[0].menu_item[0].url;
          menu_code = parent.service_menu && parent.service_menu[0].menu_item && parent.service_menu[0].menu_item[0].menu_code;
        }
      }
    }

    return { url, menu_code };
  };

  const setProperty = (parent, menu, url) => {
    setSelectedParent(parent);
    setSelectedMenu(menu);
    if(url === '/home'){
      setIsCollapsed(!isCollapsed)
    }
    setSelectedUrl(url);
    dispatch(pageActions.resetFilters());
  };

  useEffectOnce(() => {
    if (selectedUrl) {
      history.push('/'+selectedParent);
    }
    else {
      setProperty(firstParent, firstMenu, ROUTES.HOME);
      history.push(ROUTES.HOME);
    }
  });
  return (
    <Box height="full" transition=".2s" display="flex">
      <Box height="full" backgroundColor="#003C7C">
        <SidebarButtonParent to={ROUTES.HOME} icon={HomeIcon} label={t("COMMON:HOME")} isActive={selectedParent === "home"} onClick={() => setProperty("home", "home", ROUTES.HOME)} />
        {checkAccount()?.map(
          (item) =>
            item.menu_code !== "home" && (
              <SidebarButtonParent
                key={item.id}
                to={generateURLParent(item).url}
                icon={objIcon[item.menu_code]}
                label={language === LANGUANGE.EN ? item?.en_name : item?.name}
                isActive={selectedParent === item.menu_code}
                onClick={() => setProperty(item.menu_code, generateURLParent(item).menu_code, generateURLParent(item).url)}
              />
            )
        )}
      </Box>
      <Collapse in={isCollapsed}>
      <Box width="240px" height="full" backgroundColor="#00366F" alignItems="center" display="flex" flexDirection="column">
        {getChild(checkAccount(), selectedParent) &&
          getChild(checkAccount(), selectedParent)?.map((obj) => (
            <Fragment key={obj.id}>
              <Box fontWeight={500} fontSize={16} height="40px" width="90%" color="#668AB0" display="flex" alignItems="center" children={language === LANGUANGE.EN ? obj?.en_name : obj?.name} />
              <Box width="90%" children={<Divider />} />
              <Box marginY={0.5} color="#668AB0" width="90%">
                <Accordion allowToggle>
                  {obj.menu_code !== "home" && (
                    <SidebarButtonService
                      to={ROUTES.HOME}
                      icon={HomeIcon}
                      label={t("COMMON:HOME")}
                      isActive={selectedMenu === "home"}
                      onClick={() => setProperty("home", "home", ROUTES.HOME)}
                    />
                  )}
                  {obj.url && (
                    <SidebarButtonService
                      to={obj.url}
                      icon={objIcon[obj.menu_code]}
                      label={language === LANGUANGE.EN ? obj?.en_name : obj?.name}
                      isActive={selectedMenu === obj.menu_code}
                      onClick={() => setProperty(selectedParent, obj.menu_code, obj.url)}
                    />
                  )}

                  {obj.service_menu &&
                    obj.service_menu?.map((service) => (
                      <Fragment key={service.id}>
                        {service.url ? (
                          <SidebarButtonService
                            to={service.url}
                            icon={objIcon[service.menu_code]}
                            label={language === LANGUANGE.EN ? service?.en_name : service?.name}
                            isActive={selectedMenu === service.menu_code}
                            onClick={() => setProperty(selectedParent, service.menu_code, service.url)}
                          />
                        ) : (
                          <AccordionItem border="none" position="relative">
                            <AccordionButton
                              height="40px"
                              width="full"
                              borderRadius={4}
                              padding={0}
                              _focus={{ boxShadow: "none" }}
                              _hover={{ backgroundColor: "#194b7d", color: "white" }}
                            >
                              <Box width="8" children={<Icon as={objIcon[service.menu_code]} width={4} height={6} />} />
                              <Box fontWeight={500} fontSize={14} textAlign="left" children={language === LANGUANGE.EN ? service?.en_name : service?.name} />
                              <AccordionIcon position="absolute" right={1} />
                            </AccordionButton>

                            <AccordionPanel padding={0}>
                              {service.menu_item &&
                                service.menu_item?.map((item) => (
                                  <SidebarButtonItem
                                    key={item.id}
                                    to={item.url}
                                    icon={objIcon[item.menu_code]}
                                    label={language === LANGUANGE.EN ? item?.en_name : item?.name}
                                    isActive={selectedMenu === item.menu_code}
                                    onClick={() => setProperty(selectedParent, item.menu_code, item.url)}
                                  />
                                ))}
                            </AccordionPanel>
                          </AccordionItem>
                        )}
                      </Fragment>
                    ))}
                </Accordion>
              </Box>
            </Fragment>
          ))}
      </Box>
      </Collapse>
    </Box>
  );
};

export default Sidebar;
