import { Box, Text} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Input, Spin } from "antd";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import {
  PRIMARY_COLOR,
} from "../../../../constant/propertiesConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import { useFormik } from "formik";
import { checkUserSchema } from "../userSchema";

import { useDispatch, useSelector } from "react-redux";
import {
  checkUserAcc,
  userAction,
} from "../../../../services/main/userReducer";
import { ROUTES } from "../../../../constant/routeConstant";
import { useState } from "react";
import ModalCustom from "../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../components/icons";
const CheckUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [valid, setValid] = useState(false);
  const [isHitButton, setIsHitButton] = useState(false);
  const { check_user_loading } = useSelector((state) => state.user);
  //modal
  const [modalOpen, setModalOpen] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");

  const onChangeEmail = (event) => {
    setFieldValue("email", event.target.value.toLowerCase());
  };
  const goToNextPage = () => {
    dispatch(userAction.setIsChecked(true));
  };
  const clickOkOnModalSuccess = () => {
    // klik tombol ok di dalem modal sukses
    setModalOpen(false);
    dispatch(userAction.setIsChecked(true));
  };
  const clickOkOnModalFailed = () => {
    setModalOpen(false);
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      nik: "",
      email: "",
    },
    validationSchema: checkUserSchema(t),
    onSubmit: (values) => {
      const body = {
        person_profile: {
          nik: values?.nik,
        },
        email: values?.email,
        all: "",
      };
      setIsHitButton(true);
      dispatch(checkUserAcc(body)).then((res) => {
        if (res.payload.ok) {
          setModalOpen(true);
          setResp(t("USER:DATA_VALID"))
          dispatch(userAction.setUserCheck(body));
          setValid(true);
          // jika dari response tidak mendapatkan user auth , maka
          // di step login account tidak auto fill bagian username
          if (res.payload.response.user_auth.username !== "") {
            dispatch(
              userAction.setUsernameAuth(
                res.payload.response.user_auth.username
              )
            );
          }
        } else {
          setValid(false);
          const errMessage =
            res.payload.response.data.nexsoft.payload.status.message;
          if (errMessage.toLowerCase().includes("nik")) {
            setFieldError("nik", errMessage);
          }
          if (errMessage.toLowerCase().includes("surel")) {
            errors.email = errMessage;
            setFieldError("email", errMessage);
          }
        }
      });
    },
  });

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box padding={4}>
        <HeadTitle
          title={t("USER:ADD_USER")}
          subtitle={
            <>
              <span style={{ color: PRIMARY_COLOR }}>
                {t("USER:SUBTITLE_1")}
              </span>{" "}
              -{" "}
              <span style={{ color: PRIMARY_COLOR }}>
                {t("USER:SUBTITLE_2")}
              </span>{" "}
              - {t("USER:SUBTITLE_3")}
            </>
          }
        />
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          marginTop={6}
          borderRadius="10px"
        >
          {/* Bold Text  */}
          <Box padding={1} marginBottom={4}>
            <Text>
              <b>{t("USER:NOTICE_CHECK")}</b>
            </Text>
          </Box>

          {/* Identification Number / Nik */}
          <Box padding={1} display="flex" marginBottom={4} marginTop={2}>
            <Box width="10rem" marginTop={2}>
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_IDENTIFICATION_NUMBER")}
              </Text>
            </Box>
            <Box>
              <Input
                type="text"
                style={inputTheme}
                name="nik"
                onChange={handleChange}
                onBlur={handleBlur}
                size="large"
                value={values?.nik}
              />
              {errors.nik && touched.nik && (
                <ValidationError text={errors.nik} />
              )}
            </Box>
          </Box>
          {/* Email   */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}>
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_EMAIL")}
              </Text>
            </Box>
            <Box>
              <Input
                type="text"
                style={inputTheme}
                name="email"
                // onChange={handleChange}
                onChange={(event) => onChangeEmail(event)}
                onBlur={handleBlur}
                size="large"
                value={values?.email}
              />
              {errors.email && touched.email && (
                <ValidationError text={errors.email} />
              )}
            </Box>
          </Box>

          {/* all  */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}></Box>
            <Box>
              {errors.all && touched.all && (
                <ValidationError text={errors.all} />
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES.USER.LIST}
            type="button"
            onClick={() => dispatch(userAction.resetReduxUser())}
            text={t("COMMON:BUTTON_CANCEL")}
          />
          {valid && isValid  ? (
            <ButtonPrimary
              text={check_user_loading ? <Spin /> : t("USER:BUTTON_NEXT")}
              width="9.375rem"
              onClick={goToNextPage}
              isDisabled={!isValid}
            />
          ) : (
            <ButtonPrimary
              text={check_user_loading ? <Spin /> : t("USER:BUTTON_CHECK")}
              width="9.375rem"
              type="submit"
              
            />
          )}
        </Box>
      </Box>
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <DeleteSuccess /> : <SavedSuccess />}
      />
    </form>
  );
};

export default CheckUser;
