import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import FileSaver from "file-saver";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import {
  API_URL,
  API_VERSION,
  API_PREFIX,
} from "../../../constant/apiConstant";

const INITIAL_STATE = {
  list_unmapped_product: [],
  printed_unmapped: [],
  selectedRowKeys: [],
  table_loading: false
};

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListUnmappedProduct = createAsyncThunk(
  "getListUnmappedProduct",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/unmapped/product`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doGet(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
       } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);

export const getInitListUnmappedProduct = createAsyncThunk(
  "unmapped/product/initiateGetList",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/unmapped/product/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const downloadUnmappedProduct = createAsyncThunk(
  "unmapped/product/download",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/unmapped/product/download`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const fetchXlsx = await baseApi.doPostResponseBlob(url, token, object);
      
      // const response = await baseApi.doPost(url, token, object);
      if (fetchXlsx) {
        result.ok = true;
        result.response = fetchXlsx;
      }

    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const unmappedProduct = createSlice({
  name: "createMonitoringProduct",
  initialState: INITIAL_STATE,
  reducers: {
    setSingleCheck(state, action) {
      // if (!payload || typeof payload !== 'number')
      let tempSelected = [...state.selectedRowKeys];
      const isExist = state.selectedRowKeys?.includes(action.payload);
      if (isExist) {
        tempSelected = tempSelected.filter((id) => id !== action.payload);
        state.selectedRowKeys = tempSelected;
        return;
      }
      state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
    },
    setAllCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      let tempsId = action.payload;
      let isExist = false;

      for (const data of tempsId) {
        isExist = false;
        for (const existingId of state.selectedRowKeys) {
          if (data === existingId) {
            isExist = true;
            break;
          }
        }
        if (!isExist) break;
      }

      if (isExist) {
        for (const data of tempsId) {
          tempSelected = tempSelected.filter((id) => id !== data);
          state.selectedRowKeys = tempSelected;
        }
      } else {
        state.selectedRowKeys = Array.from(
          new Set([...state.selectedRowKeys, ...tempsId])
        );
      }
    },
    resetReduxUnmapped() {
      return INITIAL_STATE;
    },
    setTableLoadingFalse(state){
      state.table_loading = false
    },
    setTableLoadingTrue(state){
      state.table_loading = true
    },
    resetCBX(state) {
      state.selectedRowKeys = []
    }
  },
  extraReducers: {
    [getListUnmappedProduct.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
      state.list_unmapped_product = []
    },
    [getListUnmappedProduct.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_unmapped_product = action.payload.response;
    },
    [getListUnmappedProduct.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [downloadUnmappedProduct.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [downloadUnmappedProduct.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
     
    },
    [downloadUnmappedProduct.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
  },
});

export const unmappedProductReducerAction = unmappedProduct.actions;

export default unmappedProduct.reducer;
