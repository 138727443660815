import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Select as SelectChakra,
  CloseButton,
} from "@chakra-ui/react";
import { Pagination, Table, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import { AddIcon, SearchIcon } from "../../../components/icons";
import {
  GRAY_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../../constant/propertiesConstant";
import "./User.css";
import { ROUTES } from "../../../constant/routeConstant";
import { EditIcon, DetailIcon } from "../../../components/icons";
import {
  getListUser,
  getInitiateListUser,
} from "../../../services/main/userReducer";
import "../../../css/GlobalAntd.css";
import classes from "./User.module.css";
import { selectOptFilterUserTheme } from "../../../constant/themeConstant";
import {
  selectDropDownFilterUser,
  selectPaginationOption,
} from "../../../constant/selectOptConstant";
import { CapitalizeFirstLetter } from "../../../function/CapitalizeFirstLetter";
import { useCallback } from "react";
import StringUtil from "../../../utils/stringUtil";
import CustomPopOver from "../../../components/popOver/CustomPopOver";
import { ID_USER } from "../../../constant/idConstant";
import moment from "moment";
import DateUtil, { FORMAT } from "../../../utils/dateUtil";
import { subHours } from "date-fns";
const ListUser = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { token } = useSelector((state) => state.auth);
  const data = useSelector((state) => state.user.list);
  const { table_loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  // for filter
  const [order, setOrder] = useState("");
  const [inputSearch, setInputSearch] = useState("");
  const [form, setForm] = useState("");
  const [isDisabled, setisDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setisDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setisDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };
  const timerObj = () => {
    setTimeout(function () {
      setPopOverOpen(false);
    }, 5000);
  };
  const submitOk = () => {
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
      timerObj();
    } else {
      setInputSearch(form);
      setPage(1);
      setPopOverOpen(false);
    }
  };
  const resetFilter = () => {
    setForm("");
    setInputSearch("");
    setisDisabled(true);
    setSelectDropDownFilter("");
    setPopOverOpen(false);
  };
  const handleCloseButtonSearch = () => {
    setForm("");
    setButtonSearchOrClose("search");
    setisDisabled(true);
  };
  const handleSelectPagination = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };
  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");
  const handleChangeSelect = (name, value, option) => {
    setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
  };

  const fetchInitiate = () =>{
    let filter = `${selectDropDownFilter.value} like ${inputSearch}`;
    const params =
      inputSearch === ""
        ? { page, limit, order }
        : { page, limit, filter, order };
    dispatch(getInitiateListUser(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  } 
  const fetchUser = () =>{
    let filter = `${selectDropDownFilter.value} like ${inputSearch}`;
    const params =
      inputSearch === ""
        ? { page, limit, order }
        : { page, limit, filter, order };
    dispatch(getListUser(params));
  }
  useEffect(() => {
    fetchInitiate()
    fetchUser()
  }, [page, limit,total, token, inputSearch, order,dispatch]);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const datasource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      id: item.id,
      username: item.username,
      firstname: item.local_first_name,
      lastname: item.local_last_name,
      email: item.local_email,
      phone: item.local_phone,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      action: (
        <div key={index}>
          <ButtonCustom
            link={{ pathname: ROUTES.USER.EDIT, state: item }}
            text={<EditIcon />}
            backgroundcolor="transparent"
            id={ID_USER.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{ pathname: ROUTES.USER.DETAIL, state: item }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
            id={ID_USER.LIST.BTN_VIEW + "_" + item.id}
          />
        </div>
      ),
    }));
  const columns = [
    {
      title: (
        <Box
          display="flex"
          alignItems="center"
          cursor="pointer"
          marginStart={1}
        >
          <Text>{t("USER:FIELD_ID")}</Text>
        </Box>
      ),
      dataIndex: "id",
      key: "id",
      className: classes.columnRole,
      width: "58px",
      align: "left",
      marginLeft: "2px",
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.id - b.id,
      fontSize: "12px",
      render: (id) => (
        <Box>
          <Text marginStart={1}>{id}</Text>
        </Box>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER:FIELD_USERNAME")}
        </Box>
      ),
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ["descend", "ascend"],
      fontSize: "12px",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER:FIELD_FIRST_NAME")}
        </Box>
      ),
      dataIndex: "firstname",
      key: "firstname",
      width: 120,
      sorter: (a, b) => a.firstname.localeCompare(b.firstname),
      sortDirections: ["descend", "ascend"],
      fontSize: "12px",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER:FIELD_LAST_NAME")}
        </Box>
      ),
      dataIndex: "lastname",
      key: "lastname",
      width: 200,
      sorter: (a, b) => a.lastname.localeCompare(b.lastname),
      sortDirections: ["descend", "ascend"],
      fontSize: "12px",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER:FIELD_EMAIL")}
        </Box>
      ),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
      fontSize: "12px",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("USER:FIELD_PHONE")}
        </Box>
      ),
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortDirections: ["descend", "ascend"],
      fontSize: "12px",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at-b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer" marginLeft={4}>
          {t("COMMON:ACTION")}
        </Box>
      ),
      dataIndex: "action",
      key: "action",
      fontSize: "12px",
      width: 105,
    },
  ];
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("USER:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("USER:SUBTITLE_1")}</span> -{" "}
            {t("USER:TITLE_LIST")}
          </>
        }
        button={
          <ButtonPrimary
            link={ROUTES.USER.ADD}
            icon={<Icon as={AddIcon} />}
            id={ID_USER.LIST.BTN_ADD}
            text={t("USER:BUTTON_ADD_NEW_USER")}
          />
        }
      />

      <Box marginTop={6}>
        <Box marginLeft={"18.5rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            placeholder={t("COMMON:SEARCH_BY")}
            value={selectDropDownFilter.value}
          >
            {selectDropDownFilterUser?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="270px"
            height="40px"
            backgroundColor={WHITE_COLOR}
            borderRadius={6}
          >
            <Input
              type="text"
              id={ID_USER.LIST.SEARCH}
              placeholder={
                selectDropDownFilter.value
                  ? `${t("COMMON:SEARCH_BY")} ${CapitalizeFirstLetter(
                      t(selectDropDownFilter?.children)
                    )}`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleChange(e)}
              pointerEvents={
                selectDropDownFilter.value === undefined ? "none" : "auto"
              }
              value={form}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonCustom
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              id={ID_USER.LIST.BTN_APPLY}
              onClick={submitOk}
              marginRight={2}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              id={ID_USER.LIST.RESET_FILTER}
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
        
          dataSource={datasource}
          columns={columns}
          rowKey="uid"
          pagination={false}
          onChange={handleTableChange}
          size="small"
          loading={table_loading}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <SelectChakra
                  border="1px solid"
                  borderRadius="6px"
                  borderColor="#CCCCCC"
                  id={ID_USER.LIST.ROW_PER_PAGE}
                  size="sm"
                  width="70px"
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={handleSelectPagination}
                >
                  {selectPaginationOption?.map((value, index) => {
                    return (
                      <option key={index.toString()} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </SelectChakra>
              </Box>
              <Box>
                <Pagination
                  id={ID_USER.LIST.PAGINATION}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListUser;
