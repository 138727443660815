import React from "react";
import "./App.css";
import Login from "./pages/login/Login";
// import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import { DEFAULT_LANGUAGE } from "./constant/propertiesConstant";
// import { ID_LANGUAGE } from "./constant/propertiesConstant";
import { ROUTES, ROUTES_USER_BUYER, ROUTES_USER_SELLER } from "./constant/routeConstant";

// sysuser
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import AddDataGroup from "./pages/main/DataGroup/addDataGroup/AddDataGroup";
import DetailDataGroup from "./pages/main/DataGroup/detailDataGroup/DetailDataGroup";
import EditDataGroup from "./pages/main/DataGroup/editDataGroup/EditDataGroup";
import ListDataGroup from "./pages/main/DataGroup/ListDataGroup";
import Home from "./pages/main/Home/Home";
import ListSftpAs2Log from "./pages/main/Monitoring/SFTPAS2/ListSftpAs2Log";
import AddRole from "./pages/main/Role/addRole/AddRole";
import DetailRole from "./pages/main/Role/detailRole/DetailRole";
import EditRole from "./pages/main/Role/editRole/EditRole";
import ListRole from "./pages/main/Role/ListRole";
import AddUser from "./pages/main/User/addUser/AddUser";
import DetailUser from "./pages/main/User/detailUser/DetailUser";
import EditUser from "./pages/main/User/editUser/EditUser";
import ListUser from "./pages/main/User/ListUser";
import DetailUserActivity from "./pages/main/UserActivity/DetailUserActivity";
import ListUserActivity from "./pages/main/UserActivity/ListUserActivity";
import ChangePassword from "./pages/navbarDropdown/changePassword/ChangePassword";
import ChangeProfile from "./pages/navbarDropdown/changeProfile/ChangeProfile";
import RequestActivationCode from "./pages/navbarDropdown/requestActivationCode/RequestActivationCode";
import NotFound from "./pages/notfound/NotFound";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import { PrivateRoute } from "./privateRoute";
//// user seller
import Dashboard from "./pages/Dashboard/Dashboard";
import AddDistributor from "./pages/main/Master/Distributor/addDistributor/AddDistributor";
import DetailDistributor from "./pages/main/Master/Distributor/detailDistributor/DetailDistributor";
import ListDistributor from "./pages/main/Master/Distributor/listDistributor/ListDistributor";
import AddBKLMapping from "./pages/main/Master/Mapping/bklMapping/AddBKLMapping";
import DetailBKLMapping from "./pages/main/Master/Mapping/bklMapping/DetailBKLMapping";
import EditBKLMapping from "./pages/main/Master/Mapping/bklMapping/EditBKLMapping";
import ListBKLMapping from "./pages/main/Master/Mapping/bklMapping/ListBKLMapping";
import AddProductBKLMapping from "./pages/main/Master/Mapping/productBklMapping/AddProductBKLMapping";
import DetailProductBKLMapping from "./pages/main/Master/Mapping/productBklMapping/DetailProductBKLMapping";
import EditProductBKLMapping from "./pages/main/Master/Mapping/productBklMapping/EditProductBKLMapping";
import ListProductBKLMapping from "./pages/main/Master/Mapping/productBklMapping/ListProductBKLMapping";
import AddProductCategoryMapping from "./pages/main/Master/Mapping/productCategoryMapping/AddProductCategoryMapping";
import DetailProductCategoryMapping from "./pages/main/Master/Mapping/productCategoryMapping/DetailProductCategoryMapping";
import EditProductCategoryMapping from "./pages/main/Master/Mapping/productCategoryMapping/EditProductCategoryMapping";
import ListProductCategoryMapping from "./pages/main/Master/Mapping/productCategoryMapping/ListProductCategoryMapping";
import AddProductMapping from "./pages/main/Master/Mapping/productMapping/AddProductMapping";
import DetailProductMapping from "./pages/main/Master/Mapping/productMapping/DetailProductMapping";
import EditProductMapping from "./pages/main/Master/Mapping/productMapping/EditProductMapping";
import ListProductMapping from "./pages/main/Master/Mapping/productMapping/ListProductMapping";
import AddProductMappingHO from "./pages/main/Master/Mapping/productMappingHo/AddProductMappingHO";
import DetailProductMappingHO from "./pages/main/Master/Mapping/productMappingHo/DetailProductMappingHO";
import EditProductMappingHO from "./pages/main/Master/Mapping/productMappingHo/EditProductMappingHO";
import ListProductMappingHO from "./pages/main/Master/Mapping/productMappingHo/ListProductMappingHO";
import AddProductSubtitute from "./pages/main/Master/Mapping/productSubtitute/AddProductSubtitute";
import DetailProductSubtitute from "./pages/main/Master/Mapping/productSubtitute/DetailProductSubtitute";
import EditProductSubtitute from "./pages/main/Master/Mapping/productSubtitute/EditProductSubtitute";
import ListProductSubtitute from "./pages/main/Master/Mapping/productSubtitute/ListProductSubtitute";
import AddProductCategory from "./pages/main/Master/Product/productCategory/AddProductCategory";
import DetailProductCategory from "./pages/main/Master/Product/productCategory/DetailProductCategory";
import EditProductCategory from "./pages/main/Master/Product/productCategory/EditProductCategory";
import ListProductCategory from "./pages/main/Master/Product/productCategory/ListProductCategory";
import AddProductCustomer from "./pages/main/Master/Product/productCustomer/AddProductCustomer";
import DetailProductCustomer from "./pages/main/Master/Product/productCustomer/DetailProductCustomer";
import EditProductCustomer from "./pages/main/Master/Product/productCustomer/EditProductCustomer";
import ListProductCustomer from "./pages/main/Master/Product/productCustomer/ListProductCustomer";
import AddProductPrincipal from "./pages/main/Master/Product/productPrincipal/AddProductPrincipal";
import DetailProductPrincipal from "./pages/main/Master/Product/productPrincipal/DetailProductPrincipal";
import EditProductPrincipal from "./pages/main/Master/Product/productPrincipal/EditProductPrincipal";
import ListProductPrincipal from "./pages/main/Master/Product/productPrincipal/ListProductPrincipal.jsx";
import AddWarehouse from "./pages/main/Master/Warehouse/Add/AddWarehouse";
import DetailWarehouse from "./pages/main/Master/Warehouse/Detail/DetailWarehouse";
import EditWarehouse from "./pages/main/Master/Warehouse/Edit/EditWarehouse";
import ListWarehouse from "./pages/main/Master/Warehouse/List/ListWarehouse";
import ListPo from "./pages/main/Report/Report/PO/ListPo";
import ListPoDetail from "./pages/main/Report/Report/PODetail/ListPoDetail";
import ImportSO from "./pages/main/Transaction/SO/ImportSO";
// user buyer

import DashboardBuyerCS from "./pages/Dashboard/DashboardBuyerCs";
import AddStoreSuppConn from "./pages/main/Master/Connection/AddStoreSuppConn";
import DetailStoreSuppConn from "./pages/main/Master/Connection/DetailStoreSuppConn";
import EditStoreSuppConn from "./pages/main/Master/Connection/EditStoreSuppConn";
import ListStoreSuppConn from "./pages/main/Master/Connection/ListStoreSuppConn";
import AddStore from "./pages/main/Master/Store/AddStore";
import DetailStore from "./pages/main/Master/Store/DetailStore";
import EditStore from "./pages/main/Master/Store/EditStore";
import ListStore from "./pages/main/Master/Store/ListStore";
import DetailProcessPO from "./pages/main/Process/processPO/Detail/DetailProcessPO";
import ListProcessPO from "./pages/main/Process/processPO/ListProcessPO";
import ListReprocessPO from "./pages/main/Process/reprocessPO/ListReprocessPO";
import ListPoVsSo from "./pages/main/Report/Report/POVSSO/ListPoVsSo";
// connection
import AddNotification from "./pages/main/Master/Notification/AddNotification";
import DetailNotification from "./pages/main/Master/Notification/DetailNotification";
import EditNotification from "./pages/main/Master/Notification/EditNotification";
import ListNotification from "./pages/main/Master/Notification/ListNotification";
import ReportListConnection from "./pages/main/Report/Report/ListConnection/ListConnection";

// monitoring
import DetailConnection from "./pages/main/Monitoring/connection/DetailConnection";
import ListConnection from "./pages/main/Monitoring/connection/ListConnection";
// import ListMonitoringPo from "./pages/main/Monitoring/PO/ListMonitoringPo";
import { useDispatch, useSelector } from "react-redux";
import { userCategoryType } from "./constant/propertiesConstant";
import useCheckUserCategory from "./hooks/useCheckUserCategory";
import EditDistributor from "./pages/main/Master/Distributor/editDistributor/EditDistributor";
import DetailMonitoringListProcessPO from "./pages/main/Monitoring/List Process PO/Detail/DetailProcessPO";
import MonitoringListProcessPO from "./pages/main/Monitoring/List Process PO/ListProcessPO";
import MonitoringPO from "./pages/main/Monitoring/PO/PO";
import UnmappedProduct from "./pages/main/Monitoring/unmapped Product/UnmappedProduct";
import UnmappedStore from "./pages/main/Monitoring/unmapped Store/UnmappedStore";
import DetailBKLSummary from "./pages/main/Transaction/PO/BKLSummary/DetailBKLSummary";
import ListBKLSummary from "./pages/main/Transaction/PO/BKLSummary/ListBKLSummary";
import DetailProccessApprovePo from "./pages/main/Transaction/PO/ProccessApprovePO/DetailProccessApprovePo";
import ListProcessApprovePo from "./pages/main/Transaction/PO/ProccessApprovePO/ListProccessApprovePo";
import ListCopyMapping from "./pages/main/Mapping/CopyMapping/ListCopyMapping";


function App() {
  const dispatch = useDispatch();
  const { role, token, is_authenticated , user_category } = useSelector((state) => state.auth);
  const roleToLowerCase = role.toLowerCase();
  const isSysuserAdmin = roleToLowerCase.includes("admin");

  const { isAccepted } = useCheckUserCategory([userCategoryType.CUSTOM]);
  const isAdminLvl13 = user_category === userCategoryType.ADMIN;

  return (
    <BrowserRouter>
      <Switch>
        {/* PUBLIC */}
        <Route path={ROUTES.LOGIN} component={Login} exact />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} exact />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgetPassword} exact />
        <PrivateRoute path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} exact />
        <PrivateRoute path={ROUTES.CHANGE_PROFILE} component={ChangeProfile} exact />
        <PrivateRoute path={ROUTES.REQUEST_ACTIVATION_CODE} component={RequestActivationCode} exact />

        {/* ------- Start Of SysUser --------  */}

        {/* Home */}
        <PrivateRoute path={ROUTES.HOME} component={Home} exact />

        {/* User */}
        <PrivateRoute path={ROUTES.USER.LIST} component={ListUser} exact />
        <PrivateRoute path={ROUTES.USER.DETAIL} component={DetailUser} exact />
        <PrivateRoute path={ROUTES.USER.EDIT} component={EditUser} exact />
        <PrivateRoute path={ROUTES.USER.ADD} component={AddUser} exact />

        {/* Data Group  */}
        <PrivateRoute path={ROUTES.DATA_GROUP.LIST} component={ListDataGroup} exact />
        <PrivateRoute path={ROUTES.DATA_GROUP.ADD} component={AddDataGroup} exact />
        <PrivateRoute path={ROUTES.DATA_GROUP.EDIT} component={EditDataGroup} exact />
        <PrivateRoute path={ROUTES.DATA_GROUP.DETAIL} component={DetailDataGroup} exact />

        {/* Role  */}
        <PrivateRoute path={ROUTES.ROLE.LIST} component={ListRole} exact />
        <PrivateRoute path={ROUTES.ROLE.ADD} component={AddRole} exact />
        <PrivateRoute path={ROUTES.ROLE.EDIT} component={EditRole} exact />
        <PrivateRoute path={ROUTES.ROLE.DETAIL} component={DetailRole} exact />

        {/* User Activity  */}
        <PrivateRoute path={ROUTES.USER_ACTIVITY.LIST} component={ListUserActivity} exact />
        <PrivateRoute path={ROUTES.USER_ACTIVITY.DETAIL} component={DetailUserActivity} exact />

        {/* List Proccess Po  */}
        {/* <PrivateRoute path={ROUTES.LIST_PROCCESS_PO.LIST} component={ListMonitoringListProccessPo} exact /> */}

        {/* Connection  */}
        {/* <PrivateRoute path={ROUTES.CONNECTION.LIST} component={ListConnection} exact /> */}
        
        {/* Monitoring  */}
        
        <PrivateRoute path={ROUTES.SFTP_AS2_LOG.LIST} component={ListSftpAs2Log} exact />

        

        {/* --------- End Of SysUser ---------  */}

        {/* ------- Start Of User Seller --------  */}

        {/* --------- Product Principal -----------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.MASTER} component={Home} exact />

        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.LIST} component={ListProductPrincipal} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.ADD} component={AddProductPrincipal} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.EDIT} component={EditProductPrincipal} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.DETAIL} component={DetailProductPrincipal} exact />

        {/* -------- Product Customer  ----------- */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CUSTOMER.LIST} component={ListProductCustomer} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CUSTOMER.ADD} component={AddProductCustomer} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CUSTOMER.EDIT} component={EditProductCustomer} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CUSTOMER.DETAIL} component={DetailProductCustomer} exact />


        {/* -------- Product Category ---------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST} component={ListProductCategory} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY.ADD} component={AddProductCategory} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY.EDIT} component={EditProductCategory} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY.DETAIL} component={DetailProductCategory} exact />

        {/* ---------  Product Mapping HO --------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING_HO.LIST} component={ListProductMappingHO} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING_HO.ADD} component={AddProductMappingHO} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING_HO.EDIT} component={EditProductMappingHO} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING_HO.DETAIL} component={DetailProductMappingHO} exact />

        {/* -------- Product Mapping ---------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST} component={ListProductMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING.ADD} component={AddProductMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING.EDIT} component={EditProductMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_MAPPING.DETAIL} component={DetailProductMapping} exact />

        {/* -------- BKL Mapping -----  */}
        <PrivateRoute path={ROUTES_USER_SELLER.BKL_MAPPING.LIST} component={ListBKLMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.BKL_MAPPING.ADD} component={AddBKLMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.BKL_MAPPING.EDIT} component={EditBKLMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.BKL_MAPPING.DETAIL} component={DetailBKLMapping} exact />

        {/* -------- Product BKL Mapping ---------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_BKL_MAPPING.LIST} component={ListProductBKLMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_BKL_MAPPING.ADD} component={AddProductBKLMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_BKL_MAPPING.EDIT} component={EditProductBKLMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_BKL_MAPPING.DETAIL} component={DetailProductBKLMapping} exact />

        {/* ------ Product Category Mapping ---------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.LIST} component={ListProductCategoryMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.ADD} component={AddProductCategoryMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.EDIT} component={EditProductCategoryMapping} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.DETAIL} component={DetailProductCategoryMapping} exact />

        {/* --------- Product Subtitute ---------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.LIST} component={ListProductSubtitute} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.ADD} component={AddProductSubtitute} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.EDIT} component={EditProductSubtitute} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.DETAIL} component={DetailProductSubtitute} exact />

        {/* -------  Distributor -------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.DISTRIBUTOR.LIST} component={ListDistributor} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.DISTRIBUTOR.ADD} component={AddDistributor} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.DISTRIBUTOR.EDIT} component={EditDistributor} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.DISTRIBUTOR.DETAIL} component={DetailDistributor} exact />

        {/* --------- Warehouse ----------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.WAREHOUSE.LIST} component={ListWarehouse} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.WAREHOUSE.ADD} component={AddWarehouse} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.WAREHOUSE.EDIT} component={EditWarehouse} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.WAREHOUSE.DETAIL} component={DetailWarehouse} exact />

        {/* ------------ PO(Report) ------------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.REPORT_PO.LIST} component={ListPo} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.REPORT_PO_VS_SO.LIST} component={ListPoVsSo} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.REPORT_PO_DETAIL.LIST} component={ListPoDetail} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.REPORT_LIST_CONNECTION.LIST} component={ReportListConnection} exact />


        {/* ------ Transaction --------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.IMPORT_SO.LIST} component={ImportSO} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PROCESS_APPROVE_PO.LIST} component={ListProcessApprovePo} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.PROCESS_APPROVE_PO.DETAIL} component={DetailProccessApprovePo} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.BKL_SUMMARY.LIST} component={ListBKLSummary} exact />
        <PrivateRoute path={ROUTES_USER_SELLER.BKL_SUMMARY.DETAIL} component={DetailBKLSummary} exact />
        
        {/* ------ Dashboard -------  */}
        <PrivateRoute path={ROUTES_USER_SELLER.DASHBOARD.LIST} component={isAccepted ? DashboardBuyerCS : Dashboard} exact />

        {/* ------ End Of User Seller --------  */}


        {/* Start of User Buyer */}
        <PrivateRoute path={ROUTES_USER_BUYER.MASTER} component={Home} exact />
        {/* --------- Master Store ---------  */}
        <PrivateRoute path={ROUTES_USER_BUYER.STORE.LIST} component={ListStore} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.STORE.DETAIL} component={DetailStore} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.STORE.ADD} component={AddStore} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.STORE.EDIT} component={EditStore} exact />

         {/* --------- Copy Mapping---------  */}
         <PrivateRoute path={ROUTES_USER_BUYER.MAPPING.ADD} component={ListCopyMapping} exact />

        {/* --------- Process ---------  */}
        
        <PrivateRoute path={ROUTES_USER_BUYER.PROCESS.LIST} component={ListProcessPO} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.PROCESS.DETAIL} component={DetailProcessPO} exact />

        {/* --------- ReProcess ---------  */}
        <PrivateRoute path={ROUTES_USER_BUYER.REPROCESS.LIST} component={ListReprocessPO} exact />

        {/* --------- Store Supplier Connection -------- */}
        <PrivateRoute path={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.LIST} component={ListStoreSuppConn} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.DETAIL} component={DetailStoreSuppConn} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.ADD} component={AddStoreSuppConn} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.EDIT} component={EditStoreSuppConn} exact />

        {/* --------- Notification -------- */}
        <PrivateRoute path={ROUTES_USER_BUYER.NOTIFICATION.LIST} component={ListNotification} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.NOTIFICATION.DETAIL} component={DetailNotification} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.NOTIFICATION.ADD} component={AddNotification} exact />
        <PrivateRoute path={ROUTES_USER_BUYER.NOTIFICATION.EDIT} component={EditNotification} exact />



        {/* --------- Multi User Menu --------- */}

        {/* MONITORING */}
        
        {/* List Process PO */}
        <PrivateRoute path={ROUTES.LIST_PROCCESS_PO.LIST} component={MonitoringListProcessPO} exact />
        <PrivateRoute path={ROUTES.LIST_PROCCESS_PO.DETAIL} component={DetailMonitoringListProcessPO} exact />
        {/* PO */}
        <PrivateRoute path={ROUTES.MONITORING_PO.LIST} component={MonitoringPO} exact />
        {/* Connection  */}
        <PrivateRoute path={ROUTES.CONNECTION.LIST} component={ListConnection} exact />
        <PrivateRoute path={ROUTES.CONNECTION.DETAIL} component={DetailConnection} exact />
        {/* SFTP AS2 LOG  */}
        <PrivateRoute path={isAdminLvl13 ? ROUTES.SFTP_AS2_LOG_ADMIN.LIST  : ROUTES.SFTP_AS2_LOG.LIST} component={ListSftpAs2Log} exact />
        {/*  uNMAPPED PRODUCT */}
        <PrivateRoute path={ROUTES.UNMAPPED_PRODUCT.LIST} component={UnmappedProduct} exact />
        {/*  uNMAPPED Store */}
        <PrivateRoute path={ROUTES.UNMAPPED_STORE.LIST} component={UnmappedStore} exact />


        {/* Not Found  */}
        <PrivateRoute path={ROUTES.NOTFOUND} component={NotFound} exact />

      </Switch>
    </BrowserRouter>
  );
}

export default App;
