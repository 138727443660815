import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Divider,
  Text,
  Badge,
  Select as SelectChakra,
  Button,
} from "@chakra-ui/react";
import { Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import SortButton from "../../../components/SortButton";
import { ROUTES } from "../../../constant/routeConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  BlueEditIcon,
  RedDeleteIcon,
  BackIcon,
  DetailIcon,
} from "../../../components/icons";
const DetailUserActivity = () => {
  const { t } = useTranslation();
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("USER_ACTIVITY:DETAIL_USER_ACTIVITY")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>Home</span> -{" "}
            <span style={{ color: "#003C7C" }}>Report</span> -{" "}
            <span style={{ color: "#003C7C" }}>Log</span> -{" "}
            <span style={{ color: "#003C7C" }}>User Activity</span> - Detail
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <Box
        marginTop={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <ButtonCustom
            text="Back to List"
            color="#003C7C"
            variant="unstyled"
            link={ROUTES.USER_ACTIVITY.LIST}
            icon={<BackIcon />}
          />
        </Box>
        <Box>
          <Text>
            <Stack direction="row" spacing="4rem">
              <Box display="flex" alignItems="center">
                <Box marginRight={2}>
                  Created By <b>Joko</b> On <b>Feb-2021</b> | Last Modified By{" "}
                  <b>Tamma</b> On <b>Feb 19-Feb 2021</b>
                </Box>
                <Box>
                  <DetailIcon />
                </Box>
              </Box>
            </Stack>
          </Text>
        </Box>
      </Box>

      {/* Body */}
      <Box backgroundColor="white" minHeight="300px" padding={4}>
        <Box padding={1} display="flex">
          {/* left side  */}
          <Box padding={1} width="50rem">
            {/* Client Id */}
            <Box padding={1} marginBottom={2}>
              <Box>Client ID</Box>
              <Box>
                <Text>
                  <b>343i489sjfdnsngue438w45464rs</b>
                </Text>
              </Box>
            </Box>
            {/* Username  */}
            <Box padding={1} marginBottom={2}>
              <Box>Username</Box>
              <Box>
                <Text>
                  <b>Adistia</b>
                </Text>
              </Box>
            </Box>
            {/* Company Name  */}
            <Box padding={1} marginBottom={2}>
              <Box>Company Name</Box>
              <Box>
                <Text>
                  <b>PT Tirta Investama</b>
                </Text>
              </Box>
            </Box>
             {/* Time Activity  */}
             <Box padding={1} marginBottom={2}>
              <Box>Time Activity</Box>
              <Box>
                <Text>
                  <b>2021-05-06 14.40.08</b>
                </Text>
              </Box>
            </Box>
          </Box>
          {/* right side  */}
          <Box padding={1} width="50rem">
            {/* Activity */}
            <Box padding={1} marginBottom={2}>
              <Box>Activity</Box>
              <Box>
                <Text>
                  <b>Login</b>
                </Text>
              </Box>
            </Box>
            {/* Status  */}
            <Box padding={1} marginBottom={2}>
              <Box>Status</Box>
              <Box>
                <Text>
                  <b>Success</b>
                </Text>
              </Box>
            </Box>
            {/* IP Address  */}
            <Box padding={1} marginBottom={2}>
              <Box>IP Address</Box>
              <Box>
                <Text>
                  <b>192.168.70.80</b>
                </Text>
              </Box>
            </Box>
             {/* Device  */}
             <Box padding={1} marginBottom={2}>
              <Box>Device</Box>
              <Box>
                <Text>
                  <b>Samsung XXX</b>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailUserActivity;
