import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addProductCategorySchema = (t) => {
  return () =>
    yup.object().shape({
      distributor_id: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME"),
          })
        )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      product_category_code: yup
        .string()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_CODE"),
          })
        ),
      product_category_name: yup
        .string()
        .max(
          100,
          i18next.t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(100),
          })
        )
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME"),
          })
        )
        .matches(
          REGEX_CONSTANT.PRODUCT_CATEGORY_NAME,
          t("PRODUCT_CATEGORY:ERROR_PRODUCT_CATEGORY_NAME")
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_CATEGORY:FIELD_STATUS"),
        })
      ),
    });
};

export const editProductCategorySchema = (t) => {
  return () =>
    yup.object().shape({
      distributor_id: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME"),
          })
        )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      product_category_code: yup
        .string()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_CODE"),
          })
        ),
      product_category_name: yup
        .string()
        .max(
          100,
          i18next.t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(100),
          })
        )
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME"),
          })
        )
        .matches(
          REGEX_CONSTANT.PRODUCT_CATEGORY,
          i18next.t("COMMON:ERROR_INCORRECT_FORMAT", {
            field: i18next.t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME"),
          })
        ),
    });
};
