const ObjectMergeOptions = {
    isMergingArrays: false,
    isImmutable: true
  }
  
  /**
   * Function to check of object has empty, null, and undefined
   * @param {object} object 
   * @returns {boolean} boolean, true if is empty, else false
   * @author Jo
   */
  const isEmpty = (object) => {
    switch (typeof object) {
      case 'string':
        return !(object.trim().length > 0);
      case 'object':
        if (object instanceof Array) {
          return !(object.length > 0);
        }
        for (let key in object) return false;
        return true
      default:
        return isNull(object);
    }
  }
  
  /**
   * Function to check of object has not empty, opposite of isEmpty
   * @param {object} object 
   * @returns {boolean} boolean, true if is not empty, else false
   * @author Bayu
   */
  const isNotEmpty = (object) => !isEmpty(object);
  
  /**
   * Function to check if is object
   * @param {object} obj 
   * @returns {boolean} boolean, true if object, false otherwise
   * @author Bayu
   */
  const isObject = (obj) => obj && typeof obj === 'object';
  
  /**
   * Function to check if object is null or undefined
   * @param {object} object 
   * @returns {boolean} boolean, true if object null or undefined, else false
   * @author Bayu
   */
  const isNull = (object) => {
    if (object === undefined || object == null) return true;
    return false;
  }
  
  /**
   * Function to check if object is not null or undefined, opposite to isNull function
   * @param {object} object 
   * @returns {boolean} boolean, true if object null or undefined, else false
   * @author Bayu
   */
  const isNotNull = (object) => {
    if ((object !== undefined && object != null)) return true;
    return false;
  }
  
  const isFunction = (fn) => {
    return (fn !== null || fn !== undefined) && typeof fn === 'function';
  }
  
  /**
   * Function to deeply merge target object with source object, where if target don't have key,
   * will append from source target
   * @param {object} target 
   * @param {object} source 
   * @param {ObjectMergeOptions} options 
   * @author ahtcx https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
   */
  const merge = (target, source, options) => {
    target = ((obj) => {
      let cloneObj;
  
      if (!options.isImmutable) {
        return Object.assign({}, obj)
      }
  
      try {
        cloneObj = JSON.parse(JSON.stringify(obj));
      } catch (err) {
        cloneObj = Object.assign({}, obj)
      }
  
      return cloneObj;
    })(target);
  
    if (!isObject(target) || !isObject(source)) return source;
  
    Object.keys(source).forEach(key => {
      const targetValue = target[key];
      const sourceValue = source[key];
  
      if (Array.isArray(targetValue) && Array.isArray(sourceValue))
          if (options.isMergingArrays) {
              target[key] = targetValue.map((x, i) => sourceValue.length <= i ? x : merge(x, sourceValue[i], options.isMergingArrays));
              if (sourceValue.length > targetValue.length)
                  target[key] = target[key].concat(sourceValue.slice(targetValue.length));
          } else {
              target[key] = targetValue.concat(sourceValue);
          }
      else if (isObject(targetValue) && isObject(sourceValue))
          target[key] = merge(Object.assign({}, targetValue), sourceValue, options.isMergingArrays);
      else
          target[key] = sourceValue;
    });
  
    return target;
  }
  const isPropertiesTruthy = (object) => {
    let isTruthy = true;
    for (const key in object) {
      if (!Boolean(object[key])) {
        isTruthy = false;
        break;
      }
    }
    return isTruthy;
  }
  const ObjectUtil = {
    isEmpty,
    isNotEmpty,
    isPropertiesTruthy,
    isNull,
    isNotNull,
    isObject,
    isFunction,
    merge
  }
  
  export default ObjectUtil;