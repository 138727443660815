import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { CheckStatus } from "../constant/permissionConstant";
import {
  BLACK_COLOR,
  GRAY_BG_MEDIUM,
  GRAY_COLOR,
  GRAY_DISABLED,
  GRAY_MEDIUM,
  GREEN_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../constant/propertiesConstant";
// import { CheckedIcon, UncheckedIcon } from "../../icons";
import {
  CorrectIcon as CheckedIcon,
  DoubleCorrectIcon,
  CloseIcon as UncheckedIcon,
} from "../components/icons";

const CustomCheckbox = ({
  name,
  isChecked,
  onChange,
  children,
  isIgnored,
  ...restProps
}) => {
  return (
    <Flex
      as="label"
      key={name}
      data-test-id={name}
      data-test-checked={isChecked}
      display={isIgnored ? "none" : "flex"}
    >
      <input
        name={name}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
        data-test-checked={isChecked}
        hidden
      />
      <Stack
        as="span"
        direction="row"
        alignItems="center"
        height="22px"
        backgroundColor={isChecked ? GREEN_COLOR : GRAY_BG_MEDIUM}
        paddingY="0"
        paddingX="1.5"
        borderRadius="4px"
        color={WHITE_COLOR}
        fontWeight="400"
        fontSize="sm"
        cursor="pointer"
      >
        {isChecked ? <CheckedIcon /> : <UncheckedIcon />}
        <Box fontSize="12">{children}</Box>
      </Stack>
    </Flex>
  );
};

const CheckboxTree = ({
  data,
  onChange,
  onNodeClick,
  onHeaderClick,
  translate,
  translatePrefix,
  translateAfterfix,
  translateButtonAfterfix,
  level = 1,
  ...restProps
} = {}) => {
  const handleParentClick = (event, node, index) => {
    onNodeClick && onNodeClick(event, node, index);
  };

  const handleClickChange = (
    event,
    node,
    permission,
    index,
    arr,
    isAllChecked
  ) => {
    onChange && onChange(event, node, permission, index, arr, isAllChecked);
  };

  if (!translatePrefix) translatePrefix = "COMMON";

  return (
    <>
      {data &&
        Array.isArray(data) &&
        data.map((node, index) => {
          const isAllChecked = node.permission.every(
            (each) => each.status === CheckStatus.CHECKED || each?.ignored
          );
          return (
            <Box
              key={node.key}
              // id={`${ELEMENT_ID.CBTREE}_${level}`}
              // data-test-id={`${ELEMENT_ID.CBTREE}_${level}`}
            >
              {level > 1 && (
                <Box position="relative">
                  <Box
                    position="absolute"
                    top="40px"
                    left="-16px"
                    height="1px"
                    width="10px"
                    bgColor={GRAY_DISABLED}
                  />
                </Box>
              )}
              <Box
                border={`1px solid ${
                  node?.isExpanded ? PRIMARY_COLOR : GRAY_DISABLED
                }`}
                my="1"
                padding="4"
                borderRadius="8px"
                onClick={(event) => handleParentClick(event, node, index)}
                maxWidth={`${755 - level * 34}px`}
              >
                <Text
                  fontSize="11px"
                  color={GRAY_MEDIUM}
                  fontWeight="500"
                  lineHeight="11px"
                >
                  {translate("COMMON:LEVEL").toUpperCase()} {String(level)}
                </Text>
                <Flex
                  color={node?.isExpanded ? PRIMARY_COLOR : BLACK_COLOR}
                  fontWeight="500"
                >
                  {translate ? (
                    <Text>
                      {translate(
                        `${translatePrefix}:${
                          translateAfterfix ?? ""
                        }${node?.label?.toUpperCase()}`
                      )}
                    </Text>
                  ) : (
                    <Text>{node.label}</Text>
                  )}

                  {node.children && (
                    <Text>
                      {node?.isExpanded ? (
                        <ChevronUpIcon h="18px" w="24px" />
                      ) : (
                        <ChevronDownIcon h="18px" w="24px" />
                      )}
                    </Text>
                  )}
                </Flex>

                <Stack direction="row">
                  {node?.permission &&
                    node.permission.map((permission, index) => {
                      return (
                        <Box
                          key={permission.key}
                          display={permission?.ignored ? "none" : "block"}
                        >
                          <CustomCheckbox
                            name={permission.key}
                            isChecked={
                              permission.status === CheckStatus.CHECKED
                            }
                            onChange={(event) =>
                              handleClickChange(event, node, permission, index)
                            }
                          >
                            {translate ? (
                              <Text>
                                {translate(
                                  `${translatePrefix}:${
                                    translateButtonAfterfix ?? ""
                                  }${permission.label.toUpperCase()}`
                                )}
                              </Text>
                            ) : (
                              <Text>{permission.label}</Text>
                            )}
                          </CustomCheckbox>
                        </Box>
                      );
                    })}

                  <Box>
                    <CustomCheckbox
                      name={`${node.key}:select_all`}
                      isChecked={isAllChecked}
                      onChange={(event) =>
                        handleClickChange(
                          event,
                          node,
                          {
                            key: `${node.key}:select_all`,
                            label: "Select All",
                          },
                          index,
                          [],
                          !isAllChecked
                        )
                      }
                    >
                      <Text>Select All</Text>
                    </CustomCheckbox>
                  </Box>
                </Stack>
              </Box>

              {node.children &&
                node.isExpanded &&
                Array.isArray(node.children) && (
                  <Box
                    marginLeft="18px"
                    borderLeft={`1px solid ${GRAY_BG_MEDIUM}`}
                    paddingLeft="4"
                  >
                    <CheckboxTree
                      data={node.children}
                      onChange={handleClickChange}
                      onNodeClick={handleParentClick}
                      translate={translate}
                      translatePrefix={translatePrefix}
                      translateAfterfix={translateAfterfix}
                      translateButtonAfterfix={translateButtonAfterfix}
                      level={level + 1}
                    />
                  </Box>
                )}
            </Box>
          );
        })}
    </>
  );
};

export default CheckboxTree;
