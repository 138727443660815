import { customAlphabet } from "nanoid";
import {
  AccountIcon,
  DataGroupIcon,
  HomeIcon,
  MasterIcon,
  ReportIcon,
  RoleIcon,
  SetupIcon,
  UserIcon,
  UserActivityIcon,
  MonitoringIcon,
  TransactionIcon,
  ParameterIcon,
  MappingIcon,
  DashboardIcon,
  ProcessIcon,
  SubProcessIcon,
} from "../components/icons";
export const DEFAULT_LANGUAGE = "id-ID";
export const ID_LANGUAGE = "id-ID";
export const EN_LANGUAGE = "en-US";
export const RESOURCE = "farm";

const nanoid = customAlphabet("1234567890", 16);
export const CRYPTO_RANDOM = nanoid();

export const PRIMARY_COLOR = "#003C7C";
export const HOVER_PRIMARY = "#326296";
export const WHITE_COLOR = "#ffffff";
export const BLACK_COLOR = "#555555";
export const GREEN_COLOR = "#13A780";
export const GRAY_COLOR = "#777777";
export const GRAY_MEDIUM = "#999";
export const GRAY_BG_MEDIUM = "#666";
export const GRAY_DISABLED = "#DDDDDD";
export const GRAY_OPTION = "#cccccc";
export const RED_COLOR = "#BC2023";
export const YELLOW_COLOR = "#D69E2E";

export const LEVEL_13 = 13;
export const LEVEL_14 = 14;
export const STATUS_ACTIVE = "A";
export const STATUS_INACTIVE = "N";
export const STATUS_SUCCESS = "SUCCESS";
export const STATUS_FAILED = "FAILED";
export const LABEL_STATUS_ACTIVE = "Active";
export const LABEL_STATUS_INACTIVE = "Non Active";
export const LABEL_STATUS_SUCCESS = "Success";
export const LABEL_STATUS_FAILED = "Failed";
export const STATUS_APPROVED = "APPROVED";
export const STATUS_REJECTED = "REJECTED";
export const STATUS_UPLOADED = "UPLOADED";
export const LABEL_STATUS_APPROVED = "Approved";
export const LABEL_STATUS_REJECTED = "Rejected";
export const LABEL_STATUS_UPLOADED = "Uploaded";

export const LANGUANGE = {
  EN : "en-US",
  ID : "id-ID",
}

export const ROLE = {
  SYSADMIN: 11,
  ADMIN_SELLER: 13,
  SELLER: 14,
  USER_BUYER: 15,
};

export const DATA_GROUP = {
  SYSADMIN: 11,
  SUPPORT: 12,
  ADMIN_SELLER: 13,
  SELLER: 14,
  USER_BUYER: 15,
};

export const activationStatusOptions = [
  { value: "A", label: 'Active', langKey: 'COMMON:ACTIVE' },
  { value: "N", label: 'Non Active', langKey: 'COMMON:NONACTIVE' }
];

export const USER_CATEGORY = {
  FARMINDO: {
    value: "root_user",
    level: ROLE.SYSADMIN,
    level_data_group: DATA_GROUP.SYSADMIN,
  },
  ROOT: {
    value: "root_user",
    level: ROLE.SYSADMIN,
    level_data_group: DATA_GROUP.SYSADMIN,
  },
  ADMIN: {
    value: "admin_user",
    level: ROLE.ADMIN_SELLER,
    level_data_group: DATA_GROUP.ADMIN_SELLER,
  },
  PRINCIPAL: {
    value: "principal_user",
    level: ROLE.SELLER,
    level_data_group: DATA_GROUP.SELLER,
  },
  SUPPORT: {
    value: "support_user",
    level: ROLE.SYSADMIN,
    level_data_group: DATA_GROUP.SUPPORT,
  },
  CUSTOM: {
    value: "custom_user",
    level: ROLE.USER_BUYER,
    level_data_group: DATA_GROUP.USER_BUYER,
  },
};

export const userCategoryType = {
  FARMINDO: "root_user",
  ROOT: "root_user",
  ADMIN: "admin_user",
  PRINCIPAL: "principal_user",
  SUPPORT: "support_user",
  CUSTOM: "custom_user",
};

export const buttonVariant = {
  outline: "outline",
  solid: "solid",
};

export const badgeColor = {
  primaryColor: "primaryColor",
  primaryGray: "primaryGray",
};

export const colorScheme = {
  customGreen: "customGreen",
};

export const objIcon = {
  home: HomeIcon,
  account: AccountIcon,
  master: MasterIcon,
  user: UserIcon,
  data_group: DataGroupIcon,
  role: RoleIcon,
  setup: SetupIcon,
  report: ReportIcon,
  transaction: TransactionIcon,
  product: MasterIcon,
  monitoring: MonitoringIcon,
  process: ProcessIcon,
  "master.distributor": MasterIcon,
  "master.customer": MasterIcon,
  "master.warehouse": MasterIcon,
  "master.product": MasterIcon,
  "master.product.product_principal": MasterIcon,
  "master.product.product_principal": MasterIcon,
  "master.product.product_customer": MasterIcon,
  "master.product.product_customer": MasterIcon,
  "master.product.product_category": MasterIcon,
  "master.product.product_category": MasterIcon,
  "master.mapping": MappingIcon,
  "master.mapping.bkl_mapping": MappingIcon,
  "master.mapping.product_bkl_mapping": MappingIcon,
  "master.mapping.product_mapping_ho": MappingIcon,
  "master.mapping.product_mapping": MappingIcon,
  "master.mapping.product_category_mapping": MappingIcon,
  "master.mapping.product_substitute": MappingIcon,
  "mapping": MappingIcon,
  "mapping.mapping": MappingIcon,
  "mapping.mapping.copy_mapping": MappingIcon,
  "master.store": MasterIcon,
  "master.store.store": MasterIcon,
  "role.sysadmin": RoleIcon,
  "role.sysuser": RoleIcon,
  "report.log": UserActivityIcon,
  "report.log.user-activity": UserActivityIcon,
  "report.monitoring": MonitoringIcon,
  "report.dashboard": DashboardIcon,
  "report.dashboard.summary": DashboardIcon,
  "report.monitoring.upload-log": MonitoringIcon,
  "report.monitoring.as2_sftp_log": MonitoringIcon,
  "report.report": ReportIcon,
  "report.report.po": ReportIcon,
  "report.report.po_vs_so": ReportIcon,
  "report.report.po_detail": ReportIcon,
  "report.report.list_connection": ReportIcon,
  "transaction.so": ParameterIcon,
  "transaction.so.import_so": MasterIcon,
  "transaction.po": TransactionIcon,
  "transaction.po.process_approve_po": TransactionIcon,
  "transaction.po.bkl_summary": TransactionIcon,
  "monitoring.monitoring": MonitoringIcon,
  "monitoring.monitoring.list_process_po": MonitoringIcon,
  "monitoring.monitoring.po": MonitoringIcon,
  "monitoring.monitoring.connection": MonitoringIcon,
  "monitoring.monitoring.unmapped-product": MonitoringIcon,
  "monitoring.monitoring.unmapped-store": MonitoringIcon,
  "monitoring.monitoring.upload-log": MonitoringIcon,
  "monitoring.sftp-log": MonitoringIcon,
  "monitoring.as2-log": MonitoringIcon,
  "process.process": SubProcessIcon,
  "process.processpo": SubProcessIcon,
  "process.reprocesspo": SubProcessIcon,
  "monitoring.monitoring.so": MonitoringIcon,
  "monitoring.monitoring.unmapped_product": MonitoringIcon,
  "monitoring.monitoring.unmapped_store": MonitoringIcon,
  "monitoring.monitoring.upload_log": MonitoringIcon,
  "monitoring.monitoring.as2_sftp_log": MonitoringIcon,
  "process.process_po.processed_po": ProcessIcon,
  "process.process_po": ProcessIcon,
  "process.process_po.reprocess_po": ProcessIcon,
  "master.connection": MonitoringIcon,
  "master.connection.store_supplier_connection": MappingIcon,
  "master.notification": MasterIcon,
  "master.notification.notification": MasterIcon,
};

// TODO deleted soon
export const exampleMenu = [
  {
    id: 1,
    name: "Rumah",
    en_name: "Home",
    url: "/home",
    background: "#FF0000",
    menu_code: "home",
    service_menu: null,
  },
  {
    id: 4,
    name: "Pengguna",
    en_name: "User",
    url: "/user",
    background: "#FF0000",
    menu_code: "user",
    service_menu: null,
  },
  {
    id: 5,
    name: "Data Grup",
    en_name: "Data Group",
    url: "/data-group",
    background: "#FF0000",
    menu_code: "data-group",
    service_menu: null,
  },
  {
    id: 6,
    name: "Role",
    en_name: "Role",
    url: "/role",
    background: "#FF0000",
    menu_code: "role",
    service_menu: null,
  },
  {
    id: 7,
    name: "Transaksi",
    en_name: "Transaction",
    url: "",
    background: "#FF0000",
    menu_code: "transaction",
    service_menu: [
      {
        id: 8,
        parent_menu_id: 7,
        name: "SO",
        en_name: "SO",
        icon_name: "",
        menu_code: "transaction.so",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 8,
            name: "Impor SO",
            en_name: "Import SO",
            icon_name: "",
            type: "",
            menu_code: "transaction.so",
            url: "/import-so",
          },
        ],
      },
      {
        id: 9,
        parent_menu_id: 7,
        name: "PO",
        en_name: "PO",
        icon_name: "",
        menu_code: "transaction.po",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 9,
            name: "Proses Menyetujui PO",
            en_name: "Proccess Approve PO",
            icon_name: "",
            type: "",
            menu_code: "transaction.po.proccess-approve-po",
            url: "/upload-log",
          },
          {
            id: 2,
            service_menu_id: 9,
            name: "Ringkasan",
            en_name: "BKL Summary",
            icon_name: "",
            type: "",
            menu_code: "transaction.po.bkl-summary",
            url: "/sftp-log",
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: "Laporan",
    en_name: "Report",
    url: "",
    background: "#FF0000",
    menu_code: "report",
    service_menu: [
      {
        id: 5,
        parent_menu_id: 8,
        name: "Laporan",
        en_name: "Report",
        icon_name: "",
        menu_code: "report.report",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 5,
            name: "PO",
            en_name: "PO",
            icon_name: "",
            type: "",
            menu_code: "report.report.po",
            url: "/report-po",
          },
          {
            id: 2,
            service_menu_id: 5,
            name: "PO VS SO",
            en_name: "PO VS SO",
            icon_name: "",
            type: "",
            menu_code: "report.report.povsso",
            url: "/report-po-vs-so",
          },
          {
            id: 3,
            service_menu_id: 5,
            name: "Detail PO",
            en_name: "PO Detail",
            icon_name: "",
            type: "",
            menu_code: "report.report.podetail",
            url: "/report-detail",
          },
          {
            id: 4,
            service_menu_id: 5,
            name: "List Connection",
            en_name: "List Connection",
            icon_name: "",
            type: "",
            menu_code: "report.report.listconnection",
            url: "/report-list-connection",
          },
        ],
      },
      {
        id: 6,
        parent_menu_id: 8,
        name: "Catatan",
        en_name: "Log",
        icon_name: "",
        menu_code: "report.log",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 2,
            service_menu_id: 6,
            name: "Aktifitas Pengguna",
            en_name: "User Activity",
            icon_name: "",
            type: "",
            menu_code: "report.log.user-activity",
            url: "/user-activity",
          },
        ],
      },
      {
        id: 7,
        parent_menu_id: 8,
        name: "Pemantauan",
        en_name: "Monitoring",
        icon_name: "",
        menu_code: "report.monitoring",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 2,
            service_menu_id: 7,
            name: "SFTP Log",
            en_name: "SFTP Log",
            icon_name: "",
            type: "",
            menu_code: "report.monitoring.sftp-log",
            url: "/sftp-log",
          },
          {
            id: 3,
            service_menu_id: 7,
            name: "AS2 Log",
            en_name: "AS2 Log",
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.as2_sftp_log",
            url: "/as2-log",
          },
        ],
      },
      {
        id: 8,
        parent_menu_id: 8,
        name: "Beranda",
        en_name: "Dashboard",
        icon_name: "",
        menu_code: "report.dashboard",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 2,
            service_menu_id: 6,
            name: "Ringkasan",
            en_name: "Summary",
            icon_name: "",
            type: "",
            menu_code: "report.dashboard.summary",
            url: "/dashboard",
          },
        ],
      },
    ],
  },
  {
    id: 10,
    name: "Master",
    en_name: "Master",
    url: "",
    background: "#FF0000",
    menu_code: "master",
    service_menu: [
      {
        id: 1,
        parent_menu_id: 10,
        name: "Produk",
        en_name: "Product",
        icon_name: "",
        menu_code: "master.product",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 1,
            name: "Produk Principal",
            en_name: "Product Principal",
            icon_name: "",
            type: "",
            menu_code: "master.product.product_principal",
            url: "/product-principal",
          },
          {
            id: 2,
            service_menu_id: 1,
            name: "Produk Pelanggan",
            en_name: "Product Customer",
            icon_name: "",
            type: "",
            menu_code: "master.product.product_customer",
            url: "/product-customer",
          },
          {
            id: 3,
            service_menu_id: 1,
            name: "Produk Kategori",
            en_name: "Product Category",
            icon_name: "",
            type: "",
            menu_code: "master.product.product_category",
            url: "/product-category",
          },
        ],
      },
      {
        id: 2,
        parent_menu_id: 10,
        name: "Mapping",
        en_name: "Mapping",
        icon_name: "",
        menu_code: "master.mapping",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 2,
            name: "Produk Mapping HO",
            en_name: "Product Mapping HO",
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product-mapping-ho",
            url: "/product-mapping-ho",
          },
          {
            id: 2,
            service_menu_id: 2,
            name: "Produk Mapping",
            en_name: "Product Mapping",
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product-mapping",
            url: "/product-mapping",
          },
          {
            id: 3,
            service_menu_id: 2,
            name: "BKL Mapping",
            en_name: "BKL Mapping",
            icon_name: "",
            type: "",
            menu_code: "master.mapping.bkl-mapping",
            url: "/bkl-mapping",
          },
          {
            id: 4,
            service_menu_id: 2,
            name: "Product BKL Mapping",
            en_name: "Product BKL Mapping",
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product-bkl-mapping",
            url: "/product-bkl-mapping",
          },
          {
            id: 5,
            service_menu_id: 2,
            name: "Product Category Mapping",
            en_name: "Product Category Mapping",
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product-category-mapping",
            url: "/product-category-mapping",
          },
          {
            id: 6,
            service_menu_id: 2,
            name: "Product Pengganti",
            en_name: "Product Subtitute",
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_subtitute",
            url: "/product-subtitute",
          },
        ],
      },
      {
        id: 3,
        name: "Distributor",
        parent_menu_id: 10,
        en_name: "Distributor",
        url: "/distributor",
        background: "#FF0000",
        menu_code: "master.distributor",
        service_menu: null,
      },
      {
        id: 4,
        name: "Warehouse",
        parent_menu_id: 10,
        en_name: "Warehouse",
        url: "/warehouse",
        background: "#FF0000",
        menu_code: "master.warehouse",
        service_menu: null,
      },
      {
        id: 5,
        parent_menu_id: 10,
        name: "Store",
        en_name: "Store",
        icon_name: "",
        menu_code: "master.store",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 9,
            name: "Store",
            en_name: "Store",
            icon_name: "",
            type: "",
            menu_code: "master.store.store",
            url: "/store",
          },
        ],
      },
      {
        id: 6,
        parent_menu_id: 10,
        name: "Connection",
        en_name: "Connection",
        icon_name: "",
        menu_code: "master.connection",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 2,
            name: "Store Supplier Connection",
            en_name: "Store Supplier Connection",
            icon_name: "",
            type: "",
            menu_code: "master.connection.store-supplier-connection",
            url: "/store-supplier-connection",
          },
        ],
      },
      {
        id: 7,
        parent_menu_id: 10,
        name: "Notification",
        en_name: "Notification",
        icon_name: "",
        menu_code: "master.notification",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            service_menu_id: 2,
            name: "Notification",
            en_name: "Notification",
            icon_name: "",
            type: "",
            menu_code: "master.notification.notification",
            url: "/notification",
          },
        ],
      },
    ],
  },
  {
    id: 11,
    name: "Pemantauan",
    en_name: "Monitoring",
    url: "",
    background: "#FF0000",
    menu_code: "monitoring",
    service_menu: [
      {
        id: 1,
        parent_menu_id: 11,
        name: "Pemantauan",
        en_name: "Monitoring",
        icon_name: "",
        menu_code: "monitoring.monitoring",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            name: "Daftar Proses PO",
            en_name: "List Proccess PO",
            icon_name: "",
            menu_code: "monitoring.list-proccess-po",
            url: "/list-proccess-po",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 2,
            name: "PO",
            en_name: "PO",
            icon_name: "",
            menu_code: "monitoring.po",
            url: "/monitoring-po",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 3,
            name: "Koneksi",
            en_name: "Connection",
            icon_name: "",
            menu_code: "monitoring.connection",
            url: "/connection",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 4,
            name: "Produk Yang Belum Dipetakan",
            en_name: "Unmapped Product",
            icon_name: "",
            menu_code: "monitoring.unmapped-product",
            url: "/unmapped-product",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 5,
            name: "Toko Yang Belum Dipetakan",
            en_name: "Unmapped Store ",
            icon_name: "",
            menu_code: "monitoring.unmapped-store",
            url: "/unmapped-store",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 6,
            name: "Upload Log",
            en_name: "Upload Log",
            icon_name: "",
            menu_code: "monitoring.upload-log",
            url: "/distributor",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 7,
            name: "SFTP & AS2 Log",
            en_name: "SFTP Log & AS2 Log",
            icon_name: "",
            menu_code: "monitoring.sftp-log",
            url: "/sftp-as2-log",
            background: "#FF0000",
            menu_item: null,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    name: "Proses",
    en_name: "Process",
    url: "",
    background: "#FF0000",
    menu_code: "process",
    service_menu: [
      {
        id: 1,
        parent_menu_id: 12,
        name: "Proses PO",
        en_name: "Process PO",
        icon_name: "",
        menu_code: "process.process",
        url: "",
        background: "#FF0000",
        menu_item: [
          {
            id: 1,
            name: "Process PO",
            en_name: "Process PO",
            icon_name: "",
            menu_code: "process.processpo",
            url: "/process",
            background: "#FF0000",
            menu_item: null,
          },
          {
            id: 2,
            name: "Reprocess PO",
            en_name: "Reprocess PO",
            icon_name: "",
            menu_code: "process.reprocesspo",
            url: "/reprocess",
            background: "#FF0000",
            menu_item: null,
          },
        ],
      },
    ],
  },
];

export const sellerMenuNestle = [
  {
    id: 1,
    name: "Beranda",
    en_name: "Home",
    sequence: 1,
    icon_name: "",
    url: "/home",
    access_level: 9,
    background: "",
    menu_code: "home",
    service_menu: null,
  },
  {
    id: 5,
    name: "Master",
    en_name: "Master",
    sequence: 5,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "master",
    service_menu: [
      {
        id: 5,
        parent_menu_id: 5,
        name: "Produk",
        en_name: "Product",
        sequence: 1,
        icon_name: "",
        menu_code: "master.product",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 6,
            service_menu_id: 5,
            name: "Produk Principal",
            en_name: "Product Principal",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_principal",
            access_level: 9,
            url: "/product-principal",
            principal_locked: false,
          },
          {
            id: 7,
            service_menu_id: 5,
            name: "Produk Pelanggan",
            en_name: "Product Customer",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_customer",
            access_level: 9,
            url: "/product-customer",
            principal_locked: false,
          },
          {
            id: 8,
            service_menu_id: 5,
            name: "Kategori Produk",
            en_name: "Product Category",
            sequence: 3,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_category",
            access_level: 9,
            url: "/product-category",
            principal_locked: false,
          },
        ],
      },
      {
        id: 6,
        parent_menu_id: 5,
        name: "Pemetaan",
        en_name: "Mapping",
        sequence: 2,
        icon_name: "",
        menu_code: "master.mapping",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 9,
            service_menu_id: 6,
            name: "Pemetaan Produk HO",
            en_name: "Product Mapping HO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_mapping_ho",
            access_level: 9,
            url: "/product-mapping-ho",
            principal_locked: false,
          },
          {
            id: 10,
            service_menu_id: 6,
            name: "Pemetaan Produk",
            en_name: "Product Mapping",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_mapping",
            access_level: 9,
            url: "/product-mapping",
            principal_locked: false,
          },
          {
            id: 13,
            service_menu_id: 6,
            name: "Pemetaan Kategori Produk",
            en_name: "Product Category Mapping",
            sequence: 5,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_category_mapping",
            access_level: 9,
            url: "/product-category-mapping",
            principal_locked: false,
          },
          {
            id: 14,
            service_menu_id: 6,
            name: "Produk Pengganti",
            en_name: "Product Substitute",
            sequence: 6,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_substitute",
            access_level: 9,
            url: "/product-substitute",
            principal_locked: false,
          },
        ],
      },
      {
        id: 7,
        parent_menu_id: 5,
        name: "Distributor",
        en_name: "Distributor",
        sequence: 3,
        icon_name: "",
        menu_code: "master.distributor",
        url: "/distributor",
        access_level: 9,
        background: "",
        menu_item: null,
      },
      {
        id: 8,
        parent_menu_id: 5,
        name: "Gudang",
        en_name: "Warehouse",
        sequence: 4,
        icon_name: "",
        menu_code: "master.warehouse",
        url: "/warehouse",
        access_level: 9,
        background: "",
        menu_item: null,
      },
    ],
  },
  // {
  //   id: 7,
  //   name: "Transaksi",
  //   en_name: "Transaction",
  //   sequence: 7,
  //   icon_name: "",
  //   url: "",
  //   access_level: 9,
  //   background: "",
  //   menu_code: "transaction",
  //   service_menu: null,
  // },
  {
    id: 8,
    name: "Laporan",
    en_name: "Report",
    sequence: 8,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "report",
    service_menu: [
      {
        id: 3,
        parent_menu_id: 8,
        name: "Laporan",
        en_name: "Report",
        sequence: 3,
        icon_name: "",
        menu_code: "report.report",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 18,
            service_menu_id: 3,
            name: "PO",
            en_name: "PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "report.report.po",
            access_level: 9,
            url: "/report-po",
            principal_locked: false,
          },
          // {
          //   id: 19,
          //   service_menu_id: 3,
          //   name: "PO VS SO",
          //   en_name: "PO VS SO",
          //   sequence: 2,
          //   icon_name: "",
          //   type: "",
          //   menu_code: "report.report.po_vs_so",
          //   access_level: 9,
          //   url: "/po-vs-so",
          //   principal_locked: false,
          // },
          {
            id: 20,
            service_menu_id: 3,
            name: "Detil PO",
            en_name: "PO Detail",
            sequence: 3,
            icon_name: "",
            type: "",
            menu_code: "report.report.po_detail",
            access_level: 9,
            url: "/po-detail",
            principal_locked: false,
          },
        ],
      },
      // {
      //   id: 4,
      //   parent_menu_id: 8,
      //   name: "Halaman Utama",
      //   en_name: "Dashboard",
      //   sequence: 4,
      //   icon_name: "",
      //   menu_code: "report.dashboard",
      //   url: "",
      //   access_level: 9,
      //   background: "",
      //   menu_item: null,
      // },
    ],
  },
  {
    id: 9,
    name: "Monitoring",
    en_name: "Monitoring",
    sequence: 9,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "monitoring",
    service_menu: [
      {
        id: 14,
        parent_menu_id: 9,
        name: "Monitoring",
        en_name: "Monitoring",
        sequence: 1,
        icon_name: "",
        menu_code: "monitoring.monitoring",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 22,
            service_menu_id: 14,
            name: "Daftar Proses PO",
            en_name: "List Process PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.list_process_po",
            access_level: 9,
            url: "/list-process-po",
            principal_locked: false,
          },
          {
            id: 23,
            service_menu_id: 14,
            name: "PO",
            en_name: "PO",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.po",
            access_level: 9,
            url: "/monitoring-po",
            principal_locked: false,
          },
          {
            id: 25,
            service_menu_id: 14,
            name: "Koneksi",
            en_name: "Connection",
            sequence: 4,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.connection",
            access_level: 9,
            url: "/connection",
            principal_locked: false,
          },
          {
            id: 26,
            service_menu_id: 14,
            name: "Produk Tidak Terpeta",
            en_name: "Unmapped Product",
            sequence: 5,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.unmapped_product",
            access_level: 9,
            url: "/unmapped-product",
            principal_locked: false,
          },
          {
            id: 27,
            service_menu_id: 14,
            name: "Toko Tidak Terpeta",
            en_name: "Unmapped Store",
            sequence: 6,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.unmapped_store",
            access_level: 9,
            url: "/unmapped-store",
            principal_locked: false,
          },
          {
            id: 29,
            service_menu_id: 14,
            name: "AS2 & SFTP Log",
            en_name: "AS2 & SFTP Log",
            sequence: 8,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.as2_sftp_log",
            access_level: 9,
            url: "/monitoring-as2-sftp-log",
            principal_locked: false,
          },
        ],
      },
    ],
  },
];

export const sellerMenuExcludeDanone = [
  {
    id: 1,
    name: "Beranda",
    en_name: "Home",
    sequence: 1,
    icon_name: "",
    url: "/home",
    access_level: 9,
    background: "",
    menu_code: "home",
    service_menu: null,
  },
  {
    id: 5,
    name: "Master",
    en_name: "Master",
    sequence: 5,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "master",
    service_menu: [
      {
        id: 5,
        parent_menu_id: 5,
        name: "Produk",
        en_name: "Product",
        sequence: 1,
        icon_name: "",
        menu_code: "master.product",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 6,
            service_menu_id: 5,
            name: "Produk Principal",
            en_name: "Product Principal",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_principal",
            access_level: 9,
            url: "/product-principal",
            principal_locked: false,
          },
          {
            id: 7,
            service_menu_id: 5,
            name: "Produk Pelanggan",
            en_name: "Product Customer",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_customer",
            access_level: 9,
            url: "/product-customer",
            principal_locked: false,
          },
        ],
      },
      {
        id: 6,
        parent_menu_id: 5,
        name: "Pemetaan",
        en_name: "Mapping",
        sequence: 2,
        icon_name: "",
        menu_code: "master.mapping",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 9,
            service_menu_id: 6,
            name: "Pemetaan Produk HO",
            en_name: "Product Mapping HO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_mapping_ho",
            access_level: 9,
            url: "/product-mapping-ho",
            principal_locked: false,
          },
          {
            id: 10,
            service_menu_id: 6,
            name: "Pemetaan Produk",
            en_name: "Product Mapping",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_mapping",
            access_level: 9,
            url: "/product-mapping",
            principal_locked: false,
          },
          // {
          //   id: 13,
          //   service_menu_id: 6,
          //   name: "Pemetaan Kategori Produk",
          //   en_name: "Product Category Mapping",
          //   sequence: 5,
          //   icon_name: "",
          //   type: "",
          //   menu_code: "master.mapping.product_category_mapping",
          //   access_level: 9,
          //   url: "/product-category-mapping",
          //   principal_locked: false,
          // },
          // {
          //   id: 14,
          //   service_menu_id: 6,
          //   name: "Produk Pengganti",
          //   en_name: "Product Substitute",
          //   sequence: 6,
          //   icon_name: "",
          //   type: "",
          //   menu_code: "master.mapping.product_substitute",
          //   access_level: 9,
          //   url: "/product-substitute",
          //   principal_locked: false,
          // },
        ],
      },
      {
        id: 7,
        parent_menu_id: 5,
        name: "Distributor",
        en_name: "Distributor",
        sequence: 3,
        icon_name: "",
        menu_code: "master.distributor",
        url: "/distributor",
        access_level: 9,
        background: "",
        menu_item: null,
      },
      {
        id: 8,
        parent_menu_id: 5,
        name: "Gudang",
        en_name: "Warehouse",
        sequence: 4,
        icon_name: "",
        menu_code: "master.warehouse",
        url: "/warehouse",
        access_level: 9,
        background: "",
        menu_item: null,
      },
    ],
  },
  // {
  //   id: 7,
  //   name: "Transaksi",
  //   en_name: "Transaction",
  //   sequence: 7,
  //   icon_name: "",
  //   url: "",
  //   access_level: 9,
  //   background: "",
  //   menu_code: "transaction",
  //   service_menu: null,
  // },
  {
    id: 8,
    name: "Laporan",
    en_name: "Report",
    sequence: 8,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "report",
    service_menu: [
      {
        id: 3,
        parent_menu_id: 8,
        name: "Laporan",
        en_name: "Report",
        sequence: 3,
        icon_name: "",
        menu_code: "report.report",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 18,
            service_menu_id: 3,
            name: "PO",
            en_name: "PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "report.report.po",
            access_level: 9,
            url: "/report-po",
            principal_locked: false,
          },
          // {
          //   id: 19,
          //   service_menu_id: 3,
          //   name: "PO VS SO",
          //   en_name: "PO VS SO",
          //   sequence: 2,
          //   icon_name: "",
          //   type: "",
          //   menu_code: "report.report.po_vs_so",
          //   access_level: 9,
          //   url: "/po-vs-so",
          //   principal_locked: false,
          // },
          {
            id: 20,
            service_menu_id: 3,
            name: "Detil PO",
            en_name: "PO Detail",
            sequence: 3,
            icon_name: "",
            type: "",
            menu_code: "report.report.po_detail",
            access_level: 9,
            url: "/po-detail",
            principal_locked: false,
          },
        ],
      },
      // {
      //   id: 4,
      //   parent_menu_id: 8,
      //   name: "Halaman Utama",
      //   en_name: "Dashboard",
      //   sequence: 4,
      //   icon_name: "",
      //   menu_code: "report.dashboard",
      //   url: "",
      //   access_level: 9,
      //   background: "",
      //   menu_item: null,
      // },
    ],
  },
  {
    id: 9,
    name: "Monitoring",
    en_name: "Monitoring",
    sequence: 9,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "monitoring",
    service_menu: [
      {
        id: 14,
        parent_menu_id: 9,
        name: "Monitoring",
        en_name: "Monitoring",
        sequence: 1,
        icon_name: "",
        menu_code: "monitoring.monitoring",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 22,
            service_menu_id: 14,
            name: "Daftar Proses PO",
            en_name: "List Process PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.list_process_po",
            access_level: 9,
            url: "/list-process-po",
            principal_locked: false,
          },
          {
            id: 23,
            service_menu_id: 14,
            name: "PO",
            en_name: "PO",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.po",
            access_level: 9,
            url: "/monitoring-po",
            principal_locked: false,
          },
          {
            id: 25,
            service_menu_id: 14,
            name: "Koneksi",
            en_name: "Connection",
            sequence: 4,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.connection",
            access_level: 9,
            url: "/connection",
            principal_locked: false,
          },
          {
            id: 26,
            service_menu_id: 14,
            name: "Produk Tidak Terpeta",
            en_name: "Unmapped Product",
            sequence: 5,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.unmapped_product",
            access_level: 9,
            url: "/unmapped-product",
            principal_locked: false,
          },
          {
            id: 27,
            service_menu_id: 14,
            name: "Toko Tidak Terpeta",
            en_name: "Unmapped Store",
            sequence: 6,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.unmapped_store",
            access_level: 9,
            url: "/unmapped-store",
            principal_locked: false,
          },
          {
            id: 29,
            service_menu_id: 14,
            name: "AS2 & SFTP Log",
            en_name: "AS2 & SFTP Log",
            sequence: 8,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.as2_sftp_log",
            access_level: 9,
            url: "/monitoring-as2-sftp-log",
            principal_locked: false,
          },
        ],
      },
    ],
  },
];

export const sellerMenuDanone = [
  {
    id: 1,
    name: "Beranda",
    en_name: "Home",
    sequence: 1,
    icon_name: "",
    url: "/home",
    access_level: 9,
    background: "",
    menu_code: "home",
    service_menu: null,
  },
  {
    id: 5,
    name: "Master",
    en_name: "Master",
    sequence: 5,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "master",
    service_menu: [
      {
        id: 5,
        parent_menu_id: 5,
        name: "Produk",
        en_name: "Product",
        sequence: 1,
        icon_name: "",
        menu_code: "master.product",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 6,
            service_menu_id: 5,
            name: "Produk Principal",
            en_name: "Product Principal",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_principal",
            access_level: 9,
            url: "/product-principal",
            principal_locked: false,
          },
          {
            id: 7,
            service_menu_id: 5,
            name: "Produk Pelanggan",
            en_name: "Product Customer",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "master.product.product_customer",
            access_level: 9,
            url: "/product-customer",
            principal_locked: false,
          },
          // {
          //   id: 8,
          //   service_menu_id: 5,
          //   name: "Kategori Produk",
          //   en_name: "Product Category",
          //   sequence: 3,
          //   icon_name: "",
          //   type: "",
          //   menu_code: "master.product.product_category",
          //   access_level: 9,
          //   url: "/product-category",
          //   principal_locked: false,
          // },
        ],
      },
      {
        id: 6,
        parent_menu_id: 5,
        name: "Pemetaan",
        en_name: "Mapping",
        sequence: 2,
        icon_name: "",
        menu_code: "master.mapping",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 9,
            service_menu_id: 6,
            name: "Pemetaan Produk HO",
            en_name: "Product Mapping HO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_mapping_ho",
            access_level: 9,
            url: "/product-mapping-ho",
            principal_locked: false,
          },
          {
            id: 10,
            service_menu_id: 6,
            name: "Pemetaan Produk",
            en_name: "Product Mapping",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_mapping",
            access_level: 9,
            url: "/product-mapping",
            principal_locked: false,
          },
          {
            id: 11,
            service_menu_id: 6,
            name: "Pemetaan BKL",
            en_name: "BKL Mapping",
            sequence: 3,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.bkl_mapping",
            access_level: 9,
            url: "/bkl-mapping",
            principal_locked: false,
          },
          {
            id: 12,
            service_menu_id: 6,
            name: "Pemetaan Produk BKL",
            en_name: "Product BKL Mapping",
            sequence: 4,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_bkl_mapping",
            access_level: 9,
            url: "/product-bkl-mapping",
            principal_locked: false,
          },
          // {
          //   id: 13,
          //   service_menu_id: 6,
          //   name: "Pemetaan Kategori Produk",
          //   en_name: "Product Category Mapping",
          //   sequence: 5,
          //   icon_name: "",
          //   type: "",
          //   menu_code: "master.mapping.product_category_mapping",
          //   access_level: 9,
          //   url: "/product-category-mapping",
          //   principal_locked: false,
          // },
          {
            id: 14,
            service_menu_id: 6,
            name: "Produk Pengganti",
            en_name: "Product Substitute",
            sequence: 6,
            icon_name: "",
            type: "",
            menu_code: "master.mapping.product_substitute",
            access_level: 9,
            url: "/product-substitute",
            principal_locked: false,
          },
        ],
      },
      {
        id: 7,
        parent_menu_id: 5,
        name: "Distributor",
        en_name: "Distributor",
        sequence: 3,
        icon_name: "",
        menu_code: "master.distributor",
        url: "/distributor",
        access_level: 9,
        background: "",
        menu_item: null,
      },
      {
        id: 8,
        parent_menu_id: 5,
        name: "Gudang",
        en_name: "Warehouse",
        sequence: 4,
        icon_name: "",
        menu_code: "master.warehouse",
        url: "/warehouse",
        access_level: 9,
        background: "",
        menu_item: null,
      },
    ],
  },
  {
    id: 7,
    name: "Transaksi",
    en_name: "Transaction",
    sequence: 7,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "transaction",
    service_menu: [
      {
        id: 12,
        parent_menu_id: 7,
        name: "SO",
        en_name: "SO",
        sequence: 1,
        icon_name: "",
        menu_code: "transaction.so",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 15,
            service_menu_id: 12,
            name: "Impor SO",
            en_name: "Import SO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "transaction.so.import_so",
            access_level: 9,
            url: "/import-so",
            principal_locked: false,
          },
        ],
      },
      {
        id: 13,
        parent_menu_id: 7,
        name: "PO",
        en_name: "PO",
        sequence: 2,
        icon_name: "",
        menu_code: "transaction.po",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 16,
            service_menu_id: 13,
            name: "Proses Persetujuan PO",
            en_name: "Process Approve PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "transaction.po.process_approve_po",
            access_level: 9,
            url: "/process-approve-po",
            principal_locked: false,
          },
          {
            id: 17,
            service_menu_id: 13,
            name: "Rangkuman BKL",
            en_name: "BKL Summary",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "transaction.po.bkl_summary",
            access_level: 9,
            url: "/bkl-summary",
            principal_locked: false,
          },
        ],
      },
    ],
  },
  {
    id: 8,
    name: "Laporan",
    en_name: "Report",
    sequence: 8,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "report",
    service_menu: [
      {
        id: 3,
        parent_menu_id: 8,
        name: "Laporan",
        en_name: "Report",
        sequence: 3,
        icon_name: "",
        menu_code: "report.report",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 18,
            service_menu_id: 3,
            name: "PO",
            en_name: "PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "report.report.po",
            access_level: 9,
            url: "/report-po",
            principal_locked: false,
          },
          {
            id: 19,
            service_menu_id: 3,
            name: "PO VS SO",
            en_name: "PO VS SO",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "report.report.po_vs_so",
            access_level: 9,
            url: "/po-vs-so",
            principal_locked: false,
          },
          {
            id: 20,
            service_menu_id: 3,
            name: "Detil PO",
            en_name: "PO Detail",
            sequence: 3,
            icon_name: "",
            type: "",
            menu_code: "report.report.po_detail",
            access_level: 9,
            url: "/po-detail",
            principal_locked: false,
          },
        ],
      },
      {
        id: 4,
        parent_menu_id: 8,
        name: "Halaman Utama",
        en_name: "Dashboard",
        sequence: 4,
        icon_name: "",
        menu_code: "report.dashboard",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 21,
            service_menu_id: 4,
            name: "Rangkuman",
            en_name: "Summary",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "report.dashboard.summary",
            access_level: 9,
            url: "/dashboard-summary",
            principal_locked: false,
          },
        ],
      },
    ],
  },
  {
    id: 9,
    name: "Monitoring",
    en_name: "Monitoring",
    sequence: 9,
    icon_name: "",
    url: "",
    access_level: 9,
    background: "",
    menu_code: "monitoring",
    service_menu: [
      {
        id: 14,
        parent_menu_id: 9,
        name: "Monitoring",
        en_name: "Monitoring",
        sequence: 1,
        icon_name: "",
        menu_code: "monitoring.monitoring",
        url: "",
        access_level: 9,
        background: "",
        menu_item: [
          {
            id: 22,
            service_menu_id: 14,
            name: "Daftar Proses PO",
            en_name: "List Process PO",
            sequence: 1,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.list_process_po",
            access_level: 9,
            url: "/list-process-po",
            principal_locked: false,
          },
          {
            id: 23,
            service_menu_id: 14,
            name: "PO",
            en_name: "PO",
            sequence: 2,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.po",
            access_level: 9,
            url: "/monitoring-po",
            principal_locked: false,
          },
          {
            id: 25,
            service_menu_id: 14,
            name: "Koneksi",
            en_name: "Connection",
            sequence: 4,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.connection",
            access_level: 9,
            url: "/connection",
            principal_locked: false,
          },
          {
            id: 26,
            service_menu_id: 14,
            name: "Produk Tidak Terpeta",
            en_name: "Unmapped Product",
            sequence: 5,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.unmapped_product",
            access_level: 9,
            url: "/unmapped-product",
            principal_locked: false,
          },
          {
            id: 27,
            service_menu_id: 14,
            name: "Toko Tidak Terpeta",
            en_name: "Unmapped Store",
            sequence: 6,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.unmapped_store",
            access_level: 9,
            url: "/unmapped-store",
            principal_locked: false,
          },
          {
            id: 29,
            service_menu_id: 14,
            name: "AS2 & SFTP Log",
            en_name: "AS2 & SFTP Log",
            sequence: 8,
            icon_name: "",
            type: "",
            menu_code: "monitoring.monitoring.as2_sftp_log",
            access_level: 9,
            url: "/monitoring-as2-sftp-log",
            principal_locked: false,
          },
        ],
      },
    ],
  },
];
