/**
 * Function that create filter string that used for params on list or search
 * 
 * @param {Object} filters - filter object that contains 'key' as search key and 'value' as closure or property and value, example { product_machine: "like MACH1"}
 * @returns {string} - return string that containt all filter converted from object
 * 
 * @author Bayu
 */
 export const formatFilter = (filters = {}) => {
  let filterArr = [];
  for (let key in filters) {
    
    if (typeof filters[key] === 'string' && filters[key] && filters[key].trim().length > 0) {
      if (filters[key].replaceAll(/(like|range|eq|not_list_id|null|undefined|list_id)/ig, '').trim().length === 0) continue;
      filterArr.push(`${key} ${filters[key]}`);
    }
  }

  return filterArr.join(", ");
}

/**
 * Function that create order string that used for params on list and initate
 * @param {object} orders - [ { columnKey: <id>, order: <false/true> } ]
 * @returns {string} - return string that containt all order
 */
export const formatOrder = (order = {}) => {
  if (!Boolean(order?.order)) return '';
  return `${order.columnKey} ${order.order === 'descend' ? 'desc' : 'asc'}`;
}

/**
 * Function make first letter capitalize like PHP ucFirst
 * @param {string} sentences 
 * @returns
 */
 const ucFirst = (sentences) => {
  if(!sentences || typeof sentences !== 'string') return '';
  return sentences.trim().split(' ').map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }).join(',')
}

const StringUtil = {
  formatFilter,
  formatOrder,
  ucFirst
}

export default StringUtil;
