import { CheckStatus } from '../constant/permissionConstant';

/* ============ Checbox Tree Helper ============ */

export const setCheckedValueForNodeAndChildren = (node, value, permission, isChecked, isSelectAll = null) => {
  const { key: keyPermission } = permission;

  let newPermissionArr = [];
  // we need include check status
  if (isSelectAll !== null && isSelectAll !== undefined ) {
    newPermissionArr = node.permission.map((each) => ({
      ...each,
      status: isSelectAll ? CheckStatus.CHECKED : CheckStatus.UNCHECKED
    }))
  } else {
    const permissionIndex = node.permission.findIndex((permissionItem) => {
      const [targetKeyComplete, targetMainKey] = keyPermission.split(':');
      const [keyComplete, mainKey] = permissionItem.key.split(':');
      return targetMainKey ? mainKey === targetMainKey : keyComplete === targetKeyComplete;
    });
    try {
    newPermissionArr = [...node.permission];
    newPermissionArr[permissionIndex].status = isChecked
      ? CheckStatus.CHECKED
      : CheckStatus.UNCHECKED;
    } catch(ignore) {}
      
  }

  if (node.children) {
    return {
      ...node,
      permission: newPermissionArr,
      children: node.children.map((nodeChildren) =>
        setCheckedValueForNodeAndChildren(nodeChildren, value, permission, isChecked, isSelectAll)
      )
    };
  } else {
    return {
      ...node,
      permission: newPermissionArr
    };
  }
};

export const toggleNodeTree = (value, node, permission, isChecked, isSelectAll = null) => {
  const { key: keyValue } = value;
  const z = node.map((item) => {
    const { key: keyItem } = item;

    if (keyValue === keyItem) {
      const c = setCheckedValueForNodeAndChildren(item, value, permission, isChecked, isSelectAll);

      return c;
    }

    if (item.children) {
      const nodeTree = toggleNodeTree(value, item.children, permission, isChecked, isSelectAll);

      return {
        ...item,
        children: nodeTree
      };
    }

    return item;
  });

  const zz = z.map((item) => {
    // 1. jalankan fungsi check permission every children, every permission
    const valueArrays_ = [];
    const checkChildPermission_ = (node, permission, value) => {
      const { key: targetKeyPermission } = permission;
      const [targetKeyComplete, targetMainKey] = targetKeyPermission.split(':');
      const targetKey = targetMainKey ?? targetKeyComplete;

      if (node.children) {
        const x = node.children.map((nodeChild) => {
          return checkChildPermission_(nodeChild, permission, value);
        });

        return x.some(Boolean);
      } else {
        const selectedPermission = node.permission.findIndex((permissionItem) => {
          const { key: keyPermission } = permissionItem;
          const [keyComplete, mainKey] = keyPermission.split(':');
          const key = mainKey ?? keyComplete;

          return key === targetKey;
        });

        return node.permission[selectedPermission]?.status === CheckStatus.CHECKED;
      }
    };

    // 2. Jika semua children status === 'checked', buat current node sebagai parent dan ubah permission
    // menjadi status = 'checked' juga
    const cek_ = checkChildPermission_(item, permission, node);

    const { key: targetKeyPermission } = permission;
    const [targetKeyComplete, targetMainKey] = targetKeyPermission.split(':');
    const targetKey = targetMainKey ?? targetKeyComplete;

    const selectedPermission = item.permission.findIndex((permissionItem) => {
      const { key: keyPermission } = permissionItem;
      const [keyComplete, mainKey] = keyPermission.split(':');
      const key = mainKey ?? keyComplete;

      return key === targetKey;
    });

    if (selectedPermission >= 0) {
      item.permission[selectedPermission].status = cek_
        ? CheckStatus.CHECKED
        : CheckStatus.UNCHECKED;
    }

    return item;
  });

  return zz;
};

export const toggleExpandedNodeTree = (node, treeRole) => {
  const { key: keyValue } = node;
  return treeRole.map((item) => {
    const { key: keyItem } = item;

    if (keyValue === keyItem) {
      if (node.hasOwnProperty('isExpanded')) {
        item.isExpanded = !item.isExpanded;
      }
      return item;
    }

    if (item.children) {
      const nodeTree = toggleExpandedNodeTree(node, item.children);

      return {
        ...item,
        children: nodeTree
      };
    }

    return item;
  });
};

// const checkExsistGlobalPermission = (defaultPermission) => {

// }

/**
 * 
 * @param {Function} onChangeCallback - function that handle on change
 * @param {RegExp} regex - regex that will be replaced
 * @returns 
 */
export const regexFormatter = (onChangeCallback, regex) => {
  return (event) => {
    const value = event.target.value?.replace(regex, '');
    onChangeCallback(value, event.target.name);
  }
}

export const regexFormatterNative = (onChangeCallback, regex) => {
  return (event) => {
    event.target.value = event.target.value?.replace(regex, '');
    onChangeCallback(event);
  }
}
