import {
  Box, Text
} from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_ROLE } from "../../../../../constant/idConstant";
import { buttonVariant } from "../../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../../constant/routeConstant";
import {
  selectLevel, selectStatus
} from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import { roleAction } from "../../../../../services/main/roleReducer";
import { editRoleSchema } from "../../roleSchema";
const BodyRole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { company_name } = useSelector((state) => state.auth);
  const { principal_id } = useSelector((state) => state.auth);
  const { index_page } = useSelector((state) => state.role);
  const { role: detail } = useSelector((state) => state.role.detail_role);

  const toNextPage = () => {
    dispatch(roleAction.setCurrPage(t("ROLE:FIELD_PERMISSION")));
    dispatch(roleAction.setIndexPage(index_page + 1));
    dispatch(roleAction.setDetailRoleRole(values));
  };

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      role_id: detail?.role_id,
      description: detail?.description,
      level: detail?.level,
      status: detail?.status,
      account_id: principal_id,
    },
    validationSchema: editRoleSchema(t),
    onSubmit: toNextPage,
    isInitialValid: true,
    enableReinitialize: true,
  });
  return (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* Role  */}
        <Box padding={1} display="flex">
          <Box width="10rem" marginTop={2}>
            {t("ROLE:TITLE_LIST")}
          </Box>
          <Box className="my-select-container">
            <Input
              type="text"
              width="250px"
              name="role_id"
              onChange={handleChange}
              id={ID_ROLE.EDIT.ROLE}
              onBlur={handleBlur}
              autoComplete="false"
              style={inputTheme}
              // backgroundColor={username_auth !== '' ? GRAY_COLOR : WHITE_COLOR}
              value={values?.role_id}
            />
            {errors.role_id && touched.role_id && (
              <ValidationError text={errors.role_id} />
            )}
          </Box>
        </Box>
        {/* Farmindo Account  */}
        <Box padding={1} display="flex">
          <Box width="10rem" marginTop={2}>
            {t("ROLE:FIELD_FARMINDO_ACCOUNT")}
          </Box>
          <Box className="my-select-container">
            <Input
              type="text"
              width="250px"
              name="account_id"
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="false"
              style={inputTheme}
              disabled
              // backgroundColor={username_auth !== '' ? GRAY_COLOR : WHITE_COLOR}
              value={company_name}
            />
          </Box>
        </Box>
        {/* Description  */}
        <Box padding={1} display="flex">
          <Box width="10rem" marginTop={2}>
            {t("ROLE:FIELD_DESCRIPTION")}
          </Box>
          <Box className="my-select-container">
            <Input
              type="text"
              width="250px"
              name="description"
              id={ID_ROLE.EDIT.DESCRIPTION}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="false"
              style={inputTheme}
              // backgroundColor={username_auth !== '' ? GRAY_COLOR : WHITE_COLOR}
              value={values?.description}
            />
            {errors.description && touched.description && (
              <ValidationError text={errors.description} />
            )}
          </Box>
        </Box>
        {/* Role Level */}
        <Box padding={1} display="flex">
          <Box width="10rem">
            <Text>{t("ROLE:FIELD_ROLE_LEVEL")}</Text>
          </Box>
          <Box className="my-select-container">
            <Select
              style={inputTheme}
              showSearch={false}
              name="level"
              id={ID_ROLE.EDIT.LEVEL}
              disabled
              value={values?.level}
              allowClear="true"
              onChange={(value) => setFieldValue("level", value)}
              size="large"
            >
              {selectLevel?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value}>
                    {value}
                  </option>
                );
              })}
            </Select>
          </Box>
        </Box>
        {/* Status */}
        <Box padding={1} display="flex">
          <Box width="10rem">
            <Text>{t("ROLE:FIELD_STATUS")}</Text>
          </Box>
          <Box className="my-select-container">
            <Select
              style={inputTheme}
              id={ID_ROLE.EDIT.STATUS}
              showSearch={false}
              name="status"
              value={values?.status}
              onChange={(value) => setFieldValue("status", value)}
              size="large"
            >
              {selectStatus?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value.value}>
                    {t(value.label)}
                  </option>
                );
              })}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={4} float="right">
        <ButtonCustom
          colorScheme="blue"
          variant={buttonVariant.outline}
          marginRight={2}
          width="9.375rem"
          link={ROUTES.ROLE.LIST}
          id={ID_ROLE.EDIT.BTN_CANCEL}
          text={t("COMMON:BUTTON_CANCEL")}
        />
        <ButtonPrimary
          text={t("COMMON:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          isDisabled={!isValid}
        />
      </Box>
    </form>
  );
};
export default BodyRole;
