import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import {
  API_URL,
  API_VERSION,
  API_PREFIX,
} from "../../../constant/apiConstant";

const INITIAL_STATE = {
  list_report_po: [],
  printed_po: [],
  printed_loading: false,
  list_distributor: [],
};

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListWarehouse = createAsyncThunk(
  "getListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-connection-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListReportPO = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/po/report",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po/report`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doGet(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitListReportPO = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/po/report/initiate",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po/report/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const printReportPO = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/po/report/print",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po/report/print`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPostResponseBlob(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const reportPO = createSlice({
  name: "createReportPO",
  initialState: INITIAL_STATE,
  reducers: {
    resetDetailReportPO() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListReportPO.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListReportPO.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_report_po = action.payload.response;
    },
    [getListReportPO.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    // distributor
    [getListDistributor.pending]: (state) => {
      state.status = "loading";
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_distributor = action.payload.response;
    },
    [getListDistributor.rejected]: (state) => {
      state.status = "failed";
    },

    // distributor
    [printReportPO.pending]: (state) => {
      state.printed_loading = true;
    },
    [printReportPO.fulfilled]: (state, action) => {
      state.printed_loading = false;
    },
    [printReportPO.rejected]: (state) => {
     state.printed_loading = false;
    },
  },
});

export const reportPOReducerAction = reportPO.actions;

export default reportPO.reducer;
