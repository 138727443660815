import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list_reprocessed_po: [],
  detail_processed_po: [],
  selectedRowKeys: [],
  status_reprocess: null,
};

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListReprocessedPO = createAsyncThunk(
  "getListReprocessedPO",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/reprocess-po/list`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doGet(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);

export const getInitListReprocessedPO = createAsyncThunk(
  "getInitListReprocessedPO",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/reprocess-po/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);



export const reprocessReprocessedPO = createAsyncThunk(
  "reprocessReprocessedPO",
  async (body, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/reprocess-po`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const reprocessedPO = createSlice({
  name: "createReprocessedPO",
  initialState: INITIAL_STATE,
  reducers: {
    resetDetailReprocessedPO() {
      return INITIAL_STATE;
    },
    resetselectedRowKeysReprocessedPO(state) {
      state.selectedRowKeys=[]
    },
    setSingleCheck(state, action) {
      // if (!payload || typeof payload !== 'number')
      let tempSelected = [...state.selectedRowKeys];
      const isExist = state.selectedRowKeys?.find(
        (data) => data.id === action.payload.id
      );
      if (isExist) {
        tempSelected = tempSelected.filter(
          (data) => data.id !== action.payload.id
        );
        state.selectedRowKeys = tempSelected;
        return;
      }
      state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
    },
    setAllCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      let tempsId = action.payload;
      let isExist = false;

      for (const data of tempsId) {
        isExist = false;
        for (const existingId of state.selectedRowKeys) {
          if (data.id === existingId.id) {
            isExist = true;
            break;
          }
        }
        if (!isExist) break;
      }

      if (isExist) {
        for (const data of tempsId) {
          tempSelected = tempSelected.filter((item) => item.id !== data.id);
          state.selectedRowKeys = tempSelected;
        }
      } else {
        for (const data of tempsId) {
          const isExistOnRedux = state.selectedRowKeys?.find(
            (item) => item.id === data.id
          );
          if (!isExistOnRedux) {
            state.selectedRowKeys = [...state.selectedRowKeys, data];
          }
        }
        // state.selectedRowKeys = Array.from(new Set([...state.selectedRowKeys, ...tempsId]));
      }
    },
  },
  extraReducers: {
    [getListReprocessedPO.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListReprocessedPO.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_reprocessed_po = action.payload.response;
    },
    [getListReprocessedPO.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [reprocessReprocessedPO.pending]: (state, action) => {
      state.status = "loading";
    },
    [reprocessReprocessedPO.fulfilled]: (state, action) => {
      state.status = "success";
      state.status_reprocess = action.payload.response;
    },
    [reprocessReprocessedPO.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const reprocessedPOAction = reprocessedPO.actions;

export default reprocessedPO.reducer;
