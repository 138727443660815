import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addProductMappingHoSchema = (t) => {
  return () =>
    yup.object().shape({
      principal_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_NAME"),
          })
        ),
      customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_NAME"),
          })
        ),
      product_principal_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_NAME"),
          })
        ),
      product_customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_PRODUCT_NAME"),
          })
        ),
      barcode: yup
        .string()
        .max(
          30,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: 30,
          })
        )
        .matches(
          REGEX_CONSTANT.ALPHANUMERIC_WITH_SPACE,
          t("PRODUCT_MAPPING_HO:ERROR_BARCODE")
        ),
      additional_barcode: yup
        .string()
        .max(30)
        .matches(
          REGEX_CONSTANT.ALPHANUMERIC_WITH_SPACE,
          t("PRODUCT_MAPPING_HO:ERROR_BARCODE")
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_MAPPING_HO:FIELD_STATUS"),
        })
      ),
      uom_1: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_2: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_3: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_4: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      order_unit: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.ORDER_UNIT,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_ORDER_UNIT")
        ),
      conversion_1_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
      conversion_2_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
      conversion_3_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
    });
};

export const editProductMappingHoSchema = (t) => {
  return () =>
    yup.object().shape({
      principal_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_NAME"),
          })
        ),
      customer_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_NAME"),
          })
        ),
      product_principal_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_NAME"),
              })
            ),
        }),
      product_customer_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_PRODUCT_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_MAPPING_HO:FIELD_CUSTOMER_PRODUCT_NAME"),
              })
            ),
        }),
      barcode: yup
        .string()
        .max(
          30,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: 30,
          })
        ),
      additional_barcode: yup
        .string()
        .max(30)
        .matches(
          REGEX_CONSTANT.ALPHANUMERIC_WITH_SPACE,
          t("PRODUCT_MAPPING_HO:ERROR_BARCODE")
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_MAPPING_HO:FIELD_STATUS"),
        })
      ),
      uom_1: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_2: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_3: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_4: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      order_unit: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.ORDER_UNIT,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_ORDER_UNIT")
        ),
      conversion_1_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
      conversion_2_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
      conversion_3_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
    });
};
