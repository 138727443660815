import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackToList from "../../../../components/BackToList";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  BlueEditIcon,
  DeleteSuccess, RedDeleteIcon,
  SavedSuccess
} from "../../../../components/icons";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import { ID_NOTIFICATION_MASTER } from "../../../../constant/idConstant";
import { PRIMARY_COLOR } from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { deleteNotification, detailNotification } from "../../../../services/main/notificationReducer";

const DetailNotification = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [deletedStore, setDeletedStore] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const detailData = useSelector(
    (data) =>
      data.notification.detail_notification
  );
  const fetchData = async () => {
    dispatch(detailNotification(location.state));
  };

  useEffect(() => {
    fetchData();

  }, []);

  // fungsi modal delete
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.NOTIFICATION.LIST);
    setModalOpen(false);
  };
  const modalFailed = () => {
    setModalConfirm(false);
    setModalOpen(false);
  };
  const confirmModal = () => {
    setModalConfirm(true);
    setMessageConfirm(t("COMMON:DELETE_CONFIRM"));
  };

  // fungsi delete
  const confirmedDelete = () => {
    setModalConfirm(false);
    const Id = location.state;
    const updated_at = detailData.updated_at;
    const params = { Id, updated_at };
    dispatch(deleteNotification(params)).then((resp) => {
      if (resp.payload.ok) {
        setErr(true);
        setModalOpen(true);
        setResponse(resp.payload.response);
        setDeletedStore(true);
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response);
        setErr(true);
      }
    });
  };

  const renderFailed = () => {
    if (detailData.category === "FAILED") {
      return (
        <>
          {/* EMAIL ALERT TO */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("NOTIFICATION:FIELD_EMAIL_ALERT_TO")}</Text>
            </Box>
            <Text>
              <b style={{ width: "20rem" }}>
                {" "}
                {detailData.alert_to === ""
                  ? "-"
                  : detailData.alert_to}{" "}
              </b>
            </Text>
          </Box>
          {/* ALERT CC */}
          <Box padding={1} width="20rem">
            <Box>{t("NOTIFICATION:FIELD_EMAIL_ALERT_CC")}</Box>
            <Box>
              <Text display="flex">
                <b style={{ width: "20rem" }}>
                  {detailData.alert_cc === ""
                    ? "-"
                    : detailData.alert_cc}
                </b>
              </Text>
            </Box>
          </Box>
        </>
      )
    }
  }

  const renderSuccess = () => {
    if (detailData.category === "SUCCESS") {
      return (
        <>
          {/*  NOTIFICATION TO */}
          <Box padding={1} width="20rem">
            <Box>{t("NOTIFICATION:FIELD_NOTIFICATION_PO_TO")}</Box>
            <Box  >
              <Text display="flex">
                <b style={{ width: "20rem" }}>
                  {" "}
                  {detailData.notification_po_to === ""
                    ? "-"
                    : detailData.notification_po_to}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* NOTIFICATION CC */}
          <Box padding={1} width="20rem">
            <Box>{t("NOTIFICATION:FIELD_NOTIFICATION_PO_CC")}</Box>
            <Box>
              <Text display="flex">
                <b style={{ width: "20rem" }}>
                  {" "}
                  {detailData.notification_po_cc === ""
                    ? "-"
                    : detailData.notification_po_cc}{" "}
                </b>
              </Text>
            </Box>
          </Box>
        </>
      )
    }
  }
  const dataCategory = () => {
    if (detailData.category === "SUCCESS") {
      return (
        <>
          {t("NOTIFICATION:SUCCESS")}</>
      )
    } else {
      return (
        <>
          {t("NOTIFICATION:FAILED")}</>
      )
    }
  }

  const dataSubCategory = () => {
    if (detailData.sub_category === "All") {
      return (
        <>
          {t("NOTIFICATION:ALL")}</>
      )
    } else if (detailData.sub_category === "STORE_NOT_FOUND") {
      return (
        <>
          {t("NOTIFICATION:STORE_NOT_FOUND")}</>
      )
    } else if (detailData.sub_category === "PRODUCT_NOT_FOUND") {
      return (
        <>
          {t("NOTIFICATION:PRODUCT_NOT_FOUND")}</>
      )
    } else if (detailData.sub_category === "BKL_MIXED_PRODUCT") {
      return (
        <>
          {t("NOTIFICATION:BKL_MIXED_PRODUCT")}</>
      )
    } else if (detailData.sub_category === "PDF_NOT_FOUND") {
      return (
        <>
          {t("NOTIFICATION:PDF_NOT_FOUND")}</>
      )
    } else {
      return (
        <>
          -</>
      )
    }
  }
  return (
    <Box p={4}>
      <HeadTitle
        title={t("NOTIFICATION:DETAIL_NOTIFICATION")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_3")}
            </span>{" "}-{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:TITLE_LIST")}
            </span>{" "}
            - {t("NOTIFICATION:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES_USER_BUYER.NOTIFICATION.EDIT,
                state: location.state,
              }}
              id={ID_NOTIFICATION_MASTER.DETAIL.BTN_EDIT}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={confirmModal}
              id={ID_NOTIFICATION_MASTER.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_BUYER.NOTIFICATION.LIST}
        name={detailData.created_name}
        date={moment(detailData.created_on).format("DD/MM/YYYY")}
        modifiedDate={moment(detailData.modified_on).format(
          "DD/MM/YYYY"
        )}
        id={ID_NOTIFICATION_MASTER.DETAIL.BTN_BACK}
        modifiedBy={detailData.updated_name}
      />
      {/* White Box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        display="flex"
        marginTop={2}
        borderRadius="10px"
        gap="16px"
      >
        {/* left side */}
        <Box
          minWidth="37vw"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="16px"
        >
          {/* CATEGORY */}
          <Box padding={1} width="20rem">
            <Box>{t("NOTIFICATION:FIELD_CATEGORY")}</Box>
            <Box>
              <Text>
                <b>
                  {detailData.category === ""
                    ? "-"
                    : dataCategory()}
                </b>
              </Text>
            </Box>
          </Box>
          {/* SUB-CATEGORY*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>
                {t("NOTIFICATION:FIELD_SUB_CATEGORY")}
              </Text>
              <Text>
                <b>
                  {detailData.sub_category === ""
                    ? "-"
                    : dataSubCategory()}
                </b>
              </Text>
            </Box>
          </Box>
          {/* PRINCIPAL CODE*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("NOTIFICATION:FIELD_PRINCIPAL_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {detailData.principal_code === ""
                  ? "-"
                  : detailData.principal_code}
              </b>
            </Text>
          </Box>
          {/* PRINCIPAL NAME*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("NOTIFICATION:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {detailData.principal_name === ""
                  ? "-"
                  : detailData.principal_name}
              </b>
            </Text>
          </Box>
          {/* DISTRIBUTOR CODE*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("NOTIFICATION:FIELD_DISTRIBUTOR_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {detailData.distributor_code === ""
                  ? "-"
                  : detailData.distributor_code}
              </b>
            </Text>
          </Box>
          {/* DISTRIBUTOR NAME*/}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("NOTIFICATION:FIELD_DISTRIBUTOR_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {detailData.distributor_name === ""
                  ? "-"
                  : detailData.distributor_name}
              </b>
            </Text>
          </Box>
          {/* notification CODE*/}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>
                {t("NOTIFICATION:FIELD_NOTIFICATION_CODE")}
              </Text>
            </Box>
            <Text>
              <b>
                {detailData.notification_code === ""
                  ? "-"
                  : detailData.notification_code}
              </b>
            </Text>
          </Box>
          {/* NOTIFICATION NAME */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("NOTIFICATION:FIELD_NOTIFICATION_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {detailData.name === ""
                  ? "-"
                  : detailData.name}
              </b>
            </Text>
          </Box>
        </Box>

        {/* Right side */}
        <Box
          width="329px"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="16px"
        >
          {renderSuccess()}
          {renderFailed()}


          {/* status  */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("NOTIFICATION:FIELD_STATUS")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {detailData.status === "A"
                  ? "Active"
                  : "Non Active"}{" "}
              </b>
            </Text>
          </Box>
        </Box>
        <ModalConfirm
          isOpen={modalConfirm}
          messageModal={t("COMMON:DELETE_TITLE")}
          messageConfirm={messageConfirm}
          onOk={confirmedDelete}
          onClose={modalFailed}
        />
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={deletedStore ? modalSuccess : modalFailed}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Box>
    </Box>
  );
};

export default DetailNotification;
