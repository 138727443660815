import {
  Badge, Box, Center, Divider, Flex, Stack, Text
} from "@chakra-ui/react";
import { message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import HeadTitle from "../../../../components/HeadTitle";
import { SavedSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import CenteredSpinner from "../../../../components/spinner/CenteredSpinner";
import {
  activationStatusOptions, badgeColor, BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../constant/routeConstant";
import {
  addDataGroups, dataGroupAction, detailDataGroups
} from "../../../../services/main/dataGroupReducer";
import StringUtil from "../../../../utils/stringUtil";
import BodyDataGroup from "./body/BodyDataGroup";
import BodyDataScope3 from "./body/BodyDataScope3";

const allOption = {
  value: '_all',
  label: 'Select All'
}

const EditDataGroup = () => {
  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { detail_data_group } = useSelector((state) => state.dataGroup);
  const { curr_page } = useSelector((state) => state.dataGroup);
  const { index_page } = useSelector((state) => state.dataGroup);
  const [modalOpen, setModalOpen] = useState(false);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [currentPage, setCurrentPage] = useState(t("DATA_GROUP:DATAGROUP"));
  const [indexPage, setIndexPage] = useState(0);

  const [is_loading_detail, setIs_loading_detail] = useState(false);
  // const changePageDataGroup = () => {
  //   if (currentPage !== "DataGroup") {
  //     setCurrentPage("DataGroup");
  //     setIndexPage(indexPage - 1);
  //   } else {
  //     setCurrentPage("DataScope");
  //     setIndexPage(indexPage + 1);
  //   }
  // };

  // const onSubmitDataGroup = () => {
  //   dispatch(addDataGroups()).then((res) => {
  //     if (res.payload.ok) {
  //       setModalOpen(true);
  //     } else if (res.payload.ok === false) {
  //       message.error(res.payload.response.data.nexsoft.payload.status.message);
  //     }
  //   });
  // };
  const clickOkOnModal = () => {
    setNewDataAdded(true);
    resetReduxDataGroup();
  };
  const resetReduxDataGroup = () => {
    dispatch(dataGroupAction.resetReduxDataGroup());
  };
  const setCurrPage = () => {
    dispatch(dataGroupAction.setCurrPage(t("DATA_GROUP:DATAGROUP")));
  };

  const renderMainBody = () => {
    {/* content of body  */ }

    if (curr_page === t("DATA_GROUP:DATAGROUP")) {
      return <BodyDataGroup />;
    }
    if (curr_page === t("DATA_GROUP:DATASCOPE")) {
      // return <BodyDataScope />;
      return <BodyDataScope3 />;
      // return <></>
    }
  };

  const initializeFormData = useCallback(() => {
    if (detail_data_group) {
      const {
        group_id,
        level,
        description,
        status,
        updated_at,
        distributor_scope,
        principal_scope,
        customer_scope,
        warehouse_scope,
        store_scope,
        scope
      } = detail_data_group;

      let isHaveWarehouse = false;
      try {
        const scopes = JSON.parse(scope);
        isHaveWarehouse = Boolean(scopes['farmindo.warehouse']);
      } catch (err) { }

      const initialformValue = {
        group_id,
        level: { value: level, label: level },
        description,
        status: status === "A" ? activationStatusOptions[0] : activationStatusOptions[1],
        updated_at,
        principal_branch: [allOption],
        customer: [allOption],
        store: [allOption],
        warehouse: [],
        principal: allOption,
        po_status: null
      }

      if (principal_scope) {
        initialformValue.principal = {
          value: principal_scope[0].id,
          label: principal_scope[0].principal_name
        }
      }

      if (distributor_scope) {
        initialformValue.principal_branch = distributor_scope.map((dist) => ({
          value: dist.id,
          label: dist.distributor_name
        }));
      } else {
        initialformValue.principal_branch = [allOption];
      }

      if (customer_scope) {
        initialformValue.customer = customer_scope.map((cust) => ({
          value: cust.id,
          label: cust.customer_name
        }));
      } else {
        initialformValue.customer = [allOption];
      }

      if (warehouse_scope) {
        initialformValue.warehouse = warehouse_scope.map((wrhs) => ({
          value: wrhs.id,
          label: wrhs.warehouse_name
        }));
      } else {
        if (isHaveWarehouse) {
          initialformValue.warehouse = [allOption];
        }
      }

      if (store_scope) {
        initialformValue.store = store_scope.map((str) => ({
          value: str.id,
          label: str.store_name
        }));
      } else {
        initialformValue.store = [allOption];
      }

      if (scope) {
        const scopes = JSON.parse(scope);
        const poStatusScope = scopes['farmindo.po_status'];
        if (poStatusScope) {
          if (poStatusScope[0] === "_all") {
            initialformValue.po_status = {
              value: "_all",
              label: "All"
            }
          } else {
            initialformValue.po_status = {
              value: poStatusScope[0],
              label: StringUtil.ucFirst(poStatusScope[0])
            }
          }
        }
      }

      dispatch(dataGroupAction.setDataScope(initialformValue));
    }

  }, [detail_data_group, dispatch]);

  const fetchData = () => {
    setIs_loading_detail(true);
    dispatch(detailDataGroups(location.state.id)).then((response) => {
      setIs_loading_detail(false);
    });
    
  };
  useEffect(() => {
    resetReduxDataGroup();
    setCurrPage();
    fetchData();
  }, []);

  useEffect(() => {
    initializeFormData();
  }, [initializeFormData])

  return newDataAdded ? (
    <Redirect to={ROUTES.DATA_GROUP.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("DATA_GROUP:EDIT_DATA_GROUP")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("COMMON:HOME")}</span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DATA_GROUP:TITLE_LIST")}
            </span>{" "}
            - {t("DATA_GROUP:EDIT_DATA_GROUP")}
          </>
        }
      />
      <Box marginTop={6} marginBottom={3} minHeight="4.375rem">
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <Box
            backgroundColor="white"
            width={320}
            height="64px"
            borderRadius="8px"
            display="flex"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.31)"
          >
            <Stack direction="row" p={4} my={1}>
              {/* data group  */}
              <Badge
                borderRadius="full"
                width="24px"
                height="24px"
                variant="solid"
                colorScheme={
                  index_page >= 0
                    ? badgeColor.primaryColor
                    : badgeColor.primaryGray
                }
              >
                <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                  <Center>{t("DATA_GROUP:NUMBER_1")}</Center>
                </Text>
              </Badge>
              <Flex height="64x" justifyContent="center">
                <Text
                  fontWeight={700}
                  fontSize={16}
                  marginStart={2}
                  color={index_page >= 0 ? PRIMARY_COLOR : BLACK_COLOR}
                >
                  {t("DATA_GROUP:DATA_GROUP")}
                </Text>
              </Flex>
              {/* end of data group  */}
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* data scope  */}
              <Badge
                borderRadius="full"
                width="24px"
                height="24px"
                px="2"
                variant="solid"
                colorScheme={
                  index_page === 1
                    ? badgeColor.primaryColor
                    : badgeColor.primaryGray
                }
              >
                <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                  <Center>{t("DATA_GROUP:NUMBER_2")}</Center>
                </Text>
              </Badge>
              <Flex height="64x" justifyContent="center">
                <Text
                  fontWeight={700}
                  fontSize={16}
                  marginStart={2}
                  color={index_page === 1 ? PRIMARY_COLOR : BLACK_COLOR}
                >
                  {t("DATA_GROUP:DATA_SCOPE")}
                </Text>
              </Flex>

              {/* end of data scope  */}
            </Stack>
          </Box>
        </Stack>
      </Box>
      {/* render main body  */}
      {is_loading_detail ?
        <CenteredSpinner /> : renderMainBody()
      }


      <ModalCustom
        message="Your Data Was Saved Successfully"
        isOpen={modalOpen}
        onOk={clickOkOnModal}
        icon={<SavedSuccess />}
      />
    </Box>
  );
};

export default EditDataGroup;
