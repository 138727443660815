import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
    list_principal: [],
    status: "",
  };

  export const getListPrincipal = createAsyncThunk(
    "getListPrincipal",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  
  export const getInitListPrincipal = createAsyncThunk(
    "getInitListPrincipal",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal/initiateGetList`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

  const principal = createSlice({
    name: "createPrincipal",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: {
      [getListPrincipal.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListPrincipal.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.list_principal = action.payload.response;
      },
      [getListPrincipal.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },
    },
  });
  
  export const principalAction = principal.actions;
  
  export default principal.reducer;