import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ActionType from "../../actions/actionType";
import logo from "../../assets/white-logo.png";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ROUTES } from "../../constant/routeConstant";
import { Link } from "react-router-dom";
import { authActions } from "../../reducers/authReducer";
import {
  ChangePasswordIcon,
  ChangeProfileIcon,
  RequestActivationCodeIcon,
  ProfileIcon,
  LogoutIcon,
} from "../icons";
import { useTranslation } from "react-i18next";
import useCheckUserCategory from "../../hooks/useCheckUserCategory";
import { userCategoryType } from "../../constant/propertiesConstant";
import { setLogout } from "../../services/main/navbarMenuReducer";
const Headbar = ({ collapsed, setCollapsed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { first_name } = useSelector((state) => state.auth);
  const { last_name } = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.auth);
  const name = first_name.concat(" ").concat(last_name)

  const { isAccepted } = useCheckUserCategory([userCategoryType.ADMIN, userCategoryType.SUPPORT]);

  const fakeLogout = () => {
    dispatch(setLogout());
    dispatch(authActions.resetRedux());
  };
  return (
    <Box
      backgroundColor="white"
      height="64px"
      display="flex"
      justifyContent="space-between"
      zIndex="3"
    >
      <Box>
        <Image
          src={logo}
          margin="auto"
          width={collapsed ? "160px" : "64px"}
          transition=".2s"
        />
      </Box>

      <Box margin="12px 16px">
        <Menu>
          <MenuButton
            px={4}
            py={2}
            transition="all 0.2s"
            _hover={{ bg: "gray.400" }}
            _expanded={{ bg: "gray.400" }}
          >
            <Box
              flexDirection="row"
              display="flex"
              width="8rem"
              height="2rem"
              align={"right"}
            >
              <Box
                flexDirection="column"
                display="flex"
                align="right"
              >
                <Text style={{ width: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} fontSize="11px">
                  <b>{name === "" ? "Admin" : name}</b>
                </Text>
                <Text style={{ width: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }} fontSize="10px">{role === "" ? "Admin Principal" : role}</Text>
              </Box>
              <ChevronDownIcon />
            </Box>
          </MenuButton>
          <MenuList padding={2}>
            <MenuItem>
              <Box display="flex">
                <ProfileIcon />
                <Text marginLeft={2}>{name === "" ? "Name" : name}</Text>
              </Box>
            </MenuItem>
            <MenuDivider />

            <Link to={ROUTES.CHANGE_PASSWORD}>
              <MenuItem marginTop={5}>
                <Box display="flex" padding={0.99}>
                  <ChangePasswordIcon />
                  <Text marginLeft={2}>{t('COMMON:MENU_CHANGE_PASSWORD')}</Text>
                </Box>
              </MenuItem>
            </Link>

            <Link to={ROUTES.CHANGE_PROFILE}>
              <MenuItem>
                <Box display="flex" padding={0.6}>
                  <ChangeProfileIcon />
                  <Text marginLeft={2}>{t('COMMON:MENU_CHANGE_PROFILE')}</Text>
                </Box>
              </MenuItem>
            </Link>

            {isAccepted ? (
              <Link to={ROUTES.REQUEST_ACTIVATION_CODE}>
                <MenuItem>
                  <Box display="flex" padding={1}>
                    <RequestActivationCodeIcon />
                    <Text marginLeft={2}>{t('COMMON:MENU_REQUEST_ACTIVATION')}</Text>
                  </Box>
                </MenuItem>
              </Link>
            ) : null}
          </MenuList>
        </Menu>
        <Button onClick={() => fakeLogout()} marginLeft={1} variant="ghost">
          <LogoutIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Headbar;
