import { Box, Stack, Tag } from "@chakra-ui/react";
import { Table, Select, Pagination } from "antd";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  getInitTransactionDetailPO,
  getListTransactionDetailPO,
} from "../../../../../services/main/processedPOReducer";
import StringUtil from "../../../../../utils/stringUtil";
const TransactionDetail = () => {
  const { t } = useTranslation();

  const data = useSelector(
    (state) => state.processedPO.list_transaction_detail
  );
  const { table_loading } = useSelector((state) => state.processedPO);
  const location = useLocation();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  const { token } = useSelector((state) => state.auth);
  const schema = "schema eq " + location.state[1];
  const id = "po_incoming_id eq " + location.state[0];
  const filter = schema + " , " + id;
  const params = { page, limit, filter, order };
  const fetchDataDetail = () => {
    dispatch(getListTransactionDetailPO(params));
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  useEffect(() => {
    dispatch(getInitTransactionDetailPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });

    fetchDataDetail();
  }, [page, limit, token, total, order, dispatch]);

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      product_name: item.product_name,
      product_code: item.product_code,
      qty: item.qty,
      barcode: item.barcode,
      uom_1: item.uom_1,
      net_amount: item.net_amount,
      price: item.price,
    }));

  const columns = [
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "product_code",
      key: "product_code",
      sorter: (a, b) => a.product_code-(b.product_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_PRODUCT_NAME")}
        </Box>
      ),
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) => a.product_name-(b.product_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_BARCODE")}
        </Box>
      ),
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a, b) => a.barcode-(b.barcode),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_QTY")}
        </Box>
      ),
      dataIndex: "qty",
      key: "qty",
      sorter: (a, b) => a.qty-(b.qty),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_UOM")}
        </Box>
      ),
      dataIndex: "uom_1",
      key: "uom_1",
      sorter: (a, b) => a.uom_1-b.uom_1,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_PRICE")}
        </Box>
      ),
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price-(b.price),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_NET_AMOUNT")}
        </Box>
      ),
      dataIndex: "net_amount",
      key: "net_amount",
      sorter: (a, b) => a.net_amount-(b.net_amount),
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <>
      <Box pt={4} pb={4}>
        <HeadTitle titleSection={t("MONITORING_LIST_PO:TRANSACTION_DETAIL")} />
      </Box>
      {/* table body */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        onChange={handleTableChange}
        size="small"
        loading={table_loading}
        footer={() => (
          <Box
            backgroundColor="#E5EBF1"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Box display="flex" alignItems="center" marginX={2}>
              <Box marginX={4}>Row Per Page</Box>
              <Select
                border="1px solid"
                borderColor="#CCCCCC"
                size="sm"
                width={20}
                _focus={{ outline: "none" }}
                value={limit}
                // onChange={handleSelectPagination}
                onChange={(value, opt) => handleSelectPagination(value, opt)}
              >
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="25">25</Select.Option>
                <Select.Option value="50">50</Select.Option>
                <Select.Option value="100">100</Select.Option>
              </Select>
            </Box>
            <Box>
              <Pagination
                size="small"
                current={page}
                total={total}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                pageSize={limit}
              />
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default TransactionDetail;
