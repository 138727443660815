import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  inputTheme,
  multiSelectTheme,
} from "../../../../../constant/themeConstant";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  addProductCategory,
  getListDistributor,
  getListPrincipal,
} from "../../../../../services/main/productCategoryReducer";
import { useFormik } from "formik";
import { addProductCategorySchema } from "./productCategorySchema";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { Redirect } from "react-router-dom";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { ID_PRODUCT_CATEGORY } from "../../../../../constant/idConstant";
const AddProductCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principalName = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);

  // Code(for disabled input)
  const [distributorCode, setDistributorCode] = useState([]);

  /// modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };
  //page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);


  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "distributor_id":
        setFieldValue("distributor_id", value);
        setDistributorCode(
          option?.map((val) => {
            return val.code;
          })
        );
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };
  const onSubmit = () => {
    const payload = {
      ...values,
      principalId,
    };
    dispatch(addProductCategory(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      product_category_name: "",
      product_category_code: "",
      distributor_id: [],
      status: "",
    },
    validationSchema: addProductCategorySchema(t),
    onSubmit,
  });
  ///Principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [principalCode, setPrincipalCode] = useState("");
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };

  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const is_check_status = true;
    const params = { page, limit ,is_check_status };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const handleScrollDistributor = () => {
    setPageDistributor(pageDistributor + 1);
  };

  // Use Effect

  // Principal
  useEffect(() => {
    fetchDataPrincipal();
  }, [pagePrincipal]);
  // Distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY:ADD_PRODUCT_CATEGORY")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_CATEGORY:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY:BUTTON_1")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* Principal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_PRODUCT_CATEGORY.ADD.PRINCIPAL_CODE}
                  backgroundColor={GRAY_COLOR}
                  value={principalCode ? principalCode : ""}
                />
              </Box>
            </Box>
            {/* Distributor Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  backgroundColor={GRAY_COLOR}
                  id={ID_PRODUCT_CATEGORY.ADD.DISTRIBUTOR_CODE}
                  value={distributorCode}
                />
              </Box>
            </Box>
            {/* Product Category Code*/}
            <Box padding={1} display="flex">
              <Box width="10rem" pt={2} pl={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  onChange={handleChange}
                  name="product_category_code"
                  id={ID_PRODUCT_CATEGORY.ADD.PRODUCT_CATEGORY_CODE}
                  value={values?.product_category_code}
                />
                {errors.product_category_code &&
                  touched.product_category_code && (
                    <ValidationError text={errors.product_category_code} />
                  )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box width="250px">
                <Input
                  type="text"
                  name="principal_name"
                  id={ID_PRODUCT_CATEGORY.ADD.PRINCIPAL_NAME}
                  style={inputTheme}
                  disabled
                  background={GRAY_COLOR}
                  value={principalName}
                />
              </Box>
            </Box>
            {/* distributor name  */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME")}</Text>
              </Box>
              <Box width="250px">
                <Select
                  style={multiSelectTheme}
                  id={ID_PRODUCT_CATEGORY.ADD.DISTRIBUTOR_NAME}
                  mode="multiple"
                  showArrow
                  maxTagCount={1}
                  value={values?.distributor_id}
                  onPopupScroll={handleScrollDistributor}
                  size="large"
                  allowClear
                  onBlur={handleBlur("distributor_id")}
                  name="distributor_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                >
                  {dataDistributor &&
                    dataDistributor?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          code={data.distributor_code}
                          principal_name={data.principal_name}
                          principal_id={data.principal_id}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && touched.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>
            {/* product category name */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2} pt={0} pr={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  id={ID_PRODUCT_CATEGORY.ADD.PRODUCT_CATEGORY_NAME}
                  name="product_category_name"
                  onChange={handleChange}
                  style={inputTheme}
                  background={GRAY_COLOR}
                  value={values?.product_category_name}
                />
                {errors.product_category_name &&
                  touched.product_category_name && (
                    <ValidationError text={errors.product_category_name} />
                  )}
              </Box>
            </Box>

            {/* product status */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2} pt={0}>
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_CATEGORY:FIELD_PRODUCT_STATUS")}
                </Text>
              </Box>
              <Box className="my-select">
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  id={ID_PRODUCT_CATEGORY.ADD.STATUS}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_CATEGORY.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_CATEGORY.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddProductCategory;
