import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_PRODUCT_PRINCIPAL } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR, PRIMARY_COLOR, userCategoryType
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../../hooks/useCheckUserCategory";
import { getListPrincipal } from "../../../../../services/main/principalReducer";
import { addProductPrincipal } from "../../../../../services/main/productPrincipalReducer";
import { addSchema } from "./productPrincipalSchema";

const AddProductPrincipal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAccepted } = useCheckUserCategory([userCategoryType.PRINCIPAL]);
  const { Option } = Select;
  const [page, setPage] = useState(1);
  const principalId = useSelector((state) => state.auth.principal_id);
  const principalCode = useSelector((state) => state.auth.principal_code);
  const role = useSelector((state) => state.auth.role);
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);
  // modal response
  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);
  const [idPrincipal, setidPrincipal] = useState(null);

  // Submit funct
  const onSubmit = (values, action) => {
    values.price_uom = parseInt(values.price_uom);
    values.conversion_1_to_4 = parseInt(values.conversion_1_to_4);
    values.conversion_2_to_4 = parseInt(values.conversion_2_to_4);
    values.conversion_3_to_4 = parseInt(values.conversion_3_to_4);
    dispatch(addProductPrincipal(values)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
        action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };

  // modal success or failed
  const modalSuccess = () => {
    history.push(ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  //pengecekan apakah ini principal
  const roleToLowerCase = role.toLowerCase();
  const isPrincipal = roleToLowerCase.includes("principal");

  // Formik
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      product_code: "",
      product_name: "",
      product_description: "",
      barcode: "",
      uom_1: "",
      uom_2: "",
      uom_3: "",
      uom_4: "",
      conversion_1_to_4: "",
      conversion_2_to_4: "",
      conversion_3_to_4: "",
      order_unit: "",
      packaging: "",
      status: "",
      price_uom: "",
      principal_id: "",
    },
    validationSchema: addSchema(t),
    onSubmit,
  });
  useMemo(() => {
    if (isPrincipal) {
      setFieldValue("principal_id", principalId);
    }
  }, [isPrincipal]);
  const principalCodeDDS = useMemo(() => {
    // find detail data store from selected idStore
    return dataPrincipal?.find((search) => search.id === idPrincipal);
  }, [idPrincipal]);
  const handleChangeSelect = (name, value, option) => {
    if (name === "principal_id") {
      setFieldValue("principal_id", value);
      setidPrincipal(value);
    }
    setFieldValue(name, value);
  };

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_PRINCIPAL:ADD_PRODUCT_PRINCIPAL")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_PRINCIPAL:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_PRINCIPAL:BUTTON_1")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          // justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius={10}
        >
          {/* left side */}
          <Box padding={1} minWidth="37vw">
            {/* Principal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  style={inputTheme}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRINCIPAL_CODE}
                  width="250px"
                  value={
                    isAccepted
                      ? principalCode
                      : principalCodeDDS?.principal_code
                  }
                  disabled
                  backgroundColor={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Product Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box pb={1}>
                <Input
                  style={inputTheme}
                  type="text"
                  width="250px"
                  name="product_code"
                  onChange={handleChange}
                  value={values.product_code}
                  onBlur={handleBlur}
                />
                {errors.product_code && touched.product_code && (
                  <ValidationError text={errors.product_code} />
                )}
              </Box>
            </Box>
            {/* Barcode*/}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_BARCODE")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_PRINCIPAL.ADD.BARCODE}
                  name="barcode"
                  onChange={handleChange}
                  value={values.barcode}
                  onBlur={handleBlur}
                />
                {errors.barcode && touched.barcode && (
                  <ValidationError text={errors.barcode} />
                )}
              </Box>
            </Box>
            {/* UOM 1*/}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_1")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.ADD.UOM1}
                  width="250px"
                  onChange={handleChange}
                  name="uom_1"
                  style={inputTheme}
                  value={values.uom_1}
                  onBlur={handleBlur}
                />
                {errors.uom_1 && touched.uom_1 && (
                  <ValidationError text={errors.uom_1} />
                )}
              </Box>
            </Box>
            {/* UOM 2  */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                {" "}
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_2")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="uom_2"
                  onChange={handleChange}
                  type="text"
                  width="250px"
                  id={ID_PRODUCT_PRINCIPAL.ADD.UOM2}
                  style={inputTheme}
                  value={values.uom_2}
                  onBlur={handleBlur}
                />
                {errors.uom_2 && touched.uom_2 && (
                  <ValidationError text={errors.uom_2} />
                )}
              </Box>
            </Box>
            {/* UOM 3  */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_3")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="uom_3"
                  id={ID_PRODUCT_PRINCIPAL.ADD.UOM3}
                  onChange={handleChange}
                  type="text"
                  width="250px"
                  style={inputTheme}
                  value={values.uom_3}
                  onBlur={handleBlur}
                />
                {errors.uom_3 && touched.uom_3 && (
                  <ValidationError text={errors.uom_3} />
                )}
              </Box>
            </Box>
            {/* UOM 4 */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_UOM_4")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="uom_4"
                  onChange={handleChange}
                  type="text"
                  id={ID_PRODUCT_PRINCIPAL.ADD.UOM4}
                  width="250px"
                  style={inputTheme}
                  value={values.uom_4}
                  onBlur={handleBlur}
                />
                {errors.uom_4 && touched.uom_4 && (
                  <ValidationError text={errors.uom_4} />
                )}
              </Box>
            </Box>
            {/* Converstion To Smallest*/}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2}>
                <Text overflowWrap="break-word" width="100px">
                  {t("PRODUCT_PRINCIPAL:FIELD_CONVERSION_TO_SMALLEST")}
                </Text>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box paddingBottom={3}>
                  <Input
                    value={values.conversion_1_to_4}
                    name="conversion_1_to_4"
                    onChange={handleChange}
                    id={ID_PRODUCT_PRINCIPAL.ADD.CONVERSTION_TO_SMALLEST_1}
                    width="250px"
                    style={inputTheme}
                    onBlur={handleBlur}
                  />
                  {errors.conversion_1_to_4 && touched.conversion_1_to_4 && (
                    <ValidationError text={errors.conversion_1_to_4} />
                  )}
                </Box>
                <Box paddingBottom={3}>
                  <Input
                    value={values.conversion_2_to_4}
                    id={ID_PRODUCT_PRINCIPAL.ADD.CONVERSTION_TO_SMALLEST_2}
                    name="conversion_2_to_4"
                    onChange={handleChange}
                    width="250px"
                    style={inputTheme}
                    onBlur={handleBlur}
                  />
                  {errors.conversion_2_to_4 && touched.conversion_2_to_4 && (
                    <ValidationError text={errors.conversion_2_to_4} />
                  )}
                </Box>
                <Box paddingBottom={3}>
                  <Input
                    value={values.conversion_3_to_4}
                    name="conversion_3_to_4"
                    onChange={handleChange}
                    width="250px"
                    id={ID_PRODUCT_PRINCIPAL.ADD.CONVERSTION_TO_SMALLEST_3}
                    style={inputTheme}
                    onBlur={handleBlur}
                  />
                  {errors.conversion_3_to_4 && touched.conversion_3_to_4 && (
                    <ValidationError text={errors.conversion_3_to_4} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={{ width: 250, borderRadius: "6px" }}
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRINCIPAL_NAME}
                  value={
                    isAccepted
                      ? principalId
                      : values.principal_id
                  }
                  disabled={isPrincipal ? true : false}
                  onChange={(value, opt) =>
                    handleChangeSelect("principal_id", value, opt)
                  }
                  showSearch={false}
                  allowClear="true"
                  size="large"
                  name="principal_id"
                >
                  {dataPrincipal &&
                    dataPrincipal.map((data, index) => {
                      return (
                        <Select.Option key={index} value={data.id}>
                          {data.company_profile_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.principal_id && touched.principal_id && (
                  <ValidationError text={errors.principal_id} />
                )}
              </Box>
            </Box>
            {/* principal product name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>
                  {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="product_name"
                  onChange={handleChange}
                  value={values.product_name}
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  type="text"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRINCIPAL_PRODUCT_NAME}
                />
                {errors.product_name && touched.product_name && (
                  <ValidationError text={errors.product_name} />
                )}
              </Box>
            </Box>
            {/* product description */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRODUCT_DESCRIPTION")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="product_description"
                  value={values.product_description}
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRODUCT_DESCRIPTION}
                />
                {errors.product_description && touched.product_description && (
                  <ValidationError text={errors.product_description} />
                )}
              </Box>
            </Box>

            {/* order unit */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_PRINCIPAL:FIELD_ORDER_UNIT")}
                </Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="order_unit"
                  value={values.order_unit}
                  onChange={handleChange}
                  style={inputTheme}
                  onBlur={handleBlur}
                  type="text"
                  width="250px"
                  id={ID_PRODUCT_PRINCIPAL.ADD.ORDER_UNIT}
                />
                {errors.order_unit && touched.order_unit && (
                  <ValidationError text={errors.order_unit} />
                )}
              </Box>
            </Box>

            {/* price uom 1 */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRICE_UOM_1")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="price_uom"
                  value={values.price_uom}
                  onChange={handleChange}
                  style={inputTheme}
                  onBlur={handleBlur}
                  width="250px"
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRICE_UOM_1}
                />
                {errors.price_uom && touched.price_uom && (
                  <ValidationError text={errors.price_uom} />
                )}
              </Box>
            </Box>

            {/* product status */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRODUCT_STATUS")}</Text>
              </Box>
              <Box pb={1} className="my-select-container">
                <Select
                  name="status"
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRODUCT_STATUS}
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  onBlur={handleBlur}
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>

            {/* product packaging */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_PRINCIPAL:FIELD_PRODUCT_PACKAGING")}</Text>
              </Box>
              <Box pb={1}>
                <Input
                  name="packaging"
                  onChange={handleChange}
                  value={values.packaging}
                  style={inputTheme}
                  type="text"
                  width="250px"
                  onBlur={handleBlur}
                  id={ID_PRODUCT_PRINCIPAL.ADD.PRODUCT_PACKAGING}
                />
                {errors.packaging && touched.packaging && (
                  <ValidationError text={errors.packaging} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_PRINCIPAL.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_PRINCIPAL.ADD.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default AddProductPrincipal;
