import {configureStore , createSlice} from '@reduxjs/toolkit';
import i18next from "i18next";
import storage from 'redux-persist/lib/storage';

const INITIAL_STATE = {
  crypto_random: "",
  code_pkce: "",
  token: "",
  refresh_token: "",
  is_authenticated: false,

  language: null,

  user_id: null,
  first_name: "",
  last_name: "",
  alias_name: "",
  company_name : "",
  company_profile_access : null,
  principal_id : "",
  principal_code : "",
  principal_alias : "",

  scope: "",
  role: "",
  permission: null,
  menu: null,
  user_category: ""
};

const authSlice = createSlice({
  name :'auth',
  initialState : INITIAL_STATE,
  reducers:{
    setCryptoRandom(state,payload){
      state.crypto_random=payload.payload
    },

    setCodePKCE(state,payload){
      state.code_pkce=payload.payload
    },

    setToken(state,payload){
      state.token=payload.payload
    },

    setRefreshToken(state,payload){
      state.refresh_token=payload.payload
    },

    trueAuthenticated(state,payload){
      state.is_authenticated = true
    },

    falseAuthenticated(state){
      state.is_authenticated = false
    },

    setLanguage(state,payload){
      state.language = payload.payload
    },

    setUserId(state,payload){
      state.user_id = payload.payload
    },

    setFirstName(state,payload){
      state.first_name = payload.payload
  
    },

    setLastName(state,payload){
      state.last_name = payload.payload
    },
    setCompanyName(state,payload){
      state.company_name = payload.payload
    },
    setCompanyProfileAccess(state,payload){
      state.company_profile_access = payload.payload
    },
    setAlias(state,payload){
      state.alias_name = payload.payload
    },

    setScope(state,payload){
      state.scope = payload.payload
    },

    setPrincipalAlias(state,payload){
      state.principal_alias = payload.payload
    },
    setRole(state,payload){
      state.role = payload.payload
    },
    setPrincipalId(state,payload){
      state.principal_id = payload.payload
    },
    setPrincipalCode(state,payload){
      state.principal_code = payload.payload
    },
    setPermission(state,payload){
      state.permission = payload.payload
    },
    setMenu(state,payload){
      state.menu = payload.payload
    },
    setUserCategory(state, { payload }){
      state.user_category = payload;
    },

    // resetRedux : () => INITIAL_STATE,
    resetRedux(){
      storage.removeItem('persist:rootUser');
      sessionStorage.removeItem("selectedParent");
      sessionStorage.removeItem("selectedMenu");
      sessionStorage.removeItem("selectedUrl");
      localStorage.clear();
      return INITIAL_STATE;
    }

  }
})

export const authActions = authSlice.actions;

export default authSlice;