import { Global } from "@emotion/react";
import { Tooltip } from "antd";
import { BLACK_COLOR, WHITE_COLOR } from "../constant/propertiesConstant";

const CustomToolTip = ({
  title,
  placement,
  color,
  children,
} = {}) => {
  return (
    <>
      <Tooltip title={title} placement={placement} color={color ?? WHITE_COLOR} overlayInnerStyle={{ color: BLACK_COLOR}}>
        {children}
      </Tooltip>
    </>
  );
};

export default CustomToolTip;
