import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";
import moment from "moment";

const INITIAL_STATE = {
  list: [],
  status:  "",
  errorNotification: "",
  table_loading: false,
  distributor: {
    principal_id: null,
    alias: "",
    status: "",
    additional_data: [],
  },
  preferences: {
    is_active_email_alert: false,
    is_active_email_notification: false,
  },
  company_profile: {
    company_title_id: null,
    company_name: "",
    npwp: "",
    address_1: "",
    address_2: "",
    address_3: "",
    hamlet: "",
    neighbourhood: "",
    country_id: null,
    province_id: null,
    district_id: null,
    sub_district_id: null,
    urban_village_id: null,
    island_id: null,
    postal_code_id: null,
    phone_country_code: "",
    phone: "",
    phone_fax_country_code: "",
    fax: "",
    email: "",
    latitude: null,
    longitude: null,
    status: "",
    alias: "",
  },
  contact_person: [],
  detail_distributor: {},
  detail_company_profile: {},
  detail_preferences: {},
  detail_contact_person: [],
  contact_person_loading: false,
  edit_preferences: {
    is_active_email_alert: false,
    is_active_email_notification: false,
  },
  photo1: null,
  index_page: 1,
  curr_page: "",
};

export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributorNoLimit = createAsyncThunk(
  "getListDistributorNoLimit",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content ?? [];
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitListDistributor = createAsyncThunk(
  "getInitListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailDistributor = createAsyncThunk(
  "detailDistributor",
  async (distributorId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor/${distributorId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addDistributor = createAsyncThunk(
  "addDistributor",
  async (payloadContactPerson, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const { distributor } = getState();
    const token = auth.token;
    const body = {
      distributor: distributor?.distributor,
      preferences: distributor?.preferences,
      company_profile: {
        principal_id: distributor?.company_profile.principal_id,
        company_title_id: distributor?.company_profile.company_title_id,
        company_name: distributor?.company_profile.company_name,
        npwp: distributor?.company_profile.npwp,
        address_1: distributor?.company_profile.address_1,
        address_2: "",
        address_3: "",
        hamlet: distributor?.company_profile.hamlet,
        neighbourhood: distributor?.company_profile.neighbourhood,
        country_id: distributor?.company_profile.country_id,
        province_id: distributor?.company_profile.province_id,
        district_id: distributor?.company_profile.district_id,
        sub_district_id: distributor?.company_profile.sub_district_id,
        urban_village_id: distributor?.company_profile.urban_village_id,
        island_id: distributor?.company_profile.island_id,
        postal_code_id: distributor?.company_profile.postal_code_id,
        phone_country_code: distributor?.company_profile.phone_country_code,
        phone: distributor?.company_profile.phone,
        phone_fax_country_code:
          distributor?.company_profile.phone_fax_country_code,
        fax: distributor?.company_profile.fax,
        email: distributor?.company_profile.email,
        latitude: Number(distributor?.company_profile.latitude),
        longitude: Number(distributor?.company_profile.longitude),
        status: distributor?.company_profile.status,
        alias: distributor?.company_profile.alias,
      },
      contact_person: payloadContactPerson?.contact_person?.map((val) => {
        return {
          person_profile_id: val?.person_profile_id,
          company_profile_id: val?.company_profile_id,
          person_title_id: val?.person_title_id,
          nik: val?.nik,
          first_name: val?.first_name,
          last_name: val?.last_name,
          email: val?.email,
          phone_code: val?.phone_code,
          phone: val?.phone,
          address_1: val?.address_1,
          country_id: val?.country_id,
          join_date: moment(val?.join_date),
          resign_date: moment(val?.resign_date),
        };
      }),
    };

    const formData = new FormData();
    formData.append("content", JSON.stringify(body));
    if (distributor.photo1) {
      formData.append("photo1", distributor.photo1);
    }

    try {
      const response = await baseApi.doPost(url, token, formData);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editDistributor = createAsyncThunk(
  "editDistributor",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor/${object.distributorId}`;
    const { auth } = getState();
    const { distributor } = getState();
    const token = auth.token;
    const phoneFull = object?.companyProfile?.phone;
    const phoneCode = phoneFull?.split("-")[0] ?? "";
    const phoneNumber = phoneFull?.split("-")[1] ?? "";
    const faxFull = object?.companyProfile?.fax;
    const faxCode = faxFull?.split("-")[0] ?? "";
    const faxNumber = faxFull?.split("-")[1] ?? "";
    const body = {
      distributor: object?.distributor,
      preferences: object?.preferences,
      company_profile: {
        principal_id: object?.companyProfile.principal_id,
        company_title_id: object?.companyProfile.company_title_id.value
          ? object?.companyProfile.company_title_id.value
          : object?.companyProfile.company_title_id,
        company_name: object?.companyProfile.company_name,
        npwp: object?.companyProfile.npwp,
        deleted_photo_id: [0],
        address_1: object?.companyProfile.address_1,
        address_2: "",
        address_3: "",
        hamlet: object?.companyProfile.hamlet,
        neighbourhood: object?.companyProfile.neighbourhood,
        country_id: object?.companyProfile.country_id
          ? object?.companyProfile.country_id
          : 0,
        province_id: object?.companyProfile.province_id
          ? object?.companyProfile.province_id
          : 0,
        district_id: object?.companyProfile.district_id
          ? object?.companyProfile.district_id
          : 0,
        sub_district_id: object?.companyProfile.sub_district_id
          ? object?.companyProfile.sub_district_id
          : 0,
        urban_village_id: object?.companyProfile.urban_village_id
          ? object?.companyProfile.urban_village_id
          : 0,
        island_id: object?.companyProfile.island_id
          ? object?.companyProfile.island_id
          : 0,
        postal_code_id: object?.companyProfile.postal_code_id
          ? object?.companyProfile.postal_code_id
          : 0,
        phone_country_code: object?.companyProfile.phone_country_code
          ? object?.companyProfile.phone_country_code
          : phoneCode,
        phone: object?.isPreferences
          ? phoneNumber
          : object?.companyProfile.phone,
        phone_fax_country_code: object?.companyProfile.phone_fax_country_code
          ? object?.companyProfile.phone_fax_country_code
          : faxCode,
        fax: object?.isPreferences ? faxNumber : object?.companyProfile.fax,
        email: object?.companyProfile.email,
        latitude: Number(object?.companyProfile.latitude),
        longitude: Number(object?.companyProfile.longitude),
        status: object?.companyProfile.status,
      },
    };

    const formData = new FormData();
    const { have_default_photo, images, photo } = object?.companyProfile;
    if (have_default_photo && (!images || Array.isArray(images))) {
      const { id } = photo;
      body.company_profile.deleted_photo_id = [id];
      if (images) {
        formData.append("photo1", images[0]);
      }
    } else {
      if (images) {
        formData.append("photo1", images[0]);
      }
      body.company_profile.deleted_photo_id = [0];
    }
    formData.append("content", JSON.stringify(body));
    try {
      const response = await baseApi.doPut(url, token, formData);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const deleteDistributor = createAsyncThunk(
  "deleteDistributor",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const distributorId = object.distributorId;
    const payload = {
      distributor: {
        updated_at: object.updated_at,
      },
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor/${distributorId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, payload);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitiateListContactPerson = createAsyncThunk(
  "getInitiateListContactPerson",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/contact-person/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListContactPerson = createAsyncThunk(
  "getListContactPerson",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/contact-person`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = [];
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const updateContactPerson = createAsyncThunk(
  "updateContactPerson",
  async (payload, { getState, rejectWithValue }) => {
    let result = { ok: false };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/contact-person/multiple`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPut(
        url,
        token,
        payload.payloadContactPerson
      );
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
export const getListCompanyTitle = createAsyncThunk(
  "getListCompanyTitle",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/company-title`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const distributor = createSlice({
  name: "distributor",
  initialState: INITIAL_STATE,
  reducers: {
    setPhoto(state, action) {
      state.photo1 = action.payload;
    },
    setCurrPage(state, action) {
      state.curr_page = action.payload;
    },
    setIndexPage(state, action) {
      state.index_page = action.payload;
    },
    setDataCompanyProfile(state, action) {
      state.company_profile = action.payload;
    },
    setDataPreferences(state, action) {
      state.preferences = action.payload;
    },
    setDataPreferencesActiveEmail(state, action) {
      state.preferences.is_active_email_alert = action.payload;
    },
    setDataPreferencesActiveNotification(state, action) {
      state.preferences.is_active_email_notification = action.payload;
    },
    setDataContactPerson(state, action) {
      state.contact_person = action.payload;
    },
    setDataDistributor(state, action) {
      state.distributor = action.payload;
    },
    setDetailCompanyProfile(state, action) {
      state.detail_company_profile = action.payload;
    },
    setDetailPreferences(state, action) {
      state.detail_preferences = action.payload;
    },
    setDetailContactPerson(state, action) {
      state.detail_contact_person = action.payload;
    },
    setDetailDistributor(state, action) {
      state.detail_distributor = action.payload;
    },
    resetReduxDistributor() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // distributor
    [getInitListDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitListDistributor.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitListDistributor.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListDistributor.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list = action.payload.response;
    },
    [getListDistributor.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [getListDistributorNoLimit.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListDistributorNoLimit.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
    },
    [getListDistributorNoLimit.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [detailDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailDistributor.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailDistributor.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [addDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [addDistributor.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addDistributor.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [deleteDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteDistributor.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteDistributor.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getInitiateListContactPerson.pending]: (state, action) => {
      state.status = "loading";
      state.contact_person_loading = true;
    },
    [getInitiateListContactPerson.fulfilled]: (state, action) => {
      state.status = "success";
      state.contact_person_loading = false;
    },
    [getInitiateListContactPerson.rejected]: (state, action) => {
      state.status = "failed";
      state.contact_person_loading = false;
    },

    [getListContactPerson.pending]: (state, action) => {
      state.status = "loading";
      state.contact_person_loading = true;
    },
    [getListContactPerson.fulfilled]: (state, action) => {
      state.status = "success";
      state.contact_person_loading = false;
    },
    [getListContactPerson.rejected]: (state, action) => {
      state.status = "failed";
      state.contact_person_loading = false;
    },

    [getListCompanyTitle.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCompanyTitle.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
    },
    [getListCompanyTitle.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    ["auth/resetRedux"]: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const distributorAction = distributor.actions;

export default distributor.reducer;
