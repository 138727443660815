import ObjectUtil from "../../utils/objectUtil";
import PermissionPrefix from "./PermissionPrefix";
import cloneDeep from 'lodash.clonedeep';
export default class Permission {
  /**
   * @param {String} defaultPermission defaultPermission
   * @param {PermissionParser} parser PermissionParser
   */
  constructor(defaultPermission, parser) {
    this._defaultPermission = cloneDeep(defaultPermission);
    this._parser = parser;
  }

  get() {
    return this._defaultPermission;
  }

  set(permissionObject) {
    this._defaultPermission = cloneDeep(permissionObject);
  }

  parse(permissionString) {
    this._defaultPermission = this._parser.parse(
      permissionString,
      this._defaultPermission
    );
  }
  clone() {
    return JSON.parse(JSON.stringify(this._defaultPermission));
  }

  stringify(prefix = PermissionPrefix.SYSADMIN) {
    return this._parser.stringify(this._defaultPermission, prefix);
  }

  getGlobalExist() {
    const existPermission = {
      view: false,
      view_own: false,
      insert: false,
      insert_own: false,
      update: false,
      update_own: false,
      delete: false,
      delete_own: false
    }

    if (this._defaultPermission && Array.isArray(this._defaultPermission)) {
      let isAllTrue = false;
      for (const mainPermission of this._defaultPermission) {
        for (const eachPermission of mainPermission.permission) {
          const [, key] = eachPermission.key.split(":");
          if (existPermission.hasOwnProperty(key)) {
            existPermission[key] = true
          }

          if (ObjectUtil.isPropertiesTruthy(existPermission)) {
            isAllTrue = true;
            break;
          }

        }

        if (isAllTrue) break;
      }
    }

    return existPermission;
  }
}

