import { Box, Stack, Text } from "@chakra-ui/react";
import { message, Pagination, Select, Table, TreeSelect } from "antd";
import { Option } from "antd/lib/mentions";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import InputDateRangePicker from "../../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  ExpandMoreDown,
  ExpandMoreUp,
  PrintIcon,
} from "../../../../../components/icons";
import { ID_REPORT_LIST_CONNECTION } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR,
  GRAY_OPTION,
  PRIMARY_COLOR,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  DownloadFileOption,
  SelectDDLFontFamily,
  SelectDDLFontSize,
  SelectDDLGrid,
  SelectDDLGroupHeading,
  SelectDDLHeaderBackground,
  SelectDDLReportRows,
  SelectDDLWidth,
} from "../../../../../constant/selectOptConstant";
import {
  inputColumnSelection,
  inputTheme,
  selectGeneralFormatOptions,
  selectGeneralFormatOptionsFontFamily,
  selectGeneralFormatOptionsFontSize,
  selectGeneralFormatOptionsGrid,
  selectGeneralFormatOptionsGroupHeading,
  selectGeneralFormatOptionsHeader,
  selectOptFilterPrincipal,
} from "../../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../../hooks/useCheckUserCategory";
import { printListConnection } from "../../../../../services/main/report/reportListConnection";
import {
  getInitListStoreSupplier,
  getListCustomerDDS,
  getListDistributorDDS,
  getListPrincipalDDS,
  getListStoreSupplier,
} from "../../../../../services/main/storeSupplierConnectionReducer";
import StringUtil from "../../../../../utils/stringUtil";
const ListConnection = () => {
  const today = new Date();
  const { t } = useTranslation();
  const { isAccepted } = useCheckUserCategory([
    userCategoryType.SUPPORT,
    userCategoryType.CUSTOM,
  ]);

  const data = useSelector(
    (state) => state.storeSupplierConnection.list_connection_store
  );
  const [total, setTotal] = useState(0);
  const [order, setOrder] = useState("");

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const { table_loading } = useSelector(
    (state) => state.storeSupplierConnection
  );
  const { printed_loading } = useSelector(
    (state) => state.reportListConnection
  );
  const [updatedDate, setUpdatedDate] = useState([today, today]);

  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);

  // variable principal
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [idPrincipal, setidPrincipal] = useState(null);

  // distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(null);

  // general Option
  const [selectedValues, setSelectedValues] = useState([]);
  const [generalFormat, setGeneralformat] = useState(false);
  const [columnSection, setColumnSection] = useState(false);
  const [selectedPrint, setselectedPrint] = useState(null);

  const role = useSelector((state) => state.auth.role);

  const ColumnSelectionListConnection = [
    {
      id: 1,
      value: "distributor_name",
      label: t("REPORT_LIST_CONNECTION:FIELD_DISTRIBUTOR_NAME"),
    },
    {
      id: 2,
      value: "customer_name",
      label: t("REPORT_LIST_CONNECTION:FIELD_CUSTOMER"),
    },
    {
      id: 3,
      value: "store_name",
      label: t("REPORT_LIST_CONNECTION:FIELD_STORE_NAME"),
    },
    {
      id: 4,
      value: "pm_handling",
      label: t("REPORT_LIST_CONNECTION:FIELD_PM_HANDLING"),
    },
    { id: 5, value: "alias", label: t("REPORT_LIST_CONNECTION:FIELD_ALIAS") },
    {
      id: 6,
      value: "store_code",
      label: t("REPORT_LIST_CONNECTION:FIELD_STORE_CODE"),
    },
    {
      id: 7,
      value: "supplier_code",
      label: t("REPORT_LIST_CONNECTION:FIELD_SUPPLIER_CODE"),
    },
    {
      id: 8,
      value: "updated_at",
      label: t("REPORT_LIST_CONNECTION:FIELD_UPDATED_AT"),
    },
  ];
  let allIds = ColumnSelectionListConnection.map(({ value }) => value);
  let dataColumn = ColumnSelectionListConnection;

  const setDefaultPrincipalId = useMemo(() => {
    if (isAccepted) {
      setidPrincipal(1);
    }
  }, [role]);
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const params = { page, limit };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = `distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };

    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };
  // button Apply process
  const submitOk = () => {
    setSearch(!search);
    setPage(1);
    setObjectFilter({
      idPrincipal: idPrincipal,
      idCustomer: idCustomer,
      idDistributor: idDistributor,
    });
  };
  const [objectFilter, setObjectFilter] = useState({
    idPrincipal: 1,
    idCustomer: null,
    idDistributor: null,
  });

  const filter = StringUtil.formatFilter({
    principal_id: `eq ${objectFilter.idPrincipal}`,
    customer_id: `eq ${objectFilter.idCustomer}`,
    distributor_id: `eq ${objectFilter.idDistributor}`,
  });

  const updated_at_start = moment(updatedDate[0]).format("YYYY-MM-DD");
  const updated_at_end = moment(updatedDate[1]).format("YYYY-MM-DD");
  const params = {
    page,
    limit,
    filter,
    updated_at_start,
    updated_at_end,
    order,
  };

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const fetchData = () => {
    dispatch(getListStoreSupplier(params)).then((response) => {
      if (response.payload.ok === false) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListStoreSupplier(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
  }, [page, limit, token, search, order, dispatch]);

  // start fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi principal

  // fungsi customer
  const handleScrollCustomer = (event) => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomerName}`;
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setUpdatedDate([start, end]);
    } else setUpdatedDate([today, today]);

    setIsDisabled(false);
  };

  // const setDisableButtonApply = useMemo(() => {
  //   setIsDisabled(false);
  // }, [idPrincipal, updatedDate]);

  const resetFilter = () => {
    if (isAccepted) {
      setidPrincipal(1);
    } else setidPrincipal(null);
    setUpdatedDate([today, today]);

    setFilterPrincipalName("");
    setIdCustomer(null);
    setfilterCustomerName("");
    setidDistributor(null);
    setFilterDistributor("");
    setSearch(!search);
    setPage(1);
    setLimit(10);
    setIsDisabled(true);
  };
  const handleChangeSelect = (name, value, option) => {
    if (name === "principal_id") {
      setidPrincipal(value);
      setIsDisabled(false);
    } else if (name === "customer_id") {
      setIdCustomer(value);
      setIsDisabled(false);
    } else if (name === "distributor_id") {
      setidDistributor(value);
      setIsDisabled(false);
    } else if (name === "select_print") {
      setselectedPrint(value);
    } else if (name === "report_width") {
      setGeneralFormatObject({ ...generalFormatObject, report_width: value });
    } else if (name === "grid_line_color") {
      setGeneralFormatObject({
        ...generalFormatObject,
        grid_line_color: value,
      });
    } else if (name === "column_header_background") {
      setGeneralFormatObject({
        ...generalFormatObject,
        column_header_background: value,
      });
    } else if (name === "report_rows") {
      setGeneralFormatObject({ ...generalFormatObject, report_rows: value });
    } else if (name === "font_size") {
      setGeneralFormatObject({ ...generalFormatObject, font_size: value });
    } else if (name === "font_family") {
      setGeneralFormatObject({ ...generalFormatObject, font_family: value });
    } else if (name === "group_heading") {
      setGeneralFormatObject({ ...generalFormatObject, group_heading: value });
    }
  };

  const handleSelectColumn = (ids) => {
    setSelectedValues(ids);
  };

  const [object, setObject] = useState({
    distributor_name: false,
    customer_name: false,
    store_name: false,
    alias: false,
    pm_handling: false,
    store_code: false,
    supplier_code: false,
    updated_at: false,
  });
  const handleprint = () => {
    for (const listData of selectedValues) {
      for (const keyObj in object) {
        const check = listData?.includes(keyObj);

        if (check) {
          object[keyObj] = check;
        }
      }
    }

    const body = {
      page: parseInt(page),
      limit: parseInt(limit),
      export_type: selectedPrint,
      data_criteria: {
        principal: idPrincipal,
        customer: idCustomer,
        distributor: idDistributor,
        po_date_start:
          moment(updatedDate[0]).format("YYYY-MM-DD") + "T00:00:00Z",
        po_date_end: moment(updatedDate[1]).format("YYYY-MM-DD") + "T23:59:59Z",
      },
      column_selection: object,
      general_format_options: {
        report_width:
          generalFormatObject.report_width === null
            ? 1
            : generalFormatObject.report_width,
        grid_line_color:
          generalFormatObject.grid_line_color === null
            ? 1
            : generalFormatObject.grid_line_color,
        column_header_background:
          generalFormatObject.column_header_background === null
            ? 1
            : generalFormatObject.column_header_background,
        report_rows:
          generalFormatObject.report_rows === null
            ? 1
            : generalFormatObject.report_rows,
        font_size:
          generalFormatObject.font_size === null
            ? 10
            : generalFormatObject.font_size,
        font_family:
          generalFormatObject.font_family === null
            ? 1
            : generalFormatObject.font_family,
        group_heading:
          generalFormatObject.group_heading === null
            ? 1
            : generalFormatObject.group_heading,
      },
    };

    dispatch(printListConnection(body)).then((response) => {
      setSelectedValues([]);
      for (const keyObj in object) {
        object[keyObj] = false;
      }
      setGeneralFormatObject({
        report_width: null,
        grid_line_color: null,
        column_header_background: null,
        report_rows: null,
        font_size: null,
        font_family: null,
        group_heading: null,
      });
      setselectedPrint(null);
      if (response) {
        if (response.error?.name) {
          return message.error("Empty data");
        }
        const { data, headers } = response.payload.response;
        const [, filename] = headers["content-disposition"].split(";");
        const [, name] = filename.trim().split("=");
        FileSaver.saveAs(data, name);
      }
    });
  };
  // General Format Option
  const handleGeneralOption = () => {
    if (columnSection === true) {
      setColumnSection(false);
      setGeneralformat(!generalFormat);
    } else setGeneralformat(!generalFormat);
  };

  const [generalFormatObject, setGeneralFormatObject] = useState({
    report_width: null,
    grid_line_color: null,
    column_header_background: null,
    report_rows: null,
    font_size: null,
    font_family: null,
    group_heading: null,
  });

  const renderFormatOption = () => {
    if (generalFormat === true) {
      return (
        <Stack
          style={{ height: "49px" }}
          display="flex"
          padding="3px 8px"
          borderRadius="6px"
          direction="row"
          backgroundColor="#DDDDDD"
          alignItems="center"
          marginBottom={4}
        >
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.REPORT_WIDTH}
              style={selectGeneralFormatOptions}
              value={generalFormatObject.report_width}
              onChange={(value, opt) =>
                handleChangeSelect("report_width", value, opt)
              }
              showSearch={false}
              placeholder="Report Width"
            >
              {SelectDDLWidth?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.GRID_LINE_COLOR}
              style={selectGeneralFormatOptionsGrid}
              value={generalFormatObject.grid_line_color}
              onChange={(value, opt) =>
                handleChangeSelect("grid_line_color", value, opt)
              }
              showSearch={false}
              placeholder="Grid Line Color"
            >
              {SelectDDLGrid?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* column header background */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.HEADER_BACKGROUND}
              style={selectGeneralFormatOptionsHeader}
              value={generalFormatObject.column_header_background}
              onChange={(value, opt) =>
                handleChangeSelect("column_header_background", value, opt)
              }
              showSearch={false}
              placeholder="Column Header Background"
            >
              {SelectDDLHeaderBackground?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Report Rows */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.REPORT_ROWS}
              style={selectGeneralFormatOptions}
              value={generalFormatObject.report_rows}
              onChange={(value, opt) =>
                handleChangeSelect("report_rows", value, opt)
              }
              showSearch={false}
              placeholder="Report Rows"
            >
              {SelectDDLReportRows?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Font Size */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.FONT_SIZE}
              style={selectGeneralFormatOptionsFontSize}
              value={generalFormatObject.font_size}
              onChange={(value, opt) =>
                handleChangeSelect("font_size", value, opt)
              }
              showSearch={false}
              placeholder="Font Size"
            >
              {SelectDDLFontSize?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Font Family */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.FONT_FAMILY}
              style={selectGeneralFormatOptionsFontFamily}
              value={generalFormatObject.font_family}
              onChange={(value, opt) =>
                handleChangeSelect("font_family", value, opt)
              }
              showSearch={false}
              placeholder="Font Family"
            >
              {SelectDDLFontFamily?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Group Heading */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.GROUP_HEADING}
              style={selectGeneralFormatOptionsGroupHeading}
              value={generalFormatObject.group_heading}
              onChange={(value, opt) =>
                handleChangeSelect("group_heading", value, opt)
              }
              showSearch={false}
              placeholder="Group Heading"
            >
              {SelectDDLGroupHeading?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
        </Stack>
      );
    } else return;
  };

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_name: item.distributor_name,
      customer_name: item.customer_name,
      store_name: item.store_name,
      principal_connection_store_id: item.principal_connection_store_id,
      pm_handling: item.pm_handling,
      store_code: item.store_code,
      supplier_code: item.supplier_code,
      updated_at: moment(item.updated_at).format("DD-MM-YYYY"),
    }));

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name - b.distributor_name,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_CUSTOMER")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
      width: 300,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_ALIAS")}
        </Box>
      ),
      dataIndex: "principal_connection_store_id",
      key: "principal_connection_store_id",
      sorter: (a, b) =>
        a.principal_connection_store_id - b.principal_connection_store_id,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_PM_HANDLING")}
        </Box>
      ),
      dataIndex: "pm_handling",
      key: "pm_handling",
      sorter: (a, b) => a.pm_handling - b.pm_handling,
      sortDirections: ["descend", "ascend"],
      width: 150,
      render: (pm_handling) => (
        <>
          {pm_handling === "A" ? t("COMMON:DROP_ALL") : t("COMMON:DROP_ITEM")}
        </>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "store_code",
      key: "store_code",
      sorter: (a, b) => a.store_code - b.store_code,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_SUPPLIER_CODE")}
        </Box>
      ),
      dataIndex: "supplier_code",
      key: "supplier_code",
      sorter: (a, b) => a.supplier_code - b.supplier_code,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_LIST_CONNECTION:FIELD_UPDATED_AT")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("REPORT_LIST_CONNECTION:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPORT_LIST_CONNECTION:SUBTITLE_HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPORT_LIST_CONNECTION:SUBTITLE_PARENT")}
            </span>{" "}
            - {t("REPORT_LIST_CONNECTION:TITLE_LIST")}
          </>
        }
        button={
          <div style={{ display: "flex" }}>
            <Box className="my-select-container">
              <Select
                style={{ selectGeneralFormatOptions }}
                size="large"
                value={selectedPrint}
                placeholder={t("COMMON:EXPORT_TO")}
                disabled={selectedValues?.length === 0 ? true : false}
                onChange={(value, opt) =>
                  handleChangeSelect("select_print", value, opt)
                }
                backgroundColor="white"
                textColor={GRAY_COLOR}
              >
                {DownloadFileOption?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Box>
            <ButtonCustom
              id={ID_REPORT_LIST_CONNECTION.LIST.BTN_PRINT}
              icon={<PrintIcon />}
              width="100px"
              text={t("COMMON:PRINT")}
              marginStart={2}
              colorScheme="blue"
              variant="outline"
              onClick={handleprint}
              disabled={!selectedPrint||printed_loading }
              loading={printed_loading}
            />
          </div>
        }
      />
      <Box marginTop={6} minHeight="96px">
        <Box mb={2}>
          <b>Print Report</b>
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={4}>
          <Box className="my-select-container">
            <TreeSelect
              id={ID_REPORT_LIST_CONNECTION.LIST.COLUMN_SELECTION}
              style={inputColumnSelection}
              allowClear={true}
              placeholder={t("COMMON:COLUMN_SELECTION")}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              dropdownStyle={{ maxHeight: "300px" }}
              onChange={(ids) => {
                handleSelectColumn(ids);
              }}
              fontSize="12px"
              value={selectedValues}
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues) =>
                `+ ${omittedValues.length} `
              }
              treeData={[
                {
                  title:
                    selectedValues.length === dataColumn.length ? (
                      <span
                        onClick={() => setSelectedValues([])}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Unselect all
                      </span>
                    ) : (
                      <span
                        onClick={() => setSelectedValues(allIds)}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Select all
                      </span>
                    ),
                  // value: "xxx",
                  disableCheckbox: true,
                  disabled: true,
                },
                ...dataColumn,
              ]}
            />
          </Box>
          <ButtonCustom
            id={ID_REPORT_LIST_CONNECTION.LIST.FORMAT_OPTION}
            rightIcon={
              generalFormat === false ? <ExpandMoreDown /> : <ExpandMoreUp />
            }
            width="211px"
            text={t("COMMON:GENERAL_FORMAT")}
            variant="outline"
            backgroundColor="white"
            onClick={handleGeneralOption}
            fontSize="14px"
            borderColor={GRAY_OPTION}
            textColor={GRAY_OPTION}
          />
        </Stack>
        {/* General Format */}
        {renderFormatOption()}

        <Stack direction="row" alignItems="center" marginBottom={6}>
          <Box width="240px">
            <InputDateRangePicker
              elementId={ID_REPORT_LIST_CONNECTION.LIST.PROCESS_DATE}
              values={updatedDate}
              onChange={handleChangeDate}
              defaultValue={[today, today]}
            />
          </Box>
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.PRINCIPAL}
              disabled={!isAccepted}
              style={selectOptFilterPrincipal}
              value={idPrincipal}
              onPopupScroll={handleScrollPrincipal}
              onChange={(value, opt) =>
                handleChangeSelect("principal_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  menu={menu}
                  search={(e) => setFilterPrincipalName(e.target.value)}
                />
              )}
              showSearch={false}
              placeholder={t("COMMON:SELECT_PRINCIPAL")}
              size="large"
            >
              {dataPrincipal &&
                dataPrincipal.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.company_profile_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.CUSTOMER}
              style={selectOptFilterPrincipal}
              size="large"
              value={idCustomer}
              onPopupScroll={handleScrollCustomer}
              placeholder={t("COMMON:SELECT_CUSTOMER")}
              // menggunakan onchange
              onChange={(value, opt) =>
                handleChangeSelect("customer_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  menu={menu}
                  search={(e) => setfilterCustomerName(e.target.value)}
                />
              )}
              showSearch={false}
              allowClear="true"
            >
              {dataCustomer &&
                dataCustomer.map((data, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={data.id}
                      title={data.name}
                    >
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_LIST_CONNECTION.LIST.DISTRIBUTOR}
              style={inputTheme}
              value={idDistributor}
              onPopupScroll={handleScrollDistributor}
              // menggunakan onchange
              onChange={(value, opt) =>
                handleChangeSelect("distributor_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  menu={menu}
                  search={(e) => setFilterDistributor(e.target.value)}
                />
              )}
              showSearch={false}
              allowClear="true"
              placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
              size="large"
            >
              {dataDistributor &&
                dataDistributor.map((data, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={data.id}
                      title={data.name}
                    >
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
          <Box>
            <ButtonCustom
              id={ID_REPORT_LIST_CONNECTION.LIST.BTN_APPLY}
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              // isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_REPORT_LIST_CONNECTION.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          onChange={handleTableChange}
          scroll={{
            x: 1300,
          }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_REPORT_LIST_CONNECTION.LIST.ROW_PAGE}
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_REPORT_LIST_CONNECTION.LIST.BTN_PAGINATION}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListConnection;
