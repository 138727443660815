export const typeStore = "store";
export const typeStoreSupplierConnection = "store-supplier-connection";
export const  typeProductCustomer = "product-customer";
export const typeProductPrincipal = "product-principal";
export const typeProductCategory = "product-category";
export const typeBklMapping = "bkl-mapping";
export const typeProductBklMapping = "product-bkl-mapping";
export const typeProductMapping = "product-mapping";
export const typeProductMappingHo = "product-mapping-ho";
export const typeDistributor = "distributor";
export const typeProductCategoryMapping = "product-category-mapping";
export const typeProductSubstitute = "product-substitute";
export const typeImportSO = "sales-order";
