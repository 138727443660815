// sysuser
export const ROUTES = {
  LOGIN: "/",
  NOTFOUND:"*",
  FORGOT_PASSWORD: "/forgot/password",
  CHANGE_PASSWORD : "/change/password",
  RESET_PASSWORD: "/reset/password",
  CHANGE_PROFILE : "/change/profile",
  REQUEST_ACTIVATION_CODE:"/request-activation-code",
  HOME: "/home",
  USER: {
    LIST: "/user",
    DETAIL: "/user/detail/",
    ADD: "/user/add",
    EDIT: "/user/edit/",
  },
  ACCOUNT: {
    LIST: "/account",
    DETAIL: "/account/detail/:id",
    ADD: "/account/add",
    EDIT: "/account/edit",
  },
  CUSTOMER: {
    LIST: "/customer",
    DETAIL: "/customer/detail/:id",
    ADD: "/customer/add",
    EDIT: "/customer/edit",
  },
  DISTRIBUTOR: {
    LIST: "/distributor",
    DETAIL: "/distributor/detail",
    ADD: "/distributor/add",
    EDIT: "/distributor/edit",
  },
  DATA_GROUP: {
    LIST: "/data-group",
    DETAIL: "/data-group/detail/",
    ADD: "/data-group/add",
    EDIT: "/data-group/edit/",
  },
  ROLE: {
    LIST: "/role",
    DETAIL: "/role/detail",
    ADD: "/role/add",
    EDIT: "/role/edit",
  },
  TAX: {
    LIST: "/tax",
    DETAIL: "/tax/detail",
    ADD: "/tax/add",
    EDIT: "/tax/edit",
  },
  USER_ACTIVITY: {
    LIST: "/user-activity",
    DETAIL: "/user-activity/detail/:id",
  },
  AUDIT_TRAIL: {
    LIST: "/audit-trail",
    DETAIL: "/audit-trail/detail",
  },
  LIST_PROCCESS_PO: {
    LIST: "/list-process-po",
  },
  PO: {
    LIST: "/monitoring-po",
  },
 

  // monitoring
  LIST_PROCCESS_PO: {
    LIST: "/list-process-po",
    DETAIL: "/monitoring/process/detail/",
  },
  CONNECTION: {
    LIST: "/connection",
    DETAIL: "/connection/detail/",
  },
  MONITORING_PO: {
    LIST: "/monitoring-po"
  },
  UNMAPPED_PRODUCT: {
    LIST: "/unmapped-product",
  },
  UNMAPPED_STORE: {
    LIST: "/unmapped-store",
  },
  UPLOAD_LOG: {
    LIST: "/upload-log",
    DETAIL: "/upload-log/detail/",
  },
  SFTP_AS2_LOG :{
    LIST : "/monitoring-as2-sftp-log",
  },
  SFTP_AS2_LOG_ADMIN :{
    LIST : "/report-as2-sftp-log",
  }
  
};
/// user seller
export const ROUTES_USER_SELLER ={
  MASTER :"/master",
  // product
  PRODUCT_PRINCIPAL: {
    LIST: "/product-principal",
    DETAIL: "/product-principal/detail/",
    ADD: "/product-principal/add",
    EDIT: "/product-principal/edit",
  },
  PRODUCT_CUSTOMER: {
    LIST: "/product-customer",
    DETAIL: "/product-customer/detail/",
    ADD: "/product-customer/add",
    EDIT: "/product-customer/edit",
  },
  PRODUCT_CATEGORY: {
    LIST: "/product-category",
    DETAIL: "/product-category/detail/",
    ADD: "/product-category/add",
    EDIT: "/product-category/edit/",
  },
  // mapping
  PRODUCT_MAPPING_HO: {
    LIST: "/product-mapping-ho",
    DETAIL: "/product-mapping-ho/detail/",
    ADD: "/product-mapping-ho/add",
    EDIT: "/product-mapping-ho/edit",
  },
  PRODUCT_MAPPING: {
    LIST: "/product-mapping",
    DETAIL: "/product-mapping/detail/",
    ADD: "/product-mapping/add",
    EDIT: "/product-mapping/edit",
  },
  BKL_MAPPING: {
    LIST: "/bkl-mapping",
    DETAIL: "/bkl-mapping/detail/",
    ADD: "/bkl-mapping/add",
    EDIT: "/bkl-mapping/edit",
  },
  PRODUCT_BKL_MAPPING: {
    LIST: "/product-bkl-mapping",
    DETAIL: "/product-bkl-mapping/detail/",
    ADD: "/product-bkl-mapping/add",
    EDIT: "/product-bkl-mapping/edit",
  },
  PRODUCT_CATEGORY_MAPPING: {
    LIST: "/product-category-mapping",
    DETAIL: "/product-category-mapping/detail/",
    ADD: "/product-category-mapping/add",
    EDIT: "/product-category-mapping/edit/",
  },
  PRODUCT_SUBTITUTE :{
    LIST: "/product-substitute",
    DETAIL: "/product-substitute/detail/",
    ADD: "/product-substitute/add",
    EDIT: "/product-substitute/edit",
  },
  DISTRIBUTOR : {
    LIST: "/distributor",
    DETAIL: "/distributor/detail/",
    ADD: "/distributor/add",
    EDIT: "/distributor/edit/",
  },
  WAREHOUSE : {
    LIST : "/warehouse",
    DETAIL : "/warehouse/detail/",
    ADD :"/warehouse/add",
    EDIT : "/warehouse/edit"
  },
  IMPORT_SO :{
    LIST : "/import-so",
  },
  PROCESS_APPROVE_PO :{
    LIST:"/process-approve-po/",
    DETAIL:"/process-approve-po/detail"
  },
  LIST_PROCCESS_PO :{
    LIST:"/list-process-po",
  },
  BKL_SUMMARY :{
    LIST :"/bkl-summary",
    DETAIL : "/bkl-summary/detail"
  },


  //Report
  REPORT_PO : {
    LIST : "/report-po",
  },
  REPORT_PO_VS_SO:{
    LIST:"/po-vs-so",
  },
  REPORT_PO_DETAIL:{
    LIST : "/po-detail",
  },
  DASHBOARD : {
    LIST:"/dashboard-summary",
  },

  // Monitoring
  PO: {
    LIST: "/monitoring-po",
  },
}

export const ROUTES_USER_BUYER = {
  MASTER: "/master",
  // master store
  STORE: {
    LIST: "/store",
    DETAIL: "/master-store/detail/",
    ADD: "/master-store/add",
    EDIT: "/master-store/edit/"
  },
  MAPPING :{
    ADD : "/copy-mapping"
  },

  // process
  PROCESS: {
    LIST: "/processed-po",
    DETAIL: "/process/detail/",
    ADD: "/process/add",
    EDIT: "/process/edit/"
  },
  REPROCESS: {
    LIST: "/reprocess-po",
    DETAIL: "/reprocess/detail/",
    ADD: "/reprocess/add",
    EDIT: "/reprocess/edit/"
  },

  // connection - store supplier connection
  STORE_SUPPLIER_CONNECTION: {
    LIST: "/store-supplier-connection",
    DETAIL: "/store-supplier-connection/detail/",
    ADD: "/store-supplier-connection/add",
    EDIT: "/store-supplier-connection/edit/"
  },

  // Notification - notification
  NOTIFICATION: {
    LIST: "/notification",
    DETAIL: "/notification/detail/",
    ADD: "/notification/add",
    EDIT: "/notification/edit/"
  },

  // Report List Conncetion 
  REPORT_LIST_CONNECTION: {
    LIST: "/list-connection",
  }

}