import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../../constant/apiConstant";


const INITIAL_STATE = {
    list_report_po_detail: [],
    table_loading: false,
    printed_loading : false ,
}

export const getListWarehouse = createAsyncThunk(
  "getListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-connection-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListReportPODetail = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/po-detail",
    async(object, { getState, rejectWithValue }) => {
        let result = { ok: false, response: null };
        const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po-detail`;
        const { auth } = getState();
        const token = auth.token;

        try {
            const response = await baseApi.doGet(url, token, object);
            if (response) {
              result.ok = true;
              result.response = response.data.nexsoft.payload.data.content;
            }
          } catch (error) {
            if (error.response) {
              result.response = error.response;
            } else {
              message.error(i18next.t("COMMON:ERROR_NETWORK"));
            }
          }
          return result;
    }
)

export const getInitListReportPODetail = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/po-detail/initiate",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po-detail/initiate`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

  export const printReportPODetail = createAsyncThunk(
    "printReportPODetail",
    async (object, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po-detail/print`;
      const { auth } = getState();
      const token = auth.token;
  
      try {
        const response = await baseApi.doPostResponseBlob(url, token, object);
        if (response) {
          result.ok = true;
          result.response = response;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
          message.error(error.response.data.nexsoft.payload.status.message);
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  

  const reportPODetail = createSlice({
    name: "createReportPODetail",
    initialState: INITIAL_STATE,
    reducers: {
      resetDetailReportPODetail() {
        return INITIAL_STATE;
      },
    },
    extraReducers: {
      [getListReportPODetail.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListReportPODetail.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.list_report_po_detail = action.payload.response;
      },
      [getListReportPODetail.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },

      [printReportPODetail.pending]: (state, action) => {
        state.printed_loading = true;
      },
      [printReportPODetail.fulfilled]: (state, action) => {
        state.printed_loading = false;
      },
      [printReportPODetail.rejected]: (state, action) => {
       state.printed_loading = false;
      },
    },
  });
  
  export const reportPODetailReducerAction = reportPODetail.actions;
  
  export default reportPODetail.reducer;