import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import {
  InformationIcon,
  SavedSuccess,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  StatusProcess,
  StatusWithCondition,
  Synchronize,
  UnSuccess,
} from "../../../../components/icons";
import {
  GRAY_COLOR,
  GRAY_OPTION,
  GREEN_COLOR,
  HOVER_PRIMARY,
  LABEL_STATUS_SUCCESS,
  PRIMARY_COLOR,
  RED_COLOR,
  WHITE_COLOR,
  YELLOW_COLOR,
} from "../../../../constant/propertiesConstant";
import {
  selectDropDownReprocessPO,
  selectStatusReProcess,
} from "../../../../constant/selectOptConstant";

import moment from "moment";
import CustomCheckBox from "../../../../components/customCheckBox/CustomCheckBox";
import CustomToolTip from "../../../../components/CustomToolTip";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../components/DropDownCustom";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { ID_REPROCESS_PO } from "../../../../constant/idConstant";
import { selectOptFilterUserTheme } from "../../../../constant/themeConstant";
import {
  getInitListReprocessedPO,
  getListCustomerDDS,
  getListPrincipalDDS,
  getListReprocessedPO,
  reprocessedPOAction,
  reprocessReprocessedPO,
} from "../../../../services/main/reprocessedPOReducer";
import StringUtil from "../../../../utils/stringUtil";

const MasterStore = () => {
  const { t } = useTranslation();

  const data = useSelector((state) => state.reprocessedPO.list_reprocessed_po);

  const dataSelectedRowKeys = useSelector(
    (state) => state.reprocessedPO.selectedRowKeys
  );
  const { table_loading } = useSelector((state) => state.reprocessedPO);
  const [total, setTotal] = useState(0);
  const [form, setForm] = useState("");
  const [updatedDate, setUpdatedDate] = useState([]);
  const [filterTransactionDate, setfilterTransactionDate] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  // principal
  // const [principalId, setPrincipalId] = useState(1);
  const [principalId, setPrincipalId] = useState(null);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);
  // checkbox selected print
  const [listID, setListID] = useState([]);

  const [status, setstatus] = useState(null);
  const [submit, setSubmit] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [countSearch, setcountSearch] = useState("");
  const [popOverOpen, setPopOverOpen] = useState(false);
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  const [messages, setMessages] = useState("");
  const [err, setErr] = useState(false);

  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");

  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    }
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const setFilterTransactionDate = useMemo(() => {
    const [start, end] = updatedDate;
    if (start && end) {
      setfilterTransactionDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterTransactionDate([]);
  }, [updatedDate]);

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };

  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    setObjectFilter({
      idCustomer: idCustomer,
      created_at: filterTransactionDate,
      status: status,
      form: form,
      idPrincipal: principalId,
    });
  };
  const resetFilter = () => {
    setForm("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSubmit(true);
    setUpdatedDate([]);
    setstatus(null);
    setIdCustomer(null);
    setPopOverOpen(false);
    setfilterCustomerName("");
    dispatch(reprocessedPOAction.resetselectedRowKeysReprocessedPO());
    setcountSearch("");
    setSelectDropDownFilter("");
    setObjectFilter({
      idCustomer: null,
      created_at: "",
      status: "",
      form: "",
    });
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  // fungsi date range
  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setUpdatedDate([start, end]);
    } else setUpdatedDate([]);
    setIsDisabled(false);
  };
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "customer_id") {
      setIdCustomer(value);
    } else if (name === "principal_id") {
      setPrincipalId(value);
    } else if (name === "status") {
      setstatus(value);
    } else
      setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const [objectFilter, setObjectFilter] = useState({
    idCustomer: null,
    idPrincipal: null,
    status: "",
    form: "",
    created_at: "",
  });

  const filter = StringUtil.formatFilter({
    customer: `like ${objectFilter.idCustomer}`,
    principal: `like ${objectFilter.idPrincipal}`,
    status: `eq ${objectFilter.status}`,
    [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
    created_at: `range ${objectFilter.created_at}`,
  });
  const parameter = { page, limit, filter, order };
  const fetchData = () => {
    dispatch(getListReprocessedPO(parameter)).then((response) => {
      if (!response.payload.ok) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListReprocessedPO(parameter)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    dispatch(reprocessedPOAction.resetselectedRowKeysReprocessedPO());

    fetchData();
    setSubmit(false);
    setIsDisabled(true);
  }, [token, total, submit, order, dispatch]);

  useEffect(() => {
    dispatch(getInitListReprocessedPO(parameter)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
  }, [page, limit]);

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi princapl

  // fungsi customer
  const handleScrollCustomer = (event) => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    // const params = { page, limit };
    const filter = StringUtil.formatFilter({
      alias: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      alias: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
          id={ID_REPROCESS_PO.LIST.BTN_X}
        />
      );
    }
  };

  const handleOnChangeChecked = (event, { id, updated_at, request_id }) => {
    dispatch(
      reprocessedPOAction.setSingleCheck({
        id: id,
        updated_at: updated_at,
        request_id: request_id,
      })
    );
  };

  const handleChangeAllChecked = () => {
    dispatch(reprocessedPOAction.setAllCheck(listID));
  };
  useEffect(() => {
    setListID(
      data?.map((item) => ({
        id: item.id,
        updated_at: item.updated_at,
        request_id: item.request_id,
      }))
    );
  }, [data]);
  const getCheckedAll = () => {
    // listID
    if (listID?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of listID) {
        isCheckedAll = false;
        for (const CheckedData of dataSelectedRowKeys) {
          if (ListIdData.id === CheckedData.id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) break;
      }
      return isCheckedAll;
    } else {
      return;
    }
  };

  const print = () => {
    setModalConfirmOpen(true);
  };

  const checkedAll = getCheckedAll();
  const hasSelected = dataSelectedRowKeys?.length > 0;

  // ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };

  const openModalReprocess = () => {
    const body = {
      list_po: dataSelectedRowKeys,
    };
    setModalOpen(true);
    dispatch(reprocessReprocessedPO(body)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setMessages(resp.payload.response.data.nexsoft.payload.status.message);
      } else {
        setErr(true);
        setMessages(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };

  const clickOk = () => {
    setErr(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
    dispatch(reprocessedPOAction.resetselectedRowKeysReprocessedPO());
    setSubmit(true);
  };
  useEffect(() => {
    return () => {
      dispatch(reprocessedPOAction.resetDetailReprocessedPO());
    };
  }, []);
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      request_id: item.request_id,
      customer_code: item.customer,
      po_number: item.po_number,
      process_date: moment(item.process_date)
        .subtract(7, "hours")
        .format("DD/MM/YYYY"),
      principal: item.principal,
      process_time: moment(item.process_time)
        .subtract(7, "hours")
        .format("DD/MM/YYYY HH:mm:ss"),
      status: item.status,
      message: item.message,
      checkBox: (
        <CustomCheckBox
          isChecked={dataSelectedRowKeys?.find((data) => data.id === item.id)}
          onChange={(event) =>
            handleOnChangeChecked(event, {
              id: item.id,
              updated_at: item.updated_at,
              request_id: item.request_id,
            })
          }
        />
      ),
    }));

  const renderDotColor  =(status)=>{
    if (status === "SUCCESS") return <StatusActive />;
    if (status === "FAILED") return <StatusNonActive />;
    if (status === "PROCESS") return <StatusProcess />;
    if (status === "SUCCESS_WITH_UNMAPPED") return <StatusWithCondition />;
    if (status === "SUCCESS WITHOUT SEND FLAT FILE") return <StatusWithCondition />;
  }

  const renderDotStatus = (status) => {
    if (status === "SUCCESS") return GREEN_COLOR;
    if (status === "FAILED") return RED_COLOR;
    if (status === "PROCESS") return GRAY_COLOR;
    if (status === "SUCCESS_WITH_UNMAPPED") return YELLOW_COLOR;
    if (status === "SUCCESS WITHOUT SEND FLAT FILE") return YELLOW_COLOR;

    return WHITE_COLOR;
  };
  const renderTextStatus = (status) => {
    if (status === "SUCCESS") return t("COMMON:STATUS_SUCCESS");
    if (status === "FAILED") return t("COMMON:STATUS_FAILED");
    if (status === "PROCESS") return t("COMMON:STATUS_PROCESS");
    if (status === "SUCCESS_WITH_UNMAPPED")
      return t("REPROCESSED:STATUS_SUCCESS_WITH_UNMAPPED");
    if (status === "SUCCESS WITHOUT SEND FLAT FILE")
      return t("REPROCESSED:STATUS_SUCCESS_WITHOUT_SEND_FLAT_FILE");

    return "";
  };
  const columns = [
    {
      title: (
        <CustomCheckBox
          isChecked={checkedAll}
          onChange={handleChangeAllChecked}
        />
      ),
      dataIndex: "checkBox",
      key: "checkBox",
      width: 50,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_REQUEST_ID")}
        </Box>
      ),
      dataIndex: "request_id",
      key: "request_id",
      sorter: (a, b) => a.request_id - b.request_id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_CUSTOMER")}
        </Box>
      ),
      dataIndex: "customer_code",
      key: "customer_code",
      sorter: (a, b) => a.customer_code - b.customer_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_PROCESS_DATE")}
        </Box>
      ),
      dataIndex: "process_date",
      key: "transaction_date",
      sorter: (a, b) => a.transaction_date - b.transaction_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:PRINCIPAL")}
        </Box>
      ),
      dataIndex: "principal",
      key: "principal",
      sorter: (a, b) => a.principal - b.principal,
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_PROCESS_TIME")}
        </Box>
      ),
      dataIndex: "process_time",
      key: "transaction_date",
      sorter: (a, b) => a.transaction_date - b.transaction_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
               {renderDotColor(status)}
              </Box>
              <Box color={renderDotStatus(status)}>
                {renderTextStatus(status)}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPROCESSED:FIELD_MESSAGE")}
        </Box>
      ),
      dataIndex: "message",
      key: "message",
      sorter: (a, b) => a.message - b.message,
      sortDirections: ["descend", "ascend"],
      // render(text, data) {
      //   if (data.status === LABEL_STATUS_SUCCESS) return "Ok";
      //   return (
      //     <>
      //       <Flex alignItems={"center"}>
      //         <Text mr={1}>
      //           {data.status === "SUCCESS"
      //             ? t("COMMON:STATUS_SUCCESS")
      //             : t("COMMON:STATUS_FAILED")}
      //         </Text>
      //         <CustomToolTip title={text} placement="bottomRight">
      //           <div>
      //             <InformationIcon />
      //           </div>
      //         </CustomToolTip>
      //       </Flex>
      //     </>
      //   );
      // },
    },
  ];

  return (
    <Box p={4}>
      <HeadTitle
        title={t("REPROCESSED:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPROCESSED:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPROCESSED:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPROCESSED:SUBTITLE_3")}
            </span>{" "}
            - {t("REPROCESSED:TITLE_LIST")}
          </>
        }
        button={
          <ButtonCustom
            id={ID_REPROCESS_PO.LIST.BTN_PROCESS}
            onClick={() => print()}
            disabled={!hasSelected}
            icon={<Synchronize />}
            width="160px"
            height="40px"
            text={t("REPROCESSED:TITLE_LIST")}
            fontSize="16px"
            // marginStart={2}
            colorScheme="white"
            // variant="outline"
            backgroundcolor={hasSelected === true ? PRIMARY_COLOR : "grey"}
          />
        }
      />

      <Box marginTop={6}>
        <Box marginLeft={"7.9rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            onClear={handleClear}
            allowClear={true}
            size="large"
            value={
              selectDropDownFilter.value ? selectDropDownFilter.value : null
            }
          >
            {selectDropDownReprocessPO.map((value, index) => {
              return (
                <>
                  <Select.Option key={index} value={value.value}>
                    {t(value.label)}
                  </Select.Option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="230px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              type="text"
              // placeholder={t("COMMON:SEARCH_FILE_NAME")}
              placeholder={
                selectDropDownFilter.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="15px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={
                selectDropDownFilter.value === undefined ? "none" : "auto"
              }
              value={countSearch}
              borderColor={GRAY_OPTION}
              id={ID_REPROCESS_PO.LIST.SEARCH}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          {/* By date */}
          <Box width="230px">
            <InputDateRangePicker
              elementId={ID_REPROCESS_PO.LIST.PROCESS_DATE}
              values={updatedDate}
              onChange={handleChangeDate}
              placeHolder={t("COMMON:SELECT_DATE")}
            />
          </Box>
          {/* By Principal */}
          <Select
            // id={ID_PROCESS_PO.LIST.PRINCIPAL}
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            placeholder={t("COMMON:PRINCIPAL")}
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.alias}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>

          {/* By Customer */}
          <Select
            id={ID_REPROCESS_PO.LIST.CUSTOMER}
            value={idCustomer}
            style={{ width: 125 }}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("COMMON:CUSTOMER")}
            size="large"
            borderColor={GRAY_OPTION}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={true}
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={data.alias}
                    title={data.alias}
                  >
                    {data.alias}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By Download Status */}
          <Select
            id={ID_REPROCESS_PO.LIST.SELECT_STATUS}
            style={{ width: 110 }}
            onChange={(value, opt) => handleChangeSelect("status", value, opt)}
            value={status}
            size="large"
            borderColor={GRAY_OPTION}
            placeholder={"Status"}
            allowClear={true}
          >
            {selectStatusReProcess?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              isDisabled={isDisabled}
              id={ID_REPROCESS_PO.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_REPROCESS_PO.LIST.BTN_RESET}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          onChange={handleTableChange}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_REPROCESS_PO.LIST.ROW_PAGE}
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalCustom
        icon={err ? <UnSuccess /> : <SavedSuccess />}
        message={messages}
        onOk={clickOk}
        isOpen={modalOpen}
      />
      <ModalConfirm
        colorOk="green"
        messageModal={t("COMMON:TITTLE_DIALOG_REPROCESS")}
        messageConfirm={t("COMMON:BUTTON_REPROCESS")}
        onClose={closeModalConfirm}
        onOk={openModalReprocess}
        isOpen={modalConfirmOpen}
        confirmNo={t("COMMON:NO")}
        confirmYes={t("COMMON:CONFIRM_REPROCESS")}
      />
    </Box>
  );
};

export default MasterStore;
