import {
  Box
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../../components/button/ButtonPrimary";
import CustomToggle from "../../../../../../components/customToggle/CustomToggle";
import { ID_DISTRIBUTOR } from "../../../../../../constant/idConstant";
import { distributorAction } from "../../../../../../services/main/distributorReducer";
const BodyPreferences = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { index_page } = useSelector((state) => state.distributor);
  const { preferences } = useSelector((state) => state.distributor);
  const toNextPage = () => {
    dispatch(distributorAction.setIndexPage(index_page + 1));
    dispatch(
      distributorAction.setCurrPage(
        t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
      )
    );
    dispatch(distributorAction.setDataPreferences(values));
  };
  const toPreviousPage = () => {
    dispatch(distributorAction.setIndexPage(index_page - 1));
    dispatch(
      distributorAction.setCurrPage(
        t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
      )
    );
    dispatch(distributorAction.setDataPreferences(values));
  };
  // for switch(toogle)
  const [isActiveEmailAlert, setIsActiveEmailAlert] = useState(false);
  const [isActiveEmailNotification, setIsActiveEmailNotificaction] =
    useState(false);
  const handleSwitchChange = (name) => { 
    if (name === "activeEmailAlert") {
      const val = !isActiveEmailAlert;
      setFieldValue("is_active_email_alert", val ? true : false);
      setIsActiveEmailAlert(!isActiveEmailAlert);
    }
    if (name === "activeEmailNotification") {
      const val = !isActiveEmailNotification;
      setFieldValue("is_active_email_notification", val ? true : false);
      setIsActiveEmailNotificaction(!isActiveEmailNotification);
    }
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      // principal_id: principalId,
      is_active_email_alert: preferences?.is_active_email_alert,
      is_active_email_notification: preferences?.is_active_email_notification,
    },
    // validationSchema: addUserProfileSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit: toNextPage,
  }); 

  useEffect(() => {
    setIsActiveEmailAlert(preferences.is_active_email_alert);
    setIsActiveEmailNotificaction(preferences.is_active_email_notification);
  }, []);

  return (
    <form>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
        justifyContent="space-between"
        display="flex"
      >
        <Box padding={1} width="404px">
          {/* Active Email Alert */}
          <Box padding={1} display="flex">
            <Box width={"12rem"} marginTop={2}>
              {t("DISTRIBUTOR:FIELD_ACTIVE_EMAIL_ALERT")}
            </Box>
            <Box marginLeft={2}>
              <CustomToggle
                onChange={() => handleSwitchChange("activeEmailAlert")}
                textChecked={t("COMMON:YES")}
                textUnChecked={t("COMMON:NO")}
                isChecked={values?.is_active_email_alert}
                id={ID_DISTRIBUTOR.ADD.ACTIVE_ALERT}
                size="lg"
              />
            </Box>
          </Box>
          {/* Active Email Notification */}
          <Box padding={1} display="flex">
            <Box width={"12rem"} marginTop={2}>
              {t("DISTRIBUTOR:FIELD_ACTIVE_EMAIL_NOTIFICATION")}
            </Box>
            <Box marginLeft={2}>
              <CustomToggle
                onChange={() => handleSwitchChange("activeEmailNotification")}
                textChecked={t("COMMON:YES")}
                textUnChecked={t("COMMON:NO")}
                isChecked={values?.is_active_email_notification}
                size="lg"
                id={ID_DISTRIBUTOR.ADD.ACTIVATE_NOTIFICATION}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          onClick={toNextPage}
        />
      </Box>
    </form>
  );
};

export default BodyPreferences;
