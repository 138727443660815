import {
  Box
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../../components/button/ButtonPrimary";
import CustomToggle from "../../../../../../components/customToggle/CustomToggle";
import {
  DeleteSuccess,
  SavedSuccess
} from "../../../../../../components/icons";
import ModalCustom from "../../../../../../components/ModalCustom";
import { ID_DISTRIBUTOR } from "../../../../../../constant/idConstant";
import { ROUTES_USER_SELLER } from "../../../../../../constant/routeConstant";
import {
  distributorAction,
  editDistributor
} from "../../../../../../services/main/distributorReducer";
const BodyPreferences = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { curr_page } = useSelector((state) => state.distributor);
  const { index_page } = useSelector((state) => state.distributor);
  const { preferences } = useSelector((state) => state.distributor);
  const { detail_company_profile } = useSelector((state) => state.distributor); 
  const { detail_distributor } = useSelector((state) => state.distributor);
  const toNextPage = () => {
    dispatch(distributorAction.setIndexPage(index_page + 1));
    dispatch(
      distributorAction.setCurrPage(
        t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
      )
    );
    dispatch(distributorAction.setDataPreferences(values));
  };
  const toPreviousPage = () => {
    dispatch(distributorAction.setIndexPage(index_page - 1));
    dispatch(
      distributorAction.setCurrPage(
        t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
      )
    );
  };
  const handleCancel = () => {
    dispatch(distributorAction.resetReduxDistributor());
  };
  /// fungsi untuk modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };
  // for switch(toogle)
  const [isActiveEmailAlert, setIsActiveEmailAlert] = useState(false);
  const [isActiveEmailNotification, setIsActiveEmailNotificaction] =
    useState(false);
  const handleSwitchChange = (name) => {
    if (name === "activeEmailAlert") {
      const val = !isActiveEmailAlert;
      setFieldValue("is_active_email_alert", val ? true : false);
      setIsActiveEmailAlert(!isActiveEmailAlert);
      // dispatch(
      //   distributorAction.setDataPreferencesActiveEmail(!isActiveEmailAlert)
      // );
    }
    if (name === "activeEmailNotification") {
      const val = !isActiveEmailNotification;
      setFieldValue("is_active_email_notification", val ? true : false);
      setIsActiveEmailNotificaction(!isActiveEmailNotification);
      // dispatch(
      //   distributorAction.setDataPreferencesActiveNotification(
      //     !isActiveEmailNotification
      //   )
      // );
    }
  };
  const handleOnSubmit = () => {
    const payload = {
      companyProfile: {
        ...detail_company_profile,
        company_name: detail_company_profile?.name,
      },
      preferences: values,
      distributor: detail_distributor,
      distributorId: detail_distributor?.id,
      isPreferences: true,
    };
    dispatch(editDistributor(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      // principal_id: principalId,
      is_active_email_alert: preferences.is_active_email_alert,
      is_active_email_notification: preferences.is_active_email_notification,
    },
    // validationSchema: addUserProfileSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    setIsActiveEmailAlert(preferences.is_active_email_alert);
    setIsActiveEmailNotificaction(preferences.is_active_email_notification);
  }, []);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.DISTRIBUTOR.LIST} />
  ) : (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
        justifyContent="space-between"
        display="flex"
      >
        <Box padding={1} width="404px">
          {/* Active Email Alert */}
          <Box padding={1} display="flex">
            <Box width={"12rem"} marginTop={2}>
              {t("DISTRIBUTOR:FIELD_ACTIVE_EMAIL_ALERT")}
            </Box>
            <Box marginLeft={2}>
              <CustomToggle
                onChange={() => handleSwitchChange("activeEmailAlert")}
                textChecked={t("COMMON:YES")}
                textUnChecked={t("COMMON:NO")}
                isChecked={isActiveEmailAlert}
                size="lg"
                id={ID_DISTRIBUTOR.EDIT.ACTIVE_ALERT}
              />
            </Box>
          </Box>
          {/* Active Email Notification */}
          <Box padding={1} display="flex">
            <Box width={"12rem"} marginTop={2}>
              {t("DISTRIBUTOR:FIELD_ACTIVE_EMAIL_NOTIFICATION")}
            </Box>
            <Box marginLeft={2}>
              <CustomToggle
                onChange={() => handleSwitchChange("activeEmailNotification")}
                textChecked={t("COMMON:YES")}
                textUnChecked={t("COMMON:NO")}
                isChecked={isActiveEmailNotification}
                size="lg"
                id={ID_DISTRIBUTOR.EDIT.ACTIVATE_NOTIFICATION}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={10} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          link={ROUTES_USER_SELLER.DISTRIBUTOR.LIST}
          text={t("COMMON:BUTTON_CANCEL")}
          onClick={handleCancel}
          id={ID_DISTRIBUTOR.EDIT.BTN_CANCEL}
        />
        <ButtonPrimary
          text={t("COMMON:BUTTON_SAVE")}
          width="9.375rem"
          type="submit"
          id={ID_DISTRIBUTOR.EDIT.BTN_SAVE}
        />
      </Box>
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <DeleteSuccess /> : <SavedSuccess />}
      />
    </form>
  );
};

export default BodyPreferences;
