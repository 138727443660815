import { isAfter } from "date-fns";
import jwtDecode from "jwt-decode";
import * as ActionType from "../actions/actionType";
import { userCategoryType } from "../constant/propertiesConstant";

export const setLanguage = (locale, dispatch) => {
  dispatch({ type: ActionType.SET_LANGUAGE, payload: locale });
};


/**
 * This function input array of object and key is object key, and return unique of that array of object
 * @param {Array} array - Array of object
 * @param {string} key - key of object that make unique
 * @returns Filtered array, unique every object
 */
 export const createUniqueArray = (array = [], key) => {
  if(!array){
    return [];
  }
  const set = new Set();
  return array.filter(item => {
    let k = item[key];
    return set.has(k) ? false : set.add(k)
  })
}


/**
 * 
 * @param {string} userCategory - value user categorynya
 * @param {string | Array<string>} acceptCategoryType 
 */
export const checkUsersCategory = (userCategory, acceptCategoryType) => {
  let isHaveCategory = false;
  if (!userCategory) return isHaveCategory;
  switch (typeof acceptCategoryType) {
    case 'string': 
      if (userCategory.toLowerCase() === acceptCategoryType.toLowerCase()) {
        isHaveCategory = true;
      }
      break;
    default:
      for (const type of acceptCategoryType) {
        if (userCategory.toLowerCase() === type?.toLowerCase()) {
          isHaveCategory = true;
          break;
        }
      }
      break;
  }

  return isHaveCategory;
}

export const checkIsTokenExpired = (token) => {
  if (token){
  const jwt = jwtDecode(token);
  const currentTime = new Date();
  const expiredTime = new Date(jwt.exp * 1000);

  return isAfter(currentTime, expiredTime);
  }
}
