import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list_distributor: [],
  list_store: [],
  list_store_target: [],
  distributor_loading: false,
  store_loading: false,
  store_target_loading: false,
  proccess_mapping_loading: false,
  status: "",
};

export const processCopyMapping = createAsyncThunk(
  "processCopyMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-mapping-copy`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      distributor_id: [object?.distributor_id],
      store_id: [object?.store_id],
      store_id_target: object?.store_id_target,
    };
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCSMForDistributor = createAsyncThunk(
  "getListCSMForDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/filter-product-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCSMForDistributorTarget = createAsyncThunk(
  "getListCSMForDistributorTarget",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/filter-product-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const copyMapping = createSlice({
  name: "copyMapping",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxCopyMapping() {
      return INITIAL_STATE;
    },
  },

  extraReducers: {
    [getListDistributorDDS.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListDistributorDDS.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getListDistributorDDS.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getListCSMForDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListCSMForDistributor.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getListCSMForDistributor.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getListCSMForDistributorTarget.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListCSMForDistributorTarget.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getListCSMForDistributorTarget.rejected]: (state, action) => {
      state.status = "failed";
    },

    [processCopyMapping.pending]: (state, action) => {
      state.status = "loading";
      state.proccess_mapping_loading = true;
    },
    [processCopyMapping.fulfilled]: (state, action) => {
      state.status = "success";
      state.proccess_mapping_loading = false;
    },
    [processCopyMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.proccess_mapping_loading = false;
    },
  },
});

export const copyMappingAction = copyMapping.actions;

export default copyMapping.reducer;
