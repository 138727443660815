export function arrayOrArrOfObj(arr) {
  let result;
  if (Array.isArray(arr) && typeof arr[0] === "object" && arr.length > 0) {
    // yourVariable is an array of objects
    result = true;
  } else {
    // yourVariable is not an array of objects
    result = false;
  }
  return result;
}
