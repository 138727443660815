import Spinner from "./Spinner";

const LoadingIndicatorSpinner = ({ isLoading, isDisabled, ...restProps }) => {
  return (
      <Spinner size='sm' hidden={!isLoading} isDisabled={isDisabled} thickness='2px' {...restProps} />
  );
};

export const ReactSelectLoadingIndicatorSpinner = ({ selectProps }) => {
  return <LoadingIndicatorSpinner isDisabled={selectProps?.isDisabled} isLoading={selectProps?.isLoading} />
}

export default LoadingIndicatorSpinner;