import {
  Box,
  Icon,
  Input,
  InputGroup,
  Text,
  InputLeftAddon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import { ROUTES } from "../../../constant/routeConstant";
import { Radio } from "antd";
import { useHistory } from "react-router-dom";
import { useWithRequestActivationCode } from "../../../services/main/sessionReducer";
import ModalCustom from "../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess, UnSuccess } from "../../../components/icons";
import { useForm } from "react-hook-form";
import { REGEX_CONSTANT } from "../../../constant/regexConstant";
import ValidationError from "../../../components/validationErrorMessage/ValidationError";

const requestActivationInitialValue = {
  username: '',
  email: '',
  phone: ''
}

const RequestActivationCode = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [initialValue, setinitialValue] = useState(t("REQUEST_ACTIVATION_CODE:FIELD_EMAIL"));

  const {
    control,
    register,
    handleSubmit,

    formState: { errors, touchedFields }
  } = useForm({
    mode: 'onChange',
    defaultValues: requestActivationInitialValue,
    
  });

  const {
    handleOnSubmit,
    is_submitting,
    response,
    error,
    has_error
  } = useWithRequestActivationCode({
    onFinally: () => setOpen(true)
  });

  const handleOnFormSubmit = () => {    
    handleSubmit(handleOnSubmit)();
  }

 

  const onChange = (e) => {
    setinitialValue(e.target.value);
  };

  const handleOnFailedClick = () => {
    setOpen(false);
  }

  const handleOnSuccessClick = () => {
    setOpen(false);
    history.push(ROUTES.HOME);
  }


  const WithTelepon = () => {
    return (
      <>
        {/* Username  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
          {t("REQUEST_ACTIVATION_CODE:FIELD_USERNAME")}
          </Box>
          <Box>
            <Input type="text" width="14rem" textColor="black" />
          </Box>
        </Box>
        {/* Telepon */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
          {t("REQUEST_ACTIVATION_CODE:FIELD_TELEPON")}
          </Box>
          <Box>
            <InputGroup>
              <InputLeftAddon children="+62" />
              <Input type="tel" placeholder="phone number" width="10rem"/>
            </InputGroup>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box padding={4}>
      {/* Head Tittle  */}
      <HeadTitle
        title={t("REQUEST_ACTIVATION_CODE:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>
              {t("REQUEST_ACTIVATION_CODE:HOME")}
            </span>{" "}
            - {t("REQUEST_ACTIVATION_CODE:TITLE_LIST")}
          </>
        }
      />
      {/* White Background  */}
      <Box backgroundColor="white" minHeight="300px" padding={4} marginTop={6}>
        {/* Pilih  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
          {t("REQUEST_ACTIVATION_CODE:TEXT_PILIH")}
          </Box>
          <Box>
            <Radio.Group onChange={onChange} value={initialValue}>
              <Radio value="Email">{t("REQUEST_ACTIVATION_CODE:FIELD_EMAIL")}</Radio>
              <Radio value="Telepon" disabled>{t("REQUEST_ACTIVATION_CODE:FIELD_TELEPON")}</Radio>
            </Radio.Group>
          </Box>
        </Box>
        
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
          {t("REQUEST_ACTIVATION_CODE:FIELD_USERNAME")}
          </Box>
          <Box>
            <Input 
              type="text" 
              width="14rem" 
              textColor="black"  
              {...register('username', {
                required: t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_USERNAME") }),
                minLength: {
                  value: 6,
                  message: t("COMMON:ERROR_MIN_LENGTH", { length: 6 })
                },
                maxLength: {
                  value: 20,
                  message: t("COMMON:ERROR_MAX_LENGTH", { length: 20 })
                },
                pattern: {
                  value: REGEX_CONSTANT.USERNAME,
                  message: t('USER:ERROR_USERNAME')
                }
              })}
            />
            {(errors.username && touchedFields.username) && (
                <ValidationError text={errors.username.message} />
              )}
          </Box>
        </Box>

        {/* Email */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
          {t("REQUEST_ACTIVATION_CODE:FIELD_EMAIL")}
          </Box>
          <Box>
            <Input 
              type="text" 
              width="14rem" 
              textColor="black" 
            {...register('email', {
              pattern: {
                value: REGEX_CONSTANT.EMAIL,
                message: t("USER:ERROR_EMAIL")
              },
              maxLength: {
                value: 100,
                message: t("COMMON:ERROR_MAX_LENGTH", { length: 100 })
              },
              required: t("COMMON:ERROR_REQUIRED", { field: t("USER:FIELD_EMAIL") })
            })}
            />
            {(errors.email && touchedFields.email) && (
                <ValidationError text={errors.email.message} />
              )}
          </Box>
        </Box>
      </Box>

      {/* Button  */}
      <Box display="flex" marginTop={4} float="right">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          // type='submit'
          // onClick={history.push(ROUTES.HOME)}
          link={ROUTES.HOME}
          text={t("COMMON:BUTTON_CANCEL")}
          isLoading={is_submitting}
          isDisabled={is_submitting}
        />
        <ButtonPrimary 
          text={t("COMMON:BUTTON_RESEND")} 
          width="9.375rem" 
          onClick={() => handleOnFormSubmit()}
          isLoading={is_submitting}
          isDisabled={is_submitting}
        />
      </Box>
      <ModalCustom
        icon={has_error ? <UnSuccess /> : <SavedSuccess />}
        message={has_error ? error?.message : response?.message }
        onOk={has_error ? handleOnFailedClick : handleOnSuccessClick}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default RequestActivationCode;
