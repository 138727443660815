import { Box, Stack, Text } from "@chakra-ui/react";
import { Select } from "antd";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../components/button/ButtonCustom";
import InputDateRangePicker from "../../components/datePicker/InputDateRangePicker";
import HeadTitle from "../../components/HeadTitle";
import {
  GRAY_COLOR,
  GRAY_DISABLED,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../constant/propertiesConstant";
import { selectOptFilterPrincipal } from "../../constant/themeConstant";
import { getListDashboard } from "../../services/main/dashboardReducer";

import moment from "moment";
import "./Dashboard.css";
import { ID_DASHBOARD } from "../../constant/idConstant";
const Dashboard = () => {
  const today = new Date();
  const currentWeek = moment().subtract(7, "days").toDate();
  // const prevMonth = moment().subtract(1, "month").startOf("month").toDate();
  const thisMonth = moment().startOf("month").toDate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);

  const [filterPicker, setfilterPicker] = useState("This Week");
  const principal_id_auth = useSelector((state) => state.auth.principal_id);

  const [submit, setSubmit] = useState(false);
  const [listData, setListData] = useState([]);
  const data = useSelector((state) => state.dashboard.listDashboard);
  const { token } = useSelector((state) => state.auth);

  const [dateRange, setDateRange] = useState([currentWeek, today]);

  const body = {
    start_date: moment(dateRange[0]).format("YYYY-MM-DD") + "T00:00:00Z",
    end_date: moment(dateRange[1]).format("YYYY-MM-DD") + "T23:59:59Z",
    principal_id: principal_id_auth,
  };
  useEffect(() => {
    dispatch(getListDashboard(body));
    setSubmit(false);
    setIsDisabled(true);
  }, [token, submit, dispatch]);

  useEffect(() => {
    setListData(data);
  }, [data]);

  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setDateRange([start, end]);
    } else setDateRange([]);
    setIsDisabled(false);
  };

  const submitOk = () => {
    setSubmit(true);
  };

  const resetFilter = () => {
    setSubmit(true);
    setIsDisabled(true);
    setfilterPicker("This Week");
    setDateRange([currentWeek, today]);
  };
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    setfilterPicker(option.children);
    if (option.value === "thisWeek") {
      setDateRange([currentWeek, today]);
    } else if (option.value === "thisMonth") {
      setDateRange([thisMonth, today]);
    }
  };

  const dataPODaily =
    listData.length === 0
      ? []
      : listData?.po_daily_out?.reduce(
          (previousValue, currentValue, index, arr) => {
            const {
              po_distributor,
              po_principal,
              total_po,
              total_po_distributor,
              total_po_principal,
            } = previousValue;

            let dateString = moment(currentValue.date, "YYYY-DD-MM").format(
              "MMM D"
            );
            if (arr.length - 1 === index) {
              dateString = "Total";
            }
            po_distributor.push({
              x: dateString,
              y: currentValue.po_distributor,
            });
            po_principal.push({
              x: dateString,
              y: currentValue.po_principal,
            });
            total_po.push({ x: dateString, y: currentValue.total_po });
            total_po_distributor.push({
              x: dateString,
              y: currentValue.total_po_distributor,
            });
            total_po_principal.push({
              x: dateString,
              y: currentValue.total_po_principal,
            });

            return previousValue;
          },
          {
            po_distributor: [],
            po_principal: [],
            total_po: [],
            total_po_distributor: [],
            total_po_principal: [],
          }
        );

  const labelPOTIV =
    listData.length === 0
      ? []
      : Object.entries(listData?.po_per_customer).reduce(
          (prev, curr, index) => {
            const { label, data } = prev;
            const [key, value] = curr;
            label.push(key);
            data.push(value);
            return prev;
          },
          {
            label: [],
            data: [],
          }
        );

  const labelDownload =
    listData.length === 0
      ? []
      : Object.entries(listData?.interval_download).reduce(
          (prev, curr, index) => {
            const { data } = prev;
            const [value] = curr;
            data.push(value);
            return prev;
          },
          {
            data: [],
          }
        );

  const labelUpload =
    listData.length === 0
      ? []
      : Object.entries(listData?.interval_upload).reduce(
          (prev, curr, index) => {
            const { data } = prev;
            const [value] = curr;
            data.push(value);
            return prev;
          },
          {
            data: [],
          }
        );

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );
  ChartJS.register({
    id: "customSpacingLegend",
    beforeInit(chart) {
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;

      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height as suggested in another answers
        this.height += 20;
      };
    },
  });
  // buat kustomisasi margin milik legend chart js
  // const legendMargin = {
  //   id: "legendMargin",
  //   beforeInit(legend, options) {
  //     const originalFit = ChartJS.legend.fit;
  //     // Override the fit function
  //     ChartJS.legend.fit = function fit() {
  //       // Call original function and bind scope in order to use `this` correctly inside it
  //       originalFit.bind(ChartJS.legend)();
  //       this.height += 20;
  //     };
  //   },
  // };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // buat ngeubah title
      title: {
        align: "start",
        display: false,
        text: "PO Daily",
      },

      // buat ngeubah sub title
      legend: {
        align: "end",
        display: false,
        position: "top",
        labels: {
          font: {
            size: 10,
          },
          usePointStyle: true,
        },
      },

      maintainAspectRatio: false,
    },
    // ini buat matin atau aktifin garis grid sesuai axis X atau Y
    scales: {
      x: {
        // numpuk kearah atas
        stacked: false,
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
      y: {
        // numpuk kearah kanan
        stacked: false,
        // garis axis Y
        grid: {
          drawBorder: false,
          color: GRAY_DISABLED,
        },
      },
    },
  };

  const dataDaily = {
    labels: [],
    datasets: [
      {
        label: "PO Distributor",
        data: dataPODaily?.po_distributor,
        backgroundColor: "#68C083",
      },
      {
        label: "PO Principal",
        data: dataPODaily?.po_principal,
        backgroundColor: "#267CB3",
      },
      {
        label: "Total PO Distributor",
        data: dataPODaily?.total_po_distributor,
        backgroundColor: "#68C083",
      },
      {
        label: "Total PO Principal",
        data: dataPODaily?.total_po_principal,
        backgroundColor: "#267CB3",
      },
      {
        label: "Total PO",
        data: dataPODaily?.total_po,
        backgroundColor: "#8760CA",
      },
    ],
  };

  const data_poo = {
    total_po_principal: [
      {
        x: "PO Process",
        y: listData?.total_po_out?.total_po_principal_distributor
          ?.total_po_principal,
      },
    ],
    total_po_distributor: [
      {
        x: "PO Process",
        y: listData?.total_po_out?.total_po_principal_distributor
          ?.total_po_distributor,
      },
    ],
    total_po_success: [
      {
        x: "PO TIV",
        y: listData?.total_po_out?.total_po_success_failed?.total_po_success,
      },
    ],
    total_po_failed: [
      {
        x: "PO TIV",
        y: listData?.total_po_out?.total_po_success_failed?.total_po_failed,
      },
    ],
    total_po_checked: [
      {
        x: "PO Success",
        y: listData?.total_po_out?.total_po_check_uncheck?.total_po_checked,
      },
    ],
    total_po_unchecked: [
      {
        x: "PO Success",
        y: listData?.total_po_out?.total_po_check_uncheck?.total_po_unchecked,
      },
    ],
    total_po_notyet: [
      {
        x: "PO Checked",
        y: listData?.total_po_out?.total_po_download_not_downloaded
          ?.total_po_notyet,
      },
    ],
    total_po_downloaded: [
      {
        x: "PO Checked",
        y: listData?.total_po_out?.total_po_download_not_downloaded
          ?.total_po_downloaded,
      },
    ],
  };
  const dataPO = {
    labels: [],

    datasets: [
      {
        label: "Total PO Principal",
        data: data_poo?.total_po_principal,
        backgroundColor: "#267CB3",
      },
      {
        label: "Total PO Distributor",
        data: data_poo?.total_po_distributor,
        backgroundColor: "#68C083",
      },
      {
        label: "Total PO Success",
        data: data_poo?.total_po_success,
        backgroundColor: "#F9D55B",
      },
      {
        label: "Total PO Failed",
        data: data_poo?.total_po_failed,
        backgroundColor: "#ED6648",
      },
      {
        label: "Total PO Checked",
        data: data_poo?.total_po_checked,
        backgroundColor: "#8760CA",
      },
      {
        label: "Total PO Unchecked",
        data: data_poo?.total_po_unchecked,
        backgroundColor: "#6DDBDC",
      },
      {
        label: "Total PO Notyet",
        data: data_poo?.total_po_notyet,
        backgroundColor: "#A1BF39",
      },
      {
        label: "Total PO Downloaded",
        data: data_poo?.total_po_downloaded,
        backgroundColor: "#E371AC",
      },
    ],
  };
  const optionsPO = {
    barThickness: 13,
    borderRadius: 5,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // buat ngeubah title
      title: {
        align: "start",
        display: false,
        text: "PO Daily",
      },

      // buat ngeubah sub title
      legend: {
        align: "start",
        display: false,
        position: "top",
        labels: {
          font: {
            size: 10,
          },
          usePointStyle: true,
        },
      },

      maintainAspectRatio: false,
    },
    // ini buat matin atau aktifin garis grid sesuai axis X atau Y
    scales: {
      x: {
        // numpuk kearah atas
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        // numpuk kearah kanan
        stacked: true,
        // garis axis Y
        grid: {
          drawBorder: false,
          color: GRAY_DISABLED,
          drawTicks: false,
        },
      },
    },
  };
  // pie total PO
  const dataPOTIV = {
    labels: labelPOTIV.label,
    datasets: [
      {
        data: labelPOTIV?.data,
        backgroundColor: [
          "#267CB3",
          "#68C083",
          "#F9D55B",
          "#ED6648",
          "#8760CA",
          "#6DDBDC",
          "#A1BF39",
          "#E371AC",
          "#5F870F",
          "#E04444",
          "#385ED5",
          "#6A1082",
          "#F9D18B",
        ],
        borderWidth: 0,
      },
    ],
  };
  const dataInterval = {
    labels: ["12hr", "24hr", "30hr", "36hr", "42hr", "48hr", ">48hr"],
    datasets: [
      {
        data: labelDownload?.data,
        backgroundColor: [
          "#267CB3",
          "#68C083",
          "#F9D55B",
          "#ED6648",
          "#8760CA",
          "#6DDBDC",
          "#A1BF39",
        ],
        borderWidth: 0,
      },
    ],
  };
  const optionsPOTIV = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      colors: {
        enabled: true,
      },
      // buat ngeubah title
      title: {
        display: false,
        text: "Total PO ",
        labels: {
          usePointStyle: true,
        },
        color: "black",
        font: {
          size: 16,
          weight: "bold",
        },
      },

      // buat ngeubah sub title
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 10,
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
    },
  };
  // pie total PO
  const dataUpload = {
    labels: ["12hr", "24hr", "30hr", "36hr", "42hr", "48hr", ">48hr"],
    datasets: [
      {
        data: labelUpload.data,
        backgroundColor: [
          "#267CB3",
          "#68C083",
          "#F9D55B",
          "#ED6648",
          "#8760CA",
          "#6DDBDC",
          "#A1BF39",
        ],
        borderWidth: 0,
      },
    ],
  };
  return (
    <Box p={4}>
      <HeadTitle
        title={t("DASHBOARD:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DASHBOARD:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DASHBOARD:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DASHBOARD:SUBTITLE_3")}
            </span>{" "}
            - {t("DASHBOARD:TITLE_LIST")}
          </>
        }
        button={
          <Stack direction="row" alignItems="center">
            <Box width="240px">
              <InputDateRangePicker
                elementId={ID_DASHBOARD.LIST.DATE}
                values={dateRange}
                onChange={handleChangeDate}
                defaultValue={[currentWeek, today]}
              />
            </Box>
            <Box className="my-select-container">
              <Select
                style={selectOptFilterPrincipal}
                onChange={(value, opt) =>
                  handleChangeSelect("datePick", value, opt)
                }
                allowClear="true"
                size="large"
                placeholder="Range Pick Date"
                value={filterPicker}
              >
                <Select.Option value={"thisMonth"}>This Month</Select.Option>
                <Select.Option value={"thisWeek"}>This Week</Select.Option>
              </Select>
            </Box>
            <Box>
              <ButtonCustom
                id={ID_DASHBOARD.LIST.BTN_SUBMIT}
                text={t("COMMON:BUTTON_APPLY")}
                backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
                color={WHITE_COLOR}
                fontWeight={500}
                fontSize={14}
                width="93px"
                onClick={submitOk}
                marginRight={2}
                isDisabled={isDisabled}
              />
            </Box>
            <Box>
              <Text
                id={ID_DASHBOARD.LIST.BTN_RESET}
                color={PRIMARY_COLOR}
                fontWeight={500}
                fontSize={14}
                onClick={resetFilter}
                pointerEvents="auto"
                cursor="pointer"
              >
                {t("COMMON:BUTTON_RESET_FILTER")}
              </Text>
            </Box>
          </Stack>
        }
      />
      <Box
        display="flex"
        flexDirection="row"
        marginTop={4}
        borderRadius="10px"
        justifyContent="space-between"
      >
        <Box
          backgroundColor="white"
          height="351px"
          width="48vw"
          borderRadius="10px"
          padding="20px"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <HeadTitle titleSection={t("DASHBOARD:PO_DAILY")} />
            <Box>
              <span style={{ backgroundColor: "#68C083" }} class="dot"></span>
              <span class="title">{t("DASHBOARD:PO_DAILY_1")}</span>
              <span style={{ backgroundColor: "#267CB3" }} class="dot"></span>
              <span class="title">{t("DASHBOARD:PO_DAILY_2")}</span>
              <span style={{ backgroundColor: "#68C083" }} class="dot"></span>
              <span class="title">{t("DASHBOARD:PO_DAILY_4")}</span>
              <span style={{ backgroundColor: "#267CB3" }} class="dot"></span>
              <span class="title">{t("DASHBOARD:PO_DAILY_5")}</span>
              <span style={{ backgroundColor: "#8760CA" }} class="dot"></span>
              <span class="title">{t("DASHBOARD:PO_DAILY_3")}</span>
            </Box>
          </Box>
          <Box height="300px">
            <Bar options={options} data={dataDaily} />
          </Box>
        </Box>
        <Box
          backgroundColor="white"
          height="351px"
          width="28vw"
          borderRadius="10px"
          padding="20px"
        >
          <Box ox display="flex" flexDirection="column">
            <HeadTitle titleSection={t("DASHBOARD:TOTAL_PO")} />
            <Box>
              <Text>
                <span style={{ backgroundColor: "#33B6DF" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_1")}</span>
                <span style={{ backgroundColor: "#5DBB55" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_2")}</span>
                <span style={{ backgroundColor: "#EAB52C" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_3")}</span>
                <span style={{ backgroundColor: "#BC2023" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_4")}</span>
              </Text>
              <Text>
                <span style={{ backgroundColor: "#9257DE" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_5")}</span>
                <span style={{ backgroundColor: "#85EBF1" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_6")}</span>
                <span style={{ backgroundColor: "#8ae3a9" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_7")}</span>
                <span style={{ backgroundColor: "#DA6BDC" }} class="dot"></span>
                <span class="title">{t("DASHBOARD:TOTAL_PO_8")}</span>
              </Text>
            </Box>
          </Box>
          <Box height="250px">
            <Bar options={optionsPO} data={dataPO} />
          </Box>
        </Box>
      </Box>
      {/* triple white board */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginTop={4}
      >
        {/* Po Seller */}
        <Box
          p={4}
          width="25vw"
          height="20vw"
          backgroundColor="white"
          borderRadius="10px"
        >
          <Box pb={2} display="flex" justifyContent="center">
            <HeadTitle titleSection={t("DASHBOARD:PO_TIV")} />
          </Box>
          <Pie options={optionsPOTIV} data={dataPOTIV} />
        </Box>
        <Box
          p={4}
          width="25vw"
          height="20vw"
          backgroundColor="white"
          borderRadius="10px"
        >
          <Box pb={2} display="flex" justifyContent="center">
            <HeadTitle titleSection={t("DASHBOARD:INTERVAL_DOWNLOAD")} />
          </Box>
          <Pie options={optionsPOTIV} data={dataUpload} />
        </Box>
        <Box
          p={4}
          width="25vw"
          height="20vw"
          backgroundColor="white"
          borderRadius="10px"
        >
          <Box pb={2} display="flex" justifyContent="center">
            <HeadTitle titleSection={t("DASHBOARD:INTERVAL_UPLOAD")} />
          </Box>
          <Pie options={optionsPOTIV} data={dataInterval} />
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
