import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackToList from "../../../../components/BackToList";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  BlueEditIcon,
  DeleteSuccess, RedDeleteIcon,
  SavedSuccess
} from "../../../../components/icons";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import { ID_STORE_MASTER } from "../../../../constant/idConstant";
import { PRIMARY_COLOR } from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";

import { deleteMasterStore, detailMasterStore } from "../../../../services/main/masterStoreReducer";

const DetailStore = () => {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [deletedStore, setDeletedStore] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const initialDataStore = {
    store_id: "",
    store_code: "",
    customer_name: "",
    customer_code: "",
    store_name: "",
    phone: "",
    status: "",
    address_1: "",
    country_name: "",
    island_name: "",
    province_name: "",
    district_name: "",
    sub_district_name: "",
    urban_village_name: "",
  };

  // fungsi modal
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.STORE.LIST);
    setModalOpen(false);
  };
  const modalFailed = () => {
    setModalConfirm(false);
    setModalOpen(false);
  };
  const confirmModal = () => {
    setModalConfirm(true);
    setMessageConfirm(t("COMMON:DELETE_CONFIRM"));
  };

  const confirmedDelete = () => {
    setModalConfirm(false);
    dispatch(deleteMasterStore(params)).then((resp) => {
      if (resp.payload.ok) {
        setErr(true);
        setModalOpen(true);
        setResponse(resp.payload.response);
        setDeletedStore(true);
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response);
        setErr(true);
      }
    });
  };

  const [dataStore, setDataStore] = useState(initialDataStore);
  // let dataId = useParams();
  const dispatch = useDispatch();
  const fetchData = async () => {
    dispatch(detailMasterStore(location.state)).then((response) => {
      if (response.payload.ok) {
        setDataStore(response.payload.response.store);
      } else {
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const detailData = useSelector((data) => data.masterStore.detail_store);
  const dataProduct = detailData?.data_management_history;
  const updated_at = dataProduct.modified_on;
  const storeMasterId = location.state;
  const params = { storeMasterId, updated_at };

  return (
    <Box p={4}>
      <HeadTitle
        title={t("STORE:DETAIL_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:TITLE_LIST")}
            </span>{" "}
            - {t("STORE:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              id={ID_STORE_MASTER.DETAIL.BTN_EDIT}
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES_USER_BUYER.STORE.EDIT,
                state: location.state,
              }}
            />
            <ButtonCustom
              id={ID_STORE_MASTER.DETAIL.BTN_DELETE}
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={confirmModal}
            />
          </>
        }
      />
      {/* back to list and created by section */}
      <BackToList
        id={ID_STORE_MASTER.DETAIL.BTN_BACK}
        link={ROUTES_USER_BUYER.STORE.LIST}
        name={dataProduct.created_name}
        date={moment(dataProduct.created_on).format("DD/MM/YYYY")}
        modifiedDate={moment(dataProduct.modified_on).format("DD/MM/YYYY")}
        modifiedBy={dataProduct.modified_name}
      />

      {/* White Box section */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        display="flex"
        marginTop={2}
        borderRadius="10px"
      >
        <Box minWidth="37vw">
          {/* row 1 */}
          <Box display="flex">
            {/* left side */}
            <Box padding={1} width="13rem">
              {/* Store ID */}
              <Box padding={1} width="20rem">
                <Box>{t("STORE:FIELD_STORE_ID")}</Box>
                <Box>
                  <Text>
                    <b>
                      {dataStore.store_id === "" ? "-" : dataStore.store_id}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* CUSTOMER CODE*/}
              <Box padding={1} width="20rem">
                <Box width="10rem">
                  <Text>{t("STORE:FIELD_CUSTOMER_CODE")}</Text>
                  <Text>
                    <b>
                      {dataStore.customer_code === ""
                        ? "-"
                        : dataStore.customer_code}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* CUSTOMER NAME*/}
              <Box padding={1} width="20rem">
                <Box>
                  <Text>{t("STORE:FIELD_CUSTOMER_NAME")}</Text>
                </Box>
                <Text>
                  <b>
                    {dataStore.customer_name === ""
                      ? "-"
                      : dataStore.customer_name}
                  </b>
                </Text>
              </Box>
              {/* CUSTOMER CODE*/}
              <Box padding={1} width="20rem">
                <Box>
                  <Text>{t("STORE:FIELD_STORE_CODE")}</Text>
                </Box>
                <Text>
                  <b>
                    {dataStore.store_code === "" ? "-" : dataStore.store_code}
                  </b>
                </Text>
              </Box>
              {/* Store Name*/}
              <Box padding={1} width="20rem">
                <Box>
                  <Text>{t("STORE:FIELD_STORE_NAME")}</Text>
                </Box>
                <Text>
                  <b>
                    {" "}
                    {dataStore.store_name === "" ? "-" : dataStore.store_name}
                  </b>
                </Text>
              </Box>
              {/* phone*/}
              <Box padding={1} width="20rem">
                <Box width="10rem">
                  <Text>{t("STORE:FIELD_PHONE")}</Text>
                </Box>
                <Text>
                  <b>{dataStore.phone === "" ? "-" : dataStore.phone}</b>
                </Text>
              </Box>
              {/* status*/}
              <Box padding={1} width="20rem">
                <Box width="10rem">
                  <Text>{t("STORE:FIELD_STATUS")}</Text>
                </Box>
                <Text>
                  <b>{dataStore.status === "A" ? t("COMMON:STATUS_ACTIVE") : t("COMMON:STATUS_INACTIVE")}</b>
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Right side */}
        <Box padding={1}>
          {/* Address */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE:FIELD_ADDRESS")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {dataStore.address_1 === "" ? "-" : dataStore.address_1}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Postal Code */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE:FIELD_POSTAL_CODE")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {dataStore.postal_code_name === ""
                    ? "-"
                    : dataStore.postal_code_name}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Country */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE:FIELD_COUNTRY")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {dataStore.country_name === ""
                    ? "-"
                    : dataStore.country_name}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Island */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE:FIELD_ISLAND")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {dataStore.island_name === ""
                  ? "-"
                  : dataStore.island_name}{" "}
              </b>
            </Text>
          </Box>
          {/* Province */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE:FIELD_PROVINCE")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {dataStore.province_name === ""
                    ? "-"
                    : dataStore.province_name}
                </b>
              </Text>
            </Box>
          </Box>
          {/* District */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE:FIELD_DISTRICT")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {dataStore.district_name === ""
                  ? "-"
                  : dataStore.district_name}{" "}
              </b>
            </Text>
          </Box>
          {/* Sb-District */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE:FIELD_SUB_DISTRICT")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {dataStore.sub_district_name === ""
                  ? "-"
                  : dataStore.sub_district_name}{" "}
              </b>
            </Text>
          </Box>
          {/* Urban Village */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE:FIELD_URBAN_VILLAGE")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {dataStore.urban_village_name === ""
                  ? "-"
                  : dataStore.urban_village_name}{" "}
              </b>
            </Text>
          </Box>
        </Box>
        <ModalConfirm
          isOpen={modalConfirm}
          messageModal={t("COMMON:DELETE_TITLE")}
          messageConfirm={messageConfirm}
          onOk={confirmedDelete}
          onClose={modalFailed}
          confirmYes={t("COMMON:YES")}
          confirmNo={t("COMMON:NO")}
        />
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={deletedStore ? modalSuccess : modalFailed}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Box>
    </Box>
  );
};

export default DetailStore;
