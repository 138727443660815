import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  CloseButton,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { message, Pagination, Select, Table } from "antd";
import { useTranslation } from "react-i18next";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  RED_COLOR,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import "react-day-picker/lib/style.css";
import {
  SearchIcon,
  DetailIcon,
  StatusActive,
  StatusNonActive,
} from "../../../../components/icons";
import { ROUTES } from "../../../../constant/routeConstant";
import { useDispatch, useSelector } from "react-redux";
import {
  getInitListStoreSupplier,
  getListCustomerDDS,
  getListDistributorDDS,
  getListStoreSupplier,
} from "../../../../services/main/storeSupplierConnectionReducer";
import {
  selectOptFilterDistributorTheme,
  selectOptFilterUserTheme,
  selectFilterProcessTheme,
} from "../../../../constant/themeConstant";
import { selectDropDownFilterStore } from "../../../../constant/selectOptConstant";
import DropDownCustom from "../../../../components/DropDownCustom";
import { getListDistributor } from "../../../../services/main/distributorReducer";
import StringUtil from "../../../../utils/stringUtil";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { getListCustomer } from "../../../../services/main/customerReducer";
import { ID_MONITORING } from "../../../../constant/idConstant";
import { pageActions } from "../../../../reducers/pageReducer";
const ListConnection = () => {
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state.storeSupplierConnection.list_connection_store
  );
  const { status: statusCustomer } = useSelector((state) => state.customer);
  const { status: statusDistributor } = useSelector(
    (state) => state.distributor
  );
  const { filters: objectFilter } = useSelector((state) => state.page);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { table_loading } = useSelector(
    (state) => state.storeSupplierConnection
  );
  const [filterDataStore, setFilterDataStore] = useState(null);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [search, setSearch] = useState(false);
  const [reset, setReset] = useState(true);
  const [order, setOrder] = useState("");
  const [form, setForm] = useState("");
  const [countSearch, setcountSearch] = useState(objectFilter?.countSearch);
  const [popOverOpen, setPopOverOpen] = useState(false);

  //   distributor parameter
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");
  const [idDistributor, setidDistributor] = useState(
    objectFilter?.idDistributor
  );

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(100);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(objectFilter?.idCustomer);

  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    } else setForm("");
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };
  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    // cek filternya dipilih apa engga
    if (selectDropDownFilter != null) {
      setFilterDataStore(form);
    }
    setSearch(true);
    setPage(1);
    dispatch(
      pageActions.setFilters({
        idCustomer: idCustomer,
        idDistributor: idDistributor,
        selectDropDownFilter: selectDropDownFilter,
        countSearch: countSearch,
      })
    );
    setIsSubmit(!isSubmit);
  };
  const resetFilter = () => {
    setFilterDataStore(null);
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setidDistributor(null);
    setFilterDistributor("");
    setIdCustomer(null);
    setfilterCustomerName("");
    setSelectDropDownFilter(null);
    setSearch(false);
    setReset(!reset);
    setForm("");
    setPage(1);
    setLimit(10);
    setcountSearch("");
    setPopOverOpen(false);
    setIdCustomer(null);
    setfilterCustomerName("");
    dispatch(pageActions.resetFilters());
    setPageDistributor(1);
    setPageCustomer(1);
    setIsSubmit(!isSubmit);
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeSelect = (name, value, option) => {
    if (name === "customer_id") {
      setIdCustomer(value);
      setIsDisabled(false);
    } else setSelectDropDownFilter(option);
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const filter = StringUtil.formatFilter({
    customer_id: `eq ${objectFilter?.idCustomer}`,
    distributor_id: `eq ${objectFilter?.idDistributor}`,
    [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
  });
  const params = { page, limit, filter, order };

  const fetchData = () => {
    dispatch(getListStoreSupplier(params)).then((response) => {
      if (response.payload.ok === false) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListStoreSupplier(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    setSearch(false);
  }, [page, limit, token, isSubmit, search, order, dispatch, reset]);

  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    if (statusDistributor === "success") {
      setPageDistributor(pageDistributor + 1);
    }
  };

  const handleSelectDistributor = (name, value, option) => {
    setidDistributor(value);
  };
  //   end parameter distributor

  // fungsi customer
  const handleScrollCustomer = (event) => {
    if (statusCustomer === "success") {
      setPageCustomer(pageCustomer + 1);
    }
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    // const params = { page, limit };
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_code: item.distributor_code,
      distributor_name: item.distributor_name,
      customer_name: item.customer_name,
      store_code: item.store_code,
      store_name: item.store_name,
      alias: item.principal_connection_store_id,
      supplier_code: item.supplier_code,
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            id={ID_MONITORING.LIST.BTN_VIEW + "_" + item.id}
            link={{
              pathname: ROUTES.CONNECTION.DETAIL,
              state: item.id,
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_CODE")}
        </Box>
      ),
      dataIndex: "distributor_code",
      key: "distributor_code",
      sorter: (a, b) => a.distributor_code - b.distributor_code,
      sortDirections: ["descend", "ascend"],
      width: 140,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name - b.distributor_name,
      sortDirections: ["descend", "ascend"],
      width: 140,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
      width: 140,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "store_code",
      key: "store_code",
      sorter: (a, b) => a.store_code - b.store_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_ALIAS")}
        </Box>
      ),
      dataIndex: "alias",
      key: "alias",
      sorter: (a, b) => a.alias - b.alias,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_SUPPLIER_CODE")}
        </Box>
      ),
      dataIndex: "supplier_code",
      key: "supplier_code",
      sorter: (a, b) => a.supplier_code - b.supplier_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("STORE_SUPPLIER_CONNECTION:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === "A" ? <StatusActive /> : <StatusNonActive />}
              </Box>
              <Box color={status === "A" ? GREEN_COLOR : RED_COLOR}>
                {status === "A" ? "Active" : "Inactive"}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("CONNECTION:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("COMMON:FIELD_HOME")}</span> -{" "}
            <span style={{ color: "#003C7C" }}>{t("CONNECTION:SUBTITLE")}</span>{" "}
            -{" "}
            <span style={{ color: "#003C7C" }}>{t("CONNECTION:SUBTITLE")}</span>{" "}
            - {t("CONNECTION:TITLE_LIST")}
          </>
        }
      />
      <Box marginTop={4} minHeight="96px">
        <Box marginLeft={"41.4rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            id={ID_MONITORING.LIST.DISTRIBUTOR}
            style={selectOptFilterDistributorTheme}
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleSelectDistributor("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By Customer */}
          <Select
            id={ID_MONITORING.LIST.CUSTOMER}
            value={idCustomer}
            style={{ selectFilterProcessTheme, width: 169 }}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("COMMON:SELECT_CUSTOMER")}
            size="large"
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            // allowClear="true"
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            id={ID_MONITORING.LIST.DDL_PILIHAN}
            // value={selectDropDownFilter}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            allowClear={true}
            onClear={handleClear}
            value={selectDropDownFilter}
            size="large"
            placeholder={t("COMMON:SELECT_FILTER_BY")}
          >
            {selectDropDownFilterStore?.map((value, index) => {
              return (
                <>
                  <Select.Option key={index} value={value.value}>
                    {t(value.label)}
                  </Select.Option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              id={ID_MONITORING.LIST.SEARCH}
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              id={ID_MONITORING.LIST.BTN_SUBMIT}
            />
          </Box>
          <Box>
            <Text
              id={ID_MONITORING.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Box marginTop={4}>
          <Table
            dataSource={dataSource}
            columns={columns}
            marginTop={2}
            pagination={false}
            onChange={handleTableChange}
            size="small"
            loading={table_loading}
            scroll={{ y: 400 }}
            footer={() => (
              <Box
                backgroundColor="#E5EBF1"
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <Box display="flex" alignItems="center" marginX={2}>
                  <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                  <Select
                    id={ID_MONITORING.LIST.ROW_PER_PAGE}
                    border="1px solid"
                    size="sm"
                    width={20}
                    _focus={{ outline: "none" }}
                    value={limit}
                    onChange={(value, opt) =>
                      handleSelectPagination(value, opt)
                    }
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Select>
                </Box>
                <Box>
                  <Pagination
                    id={ID_MONITORING.LIST.NEXT_PAGE}
                    size="small"
                    current={page}
                    total={total}
                    onChange={(value) => setPage(value)}
                    showSizeChanger={false}
                    pageSize={limit}
                  />
                </Box>
              </Box>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListConnection;
