import {
  Box, Stack, Text
} from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import {
  StatusActive,
  StatusNonActive
} from "../../../../../components/icons";
import { GREEN_COLOR, RED_COLOR } from "../../../../../constant/propertiesConstant";
const BodyDataGroup = () => {
  const getDetailDataGroup = useSelector((state) => state.dataGroup.detail_data_group);
  let status = getDetailDataGroup.status === "A" ? "Active" : "Inactive"
  let color = status === "Active" ? GREEN_COLOR : RED_COLOR;
  let icon =
    status === "Active" ? (
      <StatusActive />
    ) : (
      <StatusNonActive />
    );
  return (
    <Box padding={1}>
      {/* Data Group Id */}
      <Box padding={1} marginBottom={2}>
        <Box>Data Group Id</Box>
        <Box>
          <Text>
            <b>{getDetailDataGroup.group_id ? getDetailDataGroup.group_id : "Group Id" }</b>
          </Text>
        </Box>
      </Box>
      {/* Description */}
      <Box padding={1} marginBottom={2}>
        <Box>Description</Box>
        <Box>
          <Text>
            <b>{getDetailDataGroup.description ? getDetailDataGroup.description : "Desc" }</b>
          </Text>
        </Box>
      </Box>
      {/* Status */}
      <Box padding={1} marginBottom={2}>
        <Box>Status</Box>
        <Box>
          <Text color={color}>
            <Stack direction="row" spacing="4rem">
              <Box display="flex" alignItems="center">
                <Box marginRight={2}>{icon}</Box>
                <Box>{status}</Box>
              </Box>
            </Stack>
          </Text>
        </Box>
      </Box>
      {/* Level */}
      <Box padding={1} marginBottom={2}>
        <Box>Level</Box>
        <Box>
          <Text>
            <b>{getDetailDataGroup.level > 0 ? getDetailDataGroup.level : "Level" }</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BodyDataGroup;
