import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "./styles/InputDateRangePicker.css";
import "./styles/InputDatePicker.css";
import DateUtil from "../../utils/dateUtil";
import { useCallback } from "react";
import useStateHistory from "../../utils/useStateHistory";
import { useEffect } from "react";
import { Calendar } from "../icons";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonCustom from "../button/ButtonCustom";
import { GRAY_OPTION, WHITE_COLOR, GRAY_MEDIUM } from "../../constant/propertiesConstant";
import { useTranslation } from "react-i18next";
import { endOfDay } from 'date-fns';
import ClearIconButton from "../button/ClearIconButton";

const InputDateRangePicker = ({ values = [null, null], defaultValue =[],onChange = (([startDate, endDate]) => { }), placeHolder = "Select Date", elementId= "datePicker" } = {}) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [stateHistory, setStateHistory, { history, setHistory, index, setIndex, undo, redo, reset }] = useStateHistory([], 2);

  const initialFocusRef = useRef();
  const isDoneRef = useRef(false);

  const onChangeDate = useCallback((dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      setHistory((history) => [history[0], [start, end]]);
      setIndex(1);
    }

  }, [setHistory, setIndex]);

  const handleOnClose = useCallback(() => {
    if (isDoneRef.current) {
      isDoneRef.current = false;
      if (onChange && typeof onChange === 'function') {
        const actualStartDate = startDate;
        const actualEndDate = endOfDay(endDate);
        onChange([actualStartDate, actualEndDate]);
      };
      return (() => { setHistory([[startDate, endDate]]); setIndex(0) })();
    }

    return undo();
  }, [undo, startDate, endDate, setHistory, setIndex, onChange]);

  const handleButtonToClose = useCallback((isDone = false, onClose) => {
    return () => {

      isDoneRef.current = isDone;
      if (onClose && typeof onClose === 'function') onClose();
    }
  }, []);

  const handleOnClear = () => {
    isDoneRef.current = true;
    onChange([null, null]);
    setStateHistory(defaultValue);
  }

  useEffect(() => {
    if (Array.isArray(stateHistory)) {
      const [start, end] = stateHistory;
      setStartDate(start);
      setEndDate(end);
    }
  }, [stateHistory, onChangeDate]);

  useEffect(() => {
    setHistory([values]);
  }, [values]);
  const inputValue = useMemo(() => {
    if (!startDate || !endDate) return ""
    return `${DateUtil.formatDate(
      startDate
    )} - ${DateUtil.formatDate(endDate)}`
  }, [startDate, endDate])
  return (
    <>
      <Popover
        initialFocusRef={initialFocusRef}
        placement='bottom-start'
        onClose={handleOnClose}
        id={elementId}
      >
        {({ isOpen, onClose }) => (
          <>
            {startDate ? (
              <InputGroup>
                <PopoverTrigger>
                  <Input
                    placeholder={placeHolder}
                    value={inputValue}
                    backgroundColor={WHITE_COLOR}
                    _placeholder={{
                      color: GRAY_MEDIUM
                    }}
                    borderColor={GRAY_OPTION}
                    readOnly
                    cursor='pointer'
                  />
                </PopoverTrigger>
                <InputRightElement children={<ClearIconButton onClick={handleOnClear} />} zIndex='0' />
              </InputGroup>
            ) : (
              <PopoverTrigger>
                <InputGroup>
                  <Input
                    placeholder={placeHolder}
                    _placeholder={{
                      color: GRAY_MEDIUM
                    }}
                    borderColor={GRAY_OPTION}
                    value={inputValue}
                    backgroundColor={WHITE_COLOR}
                    readOnly
                    cursor='pointer'
                  />
                  <InputRightElement children={<Calendar />} zIndex='0' />
                </InputGroup>
              </PopoverTrigger>
            )}


            <PopoverContent color={WHITE_COLOR}>
              <PopoverBody padding='0'>
                <ReactDatePicker
                  selected={startDate}
                  onChange={onChangeDate}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  shouldCloseOnSelect={false}
                  disabledKeyboardNavigation={true}
                  showMonthDropdown
                  showYearDropdown
                  inline
                />
              </PopoverBody>
              <PopoverFooter
                border='0'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                pb={4}
              >
                <Flex
                  width='full'
                  justifyContent='space-between'
                >
                  <ButtonCustom
                    colorScheme="blue"
                    variant="outline"
                    minWidth='100px'
                    maxWidth='100px'
                    marginLeft='2'
                    text={t('COMMON:BUTTON_CANCEL')}
                    onClick={handleButtonToClose(false, onClose)}
                  >

                  </ButtonCustom>
                  <ButtonPrimary
                    minWidth='100px'
                    maxWidth='100px'
                    marginRight='2'
                    text={t('COMMON:BUTTON_SAVE')}
                    onClick={handleButtonToClose(true, onClose)}
                  >

                  </ButtonPrimary>
                </Flex>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </>
  );
};

export default InputDateRangePicker;