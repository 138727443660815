import { Button, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ButtonCustom = (props) => {
  return props.link ? (
    <Link to={props.link}>
      <Button
        leftIcon={props.icon}
        background={props.backgroundcolor}
        rightIcon={props.rightIcon}
        _focus={{
          ringColor: "hwb(211deg 0% 51%)",
        }}
        colorScheme={props?.colorScheme}
        isLoading={props.loading}
        {...props}
      >
        <Text fontSize={props.fontsize}>{props.text}</Text>
      </Button>
    </Link>
  ) : (
    <Button
      leftIcon={props.icon}
      background={props.backgroundcolor}
      rightIcon={props.rightIcon}
      colorScheme={props.colorScheme}
      isLoading={props.loading}
      _focus={{
        ringColor: "hwb(211deg 0% 51%)",
      }}
      {...props}
    >
      <Text fontSize={props.fontsize}>{props.text}</Text>
    </Button>
  );
};

export default ButtonCustom;
