import { Input } from "antd";
const DropDownCustom = ( {menu, search, value }) => {
  return (
    <>
      <div style={{ padding: "2px 8px" }}>
        <Input
          placeholder="Search"
          size="middle"
          onChange={search}
          allowClear
          value={value}
          // onKeyDown={(e) => {
          //   _ignoreBackspace(e);
          // }}
        />
      </div>
      <div style={{ padding: 2 }}>{menu}</div>
    </>
  );
};
export default DropDownCustom;
