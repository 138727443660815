import {
  Box, Text
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  BlueEditIcon, DeleteSuccess, RedDeleteIcon, UnSuccess
} from "../../../../../components/icons";
import ModalConfirm from "../../../../../components/ModalConfirm";
import ModalCustom from "../../../../../components/ModalCustom";
import { ID_PRODUCT_CATEGORY_MAPPING } from "../../../../../constant/idConstant";
import {
  PRIMARY_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
import { RenderStatus } from "../../../../../function/RenderStatus";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import {
  deleteProductCategoryMapping,
  detailProductCategoryMapping
} from "../../../../../services/main/productCategoryMappingReducer";
const DetailProductCategoryMapping = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState("");
  const [detailProduct, setDetailProduct] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const payload = { updated_at: updatedAt };

  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmOpen(false);
  };

  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      productId: Number(location.state.id),
    };
    dispatch(deleteProductCategoryMapping(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };

  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailProductCategoryMapping(location.state.id)).then(
      (response) => {
        if (response.payload.ok) {
          setUpdatedAt(response.payload.response.updated_at);
          setDetailProduct(response.payload.response);
        }
      }
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    fetchData();
  });

  return isDeleted ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY_MAPPING:DETAIL_PRODUCT_CATEGORY_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_CATEGORY_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              id={ID_PRODUCT_CATEGORY_MAPPING.DETAIL.BTN_EDIT}
              link={{
                pathname: ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              id={ID_PRODUCT_CATEGORY_MAPPING.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.LIST}
        name={detailProduct?.created_name}
        date={moment(detailProduct?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(detailProduct?.at).format("DD/MM/YYYY")}
        modifiedBy={detailProduct?.updated_name}
        id={ID_PRODUCT_CATEGORY_MAPPING.DETAIL.BTN_BACK}
      />
      {/* white box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        justifyContent="space-between"
        display="flex"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box padding={1} width="404px">
          {/* Product Category */}
          <Box padding={1}>
            <Box>{t("PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY")}</Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_category_code
                    ? detailProduct?.product_category_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Principal Code */}
          <Box padding={1}>
            <Box>{t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.principal_code
                    ? detailProduct?.principal_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Distributor Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_CODE")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.distributor_code
                    ? detailProduct?.distributor_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Customer Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.customer_code
                    ? detailProduct?.customer_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Store Code */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.store_code ? detailProduct?.store_code : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Principal Product Code */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_CODE")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_principal_code
                    ? detailProduct?.product_principal_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Customer Product Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_customer_code
                    ? detailProduct?.product_customer_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* status */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_STATUS")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{RenderStatus(detailProduct?.status)}</b>
              </Text>
            </Box>
          </Box>
        </Box>
        {/* end of left side  */}

        {/* right side */}
        <Box padding={1} marginRight="7.5rem" width="404px">
          {/* product category name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY_NAME")}
              </Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {detailProduct?.product_category_name
                    ? detailProduct?.product_category_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* principal name*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {detailProduct?.principal_name
                    ? detailProduct?.principal_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Customer  name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.customer_name
                    ? detailProduct?.customer_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* store name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.store_name ? detailProduct?.store_name : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* principal product name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_principal_name
                    ? detailProduct?.product_principal_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* customer product name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_customer_name
                    ? detailProduct?.product_customer_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* supplier code */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_SUPPLIER_CODE")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.supplier_code
                    ? detailProduct?.supplier_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
            {/* distributor name */}
            <Box padding={1}>
            <Box width="10rem">
              <Text>
                {t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_NAME")}
              </Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.distributor_name
                    ? detailProduct?.distributor_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
        idYes={ID_PRODUCT_CATEGORY_MAPPING.DETAIL.BTN_YES}
        idNo={ID_PRODUCT_CATEGORY_MAPPING.DETAIL.BTN_NO}
      />
    </Box>
  );
};

export default DetailProductCategoryMapping;
