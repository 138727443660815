// global
export const ID_PRODUCT_PRINCIPAL = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    PRODUCT_DESCRIPTION: "product_description",
    BARCODE: "barcode",
    UOM1: "uom1",
    UOM2: "uom2",
    UOM3: "uom3",
    UOM4: "uom4",
    PRICE_UOM_1: "price_uom_1",
    CONVERSTION_TO_SMALLEST_1: "conv_smallest1",
    CONVERSTION_TO_SMALLEST_2: "conv_smallest2",
    CONVERSTION_TO_SMALLEST_3: "conv_smallest3",
    ORDER_UNIT: "order_unit",
    PRODUCT_PACKAGING: "product_packaging",
    PRODUCT_STATUS: "product_status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    PRODUCT_DESCRIPTION: "product_description",
    BARCODE: "barcode",
    UOM1: "uom1",
    UOM2: "uom2",
    UOM3: "uom3",
    UOM4: "uom4",
    PRICE_UOM_1: "price_uom_1",
    CONVERSTION_TO_SMALLEST_1: "conv_smallest1",
    CONVERSTION_TO_SMALLEST_2: "conv_smallest2",
    CONVERSTION_TO_SMALLEST_3: "conv_smallest3",
    ORDER_UNIT: "order_unit",
    PRODUCT_PACKAGING: "product_packaging",
    PRODUCT_STATUS: "product_status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_PRODUCT_CUSTOMER = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    PRODUCT_DESCRIPTION: "product_description",
    BARCODE: "barcode",
    UOM1: "uom1",
    UOM2: "uom2",
    UOM3: "uom3",
    UOM4: "uom4",
    PRICE_UOM_1: "price_uom_1",
    CONVERSTION_TO_SMALLEST_1: "conv_smallest1",
    CONVERSTION_TO_SMALLEST_2: "conv_smallest2",
    CONVERSTION_TO_SMALLEST_3: "conv_smallest3",
    ORDER_UNIT: "order_unit",
    PRODUCT_PACKAGING: "product_packaging",
    PRODUCT_STATUS: "product_status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    PRODUCT_DESCRIPTION: "product_description",
    BARCODE: "barcode",
    UOM1: "uom1",
    UOM2: "uom2",
    UOM3: "uom3",
    UOM4: "uom4",
    PRICE_UOM_1: "price_uom_1",
    CONVERSTION_TO_SMALLEST_1: "conv_smallest1",
    CONVERSTION_TO_SMALLEST_2: "conv_smallest2",
    CONVERSTION_TO_SMALLEST_3: "conv_smallest3",
    ORDER_UNIT: "order_unit",
    PRODUCT_PACKAGING: "product_packaging",
    PRODUCT_STATUS: "product_status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};
// sysuser
export const ID_DATA_GROUP = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_X: "btnX",
    PAGINATION: "btnPage",
  },
  ADD: {
    // data group
    DATA_GROUP_ID: "data_group_id",
    DESCRIPTION: "description",
    LEVEL: "level",
    STATUS: "status",
    // data scope
    PRINCIPAL: "principal",
    PRODUCT_STATUS: "po_status",
    PRINCIPAL_BRANCH: "principal_branch",
    CUSTOMER: "customer",
    STORE: "store",
    WAREHOUSE: "warehouse",
    GEO_TREE: "geo_tree",
    //button
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    BTN_NEXT: "btnNext",
    BTN_PREVIOUS: "btnPrevious",
  },
  EDIT: {
    // data group
    DATA_GROUP_ID: "data_group_id",
    DESCRIPTION: "description",
    LEVEL: "level",
    STATUS: "status",
    // data scope
    PRINCIPAL: "principal",
    PRODUCT_STATUS: "po_status",
    PRINCIPAL_BRANCH: "principal_branch",
    CUSTOMER: "customer",
    STORE: "store",
    WAREHOUSE: "warehouse",
    GEO_TREE: "geo_tree",
    //button
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    BTN_NEXT: "btnNext",
    BTN_PREVIOUS: "btnPrevious",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_ROLE = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_X: "btnX",
    PAGINATION: "btnPage",
  },
  ADD: {
    ROLE: "role",
    DESCRIPTION: "description",
    LEVEL: "level",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    ROLE: "role",
    DESCRIPTION: "description",
    LEVEL: "level",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_USER = {
  CHECK: {
    NIK_CHECK: "nik",
    EMAIL_CHECK: "email",
    BTN_CHECK: "btnCheck",
    BTN_NEXT: "btnNext"
  },
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_X: "btnX",
    PAGINATION: "btnPage",
  },
  ADD: {
    // user profile
    PHOTO: "photo",
    NIK: "nik",
    PERSON_TITLE: "person_title",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    EMAIL: "email",
    COUNTRY_CODE: "country_code",
    LOCALE: "locale",
    PHONE: "phone",
    ADDRESS_1: "address1",
    ADDRESS_2: "address2",
    ADDRESS_3: "address3",
    COUNTRY: "country",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    ISLAND: "island",
    HAMLET: "hamlet",
    NEIGHBOURHOOD: "neighbourd",
    // login account
    USERNAME: "username",
    PASSWORD: "password",
    // access setting
    USER_CATEGORY: "user_category",
    COMPANY_PROFILE: "company_profile",
    ROLE: "role",
    DATA_GROUP: "data_group",
    // activation
    STATUS: "status",
    ACTIVE_PERIOD: "active_period",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    BTN_PREVIOUS: "btnPrevious",
    BTN_NEXT: "btnNext"
  },
  EDIT: {
    // user profile
    PHOTO: "photo",
    NIK: "nik",
    PERSON_TITLE: "person_title",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    EMAIL: "email",
    COUNTRY_CODE: "country_code",
    LOCALE: "locale",
    PHONE: "phone",
    ADDRESS_1: "address1",
    ADDRESS_2: "address2",
    ADDRESS_3: "address3",
    COUNTRY: "country",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    ISLAND: "island",
    HAMLET: "hamlet",
    NEIGHBOURHOOD: "neighbourd",
    // login account
    USERNAME: "username",
    PASSWORD: "password",
    // access setting
    USER_CATEGORY: "user_category",
    COMPANY_PROFILE: "company_profile",
    ROLE: "role",
    DATA_GROUP: "data_group",
    // activation
    STATUS: "status",
    ACTIVE_PERIOD: "active_period",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    BTN_PREVIOUS: "btnPrevious",
    BTN_NEXT: "btnNext"
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};
//user seller
export const ID_PRODUCT_CATEGORY = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRODUCT_CATEGORY_CODE: "product_category_code",
    PRODUCT_CATEGORY_NAME: "product_category_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRODUCT_CATEGORY_CODE: "product_category_code",
    PRODUCT_CATEGORY_NAME: "product_category_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};
export const ID_PRODUCT_MAPPING_HO = {
  LIST: {
    SEARCH: "search",
    SELECT_PRINCIPAL: "select_principal",
    SELECT_CUSTOMER: "select_customer",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    BARCODE: "barcode",
    BARCODE_CASE: "barcode_case",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    BARCODE: "barcode",
    BARCODE_CASE: "barcode_case",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_PRODUCT_MAPPING = {
  LIST: {
    SEARCH: "search",
    SELECT_DISTRIBUTOR: "select_distributor",
    SELECT_STORE: "select_store",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    STORE_ID: "store_id",
    STORE_NAME: "store_name",
    WAREHOUSE_CODE: "warehouse_code",
    WAREHOUSE_NAME: "warehouse_name",
    QTY_DPD: "qty_dpd",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    STORE_ID: "store_id",
    STORE_NAME: "store_name",
    WAREHOUSE_CODE: "warehouse_code",
    WAREHOUSE_NAME: "warehouse_name",
    QTY_DPD: "qty_dpd",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};
export const ID_BKL_MAPPING = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    DISTRIBUTION_CENTRE: "distribution_centre",
    AREA_CODE: "area_code",
    STORE_SAP_ID: "store_sap_id",
    STORE_SAP_NAME: "store_sap_name",
    FREQUENCY: "frequency",
    OPEN_PO: "open_po",
    DELIVERY: "delivery",
    DEPO_ID: "depo_id",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    DISTRIBUTION_CENTRE: "distribution_centre",
    AREA_CODE: "area_code",
    STORE_SAP_ID: "store_sap_id",
    STORE_SAP_NAME: "store_sap_name",
    FREQUENCY: "frequency",
    OPEN_PO: "open_po",
    DELIVERY: "delivery",
    DEPO_ID: "depo_id",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};
export const ID_PRODUCT_BKL_MAPPING = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "row_page",
    DISTRIBUTOR: "distributor",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    BKL_TYPE: "bkl_type",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    BKL_TYPE: "bkl_type",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_PRODUCT_CATEGORY_MAPPING = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "row_page",
    DISTRIBUTOR: "distributor",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRODUCT_CATEGORY: "product_category",
    PRODUCT_CATEGORY_NAME: "product_category_name",
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRODUCT_CATEGORY: "product_category",
    PRODUCT_CATEGORY_NAME: "product_category_name",
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    PRINCIPAL_PRODUCT_CODE: "principal_product_code",
    PRINCIPAL_PRODUCT_NAME: "principal_product_name",
    CUSTOMER_PRODUCT_CODE: "customer_product_code",
    CUSTOMER_PRODUCT_NAME: "customer_product_name",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_PRODUCT_SUBSTITUTE = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "row_page",
    ROW_PER_PAGE: "row_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRODUCT_CATEGORY: "product_category",
    PRODUCT_CATEGORY_NAME: "product_category_name",
    PRINCIPAL_PRODUCT_CODE_FROM: "principal_product_From",
    PRINCIPAL_PRODUCT_NAME_FROM: "principal_product_name_From",
    PRINCIPAL_PRODUCT_CODE_TO: "principal_product_To",
    PRINCIPAL_PRODUCT_NAME_TO: "principal_product_name_To",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    PRODUCT_CATEGORY: "product_category",
    PRODUCT_CATEGORY_NAME: "product_category_name",
    PRINCIPAL_PRODUCT_CODE_FROM: "principal_product_From",
    PRINCIPAL_PRODUCT_NAME_FROM: "principal_product_name_From",
    PRINCIPAL_PRODUCT_CODE_TO: "principal_product_To",
    PRINCIPAL_PRODUCT_NAME_TO: "principal_product_name_To",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_WAREHOUSE = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "view_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    WAREHOUSE_CODE: "warehouse_code",
    WAREHOUSE_NAME: "warehouse_name",
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    IS_DPD_RULES: "is_dpd",
    GROUP: "group",
    DEPO_NAME: "depo_name",
    ADDRESS: "address",
    COUNTRY: "country",
    ISLAND: "island",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  EDIT: {
    WAREHOUSE_CODE: "warehouse_code",
    WAREHOUSE_NAME: "warehouse_name",
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    IS_DPD_RULES: "is_dpd",
    GROUP: "group",
    DEPO_NAME: "depo_name",
    ADDRESS: "address",
    COUNTRY: "country",
    ISLAND: "island",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    STATUS: "status",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_DISTRIBUTOR = {
  LIST: {
    SEARCH: "search",
    ROW_PER_PAGE: "view_page",
    RESET_FILTER: "btnReset",
    BTN_ADD: "btnAdd",
    BTN_UPLOAD: "btnUpload",
    BTN_APPLY: "btnApply",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    PAGINATION: "btnPage",
  },
  ADD: {
    // company profile
    CODE: "code",
    DISTRIBUTOR_TITLE: "distributor_title",
    DISTRIBUTOR_NAME: "distributor_name",
    NPWP: "npwp",
    PHONE_CODE: "phone_code",
    PHONE: "phone",
    FAX_CODE: "fax_code",
    FAX: "fax",
    STATUS: "status",
    ADDRESS: "address",
    COUNTRY: "country",
    ISLAND: "island",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    HAMLET: "hamlet",
    NEIGHBOURHOOD: "neighbourd",
    LONGITUDE: "longitude",
    LATITUDE: "latitude",

    // preferences
    ACTIVE_ALERT: "activate_alert",
    ACTIVATE_NOTIFICATION: "activate_notif",

    // contactperson
    PERSON_TITLE: "person_titlte",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    NIK: "nik",
    PHONE_CODE_CP: "phone_code",
    PHONE_CP: "phone",
    EMAIL: "email",
    JOIN_DATE: "join_date",
    RESIGN_DATE: "resign_date",

    // Button
    BTN_NEXT: "btnNext",
    BTN_PREVIOUS: "btnPrevious",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    BTN_ADD_CONTACT: "btnAddContact",
    BTN_DELETE: "btnDelete",
  },
  EDIT: {
    // company profile
    CODE: "code",
    DISTRIBUTOR_TITLE: "distributor_title",
    DISTRIBUTOR_NAME: "distributor_name",
    NPWP: "npwp",
    PHONE_CODE: "phone_code",
    PHONE: "phone",
    FAX_CODE: "fax_code",
    FAX: "fax",
    STATUS: "status",
    ADDRESS: "address",
    COUNTRY: "country",
    ISLAND: "island",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    HAMLET: "hamlet",
    NEIGHBOURHOOD: "neighbourd",
    LONGITUDE: "longitude",
    LATITUDE: "latitude",

    // preferences
    ACTIVE_ALERT: "activate_alert",
    ACTIVATE_NOTIFICATION: "activate_notif",

    // contactperson
    PERSON_TITLE: "person_titlte",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    NIK: "nik",
    PHONE_CODE_CP: "phone_code",
    PHONE_CP: "phone",
    EMAIL: "email",
    JOIN_DATE: "join_date",
    RESIGN_DATE: "resign_date",

    // Button
    BTN_NEXT: "btnNext",
    BTN_PREVIOUS: "btnPrevious",
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    BTN_ADD_CONTACT: "btnAddContact",
    BTN_DELETE: "btnDelete",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

// buyer - cs
export const ID_CSM_MASTER = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "row_page",
    DISTRIBUTOR: "distributor",
    CUSTOMER: "customer",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_CONNECTION: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PAGINATION: "btnPage",
    BTN_UPLOAD: "btnUpload",
  },
  ADD: {
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    SUPPLIER_CODE: "supplier_code",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    STORE_ID: "store_id",
    STORE_NAME: "store_name",
    ALIAS: "alias",
    PM_HANDLING: "pm_handling",
    NOTIFICATION_NAME: "notification_name",
    ALERT: "alert",
    PRODUCT_MAPPING: "product_mapping",
    BKL: "is_bkl",
    SALESMAN_CODE: "salesman_code",
    SALESMAN_NAME: "salesman_name",
    WAREHOUSE_CODE: "warehouse_code",
    WAREHOUSE_NAME: "warehouse_name",
    PRINCIPAL_CODE: "prIncipal_code",
    PRINCIPAL_NAME: "prIncipal_name",
    STATUS: "status",
    ADDITIONAL_SALESMAN_CODE:"additional_salesman_code",
    ADDITIONAL_SALESMAN_NAME:"additional_salesman_name"
  },
  EDIT: {
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    SUPPLIER_CODE: "supplier_code",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    CUSTOMER_CODE: "customer_code",
    CUSTOMER_NAME: "customer_name",
    STORE_ID: "store_id",
    STORE_NAME: "store_name",
    STORE_CODE: "store_code",
    ALIAS: "alias",
    PM_HANDLING: "pm_handling",
    NOTIFICATION_NAME: "notification_name",
    ALERT: "alert",
    PRODUCT_MAPPING: "product_mapping",
    BKL: "is_bkl",
    SALESMAN_CODE: "salesman_code",
    SALESMAN_NAME: "salesman_name",
    WAREHOUSE_CODE: "warehouse_code",
    WAREHOUSE_NAME: "warehouse_name",
    STATUS: "status",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  },
};

export const ID_NOTIFICATION_MASTER = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "data_page",
    DISTRIBUTOR: "distributor",
    CUSTOMER: "customer",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_NOTIFICATION: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PAGINATION: "btnPage",
    BTN_UPLOAD: "btnUpload",
    SELECT_PRINCIPAL: "select_principal",
    SELECT_DISTRIBUTOR: "select_distributor",
  },
  ADD: {
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    NOTIFICATION_CATGEORY: "notification_category",
    NOTIFICATION_SUB_CATEGORY: "notification_subCategory",
    NOTIFICATION_CODE: "notification_code",
    NOTIFICATION_NAME: "notification_name",
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    EMAIL_PO_TO: "email_notification_to",
    EMAIL_PO_CC: "email_notification_cc",
    EMAIL_ALERT_TO: "email_alert_to",
    EMAIL_ALERT_CC: "email_alert_cc",
    STATUS: "status",
  },
  EDIT: {
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    NOTIFICATION_CATGEORY: "notification_category",
    NOTIFICATION_SUB_CATEGORY: "notification_subCategory",
    NOTIFICATION_CODE: "notification_code",
    NOTIFICATION_NAME: "notification_name",
    PRINCIPAL_CODE: "principal_code",
    PRINCIPAL_NAME: "principal_name",
    DISTRIBUTOR_CODE: "distributor_code",
    DISTRIBUTOR_NAME: "distributor_name",
    EMAIL_PO_TO: "email_notification_to",
    EMAIL_PO_CC: "email_notification_cc",
    EMAIL_ALERT_TO: "email_alert_to",
    EMAIL_ALERT_CC: "email_alert_cc",
    STATUS: "status",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  }
}

export const ID_STORE_MASTER = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "data_page",
    DISTRIBUTOR: "distributor",
    CUSTOMER: "customer",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_X: "btnX",
    BTN_PAGINATION: "btnPage",
    BTN_UPLOAD: "btnUpload",
    SELECT_CUSTOMER: "select_principal",
    SELECT_DISTRIBUTOR: "select_distributor"
  },
  ADD: {
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    STORE_ID: "store_id",
    CUSTOMER_NAME: "customer_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    COUNTRY_CODE: "country_code",
    PHONE: "phone",
    ADDRESS: "address",
    COUNTRY: "country",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    ISLAND: "island",
    STATUS: "status",
  },
  EDIT: {
    BTN_SAVE: "btnSave",
    BTN_CANCEL: "btnCancel",
    STORE_ID: "store_id",
    CUSTOMER_NAME: "customer_name",
    STORE_CODE: "store_code",
    STORE_NAME: "store_name",
    COUNTRY_CODE: "country_code",
    PHONE: "phone",
    ADDRESS: "address",
    COUNTRY: "country",
    PROVINCE: "province",
    DISTRICT: "district",
    SUB_DISTRICT: "sub_district",
    URBAN_VILLAGE: "urban_village",
    POSTAL_CODE: "postal_code",
    ISLAND: "island",
    STATUS: "status",
  },
  DETAIL: {
    BTN_EDIT: "btnEdit",
    BTN_BACK: "btnBack",
    BTN_DELETE: "btnDelete",
    BTN_YES: "btnYes",
    BTN_NO: "btnNo",
  }
}

export const ID_REPROCESS_PO = {
  LIST: {
    SEARCH: "search",
    ROW_PAGE: "data_page",
    DISTRIBUTOR: "distributor",
    CUSTOMER: "customer",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_X: "btnX",
    BTN_PAGINATION: "btnPage",
    BTN_PROCESS: "btnProcess",
    SELECT_CUSTOMER: "select_principal",
    SELECT_DISTRIBUTOR: "select_distributor",
    PROCESS_DATE: "process_date",
    SELECT_STATUS: "status",

  },
}

export const ID_PROCESS_PO = {
  LIST: {
    ROW_PAGE: "data_page",
    DISTRIBUTOR: "distributor",
    PRINCIPAL: "principal",
    STORE: "store",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PAGINATION: "btnPage",
    BTN_PROCESS: "btnProcess",
    PROCESS_DATE: "process_date",
  },
}

export const ID_REPORT_PO = {
  LIST: {
    ROW_PAGE: "data_page",
    CUSTOMER: "customer",
    PRINCIPAL: "principal",
    STORE: "store",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PRINT: "btnPrint",
    BTN_PAGINATION: "btnPage",
    BTN_PROCESS: "btnProcess",
    PROCESS_DATE: "process_date",
    FORMAT_OPTION: "Format_options",
    COLUMN_SELECTION: "Column_selection",
    REPORT_WIDTH: "report_width",
    GRID_LINE_COLOR: "grid_line_color",
    HEADER_BACKGROUND: "header_background",
    REPORT_ROWS: "feport_rows",
    FONT_SIZE: "font_size",
    FONT_FAMILY: "font_family",
    GROUP_HEADING: "group_heading",
  },
}

export const ID_REPORT_PO_DETAIL = {
  LIST: {
    ROW_PAGE: "data_page",
    CUSTOMER: "customer",
    PRINCIPAL: "principal",
    STORE: "store",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PRINT: "btnPrint",
    BTN_PAGINATION: "btnPage",
    BTN_PROCESS: "btnProcess",
    PROCESS_DATE: "process_date",
    FORMAT_OPTION: "Format_options",
    COLUMN_SELECTION: "Column_selection",
    REPORT_WIDTH: "report_width",
    GRID_LINE_COLOR: "grid_line_color",
    HEADER_BACKGROUND: "header_background",
    REPORT_ROWS: "feport_rows",
    FONT_SIZE: "font_size",
    FONT_FAMILY: "font_family",
    GROUP_HEADING: "group_heading",
  },
}

export const ID_REPORT_LIST_CONNECTION = {
  LIST: {
    ROW_PAGE: "data_page",
    CUSTOMER: "customer",
    DISTRIBUTOR: "distributor",
    PRINCIPAL: "principal",
    STORE: "store",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PRINT: "btnPrint",
    BTN_PAGINATION: "btnPage",
    BTN_PROCESS: "btnProcess",
    PROCESS_DATE: "process_date",
    FORMAT_OPTION: "Format_options",
    COLUMN_SELECTION: "Column_selection",
    REPORT_WIDTH: "report_width",
    GRID_LINE_COLOR: "grid_line_color",
    HEADER_BACKGROUND: "header_background",
    REPORT_ROWS: "feport_rows",
    FONT_SIZE: "font_size",
    FONT_FAMILY: "font_family",
    GROUP_HEADING: "group_heading",
  },
}

export const ID_REPORT_POvsSO = {
  LIST: {
    ROW_PAGE: "data_page",
    CUSTOMER: "customer",
    STORE: "store",
    DDL: "ddl",
    BTN_APPLY: "btnApply",
    BTN_RESET: "btnReset",
    ADD_STORE: "btnAdd",
    BTN_VIEW: "btnView",
    BTN_EDIT: "btnEdit",
    BTN_PRINT: "btnPrint",
    BTN_PAGINATION: "btnPage",
    BTN_PROCESS: "btnProcess",
    PROCESS_DATE: "process_date",
    FORMAT_OPTION: "Format_options",
    COLUMN_SELECTION: "Column_selection",
    REPORT_WIDTH: "report_width",
    GRID_LINE_COLOR: "grid_line_color",
    HEADER_BACKGROUND: "header_background",
    REPORT_ROWS: "feport_rows",
    FONT_SIZE: "font_size",
    FONT_FAMILY: "font_family",
    GROUP_HEADING: "group_heading",
  },
}

export const ID_DASHBOARD = {
  LIST: {
    DDL: "ddl",
    BTN_SUBMIT: "btnSubmit",
    BTN_RESET: "btnReset",
    DATE: "date",
    PRINCIPAL:"principal"
  },
}

export const ID_MONITORING = {
  LIST: {
    DDL: "ddl",
    BTN_SUBMIT: "btnSubmit",
    BTN_PRINT: "btnPrint",
    BTN_RESET: "btnReset",
    BTN_VIEW: "btnView",
    DATE: "date",
    PRINCIPAL:"principal",
    SEARCH: "search",
    ROW_PER_PAGE: "row_page",
    TRX_DATE: "trx_date",
    DELIVERY_DATE: "delivery_date",
    DISTRIBUTOR: "distributor",
    CUSTOMER: "customer",
    WAREHOUSE: "warehouse",
    DOWNLOAD_STATUS: "download_status",
    DDL_PILIHAN: "ddlPilihan",
    DOWNLOAD_PDF: "btnDownloadPDF",
    NEXT_PAGE: "next_page"
  },
}