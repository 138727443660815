import {
  Box,
  Stack,
  Divider,
  Text,
  Badge,
  Center,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { message, Spin } from "antd";
import { useTranslation } from "react-i18next";
import HeadTitle from "../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  BLACK_COLOR,
  badgeColor,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { useDispatch, useSelector } from "react-redux";
import BodyUserProfile from "./body/BodyUserProfile";
import BodyLoginAccount from "./body/BodyLoginAccount";
import BodyAccessSetting from "./body/BodyAccessSetting";
import BodyActivation from "./body/BodyActivation";
import BodyAuthentication from "./body/BodyAuthentication";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage,
} from "../../../../services/main/regionalReducer";
import { useLocation, } from "react-router-dom";
import { detailUsers, userAction } from "../../../../services/main/userReducer";
const EditUser = () => {
  const { t } = useTranslation();
  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  //modal
  const { curr_page } = useSelector((state) => state.user);
  const { index_page } = useSelector((state) => state.user);
  const { detail_user_loading } = useSelector((state) => state.user);
  const [resp, setResp] = useState("");
  const [currentPage, setCurrentPage] = useState(
    t("USER:MULTIPLE_STEP_USER_PROFILE")
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const setCurrPage = () => {
    // current page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_USER_PROFILE")));
    // index page
    dispatch(userAction.setIndexPage(0));
  };
  const fetchData = async () => {
    const params = { page, limit };
    await dispatch(getListCountry(params));
    await dispatch(getListIsland(params));
    await dispatch(getListProvince(params));
    await dispatch(getListDistrict(params));
    await dispatch(getListSubDistrict(params));
    await dispatch(getListUrbanVillage(params));
    await dispatch(getListPostalCode(params));
  };
  const fetchDetail = async () => {
    const res = await dispatch(detailUsers(location.state.id));
    const response = await res;
    if (response.payload.ok) {
      dispatch(
        userAction.setUpdatedAt(
          response.payload.response.nexsoft.payload.data.content.updated_at
        )
      );
      dispatch(
        userAction.setDetailUser(
          response.payload.response.nexsoft.payload.data.content
        )
      );
    } else {
      message.error(resp.payload.response.data.nexsoft.payload.status.message);
    }
  };
  const renderMainBody = () => {
    if (curr_page === t("USER:MULTIPLE_STEP_USER_PROFILE"))
      return <BodyUserProfile />;
    if (curr_page === t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT"))
      return <BodyLoginAccount />;
    if (curr_page === t("USER:MULTIPLE_STEP_ACCESS_SETTING"))
      return <BodyAccessSetting />;
    if (curr_page === t("USER:MULTIPLE_STEP_ACTIVATION"))
      return <BodyActivation />;
    if (curr_page === t("USER:MULTIPLE_STEP_AUTHENTICATION"))
      return <BodyAuthentication />;
  };
  const renderLoading = () => {
    <>
      <Center>
        <Spin />
      </Center>
    </>;
  };
  useEffect(() => {
    dispatch(userAction.resetReduxUser);
    setCurrPage();
    fetchData();
    fetchDetail();
  }, []);

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("USER:EDIT_USER")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("USER:SUBTITLE_1")}</span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>{t("USER:SUBTITLE_2")}</span>{" "}
            - {t("USER:EDIT_USER")}
          </>
        }
      />

      <Box marginTop={6} minHeight="4.375rem" height="85px" marginBottom={3}>
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <Box
            backgroundColor="white"
            width={900}
            height="75px"
            display="flex"
            borderRadius="8px"
            p={1}
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.31)"
          >
            <Stack direction="row" p={4}>
              {/* -------User Profile -------  */}
              <Box display="flex" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  variant="solid"
                  colorScheme={
                    index_page >= 0
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>1</Center>
                  </Text>
                </Badge>
                <Flex justifyContent="center">
                  <Text
                    fontWeight={700}
                    fontSize={16}
                    color={index_page >= 0 ? PRIMARY_COLOR : BLACK_COLOR}
                    marginStart={2}
                  >
                    {t("USER:MULTIPLE_STEP_USER_PROFILE")}
                  </Text>
                </Flex>
              </Box>
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* ------- Login Account -------  */}
              <Box display="flex" width="160px" height="24px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  px="2"
                  variant="solid"
                  colorScheme={
                    index_page >= 1
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>2</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 1 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")}
                </Text>
              </Box>
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* ----- Access Setting ------  */}
              <Box display="flex" width="190px" height="24px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  px="2"
                  variant="solid"
                  colorScheme={
                    index_page >= 2
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>3</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 2 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("USER:MULTIPLE_STEP_ACCESS_SETTING")}
                </Text>
              </Box>
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* ------- Activation -------  */}
              <Box display="flex" width="120px" height="24px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  px="2"
                  variant="solid"
                  colorScheme={
                    index_page >= 3
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>4</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 3 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("USER:MULTIPLE_STEP_ACTIVATION")}
                </Text>
              </Box>
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* ------- Authentication ---------  */}
              <Box display="flex" width="140px" height="24px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  px="2"
                  variant="solid"
                  colorScheme={
                    index_page >= 4
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>5</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 4 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("USER:MULTIPLE_STEP_AUTHENTICATION")}
                </Text>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {/* ------ render main body ------  */}
      {detail_user_loading ? renderLoading() : renderMainBody()}
    </Box>
  );
};

export default EditUser;
