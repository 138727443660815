import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import { subHours } from "date-fns";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  SavedSuccess,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  UnSuccess,
  UploadIcon,
} from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ModalImport from "../../../../../components/ModalImport";
import ModalImportPreview from "../../../../../components/ModalImportPreview";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import { ID_PRODUCT_PRINCIPAL } from "../../../../../constant/idConstant";
import { typeProductPrincipal } from "../../../../../constant/importEndPointConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { selectDropDownFilterProductPrincipal } from "../../../../../constant/selectOptConstant";
import { template_product_principal } from "../../../../../constant/templateDownloadConstant";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import {
  RenderColorStatus,
  RenderIconStatus,
  RenderStatus,
} from "../../../../../function/RenderStatus";
import useCheckUserCategory from "../../../../../hooks/useCheckUserCategory";
import { pageActions } from "../../../../../reducers/pageReducer";
import { getListPrincipal } from "../../../../../services/main/principalReducer";
import {
  getInitListProductPrincipal,
  getListProductPrincipal,
} from "../../../../../services/main/productPrincipalReducer";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../../utils/importFileUtil";
import StringUtil from "../../../../../utils/stringUtil";
import "./ProductPrincipal.css";
const ProductPrincipal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.productPrincipal.list);
  const { token } = useSelector((state) => state.auth);
  const { filters: objectFilter } = useSelector((state) => state.page);
  const table_loading = useSelector(
    (state) => state.productPrincipal.table_loading
  );
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  // get role user
  const { isAccepted } = useCheckUserCategory([userCategoryType.SUPPORT]);
  const userPrincipal = useCheckUserCategory([
    userCategoryType.PRINCIPAL,
  ]).isAccepted;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [principalId, setPrincipalId] = useState(objectFilter?.idPrincipal ?? 1);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [submit, setSubmit] = useState(false);
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal, setLimitPrincipal] = useState(100);
  const [form, setForm] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [order, setOrder] = useState("");
  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [proccessLoading, setProccessLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeProductPrincipal },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFile(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
    setProccessLoading(false);
  };

  const importConfirm = async () => {
    const object = {
      type: typeProductPrincipal,
      filename: filename,
      confirm: true,
    };
    const { ok, response } = await confirmFile(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
    setUploadLoading(true);
  };
  const confirmInfo = async (object) => {
    const response = await confirmInfoFile(object, token);
    const totalData = response?.response?.total;
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setFileImport(null);
      setModalOpen(true);
      setErr(true);
      setResp(message);
      setProccessLoading(false);
      setIsModalPreviewOpen(false);
      setUploadLoading(false);
    } else {
      setModalOpen(true);
      setErr(false);
      setResp(message);
      setIsModalPreviewOpen(false);
      setFileImport(null);
      setUploadLoading(false);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
    setUploadLoading(false);
  };
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  // dropdown filter
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "principal_id") {
      setPrincipalId(value);
    } else if (name === "select_drop_down") {
      setSelectDropDownFilter(option);
    }
  };

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi princapl

  const [countSearch, setcountSearch] = useState(objectFilter?.countSearch);
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    }
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  // search funct
  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    setIsDisabled(true);
    dispatch(
      pageActions.setFilters({
        selectDropDownFilter: selectDropDownFilter,
        countSearch: countSearch,
        idPrincipal: principalId,
      })
    );
    setIsSubmit(!isSubmit);
  };
  const resetFilter = () => {
    setSubmit(true);
    setForm("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSelectDropDownFilter(null);
    setFilterPrincipalName("");
    setPrincipalId(1);
    setcountSearch("");
    dispatch(pageActions.resetFilters());
    setIsSubmit(!isSubmit);
    setPagePrincipal(1);
    setLimitPrincipal(10);
    setPage(1);
    setLimit(10);
    setPopOverOpen(false);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setcountSearch("");
  };

  const renderStatusColumnImport = (value) => {
    if (value.toLowerCase() === "status") {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {/* status  */}
            {t(`PRODUCT_PRINCIPAL:FIELD_${value}`)}
          </Box>
        ),
        dataIndex: value,
        key: value,
        sorter: (a, b) => a.value.localeCompare(b.value),
        sortDirections: ["descend", "ascend"],
        render: (status) => {
          return (
            <>
              <Stack direction="row">
                {status === "" ? (
                  <>
                    <Box marginRight={2}>{RenderIconStatus(status)}</Box>
                    <Box color={RenderColorStatus(status)}>
                      {RenderStatus(status)}
                    </Box>
                  </>
                ) : (
                  <>
                    <Tag
                      rounded="full"
                      px="2"
                      display="flex"
                      alignItems="center"
                    >
                      <Box marginRight={2}>{RenderIconStatus(status)}</Box>
                      <Box color={RenderColorStatus(status)}>
                        {RenderStatus(status)}
                      </Box>
                    </Tag>
                  </>
                )}
              </Stack>
            </>
          );
        },
      };
    } else {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t(`PRODUCT_PRINCIPAL:FIELD_${value}`)}
            {/* <SortButton thisField="id" /> */}
          </Box>
        ),
        dataIndex: value,
        key: value,
      };
    }
  };

  // variabel untuk menampung principal id berdasarkan jenis user yang login
  // get principal_id form auth
  const principal_id_auth = useSelector((state) => state.auth.principal_id);
  // principal id untuk detail atau edit
  const principal_id_selected = isAccepted ? principalId : principal_id_auth;

  // dropdown filter button dinamis berdasarkan user role yang login
  const renderFilterButton = () => {
    if (isAccepted) {
      return (
        <>
          <Select
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={false}
            placeholder="Select Principal"
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
        </>
      );
    }
  };
  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  // filter dan parameter
  // const [objectFilter, setObjectFilter] = useState({
  //   idPrincipal: 1,
  //   form: "",
  // });
  const filter = userPrincipal
    ? StringUtil.formatFilter({
        [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
      })
    : StringUtil.formatFilter({
        principal_id: `eq ${objectFilter?.idPrincipal ?? 1}`,
        [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
      });
  const params = { page, limit, filter, order };

  const fetchData = async () => {
    dispatch(getListProductPrincipal(params));
  };
  useEffect(() => {
    dispatch(getInitListProductPrincipal(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    setSubmit(false);
  }, [page, limit, token, dataChanged, isSubmit, submit, order, dispatch]);

  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport.map((item, index) => {
      return columnImport.reduce((prev, accumulator, index) => {
        prev[accumulator] = item[index];
        return prev;
      }, {});
    });
  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      principal_code: item.principal_code,
      principal_name: item.principal_name,
      principal_product_code: item.product_code,
      principal_product_name: item.product_name,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.EDIT,
              state: [item.id, principal_id_selected],
            }}
            text={<EditIcon />}
            backgroundcolor="transparent"
            id={ID_PRODUCT_PRINCIPAL.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.DETAIL,
              state: [item.id, principal_id_selected],
            }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
            id={ID_PRODUCT_PRINCIPAL.LIST.BTN_VIEW + "_" + item.id}
          />
        </div>
      ),
    }));
  const columnsImport = columnImport?.map((value) => {
    return renderStatusColumnImport(value);
  });
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_CODE")}
        </Box>
      ),
      dataIndex: "principal_code",
      key: "principal_code",
      ellipsis: true,
      sorter: (a, b) => a.principal_code.localeCompare(b.principal_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_NAME")}
        </Box>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "principal_product_code",
      key: "principal_product_code",
      sorter: (a, b) =>
        a.principal_product_code.localeCompare(b.principal_product_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_NAME")}
        </Box>
      ),
      dataIndex: "principal_product_name",
      key: "principal_product_name",
      sorter: (a, b) =>
        a.principal_product_name.localeCompare(b.principal_product_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_PRINCIPAL:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 110,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 105,
    },
  ];
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_PRINCIPAL:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_PRINCIPAL:SUBTITLE_3")}
            </span>{" "}
            - {t("PRODUCT_PRINCIPAL:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_SELLER.PRODUCT_PRINCIPAL.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("PRODUCT_PRINCIPAL:BUTTON_1")}
              id={ID_PRODUCT_PRINCIPAL.LIST.BTN_ADD}
            />
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("COMMON:BUTTON_UPLOAD_FILE")}
              marginStart={2}
              onClick={showModal}
              variant="outline"
              colorScheme="blue"
              id={ID_PRODUCT_PRINCIPAL.LIST.BTN_UPLOAD}
            />
          </>
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={userPrincipal ? "18.9rem" : "29.5rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={6}
          className="my-select-container"
        >
          {renderFilterButton()}
          <Select
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            value={selectDropDownFilter}
          >
            {selectDropDownFilterProductPrincipal.map((value, index) => {
              return (
                <>
                  <Select.Option key={index} value={value.value}>
                    {t(value.label)}
                  </Select.Option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              type="text"
              id={ID_PRODUCT_PRINCIPAL.LIST.SEARCH}
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonCustom
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              id={ID_PRODUCT_PRINCIPAL.LIST.BTN_APPLY}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_PRODUCT_PRINCIPAL.LIST.RESET_FILTER}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          onChange={handleTableChange}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  id={ID_PRODUCT_PRINCIPAL.LIST.ROW_PER_PAGE}
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="50">50</Select.Option>
                  <Select.Option value="100">100</Select.Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  id={ID_PRODUCT_PRINCIPAL.LIST.PAGINATION}
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImport
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
        template={template_product_principal}
        token={token}
      />
      <ModalImportPreview
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
        scrollXLength={2200}
        scrollYLength={2000}
        loading={uploadLoading}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ProductPrincipal;
