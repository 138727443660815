import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-day-picker/lib/style.css";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import { InformationIcon, SearchIcon } from "../../../../components/icons";
import {
  GRAY_COLOR,
  HOVER_PRIMARY,
  LABEL_STATUS_SUCCESS,
  PRIMARY_COLOR,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
// import { PRIMARY_COLOR } from "../../../../constant/propertiesConstant";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import CustomToolTip from "../../../../components/CustomToolTip";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { selectDropDownMonitoringSFTP } from "../../../../constant/selectOptConstant";
import { selectOptFilterUserTheme } from "../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../hooks/useCheckUserCategory";
import {
  getInitListSftpLog,
  getListSftpLog,
} from "../../../../services/main/monitoring/monitoringAS2Log";
import StringUtil from "../../../../utils/stringUtil";
import { ID_MONITORING } from "../../../../constant/idConstant";
const ListSftpAs2Log = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const data = useSelector((state) => state.aslogSftp.list_sftpLog);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [submit, setSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [form, setForm] = useState("");
  const [countSearch, setcountSearch] = useState("");
  const [popOverOpen, setPopOverOpen] = useState(false);

  const [order, setOrder] = useState("");
  const [deliveryDate, setdeliveryDate] = useState("");
  const [filterDeliveryDate, setfilterDeliveryDate] = useState("");

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const table_loading = useSelector((state) => state.aslogSftp.table_loading);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");

  // variabel untuk menampung principal id berdasarkan jenis user yang login
  // get principal_id form auth
  const principal_alias = useSelector((state) => state.auth.principal_alias);
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const { isAccepted } = useCheckUserCategory([
    userCategoryType.PRINCIPAL,
    userCategoryType.ADMIN,
  ]);

  const [objectFilter, setObjectFilter] = useState({
    created_at: "",
    form: "",
  });

  useMemo(() => {
    const [start, end] = deliveryDate;
    if (start && end) {
      setfilterDeliveryDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterDeliveryDate([]);
  }, [deliveryDate]);
  const filter = isAccepted
    ? StringUtil.formatFilter({
        created_at: `range ${objectFilter.created_at}`,
        [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
        principal: `eq ${principal_alias}`,
      })
    : StringUtil.formatFilter({
        created_at: `range ${objectFilter.created_at}`,
        [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
      });
  const params = { page, limit, filter, order };
  const fetchData = () => {
    dispatch(getListSftpLog(params)).then((response) => {
      if (!response.payload.ok) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListSftpLog(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    setSubmit(false);
    setIsDisabled(true);
  }, [page, limit, token, submit, order, total, dispatch]);

  // filter
  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setdeliveryDate([start, end]);
    } else setdeliveryDate([]);
    setIsDisabled(false);
  };
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    }
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };
  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    setObjectFilter({
      created_at: filterDeliveryDate,
      form: form,
    });
  };
  const resetFilter = () => {
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSubmit(true);
    setdeliveryDate([]);
    setSelectDropDownFilter("");
    setForm("");
    setPage(1);
    setLimit(10);
    setcountSearch("");
    setPopOverOpen(false);
    setObjectFilter({
      created_at: "",
      form: "",
    });
  };

  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "select_drop_down") {
      setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
    }
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      date_time: moment(item.date_time)
        .subtract(7, "hours")
        .format("YYYY-MM-DD HH:mm:ss"),
      principal_alias: item.principal,
      nopo: item.nopo,
      flat_file_name: item.file_name,
      status: item.status === "FAILED" ? "Failed" : "Success",
      message: item.message,
      log: item.log,
    }));
  const columns = [
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_DATE_TIME")}
        </Box>
      ),
      dataIndex: "date_time",
      key: "date_time",
      width: 200,
      sorter: (a, b) => a.date_time - b.date_time,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_PRINCIPAL")}
        </Box>
      ),
      dataIndex: "principal_alias",
      key: "principal_alias",
      width: 150,
      sorter: (a, b) => a.principal_alias - b.principal_alias,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "nopo",
      key: "nopo",
      width: 150,
      sorter: (a, b) => a.nopo - b.nopo,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_FILE_NAME")}
        </Box>
      ),
      dataIndex: "flat_file_name",
      key: "flat_file_name",
      width: 200,
      sorter: (a, b) => a.flat_file_name - b.flat_file_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 150,
      sorter: (a, b) => a.status - b.status,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_MESSAGE")}
        </Box>
      ),
      dataIndex: "message",
      key: "message",
      width: 150,
      sorter: (a, b) => a.message - b.message,
      sortDirections: ["descend", "ascend"],
      render(text, data) {
        if (data.status === LABEL_STATUS_SUCCESS) return "Ok";
        return (
          <>
            <Flex alignItems={"center"}>
              <Text mr={1}>{data.status}</Text>
              <CustomToolTip title={text} placement="bottomRight">
                <div>
                  <InformationIcon />
                </div>
              </CustomToolTip>
            </Flex>
          </>
        );
      },
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("SFTP_AS2_LOG:FIELD_LOG")}
        </Box>
      ),
      dataIndex: "log",
      key: "log",
      width: 150,
      sorter: (a, b) => a.log - b.log,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("SFTP_AS2_LOG:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("COMMON:FIELD_HOME")}</span> -{" "}
            <span style={{ color: "#003C7C" }}>
              {t("SFTP_AS2_LOG:SUBTITLE")}
            </span>{" "}
            -{" "}
            <span style={{ color: "#003C7C" }}>
              {t("SFTP_AS2_LOG:SUBTITLE")}
            </span>{" "}
            - {t("SFTP_AS2_LOG:TITLE_LIST")}
          </>
        }
      />
      <Box marginTop={6}>
        <Box marginLeft={"18.9rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            id={ID_MONITORING.LIST.DDL_PILIHAN}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            onClear={handleClear}
            allowClear={true}
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            value={
              selectDropDownFilter.value ? selectDropDownFilter.value : null
            }
          >
            {selectDropDownMonitoringSFTP?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              id={ID_MONITORING.LIST.SEARCH}
              style={{ border: "1px solid #d9d9d9" }}
              type="text"
              placeholder={
                selectDropDownFilter.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={
                selectDropDownFilter?.value === undefined ? "none" : "auto"
              }
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box width="240px">
            <InputDateRangePicker
              elementId={ID_MONITORING.LIST.DELIVERY_DATE}
              values={deliveryDate}
              onChange={handleChangeDate}
              placeHolder={t("COMMON:SELECT_DATE")}
            />
          </Box>
          <Box>
            <ButtonPrimary
              id={ID_MONITORING.LIST.BTN_SUBMIT}
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              // isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_MONITORING.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Box marginTop={4}>
          <Table
            dataSource={dataSource}
            columns={columns}
            marginTop={2}
            pagination={false}
            size="small"
            loading={table_loading}
            onChange={handleTableChange}
            scroll={{ y: 400 }}
            footer={() => (
              <Box
                backgroundColor="#E5EBF1"
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <Box display="flex" alignItems="center" marginX={2}>
                  <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                  <Select
                    id={ID_MONITORING.LIST.ROW_PER_PAGE}
                    border="1px solid"
                    size="sm"
                    width={20}
                    _focus={{ outline: "none" }}
                    value={limit}
                    onChange={(value, opt) =>
                      handleSelectPagination(value, opt)
                    }
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Select>
                </Box>
                <Box>
                  <Pagination
                    id={ID_MONITORING.LIST.NEXT_PAGE}
                    size="small"
                    current={page}
                    total={total}
                    onChange={(value) => setPage(value)}
                    showSizeChanger={false}
                    pageSize={limit}
                  />
                </Box>
              </Box>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ListSftpAs2Log;
