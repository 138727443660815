import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Stack,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "./styles/InputDateRangePicker.css";
import DateUtil from "../../utils/dateUtil";
import { useCallback } from "react";
import useStateHistory from "../../utils/useStateHistory";
import { useEffect } from "react";
import { Calendar } from "../icons";
import ButtonPrimary from "../button/ButtonPrimary";
import ButtonCustom from "../button/ButtonCustom";
import { WHITE_COLOR } from "../../constant/propertiesConstant";
import { useTranslation } from "react-i18next";
import ButtonCalendar from "../button/ButtonCalendar";
import { isAfter } from "date-fns";

const InputDatePickerPo = ({
  id,
  width,
  marginstart,
  marginend,
  name,
  values = [null, null],
  onBlur,
  placeholder,
  onChange = ([startDate, endDate]) => {},
  datePickerProps,
} = {}) => {
  const [date, setDate] = useState(null);

  const [
    startDateStateHistory,
    setStartDateStateHistory,
    {
      history: startDateHistory,
      setHistory: setStartDateHistory,
      index: startDateIndex,
      setIndex: setStartDateIndex,
      undo: undoStartDate,
      redo: redoStartDate,
      reset: resetStartDate,
    },
  ] = useStateHistory(new Date(), 2);

  const [
    endDateStateHistory,
    setEndDateStateHistory,
    {
      history: endtDateHistory,
      setHistory: setEndDateHistory,
      index: endDateIndex,
      setIndex: setEndDateIndex,
      undo: undoEndDate,
      redo: redoEndDate,
      reset: resetEndDate,
    },
  ] = useStateHistory(new Date(), 2);

  const onCancelButton = (onClose) =>{
    undoEndDate();
    onClose();
  }

  const initialFocusRef = useRef();
  const isDoneRef = useRef(false);
  const { t } = useTranslation();

  const today = new Date();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDateOpen, setEndDateOpen] = useState(false);

  const handleChangeStartDate = (value) => {
    // setStartDate(value);
    setStartDateOpen(true);
    setStartDateStateHistory(value);
    if (isAfter(value, endDateStateHistory)) {
      setEndDateHistory([value]);
      setEndDateIndex(0);
    }
  };

  const handleOnSubmit = () => {
    if (
      startDateStateHistory &&
      endDateStateHistory &&
      isAfter(endDateStateHistory, startDateStateHistory)
    ) {
      if (onChange) onChange([startDateStateHistory, endDateStateHistory]);
    }
    setStartDateOpen(false);
    setEndDateOpen(false);
  };

  /**
   1.handle date awal , ketika user ketika batalkan date tidak jadi,kembali ke nilai sebelumnya
   2. end date - cancelnya sama kaya start date
   3. harus bikin logic ketika start date lebih dari end date , end date menjadi start date
   4. ketika semua start date dan end date ada nilai nya dan end date lebih start date , trigger onchange ke parent
   */

  const handleChangeEndDate = (value) => {
    setEndDateOpen(true);
    setEndDateStateHistory(value);
  };

  useEffect(() => {
    const [startDate, endDate] = values;
    if (startDate) {
      setStartDateHistory([startDate]);
      setStartDateIndex(0);
    }

    if (endDate) {
      setEndDateHistory([endDate]);
      setEndDateIndex(0);
    }
  }, [values]);

  return (
    <>
      <Box minWidth="300px">
        <Stack direction="row">
          <Popover
            initialFocusRef={initialFocusRef}
            placement="bottom-start"
            closeOnBlur={false}
            isOpen={startDateOpen}
            onClose={handleOnSubmit}
            onOpen={() => {setStartDateOpen(true) ; setEndDateOpen(false)}}
          >
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <InputGroup
                    width={width}
                    marginStart={marginstart}
                    marginEnd={marginend}
                  >
                    <Input
                      id={id}
                      name={name}
                      value={DateUtil.formatDate(startDateStateHistory)}
                      backgroundColor={WHITE_COLOR}
                      readOnly
                      cursor="pointer"
                      onBlur={onBlur}
                      placeholder={placeholder}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      children={<Calendar />}
                    />
                  </InputGroup>
                </PopoverTrigger>
                <PopoverContent color={WHITE_COLOR}>
                  <PopoverBody padding="0">
                    <ReactDatePicker
                      id={`datepicker-${name ?? ""}`}
                      timeInputLabel="Time:"
                      showMonthDropdown
                      showTimeInput
                      selected={startDateStateHistory}
                      onChange={handleChangeStartDate}
                      shouldCloseOnSelect={false}
                      disabledKeyboardNavigation={true}
                      selectsStart
                      inline
                      startDate={startDateStateHistory}
                      //   endDate={endDateStateHistory}
                      {...datePickerProps}
                    />
                  </PopoverBody>
                  <PopoverFooter
                    border="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={4}
                  >
                    <Flex width="full" justifyContent="space-between">
                      <ButtonCustom
                        colorScheme="white"
                        color="black"
                        marginRight={2}
                        minWidth="100px"
                        maxWidth="100px"
                        marginLeft="2"
                        onClick={() => {
                          setStartDateHistory([
                            startDateHistory[startDateIndex - 1],
                          ]);
                          setStartDateIndex(0);
                          setStartDateOpen(false);
                        }}
                        text={t("COMMON:BUTTON_CANCEL")}
                      ></ButtonCustom>
                      <ButtonPrimary
                        minWidth="100px"
                        maxWidth="100px"
                        marginRight="2"
                        text={t("COMMON:BUTTON_DONE")}
                        onClick={onClose}
                      ></ButtonPrimary>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )}
          </Popover>
          <Popover
            // initialFocusRef={initialFocusRef}
            placement="bottom-start"
            closeOnBlur={false}
            onClose={handleOnSubmit}
            isOpen={endDateOpen}
            onOpen={() => {setStartDateOpen(false); setEndDateOpen(true); }}
          >
            {({ isOpen, onClose }) => (
              <>
                <PopoverTrigger>
                  <InputGroup
                    width={width}
                    marginStart={marginstart}
                    marginEnd={marginend}
                  >
                    <Input
                      id={id}
                      name={name}
                      value={DateUtil.formatDate(endDateStateHistory)}
                      backgroundColor={WHITE_COLOR}
                      readOnly
                      cursor="pointer"
                      onBlur={onBlur}
                      placeholder={placeholder}
                    />
                    <InputRightElement
                      pointerEvents="none"
                      children={<Calendar />}
                    />
                  </InputGroup>
                </PopoverTrigger>
                <PopoverContent color={WHITE_COLOR}>
                  <PopoverBody padding="0">
                    <ReactDatePicker
                      id={`datepicker-${name ?? ""}`}
                      timeInputLabel="Time:"
                      showMonthDropdown
                      showTimeInput
                      selected={endDateStateHistory}
                      onChange={handleChangeEndDate}
                      shouldCloseOnSelect={false}
                      disabledKeyboardNavigation={true}
                      selectsStart
                      inline
                      startDate={startDateStateHistory}
                      endDate={endDateStateHistory}
                      minDate={startDateStateHistory}
                      {...datePickerProps}
                    />
                  </PopoverBody>
                  <PopoverFooter
                    border="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={4}
                  >
                    <Flex width="full" justifyContent="space-between">
                      <ButtonCustom
                        colorScheme="white"
                        color="black"
                        marginRight={2}
                        minWidth="100px"
                        maxWidth="100px"
                        marginLeft="2"
                        onClick={undoEndDate}
                        text={t("COMMON:BUTTON_CANCEL")}
                      ></ButtonCustom>
                      <ButtonPrimary
                        minWidth="100px"
                        maxWidth="100px"
                        marginRight="2"
                        text={t("COMMON:BUTTON_DONE")}
                        onClick={onClose}
                      ></ButtonPrimary>
                    </Flex>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Stack>
      </Box>
    </>
  );
};

export default InputDatePickerPo;
