import { Box, Text } from "@chakra-ui/react";
import { Select } from "antd";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import FieldMultiSelectSearchCustomer from "../../../../../components/field/FieldMultiSelectSearchCustomer";
import FieldMultiSelectSearchDistributor from "../../../../../components/field/FieldMultiSelectSearchDistributor";
import FieldMultiSelectSearchStore from "../../../../../components/field/FieldMultiSelectSearchStore";
import FieldMultiSelectSearchWarehouse from "../../../../../components/field/FieldMultiSelectSearchWarehouse";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { ID_DATA_GROUP } from "../../../../../constant/idConstant";
import { ROUTES } from "../../../../../constant/routeConstant";
import { selectStatus2 } from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import {
  dataGroupAction, useWithEditDataGroup
} from "../../../../../services/main/dataGroupReducer";
import ObjectUtil from "../../../../../utils/objectUtil";
import { addDataScopeChema } from "../../dataGroupSchema";

const BodyDataScope3 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { index_page } = useSelector((state) => state.dataGroup);
  const { principal_id, company_name } = useSelector((state) => state.auth);
  const { data_scope, data_group, detail_data_group } = useSelector(
    (state) => state.dataGroup
  );
  const [warehouseInitialFilter, setWarehouseInitialFilter] = useState({});
  const [principalBranchFilter, setPrincipalBranchFilter] = useState({});
  const [storeFilter, setStoreFilter] = useState({});

  const [dataEdited, setDataEdited] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const handleNotificationModalOpen = (message, hasError = false) => {
    setErr(hasError);
    setResp(message);
    setModalOpen(true);
  };

  const { handleOnSubmit, is_submitting } = useWithEditDataGroup({
    onSuccess: ({ message }) => handleNotificationModalOpen(message),
    onError: ({ message }) => handleNotificationModalOpen(message, true),
  });

  const clickOkOnModalSuccess = () => {
    setDataEdited(true);
    history.replace(ROUTES.DATA_GROUP.LIST);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setDataEdited(false);
    setModalOpen(false);
  };

  const onSubmit = (values) => {
    dispatch(dataGroupAction.setDataScope(values));
    const formData = {
      ...data_scope,
      ...data_group,
      ...values,
    };

    handleOnSubmit(formData, detail_data_group?.id);
  };

  const {
    values,
    errors,
    isValid,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      po_status: data_scope.po_status,
      principal_branch: data_scope.principal_branch,
      customer: data_scope.customer,
      store: data_scope.store,
      warehouse: data_scope.warehouse,
    },
    validationSchema: addDataScopeChema(t),
    onSubmit: onSubmit,
    isInitialValid: true,
    enableReinitialize: true,
    // isInitialValid : data_group.group_id !== null ? true : false,
  });

  const handleChangeSelect = (name, value, opt) => {
    switch (name) {
      case "principal_branch":
        setFieldValue("principal_branch", opt);
        break;
      case "po_status":
        setFieldValue("po_status", opt);
        break;
      default:
    }
  };

  const handleOnCustomerChange = useCallback(() => {
    setFieldValue("store", []);
  }, [setFieldValue]);

  const handleOnPrincipalChange = useCallback(() => {
    setPrincipalBranchFilter({ principal_id: `eq ${principal_id}` });
    setWarehouseInitialFilter({ principal_id: `eq ${principal_id}` });
  }, [principal_id]);

  const changeToDataGroup = () => {
    dispatch(dataGroupAction.setIndexPage(index_page - 1));
    dispatch(dataGroupAction.setCurrPage(t("DATA_GROUP:DATAGROUP")));
  };

  useEffect(() => {
    if (ObjectUtil.isNotEmpty(values.customer)) {
      const customerListId = values.customer.reduce(
        (prev, accumulator, index, arr) => {
          if (index === arr.length - 1) {
            prev += String(accumulator.value);
          } else {
            prev += `${accumulator.value}_`;
          }

          return prev;
        },
        ""
      );
      if (
        values?.customer &&
        typeof values?.customer[0].value === "string" &&
        values.customer[0]?.value.includes("all")
      ) {
        setStoreFilter({ customer_id: `list_id ` });
      } else {
        setStoreFilter({ customer_id: `list_id ${customerListId}` });
      }
    }
  }, [values.customer]);

  useEffect(() => {
    if (principal_id) {
      setPrincipalBranchFilter({ principal_id: `eq ${principal_id}` });
      setWarehouseInitialFilter({ principal_id: `eq ${principal_id}` });
    }
  }, [principal_id]);

  useEffect(() => {
    handleOnPrincipalChange();
  }, [handleOnPrincipalChange]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box backgroundColor="white" minHeight="350px" padding={4}>
          {/* Principal Name */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("DATA_GROUP:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Box width="16rem">
              <Select
                width="16rem"
                style={inputTheme}
                id={ID_DATA_GROUP.EDIT.PRINCIPAL}
                size="large"
                name="principal_name"
                backgroundColor={"#EEEEEE"}
                placeholder={company_name}
                disabled
              />
            </Box>
          </Box>
          {/* PO Status */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("DATA_GROUP:FIELD_PO_STATUS")}</Text>
            </Box>
            <Box width="16rem">
              <Select
                width="16rem"
                size="large"
                id={ID_DATA_GROUP.EDIT.STATUS}
                // onBlur={handleBlur}
                value={values?.po_status}
                style={inputTheme}
                name="po_status"
                onChange={(value, opt) =>
                  handleChangeSelect("po_status", value, opt)
                }
                options={selectStatus2}
              ></Select>
              {errors.po_status && touched.po_status && (
                <ValidationError text={errors.po_status} />
              )}
            </Box>
          </Box>
          {/* Principal Branch */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("DATA_GROUP:FIELD_PRINCIPAL_BRANCH")}</Text>
            </Box>
            <Box width="16rem">
              <FieldMultiSelectSearchDistributor
                name="principal_branch"
                onChange={(v) => setFieldValue("principal_branch", v)}
                id={ID_DATA_GROUP.EDIT.PRINCIPAL_BRANCH}
                values={values.principal_branch}
                initialFilter={principalBranchFilter}
              />
              {errors.principal_branch && touched.principal_branch && (
                <ValidationError text={errors.principal_branch} />
              )}
            </Box>
          </Box>

          {/* Customer */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("DATA_GROUP:FIELD_CUSTOMER")}</Text>
            </Box>
            <Box width="16rem">
              <FieldMultiSelectSearchCustomer
                id={ID_DATA_GROUP.EDIT.CUSTOMER}
                name="customer"
                onChange={(v) => setFieldValue("customer", v)}
                values={values.customer}
                onValueChange={handleOnCustomerChange}
              />
              {errors.customer && touched.customer && (
                <ValidationError text={errors.customer} />
              )}
            </Box>
          </Box>
          {/* Store */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("DATA_GROUP:FIELD_STORE")}</Text>
            </Box>
            <Box width="16rem">
              <FieldMultiSelectSearchStore
                id={ID_DATA_GROUP.EDIT.STORE}
                name="store"
                onChange={(v) => setFieldValue("store", v)}
                values={values.store}
                initialFilter={storeFilter}
              />
              {errors.store && touched.store && (
                <ValidationError text={errors.store} />
              )}
            </Box>
          </Box>
          {/* Warehouse */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("DATA_GROUP:FIELD_WAREHOUSE")}</Text>
            </Box>
            <Box width="16rem">
              <FieldMultiSelectSearchWarehouse
                id={ID_DATA_GROUP.EDIT.WAREHOUSE}
                name="warehouse"
                onChange={(v) => setFieldValue("warehouse", v)}
                values={values.warehouse}
                initialFilter={warehouseInitialFilter}
              />
              {/* {errors.store && touched.store && (
                <ValidationError text={errors.store} />
              )} */}
            </Box>
          </Box>
        </Box>
        {/* button  */}
        <Box display="flex" marginTop={4} float="right">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            onClick={changeToDataGroup}
            text={t("COMMON:BUTTON_PREVIOUS")}
            id={ID_DATA_GROUP.EDIT.BTN_PREVIOUS}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            isLoading={is_submitting}
            isDisabled={is_submitting}
            id={ID_DATA_GROUP.EDIT.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </>
  );
};

export default BodyDataScope3;
