import { Box, Stack } from "@chakra-ui/react";
import React from "react";
import ButtonPrimary from "../components/button/ButtonPrimary";
import { PRIMARY_COLOR, WHITE_COLOR } from "../constant/propertiesConstant";
import ButtonCustom from "./button/ButtonCustom";

const ClickableTab = ({ tabs, onChangeTab, activeTab }) => {
  return (
    <Box>
      <Stack direction="row">
        {tabs &&
          tabs.map((tab, index) => (
            <ButtonCustom
              key={index}
              variant={index === activeTab ? "solid" : "outline"}
              text={tab}
              colorScheme={index === activeTab ? "white" : "blue"}
              background={index === activeTab ? PRIMARY_COLOR : WHITE_COLOR}
              minWidth="fit-content"
              onClick={() => onChangeTab && onChangeTab(index)}
            />
          ))}
      </Stack>
    </Box>
  );
};

export default ClickableTab;
