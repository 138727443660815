import { Spinner as ChakraSpinner } from "@chakra-ui/react"
import { GRAY_DISABLED, PRIMARY_COLOR } from "../../constant/propertiesConstant";

/**
 * 
 * @param {import("@chakra-ui/react").SpinnerProps} param0 
 * @returns 
 */
const Spinner = ({id, isDisabled, ...restProps}) => {
  return (
    <ChakraSpinner
      thickness='3px'
      speed='0.65s'
      emptyColor='gray.200'
      color={isDisabled ? GRAY_DISABLED : PRIMARY_COLOR}
      size='lg'
      {...restProps}
    />
  )
}

export default Spinner;