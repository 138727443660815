import { Box, Stack, Text } from "@chakra-ui/react";
import { Pagination, Select, Table, TreeSelect, message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HeadTitle from "../../../../../components/HeadTitle";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import {
  GRAY_COLOR,
  GRAY_OPTION,
  PRIMARY_COLOR,
  WHITE_COLOR,
  userCategoryType,
} from "../../../../../constant/propertiesConstant";

import {
  ExpandMoreDown,
  ExpandMoreUp,
  PrintIcon,
} from "../../../../../components/icons";

import { Option } from "antd/lib/mentions";
import FileSaver from "file-saver";
import moment from "moment";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { ID_REPORT_PO } from "../../../../../constant/idConstant";
import {
  DownloadFileOption,
  SelectDDLFontFamily,
  SelectDDLFontSize,
  SelectDDLGrid,
  SelectDDLGroupHeading,
  SelectDDLHeaderBackground,
  SelectDDLReportRows,
  SelectDDLWidth,
} from "../../../../../constant/selectOptConstant";
import {
  inputColumnSelection,
  selectGeneralFormatOptions,
  selectGeneralFormatOptionsFontFamily,
  selectGeneralFormatOptionsFontSize,
  selectGeneralFormatOptionsGrid,
  selectGeneralFormatOptionsGroupHeading,
  selectGeneralFormatOptionsHeader,
  selectOptFilterPrincipal,
  selectOptFilterUserTheme,
  selectOptFilterWarehouse,
} from "../../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../../hooks/useCheckUserCategory";

import { subHours } from "date-fns";
import InputDatePickerPo from "../../../../../components/datePicker/InputDatePickerPo";
import {
  getInitListReportPO,
  getListCustomerDDS,
  getListDistributor,
  getListPrincipalDDS,
  getListReportPO,
  getListWarehouse,
  printReportPO,
} from "../../../../../services/main/report/reportPOReducer";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import StringUtil from "../../../../../utils/stringUtil";
const ListPo = () => {
  const { isAccepted } = useCheckUserCategory([
    userCategoryType.SUPPORT,
    userCategoryType.CUSTOM,
  ]);
  const today = new Date();
  // Set to the start of the day (00:00)
  const startOfDay = new Date(today);
  startOfDay.setHours(0, 0, 0, 0);

  // Set to the end of the day (23:59)
  const endOfDay = new Date(today);
  endOfDay.setHours(23, 59, 59, 999);
  const { t } = useTranslation();
  const { user_category } = useSelector((state) => state.auth);
  const is_support = user_category === userCategoryType.SUPPORT;

  const data = useSelector((state) => state.reportPO.list_report_po);
  const princpId = useSelector((state) => state.auth?.principal_id);
  const principalAlias = useSelector((state) => state.auth?.principal_alias);
  const priAlias = principalAlias.toLowerCase();

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState(false);
  const [order, setOrder] = useState("");
  const { table_loading } = useSelector((state) => state.reportPO);
  const { printed_loading } = useSelector((state) => state.reportPO);

  const [updatedDate, setUpdatedDate] = useState([startOfDay, endOfDay]);

  const [buttonApply, setButtonApply] = useState("");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [generalFormat, setGeneralformat] = useState(false);
  const [columnSection, setColumnSection] = useState(false);

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [inputWarehouse, setInputWarehouse] = useState("");
  const [warehouseId, setWarehouseId] = useState([]);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  // const [limitWarehouse, setLimitWarehouse] = useState(10);
  const [limitWarehouse, setLimitWarehouse] = useState(50);
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const ddsWarehouse = useMemo(() => {
    return dataWarehouse?.filter((values) =>
      values?.warehouse_code.includes(searchWarehouse)
    );
  }, [dataWarehouse, searchWarehouse]);

  // distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(null);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);
  // variable principal
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [idPrincipal, setidPrincipal] = useState(null);

  const [objectFilter, setObjectFilter] = useState({
    updatedDate: [today, today],
    idPrincipal: is_support ? 1 : princpId,
    idCustomer: null,
    idDistributor: null,
    idWarehouse: [],
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedPrint, setselectedPrint] = useState(null);
  const ColumnSelection = [
    {
      id: 1,
      value: "distributor_name",
      label: t("REPORT_PO:FIELD_DISTRIBUTOR_NAME"),
    },
    { id: 2, value: "customer_name", label: t("REPORT_PO:FIELD_CUSTOMER") },
    { id: 3, value: "store_code", label: t("REPORT_PO:FIELD_STORE_CODE") },
    { id: 4, value: "store_name", label: t("REPORT_PO:FIELD_STORE_NAME") },
    { id: 5, value: "alias", label: t("REPORT_PO:FIELD_ALIAS") },
    {
      id: 6,
      value: "store_address",
      label: t("REPORT_PO:FIELD_STORE_ADDRESS"),
    },
    { id: 7, value: "po_number", label: t("REPORT_PO:FIELD_PO_NUMBER") },
    { id: 8, value: "po_date", label: t("REPORT_PO:FIELD_PO_DATE") },
    { id: 9, value: "so_number", label: t("REPORT_PO:FIELD_SO_NUMBER") },
    { id: 10, value: "so_date", label: t("REPORT_PO:FIELD_SO_DATE") },
    { id: 11, value: "do_number", label: t("REPORT_PO:FIELD_DO_NUMBER") },
    { id: 12, value: "so_amount", label: t("REPORT_PO:FIELD_SO_AMMOUNT") },
    { id: 13, value: "po_amount", label: t("REPORT_PO:FIELD_PO_AMMOUNT") },
    {
      id: 14,
      value: "time_process",
      label: t("REPORT_PO:FIELD_TIME_PROCCESS"),
    },
    {
      id: 15,
      value: "supplier_code",
      label: t("REPORT_PO:FIELD_SUPPLIER_CODE"),
    },
  ];
  const ColumnSelectionDanone = [
    {
      id: 1,
      value: "distributor_name",
      label: t("REPORT_PO:FIELD_DISTRIBUTOR_NAME"),
    },
    { id: 2, value: "customer_name", label: t("REPORT_PO:FIELD_CUSTOMER") },
    { id: 3, value: "store_code", label: t("REPORT_PO:FIELD_STORE_CODE") },
    { id: 4, value: "store_name", label: t("REPORT_PO:FIELD_STORE_NAME") },
    { id: 5, value: "alias", label: t("REPORT_PO:FIELD_ALIAS") },
    {
      id: 6,
      value: "store_address",
      label: t("REPORT_PO:FIELD_STORE_ADDRESS"),
    },
    { id: 7, value: "po_number", label: t("REPORT_PO:FIELD_PO_NUMBER") },
    { id: 8, value: "po_date", label: t("REPORT_PO:FIELD_PO_DATE") },
    { id: 9, value: "so_number", label: t("REPORT_PO:FIELD_SO_NUMBER") },
    { id: 10, value: "so_date", label: t("REPORT_PO:FIELD_SO_DATE") },
    { id: 11, value: "do_number", label: t("REPORT_PO:FIELD_DO_NUMBER") },
    { id: 12, value: "so_amount", label: t("REPORT_PO:FIELD_SO_AMMOUNT") },
    { id: 13, value: "po_amount", label: t("REPORT_PO:FIELD_PO_AMMOUNT") },
    {
      id: 14,
      value: "time_process",
      label: t("REPORT_PO:FIELD_TIME_PROCCESS"),
    },
    { id: 15, value: "warehouse_name", label: t("REPORT_PO:FIELD_WHS_ID") },
    { id: 16, value: "gap_amount", label: t("REPORT_PO:FIELD_GAP_AMMOUNT") },
    {
      id: 17,
      value: "so_imported_time",
      label: t("REPORT_PO:FIELD_1ST_SO_IMPORTED_TIME"),
    },
    {
      id: 18,
      value: "lead_time_process",
      label: t("REPORT_PO:FIELD_LEAD_TIME_PROCCESSED_IMPORTED"),
    },
    {
      id: 19,
      value: "approval_status",
      label: t("REPORT_PO:FIELD_STATUS_APPROVAL_PO"),
    },
    {
      id: 20,
      value: "modified_name",
      label: t("REPORT_PO:FIELD_1ST_MODIFIED_BY"),
    },
    {
      id: 21,
      value: "modified_at",
      label: t("REPORT_PO:FIELD_1ST_MODIFIED_TIME"),
    },
    {
      id: 22,
      value: "lead_time_approval",
      label: t("REPORT_PO:FIELD_LEAD_TIME_APPROVAL"),
    },
    {
      id: 23,
      value: "po_download_status",
      label: t("REPORT_PO:FIELD_STATUS_DOWNLOAD_PO"),
    },
    {
      id: 24,
      value: "po_download_name",
      label: t("REPORT_PO:FIELD_1ST_DOWNLOADED_BY"),
    },
    {
      id: 25,
      value: "po_download_time",
      label: t("REPORT_PO:FIELD_1ST_DOWNLOADED_TIME"),
    },
    {
      id: 26,
      value: "lead_time_download",
      label: t("REPORT_PO:FIELD_LEAD_TIME_DOWNLOAD_PO"),
    },
    { id: 27, value: "do_date", label: t("REPORT_PO:FIELD_DO_DATE") },
    {
      id: 28,
      value: "supplier_code",
      label: t("REPORT_PO:FIELD_SUPPLIER_CODE"),
    },
  ];
  let allIds =
    priAlias === "danone"
      ? ColumnSelectionDanone.map(({ value }) => value)
      : ColumnSelection.map(({ value }) => value);
  let dataColumn =
    priAlias === "danone" ? ColumnSelectionDanone : ColumnSelection;

  const role = useSelector((state) => state.auth.role);
  const idPrincipalState = useSelector((state) => state.auth.principal_id);

  const setDefaultPrincipalId = useMemo(() => {
    if (isAccepted) {
      setidPrincipal(1);
    } else setidPrincipal(idPrincipalState);
  }, [role]);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  // button Apply process

  const filter = StringUtil.formatFilter({
    schema: `eq ${objectFilter.idPrincipal}`,
    customer_id: `eq ${objectFilter.idCustomer}`,
    distributor_id: `eq ${objectFilter?.idDistributor?.value}`,
    warehouse_id: `list_id ${objectFilter?.idWarehouse?.join("_")}`,
  });
  const updated_at_start = moment(updatedDate[0]).format(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
  );
  const updated_at_end = moment(updatedDate[1]).format(
    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
  );

  const submitOk = () => {
    setSearch(!search);
    setPage(1);
    setButtonApply(idPrincipal);
    setObjectFilter({
      updatedDate: updatedDate,
      idDistributor: idDistributor,
      idPrincipal: idPrincipal,
      idCustomer: idCustomer,
      idWarehouse: warehouseId.map((val) => {
        return val.value;
      }),
    });
  };
  const params = {
    page,
    limit,
    filter,
    updated_at_start,
    updated_at_end,
    order,
  };
  const [object] = useState({
    distributor_name: false,
    customer_name: false,
    store_code: false,
    store_name: false,
    alias: false,
    store_address: false,
    po_number: false,
    po_date: false,
    warehouse_name: false,
    so_number: false,
    do_number: false,
    so_date: false,
    so_amount: false,
    po_amount: false,
    gap_amount: false,
    do_date: false,
    time_process: false,
    so_imported_time: false,
    lead_time_process: false,
    approval_status: false,
    modified_name: false,
    modified_at: false,
    lead_time_approval: false,
    po_download_status: false,
    po_download_name: false,
    po_download_time: false,
    lead_time_download: false,
    supplier_code: false,
  });
  const handleSelectColumn = (ids) => {
    setSelectedValues(ids);
  };

  const handleprint = () => {
    // coba bikin variable baru nampung object,
    // untuk di manipulasi isinya

    for (const listData of selectedValues) {
      for (const keyObj in object) {
        const check = listData === keyObj;

        if (check) {
          object[keyObj] = check;
        }
      }
    }

    const body = {
      page: parseInt(page),
      limit: parseInt(limit),
      export_type: selectedPrint,
      // date_selection: {
      //   start_date: moment(updatedDate[0]).format("YYYY-MM-DD") + "T00:00:00Z",
      //   end_date: moment(updatedDate[1]).format("YYYY-MM-DD") + "T23:59:59Z",
      // },
      date_selection: {
        start_date: moment(updatedDate[0]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        end_date: moment(updatedDate[1]).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      },
      data_criteria: {
        // po_date_start:
        //   moment(updatedDate[0]).format("YYYY-MM-DD") + "T00:00:00Z",
        // po_date_end: moment(updatedDate[1]).format("YYYY-MM-DD") + "T23:59:59Z",
        po_date_start: moment(updatedDate[0]).format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ),
        po_date_end: moment(updatedDate[1]).format(
          "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
        ),
        principal: idPrincipal,
        customer: idCustomer,
      },
      column_selection: object,
      general_format_options: {
        report_width:
          generalFormatObject.report_width === null
            ? 1
            : generalFormatObject.report_width,
        grid_line_color:
          generalFormatObject.grid_line_color === null
            ? 1
            : generalFormatObject.grid_line_color,
        column_header_background:
          generalFormatObject.column_header_background === null
            ? 1
            : generalFormatObject.column_header_background,
        report_rows:
          generalFormatObject.report_rows === null
            ? 1
            : generalFormatObject.report_rows,
        font_size:
          generalFormatObject.font_size === null
            ? 10
            : generalFormatObject.font_size,
        font_family:
          generalFormatObject.font_family === null
            ? 1
            : generalFormatObject.font_family,
        group_heading:
          generalFormatObject.group_heading === null
            ? 1
            : generalFormatObject.group_heading,
      },
    };

    dispatch(printReportPO(body)).then((response) => {
      setSelectedValues([]);
      for (const keyObj in object) {
        object[keyObj] = false;
      }
      setGeneralFormatObject({
        report_width: null,
        grid_line_color: null,
        column_header_background: null,
        report_rows: null,
        font_size: null,
        font_family: null,
        group_heading: null,
      });
      setselectedPrint(null);
      if (response) {
        if (response.error?.name) {
          return message.error("Empty data");
        }
        const { data, headers } = response.payload.response;
        const [, filename] = headers["content-disposition"].split(";");
        const [, name] = filename.trim().split("=");
        FileSaver.saveAs(data, name);
      }
    });
  };
  const fetchData = () => {
    dispatch(getListReportPO(params)).then((response) => {
      if (response.payload.ok === false) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListReportPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    // setIsDisabled(true);
    fetchData();
  }, [page, limit, search, order, dispatch]);

  // start fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi principal

  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${princpId}`,
    });
    const filtercs = StringUtil.formatFilter({
      principal_id: `eq ${idPrincipal}`,
    });
    const params = isAccepted
      ? { page, limit, filter: filtercs }
      : { page, limit, filter };

    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDataDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
      principal_id: `eq ${princpId}`,
    });
    const filtercs = StringUtil.formatFilter({
      principal_id: `eq ${idPrincipal}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = isAccepted
      ? { page, limit, filter: filtercs }
      : { page, limit, filter };

    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  const handleScrollDistributor = () => {
    setPageDistributor(pageDistributor + 1);
  };

  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor, idPrincipal]);

  useEffect(() => {
    fetchDataDistributorSearch();
  }, [filterDistributor, idPrincipal]);

  // fungsi customer
  const handleScrollCustomer = (event) => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomerName}`;
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  const fetchDataWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const fetchDataWarehouseSearch = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageWarehouse(1);
      setDataWarehouse(response.payload.response);
    });
  };

  const handleScrollWarehouse = () => {
    setPageWarehouse(pageWarehouse + 1);
  };
  // warehouse
  useEffect(() => {
    fetchDataWarehouse();
  }, [pageWarehouse]);

  useEffect(() => {
    fetchDataWarehouseSearch();
  }, [inputWarehouse]);

  const handleSelectPagination = (value, Option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setUpdatedDate([start, end]);
    } else setUpdatedDate([today, today]);

    setIsDisabled(false);
  };

  const handleChangeDates = (value) => {
    setUpdatedDate(value);
  };

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const handleChangeDateRange = (dates, dateStrings) => {
    const stringDate = dateStrings;
    const start = stringDate[0];
    const end = stringDate[1];
    if (start && end) {
      setUpdatedDate([dates[0], dates[1]]);
    }
  };

  const handleGeneralOption = () => {
    if (columnSection === true) {
      setColumnSection(false);
      setGeneralformat(!generalFormat);
    } else setGeneralformat(!generalFormat);
  };

  const [generalFormatObject, setGeneralFormatObject] = useState({
    report_width: null,
    grid_line_color: null,
    column_header_background: null,
    report_rows: null,
    font_size: null,
    font_family: null,
    group_heading: null,
  });

  const renderFormatOption = () => {
    if (generalFormat === true) {
      return (
        <Stack
          style={{ height: "49px" }}
          display="flex"
          padding="3px 8px"
          borderRadius="6px"
          direction="row"
          backgroundColor="#DDDDDD"
          alignItems="center"
          marginBottom={4}
        >
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.REPORT_WIDTH}
              style={selectGeneralFormatOptions}
              value={generalFormatObject.report_width}
              onChange={(value, opt) =>
                handleChangeSelect("report_width", value, opt)
              }
              showSearch={false}
              placeholder="Report Width"
            >
              {SelectDDLWidth?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.GRID_LINE_COLOR}
              style={selectGeneralFormatOptionsGrid}
              value={generalFormatObject.grid_line_color}
              onChange={(value, opt) =>
                handleChangeSelect("grid_line_color", value, opt)
              }
              showSearch={false}
              placeholder="Grid Line Color"
            >
              {SelectDDLGrid?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* column header background */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.HEADER_BACKGROUND}
              style={selectGeneralFormatOptionsHeader}
              value={generalFormatObject.column_header_background}
              onChange={(value, opt) =>
                handleChangeSelect("column_header_background", value, opt)
              }
              showSearch={false}
              placeholder="Column Header Background"
            >
              {SelectDDLHeaderBackground?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Report Rows */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.REPORT_ROWS}
              style={selectGeneralFormatOptions}
              value={generalFormatObject.report_rows}
              onChange={(value, opt) =>
                handleChangeSelect("report_rows", value, opt)
              }
              showSearch={false}
              placeholder="Report Rows"
            >
              {SelectDDLReportRows?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Font Size */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.FONT_SIZE}
              style={selectGeneralFormatOptionsFontSize}
              value={generalFormatObject.font_size}
              onChange={(value, opt) =>
                handleChangeSelect("font_size", value, opt)
              }
              showSearch={false}
              placeholder="Font Size"
            >
              {SelectDDLFontSize?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Font Family */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.FONT_FAMILY}
              style={selectGeneralFormatOptionsFontFamily}
              value={generalFormatObject.font_family}
              onChange={(value, opt) =>
                handleChangeSelect("font_family", value, opt)
              }
              showSearch={false}
              placeholder="Font Family"
            >
              {SelectDDLFontFamily?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Group Heading */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO.LIST.GROUP_HEADING}
              style={selectGeneralFormatOptionsGroupHeading}
              value={generalFormatObject.group_heading}
              onChange={(value, opt) =>
                handleChangeSelect("group_heading", value, opt)
              }
              showSearch={false}
              placeholder="Group Heading"
            >
              {SelectDDLGroupHeading?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
        </Stack>
      );
    } else return;
  };
  const resetFilter = () => {
    if (isAccepted) {
      // if()
      setidPrincipal(is_support ? 1 : princpId);
    } else setidPrincipal(idPrincipalState);
    setUpdatedDate([today, today]);
    // setUpdatedDate([moment(today), moment(today)]);
    setFilterPrincipalName("");
    setIdCustomer(null);
    setidDistributor(null);
    setfilterCustomerName("");
    setFilterDistributor("");
    setSearch(!search);
    setPage(1);
    setLimit(10);
    setIsDisabled(true);
    setObjectFilter({
      updatedDate: [today, today],
      idPrincipal: is_support ? 1 : princpId,
      idCustomer: null,
      idDistributor: null,
      idWarehouse: [],
    });
    setWarehouseId([]);
  };
  const handleChangeSelect = (name, value, Option) => {
    if (name === "principal_id") {
      setidPrincipal(value);
      setIsDisabled(false);
    } else if (name === "warehouse_id") {
      setWarehouseId(value);
      setIsDisabled(false);
    } else if (name === "distributor_id") {
      setidDistributor(Option);
      setIsDisabled(false);
    } else if (name === "customer_id") {
      setIdCustomer(value);
      setIsDisabled(false);
    } else if (name === "select_print") {
      setselectedPrint(value);
    } else if (name === "report_width") {
      setGeneralFormatObject({ ...generalFormatObject, report_width: value });
    } else if (name === "grid_line_color") {
      setGeneralFormatObject({
        ...generalFormatObject,
        grid_line_color: value,
      });
    } else if (name === "column_header_background") {
      setGeneralFormatObject({
        ...generalFormatObject,
        column_header_background: value,
      });
    } else if (name === "report_rows") {
      setGeneralFormatObject({ ...generalFormatObject, report_rows: value });
    } else if (name === "font_size") {
      setGeneralFormatObject({ ...generalFormatObject, font_size: value });
    } else if (name === "font_family") {
      setGeneralFormatObject({ ...generalFormatObject, font_family: value });
    } else if (name === "group_heading") {
      setGeneralFormatObject({ ...generalFormatObject, group_heading: value });
    }
  };

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_name: item.distributor_name,
      customer_name: item.customer_name,
      store_code: item.store_code,
      store_name: item.store_name,
      store_address: item.store_address,
      po_number: item.po_number,
      po_date:
        moment(item.po_date).format("DD-MM-YYYY") === "01-01-0001"
          ? "-"
          : moment(item.po_date).format("DD-MM-YYYY"),
      warehouse: item.warehouse_name,
      so_number: item.so_number,
      do_number: item.do_number,
      do_date:
        moment(item.do_date).format("DD-MM-YYYY") === "01-01-0001"
          ? "-"
          : moment(item.do_date).format("DD-MM-YYYY"),
      so_date:
        moment(item.so_date).format("DD-MM-YYYY") === "01-01-0001"
          ? "-"
          : moment(item.so_date).format("DD-MM-YYYY"),
      so_amount: item.so_amount,
      po_amount: item.po_amount,
      gap_amount: item.gap_amount,
      so_imported_time:
        moment(item.so_imported_time)
          .subtract(361, "minutes")
          .format("DD-MM-YYYY HH:mm:ss") === "01-01-0001 00:01:00"
          ? "-"
          : moment(item.so_imported_time)
              .subtract(361, "minutes")
              .format("DD-MM-YYYY HH:MM:SS"),
      processed_at: item.processed_at,
      status_approval: item.approval_status,
      modified_name: item.modified_name,
      modified_time:
        moment(item.modified_at).format("DD-MM-YYYY") === "01-01-0001"
          ? "-"
          : moment(item.modified_at).format("DD-MM-YYYY"),
      download_name: item.download_name,
      downloaded_time: item.downloaded_time,
      time_process: DateUtil.formatDate(
        subHours(new Date(item.time_process), 7),
        FORMAT.DATE_TIME
      ),
      alias: item.alias,
      lead_time_approval: item.lead_time_approval,
      status_download: item.po_download_status,
      downloaded_by: item.po_download_name,
      lead_time_download: item.lead_time_download,
      lead_time_process: item.lead_time_process,
      supplier_code : item.supplier_code
    }));

  let columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name - b.distributor_name,
      sortDirections: ["descend", "ascend"],
      fixed: "left",
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_CUSTOMER")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
      width: 170,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "store_code",
      key: "store_code",
      sorter: (a, b) => a.store_code - b.store_code,
      sortDirections: ["descend", "ascend"],
      width: 170,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
      width: 170,
    },

    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_ALIAS")}
        </Box>
      ),
      dataIndex: "alias",
      key: "alias",
      sorter: (a, b) => a.alias - b.alias,
      sortDirections: ["descend", "ascend"],
      width: 170,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_STORE_ADDRESS")}
        </Box>
      ),
      dataIndex: "store_address",
      key: "store_address",
      sorter: (a, b) => a.store_address - b.store_address,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_PO_DATE")}
        </Box>
      ),
      dataIndex: "po_date",
      key: "po_date",
      sorter: (a, b) => a.po_date - b.po_date,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_SO_NUMBER")}
        </Box>
      ),
      dataIndex: "so_number",
      key: "so_number",
      sorter: (a, b) => a.so_number - b.so_number,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_DO_NUMBER")}
        </Box>
      ),
      dataIndex: "do_number",
      key: "do_number",
      sorter: (a, b) => a.do_number - b.do_number,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_SO_DATE")}
        </Box>
      ),
      dataIndex: "so_date",
      key: "so_date",
      sorter: (a, b) => a.so_date - b.so_date,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_SO_AMMOUNT")}
        </Box>
      ),
      dataIndex: "so_amount",
      key: "so_amount",
      sorter: (a, b) => a.so_amount - b.so_amount,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_PO_AMMOUNT")}
        </Box>
      ),
      dataIndex: "po_amount",
      key: "po_amount",
      sorter: (a, b) => a.po_amount - b.po_amount,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_TIME_PROCCESS")}
        </Box>
      ),
      dataIndex: "time_process",
      key: "time_process",
      sorter: (a, b) => a.time_process - b.time_process,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_SUPPLIER_CODE")}
        </Box>
      ),
      dataIndex: "supplier_code",
      key: "supplier_code",
      sorter: (a, b) => a.suuplier_code - b.suuplier_code,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
  ];

  const extraColumn = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_DO_DATE")}
        </Box>
      ),
      dataIndex: "do_date",
      key: "do_date",
      sorter: (a, b) => a.do_date - b.do_date,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_GAP_AMMOUNT")}
        </Box>
      ),
      dataIndex: "gap_amount",
      key: "gap_amount",
      sorter: (a, b) => a.gap_amount - b.gap_amount,
      sortDirections: ["descend", "ascend"],
      width: 120,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_WHS_ID")}
        </Box>
      ),
      dataIndex: "warehouse",
      key: "warehouse",
      sorter: (a, b) => a.warehouse - b.warehouse,
      sortDirections: ["descend", "ascend"],
      width: 150,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_1ST_SO_IMPORTED_TIME")}
        </Box>
      ),
      dataIndex: "so_imported_time",
      key: "so_imported_time",
      sorter: (a, b) => a.so_imported_time - b.so_imported_time,
      sortDirections: ["descend", "ascend"],
      width: 170,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_LEAD_TIME_PROCCESSED_IMPORTED")}
        </Box>
      ),
      dataIndex: "lead_time_process",
      key: "lead_time_process",
      sorter: (a, b) => a.lead_time_process - b.lead_time_process,
      sortDirections: ["descend", "ascend"],
      width: 240,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_STATUS_APPROVAL_PO")}
        </Box>
      ),
      dataIndex: "status_approval",
      key: "status_approval",
      sorter: (a, b) => a.status_approval - b.status_approval,
      sortDirections: ["descend", "ascend"],
      width: 170,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_1ST_MODIFIED_BY")}
        </Box>
      ),
      dataIndex: "modified_name",
      key: "modified_name",
      sorter: (a, b) => a.modified_name - b.modified_name,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_1ST_MODIFIED_TIME")}
        </Box>
      ),
      dataIndex: "modified_time",
      key: "modified_time",
      sorter: (a, b) => a.modified_time - b.modified_time,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_LEAD_TIME_APPROVAL")}
        </Box>
      ),
      dataIndex: "lead_time_approval",
      key: "lead_time_approval",
      sorter: (a, b) => a.lead_time_approval - b.lead_time_approval,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_STATUS_DOWNLOAD_PO")}
        </Box>
      ),
      dataIndex: "status_download",
      key: "status_download",
      sorter: (a, b) => a.status_download - b.status_download,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_1ST_DOWNLOADED_BY")}
        </Box>
      ),
      dataIndex: "downloaded_by",
      key: "downloaded_by",
      sorter: (a, b) => a.downloaded_by - b.downloaded_by,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_1ST_DOWNLOADED_TIME")}
        </Box>
      ),
      dataIndex: "downloaded_time",
      key: "downloaded_time",
      sorter: (a, b) => a.downloaded_time - b.downloaded_time,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO:FIELD_LEAD_TIME_DOWNLOAD_PO")}
        </Box>
      ),
      dataIndex: "lead_time_download",
      key: "lead_time_download",
      sorter: (a, b) => a.lead_time_download - b.lead_time_download,
      sortDirections: ["descend", "ascend"],
      width: 200,
    },
  ];

  const kolomDinamis = useMemo(() => {
    // ketika principal id != 6 column return
    setSelectedValues([]);
    if (idPrincipal === 6) {
      return [...columns, ...extraColumn];
    }
    return columns;
  }, [buttonApply]);

  const onCancelDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  const onOkDatePikcer = (dates) => {
    // const stringDate = dateStrings;
    // const start = stringDate[0];
    // const end = stringDate[1];
    // if (start && end) {
    //   setUpdatedDate([dates[0], dates[1]]);
    // }
    setIsDatePickerOpen(false);
  };

  const onOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("REPORT_PO:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPORT_PO:SUBTITLE_HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPORT_PO:SUBTITLE_PARENT")}
            </span>{" "}
            - {t("REPORT_PO:TITLE_LIST")}
          </>
        }
        button={
          <div style={{ display: "flex" }}>
            <Box className="my-select-container">
              <Select
                style={{ selectGeneralFormatOptions }}
                size="large"
                placeholder={t("COMMON:EXPORT_TO")}
                // disabled=
                onChange={(value, opt) =>
                  handleChangeSelect("select_print", value, opt)
                }
                value={selectedPrint}
                disabled={selectedValues?.length === 0 ? true : false}
                backgroundColor="white"
                textColor={GRAY_COLOR}
                id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
              >
                {DownloadFileOption?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Box>

            <ButtonCustom
              id={ID_REPORT_PO.LIST.BTN_PRINT}
              icon={<PrintIcon />}
              width="100px"
              text={t("COMMON:PRINT")}
              disabled={!selectedPrint || printed_loading}
              marginStart={2}
              colorScheme="blue"
              variant="outline"
              onClick={handleprint}
              loading={printed_loading}
            />
          </div>
        }
      />
      <Box marginTop={6} minHeight="96px">
        <Box mb={2}>
          <b>Print Report</b>
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={4}>
          <Box className="my-select-container">
            <TreeSelect
              id={ID_REPORT_PO.LIST.COLUMN_SELECTION}
              style={inputColumnSelection}
              allowClear={true}
              placeholder={t("COMMON:COLUMN_SELECTION")}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              dropdownStyle={{ maxHeight: "300px" }}
              onChange={(ids) => {
                handleSelectColumn(ids);
              }}
              value={selectedValues}
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues) =>
                `+ ${omittedValues.length} `
              }
              treeData={[
                {
                  title:
                    selectedValues.length === dataColumn.length ? (
                      <span
                        onClick={() => setSelectedValues([])}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Unselect all
                      </span>
                    ) : (
                      <span
                        onClick={() => setSelectedValues(allIds)}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Select all
                      </span>
                    ),
                  // value: "xxx",
                  disableCheckbox: true,
                  disabled: true,
                },
                ...dataColumn,
              ]}
            />
          </Box>
          <ButtonCustom
            id={ID_REPORT_PO.LIST.FORMAT_OPTION}
            rightIcon={
              generalFormat === false ? <ExpandMoreDown /> : <ExpandMoreUp />
            }
            width="211px"
            text={t("COMMON:GENERAL_FORMAT")}
            variant="outline"
            backgroundColor="white"
            onClick={handleGeneralOption}
            fontSize="14px"
            borderColor={GRAY_OPTION}
            textColor={GRAY_OPTION}
          />
          <InputDatePickerPo
            id={"hehe"}
            onChange={setUpdatedDate}
            values={updatedDate}
          />
        </Stack>

        {/* filter print */}
        {renderFormatOption()}

        <Stack direction="row" alignItems="center" marginBottom={6}>
          <Box className="my-select-container">
            {/* By Distributor */}
            <Select
              placeholder={t("PROCCESS_APPROVE_PO:FIELD_DISTRIBUTOR")}
              style={selectOptFilterUserTheme}
              onPopupScroll={handleScrollDistributor}
              value={idDistributor}
              onChange={(value, opt) =>
                handleChangeSelect("distributor_id", value, opt)
              }
              size="large"
              fontSize="14px"
              textColor={GRAY_COLOR}
              id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
              backgroundColor="white"
              showSearch={false}
              dropdownRender={(menu) => (
                <DropDownCustom
                  menu={menu}
                  value={filterDistributor}
                  search={(e) => setFilterDistributor(e.target.value)}
                />
              )}
              allowClear="true"
            >
              {dataDistributor?.map((value, index) => {
                return (
                  <>
                    <option
                      key={index.toString()}
                      value={value.id}
                      label={value.name}
                    >
                      {value.name}
                    </option>
                  </>
                );
              })}
            </Select>
            {/* <InputDateRangePicker
              elementId={ID_REPORT_PO.LIST.PROCESS_DATE}
              values={updatedDate}
              onChange={handleChangeDate}
              defaultValue={[today, today]}
            /> */}
            {/* <InputDateRangePickerAntd
              id={ID_REPORT_PO.LIST.PROCESS_DATE}
              value={updatedDate}
              size="large"
              onCancel={onCancelDatePicker}
              onOk={(value) => {
                // onOkDatePikcer(dates);
              }}
              // isOpen={isDatePickerOpen}
              onChange={(dates, dateStrings) => {
                // setSelectedDate(dates);
                // setIsDatePickerOpen(true)
                handleChangeDateRange(dates, dateStrings);
              }}
              // onCalendarChange={onOpenDatePicker}
              // onOpenChange={onOpenDatePicker}
            /> */}
          </Box>
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_PO.LIST.PRINCIPAL}
              style={selectOptFilterUserTheme}
              value={idPrincipal}
              onPopupScroll={handleScrollPrincipal}
              onChange={(value, opt) =>
                handleChangeSelect("principal_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  value={filterPrincipalName}
                  menu={menu}
                  search={(e) => setFilterPrincipalName(e.target.value)}
                />
              )}
              showSearch={false}
              placeholder={t("COMMON:SELECT_PRINCIPAL")}
              size="large"
            >
              {dataPrincipal &&
                dataPrincipal.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.company_profile_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
          {/* customer  */}
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_PO.LIST.CUSTOMER}
              style={selectOptFilterPrincipal}
              size="large"
              value={idCustomer}
              onPopupScroll={handleScrollCustomer}
              placeholder={t("COMMON:SELECT_CUSTOMER")}
              // menggunakan onchange
              onChange={(value, opt) =>
                handleChangeSelect("customer_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  value={filterCustomerName}
                  menu={menu}
                  search={(e) => setfilterCustomerName(e.target.value)}
                />
              )}
              showSearch={false}
              allowClear="true"
            >
              {dataCustomer &&
                dataCustomer.map((data, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={data.id}
                      title={data.name}
                    >
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
          {/* warehouse  */}
          <Box className="my-select-container">
            <Select
              style={selectOptFilterWarehouse}
              maxTagCount={2}
              name="warehouse_id"
              mode="multiple"
              onChange={(value, opt) =>
                handleChangeSelect("warehouse_id", value, opt)
              }
              size="large"
              placeholder={"Search " + t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE")}
              value={warehouseId}
              onPopupScroll={handleScrollWarehouse}
              fontSize="14px"
              backgroundColor="white"
              textColor={GRAY_COLOR}
              allowClear="true"
              // onSearch={(e)=>setSearchWarehouse(e.target.value)}
              onSearch={(e) => setInputWarehouse(e.target.value)}
              optionFilterProp="children"
              showArrow
              // dropdownRender={(menu) => (
              //   <DropDownCustom
              //     // value={inputWarehouse}
              //     value={searchWarehouse}
              //     menu={menu}
              //     // search={(e) => setInputWarehouse(e.target.value)}
              //     search={(e) => setSearchWarehouse(e.target.value)}
              //   />
              // )}
              labelInValue={true}
            >
              {dataWarehouse?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value.id} label={value}>
                    {value.warehouse_code}
                  </option>
                );
              })}
            </Select>
          </Box>

          <Box>
            <ButtonCustom
              id={ID_REPORT_PO.LIST.BTN_APPLY}
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              // isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_REPORT_PO.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={kolomDinamis}
          pagination={false}
          size="small"
          scroll={{
            x: 2200,
            y: 330,
          }}
          loading={table_loading}
          onChange={handleTableChange}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_REPORT_PO.LIST.ROW_PAGE}
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Option value="10">10</Option>
                  <Option value="25">25</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_REPORT_PO.LIST.BTN_PAGINATION}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListPo;
