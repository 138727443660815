export const STORE_KEY = {
  Global: 'store@global:farmindo',
  Language: 'store:farmindo2_language'
}

export const getLocalStorage = (key, defaultValue = null) => {
  const unserializedData = localStorage.getItem(key);
  return unserializedData ? JSON.parse(unserializedData) : null;
}

export const setLocalStorage = (key, value, isSerialized = true) => {
  try {
    const serializedData = isSerialized ? JSON.stringify(value) : value;
    localStorage.setItem(key, serializedData);
    return true;
  } catch (error) {
    return false;
  }
}