import i18next from "i18next";

export const personTitle = [
  { id: 1, value: "Mr", label: "COMMON:MR" },
  { id: 2, value: "Ms", label: "COMMON:MRS" },
];

export const locale = ["id-ID", "en-US"];
export const selectRoleLevel = [13, 14];
export const selectLevel = [13, 14];
export const selectFrequency = [1, 2, 3, 4, 5];
export const selectPaginationOption = [10, 25, 50, 100];

export const gender = [
  { id: 1, value: "L", label: "COMMON:MALE" },
  { id: 2, value: "P", label: "COMMON:FEMALE" },
];

export const dayName = [
  "Senin",
  "Selasa",
  "Rabu",
  "Kamis",
  "Jumat",
  "Sabtu",
  "Minggu",
];

export const selectBklTypes = ["air", "galon"];

export const selectDropDownFilterUser = [
  { id: 1, value: "firstname", label: "USER:FIELD_FIRST_NAME" },
  { id: 2, value: "username", label: "USER:FIELD_USERNAME" },
];

export const selectStatus = [
  { id: 1, value: "A", label: "COMMON:STATUS_ACTIVE" },
  { id: 2, value: "N", label: "COMMON:STATUS_INACTIVE" },
];

export const selectStatus2 = [
  { value: "_all", label: "All", isDisabled: false },
  { value: "approve", label: "Approve", isDisabled: false },
  { value: "reject", label: "Reject", isDisabled: false },
  { value: "failed", label: "Failed", isDisabled: false },
  { value: "success", label: "Success", isDisabled: false },
];
export const selectStatusReProcess = [
  { id: 1, value: "SUCCESS", label: "COMMON:STATUS_SUCCESS" },
  { id: 2, value: "FAILED", label: "COMMON:STATUS_FAILED" },
  { id: 3, value: "PROCESS", label: "COMMON:STATUS_PROCESS" },
  { id: 4, value: "SUCCESS_WITH_UNMAPPED", label: "REPROCESSED:STATUS_SUCCESS_WITH_UNMAPPED" },
  { id: 5, value: "SUCCESS WITHOUT SEND FLAT FILE", label: "REPROCESSED:STATUS_SUCCESS_WITHOUT_SEND_FLAT_FILE" },
];

export const selectPMHandling = [
  { id: 1, value: "A", label: "COMMON:DROP_ALL" },
  { id: 2, value: "I", label: "COMMON:DROP_ITEM" },
];

export const selectDropDownFilterStore = [
  { id: 1, value: "store_code", label: "STORE:FIELD_STORE_CODE" },
  { id: 2, value: "store_name", label: "STORE:FIELD_STORE_NAME" },
];

export const selectDropDownFilterNotification = [
  { id: 1, value: "code", label: "NOTIFICATION:FIELD_CODE" },
  { id: 2, value: "name", label: "NOTIFICATION:FIELD_NAME" },
];

export const selectDropDownFilterProductPrincipal = [
  {
    id: 1,
    value: "product_code",
    label: "PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_CODE",
  },
  {
    id: 2,
    value: "product_name",
    label: "PRODUCT_PRINCIPAL:FIELD_PRINCIPAL_PRODUCT_NAME",
  },
];

export const selectDropDownFilterProductCustomer = [
  {
    id: 1,
    value: "product_code",
    label: "PRODUCT_CUSTOMER:FIELD_PRODUCT_CODE",
  },
  {
    id: 2,
    value: "product_name",
    label: "PRODUCT_CUSTOMER:FIELD_PRODUCT_NAME",
  },
];

export const selectDropDownFilterProductCategory = [
  {
    id: 1,
    value: "category code",
    label: "PRODUCT_CATEGORY:FIELD_CATEGORY_CODE",
  },
  {
    id: 2,
    value: "category name",
    label: "PRODUCT_CATEGORY:FIELD_CATEGORY_NAME",
  },
];

export const selectDropDownFilterProductMappingHo = [
  {
    id: 1,
    value: "principal_product_code",
    label: "PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_CODE",
  },
  {
    id: 2,
    value: "principal_product_name",
    label: "PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_NAME",
  },
  {
    id: 3,
    value: "product_code",
    label: "PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE",
  },
];

export const selectDropDownFilterProductMapping = [
  {
    id: 1,
    value: "principal_product_code",
    label: "PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_CODE",
  },
  {
    id: 2,
    value: "product_customer_code",
    label: "PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE",
  },
];

export const selectDropDownCategory = [
  { id: 1, value: "SUCCESS", label: "Notification Success" },
  { id: 2, value: "FAILED", label: "Notification Failed" },
];

export const selectDropDownSubCategory = [
  { id: 4, value: "ALL", label: "All" },
  { id: 1, value: "STORE_NOT_FOUND", label: "Store Not Map" },
  { id: 2, value: "PRODUCT_NOT_FOUND", label: "Product Not Found" },
  { id: 3, value: "BKL_MIXED_PRODUCT", label: "BKL Mixed Product" },
  { id: 5, value: "PDF_NOT_FOUND", label: "PDF File Not Found" },
];

export const selectDropDownProductCategoryMapping = [
  {
    id: 1,
    value: "store_code",
    label: "PRODUCT_CATEGORY_MAPPING:FIELD_STORE_CODE",
  },
  {
    id: 2,
    value: "store_name",
    label: "PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME",
  },
];

export const selectDropDownBKLMapping = [
  { id: 1, value: "area_code", label: "BKL_MAPPING:FIELD_AREA" },
  { id: 2, value: "store_code", label: "BKL_MAPPING:FIELD_STORE_CODE" },
  {
    id: 3,
    value: "distribution_centre",
    label: "BKL_MAPPING:FIELD_DISTRIBUTION_CENTRE",
  },
];

export const selectDropDownProductBKLMapping = [
  { id: 1, value: "store_code", label: "PRODUCT_BKL_MAPPING:FIELD_STORE_CODE" },
  { id: 2, value: "store_name", label: "PRODUCT_BKL_MAPPING:FIELD_STORE_NAME" },
];

export const selectDropDownProductSubtitute = [
  // {
  //   id: 1,
  //   value: "product_category_name",
  //   label: "PRODUCT_SUBTITUTE:FIELD_PRODUCT_CATEGORY_NAME",
  // },
  {
    id: 1,
    value: "principal_product_code",
    label: "PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_TO",
  },
];

export const selectDropDownWarehouse = [
  { id: 1, value: "warehouse_code", label: "WAREHOUSE:FIELD_WAREHOUSE_CODE" },
  { id: 2, value: "warehouse_name", label: "WAREHOUSE:FIELD_WAREHOUSE_NAME" },
];

export const selectDropDownGroup = [
  { id: 1, value: "DPD", label: "WAREHOUSE:FIELD_DPD" },
  { id: 2, value: "DEPO", label: "WAREHOUSE:FIELD_DEPO" },
];

export const selectDropDownDistributor = [
  {
    id: 1,
    value: "distributor_code",
    label: "DISTRIBUTOR:FIELD_DISTRIBUTOR_CODE",
  },
  {
    id: 2,
    value: "distributor_name",
    label: "DISTRIBUTOR:FIELD_DISTRIBUTOR_NAME",
  },
];

export const selectDropDownMonitoringSFTP = [
  { id: 1, value: "flat_file_name", label: "File Name" },
  { id: 2, value: "nopo", label: "PO Number" },
];
export const selectDropDownMonitoringListPO = [
  { id: 1, value: "store_name", label: "COMMON:STORE_NAME" },
  { id: 2, value: "po_number", label: "COMMON:PO_NUMBER" },
  { id: 3, value: "do_number", label: "COMMON:DO_NUMBER" },
  { id: 4, value: "so_number", label: "COMMON:SO_NUMBER" },
];

export const selectDropDownMonitoringUnmappedProduct = [
  { id: 2, value: "po_number", label: "COMMON:PO_NUMBER" },
  { id: 3, value: "product_code", label: "COMMON:PRODUCT_CODE" },
  { id: 4, value: "product_name", label: "COMMON:PRODUCT_NAME" },
];

export const selectDropDownMonitoringUnmappedStore = [
  { id: 2, value: "po_number", label: "COMMON:PO_NUMBER" },
  { id: 3, value: "store_code", label: "COMMON:STORE_CODE" },
  { id: 4, value: "supplier_code", label: "COMMON:SUPPLIER_CODE" },
];

export const selectDropDownPoOnSchedule = ["All", "Y", "N"];
export const selectDropDownPoStatus = [
  { id: 1, value: "APPROVED", label: "Approved" },
  { id: 2, value: "REJECTED", label: "Rejected" },
  { id: 3, value: "UPLOADED", label: "Uploaded" },
];

export const selectDropDownBKLSummary = [
  { id: 1, value: "po_number", label: "BKL_SUMMARY:FIELD_PO_NUMBER" },
  { id: 2, value: "customer_name", label: "BKL_SUMMARY:FIELD_CUSTOMER_NAME" },
  { id: 3, value: "store_code", label: "BKL_SUMMARY:FIELD_STORE_CODE" },
];

export const selectDropDownProccessApprovePo = [
  { id: 1, value: "po_number", label: "PROCCESS_APPROVE_PO:FIELD_PO_NUMBER" },
  { id: 2, value: "so_number", label: "PROCCESS_APPROVE_PO:FIELD_SO_NUMBER" },
  { id: 3, value: "do_number", label: "PROCCESS_APPROVE_PO:FIELD_DO_NUMBER" },
  { id: 4, value: "store_code", label: "PROCCESS_APPROVE_PO:FIELD_STORE_CODE" },
  { id: 5, value: "store_name", label: "PROCCESS_APPROVE_PO:FIELD_STORE_NAME" },
];

export const SelectDDLWidth = [
  { id: 1, value: 1, label: "Auto" },
  { id: 2, value: 2, label: "100%" },
  { id: 3, value: 3, label: "A4 72 PPI Potrait(595px)" },
  { id: 4, value: 4, label: "A4 72 PPI Landscape(842px)" },
];

export const SelectDDLGrid = [
  { id: 1, value: 1, label: "White" },
  { id: 2, value: 2, label: "Dark Gray" },
  { id: 3, value: 3, label: "Light Gray" },
  { id: 4, value: 4, label: "Gray" },
  { id: 5, value: 5, label: "Dim Gray" },
];
export const SelectDDLHeaderBackground = [
  { id: 1, value: 1, label: "White" },
  { id: 2, value: 2, label: "Dark Gray" },
  { id: 3, value: 3, label: "Light Gray" },
];

export const SelectDDLReportRows = [
  { id: 1, value: 1, label: "Plain" },
  { id: 2, value: 2, label: "Stripe" },
];

export const SelectDDLFontSize = [
  { id: 1, value: 10, label: "10px" },
  { id: 2, value: 12, label: "12px" },
  { id: 3, value: 14, label: "14px" },
  { id: 4, value: 18, label: "18px" },
  { id: 5, value: 24, label: "24px" },
];

export const SelectDDLFontFamily = [
  { id: 1, value: 1, label: "Tahoma" },
  { id: 2, value: 2, label: "Arial" },
  { id: 3, value: 3, label: "Georgia" },
  { id: 4, value: 4, label: "Century Gothic" },
  { id: 5, value: 5, label: "Palatino" },
  { id: 6, value: 6, label: "Trebuchet MS" },
];
export const SelectDDLGroupHeading = [
  { id: 1, value: 1, label: "Show on First Line" },
  // { id: 2, value: 2, label: "Show on Each Line" },
];



export const DownloadFileOption = [
  { id: 1, value: "csv", label: ".CSV" },
  { id: 2, value: "pdf", label: ".PDF" },
  { id: 3, value: "excel", label: ".XLSX" },
];

export const selectDropDownReprocessPO = [
  {
    id: 1,
    value: "file_name",
    label: "COMMON:FILE_NAME",
  },
  {
    id: 2,
    value: "po_number",
    label: "COMMON:PO_NUMBER",
  },
];
