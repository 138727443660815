import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";


const INITIAL_STATE = {
    list_processed_po: [],
    detail_processed_po:[],
    list_transaction_detail:[],
    selectedRowKeys: [],
}

export const getListMasterStoreDDS = createAsyncThunk(
  "getListMasterStoreDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProcessedPO = createAsyncThunk(
    "getListProcessedPO",
    async(params, { getState, rejectWithValue }) => {
        let result = { ok: false, response: null };
        const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process/po`;
        const { auth } = getState();
        const token = auth.token;
        try {
          const response = await baseApi.doGet(url, token, params);
          if (response) {
            result.ok = true;
            result.response = response.data.nexsoft.payload.data.content;
          }
        } catch (error) {
          if (error.response) {
            result.response = error.response;
          } else {
            message.error(i18next.t("COMMON:ERROR_NETWORK"));
          }
          return rejectWithValue(error)
        }
        return result;
    }
)

export const getInitListProcessedPO = createAsyncThunk(
    "getInitListProcessedPO",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process/po/initiate`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  
  export const detailProcessedPO = createAsyncThunk(
    "Get Detail Processed PO",
    async (processedId, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process/po/${processedId[0]}-${processedId[1]}`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
  
      return result;
    }
  );
  
  export const getListTransactionDetailPO = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/po-detail/po",
    async(params, { getState, rejectWithValue }) => {
        let result = { ok: false, response: null };
        const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po-detail`;
        const { auth } = getState();
        const token = auth.token;
        try {
          const response = await baseApi.doGet(url, token, params);
          if (response) {
            result.ok = true;
            result.response = response.data.nexsoft.payload.data.content;
          }
        } catch (error) {
          if (error.response) {
            result.response = error.response;
          } else {
            message.error(i18next.t("COMMON:ERROR_NETWORK"));
          }
        }
        return result;
    }
)

export const getInitTransactionDetailPO = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/po-detail/initiate",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/po-detail/initiate`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

  export const deleteProcessedPO = createAsyncThunk(
    "Delete Processed PO",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process/po/${params.Id}-${params.Principal}`;
      const { auth } = getState();
      const token = auth.token;
      const body = {
        updated_at: params.updated_at,
      };
      try {
        const response = await baseApi.doDelete(url, token, body);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.status.message;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
  
      return result;
    }
  );

  export const multiDeleteProcessedPO = createAsyncThunk(
    "Multi Delete Processed PO",
    async (body, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process/po`;
      const { auth } = getState();
      const token = auth.token;
      
      try {
        const response = await baseApi.doDelete(url, token, body);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.status.message;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
  
      return result;
    }
  );

  const processedPO = createSlice({
    name: "createProcessedPO",
    initialState: INITIAL_STATE,
    reducers: {
      resetDetailProcessedPO() {
        return INITIAL_STATE;
      },
      resetselectedRowKeysReprocessedPO(state) {
        state.selectedRowKeys=[]
      },
      setSingleCheck(state, action) {
        // if (!payload || typeof payload !== 'number')
        let tempSelected = [...state.selectedRowKeys];
        const isExist = state.selectedRowKeys?.find(
          (data) => data.id === action.payload.id
        );
        if (isExist) {
          tempSelected = tempSelected.filter(
            (data) => data.id !== action.payload.id
          );
          state.selectedRowKeys = tempSelected;
          return;
        }
        state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
      },
      setAllCheck(state, action) {
        let tempSelected = [...state.selectedRowKeys];
        let tempsId = action.payload;
        let isExist = false;
  
        for (const data of tempsId) {
          isExist = false;
          for (const existingId of state.selectedRowKeys) {
            if (data.id === existingId.id) {
              isExist = true;
              break;
            }
          }
          if (!isExist) break;
        }
  
        if (isExist) {
          for (const data of tempsId) {
            tempSelected = tempSelected.filter((item) => item.id !== data.id);
            state.selectedRowKeys = tempSelected;
          }
        } else {
          for (const data of tempsId) {
            const isExistOnRedux = state.selectedRowKeys?.find(
              (item) => item.id === data.id
            );
            if (!isExistOnRedux) {
              state.selectedRowKeys = [...state.selectedRowKeys, data];
            }
          }
          // state.selectedRowKeys = Array.from(new Set([...state.selectedRowKeys, ...tempsId]));
        }
      },
    },
    extraReducers: {
      [getListProcessedPO.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListProcessedPO.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.list_processed_po = action.payload.response;
      },
      [getListProcessedPO.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },
      [detailProcessedPO.pending]: (state, action) => {
        state.status = "loading";
      },
      [detailProcessedPO.fulfilled]: (state, action) => {
        state.status = "success";
        state.detail_processed_po = action.payload.response;
      },
      [detailProcessedPO.rejected]: (state, action) => {
        state.status = "failed";
      },
      [getListTransactionDetailPO.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListTransactionDetailPO.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.list_transaction_detail = action.payload.response;
      },
      [getListTransactionDetailPO.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },
    },
  });
  
  export const processedPOAction = processedPO.actions;
  
  export default processedPO.reducer;