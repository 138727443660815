import { Box, Stack, Text } from "@chakra-ui/react";
import { message, Pagination, Select, Table, TreeSelect } from "antd";
import { Option } from "antd/lib/mentions";
import FileSaver from "file-saver";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import InputDateRangePicker from "../../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  ExpandMoreDown,
  ExpandMoreUp,
  PrintIcon,
} from "../../../../../components/icons";
import { ID_REPORT_PO_DETAIL } from "../../../../../constant/idConstant";
import {
  GRAY_COLOR,
  GRAY_OPTION,
  PRIMARY_COLOR,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  DownloadFileOption,
  SelectDDLFontFamily,
  SelectDDLFontSize,
  SelectDDLGrid,
  SelectDDLGroupHeading,
  SelectDDLHeaderBackground,
  SelectDDLReportRows,
  SelectDDLWidth,
} from "../../../../../constant/selectOptConstant";
import {
  inputColumnSelection,
  selectGeneralFormatOptions,
  selectGeneralFormatOptionsFontFamily,
  selectGeneralFormatOptionsFontSize,
  selectGeneralFormatOptionsGrid,
  selectGeneralFormatOptionsGroupHeading,
  selectGeneralFormatOptionsHeader,
  selectOptFilterPrincipal,
  selectOptFilterUserTheme,
  selectOptFilterWarehouse,
} from "../../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../../hooks/useCheckUserCategory";
import {
  getInitListReportPODetail,
  getListCustomerDDS,
  getListPrincipalDDS,
  getListReportPODetail,
  printReportPODetail,
  getListDistributor,
  getListWarehouse,
} from "../../../../../services/main/report/reportPODetailReducer";
import StringUtil from "../../../../../utils/stringUtil";
const ListPoDetail = () => {
  const today = new Date();
  const { t } = useTranslation();

  const data = useSelector(
    (state) => state.reportPODetail.list_report_po_detail
  );
  const princpId = useSelector((state) => state.auth?.principal_id);
  const { isAccepted } = useCheckUserCategory([
    userCategoryType.SUPPORT,
    userCategoryType.CUSTOM,
  ]);
  const { user_category } = useSelector((state) => state.auth);
  const is_support = user_category === userCategoryType.SUPPORT;
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState(false);

  const { token } = useSelector((state) => state.auth);
  const { table_loading } = useSelector((state) => state.reportPODetail);
  const { printed_loading } = useSelector((state) => state.reportPODetail);
  const [updatedDate, setUpdatedDate] = useState([today, today]);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [order, setOrder] = useState("");

  // distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(null);
  // Definisikan variabel refs untuk melacak perubahan inputStore
  const prevInputDistributor = useRef(filterDistributor);
  const isSearchAction = useRef(false);

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [inputWarehouse, setInputWarehouse] = useState("");
  const [warehouseId, setWarehouseId] = useState([]);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  // const [limitWarehouse, setLimitWarehouse] = useState(10);
  const [limitWarehouse, setLimitWarehouse] = useState(50);
  const [searchWarehouse, setSearchWarehouse] = useState("");
  const ddsWarehouse = useMemo(() => {
    return dataWarehouse?.filter((values) =>
      values?.warehouse_code.includes(searchWarehouse)
    );
  }, [dataWarehouse, searchWarehouse]);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);

  // variable principal
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [idPrincipal, setidPrincipal] = useState(null);
  const prevIdPrincipalValue = useRef(idPrincipal);

  const [objectFilter, setObjectFilter] = useState({
    idPrincipal: 1,
    idCustomer: null,
    idWarehouse: [],
  });

  // general Option
  const [selectedValues, setSelectedValues] = useState([]);
  const [generalFormat, setGeneralformat] = useState(false);
  const [columnSection, setColumnSection] = useState(false);
  const [selectedPrint, setselectedPrint] = useState(null);

  const role = useSelector((state) => state.auth.role);
  const idPrincipalState = useSelector((state) => state.auth.principal_id);

  const setDefaultPrincipalId = useMemo(() => {
    if (isAccepted) {
      setidPrincipal(1);
    } else setidPrincipal(idPrincipalState);
  }, [role]);

  const ColumnSelectionPODetail = [
    {
      id: 1,
      value: "distributor_name",
      label: t("REPORT_PO_DETAIL:FIELD_DISTRIBUTOR_NAME"),
    },
    {
      id: 2,
      value: "customer_name",
      label: t("REPORT_PO_DETAIL:FIELD_CUSTOMER"),
    },
    {
      id: 4,
      value: "store_name",
      label: t("REPORT_PO_DETAIL:FIELD_STORE_NAME"),
    },
    {
      id: 5,
      value: "store_address",
      label: t("REPORT_PO_DETAIL:FIELD_STORE_ADDRESS"),
    },
    {
      id: 6,
      value: "expired_date",
      label: t("REPORT_PO_DETAIL:FIELD_EXPIRED_DATE"),
    },
    { id: 7, value: "po_number", label: t("REPORT_PO_DETAIL:FIELD_PO_NUMBER") },
    { id: 8, value: "po_date", label: t("REPORT_PO_DETAIL:FIELD_PO_DATE") },
    {
      id: 9,
      value: "delivery_date",
      label: t("REPORT_PO_DETAIL:FIELD_DELIVERY_DATE"),
    },
    {
      id: 10,
      value: "product_code",
      label: t("REPORT_PO_DETAIL:FIELD_PRODUCT_CODE"),
    },
    {
      id: 11,
      value: "product_name",
      label: t("REPORT_PO_DETAIL:FIELD_PRODUCT_NAME"),
    },
    { id: 12, value: "qty", label: t("REPORT_PO_DETAIL:FIELD_QTY") },
    { id: 13, value: "uom", label: t("REPORT_PO_DETAIL:FIELD_UOM") },
    {
      id: 14,
      value: "net_amount",
      label: t("REPORT_PO_DETAIL:FIELD_NET_AMMOUNT"),
    },
  ];
  let allIds = ColumnSelectionPODetail.map(({ value }) => value);
  let dataColumn = ColumnSelectionPODetail;
  // button Apply process
  const submitOk = () => {
    setSearch(!search);
    setPage(1);
    setObjectFilter({
      idPrincipal: idPrincipal,
      idCustomer: idCustomer,
      idWarehouse: warehouseId.map((val) => {
        return val.value;
      }),
    });
  };
  const filter = StringUtil.formatFilter({
    schema: `eq ${objectFilter.idPrincipal}`,
    customer_id: `eq ${objectFilter.idCustomer}`,
    distributor: `eq ${idDistributor?.value}`,
    warehouse_id : `list_id ${objectFilter?.idWarehouse?.join("_")}`
  });
  const updated_at_start = moment(updatedDate[0]).format("YYYY-MM-DD");
  const updated_at_end = moment(updatedDate[1]).format("YYYY-MM-DD");
  // const params = { page, limit, filter };

  const params = {
    page,
    limit,
    filter,
    updated_at_start,
    updated_at_end,
    order,
  };

  const fetchData = () => {
    dispatch(getListReportPODetail(params)).then((response) => {
      if (response.payload.ok === false) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListReportPODetail(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
  }, [page, limit, token, search, order, dispatch]);

  const fetchDataWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const fetchDataWarehouseSearch = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageWarehouse(1);
      setDataWarehouse(response.payload.response);
    });
  };

  const handleScrollWarehouse = () => {
    setPageWarehouse(pageWarehouse + 1);
  };
  // warehouse
  useEffect(() => {
    fetchDataWarehouse();
  }, [pageWarehouse]);

  useEffect(() => {
    fetchDataWarehouseSearch();
  }, [inputWarehouse]);

  // start fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi principal

  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${princpId}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const filtercs = StringUtil.formatFilter({
      principal_id: `eq ${idPrincipal}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = isAccepted
      ? { page, limit, filter: filtercs }
      : { page, limit, filter };

    dispatch(getListDistributor(params)).then((response) => {
      // setDataDistributor([...dataDistributor, ...resp]);
      if (!response.payload.ok) return;
      // setPageStore(1);

      const resp = response.payload.response;
      if (!resp) return;

      if (isSearchAction.current) {
        setDataDistributor(resp);
        prevIdPrincipalValue.current = idPrincipal;
        prevInputDistributor.current = filterDistributor;
      } else if (
        idPrincipal !== prevIdPrincipalValue.current ||
        filterDistributor !== prevInputDistributor.current
      ) {
        setDataDistributor(resp);
        prevIdPrincipalValue.current = idPrincipal;
        prevInputDistributor.current = filterDistributor;
      } else {
        setDataDistributor([...dataDistributor, ...resp]);
      }
    });
  };

  const handleScrollDistributor = () => {
    isSearchAction.current = false;
    setPageDistributor(pageDistributor + 1);
  };
  const handleSearchDistributor = (event) => {
    // Set isSearchAction to true to reset pageStore on search
    isSearchAction.current = true;
    setFilterDistributor(event);
    setPageDistributor(1);
  };

  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor, idPrincipal]);

  useEffect(() => {
    if (isSearchAction.current) {
      fetchDataDistributor();
    }
  }, [filterDistributor]);

  // fungsi customer
  const handleScrollCustomer = (event) => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomerName}`;
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  const handleSelectPagination = (value, Option) => {
    setPage(1);
    setLimit(value);
  };

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setUpdatedDate([start, end]);
    } else setUpdatedDate([today, today]);
    setIsDisabled(false);
  };

  const resetFilter = () => {
    if (isAccepted) {
      setidPrincipal(is_support ? 1 : princpId);
    } else setidPrincipal(idPrincipalState);
    setFilterPrincipalName("");
    setUpdatedDate([today, today]);
    setIdCustomer(null);
    setfilterCustomerName("");
    setSearch(!search);
    setPage(1);
    setLimit(10);
    setIsDisabled(true);
    setidDistributor(null);
    setPageDistributor(1);
    setFilterDistributor("");
    setWarehouseId([]);
    setObjectFilter({
      idPrincipal: 1,
      idCustomer: null,
      idWarehouse: [],
    });
  };
  const handleChangeSelect = (name, value, Option) => {
    if (name === "principal_id") {
      setidPrincipal(value);
      setIsDisabled(false);
      setidDistributor(null);
      setPageDistributor(1);
      setFilterDistributor("");
    } else if (name === "warehouse_id") {
      setWarehouseId(value);
      setIsDisabled(false);
    } else if (name === "distributor_id") {
      setidDistributor(Option);
    } else if (name === "customer_id") {
      setIdCustomer(value);
      setIsDisabled(false);
    } else if (name === "select_print") {
      setselectedPrint(value);
    } else if (name === "report_width") {
      setGeneralFormatObject({ ...generalFormatObject, report_width: value });
    } else if (name === "grid_line_color") {
      setGeneralFormatObject({
        ...generalFormatObject,
        grid_line_color: value,
      });
    } else if (name === "column_header_background") {
      setGeneralFormatObject({
        ...generalFormatObject,
        column_header_background: value,
      });
    } else if (name === "report_rows") {
      setGeneralFormatObject({ ...generalFormatObject, report_rows: value });
    } else if (name === "font_size") {
      setGeneralFormatObject({ ...generalFormatObject, font_size: value });
    } else if (name === "font_family") {
      setGeneralFormatObject({ ...generalFormatObject, font_family: value });
    } else if (name === "group_heading") {
      setGeneralFormatObject({ ...generalFormatObject, group_heading: value });
    }
  };

  const handleSelectColumn = (ids) => {
    setSelectedValues(ids);
  };

  const [object, setObject] = useState({
    distributor_name: false,
    customer_name: false,
    store_name: false,
    po_number: false,
    po_date: false,
    store_address: false,
    delivery_date: false,
    expired_date: false,
    product_code: false,
    product_name: false,
    qty: false,
    uom: false,
    net_amount: false,
  });
  const handleprint = () => {
    for (const listData of selectedValues) {
      for (const keyObj in object) {
        const check = listData?.includes(keyObj);

        if (check) {
          object[keyObj] = check;
        }
      }
    }

    const body = {
      page: parseInt(page),
      limit: parseInt(limit),
      export_type: selectedPrint,
      data_criteria: {
        principal: idPrincipal,
        customer: idCustomer,
        po_date_start:
          moment(updatedDate[0]).format("YYYY-MM-DD") + "T00:00:00Z",
        po_date_end: moment(updatedDate[1]).format("YYYY-MM-DD") + "T23:59:59Z",
      },
      column_selection: object,
      general_format_options: {
        report_width:
          generalFormatObject.report_width === null
            ? 1
            : generalFormatObject.report_width,
        grid_line_color:
          generalFormatObject.grid_line_color === null
            ? 1
            : generalFormatObject.grid_line_color,
        column_header_background:
          generalFormatObject.column_header_background === null
            ? 1
            : generalFormatObject.column_header_background,
        report_rows:
          generalFormatObject.report_rows === null
            ? 1
            : generalFormatObject.report_rows,
        font_size:
          generalFormatObject.font_size === null
            ? 10
            : generalFormatObject.font_size,
        font_family:
          generalFormatObject.font_family === null
            ? 1
            : generalFormatObject.font_family,
        group_heading:
          generalFormatObject.group_heading === null
            ? 1
            : generalFormatObject.group_heading,
      },
    };

    dispatch(printReportPODetail(body)).then((response) => {
      setSelectedValues([]);
      for (const keyObj in object) {
        object[keyObj] = false;
      }
      setGeneralFormatObject({
        report_width: null,
        grid_line_color: null,
        column_header_background: null,
        report_rows: null,
        font_size: null,
        font_family: null,
        group_heading: null,
      });
      setselectedPrint(null);
      if (response) {
        if (response.error?.name) {
          return message.error("Empty data");
        }
        const { data, headers } = response.payload.response;
        const [, filename] = headers["content-disposition"].split(";");
        const [, name] = filename.trim().split("=");
        FileSaver.saveAs(data, name);
      }
    });
  };

  // General Format Option
  const handleGeneralOption = () => {
    if (columnSection === true) {
      setColumnSection(false);
      setGeneralformat(!generalFormat);
    } else setGeneralformat(!generalFormat);
  };

  const [generalFormatObject, setGeneralFormatObject] = useState({
    report_width: null,
    grid_line_color: null,
    column_header_background: null,
    report_rows: null,
    font_size: null,
    font_family: null,
    group_heading: null,
  });

  const renderFormatOption = () => {
    if (generalFormat === true) {
      return (
        <Stack
          style={{ height: "49px" }}
          display="flex"
          padding="3px 8px"
          borderRadius="6px"
          direction="row"
          backgroundColor="#DDDDDD"
          alignItems="center"
          marginBottom={4}
        >
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.REPORT_WIDTH}
              style={selectGeneralFormatOptions}
              value={generalFormatObject.report_width}
              onChange={(value, opt) =>
                handleChangeSelect("report_width", value, opt)
              }
              showSearch={false}
              placeholder="Report Width"
            >
              {SelectDDLWidth?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.GRID_LINE_COLOR}
              style={selectGeneralFormatOptionsGrid}
              value={generalFormatObject.grid_line_color}
              onChange={(value, opt) =>
                handleChangeSelect("grid_line_color", value, opt)
              }
              showSearch={false}
              placeholder="Grid Line Color"
            >
              {SelectDDLGrid?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* column header background */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.HEADER_BACKGROUND}
              style={selectGeneralFormatOptionsHeader}
              value={generalFormatObject.column_header_background}
              onChange={(value, opt) =>
                handleChangeSelect("column_header_background", value, opt)
              }
              showSearch={false}
              placeholder="Column Header Background"
            >
              {SelectDDLHeaderBackground?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Report Rows */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.REPORT_ROWS}
              style={selectGeneralFormatOptions}
              value={generalFormatObject.report_rows}
              onChange={(value, opt) =>
                handleChangeSelect("report_rows", value, opt)
              }
              showSearch={false}
              placeholder="Report Rows"
            >
              {SelectDDLReportRows?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Font Size */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.FONT_SIZE}
              style={selectGeneralFormatOptionsFontSize}
              value={generalFormatObject.font_size}
              onChange={(value, opt) =>
                handleChangeSelect("font_size", value, opt)
              }
              showSearch={false}
              placeholder="Font Size"
            >
              {SelectDDLFontSize?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Font Family */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.FONT_FAMILY}
              style={selectGeneralFormatOptionsFontFamily}
              value={generalFormatObject.font_family}
              onChange={(value, opt) =>
                handleChangeSelect("font_family", value, opt)
              }
              showSearch={false}
              placeholder="Font Family"
            >
              {SelectDDLFontFamily?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
          {/* Group Heading */}
          <Box className="my-general-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.GROUP_HEADING}
              style={selectGeneralFormatOptionsGroupHeading}
              value={generalFormatObject.group_heading}
              onChange={(value, opt) =>
                handleChangeSelect("group_heading", value, opt)
              }
              showSearch={false}
              placeholder="Group Heading"
            >
              {SelectDDLGroupHeading?.map((value, index) => {
                return (
                  <>
                    <Option key={index} value={value.value}>
                      {t(value.label)}
                    </Option>
                  </>
                );
              })}
            </Select>
          </Box>
        </Stack>
      );
    } else return;
  };

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_name: item.distributor_name,
      customer_name: item.customer_name,
      store_name: item.store_name,
      store_address: item.store_address,
      po_number: item.po_number,
      po_date: moment(item.po_date).format("DD/MM/YYYY"),
      delivery_date: moment(item.delivery_date).format("DD/MM/YYYY"),
      expired_date: moment(item.expired_date).format("DD/MM/YYYY"),
      product_code: item.product_code,
      product_name: item.product_name,
      qty: item.qty,
      uom_1: item.uom_1,
      net_amount: item.net_amount,
    }));

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      fixed: "left",
      sorter: (a, b) => a.distributor_name - b.distributor_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_CUSTOMER")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_PO_DATE")}
        </Box>
      ),
      dataIndex: "po_date",
      key: "po_date",
      sorter: (a, b) => a.po_date - b.po_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_STORE_ADDRESS")}
        </Box>
      ),
      dataIndex: "store_address",
      key: "store_address",
      sorter: (a, b) => a.store_address - b.store_address,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_DELIVERY_DATE")}
        </Box>
      ),
      dataIndex: "delivery_date",
      key: "delivery_date",
      sorter: (a, b) => a.delivery_date - b.delivery_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_EXPIRED_DATE")}
        </Box>
      ),
      dataIndex: "expired_date",
      key: "expired_date",
      sorter: (a, b) => a.expired_date - b.expired_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "product_code",
      key: "product_code",
      sorter: (a, b) => a.product_code - b.product_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_PRODUCT_NAME")}
        </Box>
      ),
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) => a.product_name - b.product_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_QTY")}
        </Box>
      ),
      dataIndex: "qty",
      key: "qty",
      sorter: (a, b) => a.qty - b.qty,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_UOM")}
        </Box>
      ),
      dataIndex: "uom_1",
      key: "uom_1",
      sorter: (a, b) => a.uom_1 - b.uom_1,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("REPORT_PO_DETAIL:FIELD_NET_AMMOUNT")}
        </Box>
      ),
      dataIndex: "net_amount",
      key: "net_amount",
      sorter: (a, b) => a.net_amount - b.net_amount,
      sortDirections: ["descend", "ascend"],
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("REPORT_PO_DETAIL:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPORT_PO_DETAIL:SUBTITLE_HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("REPORT_PO_DETAIL:SUBTITLE_PARENT")}
            </span>{" "}
            - {t("REPORT_PO_DETAIL:TITLE_LIST")}
          </>
        }
        button={
          <div style={{ display: "flex" }}>
            <Box className="my-select-container">
              <Select
                style={{ selectGeneralFormatOptions }}
                size="large"
                placeholder={t("COMMON:EXPORT_TO")}
                disabled={selectedValues?.length === 0 ? true : false}
                onChange={(value, opt) =>
                  handleChangeSelect("select_print", value, opt)
                }
                value={selectedPrint}
                backgroundColor="white"
                textColor={GRAY_COLOR}
              >
                {DownloadFileOption?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Box>
            <ButtonCustom
              id={ID_REPORT_PO_DETAIL.LIST.BTN_PRINT}
              icon={<PrintIcon />}
              width="100px"
              text={t("COMMON:PRINT")}
              marginStart={2}
              colorScheme="blue"
              variant="outline"
              onClick={handleprint}
              disabled={!selectedPrint || printed_loading}
              loading={printed_loading}
            />
          </div>
        }
      />
      <Box marginTop={6} minHeight="96px">
        <Box mb={2}>
          <b>{t("COMMON:PRINT_REPORT")}</b>
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={4}>
          <Box className="my-select-container">
            <TreeSelect
              id={ID_REPORT_PO_DETAIL.LIST.COLUMN_SELECTION}
              style={inputColumnSelection}
              allowClear={true}
              placeholder={t("COMMON:COLUMN_SELECTION")}
              treeCheckable={true}
              showCheckedStrategy={TreeSelect.SHOW_CHILD}
              dropdownStyle={{ maxHeight: "300px" }}
              onChange={(ids) => {
                handleSelectColumn(ids);
              }}
              fontSize="12px"
              value={selectedValues}
              maxTagCount={1}
              maxTagPlaceholder={(omittedValues) =>
                `+ ${omittedValues.length} `
              }
              treeData={[
                {
                  title:
                    selectedValues.length === dataColumn.length ? (
                      <span
                        onClick={() => setSelectedValues([])}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Unselect all
                      </span>
                    ) : (
                      <span
                        onClick={() => setSelectedValues(allIds)}
                        style={{
                          display: "inline-block",
                          color: "#286FBE",
                          cursor: "pointer",
                        }}
                      >
                        Select all
                      </span>
                    ),
                  // value: "xxx",
                  disableCheckbox: true,
                  disabled: true,
                },
                ...dataColumn,
              ]}
            />
          </Box>
          <ButtonCustom
            id={ID_REPORT_PO_DETAIL.LIST.FORMAT_OPTION}
            rightIcon={
              generalFormat === false ? <ExpandMoreDown /> : <ExpandMoreUp />
            }
            width="211px"
            text={t("COMMON:GENERAL_FORMAT")}
            variant="outline"
            backgroundColor="white"
            onClick={handleGeneralOption}
            fontSize="14px"
            borderColor={GRAY_OPTION}
            textColor={GRAY_OPTION}
          />
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.PRINCIPAL}
              style={selectOptFilterPrincipal}
              value={idPrincipal}
              onPopupScroll={handleScrollPrincipal}
              onChange={(value, opt) =>
                handleChangeSelect("principal_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  value={filterPrincipalName}
                  menu={menu}
                  search={(e) => setFilterPrincipalName(e.target.value)}
                />
              )}
              showSearch={false}
              allowClear={false}
              placeholder={t("COMMON:SELECT_PRINCIPAL")}
              size="large"
            >
              {dataPrincipal &&
                dataPrincipal.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data.id}>
                      {data.company_profile_name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
        </Stack>

        {/* General Format */}
        {renderFormatOption()}

        <Stack direction="row" alignItems="center" marginBottom={4}>
          {/* By Distributor */}
          <Select
            placeholder={t("PROCCESS_APPROVE_PO:FIELD_DISTRIBUTOR")}
            style={selectOptFilterUserTheme}
            onPopupScroll={handleScrollDistributor}
            value={idDistributor}
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            size="large"
            fontSize="14px"
            textColor={GRAY_COLOR}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            backgroundColor="white"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                value={filterDistributor}
                search={(e) => handleSearchDistributor(e.target.value)}
              />
            )}
            allowClear="true"
          >
            {dataDistributor?.map((value, index) => {
              return (
                <>
                  <option
                    key={index.toString()}
                    value={value.id}
                    label={value.name}
                  >
                    {value.name}
                  </option>
                </>
              );
            })}
          </Select>
          <Box width="240px">
            <InputDateRangePicker
              elementId={ID_REPORT_PO_DETAIL.LIST.PROCESS_DATE}
              values={updatedDate}
              onChange={handleChangeDate}
              defaultValue={[today, today]}
            />
          </Box>
          {/* customer  */}
          <Box className="my-select-container">
            <Select
              id={ID_REPORT_PO_DETAIL.LIST.CUSTOMER}
              style={selectOptFilterPrincipal}
              size="large"
              value={idCustomer}
              onPopupScroll={handleScrollCustomer}
              placeholder={t("COMMON:SELECT_CUSTOMER")}
              // menggunakan onchange
              onChange={(value, opt) =>
                handleChangeSelect("customer_id", value, opt)
              }
              dropdownRender={(menu) => (
                <DropDownCustom
                  value={filterCustomerName}
                  menu={menu}
                  search={(e) => setfilterCustomerName(e.target.value)}
                />
              )}
              showSearch={false}
              allowClear="true"
            >
              {dataCustomer &&
                dataCustomer.map((data, index) => {
                  return (
                    <Select.Option
                      key={index}
                      value={data.id}
                      title={data.name}
                    >
                      {data.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Box>
          {/* warehouse  */}
          <Box className="my-select-container">
            <Select
              style={selectOptFilterWarehouse}
              maxTagCount={2}
              name="warehouse_id"
              mode="multiple"
              onChange={(value, opt) =>
                handleChangeSelect("warehouse_id", value, opt)
              }
              size="large"
              placeholder={"Search " + t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE")}
              value={warehouseId}
              onPopupScroll={handleScrollWarehouse}
              fontSize="14px"
              backgroundColor="white"
              textColor={GRAY_COLOR}
              allowClear="true"
              // onSearch={(e)=>setSearchWarehouse(e.target.value)}
              onSearch={(e) => setInputWarehouse(e.target.value)}
              optionFilterProp="children"
              showArrow
              // dropdownRender={(menu) => (
              //   <DropDownCustom
              //     // value={inputWarehouse}
              //     value={searchWarehouse}
              //     menu={menu}
              //     // search={(e) => setInputWarehouse(e.target.value)}
              //     search={(e) => setSearchWarehouse(e.target.value)}
              //   />
              // )}
              labelInValue={true}
            >
              {dataWarehouse?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value.id} label={value}>
                    {value.warehouse_code}
                  </option>
                );
              })}
            </Select>
          </Box>

          <Box>
            <ButtonCustom
              id={ID_REPORT_PO_DETAIL.LIST.BTN_APPLY}
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              // isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_REPORT_PO_DETAIL.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleTableChange}
          size="small"
          scroll={{
            x: 2200,
            y: 330,
          }}
          loading={table_loading}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_REPORT_PO_DETAIL.LIST.ROW_PAGE}
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Option value="10">10</Option>
                  <Option value="25">25</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_REPORT_PO_DETAIL.LIST.BTN_PAGINATION}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListPoDetail;
