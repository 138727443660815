import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  dataGroupAction,
  getInitiateListDataGroup,
  getListDataGroupOption,
} from "../../../../../services/main/dataGroupReducer";
import {
  getInitiateListRole,
  getListRoleOption,
  roleAction,
} from "../../../../../services/main/roleReducer";
import React, { useEffect, useState, useCallback } from "react";
import { userAction } from "../../../../../services/main/userReducer";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import { GRAY_DISABLED } from "../../../../../constant/propertiesConstant";
import { addAccessSettingSchema } from "../../userSchema";
import { useFormik } from "formik";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { inputTheme } from "../../../../../constant/themeConstant";
import { ID_USER } from "../../../../../constant/idConstant";
const BodyAccessSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // get data from redux
  const { access_setting } = useSelector((state) => state.user);
  const { company_profile_access } = useSelector((state) => state.auth);
  const { index_page } = useSelector((state) => state.user);
  const company_profile_name = useSelector((state) => state.auth.company_name);
  // role
  const [pageRole, setPageRole] = useState(1);
  const [limitRole, setLimitRole] = useState(10);
  const [dataRole, setDataRole] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const paramsLevel14 = {
    filter: "level eq 14",
  };
  const listRole = useSelector((state) => state.role.list_option);
  const countDataRole = useSelector((state) => state.role.count_data_option);
  // const fetchDataRole2 = useCallback(() => {
  //   let params = {
  //     page: pageRole,
  //     limit: limitRole,
  //   };
  //   dispatch(getListRoleOption(params)).then((res) => { 
  //   });
  // }, [pageRole, limitRole, dispatch]);
  const fetchDataRole = () => {
    let params = {
      page: pageRole,
      limit: limitRole,
      filter: paramsLevel14.filter,
    };
    dispatch(getListRoleOption(params)).then((res) => {
      if (!res.payload.ok) return;
      const resp = res.payload.response;
      if (!resp) return;
      setDataRole([...dataRole, ...resp]);
    });
  };
  let listRoleMapping =
    // ?.filter((val) => val.level === LEVEL_14)
    listRole?.map((val) => {
      let object = {
        dataPlaceholder: val.role_id,
        data: val.id,
      };
      return object;
    });
  const handleChangeRole = (value, option) => {
    setFieldValue("role_id", {
      value: value,
      label: option?.children,
    });
  };
  // data group
  const listDataGroup = useSelector((state) => state.dataGroup.list_option);
  const [pageDataGroup, setpageDataGroup] = useState(1);
  const [limitDataGroup, setlimitDataGroup] = useState(10);
  const countDataGroup = useSelector(
    (state) => state.dataGroup.count_data_option
  );
  const fetchDataGroup = () => {
    let params = {
      page: pageDataGroup,
      limit: limitDataGroup,
      filter: paramsLevel14.filter,
    };
    dispatch(getListDataGroupOption(params)).then((res) => {
      if (!res.payload.ok) return;
      const resp = res.payload.response;
      if (!resp) return;

      setDataGroup([...dataGroup, ...resp]);
    });
  };
  const handleChangeDataGroup = (value, option) => {
    setFieldValue("group_id", {
      value: value,
      label: option?.children,
    });
  };

  const onScroll = (event) => {
    const target = event.target;
    const containerHeight = event.currentTarget.clientHeight;
    const scrollHeight = event.currentTarget.scrollHeight;
    const scrollTop = Math.ceil(event.currentTarget.scrollTop);
    const progress = ((scrollTop + containerHeight) / scrollHeight) * 100;
    if (progress === 100) {
      setPageAndFetchRole();
    }
  };
  const setPageAndFetchRole = () => {
    if (pageRole + 1 <= Math.ceil(countDataRole / 10)) {
      setPageRole(pageRole + 1);
    }
    if (pageDataGroup + 1 <= Math.ceil(countDataGroup / 10)) {
      setpageDataGroup(pageDataGroup + 1);
    }
  };
  const onSubmit = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACTIVATION")));
    // set index page
    dispatch(userAction.setIndexPage(index_page + 1));
    dispatch(userAction.setAccessSetting(values));
  };
  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
    dispatch(userAction.setAccessSetting(values));
  };
  const handleScroll = (name) => {
    if (name === "role") {
      setPageRole(pageRole + 1);
    }
    if (name === "datagroup") {
      setpageDataGroup(pageDataGroup + 1);
    }
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      role_id: {
        value: access_setting?.role_id?.value,
        label: access_setting?.role_id?.label,
      },
      group_id: {
        value: access_setting?.group_id?.value,
        label: access_setting?.group_id?.label,
      },
    },
    validationSchema: addAccessSettingSchema(t),
    onSubmit,
    isInitialValid: access_setting.role_id !== "" ? true : false,
  });

  useEffect(() => {
    dispatch(getInitiateListDataGroup()).then((response) => {
      fetchDataGroup();
      dispatch(
        dataGroupAction.setCountDataOption(response.payload.response.count_data)
      );
    });
  }, [pageDataGroup]);

  useEffect(() => {
    dispatch(getInitiateListRole()).then((response) => {
      fetchDataRole();
      dispatch(
        roleAction.setCountDataOption(response.payload.response.count_data)
      );
    });
  }, [pageRole]);

  useEffectOnce(() => {
    dispatch(
      userAction.setAccessSetting({
        ...access_setting,
        company_profile_access: company_profile_access,
      })
    );
  });
  return (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* User Category  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_USER_CATEGORY")}
          </Box>
          <Box>
            <Select
              style={inputTheme}
              placeholder="Principal User"
              width="250px"
              disabled
              backgroundColor={GRAY_DISABLED}
              id={ID_USER.ADD.USER_CATEGORY}
              textColor="black"
              size="large"
            ></Select>
          </Box>
        </Box>
        {/* Company Profile   */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_COMPANY_PROFILE")}
          </Box>
          <Box>
            <Select
              placeholder={company_profile_name}
              width="250px"
              style={inputTheme}
              disabled
              id={ID_USER.ADD.COMPANY_PROFILE}
              textColor="black"
              size="large"
              backgroundColor={GRAY_DISABLED}
            ></Select>
          </Box>
        </Box>

        {/* Role  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_ROLE")}
          </Box>
          <Box>
            <Select
              style={inputTheme}
              name="role_id"
              onBlur={handleBlur}
              value={values?.role_id}
              id={ID_USER.ADD.ROLE}
              onPopupScroll={() => handleScroll("role")}
              onChange={handleChangeRole}
              dropdownStyle
              allowClear="true"
              size="large"
            >
              {dataRole &&
                dataRole?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.role_id}
                    </option>
                  );
                })}
            </Select>
            {errors?.role_id?.value && touched?.role_id?.value && (
              <ValidationError text={errors?.role_id?.value} />
            )}
          </Box>
        </Box>

        {/* Data Group  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_DATA_GROUP")}
          </Box>
          <Box>
            <Select
              style={inputTheme}
              id={ID_USER.ADD.DATA_GROUP}
              name="group_id"
              value={values?.group_id}
              onPopupScroll={() => handleScroll("datagroup")}
              onChange={handleChangeDataGroup}
              allowClear="true"
              size="large"
              onBlur={handleBlur}
            >
              {dataGroup &&
                dataGroup?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.group_id}
                    </option>
                  );
                })}
            </Select>
            {errors?.group_id?.value && touched?.group_id?.value && (
              <ValidationError text={errors?.group_id?.value} />
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          type="button"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
          id={ID_USER.ADD.BTN_PREVIOUS}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          id={ID_USER.ADD.BTN_NEXT}
        />
      </Box>
    </form>
  );
};

export default BodyAccessSetting;
