import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  list_product_principal: [],
  status: "",
  table_loading: false,
  add_warehouse_loading: false,
  edit_warehouse_loading: false,
  delete_warehouse_loading: false,
};
export const getInitiateListWarehouse = createAsyncThunk(
  "getInitiateListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListWarehouse = createAsyncThunk(
  "getListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailWarehouse = createAsyncThunk(
  "detailWarehouse",
  async (warehouseId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse/${warehouseId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addWarehouse = createAsyncThunk(
  "addWarehouse",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      principal_id: object?.principal_id,
      warehouse_code: object?.warehouse_code,
      warehouse_name: object?.warehouse_name,
      address_1: object?.address_1,
      address_2: "",
      address_3: "",
      country_id: object?.country_id,
      island_id: object?.island_id,
      province_id: object?.province_id,
      district_id: object?.district_id,
      sub_district_id: object?.sub_district_id,
      urban_village_id: object?.urban_village_id,
      postal_code_id: object?.postal_code_id,
      is_dpd_rules: object?.is_dpd_rules,
      additional_data: object?.is_dpd_rules
        ? {
            depo_name: object?.depo_name,
            group: object?.group,
          }
        : {
            depo_name: "",
            group: "",
          },
      status: object?.status,
    };

    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editWarehouse = createAsyncThunk(
  "editWarehouse",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      principal_id: object?.principal_id,
      warehouse_code: object?.warehouse_code,
      warehouse_name: object?.warehouse_name,
      address_1: object?.address_1,
      address_2: "",
      address_3: "",
      country_id: object?.country_id,
      island_id: object?.island_id,
      province_id: object?.province_id,
      district_id: object?.district_id,
      sub_district_id: object?.sub_district_id,
      urban_village_id: object?.urban_village_id,
      postal_code_id: object?.postal_code_id,
      is_dpd_rules: object?.is_dpd_rules,
      additional_data: object?.is_dpd_rules
        ? {
            depo_name: object?.depo_name,
            group: object?.group,
          }
        : {
            depo_name: "",
            group: "",
          },
      status: object?.status,
      updated_at: object?.updated_at,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse/${object.warehouseId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const deleteWarehouse = createAsyncThunk(
  "deleteWarehouse",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      warehouseId: object.warehouseId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse/${body.warehouseId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, body.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const warehouse = createSlice({
  name: "warehouse",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxWarehouse() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // warehouse
    [getInitiateListWarehouse.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListWarehouse.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListWarehouse.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListWarehouse.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list = action.payload.response;
    },
    [getListWarehouse.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [detailWarehouse.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailWarehouse.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [addWarehouse.pending]: (state, action) => {
      state.status = "loading";
      state.add_warehouse_loading = true;
    },
    [addWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
      state.add_warehouse_loading = false;
    },
    [addWarehouse.rejected]: (state, action) => {
      state.status = "failed";
      state.add_warehouse_loading = false;
    },

    [editWarehouse.pending]: (state, action) => {
      state.status = "loading";
      state.edit_warehouse_loading = true;
    },
    [editWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
      state.edit_warehouse_loading = false;
    },
    [editWarehouse.rejected]: (state, action) => {
      state.status = "failed";
      state.edit_warehouse_loading = false;
    },

    [deleteWarehouse.pending]: (state, action) => {
      state.status = "loading";
      state.delete_warehouse_loading = true;
    },
    [deleteWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
      state.delete_warehouse_loading = false;
    },
    [deleteWarehouse.rejected]: (state, action) => {
      state.status = "failed";
      state.delete_warehouse_loading = false;
    },

    // principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
  },
});

export const warehouseAction = warehouse.actions;

export default warehouse.reducer;
