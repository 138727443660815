import { Box } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RoleTree from "../../../../../components/RoleTree";
import {
  defaultPermissionSysUserAdminSeller, defaultPermissionSysUserSeller
} from "../../../../../constant/permissionConstant";
import { ROLE } from "../../../../../constant/propertiesConstant";
import {
  Permission,
  //   RolePermissionParser,
  RoleSysuserPermissionParser
} from "../../../../../lib/permission";

const BodyPermission2 = () => {
  const { t } = useTranslation();
  const [tree, setTree] = useState([]);
  const data = useSelector(
    (state) => state.role.detail_role // dapetin dari redux
  ); 
  const sysUserPermissionParser = new RoleSysuserPermissionParser();
  const initializePermissionTree = useCallback(() => {
    if (data?.permission && data?.role?.level) {
      let permission = null;
      switch (data.role.level) {
        case ROLE.SELLER:
          permission = new Permission(
            defaultPermissionSysUserSeller,
            sysUserPermissionParser
          );
          break;
        case ROLE.ADMIN_SELLER:
          permission = new Permission(
            defaultPermissionSysUserAdminSeller,
            sysUserPermissionParser
          );
          break;
        default:
      }

      permission?.parse(data?.permission); 
      setTree(permission?.clone());
    }
  }, [data]);

  useEffect(() => {
    initializePermissionTree();
  }, [initializePermissionTree]);

  return (
    <Box>
      <RoleTree
        translatePrefix="ROLE"
        translateAfterfix="ROLE_PERMISSION_"
        translateButtonAfterfix="TOGGLE_"
        data={tree}
        onChange={() => {}}
        onNodeClick={() => {}}
        translate={t}
      />
    </Box>
  );
};

export default BodyPermission2;
