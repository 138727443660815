import { Image } from "@chakra-ui/react"
import { useEffect } from "react";
import { useState } from "react";
import NoImagePlaceholder from '../assets/noimage_placeholder.png';

/**
 * 
 * @param {import("@chakra-ui/react").ImageProps} props 
 * @returns 
 */
const ImageCompanyLogoUser = ({ src, ...restProps} ) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (typeof src === 'object' || src instanceof File) {
      let imageObjectUrl = '';
      try {
        imageObjectUrl = URL.createObjectURL(src[0]);
      } catch (ignore) {}
      setImageUrl(imageObjectUrl);
    } else if (src && Array.isArray(src) && src[0] instanceof File) {
      let imageObjectUrl = '';
      try {
        imageObjectUrl = URL.createObjectURL(src[0]);
      } catch (ignore) {}
      setImageUrl(imageObjectUrl);
    } else {
      setImageUrl(src);
    }

    return () => {
      if (typeof src === 'object' || src instanceof File || Array.isArray(src)) {
        URL.revokeObjectURL(src);
      }
    }
  }, [src]);

  return (
    <Image 
      width='135px'
      height='90px'
      backgroundSize='cover'
      src={imageUrl ? imageUrl : NoImagePlaceholder}
      {...restProps} 
    />
  );
}

export default ImageCompanyLogoUser