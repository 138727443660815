import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import ImageCompanyLogo from "../../../../../../components/ImageCompanyLogo";
import FormatUtil from "../../../../../../utils/formatUtil";

const BodyCompanyProfile = (props) => {
  const data = props?.data;
  const dataDistributor = props?.datadistributor;
  const code = props?.distributorcode;
  const photo = data?.photo?.length > 0 ? data?.photo[0] : {};
  const { t } = useTranslation();
  return (
    <Box display="flex">
      {/* left side  */}
      <Box padding={1} width="50rem">
        {/* Company Logo */}
        <Box padding={1} marginBottom={2} minHeight="112px">
          <Box>{t("DISTRIBUTOR:FIELD_COMPANY_LOGO")}</Box>
          <Box>
            <ImageCompanyLogo src={FormatUtil.formatPhotoUrl(photo)} />
          </Box>
        </Box>
        {/* Code  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_CODE")}</Box>
          <Box>
            <Text>
              <b>{code ? code : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/*Distributor title  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_DISTRIBUTOR_TITLE")}</Box>
          <Box>
            <Text>
              <b>{data?.company_title_name ? data?.company_title_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Distributor name */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_DISTRIBUTOR_NAME")}</Box>
          <Box>
            <Text>
              <b>{data?.name ? data?.name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* NPWP */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_NPWP")}</Box>
          <Box>
            <Text>
              <b>{data?.npwp ? data?.npwp : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Email  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_EMAIL")}</Box>
          <Box>
            <Text>
              <b>{data?.email ? data?.email : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Phone  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_PHONE")}</Box>
          <Box>
            <Text>
              <b>{data?.phone ? data?.phone : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Fax Number  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_FAX_NUMBER")}</Box>
          <Box>
            <Text>
              <b>{data?.fax ? data?.fax : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Company Profile ID MDB  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_ALIAS")}</Box>
          <Box>
            <Text>
              <b>{dataDistributor?.alias ? dataDistributor?.alias : "-"}</b>
            </Text>
          </Box>
        </Box>
      </Box>
      {/* center side  */}
      <Box padding={1} width="50rem" minHeight="47px">
        {/* Address */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_ADDRESS")}</Box>
          <Box>
            <Text>
              <b>{data?.address_1 ? data?.address_1 : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Country  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_COUNTRY")}</Box>
          <Box>
            <Text>
              <b>{data?.country_name ? data?.country_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Island  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_ISLAND")}</Box>
          <Box>
            <Text>
              <b>{data?.island_name ? data?.island_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Province  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_PROVINCE")}</Box>
          <Box>
            <Text>
              <b>{data?.province_name ? data?.province_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* District  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_DISTRICT")}</Box>
          <Box>
            <Text>
              <b>{data?.district_name ? data?.district_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Sub District  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_SUB_DISTRICT")}</Box>
          <Box>
            <Text>
              <b>{data?.sub_district_name ? data?.sub_district_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Urban Village  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_URBAN_VILLAGE")}</Box>
          <Box>
            <Text>
              <b>{data?.urban_village_name ? data?.urban_village_name : "-"}</b>
            </Text>
          </Box>
        </Box>
      </Box>
      {/* right side  */}
      <Box padding={1} width="40rem" minHeight="47px">
        {/* Hamlet */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_HAMLET")}</Box>
          <Box>
            <Text>
              <b>{data?.hamlet ? data?.hamlet : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Neighborhood  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_NEIGHBOURHOOD")}</Box>
          <Box>
            <Text>
              <b>{data?.neighbourhood ? data?.neighbourhood : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Postal Code  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("DISTRIBUTOR:FIELD_POSTAL_CODE")}</Box>
          <Box>
            <Text>
              <b>{data?.postal_code_name ? data?.postal_code_name : "-"}</b>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyCompanyProfile;
