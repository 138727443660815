import { REGEX_CONSTANT } from "../../../../constant/regexConstant";
import * as yup from "yup";

export const addWarehouseSchema = (t) => {
  return () =>
    yup.object().shape({
      warehouse_code: yup
        .string()
        .nullable()
        .max(
            35,
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 35,
            })
          )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("WAREHOUSE:FIELD_WAREHOUSE_CODE"),
          })
        ),
      warehouse_name: yup
        .string()
        .nullable()
        .max(
            100,
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 100,
            })
          )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("WAREHOUSE:FIELD_WAREHOUSE_NAME"),
          })
        ),
      address_1: yup
        .string()
        .nullable()
        .max(
            100,
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 100,
            })
          )
        .required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("WAREHOUSE:FIELD_ADDRESS"),
          })
        ),
      status: yup
        .string()
        .required(
          t("COMMON:ERROR_REQUIRED", { field: t("WAREHOUSE:FIELD_STATUS") })
        ),
      is_dpd_rules: yup.boolean().nullable(),
      depo_name: yup.string().when("is_dpd_rules", {
        is: (value) => value,
        then: yup.string().required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("WAREHOUSE:FIELD_DEPO_NAME"),
          })
        ),
      }),
      group: yup.string().when("is_dpd_rules", {
        is: (value) => value,
        then: yup.string().required(
          t("COMMON:ERROR_REQUIRED", {
            field: t("WAREHOUSE:FIELD_GROUP"),
          })
        ),
      }),
    });
};


export const editWarehouseSchema = (t) => {
    return () =>
      yup.object().shape({
        warehouse_code: yup
          .string()
          .nullable()
          .max(
            35,
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 35,
            })
          )
          .required(
            t("COMMON:ERROR_REQUIRED", {
              field: t("WAREHOUSE:FIELD_WAREHOUSE_CODE"),
            })
          ),
        warehouse_name: yup
          .string()
          .nullable()
          .max(
            100,
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 100,
            })
          )
          .required(
            t("COMMON:ERROR_REQUIRED", {
              field: t("WAREHOUSE:FIELD_WAREHOUSE_NAME"),
            })
          ),
        address_1: yup
          .string()
          .nullable()
          .max(
            100,
            t("COMMON:ERROR_MAX_LENGTH", {
              length: 100,
            })
          )
          .required(
            t("COMMON:ERROR_REQUIRED", {
              field: t("WAREHOUSE:FIELD_ADDRESS"),
            })
          ),
        status: yup
          .string()
          .required(
            t("COMMON:ERROR_REQUIRED", { field: t("WAREHOUSE:FIELD_STATUS") })
          ),
        is_dpd_rules: yup.boolean().nullable(),
        depo_name: yup.string().when("is_dpd_rules", {
          is: (value) => value,
          then: yup.string().required(
            t("COMMON:ERROR_REQUIRED", {
              field: t("WAREHOUSE:FIELD_DEPO_NAME"),
            })
          ),
        }),
        group: yup.string().when("is_dpd_rules", {
          is: (value) => value,
          then: yup.string().required(
            t("COMMON:ERROR_REQUIRED", {
              field: t("WAREHOUSE:FIELD_GROUP"),
            })
          ),
        }),
      });
  };