import { Box, Stack, Tag } from "@chakra-ui/react";
import { Table, Select, Pagination } from "antd";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  detailInitiateListBKLSummary,
  detailListBKLSummary,
} from "../../../../../services/main/bklSummaryReducer";
import {
  detailInitiateApprovePO,
  detailListApprovePO,
} from "../../../../../services/main/proccessApprovePoReducer";
import {
  getInitTransactionDetailPO,
  getListTransactionDetailPO,
} from "../../../../../services/main/processedPOReducer";
import StringUtil from "../../../../../utils/stringUtil";
const TransactionDetailProcess = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { detail_table_loading } = useSelector(
    (state) => state.proccessApprovePo
  );

  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const params = {
    page,
    limit,
    order,
    // filter
  };
  const object = {
    params: params,
    id: location.state.id,
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const fetchDataDetail = () => {
    dispatch(detailListApprovePO(object)).then((response) => {
      setData(response.payload.response);
    });
  };

  useEffect(() => {
    dispatch(detailInitiateApprovePO(object)).then((response) => {
      setTotal(response.payload.response.count_data);
    });

    fetchDataDetail();
  }, [page, limit, total ,order, dispatch]);

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      product_code: item.product_code,
      product_name: item.product_name,
      qty_po: item.po_qty,
      qty_so: item.so_qty,
      qty_do: item.do_qty,
      net_amount_po: item?.po_net_amount
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      net_amount_so: item?.do_net_amount
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
      net_amount_do: item?.so_net_amount
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, "."),
    }));

  const columns = [
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "product_code",
      key: "product_code",
      sorter: (a, b) => a.product_code.localeCompare(b.product_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_PRODUCT_NAME")}
        </Box>
      ),
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_PO_QTY")}
        </Box>
      ),
      dataIndex: "qty_po",
      key: "qty_po",
      sorter: (a, b) => a.qty_po - b.qty_po,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_SO_QTY")}
        </Box>
      ),
      dataIndex: "qty_so",
      key: "qty_so",
      sorter: (a, b) => a.qty_so - b.qty_so,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_DO_QTY")}
        </Box>
      ),
      dataIndex: "qty_do",
      key: "qty_do",
      sorter: (a, b) => a.qty_do - b.qty_do,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_NET_AMOUNT_PO")}
        </Box>
      ),
      dataIndex: "net_amount_po",
      key: "net_amount_po",
      sorter: (a, b) => a.net_amount_po - b.net_amount_po,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_NET_AMOUNT_SO")}
        </Box>
      ),
      dataIndex: "net_amount_so",
      key: "net_amount_so",
      sorter: (a, b) => a.net_amount_so - b.net_amount_so,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_NET_AMOUNT_DO")}
        </Box>
      ),
      dataIndex: "net_amount_do",
      key: "net_amount_do",
      sorter: (a, b) => a.net_amount_do - b.net_amount_do,
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <>
      <Box pt={4} pb={4}>
        <HeadTitle titleSection={t("PROCCESS_APPROVE_PO:TRANSACTION_DETAIL")} />
      </Box>
      {/* table body */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        onChange={handleTableChange}
        loading={detail_table_loading}
        size="small"
        footer={() => (
          <Box
            backgroundColor="#E5EBF1"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Box display="flex" alignItems="center" marginX={2}>
              <Box marginX={4}>Row Per Page</Box>
              <Select
                border="1px solid"
                borderColor="#CCCCCC"
                size="sm"
                width={20}
                _focus={{ outline: "none" }}
                value={limit}
                // onChange={handleSelectPagination}
                onChange={(value, opt) => handleSelectPagination(value, opt)}
              >
                <Select.Option value="10">10</Select.Option>
                <Select.Option value="25">25</Select.Option>
                <Select.Option value="50">50</Select.Option>
                <Select.Option value="100">100</Select.Option>
              </Select>
            </Box>
            <Box>
              <Pagination
                size="small"
                current={page}
                total={total}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                pageSize={limit}
              />
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default TransactionDetailProcess;
