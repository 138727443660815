import { Box, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import { PRIMARY_COLOR } from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  BlueEditIcon,
  RedDeleteIcon,
  DeleteSuccess,
  UnSuccess,
} from "../../../../../components/icons";
import { Redirect, useLocation } from "react-router-dom";
import {
  deleteProductMapping,
  detailProductMapping,
} from "../../../../../services/main/productMappingReducer";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import BackToList from "../../../../../components/BackToList";
import moment from "moment/moment";
import { RenderStatus } from "../../../../../function/RenderStatus";
import ModalCustom from "../../../../../components/ModalCustom";
import ModalConfirm from "../../../../../components/ModalConfirm";
const DetailProductMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [detailProduct, setDetailProduct] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const payload = { updated_at: updatedAt };
  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmOpen(false);
  };

  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      productId: Number(location.state.id),
    };
    dispatch(deleteProductMapping(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete

  const isQtyDpd = () => {
    if (detailProduct?.additional_data?.qty_dpd === 0) {
      return "-";
    } else {
      return detailProduct?.additional_data?.qty_dpd;
    }
  };

  const isWarehouse = () => {
    if (detailProduct?.additional_data?.warehouse_code === "") {
      return "-";
    } else {
      return detailProduct?.additional_data?.warehouse_code;
    }
  };

  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };
  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailProductMapping(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(response.payload.response.updated_at);
        setDetailProduct(response.payload.response);
      }
    });
    setIsLoading(false);
  };
  useEffectOnce(() => {
    fetchData();
  });

  const BodyMapping = () => {
    return (
      <>
        {/* left side */}
        <Box padding={1} width="404px">
          {/* Principal Code */}
          <Box padding={1}>
            <Box>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.principal_code
                    ? detailProduct?.principal_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Customer Code */}
          <Box padding={1}>
            <Box>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.customer_code
                    ? detailProduct?.customer_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Principal Product Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_PRODUCT_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.principal_product_code
                    ? detailProduct?.principal_product_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Customer Product Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_customer_code
                    ? detailProduct?.product_customer_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Store Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_STORE_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.store_code ? detailProduct?.store_code : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Barcode*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_QTY_DPD")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{isQtyDpd()}</b>
              </Text>
            </Box>
          </Box>
          {/* Warehouse*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_WAREHOUSE_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{isWarehouse()}</b>
              </Text>
            </Box>
          </Box>
          {/* product status */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRODUCT_STATUS")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{RenderStatus(detailProduct?.status)}</b>
              </Text>
            </Box>
          </Box>
          {/* order unit */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_ORDER_UNIT")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.order_unit ? detailProduct?.order_unit : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* principal uom 1 */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_1")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{detailProduct?.uom_1 ? detailProduct?.uom_1 : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* principal uom 2 */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_2")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{detailProduct?.uom_2 ? detailProduct?.uom_2 : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* principal uom 3 */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_3")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{detailProduct?.uom_3 ? detailProduct?.uom_3 : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* principal uom 4 */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_4")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{detailProduct?.uom_4 ? detailProduct?.uom_4 : "-"}</b>
              </Text>
            </Box>
          </Box>
        </Box>
        {/* end of left side  */}

        {/* right side */}
        <Box padding={1} marginRight="7.5rem" width="404px">
          {/* principal name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {detailProduct?.principal_name
                    ? detailProduct?.principal_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* customer name  */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {detailProduct?.customer_name
                    ? detailProduct?.customer_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* principal product name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.principal_product_name
                    ? detailProduct?.principal_product_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* customer product name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_customer_name
                    ? detailProduct?.product_customer_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Store Name*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_STORE_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.store_name ? detailProduct?.store_name : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Distributor Code */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.distributor_code
                    ? detailProduct?.distributor_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Distributor Name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.distirbutor_name
                    ? detailProduct?.distirbutor_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* FIELD_CONVERSION_TO_SMALLEST*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_CONVERSION_1_TO_4")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.conv_1_to_4
                    ? detailProduct?.conv_1_to_4
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* FIELD_CONVERSION_TO_SMALLEST 2*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_CONVERSION_2_TO_4")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.conv_2_to_4
                    ? detailProduct?.conv_2_to_4
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* FIELD_CONVERSION_TO_SMALLEST 3*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_MAPPING:FIELD_CONVERSION_3_TO_4")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.conv_3_to_4
                    ? detailProduct?.conv_3_to_4
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  const [currentPage, setCurrentPage] = useState("Mapping");
  const changeCurrent = (current) => {
    setCurrentPage(current);
  };
  return isDeleted ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_MAPPING:DETAIL_PRODUCT_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_MAPPING:DETAIL_PRODUCT_MAPPING")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              // id="btn-UserSeller-ProductPrincipal-DetailProductPrincipal-Edit"
              link={{
                pathname: ROUTES_USER_SELLER.PRODUCT_MAPPING.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              // id="btn-UserSeller-ProductPrincipal-DetailProductPrincipal-Delete"
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST}
        name={detailProduct?.created_name}
        date={moment(detailProduct?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(detailProduct?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={detailProduct?.updated_name}
      />
      {/* white box  */}
      <Box backgroundColor="white" minHeight="300px" padding={4}>
        <Box>
          <Box
            justifyContent="space-between"
            display="flex"
            borderRadius="10px"
          >
            <BodyMapping />
          </Box>
        </Box>
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
      />
    </Box>
  );
};

export default DetailProductMapping;
