import axios from 'axios';
import { isAfter } from "date-fns";
import jwtDecode from 'jwt-decode';
import { authActions } from '../reducers/authReducer';
/**
 * @type {import('@reduxjs/toolkit').Store}
 */
let store = null;
export const injectStore = (_store) => {
    store = _store;
};

const checkIsTokenExpired = (token) => {
    if (!token) return true;
    const jwt = jwtDecode(token);
    const currentTime = new Date();
    const expiredTime = new Date(jwt.exp * 1000);
    return isAfter(currentTime, expiredTime);
}

const apiInstance = axios.create()

apiInstance.interceptors.request.use(config => {
    if (store === null || store === undefined) return config;
    const token = store.getState().auth.token ?? '';

    if (checkIsTokenExpired(token)) {
        store.dispatch(authActions.resetRedux())
    };

    return config;
})

export default apiInstance;