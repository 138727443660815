import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import CustomToggle from "../../../../components/customToggle/CustomToggle";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ID_CSM_MASTER } from "../../../../constant/idConstant";
import {
  GRAY_COLOR,
  PRIMARY_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import {
  selectPMHandling,
  selectStatus
} from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import { getListCustomer } from "../../../../services/main/customerReducer";
import { getListDistributor } from "../../../../services/main/distributorReducer";
import { getListMasterStore } from "../../../../services/main/masterStoreReducer";
import { getListNotification } from "../../../../services/main/notificationReducer";
import {
  detailStoreSupplier, updateStoreSupplier
} from "../../../../services/main/storeSupplierConnectionReducer";
import { getListWarehouse } from "../../../../services/main/warehouseReducer";
import StringUtil from "../../../../utils/stringUtil";
import { updateSchema } from "./StoreSuppConnSchema";

const EditStoreSuppConn = () => {
  const customer_store_mapping = useSelector(
    (data) =>
      data.storeSupplierConnection.detail_connection_store
        .customer_store_mapping
  );
  const data_management_history = useSelector(
    (data) =>
      data.storeSupplierConnection.detail_connection_store
        .data_management_history
  );

  // const customer_store_mapping = useSelector(
  //   (data) => data.storeSupplierConnection.detail_connection_store
  // );

  const [ischecked, setIschecked] = useState(false);
  const { t } = useTranslation();

  const location = useLocation();

  const history = useHistory();
  const { Option } = Select;
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [ischeckedPM, setIscheckedPM] = useState(false);
  const [ischeckedBKL, setIscheckedBKL] = useState(false);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);

  // store
  const [dataStore, setDataStore] = useState([]);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore] = useState(10);
  const [filterStoreName, setfilterStoreName] = useState("");
  const [idStore, setIdStore] = useState("");

  // notification
  const [dataNotification, setDataNotification] = useState([]);
  const [pageNotification, setPageNotification] = useState(1);
  const [limitNotification] = useState(10);
  const [filterNotificationName, setfilterNotificationName] = useState("");
  const [idNotification, setIdNotification] = useState("");

  // distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(null);

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  const [limitWarehouse] = useState(10);
  const [filterWarehouseName, setfilterWarehouseName] = useState("");
  const [idWarehouse, setIdWarehouse] = useState(null);

  // fungsi warehouse
  const handleScrollWarehouse = (event) => {
    setPageCustomer(pageWarehouse + 1);
  };

  const fetchWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_name: `like ${filterWarehouseName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const fetchWarehouseSearch = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_name: `like ${filterWarehouseName}`,
    });
    const params =
      filterWarehouseName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (response.payload.ok) {
        setPageWarehouse(1);
        setDataWarehouse(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchWarehouse();
  }, [pageWarehouse]);
  useEffect(() => {
    fetchWarehouseSearch();
  }, [filterWarehouseName]);

  const dataWarehouseName = useMemo(() => {
    // find customer code by selected customer id
    return dataWarehouse?.find((search) => search.id === idWarehouse);
  }, [idWarehouse]);
  // end fungsi warehouse
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const params = { page, limit };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = `distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };

  const dataDistributorCode = useMemo(() => {
    // find distributor code by selected distributor_id
    return dataDistributor?.find((search) => search.id === idDistributor);
  }, [idDistributor]);

  //   end parameter distributor

  // fungsi customer
  const handleScrollCustomer = (event) => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomer(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomerName}`;
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomer(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  const dataCustomerCode = useMemo(() => {
    // find customer code by selected customer id
    return dataCustomer?.find((search) => search.id === idCustomer);
  }, [idCustomer]);
  // end fungsi customer

  // fungsi store
  const fetchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = "customer_id eq " + idCustomer;
    const params =
      idCustomer === null ? { page, limit } : { page, limit, filter };
    if (idCustomer === null) {
      setDataStore("");
    } else {
      dispatch(getListMasterStore(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStore([...dataStore, ...resp]);
      });
    }
  };
  const fetchStoreSearch = () => {
    const page = pageStore;
    const limit = limitStore;
    const filterCustomerId = "customer_id eq " + idCustomer;
    const filter = `${filterCustomerId} , store_name like ${filterStoreName}`;
    const params =
      filterStoreName === "" ? { page, limit } : { page, limit, filter };
    if (idCustomer === null) {
      setDataStore("");
    } else {
      dispatch(getListMasterStore(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };

  useEffect(() => {
    fetchStore();
  }, [pageStore, idCustomer]);
  useEffect(() => {
    fetchStoreSearch();
  }, [filterStoreName]);

  const handleScrollStore = (event) => {
    setPageStore(pageStore + 1);
  };

  // end fungsi store

  // start fungsi notification
  const fetchNotification = () => {
    const page = pageNotification;
    const limit = limitNotification;
    const params = { page, limit };
    dispatch(getListNotification(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataNotification([...dataNotification, ...resp]);
    });
  };
  const fetchNotificationSearch = () => {
    const page = pageNotification;
    const limit = limitNotification;
    const filter = `name like ${filterNotificationName}`;
    const params =
      filterNotificationName === "" ? { page, limit } : { page, limit, filter };

    dispatch(getListNotification(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageStore(1);
      setDataNotification(response.payload.response);
    });
  };
  useEffect(() => {
    fetchNotification();
  }, [pageNotification]);
  useEffect(() => {
    fetchNotificationSearch();
  }, [filterNotificationName]);

  const handleScrollNotification = (event) => {
    setPageNotification(pageNotification + 1);
  };
  // end fungsi notification

  // submit data
  const onSubmit = (values, action) => {
    if (typeof values.distributor_id === "object") {
      values.distributor_id = values.distributor_id.id;
    }
    if (typeof values.warehouse_id === "object") {
      values.warehouse_id = values.warehouse_id.id;
    }
    delete values.salesman_code;
    delete values.salesman_name;
    const id = location.state;
    const principal_id = customer_store_mapping?.principal_id;
    const object = { id, values, principal_id };

    dispatch(updateStoreSupplier(object)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };

  const handleChangeSelect = (name, value, option) => {
    if (name === "distributor_id") {
      setFieldValue("distributor_id", value);
      setidDistributor(value);
      setPrincipalID();
    } else if (name === "customer_id") {
      setFieldValue("customer_id", value);
      setIdCustomer(value);
    } else if (name === "store_id") {
      setFieldValue("store_id", value);
      // setIdStore(value);
    } else if (name === "warehouse_id") {
      setFieldValue("warehouse_id", value);
      setIdWarehouse(value);
    }
    setFieldValue(name, value);
  };
  // fungsi modal
  const modalSuccess = () => {
    // dispatch(storeConnectionSupplierAction.resetDetailStoreConnection());
    history.push(ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    dispatch(detailStoreSupplier(location.state));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSwitchChange = (event) => {
    const valuePM = !ischeckedPM;
    setFieldValue("is_product_mapping", valuePM === true ? "Y" : "N");
    setIscheckedPM(!ischeckedPM);
  };
  const handleSwitchBKLChange = (event) => {
    const valueBKL = !ischeckedBKL;
    setFieldValue("is_bkl", valueBKL === true ? "Y" : "N");
    setIscheckedBKL(!ischeckedBKL);
  };
  const principal_id = customer_store_mapping?.principal_id;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      supplier_code: customer_store_mapping?.supplier_code ?? "",
      distributor_id: {
        id: customer_store_mapping?.distributor_id,
        value: customer_store_mapping?.distributor_name,
      },
      distributor_code: customer_store_mapping?.distributor_code ?? "",
      distributor_name: customer_store_mapping?.name ?? "",
      customer_id: customer_store_mapping?.customer_id ?? "",
      customer_code: customer_store_mapping?.customer_code ?? "",
      customer_name: customer_store_mapping?.customer_name ?? "",
      store_id: customer_store_mapping?.store_id ?? "",
      farmindo_store: customer_store_mapping?.store_alias ?? "",
      store_name: customer_store_mapping?.store_name ?? "",
      principal_connection_store_id: customer_store_mapping?.alias ?? "",
      pm_handling: customer_store_mapping?.pm_handling ?? "",
      notification_id: customer_store_mapping?.notification_id ?? "",
      notification_name: customer_store_mapping?.notification_name ?? "",
      notification_alert_id:
        customer_store_mapping?.notification_alert_id ?? "",
      notification_alert_name:
        customer_store_mapping?.notification_alert_name ?? "",
      status: customer_store_mapping?.status ?? "",
      additional_data: customer_store_mapping?.additional_data ?? [],
      warehouse_id: {
        id: customer_store_mapping?.warehouse_id,
        value: customer_store_mapping?.warehouse_name,
        code: customer_store_mapping?.warehouse_code,
      },
      warehouse_name: customer_store_mapping?.warehouse_name ?? "",
      is_bkl: customer_store_mapping?.is_bkl,
      is_product_mapping: customer_store_mapping?.is_product_mapping,
      updated_at: data_management_history?.modified_on,
      additional_data: {
        salesman_code: customer_store_mapping?.additional_data.salesman_code,
        salesman_name: customer_store_mapping?.additional_data.salesman_name,
      },
    },
    validationSchema: updateSchema,
    onSubmit,
  });
  useEffect(() => {
    values.is_bkl === "Y" ? setIscheckedBKL(true) : setIscheckedBKL(false);
    values.is_product_mapping === "Y"
      ? setIscheckedPM(true)
      : setIscheckedPM(false);
  }, [customer_store_mapping, values.is_bkl, values.is_product_mapping]);

  const setPrincipalID = useMemo(() => {
    setFieldValue("principal_id", dataDistributorCode?.principal_id);
  }, [dataDistributorCode]);

  const renderCustomForm = () => {
    // kao = 2 , nestle = 8 kid= 19
    if (principal_id === 2 || principal_id === 8 || principal_id === 19) {
      return (
        <>
          {/* salesman code */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_SALESMAN_CODE")}{" "}
                {t("STORE_SUPPLIER_CONNECTION:OPTIONAL")}
              </Text>
            </Box>
            <Box className="form-input-padding-bottom">
              <Input
                value={values.additional_data.salesman_code}
                name="salesman_code"
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "additional_data.salesman_code",
                    e.currentTarget.value
                  );
                }}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_CSM_MASTER.EDIT.SALESMAN_CODE}
              />
              {errors.salesman_code && touched.salesman_code && (
                <ValidationError text={errors.salesman_code} />
              )}
            </Box>
          </Box>

          {/* salesman name */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_SALESMAN_NAME")}{" "}
                {t("STORE_SUPPLIER_CONNECTION:OPTIONAL")}
              </Text>
            </Box>
            <Box className="my-select-container" pb={1}>
              <Input
                name="salesman_name"
                value={values.additional_data.salesman_name}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "additional_data.salesman_name",
                    e.currentTarget.value
                  );
                }}
                style={inputTheme}
                type="text"
                width="12rem"
                onBlur={handleBlur}
                id={ID_CSM_MASTER.EDIT.SALESMAN_NAME}
              />
              {errors.salesman_name && (
                <ValidationError text={errors.salesman_name} />
              )}
            </Box>
          </Box>
        </>
      );
    }
  };
  const renderCustomNestle = () => {
    if (principal_id === 8) {
      return (
        <>
          {/* warehouse id */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_WAREHOUSE_NAME")}{" "}
                {t("STORE_SUPPLIER_CONNECTION:OPTIONAL")}
              </Text>
            </Box>
            <Box className="my-select-container" pb={1}>
              <Select
                id={ID_CSM_MASTER.EDIT.WAREHOUSE_NAME}
                value={values.warehouse_id}
                style={inputTheme}
                size="large"
                name="warehouse_id"
                onPopupScroll={handleScrollWarehouse}
                onChange={(value, opt) =>
                  handleChangeSelect("warehouse_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(e) => setfilterWarehouseName(e.target.value)}
                  />
                )}
                showSearch={false}
                allowClear="true"
              >
                {dataWarehouse &&
                  dataWarehouse.map((data, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={data.id}
                        title={data.warehouse_name}
                      >
                        {data.warehouse_name}
                      </Select.Option>
                    );
                  })}
              </Select>
              {errors.warehouse_id && touched.warehouse_id && (
                <ValidationError text={errors.warehouse_id} />
              )}
            </Box>
          </Box>
          {/* warehouse name */}
          <Box padding={1} display="flex">
            <Box width="10rem" pl={2}>
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_WAREHOUSE_CODE")}{" "}
                {t("STORE_SUPPLIER_CONNECTION:OPTIONAL")}
              </Text>
            </Box>
            <Box pb={1}>
              <Input
                disabled
                name="barcode"
                value={
                  dataWarehouseName
                    ? dataWarehouseName.warehouse_code
                    : values.warehouse_id?.code
                }
                style={inputTheme}
                type="text"
                id={ID_CSM_MASTER.EDIT.WAREHOUSE_CODE}
              />
            </Box>
          </Box>
        </>
      );
    }
  };
  return (
    <Box p={4}>
      {/* Head Title session */}
      <HeadTitle
        title={t("STORE_SUPPLIER_CONNECTION:EDIT_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_3")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:TITLE_LIST")}
            </span>{" "}
            - {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_5")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          minHeight="300px"
          padding={4}
          // justifyContent="space-between"
          display="flex"
          flex-direction="column"
          align-items="flex-end"
          gap="20px"
          marginTop={4}
          borderRadius={10}
        >
          {/* left side */}
          <Box
            padding={1}
            width="402px"
            flexDirection="column"
            alignItems="flex-start"
          >
            {/* supplier code */}
            <Box
              padding={1}
              width="402px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="28px"
            >
              <Box className="form-title">
                <Text width="92px">
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_SUPPLIER_CODE")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  name="supplier_code"
                  value={values.supplier_code}
                  style={inputTheme}
                  onChange={handleChange}
                  type="text"
                  width="12rem"
                  backgroundColor={GRAY_COLOR}
                  id={ID_CSM_MASTER.EDIT.SUPPLIER_CODE}
                />
                {errors.supplier_code && touched.supplier_code && (
                  <ValidationError text={errors.supplier_code} />
                )}
              </Box>
            </Box>
            {/* distributor Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_CODE")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  name="distributor_code"
                  value={
                    dataDistributorCode
                      ? dataDistributorCode.distributor_code
                      : values.distributor_code
                  }
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  id={ID_CSM_MASTER.EDIT.DISTRIBUTOR_CODE}
                />
              </Box>
            </Box>
            {/* customer code*/}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_CUSTOMER_CODE")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  name="barcode"
                  value={
                    dataCustomerCode
                      ? dataCustomerCode.customer_code
                      : values.customer_code
                  }
                  style={inputTheme}
                  type="text"
                  id={ID_CSM_MASTER.EDIT.CUSTOMER_CODE}
                />
              </Box>
            </Box>

            {/* store id */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  disabled
                  value={customer_store_mapping?.store_code}
                  name="farmindo_store"
                  style={inputTheme}
                  onBlur={handleBlur}
                  id={ID_CSM_MASTER.EDIT.STORE_CODE}
                />
                {errors.farmindo_store && touched.farmindo_store && (
                  <ValidationError text={errors.farmindo_store} />
                )}
              </Box>
            </Box>

            {/* alias */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_ALIAS")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <Input
                  value={values.principal_connection_store_id}
                  name="principal_connection_store_id"
                  onChange={handleChange}
                  style={inputTheme}
                  type="text"
                  width="12rem"
                  onBlur={handleBlur}
                  id={ID_CSM_MASTER.EDIT.STORE_ID}
                />
                {errors.principal_connection_store_id &&
                  touched.principal_connection_store_id && (
                    <ValidationError
                      text={errors.principal_connection_store_id}
                    />
                  )}
              </Box>
            </Box>
            {/* pm handling */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_PM_HANDLING")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={inputTheme}
                  size="large"
                  value={values.pm_handling}
                  onChange={(value, opt) =>
                    handleChangeSelect("pm_handling", value, opt)
                  }
                  showSearch={false}
                  allowClear="true"
                  id={ID_CSM_MASTER.EDIT.PM_HANDLING}
                >
                  {selectPMHandling?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.pm_handling && (
                  <ValidationError text={errors.pm_handling} />
                )}
              </Box>
            </Box>
            {/* notification name */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2}>
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_NOTIFICATION_NAME")}{" "}
                  {t("STORE_SUPPLIER_CONNECTION:OPTIONAL")}
                </Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={inputTheme}
                  size="large"
                  value={values.notification_id}
                  onPopupScroll={handleScrollNotification}
                  onChange={(value, opt) =>
                    handleChangeSelect("notification_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterNotificationName(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  id={ID_CSM_MASTER.EDIT.NOTIFICATION_NAME}
                  allowClear="true"
                >
                  {dataNotification &&
                    dataNotification
                      .filter(
                        (dataNotification) =>
                          dataNotification.category === "SUCCESS"
                      )
                      .map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.id}
                            title={data.name}
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Box>
            </Box>
            {/* alert */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_ALERT")}{" "}
                  {t("STORE_SUPPLIER_CONNECTION:OPTIONAL")}
                </Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  style={inputTheme}
                  size="large"
                  value={values.notification_alert_id}
                  onPopupScroll={handleScrollNotification}
                  onChange={(value, opt) =>
                    handleChangeSelect("notification_alert_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterNotificationName(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  id={ID_CSM_MASTER.EDIT.NOTIFICATION_NAME}
                  allowClear="true"
                >
                  {dataNotification &&
                    dataNotification
                      .filter(
                        (dataNotification) =>
                          dataNotification.category === "FAILED"
                      )
                      .map((data, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={data.id}
                            title={data.name}
                          >
                            {data.name}
                          </Select.Option>
                        );
                      })}
                </Select>
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  style={inputTheme}
                  size="large"
                  value={values.status}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  showSearch={false}
                  id={ID_CSM_MASTER.EDIT.STATUS}
                  allowClear="true"
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && <ValidationError text={errors.status} />}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* customer name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_CUSTOMER_NAME")}
                </Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  disabled
                  style={inputTheme}
                  size="large"
                  value={customer_store_mapping?.customer_name}
                  onPopupScroll={handleScrollCustomer}
                  placeholder="Select Customer"
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomerName(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  id={ID_CSM_MASTER.EDIT.CUSTOMER_NAME}
                  allowClear="true"
                >
                  {dataCustomer &&
                    dataCustomer.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Box>
            </Box>
            {/* distributor name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  disabled
                  style={inputTheme}
                  value={customer_store_mapping?.principal_name}
                  onPopupScroll={handleScrollDistributor}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setFilterDistributor(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear="true"
                  size="large"
                ></Select>
              </Box>
            </Box>
            {/* distributor name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_NAME")}
                </Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  disabled
                  style={inputTheme}
                  value={customer_store_mapping?.distributor_name}
                  onPopupScroll={handleScrollDistributor}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setFilterDistributor(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear="true"
                  placeholder="Select Distributor"
                  id={ID_CSM_MASTER.EDIT.DISTRIBUTOR_NAME}
                  size="large"
                >
                  {dataDistributor &&
                    dataDistributor.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Box>
            </Box>

            {renderCustomForm()}
            {renderCustomNestle()}
            {/* store name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_NAME")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  disabled
                  style={inputTheme}
                  size="large"
                  value={customer_store_mapping?.store_name}
                  onPopupScroll={handleScrollStore}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterStoreName(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  id={ID_CSM_MASTER.EDIT.STORE_NAME}
                  allowClear="true"
                >
                  {dataStore &&
                    dataStore.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.store_name}
                        >
                          {data.store_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>

            {/* BKL */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>
                  {t("STORE_SUPPLIER_CONNECTION:FIELD_PRODUCT_MAPPING")}
                </Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <CustomToggle
                  onChange={handleSwitchChange}
                  textChecked={t("COMMON:YES")}
                  textUnChecked={t("COMMON:NO")}
                  isChecked={ischeckedPM}
                  id={ID_CSM_MASTER.EDIT.PRODUCT_MAPPING}
                />
              </Box>
            </Box>

            {/* BKL */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_BKL")}</Text>
              </Box>
              <Box className="form-input-padding-bottom">
                <CustomToggle
                  onChange={handleSwitchBKLChange}
                  textChecked={t("COMMON:YES")}
                  textUnChecked={t("COMMON:NO")}
                  isChecked={ischeckedBKL}
                  id={ID_CSM_MASTER.EDIT.BKL}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_CSM_MASTER.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_CSM_MASTER.EDIT.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default EditStoreSuppConn;
