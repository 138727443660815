import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";
import { getInitiateListProductMappingHo } from "./productMappingHoReducer";
import { arrayOrArrOfObj } from "../../utils/arrayOrArrOfObjUtil";

const INITIAL_STATE = {
  list: [],
  list_principal: [],
  list_distributor: [],
  edit_product_category: {},
  table_loading: false,
  status: "",
};

export const getInitiateListProductCategory = createAsyncThunk(
  "initiateListProductCategory",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCategory = createAsyncThunk(
  "getListProductCategory",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailProductCategory = createAsyncThunk(
  "detailProductCategory",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailProductCategoryForEdit = createAsyncThunk(
  "detailProductCategoryForEdit",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addProductCategory = createAsyncThunk(
  "addProductCategory",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editProductCategory = createAsyncThunk(
  "editProductCategory",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const isArrayOfObj = arrayOrArrOfObj(object?.distributor_id);
    const body = {
      principal_id: object.principal_id,
      product_category_name: object.product_category_name,
      product_category_code: object.product_category_code,
      distributor_id: isArrayOfObj
        ? object.distributor_id.map((val) => {
            return val.value;
          })
        : object.distributor_id.map((val) => {
            return val;
          }),
      status: object.status,
      updated_at: object.updatedAt,
      productId: object.product_id,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response;
        result.message = response.nexsoft;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const deleteProductCategory = createAsyncThunk(
  "deleteProductCategory",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      productId: object.productId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListPrincipalFilter = createAsyncThunk(
  "getListPrincipalFilter",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const productCategory = createSlice({
  name: "productCategory",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxProductCategory() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // product category
    [getInitiateListProductCategory.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProductCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListProductCategory.pending]: (state, action) => {
      state.table_loading = true;
      state.status = "loading";
    },
    [getListProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list = action.payload.response;
    },
    [getListProductCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [detailProductCategory.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailProductCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [detailProductCategoryForEdit.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductCategoryForEdit.fulfilled]: (state, action) => {
      state.status = "success";
      state.edit_product_category = action.payload.response;
    },
    [detailProductCategoryForEdit.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [addProductCategory.pending]: (state, action) => {
      state.status = "loading";
    },
    [addProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addProductCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editProductCategory.pending]: (state, action) => {
      state.status = "loading";
    },
    [editProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editProductCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [deleteProductCategory.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductCategory.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    //get List Principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    //get List Principal for filter
    [getListPrincipalFilter.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipalFilter.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipalFilter.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    //get List Distributor
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_distributor = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
  },
});

export const productCategoryAction = productCategory.actions;

export default productCategory.reducer;
