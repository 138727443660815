export const CheckStatus = {
  CHECKED: "checked",
  INTERMEDIATE: "intermediate",
  UNCHECKED: "unchecked",
};

export const defaultPermissionSysAdmin = [
  {
    key: "account",
    label: "account",
    isExpanded: false,
    permission: [
      {
        key: "account:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "account:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "account:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "account:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "master",
    label: "master",
    isExpanded: false,
    permission: [
      {
        key: "master:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "master.customer",
        label: "customer",
        permission: [
          {
            key: "master.customer:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.customer:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.customer:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.customer:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
      {
        key: "master.distributor",
        label: "distributor",
        permission: [
          {
            key: "master.distributor:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
    ],
  },
  {
    key: "user",
    label: "user",
    isExpanded: false,
    permission: [
      {
        key: "user:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "data_group",
    label: "data_group",
    isExpanded: false,
    permission: [
      {
        key: "data_group:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "role",
    label: "role",
    isExpanded: false,
    permission: [
      {
        key: "role:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "role.sysadmin",
        label: "sysadmin",
        permission: [
          {
            key: "role.sysadmin:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "role.sysadmin:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "role.sysadmin:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "role.sysadmin:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
      {
        key: "role.sysuser",
        label: "sysuser",
        permission: [
          {
            key: "role.sysuser:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "role.sysuser:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "role.sysuser:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "role.sysuser:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
    ],
  },
  {
    key: "setup",
    label: "setup",
    isExpanded: false,
    permission: [
      {
        key: "setup:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "setup:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "setup:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "setup:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "setup.parameter_management",
        label: "parameter_management",
        isExpanded: false,
        permission: [
          {
            key: "setup.parameter_management:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "setup.parameter_management:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "setup.parameter_management:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "setup.parameter_management:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "setup.parameter_management.parameter_group",
            label: "parameter_group",
            isExpanded: false,
            permission: [
              {
                key: "setup.parameter_management.parameter_group:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "setup.parameter_management.parameter_group:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "setup.parameter_management.parameter_group:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "setup.parameter_management.parameter_group:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "setup.parameter_management.parameter",
            label: "parameter",
            isExpanded: false,
            permission: [
              {
                key: "setup.parameter_management.parameter:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "setup.parameter_management.parameter:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "setup.parameter_management.parameter:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "setup.parameter_management.parameter:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "report",
    label: "report",
    isExpanded: false,
    permission: [
      {
        key: "report:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "report.log",
        label: "log",
        isExpanded: false,
        permission: [
          {
            key: "report.log:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.log.user_activity",
            label: "user_activity",
            isExpanded: false,
            permission: [
              {
                key: "report.log.user_activity:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.log.audit_trail",
            label: "audit_trail",
            permission: [
              {
                key: "report.log.audit_trail:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "report.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "report.monitoring:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.monitoring.as2_log",
            label: "as2_log",
            permission: [
              {
                key: "report.monitoring.as2_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.monitoring.sftp_log",
            label: "sftp_log",
            permission: [
              {
                key: "report.monitoring.sftp_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "server_configuration",
    label: "server_configuration",
    isExpanded: false,
    permission: [
      {
        key: "server_configuration:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "server_configuration:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "server_configuration.server_configuration",
        label: "server_configuration",
        isExpanded: false,
        permission: [
          {
            key: "server_configuration.server_configuration:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "server_configuration.server_configuration:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "server_configuration.server_configuration.scheduler",
            label: "scheduler",
            permission: [
              {
                key: "server_configuration.server_configuration.scheduler:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "server_configuration.server_configuration.scheduler:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "server_configuration.server_configuration.host_server",
            label: "host_server",
            permission: [
              {
                key: "server_configuration.server_configuration.host_server:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "server_configuration.server_configuration.host_server:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "change_profile",
    label: "change_profile",
    isExpanded: false,
    permission: [
      {
        key: "change_profile:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "change_profile:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "change_password",
    label: "change_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "change_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "forgot_password",
    label: "forgot_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "forgot_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
];

export const defaultPermissionSysUserAllPermission = [
  {
    key: "master",
    label: "master",
    isExpanded: false,
    permission: [
      {
        key: "master:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "master.product",
        label: "product",
        isExpanded: false,
        permission: [
          {
            key: "master.product:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.product.product_principal",
            label: "principal",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_principal:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.product.product_customer",
            label: "customer",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_customer:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.product.product_category",
            label: "category",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_category:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.mapping",
        label: "mapping",
        isExpanded: false,
        permission: [
          {
            key: "master.mapping:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.mapping.product_mapping_ho",
            label: "product_mapping_ho",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_mapping_ho:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_mapping",
            label: "product_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.bkl_mapping",
            label: "bkl_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.bkl_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_bkl_mapping",
            label: "product_bkl_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_bkl_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_category_mapping",
            label: "product_category_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_category_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_substitute",
            label: "product_substitute",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_substitute:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.distributor",
        label: "distributor",
        isExpanded: false,
        permission: [
          {
            key: "master.distributor:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
      {
        key: "master.warehouse",
        label: "warehouse",
        isExpanded: false,
        permission: [
          {
            key: "master.warehouse:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
      {
        key: "master.store",
        label: "store",
        isExpanded: false,
        permission: [
          {
            key: "master.store:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.store.store",
            label: "store",
            isExpanded: false,
            permission: [
              {
                key: "master.store.store:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.connection",
        label: "connection",
        isExpanded: false,
        permission: [
          {
            key: "master.connection:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.connection:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.connection:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.connection:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.connection.store_supplier_connection",
            label: "store_supplier_connection",
            isExpanded: false,
            permission: [
              {
                key: "master.connection.store_supplier_connection:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.connection.store_supplier_connection:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.connection.store_supplier_connection:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.connection.store_supplier_connection:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.notification",
        label: "notification",
        isExpanded: false,
        permission: [
          {
            key: "master.notification:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.notification:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.notification:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.notification:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.notification.notification",
            label: "notification",
            isExpanded: false,
            permission: [
              {
                key: "master.notification.notification:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.notification.notification:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.notification.notification:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.notification.notification:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "user",
    label: "user",
    isExpanded: false,
    permission: [
      {
        key: "user:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "data_group",
    label: "data_group",
    isExpanded: false,
    permission: [
      {
        key: "data_group:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "role",
    label: "role",
    isExpanded: false,
    permission: [
      {
        key: "role:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "report",
    label: "report",
    isExpanded: false,
    permission: [
      {
        key: "report:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "report.log",
        label: "log",
        isExpanded: false,
        permission: [
          {
            key: "report.log:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "report.log:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.log.user_activity",
            label: "user_activity",
            isExpanded: false,
            permission: [
              {
                key: "report.log.user_activity:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.log.audit_trail",
            label: "audit_trail",
            isExpanded: false,
            permission: [
              {
                key: "report.log.audit_trail:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "report.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "report.monitoring:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "report.monitoring:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.monitoring.upload_log",
            label: "upload_log",
            isExpanded: false,
            permission: [
              {
                key: "report.monitoring.upload_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.monitoring.as2_log",
            label: "as2_log",
            isExpanded: false,
            permission: [
              {
                key: "report.monitoring.as2_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.monitoring.sftp_log",
            label: "sftp_log",
            isExpanded: false,
            permission: [
              {
                key: "report.monitoring.sftp_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "report.report",
        label: "report",
        isExpanded: false,
        permission: [
          {
            key: "report.report:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "report.report:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.report.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.report.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.report.po_vs_so",
            label: "po_vs_so",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po_vs_so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.report.po_vs_so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.report.po_detail",
            label: "po_detail",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po_detail:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.report.po_detail:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "report.dashboard",
        label: "dashboard",
        isExpanded: false,
        permission: [
          {
            key: "report.dashboard:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "report.dashboard:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.dashboard.summary",
            label: "summary",
            isExpanded: false,
            permission: [
              {
                key: "report.dashboard.summary:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.dashboard.summary:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "transaction",
    label: "transaction",
    isExpanded: false,
    permission: [
      {
        key: "transaction:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "transaction.so",
        label: "so",
        isExpanded: false,
        permission: [
          {
            key: "transaction.so:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "transaction.so.import_so",
            label: "import_so",
            isExpanded: false,
            permission: [
              {
                key: "transaction.so.import_so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "transaction.so.process_approve_po",
            label: "process_approve_po",
            isExpanded: false,
            permission: [
              {
                key: "transaction.so.process_approve_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.process_approve_po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.process_approve_po:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.process_approve_po:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "transaction.so.bkl_summary",
            label: "bkl_summary",
            isExpanded: false,
            permission: [
              {
                key: "transaction.so.bkl_summary:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.bkl_summary:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "transaction.po",
        label: "po",
        isExpanded: false,
        permission: [
          {
            key: "transaction.po:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
    ],
  },
  {
    key: "process",
    label: "process",
    isExpanded: false,
    permission: [
      {
        key: "process:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "process:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "process:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "process:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "process.process_po",
        label: "process_po",
        isExpanded: false,
        permission: [
          {
            key: "process.process_po:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "process.process_po:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "process.process_po:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "process.process_po:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "process.process_po.processed_po",
            label: "processed_po",
            isExpanded: false,
            permission: [
              {
                key: "process.process_po.processed_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "process.process_po.processed_po:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "process.process_po_reprocess_po",
            label: "reprocess_po",
            isExpanded: false,
            permission: [
              {
                key: "process.process_po_reprocess_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "process.process_po_reprocess_po:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "monitoring",
    label: "monitoring",
    isExpanded: false,
    permission: [
      {
        key: "monitoring:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "monitoring.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "monitoring.monitoring:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "monitoring.monitoring.list_process_po",
            label: "list_process_po",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.list_process_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.list_process_po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.so",
            label: "so",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.connection",
            label: "connection",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.connection:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_product",
            label: "unmapped_product",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_product:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_store",
            label: "unmapped_store",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_store:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.upload_log",
            label: "upload_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.upload_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.as2_log",
            label: "as2_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.as2_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.sftp_log",
            label: "sftp_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.sftp_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "change_profile",
    label: "change_profile",
    isExpanded: false,
    permission: [
      {
        key: "change_profile:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "change_profile:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "change_password",
    label: "change_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "change_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "forgot_password",
    label: "forgot_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "forgot_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
];

export const defaultPermissionSysUserSeller = [
  {
    key: "master",
    label: "master",
    isExpanded: false,
    permission: [
      {
        key: "master:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:delete_own",
        label: "delete_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "master.product",
        label: "product",
        isExpanded: false,
        permission: [
          {
            key: "master.product:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.product.product_principal",
            label: "principal",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_principal:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.product.product_customer",
            label: "customer",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_customer:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.product.product_category",
            label: "category",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_category:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_category:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.mapping",
        label: "mapping",
        isExpanded: false,
        permission: [
          {
            key: "master.mapping:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.mapping:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.mapping.product_mapping_ho",
            label: "product_mapping_ho",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_mapping_ho:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping_ho:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_mapping",
            label: "product_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.bkl_mapping",
            label: "bkl_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.bkl_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.bkl_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_bkl_mapping",
            label: "product_bkl_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_bkl_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_bkl_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_category_mapping",
            label: "product_category_mapping",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_category_mapping:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_category_mapping:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.mapping.product_substitute",
            label: "product_substitute",
            isExpanded: false,
            permission: [
              {
                key: "master.mapping.product_substitute:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.mapping.product_substitute:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.distributor",
        label: "distributor",
        isExpanded: false,
        permission: [
          {
            key: "master.distributor:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.distributor:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
      {
        key: "master.warehouse",
        label: "warehouse",
        isExpanded: false,
        permission: [
          {
            key: "master.warehouse:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.warehouse:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
      },
    ],
  },
  {
    key: "report",
    label: "report",
    isExpanded: false,
    permission: [
      {
        key: "report:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "report:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "report.report",
        label: "report",
        isExpanded: false,
        permission: [
          {
            key: "report.report:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "report.report:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.report.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.report.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.report.po_vs_so",
            label: "po_vs_so",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po_vs_so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.report.po_vs_so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.report.po_detail",
            label: "po_detail",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po_detail:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.report.po_detail:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "report.dashboard",
        label: "dashboard",
        isExpanded: false,
        permission: [
          {
            key: "report.dashboard:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "report.dashboard:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.dashboard.summary",
            label: "summary",
            isExpanded: false,
            permission: [
              {
                key: "report.dashboard.summary:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "report.dashboard.summary:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "transaction",
    label: "transaction",
    isExpanded: false,
    permission: [
      {
        key: "transaction:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "transaction:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "transaction.so",
        label: "so",
        isExpanded: false,
        permission: [
          {
            key: "transaction.so:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.so:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "transaction.so.import_so",
            label: "import_so",
            isExpanded: false,
            permission: [
              {
                key: "transaction.so.import_so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.import_so:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "transaction.po",
        label: "po",
        isExpanded: false,
        permission: [
          {
            key: "transaction.po:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "transaction.po:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "transaction.po.process_approve_po",
            label: "process_approve_po",
            isExpanded: false,
            permission: [
              {
                key: "transaction.po.process_approve_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.po.process_approve_po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.po.process_approve_po:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.po.process_approve_po:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "transaction.po.bkl_summary",
            label: "bkl_summary",
            isExpanded: false,
            permission: [
              {
                key: "transaction.po.bkl_summary:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.po.bkl_summary:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "transaction.so.bkl_summary:insert",
                label: "insert",
                status: CheckStatus.CHECKED,
                ignored: true
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "monitoring",
    label: "monitoring",
    isExpanded: false,
    permission: [
      {
        key: "monitoring:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "monitoring:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "monitoring.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "monitoring.monitoring:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "monitoring.monitoring:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "monitoring.monitoring.list_process_po",
            label: "list_process_po",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.list_process_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.list_process_po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.so",
            label: "so",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.connection",
            label: "connection",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.connection:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_product",
            label: "unmapped_product",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_product:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_store",
            label: "unmapped_store",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_store:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.upload_log",
            label: "upload_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.upload_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.as2_sftp_log",
            label: "as2_sftp_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.as2_sftp_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "change_profile",
    label: "change_profile",
    isExpanded: false,
    permission: [
      {
        key: "change_profile:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "change_password",
    label: "change_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "change_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "forgot_password",
    label: "forgot_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "forgot_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
];

export const defaultPermissionSysUserAdminSeller = [
  {
    key: "user",
    label: "user",
    isExpanded: false,
    permission: [
      {
        key: "user:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:insert_own",
        label: "insert_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "user:delete_own",
        label: "delete_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "data_group",
    label: "data_group",
    isExpanded: false,
    permission: [
      {
        key: "data_group:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:insert_own",
        label: "insert_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "data_group:delete_own",
        label: "delete_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "role",
    label: "role",
    isExpanded: false,
    permission: [
      {
        key: "role:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:insert_own",
        label: "insert_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "role:delete_own",
        label: "delete_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "report",
    label: "report",
    isExpanded: false,
    permission: [
      {
        key: "report:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "report.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "report.monitoring:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.monitoring.as2_sftp_log",
            label: "as2_sftp_log",
            permission: [
              {
                key: "report.monitoring.as2_sftp_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "change_profile",
    label: "change_profile",
    isExpanded: false,
    permission: [
      {
        key: "change_profile:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "change_password",
    label: "change_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "change_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "forgot_password",
    label: "forgot_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "forgot_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
];

export const defaultPermissionSysUserBuyer = [
  {
    key: "master",
    label: "master",
    isExpanded: false,
    permission: [
      {
        key: "master:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:delete_own",
        label: "delete_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "master.product",
        label: "product",
        isExpanded: false,
        permission: [
          {
            key: "master.product:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.product.product_customer",
            label: "product_customer",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_customer:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.store",
        label: "store",
        isExpanded: false,
        permission: [
          {
            key: "master.store:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:update_own",
            label: "update_own",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:delete_own",
            label: "delete_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.store.store",
            label: "store",
            isExpanded: false,
            permission: [
              {
                key: "master.store.store:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:update_own",
                label: "update_own",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:delete_own",
                label: "delete_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "report",
    label: "report",
    isExpanded: false,
    permission: [
      {
        key: "report:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "report.report",
        label: "report",
        isExpanded: false,
        permission: [
          {
            key: "report.report:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.report.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.report.po_detail",
            label: "po_detail",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po_detail:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "monitoring",
    label: "monitoring",
    isExpanded: false,
    permission: [
      {
        key: "monitoring:view_own",
        label: "view_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "monitoring.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "monitoring.monitoring:view_own",
            label: "view_own",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "monitoring.monitoring.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_product",
            label: "unmapped_product",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_product:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_store",
            label: "unmapped_store",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_store:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "change_profile",
    label: "change_profile",
    isExpanded: false,
    permission: [
      {
        key: "change_profile:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "change_profile:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "change_password",
    label: "change_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "change_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "forgot_password",
    label: "forgot_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "forgot_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
];

export const defaultPermissionSysUserSupport = [
  {
    key: "master",
    label: "master",
    isExpanded: false,
    permission: [
      {
        key: "master:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "master:delete",
        label: "delete",
        status: CheckStatus.UNCHECKEDx,
      },
    ],
    children: [
      {
        key: "master.product",
        label: "product",
        isExpanded: false,
        permission: [
          {
            key: "master.product:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.product:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.product.product_principal",
            label: "product_principal",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_principal:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_principal:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "master.product.product_customer",
            label: "product_customer",
            isExpanded: false,
            permission: [
              {
                key: "master.product.product_customer:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.product.product_customer:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.store",
        label: "store",
        isExpanded: false,
        permission: [
          {
            key: "master.store:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.store:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.store.store",
            label: "store",
            isExpanded: false,
            permission: [
              {
                key: "master.store.store:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.store.store:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.connection",
        label: "connection",
        isExpanded: false,
        permission: [
          {
            key: "master.connection:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.connection:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.connection:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.connection:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.conection.store_supplier_connection",
            label: "store_supplier_connection",
            isExpanded: false,
            permission: [
              {
                key: "master.connection.store_supplier_connection:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.connection.store_supplier_connection:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.connection.store_supplier_connection:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.connection.store_supplier_connection:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "master.notification",
        label: "notification",
        isExpanded: false,
        permission: [
          {
            key: "master.notification:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.notification:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.notification:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "master.notification:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "master.notification.notification",
            label: "notification",
            isExpanded: false,
            permission: [
              {
                key: "master.notification.notification:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.notification.notification:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.notification.notification:update",
                label: "update",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "master.notification.notification:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "process",
    label: "process",
    isExpanded: false,
    permission: [
      {
        key: "process:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "process:insert",
        label: "insert",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "process:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "process:delete",
        label: "delete",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "process.process_po",
        label: "process_po",
        isExpanded: false,
        permission: [
          {
            key: "process.process_po:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "process.process_po:insert",
            label: "insert",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "process.process_po:update",
            label: "update",
            status: CheckStatus.UNCHECKED,
          },
          {
            key: "process.process_po:delete",
            label: "delete",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "process.process_po.processed_po",
            label: "processed_po",
            isExpanded: false,
            permission: [
              {
                key: "process.process_pro.processed_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "process.process_pro.processed_po:delete",
                label: "delete",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "process.process_po.reprocess_po",
            label: "reprocess_po",
            isExpanded: false,
            permission: [
              {
                key: "process.process_pro.reprocess_po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "process.process_pro.reprocess_po:insert",
                label: "insert",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "report",
    label: "report",
    isExpanded: false,
    permission: [
      {
        key: "report:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "report.report",
        label: "report",
        isExpanded: false,
        permission: [
          {
            key: "report.report:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.report.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "report.report.po_detail",
            label: "po_detail",
            isExpanded: false,
            permission: [
              {
                key: "report.report.po_detail:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
      {
        key: "report.dashboard",
        label: "dashboard",
        isExpanded: false,
        permission: [
          {
            key: "report.dashboard:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "report.dashboard.summary",
            label: "summary",
            isExpanded: false,
            permission: [
              {
                key: "report.dashboard.summary:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "monitoring",
    label: "monitoring",
    isExpanded: false,
    permission: [
      {
        key: "monitoring:view",
        label: "view",
        status: CheckStatus.UNCHECKED,
      },
    ],
    children: [
      {
        key: "monitoring.monitoring",
        label: "monitoring",
        isExpanded: false,
        permission: [
          {
            key: "monitoring.monitoring:view",
            label: "view",
            status: CheckStatus.UNCHECKED,
          },
        ],
        children: [
          {
            key: "monitoring.monitoring.po",
            label: "po",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.po:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.po:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.so",
            label: "so",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.so:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
              {
                key: "monitoring.monitoring.so:view_own",
                label: "view_own",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.connection",
            label: "connection",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.connection:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_product",
            label: "unmapped_product",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_product:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.unmapped_store",
            label: "unmapped_store",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.unmapped_store:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.upload_log",
            label: "upload_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.upload_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.as2_log",
            label: "as2_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.as2_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
          {
            key: "monitoring.monitoring.sftp_log",
            label: "sftp_log",
            isExpanded: false,
            permission: [
              {
                key: "monitoring.monitoring.sftp_log:view",
                label: "view",
                status: CheckStatus.UNCHECKED,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    key: "change_profile",
    label: "change_profile",
    isExpanded: false,
    permission: [
      {
        key: "change_profile:update",
        label: "update",
        status: CheckStatus.UNCHECKED,
      },
      {
        key: "change_profile:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "change_password",
    label: "change_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "change_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
  {
    key: "forgot_password",
    label: "forgot_password",
    status: CheckStatus.UNCHECKED,
    permission: [
      {
        key: "forgot_password:update_own",
        label: "update_own",
        status: CheckStatus.UNCHECKED,
      },
    ],
  },
];
