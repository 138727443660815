import {
  Box, Text
} from "@chakra-ui/react";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  BlueEditIcon, DeleteSuccess, RedDeleteIcon, UnSuccess
} from "../../../../../components/icons";
import ModalConfirm from "../../../../../components/ModalConfirm";
import ModalCustom from "../../../../../components/ModalCustom";
import { ID_WAREHOUSE } from "../../../../../constant/idConstant";
import {
  PRIMARY_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
import { RenderStatus } from "../../../../../function/RenderStatus";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import {
  deleteWarehouse,
  detailWarehouse
} from "../../../../../services/main/warehouseReducer";
const DetailWarehouse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const { company_name } = useSelector((state) => state.auth);
  const principalToLowerCase = company_name?.toLowerCase();
  const isDanone = principalToLowerCase.includes("danone");

  const [dataDetail, setdataDetail] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const renderDPDrules = (is_dpd_rules) => {
    let text = "";
    if (!is_dpd_rules) text = "NO";
    if (is_dpd_rules) text = "YES";
    return text;
  };
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const payload = { updated_at: updatedAt };
  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };

  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      warehouseId: Number(location.state.id),
    };
    dispatch(deleteWarehouse(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
      } else {
        setDeleted(false);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };

  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailWarehouse(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setdataDetail(response.payload.response);
        setUpdatedAt(response.payload.response.updated_at);
      }
    });
    setIsLoading(false);
  };
  useEffectOnce(() => {
    fetchData();
  });
  return isDeleted ? (
    <Redirect to={ROUTES_USER_SELLER.WAREHOUSE.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("WAREHOUSE:DETAIL_WAREHOUSE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("WAREHOUSE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("WAREHOUSE:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("WAREHOUSE:TITLE_LIST")}
            </span>{" "}
            - {t("WAREHOUSE:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              id={ID_WAREHOUSE.DETAIL.BTN_EDIT}
              link={{
                pathname: ROUTES_USER_SELLER.WAREHOUSE.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              id={ID_WAREHOUSE.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.WAREHOUSE.LIST}
        name={dataDetail?.created_name}
        date={moment(dataDetail?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(dataDetail?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={dataDetail?.updated_name}
      />
      {/* white box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        justifyContent="space-between"
        display="flex"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box padding={1} width="404px">
          {/* Warehouse Code */}
          <Box padding={1}>
            <Box>{t("WAREHOUSE:FIELD_WAREHOUSE_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.warehouse_code
                    ? dataDetail?.warehouse_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Warehouse Name */}
          <Box padding={1}>
            <Box>{t("WAREHOUSE:FIELD_WAREHOUSE_NAME")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.warehouse_name
                    ? dataDetail?.warehouse_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Principal Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("WAREHOUSE:FIELD_PRINCIPAL_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.principal_code
                    ? dataDetail?.principal_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Principal Name*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("WAREHOUSE:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.principal_name
                    ? dataDetail?.principal_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Is DPD Rules*/}
          {isDanone ? (
            <Box padding={1}>
              <Box width="10rem">
                <Text>{t("WAREHOUSE:FIELD_IS_DPD_RULES")}</Text>
              </Box>
              <Box>
                <Text>
                  <b>{renderDPDrules(dataDetail?.is_dpd_rules)}</b>
                </Text>
              </Box>
            </Box>
          ) : (
            <></>
          )}

          {/* Status*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("WAREHOUSE:FIELD_STATUS")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{RenderStatus(dataDetail?.status)}</b>
              </Text>
            </Box>
          </Box>
        </Box>
        {/* end of left side  */}

        {/* right side */}
        <Box padding={1} marginRight="7.5rem" width="404px">
          {/* Address */}
          <Box padding={1} marginBottom={2}>
            <Box>{t("WAREHOUSE:FIELD_ADDRESS")}</Box>
            <Box>
              <Text>
                <b>{dataDetail?.address ? dataDetail?.address : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* Country  */}
          <Box padding={1} marginBottom={2} minHeight="47px">
            <Box>{t("WAREHOUSE:FIELD_COUNTRY")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.country_name ? dataDetail?.country_name : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Island  */}
          <Box padding={1} marginBottom={2} minHeight="47px">
            <Box>{t("WAREHOUSE:FIELD_ISLAND")}</Box>
            <Box>
              <Text>
                <b>{dataDetail?.island_name ? dataDetail?.island_name : "-"}</b>
              </Text>
            </Box>
          </Box>
          {/* Province  */}
          <Box padding={1} marginBottom={2} minHeight="47px">
            <Box>{t("WAREHOUSE:FIELD_ISLAND")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.province_name ? dataDetail?.province_name : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* District  */}
          <Box padding={1} marginBottom={2} minHeight="47px">
            <Box>{t("WAREHOUSE:FIELD_DISTRICT")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.district_name ? dataDetail?.district_name : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Sub District  */}
          <Box padding={1} marginBottom={2} minHeight="47px">
            <Box>{t("WAREHOUSE:FIELD_SUB_DISTRICT")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.sub_district_name
                    ? dataDetail?.sub_district_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Urban Village  */}
          <Box padding={1} marginBottom={2} minHeight="47px">
            <Box>{t("WAREHOUSE:FIELD_URBAN_VILLAGE")}</Box>
            <Box>
              <Text>
                <b>
                  {dataDetail?.urban_village_name
                    ? dataDetail?.urban_village_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
        idYes={ID_WAREHOUSE.DETAIL.BTN_YES}
        idNo={ID_WAREHOUSE.DETAIL.BTN_NO}
      />
    </Box>
  );
};

export default DetailWarehouse;
