import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  store_data: {
    store_id: "",
    store_code: "",
    store_name: "",
    customer_id: "",
    phone_country_code: "",
    phone: "",
    status: "",
    address_1: "",
    address_2: "",
    address_3: "",
    country_id: null,
    island_id: null,
    province_id: null,
    district_id: null,
    sub_district_id: null,
    urban_village_id: null,
    postal_code_id: null,
    npwp: "",
    hamlet: "",
    neighbourhood: "",
    email: "",
    alternative_email: "",
    fax: "",
    company_title_id: null,
    additional_data: [],
  },
  listStore: [],
  errorNotification: null,
  detail_store: {
    data_management_history: {},
    store: {},
  },
};

export const getListMasterStore = createAsyncThunk(
  "getListMasterStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);


export const getInitListMasterStore = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/store/initiateGetList",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailMasterStore = createAsyncThunk(
  "Get Detail Master Store",
  async (storeMasterId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store/${storeMasterId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const updateMasterStore = createAsyncThunk(
  "updateMasterStore",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store/${object.id}`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      additional_data:
        object.additional_data === "null"
          ? []
          : JSON.parse(object.additional_data),
      address_1: object.address_1,
      address_2: object.address_2,
      address_3: object.address_3,
      alternative_email: object.alternative_email,
      company_title_id: object.company_title_id,
      company_title_name: object.company_title_name,
      country_id: object.country_id,
      country_name: object.country_name,
      customer_id: object.customer_id,
      customer_code: object.customer_code,
      customer_name: object.customer_name,
      district_id: object.district_id,
      district_name: object.district_name,
      email: object.email,
      fax: object.fax,
      hamlet: object.hamlet,
      island_id: object.island_id,
      island_name: object.island_name,
      neighbourhood: object.neighbourhood,
      npwp: object.npwp,
      phone: object.phone,
      phone_country_code: object.phone_country_code,
      postal_code_id: object.postal_code_id,
      postal_code_name: object.postal_code_name,
      province_id: object.province_id,
      province_name: object.province_name,
      status: object.status,
      store_id: object.store_id,
      store_code: object.store_code,
      store_name: object.store_name,
      sub_district_id: object.sub_district_id,
      sub_district_name: object.sub_district_name,
      urban_village_id: object.urban_village_id,
      urban_village_name: object.urban_village_name,
      updated_at: object.updated_at,
    };
    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const addMasterStore = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/store",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const deleteMasterStore = createAsyncThunk(
  "Delete Master Store",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store/${params.storeMasterId}`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      updated_at: params.updated_at,
    };
    try {
      const response = await baseApi.doDelete(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

const masterStore = createSlice({
  name: "masterStore",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxUser() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListMasterStore.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListMasterStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.listStore = action.payload.response;
    },
    [getListMasterStore.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload.response;
      state.table_loading = false;
    },
    [detailMasterStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailMasterStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_store = action.payload.response;
    },
    [detailMasterStore.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateMasterStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateMasterStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.update_store = action.payload.response;
     },
    [updateMasterStore.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [addMasterStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [addMasterStore.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addMasterStore.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [deleteMasterStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteMasterStore.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteMasterStore.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
  },
});

export const masterStoreAction = masterStore.actions;

export default masterStore.reducer;
