import {
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  inputMultiTheme,
  inputTheme,
} from "../../../../../constant/themeConstant";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  detailProductSubtitute,
  editProductSubtitute,
  getListPrincipal,
  getListProductCategory,
  getListProductPrincipal,
} from "../../../../../services/main/productSubtituteReducer";
import { useFormik } from "formik";
import {
  editProductSubtituteSchema,
} from "./productSubtituteSchema";
import ModalCustom from "../../../../../components/ModalCustom";
import {
  SavedSuccess,
  UnSuccess,
} from "../../../../../components/icons";
import { Redirect, useLocation } from "react-router-dom";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import StringUtil from "../../../../../utils/stringUtil";
// import { useMemo } from "react";
// import { arrayOrArrOfObj } from "../../../../../utils/arrayOrArrOfObjUtil";
// import { useCallback } from "react";
// import {
//   replaceComma,
//   replaceCommaWithUnderscore,
// } from "../../../../../function/ReplaceComma";
// import ObjectUtil from "../../../../../utils/objectUtil";
// import {
//   filterNonEmpty,
//   filterNonEmptyObjects,
// } from "../../../../../function/FilterNonEmpty";
import { ID_PRODUCT_SUBSTITUTE } from "../../../../../constant/idConstant";
const EditProductSubtitute = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [dataDetail, setDataDetail] = useState({}); 

  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);

  const [pageProductPrincipalFrom, setPageProductPrincipalFrom] = useState(1);
  const [limitProductPrincipalFrom, setLimitProductPrincipalFrom] =
    useState(10);

  const [pageProductPrincipalTo, setPageProductPrincipalTo] = useState(1);
  const [limitProductPrincipalTo, setLimitProductPrincipalTo] = useState(10);

  const [pageProductCategory, setPageProductCategory] = useState(1);
  const [limitProductCategory, setLimitProductCategory] = useState(10);

  // Code(for disabled input)
  const [principalCode, setPrincipalCode] = useState("");
  // const [productCategoryCode, setproductCategoryCode] = useState("");
  // const [principalProductCodeFrom, setPrincipalProductCodeFrom] = useState("");
  // const [principalProductCodeTo, setPrincipalProductCodeTo] = useState("");

  const handleClear = (name) => {
    if (name === "") {
    }
  };

  const handleChangeSelect = (name, value, option) => { 
    switch (name) {
      case "product_category_id":
        setFieldValue("product_category_id", {
          value: value,
          label: option?.children,
        });
        setFieldValue(
          "product_category_code",
          option?.fulldata?.product_category_code
        );
        break;
      case "product_principal_id_from": 
        // const mapping = filterNonEmptyObjects(option).map((val) => {
        //   return {
        //     value: val.value,
        //     label: val.children,
        //     code: val.code,
        //   };
        // }); 
        // const mergedArray = [...values?.product_principal_id_from];
        setFieldValue("product_principal_id_from", value);
        setFieldValue(
          "product_principal_id_from_code",
          option?.map((val) => {
            return val.code;
          })
        );
        break;
      case "product_principal_id_to":
        setFieldValue("product_principal_id_to", {
          value: value,
          label: option?.children,
        });
        // setFieldValue("old_product_principal_id_to", {
        //   value: value,
        //   label: option?.children,
        // });
        setFieldValue(
          "product_principal_id_to_code",
          option?.fulldata?.product_code
        );
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };
  
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  // fetch data detail
  const fetchDataDetail = () => {
    setIsLoading(true);
    dispatch(detailProductSubtitute(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(response.payload.response.updated_at);
        setDataDetail(response.payload.response);
      }
    });
    setIsLoading(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  // product category
  const [dataProductCategory, setDataProductCategory] = useState([]);
  const fetchDataProductCategory = () => {
    const page = pageProductPrincipalFrom;
    const limit = limitProductPrincipalFrom;
    const is_check_status = true;
    const params = { page, limit, is_check_status };
    dispatch(getListProductCategory(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductCategory([...dataProductCategory, ...resp]);
      // setPrincipalCode(resp[0]?.principal_code);
    });
  };

  // principal product from
  const [dataPrincipalProductFrom, setDataPrincipalProductFrom] = useState([]);
  const fetchDataPrincipalProductFrom = () => {
    const page = pageProductPrincipalFrom;
    const limit = limitProductPrincipalFrom;
    const is_check_status = true;
    const params = { page, limit, is_check_status };
    dispatch(getListProductPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipalProductFrom([...dataPrincipalProductFrom, ...resp]);
      // setPrincipalCode(resp[0]?.principal_code);
    });
  };
  const handleScrollPrincipalProductFrom = () => {
    setPageProductPrincipalFrom(pageProductPrincipalFrom + 1);
  };
  // principal product to

  const [dataPrincipalProductTo, setDataPrincipalProductTo] = useState([]);
  const [filterProductPrincipalTo, setFilterProductPrincipalTo] = useState("");

  const fetchDataPrincipalProductTo = () => {
    const page = pageProductPrincipalTo;
    const limit = limitProductPrincipalTo;
    const is_check_status = true;

    const filter = StringUtil.formatFilter({
      id: `not_list_id ${values?.product_principal_id_from
        ?.map((data) => data.value)
        .join("_")}`,
    }); 
    const params = { page, limit, filter, is_check_status };

    dispatch(getListProductPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipalProductTo([...dataPrincipalProductTo, ...resp]);
    });
  };
  const handleScrollPrincipalProductTo = () => {
    setPageProductPrincipalTo(pageProductPrincipalTo + 1);
  };
  const fetchSearchPrincipalProductTo = () => {
    const page = pageProductPrincipalTo;
    const limit = limitProductPrincipalTo;
    const is_check_status = true;
    const filter = StringUtil.formatFilter({
      id: `not_list_id ${values?.product_principal_id_from
        ?.map((data) => data.value)
        .join("_")}`,
    });
    const params = { page, limit, filter, is_check_status };
    // if (!values?.product_principal_id_from?.length === 0) {
    //   setDataPrincipalProductTo([]);
    // } else {

    // }

    dispatch(getListProductPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      setPageProductPrincipalTo(1);
      setDataPrincipalProductTo(resp);
    });
  };
  const onSubmit = () => {
    const productId = location.state.id;
    const payload = {
      ...values,
      productId,
    };
    dispatch(editProductSubtitute(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      product_category_id: {
        value: dataDetail?.product_category_id,
        label: dataDetail?.product_category_name,
      },
      product_category_code: dataDetail?.product_category_code,
      product_principal_id_from:
        dataDetail?.product_principal_from?.map((val) => ({
          value: val.id,
          label: val.product_name,
          code: val.product_code,
        })) ?? [],
      product_principal_id_from_code: dataDetail?.product_principal_from?.map(
        (val) => {
          return val.product_code;
        }
      ),
      product_principal_id_to: {
        value: dataDetail?.principal_product_id_to,
        label: dataDetail?.principal_product_name_to,
      },
      product_principal_id_to_code: dataDetail.principal_product_code_to,
      old_product_principal_id_to: {
        value: dataDetail?.principal_product_id_to,
        label: dataDetail?.principal_product_name_to,
      },
      status: dataDetail?.status,
      updatedAt: updatedAt,
    },
    validationSchema: editProductSubtituteSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  }); 
  // useMemo(
  //   () => setArrOrArrOfOb(arrayOrArrOfObj(values?.product_principal_id_from)),
  //   [values?.product_principal_id_from]
  // );

  //use effect once
  useEffectOnce(() => {
    fetchDataDetail();
  });

  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);
  // product category
  useEffect(() => {
    fetchDataProductCategory();
  }, [pageProductCategory]);
  // principal product from
  useEffect(() => {
    fetchDataPrincipalProductFrom();
  }, [pageProductPrincipalFrom]);
  // principal product to
  useEffect(() => {
    fetchDataPrincipalProductTo();
  }, [pageProductPrincipalTo]);
  useEffect(() => {
    fetchSearchPrincipalProductTo();
  }, [filterProductPrincipalTo, values?.product_principal_id_from]);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_SUBTITUTE:EDIT_PRODUCT_PRODUCT_SUBTITUTE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_SUBTITUTE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_SUBTITUTE:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_SUBTITUTE:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_SUBTITUTE:EDIT_PRODUCT_PRODUCT_SUBTITUTE")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* principal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.PRINCIPAL_CODE}
                  style={inputTheme}
                  disabled
                  value={
                    principalCode ? principalCode : dataDetail?.principal_code
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* product category */}
            {/* <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_SUBTITUTE:FIELD_PRODUCT_CATEGORY")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  value={
                    values?.product_category_code
                      ? values?.product_category_code
                      : ""
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box> */}
            {/* principal product code from */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_CODE_FROM")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.PRINCIPAL_PRODUCT_CODE_FROM}
                  disabled
                  value={
                    values?.product_principal_id_from_code
                      ? values?.product_principal_id_from_code
                      : ""
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* principal product code to */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_CODE_TO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.PRINCIPAL_PRODUCT_NAME_TO}
                  style={inputTheme}
                  disabled
                  value={
                    values?.product_principal_id_to_code
                      ? values?.product_principal_id_to_code
                      : ""
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* Principal Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.PRINCIPAL_NAME}
                  style={inputTheme}
                  disabled
                  value={
                    principal_name ? principal_name : dataDetail?.principal_name
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Product Category Name */}
            {/* <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRODUCT_CATEGORY_NAME")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  allowClear
                  value={values?.product_category_id}
                  name="product_category_id"
                  onBlur={handleBlur("product_category_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_category_id", value, opt)
                  }
                >
                  {dataProductCategory?.map((data, index) => {
                    return (
                      <Select.Option
                        value={data.id}
                        key={index.toString()}
                        fulldata={data}
                      >
                        {data.product_category_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors?.product_category_id?.value &&
                  touched?.product_category_id?.value && (
                    <ValidationError
                      text={errors?.product_category_id?.value}
                    />
                  )}
              </Box>
            </Box> */}
            {/* principal product name from*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_FROM")}
                </Text>
              </Box>
              <Box width={"250px"}>
                <Select
                  style={inputMultiTheme}
                  showArrow
                  mode="multiple"
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.PRINCIPAL_PRODUCT_NAME_FROM}
                  maxTagCount={1}
                  onPopupScroll={handleScrollPrincipalProductFrom}
                  size="large"
                  allowClear
                  value={values?.product_principal_id_from}
                  name="product_principal_id_from"
                  onBlur={handleBlur("product_principal_id_from")}
                  onChange={(value, opt ,fulldata) =>
                    handleChangeSelect("product_principal_id_from", value, opt, fulldata)
                  }
                >
                  {dataPrincipalProductFrom?.map((data, index) => {
                    return (
                      <Select.Option
                        value={data.id}
                        key={data.id}
                        code={data.product_code}
                        fulldata={data}
                        title={data.product_code}
                      >
                         {data.product_code}
                        {" - "}
                        {data.product_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors?.product_principal_id_from?.value &&
                  touched?.product_principal_id_from?.value && (
                    <ValidationError
                      text={errors?.product_principal_id_from?.value}
                    />
                  )}
              </Box>
            </Box>
            {/* principal product name to */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_TO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.PRINCIPAL_PRODUCT_NAME_TO}
                  onPopupScroll={handleScrollPrincipalProductTo}
                  allowClear
                  value={values?.product_principal_id_to}
                  name="product_principal_id_to"
                  onBlur={handleBlur("product_principal_id_to")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_principal_id_to", value, opt)
                  }
                  // disabled={arrOrArrOfOb ? true : false}
                >
                  {dataPrincipalProductTo?.map((data, index) => {
                    return (
                      <Select.Option
                        value={data.id}
                        key={index.toString()}
                        fulldata={data}
                        title={data.product_code}
                      >
                       {data.product_code}
                        {" - "}
                        {data.product_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors?.product_principal_id_to?.value &&
                  touched?.product_principal_id_to?.value && (
                    <ValidationError
                      text={errors?.product_principal_id_to?.value}
                    />
                  )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_SUBTITUTE:FIELD_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  id={ID_PRODUCT_SUBSTITUTE.EDIT.STATUS}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_SUBSTITUTE.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_SUBSTITUTE.EDIT.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <UnSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EditProductSubtitute;
