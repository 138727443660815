import {
  Box,
  Text,
} from "@chakra-ui/react";
import NoImagePlaceholder from "../../../../../assets/noimage_placeholder.png";
import Spin from "antd/lib/spin";
import { useTranslation } from "react-i18next";
import FormatUtil from "../../../../../utils/formatUtil";
import { detailImageTheme } from "../../../../../constant/themeConstant";

const BodyUserProfile = ({ getDetailUser, loading }) => {
  const { t } = useTranslation();
  const userProfile = getDetailUser?.user_profile;
  let gender = userProfile?.sex === "L" ? t("COMMON:MALE") : t("COMMON:FEMALE");
  const photo = userProfile?.photo ? userProfile?.photo[0] : null;
  // const { host, path, filename } = photo ?? {};
  // const photoFull = host?.concat("/").concat(path).concat(filename);
  // const photoFull = `${photo?.host}/${photo?.path}${photo?.filename}`;
  const photoFinal = userProfile?.photo
    ? FormatUtil.formatPhotoUrl(userProfile?.photo[0])
    : "";

  if (loading) {
    return <Spin size="large" />;
  }
  return (
    <Box display="flex">
      {/* left side  */}
      <Box padding={1} width="50rem">
        {/* Add Photo File */}
        <Box padding={1} marginBottom={2} minHeight="112px">
          <Box>{t("USER:FIELD_PHOTO")}</Box>
          <Box>
            <img
              src={photoFinal ? photoFinal : NoImagePlaceholder}
              alt="Not Ready"
              style={detailImageTheme}
            />
          </Box>
        </Box>
        {/* NIK  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_IDENTIFICATION_NUMBER")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.nik ? userProfile.nik : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Title  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_TITLE")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.person_title ? userProfile.person_title : "-"}
              </b>
            </Text>
          </Box>
        </Box>
        {/* First Name And Last Name  */}
        <Box padding={1} marginBottom={2} display="flex" minHeight="47px">
          <Box marginRight={2}>
            <Box>{t("USER:FIELD_FIRST_NAME")}</Box>
            <Box>
              <Text>
                <b>{userProfile?.first_name ? userProfile.first_name : "-"}</b>
              </Text>
            </Box>
          </Box>
          <Box marginLeft={2}>
            <Box>{t("USER:FIELD_LAST_NAME")}</Box>
            <Box>
              <Text>
                <b>{userProfile?.last_name ? userProfile.last_name : "-"}</b>
              </Text>
            </Box>
          </Box>
        </Box>
        {/* Gender  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_GENDER")}</Box>
          <Box>
            <Text>
              <b>{gender}</b>
            </Text>
          </Box>
        </Box>
        {/* Email  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_EMAIL")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.email ? userProfile.email : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Phone  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_PHONE")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.phone ? userProfile.phone : "-"}</b>
            </Text>
          </Box>
        </Box>
      </Box>
      {/* center side  */}
      <Box padding={1} width="50rem" minHeight="47px">
        {/* Address */}
        <Box padding={1} marginBottom={2}>
          <Box>{t("USER:FIELD_ADDRESS")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.address ? userProfile?.address : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Locale  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_LOCALE")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.locale ? userProfile?.locale : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Country  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_COUNTRY")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.country_name ? userProfile?.country_name : "-"}
              </b>
            </Text>
          </Box>
        </Box>
        {/* Island  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_ISLAND")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.island_name ? userProfile?.island_name : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Province  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_PROVINCE")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.province_name ? userProfile?.province_name : "-"}
              </b>
            </Text>
          </Box>
        </Box>
        {/* District  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_DISTRICT")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.district_name ? userProfile?.district_name : "-"}
              </b>
            </Text>
          </Box>
        </Box>
        {/* Sub District  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_SUB_DISTRICT")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.sub_district_name
                  ? userProfile?.sub_district_name
                  : "-"}
              </b>
            </Text>
          </Box>
        </Box>
        {/* Urban Village  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_URBAN_VILLAGE")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.urban_village_name
                  ? userProfile?.urban_village_name
                  : "-"}
              </b>
            </Text>
          </Box>
        </Box>
      </Box>
      {/* right side  */}
      <Box padding={1} width="40rem" minHeight="47px">
        {/* Hamlet */}
        <Box padding={1} marginBottom={2}>
          <Box>{t("USER:FIELD_HAMLET")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.hamlet ? userProfile?.hamlet : "-"}</b>
            </Text>
          </Box>
        </Box>
        {/* Neighborhood  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_NEIGHBORHOOD")}</Box>
          <Box>
            <Text>
              <b>
                {userProfile?.neighbourhood ? userProfile?.neighbourhood : "-"}
              </b>
            </Text>
          </Box>
        </Box>
        {/* Postal Code  */}
        <Box padding={1} marginBottom={2} minHeight="47px">
          <Box>{t("USER:FIELD_POSTAL_CODE")}</Box>
          <Box>
            <Text>
              <b>{userProfile?.postal_code ? userProfile?.postal_code : "-"}</b>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyUserProfile;
