import {
  Box, Text
} from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../../components/button/ButtonPrimary";
import DropzoneImageLogo from "../../../../../../components/button/DropzoneImageLogo";
import DropDownCustom from "../../../../../../components/DropDownCustom";
import {
  SavedSuccess,
  UnSuccess
} from "../../../../../../components/icons";
import ModalCustom from "../../../../../../components/ModalCustom";
import ValidationError from "../../../../../../components/validationErrorMessage/ValidationError";
import { ID_DISTRIBUTOR } from "../../../../../../constant/idConstant";
import {
  GRAY_COLOR
} from "../../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../../constant/routeConstant";
import { selectStatus } from "../../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../../constant/themeConstant";
import { UpperCaseLetter } from "../../../../../../function/UpperCaseLetter";
import {
  distributorAction,
  editDistributor,
  getListCompanyTitle
} from "../../../../../../services/main/distributorReducer";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage
} from "../../../../../../services/main/regionalReducer";
import FormatUtil from "../../../../../../utils/formatUtil";
import { formatFilter } from "../../../../../../utils/stringUtil";
import {
  editCompanyProfileSchema
} from "../../distributorShema";
const BodyCompanyProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { curr_page } = useSelector((state) => state.distributor);
  const { index_page } = useSelector((state) => state.distributor);
  const { detail_company_profile } = useSelector((state) => state.distributor);
  const { detail_distributor } = useSelector((state) => state.distributor);
  const { preferences } = useSelector((state) => state.distributor);
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);

  const { list_country } = useSelector((state) => state.regional);
  const { list_island } = useSelector((state) => state.regional);
  const { list_province } = useSelector((state) => state.regional);
  const { list_district } = useSelector((state) => state.regional);
  const { list_sub_district } = useSelector((state) => state.regional);
  const { list_urban_village } = useSelector((state) => state.regional);
  const { list_postal_code } = useSelector((state) => state.regional);

  // const [list_country, setListCountry] = useState([]);
  // const [list_island, setListIsland] = useState([]);
  // const [list_province, setListProvince] = useState([]);
  // const [list_district, setListDistrict] = useState([]);
  // const [list_sub_district, setListSubDistrict] = useState([]);
  // const [list_urban_village, setListUrbanVillage] = useState([]);
  // const [list_postal_code, setListPostalCode] = useState([]);
  const [list_company_title, setListCompanyTitle] = useState([]);

  /// fungsi untuk modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const toNextPage = () => {
    const distributor = {
      principal_id: principalId,
      alias: values?.alias,
      status: values?.status,
      additional_data: [],
    };
    dispatch(distributorAction.setIndexPage(index_page + 1));
    dispatch(
      distributorAction.setCurrPage(t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES"))
    );
    dispatch(
      distributorAction.setDataCompanyProfile({
        ...values,
      })
    );
    dispatch(distributorAction.setDataDistributor(distributor));
  };
  const handleCancel = () => {
    dispatch(distributorAction.resetReduxDistributor());
  };

  const handleClear = (name) => {
    switch (name) {
      case "country_id":
        setFieldValue("country_id", {
          value: null,
          label: "",
        });
        setFieldValue("province_id", {
          value: null,
          label: "",
        });
        setFieldValue("island_id", {
          value: null,
          label: "",
        });
        setFieldValue("district_id", {
          value: null,
          label: "",
        });
        setFieldValue("sub_district_id", {
          value: null,
          label: "",
        });
        setFieldValue("urban_village_id", {
          value: null,
          label: "",
        });
        setFieldValue("postal_code_id", {
          value: null,
          label: "",
        });
        // setListIsland(null);
        break;

      case "island_id":
        setFieldValue("island_id", {
          value: null,
          label: "",
        });
        break;

      case "province_id":
        setFieldValue("province_id", {
          value: null,
          label: "",
        });
        setFieldValue("district_id", {
          value: null,
          label: "",
        });
        setFieldValue("sub_district_id", {
          value: null,
          label: "",
        });
        setFieldValue("urban_village_id", {
          value: null,
          label: "",
        });
        setFieldValue("postal_code_id", {
          value: null,
          label: "",
        });
        break;

      case "district_id":
        setFieldValue("district_id", {
          value: null,
          label: "",
        });
        setFieldValue("sub_district_id", {
          value: null,
          label: "",
        });
        setFieldValue("urban_village_id", {
          value: null,
          label: "",
        });
        setFieldValue("postal_code_id", {
          value: null,
          label: "",
        });
        break;

      case "sub_district_id":
        setFieldValue("sub_district_id", {
          value: null,
          label: "",
        });
        setFieldValue("urban_village_id", {
          value: null,
          label: "",
        });
        setFieldValue("postal_code_id", {
          value: null,
          label: "",
        });
        break;

      case "urban_village_id":
        setFieldValue("urban_village_id", {
          value: null,
          label: "",
        });
        setFieldValue("postal_code_id", {
          value: null,
          label: "",
        });
        break;

      case "postal_code_id":
        setFieldValue("postal_code_id", {
          value: null,
          label: "",
        });
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    if (name === "country_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ country_id: `eq ${value}` }),
        };

        dispatch(getListProvince(params));
        dispatch(getListIsland(params));
      }
      setFieldValue("country_id", value);
      setFieldValue("province_id", "");
      setFieldValue("island_id", "");
      setFieldValue("district_id", "");
      setFieldValue("sub_district_id", "");
      setFieldValue("urban_village_id", "");
      setFieldValue("postal_code_id", "");
      if (touched.country_id) {
        setFieldTouched("country_id", !touched.country_id);
      }
    } else if (name === "province_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ province_id: `eq ${value}` }),
        };
        dispatch(getListDistrict(params));
      }
      setFieldValue("province_id", value);
      setFieldValue("district_id", "");
      setFieldValue("sub_district_id", "");
      setFieldValue("urban_village_id", "");
      setFieldValue("postal_code_id", "");
      if (touched.province_id) {
        setFieldTouched("province_id", !touched.province_id);
      }
    } else if (name === "district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ district_id: `eq ${value}` }),
        };
        dispatch(getListSubDistrict(params));
      }
      setFieldValue("district_id", value);
      setFieldValue("sub_district_id", "");
      setFieldValue("urban_village_id", "");
      setFieldValue("postal_code_id", "");
      if (touched.district_id) {
        setFieldTouched("district_id", !touched.district_id);
      }
    } else if (name === "sub_district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ sub_district_id: `eq ${value}` }),
        };
        dispatch(getListUrbanVillage(params));
      }
      setFieldValue("sub_district_id", value);
      setFieldValue("urban_village_id", "");
      setFieldValue("postal_code_id", "");
      if (touched.sub_district_id) {
        setFieldTouched("sub_district_id", !touched.sub_district_id);
      }
    } else if (name === "urban_village_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ urban_village_id: `eq ${value}` }),
        };
        dispatch(getListPostalCode(params));
      }
      setFieldValue("urban_village_id", value);
      setFieldValue("postal_code_id", "");
      if (touched.urban_village_id) {
        setFieldTouched("urban_village_id", !touched.urban_village_id);
      }
    } else {
      setFieldValue(name, value);
    }
  };
  // const handleChangeManual = (name, event) => {
  //   setFieldValue("company_name", event.target.value);
  // };

  const onSubmit = () => {
    const payload = {
      companyProfile: values,
      preferences: preferences,
      distributor: {
        ...detail_distributor,
        alias: values?.alias,
        status: values?.status,
      },
      isPreferences: false,
      distributorId: detail_distributor?.id,
    };
    dispatch(editDistributor(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const phoneFull = detail_company_profile?.phone;
  const phoneCode = phoneFull?.split("-")[0] ?? "";
  const phoneNumber = phoneFull?.split("-")[1] ?? "";
  const faxFull = detail_company_profile?.fax;
  const faxCode = faxFull?.split("-")[0] ?? "";
  const faxNumber = faxFull?.split("-")[1] ?? "";

  const initialDataCompanyProfile = useMemo(() => {
    return {
      principal_id: principalId,
      company_title_id: detail_company_profile?.company_title_id,
      company_name: detail_company_profile?.name,
      name: detail_company_profile?.name,
      npwp: detail_company_profile?.npwp,
      address_1: detail_company_profile?.address_1,
      address_2: detail_company_profile?.address_2,
      address_3: detail_company_profile?.address_3,
      hamlet: detail_company_profile?.hamlet,
      neighbourhood: detail_company_profile?.neighbourhood,
      country_id:
        detail_company_profile?.country_id === 0 ||
        !detail_company_profile?.country_id
          ? null
          : detail_company_profile?.country_id,
      province_id:
        detail_company_profile?.province_id === 0 ||
        !detail_company_profile?.province_id
          ? null
          : detail_company_profile?.province_id,
      district_id:
        detail_company_profile?.district_id === 0 ||
        !detail_company_profile?.district_id
          ? null
          : detail_company_profile?.district_id,
      sub_district_id:
        detail_company_profile?.sub_district_id === 0 ||
        !detail_company_profile?.sub_district_id
          ? null
          : detail_company_profile?.sub_district_id,
      urban_village_id:
        detail_company_profile?.urban_village_id === 0 ||
        !detail_company_profile?.urban_village_id
          ? null
          : detail_company_profile?.urban_village_id,
      island_id:
        detail_company_profile?.island_id === 0 ||
        !detail_company_profile?.island_id
          ? null
          : detail_company_profile?.island_id,
      postal_code_id:
        detail_company_profile?.postal_code_id === 0 ||
        !detail_company_profile?.postal_code_id
          ? null
          : detail_company_profile?.postal_code_id,
      phone_country_code: phoneCode,
      phone: phoneNumber,
      phone_fax_country_code: faxCode,
      fax: faxNumber,
      email: detail_company_profile?.email,
      latitude: detail_company_profile?.latitude,
      longitude: detail_company_profile?.longitude,
      status: detail_distributor?.status,
      alias: detail_distributor?.alias,
      images: detail_company_profile?.photo
        ? FormatUtil.formatPhotoUrl(detail_company_profile?.photo[0])
        : "",
      photo: detail_company_profile?.photo
        ? detail_company_profile?.photo[0]
        : {},
      have_default_photo: Boolean(detail_company_profile?.photo),
    };
  }, [
    detail_company_profile,
    phoneNumber,
    phoneCode,
    faxNumber,
    faxCode,
    detail_distributor,
    principalId,
  ]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: initialDataCompanyProfile,
    validationSchema: editCompanyProfileSchema(t),
    onSubmit,

    isInitialValid: true,
    enableReinitialize: true,
  });

  const fetchDataCompanyTitle = () => {
    const page = 1;
    const limit = 10;
    const params = { page, limit };
    dispatch(getListCompanyTitle(params)).then((response) => {
      setListCompanyTitle(response.payload.response);
    });
  };

  const [inputSearchDDSCountry, setInputSearchDDSCountry] = useState("");
  const filterSearchDDSCountry = `name like ${inputSearchDDSCountry}`;
  const fetchDataCountry = () => {
    const page = 1;
    const limit = 100;
    const filter = filterSearchDDSCountry;
    const params =
      inputSearchDDSCountry === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCountry(params)).then((response) => {
      // setListCountry(response.payload.response);
    });
  };
  // island
  const [searchIsland, setSearchIsland] = useState("");
  const ddsIsland = useMemo(() => {
    if (list_island === null) return [];
    return list_island?.filter((values) =>
      values?.name.toLowerCase().includes(searchIsland.toLowerCase())
    );
  }, [list_island, searchIsland]);
  // province
  const [searchProvince, setSearchProvince] = useState("");
  const ddsProvince = useMemo(() => {
    return list_province?.filter((values) =>
      values?.name.toLowerCase().includes(searchProvince.toLowerCase())
    );
  }, [list_province, searchProvince]);
  // district
  const [searchDistrict, setSearchDistrict] = useState("");
  const ddsDistrict = useMemo(() => {
    return list_district?.filter((values) =>
      values?.name.toLowerCase().includes(searchDistrict.toLowerCase())
    );
  }, [list_district, searchDistrict]);
  // sub district
  const [searchSubDistrict, setSearchSubDistrict] = useState("");
  const ddsSubDistrict = useMemo(() => {
    return list_sub_district?.filter((values) =>
      values?.name.toLowerCase().includes(searchSubDistrict?.toLowerCase())
    );
  }, [list_sub_district, searchSubDistrict]);
  // urban_viilage
  const [searchUrbanVillage, setSearchUrbanVillage] = useState("");
  const ddsUrbanVillage = useMemo(() => {
    return list_urban_village?.filter((values) =>
      values?.name.toLowerCase().includes(searchUrbanVillage.toLowerCase())
    );
  }, [list_urban_village, searchUrbanVillage]);
  // postal
  const [searchPostalCode, setSearchPostalCode] = useState("");
  const ddsPostalCode = useMemo(() => {
    return list_postal_code?.filter((values) =>
      values?.code.includes(searchPostalCode)
    );
  }, [list_postal_code, searchPostalCode]);

  // fetch island
  const fetchIsland = () => {
    const params = {
      page: 1,
      limit: 100,
      filter: formatFilter({ country_id: `eq ${values?.country_id?.value}` }),
    };
    if (values?.country_id === null) {
      // setListIsland(null);
    } else {
      dispatch(getListIsland(params)).then((response) => {
        // setListIsland(response.payload.response);
      });
    }
  };

  // company profile
  useEffect(() => {
    fetchDataCompanyTitle();
  }, []);

  useEffect(() => {
    fetchDataCountry();
  }, [filterSearchDDSCountry]);

  useEffect(() => {
    fetchIsland();
  }, [values?.country_id?.value]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.DISTRIBUTOR.LIST} />
  ) : (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          borderRadius="10px"
          justifyContent="space-between"
          display="flex"
        >
          {/* left side */}
          <Box padding={1}>
            {/* Company Logo */}
            <Box padding={1} display="flex" marginBottom={4}>
              <Box width="10rem">
                <Text overflowWrap="break-word" width="100px">
                  {t("DISTRIBUTOR:FIELD_COMPANY_LOGO")}
                </Text>
              </Box>
              <Box>
                <DropzoneImageLogo
                  onChange={(data) => setFieldValue("images", data)}
                  onBlur={handleBlur}
                  defaultValue={values?.images}
                />
                {errors.images && touched.images && (
                  <ValidationError text={errors.images} />
                )}
              </Box>
            </Box>
            {/* Distributor Code  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="100px">
                  {t("DISTRIBUTOR:FIELD_DISTRIBUTOR_CODE")}
                </Text>
              </Box>
              <Box marginRight={2}>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  id={ID_DISTRIBUTOR.EDIT.CODE}
                  value="-"
                  disabled
                  backgroundColor={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Farmindo Account  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="100px">
                  {t("DISTRIBUTOR:FIELD_FARMINDO_ACCOUNT")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  value="-"
                  disabled
                  backgroundColor={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Distributor Title */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                {t("DISTRIBUTOR:FIELD_DISTRIBUTOR_TITLE")}
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.DISTRIBUTOR_TITLE}
                  width="250px"
                  onBlur={handleBlur}
                  size="large"
                  allowClear="true"
                  name="company_title_id"
                  showSearch={false}
                  value={values?.company_title_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("company_title_id", value, opt)
                  }
                >
                  {list_company_title?.map((value, index) => {
                    return (
                      <Select.Option
                        value={value.id}
                        key={index.toString()}
                        fulldata={value}
                        desc={value.description}
                      >
                        {UpperCaseLetter(value.title)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.company_title_id && (
                  // touched.company_title_id &&
                  <ValidationError text={errors.company_title_id} />
                )}
              </Box>
            </Box>
            {/* Distributor Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">{t("DISTRIBUTOR:FIELD_DISTRIBUTOR_NAME")}</Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  id={ID_DISTRIBUTOR.ADD.DISTRIBUTOR_NAME}
                  name="company_name"
                  onBlur={handleBlur}
                  // onChange={(event) =>
                  //   handleChangeManual("company_name", event)
                  // }
                  onChange={handleChange}
                  value={values?.company_name}
                />
                {errors.company_name && touched.company_name && (
                  <ValidationError text={errors.company_name} />
                )}
              </Box>
            </Box>
            {/* No NPWP */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="100px">
                  {t("DISTRIBUTOR:FIELD_NO_NPWP")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  name="npwp"
                  id={ID_DISTRIBUTOR.ADD.NPWP}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // onChange={(event) => handleChangeManual("npwp", event)}
                  value={values?.npwp}
                />
                {errors.npwp && touched.npwp && (
                  <ValidationError text={errors.npwp} />
                )}
              </Box>
            </Box>
            {/* Corporate Phone  */}
            <Box padding={1} display="flex">
              <Box width="10rem">{t("DISTRIBUTOR:FIELD_CORPORATE_PHONE")}</Box>
              <Box>
                <Select
                  style={{
                    width: "80px",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontStyle: "normal",
                  }}
                  id={ID_DISTRIBUTOR.ADD.PHONE_CODE}
                  size="large"
                  value={values?.phone_country_code}
                  onChange={(value, opt) =>
                    handleChangeSelect("phone_country_code", value, opt)
                  }
                >
                  {list_country?.map((value, index) => {
                    return (
                      <option key={index} value={value.phone_code}>
                        {value.phone_code}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box>
                <Input
                  type="tel"
                  style={{
                    width: "170px",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontStyle: "normal",
                  }}
                  id={ID_DISTRIBUTOR.ADD.PHONE}
                  size="large"
                  value={values?.phone}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="phone"
                />
                {errors.phone && touched.phone && (
                  <ValidationError text={errors.phone} />
                )}
              </Box>
            </Box>
            {/* Fax Number  */}
            <Box padding={1} display="flex">
              <Box width="10rem">{t("DISTRIBUTOR:FIELD_FAX_NUMBER")}</Box>
              <Box>
                <Select
                  style={{
                    width: "80px",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontStyle: "normal",
                  }}
                  id={ID_DISTRIBUTOR.ADD.FAX_CODE}
                  size="large"
                  value={values?.phone_fax_country_code}
                  onChange={(value, opt) =>
                    handleChangeSelect("phone_fax_country_code", value, opt)
                  }
                >
                  {list_country?.map((value, index) => {
                    return (
                      <option key={index} value={value.phone_code}>
                        {value.phone_code}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box>
                <Input
                  type="tel"
                  style={{
                    width: "170px",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontStyle: "normal",
                  }}
                  size="large"
                  id={ID_DISTRIBUTOR.ADD.FAX}
                  value={values?.fax}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="fax"
                />
                {errors.fax && touched.fax && (
                  <ValidationError text={errors.fax} />
                )}
              </Box>
            </Box>
            {/* Email  */}
            <Box padding={1} display="flex">
              <Box width="10rem">{t("DISTRIBUTOR:FIELD_EMAIL")}</Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  name="email"
                  onBlur={handleBlur}
                  id={ID_DISTRIBUTOR.ADD.EMAIL}
                  onChange={handleChange}
                  value={values?.email}
                />
                {errors.email && touched.email && (
                  <ValidationError text={errors.email} />
                )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_STATUS")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  id={ID_DISTRIBUTOR.ADD.STATUS}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            {/* alias  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_ALIAS")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  name="alias"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  // onChange={(event) => handleChangeManual("alias", event)}
                  value={values?.alias}
                />
                {errors.alias && touched.alias && (
                  <ValidationError text={errors.alias} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* address */}
            <Box padding={1} display="flex">
              <Box width="10rem" pt={1}>
                <Text>{t("DISTRIBUTOR:TITTLE_RIGHT")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  name="address_1"
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.ADDRESS}
                  value={values.address_1}
                  onChange={handleChange}
                  // onChange={(event) => handleChangeManual("address_1", event)}
                  onBlur={handleBlur}
                />
                {errors.address_1 && touched.address_1 && (
                  <ValidationError text={errors.address_1} />
                )}
              </Box>
            </Box>
            {/* country */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_COUNTRY")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  width="250px"
                  id={ID_DISTRIBUTOR.ADD.COUNTRY}
                  onBlur={handleBlur}
                  size="large"
                  name="country_id"
                  onClear={() => handleClear("country_id")}
                  showSearch={false}
                  value={values?.country_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("country_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setInputSearchDDSCountry(e.target.value)}
                    />
                  )}
                >
                  {list_country?.map((value, index) => {
                    return (
                      <Select.Option value={value.id} key={index.toString()}>
                        {value.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.country_id && (
                  <ValidationError text={errors.country_id} />
                )}
              </Box>
            </Box>

            {/* island */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("DISTRIBUTOR:FIELD_ISLAND")}
                </Text>
              </Box>
              <Box>
                <Select
                  id={ID_DISTRIBUTOR.ADD.ISLAND}
                  style={inputTheme}
                  onBlur={handleBlur}
                  width="250px"
                  onClear={() => handleClear("island_id")}
                  size="large"
                  showSearch={false}
                  // allowClear
                  name="island_id"
                  value={values?.island_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("island_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) => setSearchIsland(event.target.value)}
                    />
                  )}
                  disabled={values?.country_id === null ? true : false}
                >
                  {ddsIsland?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.name}
                      </option>
                    );
                  })}
                </Select>
                {errors.island_id && touched.island_id && (
                  <ValidationError text={errors.island_id} />
                )}
              </Box>
            </Box>

            {/* province */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_PROVINCE")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.PROVINCE}
                  onClear={() => handleClear("province_id")}
                  name="province_id"
                  size="large"
                  onBlur={handleBlur}
                  disabled={values?.country_id ? false : true}
                  value={values?.province_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("province_id", value, opt)
                  }
                  showSearch={false}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) => setSearchProvince(event.target.value)}
                    />
                  )}
                >
                  {ddsProvince?.map((value, index) => {
                    return (
                      <Select.Option value={value.id} key={value.id}>
                        {value.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.province_id && touched.province_id && (
                  <ValidationError text={errors.province_id} />
                )}
              </Box>
            </Box>

            {/* district */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_DISTRICT")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.DISTRICT}
                  name="district_id"
                  showSearch={false}
                  onBlur={handleBlur}
                  onClear={() => handleClear("district_id")}
                  size="large"
                  disabled={values?.province_id ? false : true}
                  value={values?.district_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("district_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) => setSearchDistrict(event.target.value)}
                    />
                  )}
                >
                  {ddsDistrict?.map((value, index) => {
                    return (
                      <Select.Option value={value.id} key={index.toString()}>
                        {value.name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.district_id && touched.district_id && (
                  <ValidationError text={errors.district_id} />
                )}
              </Box>
            </Box>

            {/* sub district */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_SUB_DISTRICT")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.SUB_DISTRICT}
                  size="large"
                  name="sub_district_id"
                  onClear={() => handleClear("sub_district_id")}
                  disabled={values?.district_id ? false : true}
                  value={values?.sub_district_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("sub_district_id", value, opt)
                  }
                  showSearch={false}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) =>
                        setSearchSubDistrict(event.target.value)
                      }
                    />
                  )}
                >
                  {ddsSubDistrict?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.name}
                      </option>
                    );
                  })}
                </Select>
                {errors.sub_district_id && touched.sub_district_id && (
                  <ValidationError text={errors.sub_district_id} />
                )}
              </Box>
            </Box>

            {/* urban village */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_URBAN_VILLAGE")}</Text>
              </Box>
              <Box>
                <Select
                  showSearch={false}
                  id={ID_DISTRIBUTOR.ADD.URBAN_VILLAGE}
                  style={inputTheme}
                  onClear={() => handleClear("urban_village_id")}
                  name="urban_village_id"
                  disabled={values?.sub_district_id ? false : true}
                  value={values?.urban_village_id}
                  size="large"
                  onChange={(value, opt) =>
                    handleChangeSelect("urban_village_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) =>
                        setSearchUrbanVillage(event.target.value)
                      }
                    />
                  )}
                >
                  {ddsUrbanVillage?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.name}
                      </option>
                    );
                  })}
                </Select>
                {errors.urban_village_id && touched.urban_village_id && (
                  <ValidationError text={errors.urban_village_id} />
                )}
              </Box>
            </Box>
            {/* postal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_POSTAL_CODE")}</Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  showSearch={false}
                  disabled={values.urban_village_id ? false : true}
                  name="postal_code_id"
                  value={values?.postal_code_id}
                  id={ID_DISTRIBUTOR.ADD.POSTAL_CODE}
                  onClear={() => handleClear("postal_code_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("postal_code_id", value, opt)
                  }
                  size="large"
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(event) =>
                        setSearchPostalCode(event.target.value)
                      }
                    />
                  )}
                >
                  {ddsPostalCode?.map((value, index) => {
                    return (
                      <option value={value.id} key={index.toString()}>
                        {value.code}
                      </option>
                    );
                  })}
                </Select>
                {errors.postal_code_id && touched.postal_code_id && (
                  <ValidationError text={errors.district_id} />
                )}
              </Box>
            </Box>
            {/* hamlet */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_HAMLET")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  name="hamlet"
                  id={ID_DISTRIBUTOR.ADD.HAMLET}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.hamlet}
                />
                {errors.hamlet && touched.hamlet && (
                  <ValidationError text={errors.hamlet} />
                )}
              </Box>
            </Box>
            {/* neighborhood */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_NEIGHBOURHOOD")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  size="large"
                  name="neighbourhood"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  id={ID_DISTRIBUTOR.ADD.NEIGHBOURHOOD}
                  value={values?.neighbourhood}
                />
                {errors.neighbourhood && touched.neighbourhood && (
                  <ValidationError text={errors.neighbourhood} />
                )}
              </Box>
            </Box>
            {/* Longitute */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_LONGITUTE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.LONGITUDE}
                  size="large"
                  name="longitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.longitude}
                />
                {errors.longitude && touched.longitude && (
                  <ValidationError text={errors.longitude} />
                )}
              </Box>
            </Box>
            {/* Latitute */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("DISTRIBUTOR:FIELD_LATITUTE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_DISTRIBUTOR.ADD.LATITUDE}
                  size="large"
                  name="latitude"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.latitude}
                />
                {errors.latitude && touched.latitude && (
                  <ValidationError text={errors.latitude} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of right side */}
        </Box>
        <Box display="flex" marginTop={10} float="right" paddingBottom="64px">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.DISTRIBUTOR.LIST}
            id={ID_DISTRIBUTOR.ADD.BTN_CANCEL}
            text={t("COMMON:BUTTON_CANCEL")}
            onClick={handleCancel}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_DISTRIBUTOR.ADD.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={resp}
          isOpen={modalOpen}
          onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
          icon={err ? <UnSuccess /> : <SavedSuccess />}
        />
      </form>
    </>
  );
};

export default BodyCompanyProfile;
