import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { Typography } from "antd";

// import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  inputMultiTheme,
  inputTheme,
} from "../../../../../constant/themeConstant";
import DropDownCustom from "../../../../../components/DropDownCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import {
  dayName,
  selectFrequency,
  selectStatus,
} from "../../../../../constant/selectOptConstant";
import { useFormik } from "formik";
import { addBklMappingSchema } from "./bklMappingSchema";
import {
  addBklMapping,
  getListCustomer,
  getListCustomerDDS,
  getListDistributor,
  getListPrincipal,
  getListStore,
  getListStoreDDS,
} from "../../../../../services/main/bklMappingReducer";
import StringUtil from "../../../../../utils/stringUtil";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { Redirect } from "react-router-dom";
import { ID_BKL_MAPPING } from "../../../../../constant/idConstant";
const AddBKLMapping = () => {
  const {
    Text,
    //  Link
  } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageUnlimited, setPageUnlimited] = useState(-99);
  const [limitUnlimited, setLimitUnlimited] = useState(-99);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);

  // Code(for disabled input)
  const [principalCode, setPrincipalCode] = useState("");
  // const [productCategoryCode, setproductCategoryCode] = useState("");
  // const [distributorCode, setDistributorCode] = useState("");
  // const [customerCode, setCustomerCode] = useState("");
  // const [storeCode, setStoreCode] = useState("");
  // const [storeSupplierCode, setStoreSupplierCode] = useState("");
  // const [productPrincipalCode, setProductPrincipalCode] = useState("");
  // const [productCustomerCode, setProductCustomerCode] = useState("");
  // const [distributorId, setDistributorId] = useState([]);
  const [idCust, setIdCust] = useState(null);

  const handleClear = (name) => {
    switch (name) {
      case "customer_id":
        setFieldValue("store_id", null);
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "distributor_id":
        setFieldValue("distributor_id", value);
        setFieldValue("distributor_code", option?.code);
        break;
      case "customer_id":
        setFieldValue("customer_id", value);
        setIdCust(value);
        setFieldValue("customer_code", option?.code);
        setFieldValue("store_id", null);
        setFieldValue("store_code", "");
        break;
      case "store_id":
        setFieldValue("store_id", value);
        setFieldValue("store_code", option?.code);
        break;
      case "frequency":
        setFieldValue("frequency", value);
        break;
      case "area_code":
        setFieldValue("area_code", value);
        break;
      case "id_depo":
        setFieldValue("id_depo", value);
        break;
      case "store_sap_id":
        setFieldValue("store_sap_id", value);
        break;
      case "store_sap_name":
        setFieldValue("store_sap_name", value);
        break;
      case "open_po":
        // if (value === "Semua") {
        //   setFieldValue([
        //     "Senin",
        //     "Selasa",
        //     "Rabu",
        //     "Kamis",
        //     "Jumat",
        //     "Sabtu",
        //     "Minggu",
        //   ]);
        // }
        if (value?.length <= values?.frequency) {
          setFieldValue("open_po", value);
        }
        break;
      case "jwk_depo":
        if (value?.length <= values?.frequency) {
          setFieldValue("jwk_depo", value);
        }
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };

  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const filteredDistributor = dataDistributor?.filter((value) => {
    return value.status === "A";
  });
  const fetchDataDistributor = () => {
    const page = pageUnlimited;
    const limit = limitUnlimited;
    const updated_at_start = "2000-01-01T00:00:00Z";
    const params = { page, limit, updated_at_start };
    dispatch(getListDistributor(params)).then((response) => {
      setDataDistributor(response.payload.response);
    });
  };
  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };
  // store
  const [dataStore, setDataStore] = useState([]);
  const [filterStore, setfilterStore] = useState("");
  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = `customer_id eq ${values?.customer_id}`;
    const params = values?.customer_id
      ? { page, limit, filter }
      : { page, limit };
    if (values?.customer_id === null) {
      setDataStore([]);
    } else {
      dispatch(getListStoreDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStore([...dataStore, ...resp]);
      });
    }
  };
  const fetchSearchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      store_name: `like ${filterStore ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (values?.customer_id === null) {
      setDataStore([]);
    } else {
      dispatch(getListStoreDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };
  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };
  const onSubmit = () => {
    dispatch(addBklMapping(values)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: null,
      customer_code: "",
      store_id: null,
      store_code: "",
      distributor_id: null,
      distributor_code: "",
      area_code: null,
      distribution_center: "",
      store_sap_id: "",
      store_sap_name: "",
      frequency: null,
      open_po: [],
      jwk_depo: [],
      id_depo: "",
      status: "",
    },
    validationSchema: addBklMappingSchema(t),
    onSubmit,
  });
  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);
  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, []);

  // customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, values?.customer_id]);

  useEffect(() => {
    fetchSearchStore();
  }, [filterStore]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.BKL_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("BKL_MAPPING:ADD_BKL_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("BKL_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("BKL_MAPPING:ADD_BKL_MAPPING")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* principal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.ADD.PRINCIPAL_CODE}
                  disabled
                  value={principalCode ? principalCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Principal Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_BKL_MAPPING.ADD.PRINCIPAL_NAME}
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.ADD.CUSTOMER_CODE}
                  disabled
                  value={values?.customer_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_BKL_MAPPING.ADD.CUSTOMER_NAME}
                  onPopupScroll={handleScrollCustomer}
                  allowClear
                  onClear={() => handleClear("customer_id")}
                  value={values?.customer_id}
                  name="customer_id"
                  onBlur={handleBlur("customer_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataCustomer &&
                    dataCustomer?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          name={data.name}
                          code={data.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* store code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_BKL_MAPPING.ADD.STORE_CODE}
                  style={inputTheme}
                  disabled
                  value={values?.store_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Store Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_BKL_MAPPING.ADD.STORE_NAME}
                  onPopupScroll={handleScrollStore}
                  allowClear
                  value={values?.store_id}
                  name="store_id"
                  onBlur={handleBlur("store_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterStore(e.target.value)}
                    />
                  )}
                >
                  {dataStore &&
                    dataStore?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.store_name}
                          code={data.store_code}
                        >
                          {data.store_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* distribution centre  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_DISTRIBUTION_CENTRE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="distribution_center"
                  style={inputTheme}
                  value={values?.distribution_center}
                  onBlur={handleBlur("distribution_center")}
                  onChange={handleChange}
                  id={ID_BKL_MAPPING.ADD.DISTRIBUTION_CENTRE}
                />
                {errors.distribution_center && touched.distribution_center && (
                  <ValidationError text={errors.distribution_center} />
                )}
              </Box>
            </Box>
            {/* Frequency  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_FREQUENCY")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  id={ID_BKL_MAPPING.ADD.FREQUENCY}
                  size="large"
                  allowClear
                  value={values?.frequency}
                  name="frequency"
                  onBlur={handleBlur("frequency")}
                  onChange={(value, opt) =>
                    handleChangeSelect("frequency", value, opt)
                  }
                >
                  {selectFrequency.map((value, index) => {
                    return (
                      <Select.Option value={value} key={index.toString()}>
                        {value}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.frequency && touched.frequency && (
                  <ValidationError text={errors.frequency} />
                )}
              </Box>
            </Box>
            {/* Area Code  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_AREA_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="area_code"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.ADD.AREA_CODE}
                  value={values?.area_code}
                  onBlur={handleBlur("area_code")}
                  onChange={handleChange}
                />
                {errors.area_code && touched.area_code && (
                  <ValidationError text={errors.area_code} />
                )}
              </Box>
            </Box>
            {/* Depo Id  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_DEPO_ID")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="id_depo"
                  style={inputTheme}
                  value={values?.id_depo}
                  onBlur={handleBlur("id_depo")}
                  onChange={handleChange}
                  id={ID_BKL_MAPPING.ADD.DEPO_ID}
                />
                {errors.id_depo && touched.id_depo && (
                  <ValidationError text={errors.id_depo} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* Distributor Code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_BKL_MAPPING.ADD.DISTRIBUTOR_CODE}
                  style={inputTheme}
                  disabled
                  value={values?.distributor_code}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* distributor name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_BKL_MAPPING.ADD.DISTRIBUTOR_NAME}
                  allowClear
                  value={values?.distributor_id}
                  name="distributor_id"
                  onBlur={handleBlur("distributor_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                >
                  {filteredDistributor &&
                    filteredDistributor?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.id}
                          name={data.name}
                          code={data.distributor_code}
                          principalid={data.principal_id}
                          principalname={data.principal_name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && touched.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>
            {/* store sap id */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_STORE_SAP_ID")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="store_sap_id"
                  id={ID_BKL_MAPPING.ADD.STORE_SAP_ID}
                  style={inputTheme}
                  value={values?.store_sap_id}
                  onBlur={handleBlur("store_sap_id")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.store_sap_id && touched.store_sap_id && (
                  <ValidationError text={errors.store_sap_id} />
                )}
              </Box>
            </Box>
            {/* store sap name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("BKL_MAPPING:FIELD_STORE_SAP_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  name="store_sap_name"
                  style={inputTheme}
                  id={ID_BKL_MAPPING.ADD.STORE_SAP_NAME}
                  value={values?.store_sap_name}
                  onBlur={handleBlur("store_sap_name")}
                  onChange={handleChange}
                  // id="tsl-UserSeller-ProductPrincipal-AddProductPrincipal-Barcode"
                />
                {errors.store_sap_name && touched.store_sap_name && (
                  <ValidationError text={errors.store_sap_name} />
                )}
              </Box>
            </Box>
            {/* Open PO  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("BKL_MAPPING:FIELD_OPEN_PO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputMultiTheme}
                  size="large"
                  showArrow
                  allowClear
                  mode="multiple"
                  id={ID_BKL_MAPPING.ADD.OPEN_PO}
                  disabled={
                    values?.frequency && values.frequency > 0 ? false : true
                  }
                  maxTagCount={2}
                  value={values?.open_po}
                  name="open_po"
                  onBlur={handleBlur("open_po")}
                  onChange={(value, opt) =>
                    handleChangeSelect("open_po", value, opt)
                  }
                >
                  {dayName?.map((val, index) => {
                    return (
                      <option
                        value={val}
                        key={index.toString()}
                        // disabled={values?.open_po?.length >= values?.frequency}
                        className={
                          values?.open_po?.length >= values?.frequency
                            ? "ant-select-item-option-custom"
                            : "ant-select-item-option"
                        }
                      >
                        {val}
                      </option>
                    );
                  })}
                </Select>
                {errors.open_po && touched.open_po && (
                  <ValidationError text={errors.open_po} />
                )}
              </Box>
            </Box>
            {/* Jadwal Kirim Depo  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("BKL_MAPPING:FIELD_JADWAL_KIRIM_DEPO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputMultiTheme}
                  size="large"
                  id={ID_BKL_MAPPING.ADD.DELIVERY}
                  allowClear
                  showArrow
                  maxTagCount={2}
                  mode="multiple"
                  disabled={
                    values?.frequency && values.frequency > 0 ? false : true
                  }
                  value={values?.jwk_depo}
                  name="jadwal_kirim_depo"
                  onBlur={handleBlur("jwk_depo")}
                  onChange={(value, opt) =>
                    handleChangeSelect("jwk_depo", value, opt)
                  }
                >
                  {dayName?.map((val, index) => {
                    return (
                      <option
                        value={val}
                        key={index.toString()}
                        // disabled={values?.jwk_depo?.length >= values?.frequency}
                        className={
                          values?.jwk_depo?.length >= values?.frequency
                            ? "ant-select-item-option-custom"
                            : "ant-select-item-option"
                        }
                      >
                        {val}
                      </option>
                    );
                  })}
                </Select>
                {errors.jwk_depo && touched.jwk_depo && (
                  <ValidationError text={errors.jwk_depo} />
                )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("BKL_MAPPING:FIELD_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  id={ID_BKL_MAPPING.ADD.STATUS}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.BKL_MAPPING.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_BKL_MAPPING.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_BKL_MAPPING.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddBKLMapping;
