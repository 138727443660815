import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  inputMultiTheme,
  inputTheme,
} from "../../../../../constant/themeConstant";
import DropDownCustom from "../../../../../components/DropDownCustom";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  detailProductMapping,
  editProductMapping,
  getListCSMForDistributor,
  getListCustomer,
  getListCustomerDDS,
  getListPrincipal,
  getListPrincipalDDS,
  getListProductPrincipal,
  getListProductPrincipalDDS,
  getListStoreOption,
  getListStoreOptionDDS,
  getListWarehouse,
  getListWarehouseDDS,
} from "../../../../../services/main/productMappingReducer";
import { useFormik } from "formik";
import { editProductMappingSchema } from "./productMappingSchema";
import StringUtil from "../../../../../utils/stringUtil";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { Redirect, useLocation } from "react-router-dom";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import { useWithInstanceListAndInitiate } from "../../../../../services/serviceUtil";
import { useCallback } from "react";
import { ID_PRODUCT_MAPPING } from "../../../../../constant/idConstant";

const EditProductMapping = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const warehouseActive = useSelector(
    (state) => state.productMapping.additional_data_warehouse_id
  );
  const qtyDpdActive = useSelector(
    (state) => state.productMapping.additional_data_qty_dpd
  );
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [dataDetail, setDataDetail] = useState({});
  // page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageUnlimited, setPageUnlimited] = useState(-99);
  const [limitUnlimited, setLimitUnlimited] = useState(-99);
  const [pageProductPrincipal, setPageProductPrincipal] = useState(1);
  const [limitProductPrincipal, setLimitProductPrincipal] = useState(10);
  const [pageProductCustomer, setPageProductCustomer] = useState(1);
  const [limitProductCustomer, setLimitProductCustomer] = useState(10);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  const [limitWarehouse, setLimitWarehouse] = useState(10);
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);

  const initialFilterProductCustomer = useRef("");
  // Code(for disabled input)

  const [principalCode, setPrincipalCode] = useState("");
  // const [productCategoryCode, setproductCategoryCode] = useState("");
  // const [distributorCode, setDistributorCode] = useState("");
  // const [customerCode, setCustomerCode] = useState("");
  // const [storeCode, setStoreCode] = useState("");
  // const [storeSupplierCode, setStoreSupplierCode] = useState("");
  // const [productPrincipalCode, setProductPrincipalCode] = useState("");
  // const [productCustomerCode, setProductCustomerCode] = useState("");
  // const [distributorId, setDistributorId] = useState([]);

  //new hooks
  const {
    limit,
    list: listProductCustomer,
    isLoading: isLoadingProductCustomer,
    setNextPage,
    setFilter,
    resetAllState,
  } = useWithInstanceListAndInitiate({
    isOption: true,
    skipFirstRun: true,
    pathInitiate: "product-customer/initiateGetList",
    pathList: "product-customer-dds",
  });

  const handleClear = (name) => {
    switch (name) {
      case "customer_id":
        setFieldValue("customer_code", null);
        setFieldValue("customer_id", {
          value: null,
          label: "",
        });
        setFieldValue("distributor_id", {
          value: null,
          label: "",
        });
        setFieldValue("distributor_code", "");
        setFieldValue("store_id", {
          value: null,
          label: "",
        });
        setFieldValue("store_code", "");
        break;
      case "distributor_id":
        setFieldValue("distributor_id", {
          value: null,
          label: "",
        });
        setFieldValue("distributor_code", "");
        setFieldValue("store_id", {
          value: null,
          label: "",
        });
        setFieldValue("store_code", "");
        break;
      case "store_id":
        setFieldValue("store_id", {
          value: null,
          label: "",
        });
        setFieldValue("store_code", "");
        break;
      case "product_principal_id":
        setFieldValue("product_principal_code", "");
        break;
      case "product_customer_id":
        setFieldValue("product_customer_code", "");
        break;
      case "warehouse_id":
        setFieldValue("warehouse_name", "");
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "customer_id":
        if (value) {
          // setIsCustomerNew(true);
          const filter = StringUtil.formatFilter({
            product_name: `like ${filterProductCustomer ?? ""}`,
            principal_id: `eq ${values.principal_id.value ?? ""}`,
            customer_id: `eq ${value ?? ""}`,
          });
          setFilter(filter);
          setDataProductCustomer([]);
          setPageProductCustomer(1);
        } else {
          resetAllState();
        }
        setFieldValue("customer_id", {
          value: value,
          label: option?.children,
        });
        setFieldValue("customer_code", option?.code);
        setFieldValue("distributor_id", {
          value: null,
          label: "",
        });
        setFieldValue("distributor_code", "");
        setFieldValue("store_id", {
          value: null,
          label: "",
        });
        setFieldValue("store_code", "");
        setFieldValue("product_customer_id", {
          value: null,
          label: "",
        });
        setFieldValue("product_customer_code", "");

        setIdCust(value);
        break;
      case "distributor_id":
        setFieldValue("distributor_id", {
          value: value,
          label: option?.children,
        });
        setFieldValue("store_id", {
          value: null,
          label: "",
        });
        setFieldValue("store_code", "");
        setFieldValue("distributor_code", option?.code);
        break;
      case "store_id":
        setFieldValue("store_id", {
          value: value,
          label: option?.children,
        });
        setFieldValue("store_code", option?.code);
        break;
      case "product_principal_id":
        setFieldValue("product_principal_id", {
          value: value,
          label: option?.children,
        });
        setFieldValue("product_principal_code", option?.code);
        break;
      case "product_customer_id":
        setFieldValue("product_customer_id", {
          value: value,
          label: option?.children,
        });
        setFieldValue("product_customer_code", option?.code);
        break;
      case "status":
        setFieldValue("status", value);
        break;
      case "warehouse_id":
        setFieldValue("warehouse_id", {
          value: value,
          label: option?.code,
        });
        setFieldValue("warehouse_name", option?.name);
        break;
      default:
        break;
    }
  };
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id?.value ?? ""}`,
    });
    const params = { page, limit, filter };
    if (
      values?.customer_id?.value === null ||
      values?.customer_id?.value === undefined
    ) {
      setDataDistributor([]);
    } else {
      dispatch(getListCSMForDistributor(params)).then((response) => {
        setDataDistributor(response.payload.response);
      });
    }
  };
  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };
  // store
  const [dataStore, setDataStore] = useState([]);
  const [filterStore, setfilterStore] = useState("");
  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id.value ?? ""}`,
      distributor_id: `eq ${values?.distributor_id?.value ?? ""}`,
    });

    const params = { page, limit, filter };
    if (
      values?.distributor_id?.value === null ||
      values?.distributor_id?.value === undefined
    ) {
      setDataStore([]);
    } else {
      dispatch(getListCSMForDistributor(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStore([...dataStore, ...resp]);
      });
    }
  };
  const fetchSearchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      store_name: `like ${filterStore ?? ""}`,
      customer_id: `eq ${values?.customer_id?.value ?? ""}`,
    });
    const params = { page, limit, filter };
    if (values?.customer_id?.value === null) {
      setDataStore(null);
    } else {
      dispatch(getListStoreOptionDDS(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };
  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };

  // Product Principal
  const [dataProductPrincipal, setDataProductPrincipal] = useState([]);
  const fetchDataProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const params = { page, limit };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductPrincipal([...dataProductPrincipal, ...resp]);
    });
  };
  const [filterProductPrincipal, setfilterProductPrincipal] = useState("");
  const fetchSearchProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const filter = `product_code like ${filterProductPrincipal}`;
    const params =
      filterProductPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListProductPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductPrincipal(1);
      setDataProductPrincipal(response.payload.response);
    });
  };

  const handleScrollProductPrincipal = () => {
    setPageProductPrincipal(pageProductPrincipal + 1);
  };
  // Product Customer
  const [dataProductCustomer, setDataProductCustomer] = useState([]);
  const [idCust, setIdCust] = useState(null);
  const fetchDataProductCustomer = useCallback(() => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId ?? ""}`,
      customer_id: `eq ${dataDetail?.product_customer_id ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    setFilter(filter);
    // if (!values?.customer_id.value) {
    //   setDataProductCustomer([]);
    // } else {
    //   dispatch(getListProductCustomer(params)).then((response) => {
    //     if (!response.payload.ok) return;
    //     const resp = response.payload.response;
    //     if (!resp && pageProductCustomer === 1) {
    //       setDataProductCustomer([]);
    //     }
    //     if (resp && pageProductCustomer !== 1) {
    //       // kalo response true dan page nya gak di 1 (ada data dan bisa discroll maka append)
    //       setDataProductCustomer([]);
    //       // setDataProductCustomer([...resp]);
    //       setDataProductCustomer([...dataProductCustomer, ...resp]);
    //     }
    //     if (pageProductCustomer === 1 && resp) {
    //       // jika filter tidak sama dengan initial filter awal maka set kaya fetch filter
    //       setDataProductCustomer([]);
    //       // setPageProductCustomer(1);
    //       setDataProductCustomer([...resp]);
    //     }
    //     if (
    //       resp &&
    //       pageProductCustomer === 1 &&
    //       filterProductCustomer !== initialFilterProductCustomer
    //     ) {
    //       fetchSearchProductCustomer();
    //     }
    //     if (
    //       resp &&
    //       pageProductCustomer !== 1 &&
    //       filterProductCustomer !== initialFilterProductCustomer
    //     ) {
    //       fetchSearchProductCustomer();
    //     }
    //   });
    // }
  }, [dataDetail]);
  const [filterProductCustomer, setfilterProductCustomer] = useState("");
  const fetchSearchProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      product_code: `like ${filterProductCustomer ?? ""}`,
      principal_id: `eq ${values?.principal_id.value ?? ""}`,
      customer_id: `eq ${values?.customer_id.value ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (!values?.customer_id.value) return;
    setFilter(filter);
  };

  const handleScrollProductCustomer = () => {
    // setPageProductCustomer(pageProductCustomer + 1);
    setNextPage();
  };

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const fetchWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${filterWarehouse ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouseDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const [filterWarehouse, setfilterWarehouse] = useState("");
  const fetchSearchWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${filterWarehouse ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouseDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageWarehouse(1);
      setDataWarehouse(response.payload.response);
    });
  };

  const handleScrollWarehouse = () => {
    setPageWarehouse(pageWarehouse + 1);
  };

  const fetchDataDetail = () => {
    setIsLoading(true);
    dispatch(detailProductMapping(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(response.payload.response.updated_at);
        setDataDetail(response.payload.response);
      }
    });
    setIsLoading(false);
  };
  const onSubmit = () => {
    const productId = location.state.id;
    const payload = {
      ...values,
      additional_data: {
        warehouse_id: values?.warehouse_id ? values?.warehouse_id : 0,
        qty_dpd: Number(values?.qty_dpd),
      },
      productId,
    };
    dispatch(editProductMapping(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: {
        value: dataDetail?.customer_id,
        label: dataDetail?.customer_name,
      },
      customer_code: dataDetail?.customer_code,
      store_id: {
        value: dataDetail?.store_id,
        label: dataDetail?.store_name,
      },
      store_code: dataDetail?.store_code,
      distributor_id: {
        value: dataDetail?.distributor_id,
        label: dataDetail?.distirbutor_name,
      },
      distributor_code: dataDetail?.distributor_code,
      product_principal_id: {
        value: dataDetail?.product_principal_id,
        label: dataDetail?.principal_product_name,
      },
      product_principal_code: dataDetail?.principal_product_code,
      barcode: null,
      warehouse_id: {
        value: dataDetail?.additional_data?.warehouse_id
          ? dataDetail?.additional_data?.warehouse_id
          : null,
        label: dataDetail?.additional_data?.warehouse_code,
      },
      warehouse_name: dataDetail?.additional_data?.warehouse_name,
      qty_dpd: dataDetail?.additional_data?.qty_dpd,
      product_customer_id: {
        value: dataDetail?.product_customer_id,
        label: dataDetail?.product_customer_name,
      },
      product_customer_code: dataDetail?.product_customer_code,
      status: dataDetail?.status,
      updatedAt: updatedAt,
      uom_1: dataDetail?.uom_1,
      uom_2: dataDetail?.uom_2,
      uom_3: dataDetail?.uom_3,
      uom_4: dataDetail?.uom_4,
      conversion_1_to_4: dataDetail?.conv_1_to_4,
      conversion_2_to_4: dataDetail?.conv_2_to_4,
      conversion_3_to_4: dataDetail?.conv_3_to_4,
      order_unit: dataDetail?.order_unit,
    },
    validationSchema: editProductMappingSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  });
  //use effect once
  useEffect(() => {
    fetchDataDetail();
  }, []);

  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);

  // customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor, values?.customer_id.value, values?.distributor_id]);

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, values?.distributor_id]);

  useEffect(() => {
    fetchSearchStore();
  }, [filterStore]);

  // product principal
  useEffect(() => {
    fetchDataProductPrincipal();
  }, [pageProductPrincipal]);
  useEffect(() => {
    fetchSearchProductPrincipal();
  }, [filterProductPrincipal]);

  // product customer
  useEffect(() => {
    fetchDataProductCustomer();
  }, [fetchDataProductCustomer]);

  useEffect(() => {
    fetchSearchProductCustomer();
  }, [filterProductCustomer]);

  // warehouse
  useEffect(() => {
    fetchWarehouse();
  }, [pageWarehouse]);

  useEffect(() => {
    fetchSearchWarehouse();
  }, [filterWarehouse]);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_MAPPING:EDIT_PRODUCT_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_MAPPING:EDIT_PRODUCT_MAPPING")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          marginTop={4}
        >
          <Box>
            <Box
              justifyContent="space-between"
              display="flex"
              borderRadius="10px"
            >
              {/* left side */}
              <Box padding={1}>
                {/* Principal Code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_PRINCIPAL_CODE")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      id={ID_PRODUCT_MAPPING.EDIT.PRINCIPAL_CODE}
                      style={inputTheme}
                      disabled
                      value={
                        principalCode
                          ? principalCode
                          : dataDetail?.principal_code
                      }
                      background={GRAY_COLOR}
                    />
                  </Box>
                </Box>
                {/* Customer Code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      id={ID_PRODUCT_MAPPING.EDIT.CUSTOMER_CODE}
                      style={inputTheme}
                      disabled
                      value={values?.customer_code}
                      background={GRAY_COLOR}
                    />
                  </Box>
                </Box>
                {/* Distributor Code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={values?.distributor_code}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.EDIT.DISTRIBUTOR_CODE}
                    />
                  </Box>
                </Box>
                {/* Store Code*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_STORE_CODE")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={values?.store_code}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.EDIT.STORE_ID}
                    />
                  </Box>
                </Box>
                {/* Customer Product Code*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      id={ID_PRODUCT_MAPPING.EDIT.CUSTOMER_PRODUCT_CODE}
                      value={values?.product_customer_code}
                      background={GRAY_COLOR}
                    />
                  </Box>
                </Box>
                {/* Principal Product Code*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_PRODUCT_CODE")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={values?.product_principal_code}
                      background={GRAY_COLOR}
                      id={ID_PRODUCT_MAPPING.EDIT.PRINCIPAL_PRODUCT_CODE}
                    />
                  </Box>
                </Box>

                {/* Warehouse*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_WAREHOUSE")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      disabled
                      value={
                        values.warehouse_name ? values?.warehouse_name : ""
                      }
                      id={ID_PRODUCT_MAPPING.EDIT.WAREHOUSE_CODE}
                      background={GRAY_COLOR}
                    />
                  </Box>
                </Box>

                {/* Qty Dpd  */}
                {qtyDpdActive ? (
                  <Box padding={1} display="flex">
                    <Box width="10rem">
                      <Text overflowWrap="break-word" width="120px">
                        {t("PRODUCT_MAPPING:FIELD_QTY_DPD")}
                      </Text>
                    </Box>
                    <Box>
                      <Input
                        type="text"
                        // placeholder="Isi Jumlah dpd"
                        style={inputTheme}
                        name="qty_dpd"
                        onChange={handleChange}
                        value={values.qty_dpd ? values.qty_dpd : ""}
                        background={GRAY_COLOR}
                        id={ID_PRODUCT_MAPPING.EDIT.QTY_DPD}
                      />
                      {errors.qty_dpd && touched.qty_dpd && (
                        <ValidationError text={errors.qty_dpd} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {/* Order Unit */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_ORDER_UNIT")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.order_unit}
                      name="order_unit"
                      onChange={handleChange}
                    />
                    {errors.order_unit && touched.order_unit && (
                      <ValidationError text={errors.order_unit} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 1*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_1")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.uom_1}
                      name="uom_1"
                      onChange={handleChange}
                    />
                    {errors.uom_1 && touched.uom_1 && (
                      <ValidationError text={errors.uom_1} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 2*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_2")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.uom_2}
                      name="uom_2"
                      onChange={handleChange}
                    />
                    {errors.uom_2 && touched.uom_2 && (
                      <ValidationError text={errors.uom_2} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 3*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_3")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.uom_3}
                      name="uom_3"
                      onChange={handleChange}
                    />
                    {errors.uom_3 && touched.uom_3 && (
                      <ValidationError text={errors.uom_3} />
                    )}
                  </Box>
                </Box>
                {/* Principal UOM 4*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_UOM_4")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.uom_4}
                      name="uom_4"
                      onChange={handleChange}
                    />
                    {errors.uom_4 && touched.uom_4 && (
                      <ValidationError text={errors.uom_4} />
                    )}
                  </Box>
                </Box>
              </Box>
              {/* end of left side  */}

              {/* right side */}
              <Box padding={1} marginRight={8}>
                {/* principal name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_NAME")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      id={ID_PRODUCT_MAPPING.EDIT.PRINCIPAL_NAME}
                      disabled
                      value={principal_name ? principal_name : ""}
                      background={GRAY_COLOR}
                    />
                  </Box>
                </Box>
                {/* customer name  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      style={inputTheme}
                      size="large"
                      onClear={() => handleClear("customer_id")}
                      onPopupScroll={handleScrollCustomer}
                      allowClear
                      id={ID_PRODUCT_MAPPING.EDIT.CUSTOMER_NAME}
                      value={values?.customer_id}
                      name="customer_id"
                      onBlur={handleBlur("customer_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("customer_id", value, opt)
                      }
                      dropdownRender={(menu) => (
                        <DropDownCustom
                          menu={menu}
                          search={(e) => setfilterCustomer(e.target.value)}
                        />
                      )}
                    >
                      {dataCustomer &&
                        dataCustomer?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.id}
                              name={data.name}
                              code={data.customer_code}
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors?.customer_id?.value ? (
                      <ValidationError text={errors?.customer_id?.value} />
                    ) : errors?.customer_id ? (
                      <ValidationError text={errors?.customer_id} />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                {/* distributor name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_NAME")}</Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      style={inputMultiTheme}
                      id={ID_PRODUCT_MAPPING.EDIT.DISTRIBUTOR_NAME}
                      size="large"
                      allowClear
                      onClear={() => handleClear("distributor_id")}
                      showArrow
                      maxTagCount={"responsive"}
                      value={values?.distributor_id}
                      name="distributor_id"
                      onBlur={handleBlur("distributor_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("distributor_id", value, opt)
                      }
                    >
                      {dataDistributor &&
                        dataDistributor?.map((data, index) => {
                          return (
                            <option
                              key={index.toString()}
                              value={data.distributor_id}
                              // onMouseOver={}
                              name={data.distributor_name}
                              code={data.distributor_code}
                              fulldata={data}
                            >
                              {data.distributor_name}
                            </option>

                            // <Select.Option
                            //   key={index.toString()}
                            //   value={data.distributor_id}
                            //   name={data.distributor_name}
                            //   code={data.distributor_code}
                            //   fulldata={data}
                            // >
                            //   {data.distributor_name}
                            // </Select.Option>
                          );
                        })}
                    </Select>
                    {errors?.distributor_id?.value &&
                      touched?.distributor_id?.value && (
                        <ValidationError text={errors?.distributor_id?.value} />
                      )}
                  </Box>
                </Box>
                {/* store  name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_STORE_NAME")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      style={inputMultiTheme}
                      id={ID_PRODUCT_MAPPING.EDIT.STORE_NAME}
                      size="large"
                      onPopupScroll={handleScrollStore}
                      allowClear
                      onClear={() => handleClear("store_id")}
                      showArrow
                      maxTagCount={"responsive"}
                      value={values?.store_id}
                      name="store_id"
                      onBlur={handleBlur("store_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("store_id", value, opt)
                      }
                      // dropdownRender={(menu) => (
                      //   <DropDownCustom
                      //     menu={menu}
                      //     search={(e) => setfilterStore(e.target.value)}
                      //   />
                      // )}
                    >
                      {dataStore &&
                        dataStore?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data?.store_id ? data?.store_id : data.id}
                              name={data.store_name}
                              code={data.store_code}
                            >
                              {data.store_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors?.store_id?.value && touched?.store_id?.value && (
                      <ValidationError text={errors?.store_id?.value} />
                    )}
                  </Box>
                </Box>
                {/* customer product name  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      style={inputTheme}
                      // loading={isLoadingProductCustomer}
                      value={values?.product_customer_id}
                      onPopupScroll={handleScrollProductCustomer}
                      size="large"
                      allowClear
                      id={ID_PRODUCT_MAPPING.EDIT.CUSTOMER_PRODUCT_NAME}
                      onClear={() => handleClear("product_customer_id")}
                      onBlur={handleBlur("product_customer_id")}
                      name="product_customer_id"
                      onChange={(value, opt) =>
                        handleChangeSelect("product_customer_id", value, opt)
                      }
                      dropdownRender={(menu) => (
                        <DropDownCustom
                          menu={menu}
                          search={(e) =>
                            setfilterProductCustomer(e.target.value)
                          }
                        />
                      )}
                    >
                      {listProductCustomer &&
                        listProductCustomer?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.id}
                              code={data.product_code}
                            >
                              {data.product_code}
                              {" - "}
                              {data.product_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors?.product_customer_id?.value &&
                      touched?.product_customer_id?.value && (
                        <ValidationError
                          text={errors?.product_customer_id?.value}
                        />
                      )}
                  </Box>
                </Box>
                {/* principal product name */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="120px">
                      {t("PRODUCT_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME")}
                    </Text>
                  </Box>
                  <Box width="12rem">
                    <Select
                      size="large"
                      name="product_principal_id"
                      onPopupScroll={handleScrollProductPrincipal}
                      style={inputTheme}
                      id={ID_PRODUCT_MAPPING.EDIT.PRINCIPAL_PRODUCT_NAME}
                      value={values?.product_principal_id}
                      allowClear
                      onClear={() => handleClear("product_principal_id")}
                      onChange={(value, opt) =>
                        handleChangeSelect("product_principal_id", value, opt)
                      }
                      onBlur={handleBlur("product_principal_id")}
                      dropdownRender={(menu) => (
                        <DropDownCustom
                          menu={menu}
                          search={(e) =>
                            setfilterProductPrincipal(e.target.value)
                          }
                        />
                      )}
                    >
                      {dataProductPrincipal &&
                        dataProductPrincipal?.map((data, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={data.id}
                              principal={data.principal_name}
                              code={data.product_code}
                              title={data.product_code}
                            >
                              {data.product_code}
                              {" - "}
                              {data.product_name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                    {errors?.product_principal_id?.value &&
                      touched?.product_principal_id?.value && (
                        <ValidationError
                          text={errors?.product_principal_id?.value}
                        />
                      )}
                  </Box>
                </Box>
                {/* Warehouse Code*/}
                {warehouseActive ? (
                  <Box padding={1} display="flex">
                    <Box width="10rem">
                      <Text overflowWrap="break-word" width="120px">
                        {t("PRODUCT_MAPPING:FIELD_WAREHOUSE_CODE")}
                      </Text>
                    </Box>
                    <Box width="12rem">
                      <Select
                        style={inputTheme}
                        id={ID_PRODUCT_MAPPING.EDIT.WAREHOUSE_NAME}
                        value={values?.warehouse_id}
                        onPopupScroll={handleScrollWarehouse}
                        size="large"
                        allowClear
                        onClear={() => handleClear("warehouse_id")}
                        onBlur={handleBlur("warehouse_id")}
                        name="warehouse_id"
                        onChange={(value, opt) =>
                          handleChangeSelect("warehouse_id", value, opt)
                        }
                        dropdownRender={(menu) => (
                          <DropDownCustom
                            menu={menu}
                            search={(e) => setfilterWarehouse(e.target.value)}
                          />
                        )}
                      >
                        {dataWarehouse &&
                          dataWarehouse?.map((data, index) => {
                            return (
                              <Select.Option
                                key={index.toString()}
                                value={data.id}
                                code={data.warehouse_code}
                                fulldata={data}
                                name={data.warehouse_name}
                              >
                                {data.warehouse_code}
                                {/* {data.warehouse_code +
                                  " - " +
                                  data.warehouse_name} */}
                              </Select.Option>
                            );
                          })}
                      </Select>
                      {errors.warehouse_id && touched.warehouse_id && (
                        <ValidationError text={errors.warehouse_id} />
                      )}
                    </Box>
                  </Box>
                ) : (
                  ""
                )}
                {/* status */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {t("PRODUCT_MAPPING:FIELD_STATUS")}
                    </Text>
                  </Box>
                  <Box>
                    <Select
                      style={inputTheme}
                      size="large"
                      id={ID_PRODUCT_MAPPING.EDIT.STATUS}
                      name="status"
                      value={values?.status}
                      onBlur={handleBlur("status")}
                      onChange={(value, opt) =>
                        handleChangeSelect("status", value, opt)
                      }
                    >
                      {selectStatus.map((value, index) => {
                        return (
                          <Select.Option value={value.value} key={index}>
                            {t(value.label)}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {errors.status && touched.status && (
                      <ValidationError text={errors.status} />
                    )}
                  </Box>
                </Box>
                {/* Converstion to smallest */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {t("PRODUCT_MAPPING:FIELD_CONVERSION_TO_SMALLEST")}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.conversion_1_to_4}
                      name="conversion_1_to_4"
                      onChange={handleChange}
                    />
                    {errors.conversion_1_to_4 && touched.conversion_1_to_4 && (
                      <ValidationError text={errors.conversion_1_to_4} />
                    )}
                  </Box>
                </Box>
                {/* Converstion to smallest 2 */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {/* {t("PRODUCT_MAPPING:FIELD_CONVERSION_TO_SMALLEST")} */}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.conversion_2_to_4}
                      name="conversion_2_to_4"
                      onChange={handleChange}
                    />
                    {errors.conversion_2_to_4 && touched.conversion_2_to_4 && (
                      <ValidationError text={errors.conversion_2_to_4} />
                    )}
                  </Box>
                </Box>
                {/* Converstion to smallest 3 */}
                <Box padding={1} display="flex">
                  <Box width="10rem">
                    <Text overflowWrap="break-word" width="80px">
                      {/* {t("PRODUCT_MAPPING:FIELD_CONVERSION_TO_SMALLEST")} */}
                    </Text>
                  </Box>
                  <Box>
                    <Input
                      type="text"
                      style={inputTheme}
                      value={values?.conversion_3_to_4}
                      name="conversion_3_to_4"
                      onChange={handleChange}
                    />
                    {errors.conversion_3_to_4 && touched.conversion_3_to_4 && (
                      <ValidationError text={errors.conversion_3_to_4} />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_MAPPING.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_MAPPING.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_MAPPING.EDIT.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EditProductMapping;
