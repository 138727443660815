import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Tag,
  Select as SelectChakra,
  CloseButton,
} from "@chakra-ui/react";
import { Pagination, Table, Select, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import SortButton from "../../../../../components/SortButton";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  LABEL_STATUS_ACTIVE,
  LABEL_STATUS_INACTIVE,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  EditIcon,
  DetailIcon,
  AddIcon,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  DeleteSuccess,
  SavedSuccess,
  UploadIcon,
  UnSuccess,
  ExportIcon,
} from "../../../../../components/icons";
import {
  getInitiateListProductMapping,
  getListDistributorOption,
  getListDistributorOptionDDS,
  getListProductMapping,
  getListStoreOption,
  getListStoreOptionDDS,
  initiateInputProductMapping,
  productMappingAction,
} from "../../../../../services/main/productMappingReducer";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import DropDownCustom from "../../../../../components/DropDownCustom";
import StringUtil from "../../../../../utils/stringUtil";
import ModalImport from "../../../../../components/ModalImport";
import ModalImportPreview from "../../../../../components/ModalImportPreview";
import ModalCustom from "../../../../../components/ModalCustom";
import { typeProductMapping } from "../../../../../constant/importEndPointConstant";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../../utils/importFileUtil";
import FileSaver from "file-saver";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import moment from "moment/moment";
import { ID_PRODUCT_MAPPING } from "../../../../../constant/idConstant";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import { subHours } from "date-fns";
import {
  RenderColorStatus,
  RenderIconStatus,
  RenderStatus,
} from "../../../../../function/RenderStatus";
import { template_product_mapping } from "../../../../../constant/templateDownloadConstant";
import { pageActions } from "../../../../../reducers/pageReducer";
const ListProductMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.productMapping.list);
  const { filters: objectFilter } = useSelector((state) => state.page);
  const { table_loading } = useSelector((state) => state.productMapping);
  // const [objectFilter, setObjectFilter] = useState({
  //   idDistributor: null,
  //   idStore: null,
  //   customerProductCode: "",
  // });
  const [isSubmit, setIsSubmit] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  const [pageDistributorOption, setPageDistributorOption] = useState(1);
  const [limitDistributorOption, setLimitDistributorOption] = useState(10);

  const [pageStoreOption, setPageStoreOption] = useState(1);
  const [limitStoreOption, setLimitStoreOption] = useState(10);

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);
  const [proccessLoading, setProccessLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };

  const handleCancel = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };

  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeProductMapping },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFile(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
    setProccessLoading(false);
  };

  const importConfirm = async () => {
    const object = {
      type: typeProductMapping,
      filename: filename,
      confirm: true,
    };
    const { ok, response } = await confirmFile(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
    setUploadLoading(true);
  };

  const confirmInfo = async (object) => {
    const response = await confirmInfoFile(object, token);
    const totalData = response?.response?.total;
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setFileImport(null);
      setModalOpen(true);
      setErr(true);
      setResp(message);
      setProccessLoading(false);
      setIsModalPreviewOpen(false);
      setUploadLoading(false);
    } else {
      setModalOpen(true);
      setErr(false);
      setResp(message);
      setIsModalPreviewOpen(false);
      setFileImport(null);
      setUploadLoading(false);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
    setUploadLoading(false);
  };

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  //check additional data
  const checkAdditionalData = () => {
    dispatch(initiateInputProductMapping()).then((response) => {
      const dpdActive = response.payload.response?.qty_dpd.active;
      const warehouseActive = response.payload.response?.warehouse_id.active;
      dispatch(productMappingAction.setQtyDpdActive(dpdActive));
      dispatch(productMappingAction.setWarehouseActive(warehouseActive));
    });
  };

  //for filter

  // ini yg buat search nya di distributor
  const [data_Distributor, setData_Distributor] = useState([]);
  const [inputDistributor, setInputDistributor] = useState("");
  const [distributorId, setDistributorId] = useState(
    objectFilter?.idDistributor
  );

  const fetchDataDistributorOption = () => {
    const page = pageDistributorOption;
    const limit = limitDistributorOption;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorOptionDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setData_Distributor([...data_Distributor, ...resp]);
    });
  };
  const fetchDataDistributorSearch = () => {
    const page = pageDistributorOption;
    const limit = limitDistributorOption;
    const filter = `distributor_name like ${inputDistributor}`;
    const params =
      inputDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorOptionDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributorOption(1);
      setData_Distributor(response.payload.response);
    });
  };
  const handleScrollDistributor = () => {
    setPageDistributorOption(pageDistributorOption + 1);
  };
  const handleSearchDistributor = (event) => {
    setInputDistributor(event);
  };

  // ini buat yang store
  const [storeId, setStoreId] = useState(objectFilter?.idStore);
  const [data_Store, setData_Store] = useState([]);
  const [inputStore, setInputStore] = useState("");
  const fetchDataStoreOption = () => {
    const page = pageStoreOption;
    const limit = limitStoreOption;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idStore ?? ""}`,
    });
    const params = { page, limit, filter };
    dispatch(getListStoreOptionDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setData_Store([...data_Store, ...resp]);
    });
  };

  const fetchDataStoreSearch = () => {
    const page = pageStoreOption;
    const limit = limitStoreOption;
    const filter = `store_name like ${inputStore}`;
    const params =
      inputStore === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListStoreOptionDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageStoreOption(1);
      setData_Store(response.payload.response);
    });
  };
  const handleScrollStore = () => {
    setPageStoreOption(pageStoreOption + 1);
  };
  const handleSearchStore = (event) => {
    setInputStore(event);
  };

  /// for filter product mapping keseluruhan
  const [inputCustomerProductCode, setInputCustomerProductCode] = useState("");
  const [form, setForm] = useState(objectFilter?.form);
  const [formStore, setFormStore] = useState(null);
  const [formDistributor, setFormDistributor] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const handleChange = (event) => {
    if (event.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(false);
    }
    // setInputCustomerProductCode(event.target.value);
    setForm(event.target.value);
  };
  const submitOk = () => {
    // const length = inputCustomerProductCode?.length;
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      // setForm(inputCustomerProductCode);
      setPage(1);
      setFormDistributor(distributorId);
      setFormStore(storeId);
      setPopOverOpen(false);
      dispatch(
        pageActions.setFilters({
          idDistributor: distributorId,
          idStore: storeId,
          form: form,
        })
      );
      setIsSubmit(!isSubmit);
    }
    // setObjectFilter({
    //   idDistributor: distributorId,
    //   idStore: storeId,
    //   customerProductCode: inputCustomerProductCode,
    // });
  };

  const resetFilter = () => {
    setForm("");
    setOrder("");
    setInputCustomerProductCode("");
    setIsDisabled(true);
    setInputDistributor("");
    setDistributorId(null);
    setInputStore("");
    setStoreId(null);
    setFormDistributor(null);
    setFormStore(null);
    setPage(1);
    setLimit(10);
    // setObjectFilter({
    //   idDistributor: null,
    //   idStore: null,
    //   customerProductCode: "",
    // });
    dispatch(pageActions.resetFilters());
    setPageDistributorOption(1);
    setPageDistributorOption(1);
    setLimitDistributorOption(10);
    setLimitStoreOption(10);
    setPopOverOpen(false);
    setButtonSearchOrClose("search");
    setIsSubmit(!isSubmit);
  };
  const handleCloseButtonSearch = () => {
    setForm("");
    setInputCustomerProductCode("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
  };
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  // const conditionParamCheck = () => {
  //   const filter = StringUtil.formatFilter({
  //     distributor_id: `eq ${objectFilter.idDistributor}`,
  //     store_id: `eq ${objectFilter.idStore}`,
  //     product_customer_code: `like ${objectFilter.customerProductCode}`,
  //   });
  //   let params;
  //   const paramsFormNull = { page, limit, order };
  //   const paramsFormsNotNull = { page, limit, filter, order };
  //   // kondisi salah
  //   if (!formDistributor) {
  //     params = paramsFormNull;
  //   }
  //   if (!formStore) {
  //     params = paramsFormNull;
  //   }
  //   if (form === "") {
  //     params = paramsFormNull;
  //   }
  //   if (!formDistributor && !formStore) {
  //     params = paramsFormNull;
  //   }
  //   if (!formDistributor && form === "") {
  //     params = paramsFormNull;
  //   }
  //   if (!formStore && form === "") {
  //     params = paramsFormNull;
  //   }
  //   if (!formDistributor && !formStore && form === "") {
  //     params = paramsFormNull;
  //   }
  //   // kondisi benar
  //   // if (formDistributor) {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   // if (formStore) {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   // if (form !== "") {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   // if (formDistributor && formStore) {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   // if (formDistributor && form !== "") {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   // if (formStore && form !== "") {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   // if (!formDistributor && formStore && form !== "") {
  //   //   params = paramsFormsNotNull;
  //   // }
  //   params = paramsFormsNotNull;
  //   return params;
  // };

  const fetchData = async () => {
    const filter = StringUtil.formatFilter({
      distributor_id: `eq ${objectFilter?.idDistributor}`,
      store_id: `eq ${objectFilter?.idStore}`,
      product_customer_code: `like ${objectFilter?.form}`,
    });
    // const params = conditionParamCheck(page, limit, order);
    const params = {
      page,
      limit,
      filter,
      order,
    };
    dispatch(getListProductMapping(params));
  };
  const fetchInitiate = () => {
    const filter = StringUtil.formatFilter({
      distributor_id: `eq ${objectFilter?.idDistributor}`,
      store_id: `eq ${objectFilter?.idStore}`,
      product_customer_code: `like ${objectFilter?.form}`,
    });
    // const params = conditionParamCheck(page, limit, order);
    // const params = !formStore ? paramsFormNull : paramsFormsNotNull;
    const params = { page, limit, filter, order };
    dispatch(getInitiateListProductMapping(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };

  useEffect(() => {
    fetchInitiate();
    fetchData();
  }, [
    page,
    limit,
    token,
    order,
    dataChanged,
    // formDistributor,
    // formStore,
    isSubmit,
    isValidateAndConfirm,
    dispatch,
  ]);

  useEffect(() => {
    fetchDataDistributorOption();
  }, [pageDistributorOption]);

  useEffect(() => {
    fetchDataDistributorSearch();
  }, [inputDistributor]);

  useEffect(() => {
    fetchDataStoreOption();
  }, [pageStoreOption]);

  useEffect(() => {
    fetchDataStoreSearch();
  }, [inputStore]);

  // ini use effect untuk fetch data distributor
  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport?.map((item, indexData) => {
      return columnImport.reduce(
        (prev, accumulator, index) => {
          prev[accumulator] = item[index];
          return prev;
        },
        { key: indexData }
      );
    });
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_code: item.distributor_code,
      distributor_name: item.distributor_name,
      farmindo_store: item.store_code,
      store_name: item.store_name,
      product_customer_code: item.product_customer_code,
      principal_product_code: item.principal_product_code,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      field_1 : item.field_1,
      field_2 : item.field_2,
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_MAPPING.EDIT,
              state: item,
            }}
            text={<EditIcon />}
            onClick={checkAdditionalData}
            backgroundColor="transparent"
            id={ID_PRODUCT_MAPPING.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_MAPPING.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
            id={ID_PRODUCT_MAPPING.LIST.BTN_VIEW + "_" + item.id}
          />
        </div>
      ),
    }));
  const renderStatusColumnImport = (value) => {
    if (value.toLowerCase() === "status") {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {/* status  */}
            {t(`PRODUCT_MAPPING:FIELD_${value}`)}
          </Box>
        ),
        dataIndex: value,
        key: value,
        sorter: (a, b) => a.value.localeCompare(b.value),
        sortDirections: ["descend", "ascend"],
        render: (status) => {
          return (
            <>
              <Stack direction="row">
                {status === "" ? (
                  <>
                    <Box marginRight={2}>{RenderIconStatus(status)}</Box>
                    <Box color={RenderColorStatus(status)}>
                      {RenderStatus(status)}
                    </Box>
                  </>
                ) : (
                  <>
                    <Tag
                      rounded="full"
                      px="2"
                      display="flex"
                      alignItems="center"
                    >
                      <Box marginRight={2}>{RenderIconStatus(status)}</Box>
                      <Box color={RenderColorStatus(status)}>
                        {RenderStatus(status)}
                      </Box>
                    </Tag>
                  </>
                )}
              </Stack>
            </>
          );
        },
      };
    } else {
      return {
        title: (
          <Box display="flex" alignItems="center" cursor="pointer">
            {t(`PRODUCT_MAPPING:FIELD_${value}`)}
          </Box>
        ),
        dataIndex: value,
        key: value,
      };
    }
  };
  const columnsImport = columnImport?.map((value) => {
    return renderStatusColumnImport(value);
  });
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}
        </Box>
      ),
      dataIndex: "distributor_code",
      key: "distributor_code",
      sorter: (a, b) => a.distributor_code.localeCompare(b.distributor_code),
      sortDirections: ["descend", "ascend"],
      width: 128,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name),
      sortDirections: ["descend", "ascend"],
      width: 140,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "farmindo_store",
      key: "farmindo_store",
      sorter: (a, b) => a.farmindo_store.localeCompare(b.farmindo_store),
      sortDirections: ["descend", "ascend"],
      width: 100,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name.localeCompare(b.store_name),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING_HO:FIELD_PRINCIPAL_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "principal_product_code",
      key: "principal_product_code",
      sorter: (a, b) =>
        a.principal_product_code.localeCompare(b.principal_product_code),
      sortDirections: ["descend", "ascend"],
      width: 175,
      ellipsis: true,
    },
    {
      title: (
        <Box
          display="flex"
          alignItems="center"
          cursor="pointer"
          style={{
            width: "155px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {t("PRODUCT_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "product_customer_code",
      key: "product_customer_code",
      width: 183,
      sorter: (a, b) =>
        a.product_customer_code.localeCompare(b.product_customer_code),
      sortDirections: ["descend", "ascend"],
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_QTY_DPD")}
        </Box>
      ),
      dataIndex: "field_1",
      key: "field_1",
      sorter: (a, b) => a.field_1 - b.field_1,
      sortDirections: ["descend", "ascend"],
      width: 125,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_WAREHOUSE_CODE")}
        </Box>
      ),
      dataIndex: "field_2",
      key: "field_2",
      sorter: (a, b) => a.field_2 - b.field_2,
      sortDirections: ["descend", "ascend"],
      width: 150,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 182,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_MAPPING:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 120,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 110,
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_MAPPING:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_3")}
            </span>{" "}
            - {t("PRODUCT_MAPPING:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_SELLER.PRODUCT_MAPPING.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("PRODUCT_MAPPING:BUTTON_9")}
              onClick={checkAdditionalData}
              id={ID_PRODUCT_MAPPING.LIST.BTN_ADD}
            />
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("COMMON:BUTTON_UPLOAD_FILE")}
              marginStart={2}
              onClick={showModal}
              variant="outline"
              colorScheme="blue"
              id={ID_PRODUCT_MAPPING.LIST.BTN_UPLOAD}
            />
            {/* <ButtonCustom
              icon={<ExportIcon />}
              text={t("COMMON:BUTTON_EXPORT_FILE")}
              marginStart={2}
              onClick={showModal}
              // id={ID_PRODUCT_MAPPING_HO.LIST.BTN_UPLOAD}
              variant="outline"
              colorScheme="blue"
            /> */}
          </>
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"30rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={5}>
          <Select
            placeholder={t("PRODUCT_MAPPING:PLACE_HOLDER_SEARCH")}
            style={selectOptFilterUserTheme}
            onPopupScroll={handleScrollDistributor}
            value={distributorId}
            onChange={(value, opt) => setDistributorId(value, opt)}
            size="large"
            fontSize="14px"
            textColor={GRAY_COLOR}
            id={ID_PRODUCT_MAPPING.LIST.SELECT_DISTRIBUTOR}
            backgroundColor="white"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                search={(e) => handleSearchDistributor(e.target.value)}
              />
            )}
            allowClear="true"
          >
            {data_Distributor?.map((value, index) => {
              return (
                <>
                  <option key={index.toString()} value={value.id}>
                    {value.name}
                  </option>
                </>
              );
            })}
          </Select>
          <Select
            placeholder={t("PRODUCT_MAPPING:PLACE_HOLDER_SEARCH_2")}
            style={selectOptFilterUserTheme}
            size="large"
            value={storeId}
            onPopupScroll={handleScrollStore}
            fontSize="14px"
            onChange={(value, opt) => setStoreId(value, opt)}
            backgroundColor="white"
            id={ID_PRODUCT_MAPPING.LIST.SELECT_STORE}
            showSearch={false}
            textColor={GRAY_COLOR}
            allowClear="true"
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                search={(e) => handleSearchStore(e.target.value)}
              />
            )}
          >
            {data_Store?.map((value, index) => {
              return (
                <option key={index} value={value.id}>
                  {value.store_name}
                </option>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            marginStart={3}
            backgroundColor="white"
            textColor={GRAY_COLOR}
            borderRadius={5}
          >
            <Input
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder={t("PRODUCT_MAPPING:PLACE_HOLDER_SEARCH_CUSTOMER")}
              fontSize="14px"
              value={form}
              id={ID_PRODUCT_MAPPING.LIST.SEARCH}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonCustom
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              id={ID_PRODUCT_MAPPING.LIST.BTN_APPLY}
              // isDisabled={isDisabled || distributorId || storeId }
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_PRODUCT_MAPPING.LIST.RESET_FILTER}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          rowKey="uid"
          columns={columns}
          onChange={handleTableChange}
          pagination={false}
          size="small"
          loading={table_loading}
          scroll={{ y: 800 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <SelectChakra
                  border="1px solid"
                  borderRadius="6px"
                  borderColor="#CCCCCC"
                  size="sm"
                  width="70px"
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </SelectChakra>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImport
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
        template={template_product_mapping}
        token={token}
      />
      <ModalImportPreview
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
        loading={uploadLoading}
        scrollXLength={2200}
        scrollYLengthLength={500}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ListProductMapping;
