import {
  Box,
  Text,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Divider,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonCustom from "./button/ButtonCustom";
import ButtonPrimary from "./button/ButtonPrimary";
import DanoneLogo from "../assets/danone 1.png";
import { useSelector } from "react-redux";
import { personTitle } from "../constant/selectOptConstant";
import moment from "moment";
import NoImagePlaceholder from "../assets/noimage_placeholder.png";
import { isArrayOfObject } from "../function/isArrayOfObject";
import ImageCompanyLogoUser from "./ImageCompanyLogoUser";

const ModalAddNewUser = ({ isOpen, onClose, message, onOk }) => {
  const { t } = useTranslation();
  const { user_category } = useSelector((state) => state.auth);
  const { user_profile } = useSelector((state) => state.user);
  const { login_account } = useSelector((state) => state.user);
  const { access_setting } = useSelector((state) => state.user);
  const { activation } = useSelector((state) => state.user);
  const { list_country } = useSelector((state) => state.regional);
  const { list_island } = useSelector((state) => state.regional);
  const { list_province } = useSelector((state) => state.regional);
  const { list_district } = useSelector((state) => state.regional);
  const { list_sub_district } = useSelector((state) => state.regional);
  const { list_urban_village } = useSelector((state) => state.regional);
  const { list_postal_code } = useSelector((state) => state.regional);
  const { photo } = useSelector((state) => state.user);
  const { detail_user } = useSelector((state) => state.user);
  const accessSetting = detail_user.access_setting;
  const getActivation = useSelector(
    (state) => state.user.detail_user.activation
  );
  const userProfile = detail_user?.user_profile;
  const getAuthentication = useSelector(
    (state) => state.user.detail_user.authentication
  );
  const listRole = useSelector((state) => state.role.list_option);
  const listDataGroup = useSelector((state) => state.dataGroup.list_option);
  const companyName = useSelector((state) => state.auth.company_name);

  const title = personTitle?.find(
    (x) => x.id === Number(user_profile?.person_title_id)
  );
  const country = list_country?.find(
    (x) => x.id === Number(user_profile?.country_id)
  );
  const island = list_island?.find(
    (x) => x.id === Number(user_profile?.island_id)
  );
  const province = list_province?.find(
    (x) => x.id === Number(user_profile?.province_id)
  );
  const district = list_district?.find(
    (x) => x.id === Number(user_profile?.district_id)
  );
  const subDistrict = list_sub_district?.find(
    (x) => x.id === Number(user_profile?.sub_district_id)
  );
  const urbanVillage = list_urban_village?.find(
    (x) => x.id === Number(user_profile?.urban_village_id)
  );
  const postalCode = list_postal_code?.find(
    (x) => x.id === Number(user_profile?.postal_code_id)
  );
  const role = listRole?.find((x) => x.id === Number(access_setting?.role_id));
  const dataGroup = listDataGroup?.find(
    (x) => x.id === Number(access_setting?.group_id)
  );
  var today = new Date();
  var todayDate = moment(today).format("DD/MM/YYYY");

  const defaultValuePeriod = "0001-01-01T00:00:00Z";

  const checkPhoto = () => {
    let result;
    if (photo && !user_profile.images && !user_profile.have_default_photo) {
      result = URL?.createObjectURL(photo);
    } else if (
      isArrayOfObject(user_profile.images) === false &&
      photo?.id !== [0] &&
      user_profile.have_default_photo
    ) {
      result = user_profile.images;
    } else if (
      isArrayOfObject(user_profile.images) &&
      !photo &&
      user_profile.have_default_photo
    ) {
      result = URL?.createObjectURL(user_profile.images[0]);
    } else if (
      isArrayOfObject(user_profile.images) &&
      !photo &&
      !user_profile.have_default_photo
    ) {
      result = URL?.createObjectURL(user_profile.images[0]);
    } else if (
      photo?.id === [0] &&
      user_profile.images === "" &&
      user_profile.have_default_photo
    ) {
      result = NoImagePlaceholder;
    } else {
      // result = DanoneLogo;
      result = NoImagePlaceholder;
    }
    return result;
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent minWidth="800px">
        <ModalHeader>
          <Center marginTop={3}>
            <Box
              fontWeight={400}
              fontSize={20}
              textAlign="center"
              marginY={2}
              children={message}
            />
          </Center>
        </ModalHeader>
        <ModalBody>
          <Stack>
            {/* ------ Text User Profile  -------  */}
            <Box>
              <Text fontWeight={500} fontSize={16} mx={2}>
                {t("USER:MULTIPLE_STEP_USER_PROFILE")}
              </Text>
            </Box>
            {/* ------ Horizontal Line  ------  */}
            <Box>
              <Divider size="mid" />
            </Box>
            {/* -------  Body User Profile -----  */}
            <Box display="flex">
              {/* ------------ row 1 ----------  */}
              <Box m={2} width="380px">
                {/*Photo file */}
                <Box padding={1} height="135px" display="flex">
                  <Box width="10rem">{t("USER:FIELD_PHOTO")}</Box>
                  <Box>
                    {/* <img
                      src={checkPhoto()}
                      // src={DanoneLogo}
                      alt="Not Ready"
                      style={{
                        width: 200,
                        maxHeight: 120,
                      }}
                      // onLoad={() => {
                      //   URL.revokeObjectURL(photo.preview);
                      // }}
                    /> */}
                    <ImageCompanyLogoUser src={user_profile.images ? user_profile.images : user_profile.photo } />
                  </Box>
                </Box>
                {/* NIK  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_NIK")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile.nik : "-"}</Text>
                  </Box>
                </Box>
                {/* Title  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_TITLE")}</Box>
                  <Box>
                    <Text>{user_profile ? title?.value : ""}</Text>
                  </Box>
                </Box>
                {/* First Name  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_FIRST_NAME")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile.first_name : ""}</Text>
                  </Box>
                </Box>
                {/* Last Name  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_LAST_NAME")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile.last_name : ""}</Text>
                  </Box>
                </Box>
                {/* Gender  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_GENDER")}</Box>
                  <Box>
                    <Text>
                      {user_profile
                        ? user_profile.sex === "L"
                          ? "Male"
                          : "Female"
                        : ""}
                    </Text>
                  </Box>
                </Box>
                {/* Email  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_EMAIL")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile.email : ""}</Text>
                  </Box>
                </Box>
                {/* Phone  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_PHONE")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile.phone : ""}</Text>
                  </Box>
                </Box>
              </Box>
              {/* ------  row 2 --------  */}
              <Box m={2} width="380px" marginBottom="30px">
                {/* address  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_ADDRESS")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile.address : ""}</Text>
                  </Box>
                </Box>
                {/* locale  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_LOCALE")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile?.locale : ""}</Text>
                  </Box>
                </Box>
                {/* country  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_COUNTRY")}</Box>
                  <Box>
                    <Text>{user_profile ? country?.name : ""}</Text>
                  </Box>
                </Box>
                {/* island  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_ISLAND")}</Box>
                  <Box>
                    <Text>{user_profile ? island?.name : ""}</Text>
                  </Box>
                </Box>
                {/* province  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_PROVINCE")}</Box>
                  <Box>
                    <Text>{user_profile ? province?.name : ""}</Text>
                  </Box>
                </Box>
                {/* district  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_DISTRICT")}</Box>
                  <Box>
                    <Text>{user_profile ? district?.name : ""}</Text>
                  </Box>
                </Box>
                {/* sub district  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_SUB_DISTRICT")}</Box>
                  <Box>
                    <Text>{user_profile ? subDistrict?.name : ""}</Text>
                  </Box>
                </Box>
                {/* urban village  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_URBAN_VILLAGE")}</Box>
                  <Box>
                    <Text>{user_profile ? urbanVillage?.name : ""}</Text>
                  </Box>
                </Box>
                {/* hamlet  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_HAMLET")}</Box>
                  <Box>
                    <Text>{user_profile ? user_profile?.hamlet : ""}</Text>
                  </Box>
                </Box>
                {/* neighbourhood */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_NEIGHBORHOOD")}</Box>
                  <Box>
                    <Text>
                      {user_profile ? user_profile?.neighbourhood : ""}
                    </Text>
                  </Box>
                </Box>
                {/* postal code */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_POSTAL_CODE")}</Box>
                  <Box>
                    <Text>{user_profile ? postalCode?.code : ""}</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* ------  Text Login Account and Access Setting ------  */}
            <Box display="flex">
              {/* -------- row 1 ---------  */}
              <Box width="380px">
                {/* ------ Text Login Account -------  */}
                <Box>
                  <Text fontWeight={500} fontSize={16} mx={2}>
                    {t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")}
                  </Text>
                </Box>
              </Box>
              {/* ---------- row 2 ---------  */}
              <Box width="380px">
                {/* ------ Text Access Setting -------  */}
                <Box>
                  <Text fontWeight={500} fontSize={16} mx={2}>
                    {t("USER:MULTIPLE_STEP_ACCESS_SETTING")}
                  </Text>
                </Box>
              </Box>
            </Box>
            {/* ------ Horizontal Line  ------  */}
            <Box>
              <Divider size="mid" />
            </Box>
            {/* ------- Body Login Account and Access Setting -----  */}
            <Box display="flex" my={2}>
              {/* ------ Body of Login Account ------  */}
              <Box width="380px" m={2}>
                {/* username */}
                <Box padding={1} display="flex">
                  <Box width="10rem"> {t("USER:FIELD_USERNAME")}</Box>
                  <Box>
                    <Text>{login_account ? login_account?.username : ""}</Text>
                  </Box>
                </Box>
                {/* password*/}
                <Box padding={1} display="flex">
                  <Box width="10rem"> {t("USER:FIELD_PASSWORD")}</Box>
                  <Box>
                    <Text>xxx</Text>
                  </Box>
                </Box>
              </Box>
              {/* ------ Body of Access Setting ------  */}
              <Box width="380px" m={2} marginBottom="30px">
                {/* user category */}
                <Box padding={1} display="flex">
                  <Box width="10rem"> {t("USER:FIELD_USER_CATEGORY")}</Box>
                  <Box>
                    <Text>
                      {access_setting.user_category
                        ? access_setting.user_category
                        : user_category}
                    </Text>
                  </Box>
                </Box>
                {/* company profile */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_COMPANY_PROFILE")}</Box>
                  <Box>
                    <Text>
                      {companyName
                        ? companyName
                        : accessSetting.company_profile_name
                        ? accessSetting.company_profile_name
                        : "-"}
                    </Text>
                  </Box>
                </Box>
                {/* role */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_ROLE")}</Box>
                  <Box>
                    <Text>
                      {access_setting ? access_setting?.role_id?.label : ""}
                    </Text>
                  </Box>
                </Box>
                {/* data group  */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_DATA_GROUP")}</Box>
                  <Box>
                    <Text>
                      {access_setting ? access_setting?.group_id?.label : ""}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* ------  Text Activation and Authentication ------  */}
            <Box display="flex">
              {/* -------- row 1 ---------  */}
              <Box width="380px">
                {/* ------ Text Activation -------  */}
                <Box>
                  <Text fontWeight={500} fontSize={16} mx={2}>
                    {t("USER:MULTIPLE_STEP_ACTIVATION")}
                  </Text>
                </Box>
              </Box>
              {/* ---------- row 2 ---------  */}
              <Box width="380px">
                {/* ------ Text Authentication -------  */}
                <Box>
                  <Text fontWeight={500} fontSize={16} mx={2}>
                    {t("USER:MULTIPLE_STEP_AUTHENTICATION")}
                  </Text>
                </Box>
              </Box>
            </Box>
            {/* ------ Horizontal Line  ------  */}
            <Box>
              <Divider size="mid" />
            </Box>
            {/* ------- Body Activation and Authentication -----  */}
            <Box display="flex" my={2}>
              {/* ------ Body of Activation------  */}
              <Box width="380px" m={2} marginBottom="30px">
                {/* Status */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_STATUS")}</Box>
                  <Box>
                    <Text>
                      {activation
                        ? activation?.status === "A"
                          ? "Active"
                          : "Inactive"
                        : ""}
                    </Text>
                  </Box>
                </Box>
                {/* Start active period*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_START_ACTIVE_PERIOD")}</Box>
                  <Box>
                    <Text>
                      {getActivation?.start_active_period === defaultValuePeriod
                        ? "-"
                        : moment(getActivation?.start_active_period).format(
                            "DD/MM/YYYY"
                          )}
                    </Text>
                  </Box>
                </Box>
                {/* End active period*/}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_END_ACTIVE_PERIOD")}</Box>
                  <Box>
                    <Text>
                      {getActivation?.end_active_period === defaultValuePeriod
                        ? "-"
                        : moment(getActivation?.end_active_period).format(
                            "DD/MM/YYYY"
                          )}
                    </Text>
                  </Box>
                </Box>
              </Box>
              {/* ------ Body of Authentication ------  */}
              <Box width="380px" m={2}>
                {/* Authentication ID */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_AUTHENTICATION_ID")}</Box>
                  <Box>
                    <Text>
                      {getAuthentication?.authentication_id
                        ? getAuthentication?.authentication_id
                        : "-"}
                    </Text>
                  </Box>
                </Box>
                {/* Client ID */}
                <Box padding={1} display="flex">
                  <Box width="10rem">{t("USER:FIELD_CLIENT_ID")}</Box>
                  <Box>
                    <Text overflowWrap="break-word" width="150px">
                      {getAuthentication?.client_id
                        ? getAuthentication?.client_id
                        : "-"}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Stack>
        </ModalBody>
        <ModalFooter>
          {/* Button  */}
          <Box display="flex" marginTop={4}>
            <ButtonCustom
              colorScheme="blue"
              variant="outline"
              marginRight={2}
              marginBottom={4}
              width="9.375rem"
              //   link={ROUTES.ROLE.LIST}
              // _hover={{ background: HOVER_PRIMARY }}
              _focus={{ boxShadow: 0 }}
              onClick={onClose}
              text={t("COMMON:BUTTON_CANCEL")}
            />
            <ButtonPrimary
              text={t("COMMON:BUTTON_SAVE")}
              width="9.375rem"
              marginBottom={4}
              onClick={onOk}
            />
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAddNewUser;
