import { Box, Input } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalAddNewUser from "../../../../../components/ModalAddNewUser";
import ModalCustom from "../../../../../components/ModalCustom";
import { ID_USER } from "../../../../../constant/idConstant";
import { GRAY_DISABLED } from "../../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../../constant/routeConstant";
import { addUsers, userAction } from "../../../../../services/main/userReducer";
const BodyAuthentication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { index_page } = useSelector((state) => state.user);
  // modal
  const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");

  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACTIVATION")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
  };
  const openModalPreview = () => {
    /// buka modal preview
    setModalPreviewOpen(true);
  };
  const closeModalPreview = () => {
    // tutup modal preview
    setModalPreviewOpen(false);
  };
  const resetReduxUser = () => {
    // buat reset redux supaya kembali seperti semula
    dispatch(userAction.resetReduxUser());
  };
  const clickOkOnModalSuccess = () => {
    // klik tombol ok di dalem modal sukses
    setNewDataAdded(true);
    resetReduxUser();
    history.push(ROUTES.USER.LIST);
  };
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };
  const saveUser = async () => {
    /// klik save di dalem tombol save user
    dispatch(addUsers()).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setResp(response.payload.response.nexsoft.payload.status.message);
        setErr(false);
      } else {
        // message.error(response.payload.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  return (
    <>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* Client id  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_CLIENT_ID")}
          </Box>
          <Box>
            <Input
              type="text"
              width="250px"
              pointerEvents="none"
              placeholder="-"
              backgroundColor={GRAY_DISABLED}
              style={{ fontSize: "16px", fontWeight: "500", color: "#333333" }}
            />
          </Box>
        </Box>
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
          id={ID_USER.ADD.BTN_PREVIOUS}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_PREVIEW")}
          width="9.375rem"
          onClick={() => openModalPreview()}
        />
      </Box>
      {/* modal  */}
      <ModalAddNewUser
        message="Detail User Account"
        isOpen={modalPreviewOpen}
        onOk={() => saveUser()}
        onClose={() => closeModalPreview()}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <DeleteSuccess /> : <SavedSuccess />}
      />
    </>
  );
};

export default BodyAuthentication;
