import { Box } from "@chakra-ui/react";
import React from "react";

const HeadTitle = ({ title, titleSection, subtitle, button }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Box fontWeight={700} fontSize={24}>
          {title}
        </Box>
        <Box fontWeight={700} fontSize={16}>
          {titleSection}
        </Box>
        <Box fontWeight={400} fontSize={14}>
          {subtitle}
        </Box>
      </Box>
      <Box>{button}</Box>
    </Box>
  );
};

export default HeadTitle;
