import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { Form, Input, Select, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../../components/button/ButtonPrimary";
import InputDatePicker from "../../../../../../components/datePicker/InputDatePicker";
import {
  AddIcon,
  DeleteSuccess,
  RedDeleteIcon,
  SavedSuccess
} from "../../../../../../components/icons";
import ModalCustom from "../../../../../../components/ModalCustom";
import { ID_DISTRIBUTOR } from "../../../../../../constant/idConstant";
import { GRAY_COLOR } from "../../../../../../constant/propertiesConstant";
import { REGEX_CONSTANT } from "../../../../../../constant/regexConstant";
import { ROUTES_USER_SELLER } from "../../../../../../constant/routeConstant";
import { personTitle } from "../../../../../../constant/selectOptConstant";
import {
  inputForContactPerson,
  inputTheme
} from "../../../../../../constant/themeConstant";
import { isDateEmpty } from "../../../../../../function/IsDateEmpty";
import { RenderLabelPersonTitle } from "../../../../../../function/RenderLabelPersonTitle";
import {
  distributorAction, getInitiateListContactPerson,
  getListContactPerson,
  updateContactPerson
} from "../../../../../../services/main/distributorReducer";
const BodyContactPerson = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { curr_page } = useSelector((state) => state.distributor);
  const { index_page } = useSelector((state) => state.distributor);
  const { company_profile } = useSelector((state) => state.distributor);
  const { preferences } = useSelector((state) => state.distributor);
  const { detail_distributor } = useSelector((state) => state.distributor);
  const [indexContactPerson, setIndexContactPerson] = useState(0);
  const { contact_person_loading } = useSelector((state) => state.distributor);
  const { detail_contact_person } = useSelector((state) => state.distributor);
  const [countData, setCountData] = useState(0);

  const renderLoading = () => {
    return (
      <>
        <Center>
          <Spin />
        </Center>
      </>
    );
  };

  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const toPreviousPage = () => {
    dispatch(distributorAction.setIndexPage(index_page - 1));
    dispatch(
      distributorAction.setCurrPage(t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES"))
    );
  };

  /// fungsi untuk modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const handleAddNewConPer = () => {
    // dia gabungin form field dan initial value dan nambahin index +1
    setIndexContactPerson(indexContactPerson + 1);
    // if (formFields.length === 0) {
    //   setFormFields(initialValue);
    // }
    // setFormFields([...formFields, initialValue]);
    // add();
  };
  const handleDeleteNewConPer = () => {
    // dia ngeset index kembali ke 0 kalo index nya kurang dari 0
    // ngosongin form field ke initial value lagi
    if (indexContactPerson < 0) {
      setIndexContactPerson(0);
    } else {
      setIndexContactPerson(indexContactPerson - 1);
    }

    // setFormFields([]);
  };

  const initialValue = {
    person_title_id: null,
    first_name: "",
    last_name: "",
    nik: "",
    phone: "",
    phone_code: "+62",
    email: "",
    join_date: null,
    resign_date: null,
  };

  const [formFields, setFormFields] = useState([]);

  const onHandleSubmit = (values) => {
    const formDataContactPerson = {
      insert_contact_person: [],
      update_contact_person: [],
      delete_contact_person: [],
    };
    for (const contact of form.getFieldValue().contact_person) {
      const {
        id,
        person_title_id,
        first_name,
        last_name,
        nik,
        phone_code,
        phone,
        email,
        join_date,
        resign_date,
        updated_at,
      } = contact;

      if (contact.hasOwnProperty("isNew") && contact.isNew) {
        formDataContactPerson.insert_contact_person.push({
          person_title_id,
          first_name,
          last_name,
          email,
          phone_code,
          phone,
          join_date,
          resign_date,
          nik,
          company_profile_id: detail_distributor?.company_profile_id,
        });
        continue;
      }
      if (contact.hasOwnProperty("isDeleted") && contact.isDeleted) {
        formDataContactPerson.delete_contact_person.push({
          id,
          updated_at,
        });
        continue;
      }
      formDataContactPerson.update_contact_person.push({
        person_title_id:
          typeof person_title_id === "object"
            ? person_title_id.value
            : person_title_id,
        first_name,
        last_name,
        email,
        phone_code,
        phone,
        join_date,
        resign_date,
        nik,
        company_profile_id: detail_distributor?.company_profile_id,
        id,
        updated_at,
      });
    }

    const object = {
      distributorId: detail_distributor?.id,
      payloadContactPerson: formDataContactPerson,
    };
    dispatch(updateContactPerson(object)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.response.nexsoft.payload.status.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const handleCancel = () => {
    dispatch(distributorAction.resetReduxDistributor());
  };
  const [loading, setLoading] = useState(false);
  const fetchContactPerson = async () => {
    const filter = `company_profile_id eq ${detail_distributor.company_profile_id}`;
    const params = { page, limit, filter };
    dispatch(getInitiateListContactPerson(params)).then((response) => {
      setCountData(response.payload.response.count_data);
    });
    dispatch(getListContactPerson(params)).then((response) => {
      setLoading(true);
      dispatch(
        distributorAction.setDetailContactPerson(response.payload.response)
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchContactPerson();
  }, []);

  const [form] = Form.useForm();
  useEffect(() => {
    const contactPersonMapping = detail_contact_person
      ? detail_contact_person?.map((value) => {
          const phoneFull = value.phone;
          const phoneCode = phoneFull?.split("-")[0] ?? "";
          const phoneNumber = phoneFull?.split("-")[1] ?? "";
          return {
            id: value?.id,
            updated_at: value?.updated_at,
            first_name: value?.first_name,
            last_name: value?.last_name,
            nik: value?.nik,
            email: value?.email,
            person_title_id: {
              value: value?.person_title_id,
              label: RenderLabelPersonTitle(value?.person_title_name),
            },
            phone_code: phoneCode,
            phone: phoneNumber,
            join_date: isDateEmpty(value?.join_date),
            resign_date: isDateEmpty(value?.resign_date),
          };
        })
      : [];

    form.setFieldsValue({
      contact_person: contactPersonMapping ? contactPersonMapping : [],
    });
  }, [detail_contact_person]);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.DISTRIBUTOR.LIST} />
  ) : contact_person_loading ? (
    renderLoading()
  ) : (
    <>
      <Form
        form={form}
        onFinish={onHandleSubmit}
        // autoComplete="off"
        initialValues={{
          contact_person: [],
        }}
      >
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          borderRadius="10px"
        >
          <Form.List name="contact_person">
            {(fields, { add, remove }) => (
              <>
                {fields
                  .filter((fs, index) => {
                    try {
                      // form.getFieldsValue().contact_person[parseInt(index)]
                      const fv =
                        form.getFieldsValue().contact_person[parseInt(index)];
                      return !Boolean(fv.isDeleted);
                    } catch {
                      return false;
                    }
                  })
                  .map((field, index) => {
                    const currentFieldValue =
                      form.getFieldsValue().contact_person[parseInt(index)];
                    return (
                      <Box // create new contact person
                        key={index}
                        border="1px dashed #666666"
                        borderRadius="8px"
                        padding={4}
                        marginBottom={5}
                      >
                        <Flex justifyContent="space-between" marginBottom="6">
                          <Text fontSize="2xl" fontWeight="700">
                            {t("DISTRIBUTOR:CREATE_NEW_CONTACT_PERSON")}
                          </Text>
                          <ButtonCustom
                            variant="outline"
                            colorScheme="red"
                            id={ID_DISTRIBUTOR.EDIT.BTN_DELETE}
                            marginRight={2}
                            width="5rem"
                            icon={<RedDeleteIcon />}
                            text={t("COMMON:BUTTON_DELETE")}
                            onClick={() => {
                              // jika isNew, langsung hapus dengan remove aja
                              if (currentFieldValue?.isNew) {
                                remove(index);
                              } else {
                                remove(index);
                                add(
                                  {
                                    ...currentFieldValue,
                                    isDeleted: true,
                                  },
                                  index
                                );
                              }
                              handleDeleteNewConPer();
                            }}
                          />
                        </Flex>
                        {/* Person Title */}
                        <Form.Item
                          name={[field.name, "person_title_id"]}
                          // key={field.key}
                          labelAlign="left"
                          labelCol={{ style: { width: 200 } }}
                          colon={false}
                          label={t("DISTRIBUTOR:FIELD_PERSON_TITLE")}
                          rules={[
                            {
                              required: true,
                              message: t("COMMON:ERROR_REQUIRED", {
                                field: t("DISTRIBUTOR:FIELD_PERSON_TITLE"),
                              }),
                            },
                          ]}
                          shouldUpdate={true}
                        >
                          <Select
                            style={inputForContactPerson}
                            size="large"
                            id={ID_DISTRIBUTOR.EDIT.PERSON_TITLE}
                          >
                            {personTitle?.map((value, index) => {
                              return (
                                <>
                                  <Option key={index} value={value.id}>
                                    {t(value.label)}
                                  </Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        {/* First Name*/}
                        <Form.Item
                          key={field.key}
                          name={[field.name, "first_name"]}
                          labelAlign="left"
                          labelCol={{ style: { width: 200 } }}
                          colon={false}
                          label={t("DISTRIBUTOR:FIELD_FIRST_NAME")}
                          rules={[
                            {
                              required: true,
                              message: t("COMMON:ERROR_REQUIRED", {
                                field: t("DISTRIBUTOR:FIELD_FIRST_NAME"),
                              }),
                            },
                            {
                              pattern: REGEX_CONSTANT.NAME,
                              message: t("COMMON:ERROR_FORMAT", {
                                field: t("DISTRIBUTOR:FIELD_FIRST_NAME"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            style={inputTheme}
                            size="large"
                            id={ID_DISTRIBUTOR.EDIT.FIRST_NAME}
                          />
                        </Form.Item>
                        {/* Last Name*/}
                        <Form.Item
                          name={[field.name, "last_name"]}
                          labelAlign="left"
                          labelCol={{ style: { width: 200 } }}
                          colon={false}
                          label={t("DISTRIBUTOR:FIELD_LAST_NAME")}
                          rules={[
                            {
                              pattern: REGEX_CONSTANT.NAME,
                              message: t("COMMON:ERROR_FORMAT", {
                                field: t("DISTRIBUTOR:FIELD_LAST_NAME"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            style={inputTheme}
                            size="large"
                            id={ID_DISTRIBUTOR.EDIT.LAST_NAME}
                          />
                        </Form.Item>
                        {/* Identification Number*/}
                        <Form.Item
                          name={[field.name, "nik"]}
                          labelCol={{ style: { width: 200 } }}
                          labelAlign="left"
                          colon={false}
                          label={t("DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER")}
                          rules={[
                            {
                              required: true,
                              message: t("COMMON:ERROR_REQUIRED", {
                                field: t(
                                  "DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER"
                                ),
                              }),
                            },
                            {
                              pattern: REGEX_CONSTANT.NIK,
                              message: t("COMMON:ERROR_FORMAT", {
                                field: t(
                                  "DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER"
                                ),
                              }),
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            style={inputTheme}
                            size="large"
                            id={ID_DISTRIBUTOR.EDIT.NIK}
                          />
                        </Form.Item>
                        <Space direction="horizontal">
                          {/* Phone code*/}
                          <Form.Item
                            name={[field.name, "phone_code"]}
                            labelCol={{ style: { width: 200 } }}
                            labelAlign="left"
                            colon={false}
                            label={t("USER:FIELD_PHONE")}
                            rules={[
                              {
                                required: true,
                                message: t("COMMON:ERROR_REQUIRED", {
                                  field: t("DISTRIBUTOR:FIELD_PHONE_CODE"),
                                }),
                              },
                            ]}
                          >
                            <Select
                              style={{
                                width: "80px",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontStyle: "normal",
                              }}
                              size="large"
                              id={ID_DISTRIBUTOR.EDIT.PHONE_CODE}
                              name="phone_code"
                            >
                              <option value="+62">+62</option>
                            </Select>
                          </Form.Item>
                          {/* Phone*/}
                          <Form.Item
                            name={[field.name, "phone"]}
                            labelCol={{ style: { width: 200 } }}
                            labelAlign="left"
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: t("COMMON:ERROR_REQUIRED", {
                                  field: t("DISTRIBUTOR:FIELD_PHONE"),
                                }),
                              },
                              {
                                pattern: REGEX_CONSTANT.PHONE_NUMBER,
                                message: t("COMMON:ERROR_FORMAT", {
                                  field: t("DISTRIBUTOR:FIELD_PHONE"),
                                }),
                              },
                            ]}
                          >
                            <Input
                              type="tel"
                              style={{
                                width: "170px",
                                fontSize: "16px",
                                fontWeight: "500",
                                fontStyle: "normal",
                                marginLeft: "-10px",
                              }}
                              size="large"
                              name="phone"
                              id={ID_DISTRIBUTOR.EDIT.PHONE}
                            />
                          </Form.Item>
                        </Space>

                        {/* Email*/}
                        <Form.Item
                          name={[field.name, "email"]}
                          labelCol={{ style: { width: 200 } }}
                          labelAlign="left"
                          colon={false}
                          label={t("DISTRIBUTOR:FIELD_EMAIL")}
                          rules={[
                            {
                              required: true,
                              message: t("COMMON:ERROR_REQUIRED", {
                                field: t("DISTRIBUTOR:FIELD_EMAIL"),
                              }),
                            },
                            {
                              pattern: REGEX_CONSTANT.EMAIL,
                              message: t("COMMON:ERROR_FORMAT", {
                                field: t("DISTRIBUTOR:FIELD_EMAIL"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            style={inputTheme}
                            size="large"
                            id={ID_DISTRIBUTOR.EDIT.EMAIL}
                          />
                        </Form.Item>
                        {/* Join Date & Resign Date*/}
                        <Box display={"flex"}>
                          {/* box join date  */}
                          <Box marginEnd={3}>
                            <Form.Item
                              name={[field.name, "join_date"]}
                              labelCol={{ style: { width: 200 } }}
                              labelAlign="left"
                              colon={false}
                              label={t("DISTRIBUTOR:FIELD_JOIN_DATE")}
                            >
                              <InputDatePicker
                                marginend={2}
                                width="250px"
                                id={ID_DISTRIBUTOR.EDIT.JOIN_DATE}
                              />
                            </Form.Item>
                          </Box>
                          {/* Box resign date */}
                          <Box marginStart={3}>
                            <Form.Item
                              name={[field.name, "resign_date"]}
                              labelCol={{ style: { width: 150 } }}
                              labelAlign="left"
                              colon={false}
                              label={t("DISTRIBUTOR:FIELD_RESIGN_DATE")}
                            >
                              <InputDatePicker
                                width="250px"
                                id={ID_DISTRIBUTOR.EDIT.RESIGN_DATE}
                              />
                            </Form.Item>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                {/* box dash add new contact person  */}
                <Form.Item>
                  <Box
                    border="1px dashed #666666"
                    borderRadius="8px"
                    padding={4}
                  >
                    <Flex justifyContent="space-between">
                      <Text fontSize="2xl" fontWeight="700">
                        {form.getFieldValue().contact_person?.length &&
                        indexContactPerson <= 0
                          ? t("DISTRIBUTOR:CREATE_NEW_CONTACT_PERSON")
                          : `${t("DISTRIBUTOR:CONTACT_INDEX")} ${
                              form.getFieldValue().contact_person?.length + 1
                            }`}
                      </Text>
                      <ButtonPrimary
                        text={t("DISTRIBUTOR:ADD_CONTACT")}
                        icon={<AddIcon />}
                        fontWeight={500}
                        id={ID_DISTRIBUTOR.EDIT.BTN_ADD_CONTACT}
                        fontSize={14}
                        onClick={() => {
                          add({
                            ...initialValue,
                            isNew: true,
                          });
                          // add()
                          handleAddNewConPer();
                          // onHandleSubmit();
                        }}
                        backgroundColor={GRAY_COLOR}
                      />
                    </Flex>
                  </Box>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Box>
        <Box display="flex" marginTop={10} float="right" paddingBottom="64px">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            id={ID_DISTRIBUTOR.EDIT.BTN_CANCEL}
            width="9.375rem"
            link={ROUTES_USER_SELLER.DISTRIBUTOR.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            onClick={handleCancel}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_DISTRIBUTOR.EDIT.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={resp}
          isOpen={modalOpen}
          onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Form>
    </>
  );
};

export default BodyContactPerson;
