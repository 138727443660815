import {
  Box, Divider, Stack
} from "@chakra-ui/react";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import BackToList from "../../../../components/BackToList";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  BlueEditIcon, DeleteSuccess, RedDeleteIcon, UnSuccess
} from "../../../../components/icons";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import CenteredSpinner from "../../../../components/spinner/CenteredSpinner";
import { ID_DATA_GROUP } from "../../../../constant/idConstant";
import {
  PRIMARY_COLOR,
  WHITE_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../constant/routeConstant";
import {
  deleteDataGroup, detailDataGroups
} from "../../../../services/main/dataGroupReducer";
import BodyDataGroup from "./body/BodyDataGroup";
import BodyDataScope2 from "./body/BodyDataScope2";
const DetailDataGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("DataGroup");
  const [is_loading_detail, setIs_loading_detail] = useState(false);
  const getDetailDataGroup = useSelector(
    (state) => state.dataGroup.detail_data_group
  );
  const [message, setMessage] = useState("");
  const changePageDataGroup = () => {
    if (currentPage !== "DataGroup") {
      setCurrentPage("DataGroup");
    } else {
      setCurrentPage("DataScope");
    }
  };
  const [updatedAt, setUpdatedAt] = useState("");
  const fetchData = () => {
    try {
      setIs_loading_detail(true);
      dispatch(detailDataGroups(location.state.id)).then((response) => {
        if (response.payload.ok) {
          setUpdatedAt(
            response.payload.response.nexsoft.payload.data.content.updated_at
          );
          setIs_loading_detail(false);
        } else {
          setIs_loading_detail(false);
        }
      });
    } catch (error) {
      setIs_loading_detail(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const payload = { updated_at: updatedAt };

  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };
  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      dataGroupId: location.state.id,
    };
    dispatch(deleteDataGroup(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };
  return isDeleted ? (
    <Redirect to={ROUTES.DATA_GROUP.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("DATA_GROUP:DETAIL_DATA_GROUP")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("COMMON:HOME")}</span> -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DATA_GROUP:TITLE_LIST")}
            </span>{" "}
            - {t("DATA_GROUP:DETAIL_DATA_GROUP")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              id={ID_DATA_GROUP.DETAIL.BTN_EDIT}
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES.DATA_GROUP.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              id={ID_DATA_GROUP.DETAIL.BTN_DELETE}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES.DATA_GROUP.LIST}
        name={getDetailDataGroup?.created_name}
        date={moment(getDetailDataGroup?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(getDetailDataGroup?.updated_at).format(
          "DD/MM/YYYY"
        )}
        modifiedBy={getDetailDataGroup?.updated_name}
        id={ID_DATA_GROUP.DETAIL.BTN_BACK}
      />

      {/* body */}
      <Box backgroundColor="white" minHeight="300px" padding={4}>
        <Stack direction="row" marginBottom={4}>
          <ButtonCustom
            text="Data Group"
            backgroundColor={
              currentPage === "DataGroup" ? PRIMARY_COLOR : WHITE_COLOR
            }
            variant={currentPage === "DataGroup" ? "solid" : "outline"}
            colorScheme="blue"
            onClick={changePageDataGroup}
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text="Data Scope"
            backgroundColor={
              currentPage === "DataScope" ? PRIMARY_COLOR : WHITE_COLOR
            }
            variant={currentPage === "DataScope" ? "solid" : "outline"}
            colorScheme="blue"
            onClick={changePageDataGroup}
          />
        </Stack>

        {/* content of body  */}
        {currentPage === "DataGroup" ? (
          <BodyDataGroup />
        ) : is_loading_detail ? (
          <CenteredSpinner />
        ) : (
          <BodyDataScope2 detail={getDetailDataGroup} />
        )}
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
        idYes={ID_DATA_GROUP.DETAIL.BTN_YES}
        idNo={ID_DATA_GROUP.DETAIL.BTN_NO}
      />
    </Box>
  );
};
export default DetailDataGroup;
