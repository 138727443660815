import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ID_STORE_MASTER } from "../../../../constant/idConstant";
import {
  GRAY_COLOR,
  PRIMARY_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { selectStatus } from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import { addMasterStore, getListCustomerDDS } from "../../../../services/main/masterStoreReducer";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage
} from "../../../../services/main/regionalReducer";
import { addSchema } from "./storeSchema";

function AddStore() {
  const { t } = useTranslation();
  const { Option } = Select;
  const dispatch = useDispatch();

  const history = useHistory();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [dataCustomer, setDataCustomer] = useState([]);
  // dispatch redux
  const fetchData = async () => {
    const parameter = { page, limit };
    dispatch(getListCountry(parameter));
    dispatch(getListIsland(parameter));
    dispatch(getListProvince(parameter));
    dispatch(getListDistrict(parameter));
    dispatch(getListSubDistrict(parameter));
    dispatch(getListUrbanVillage(parameter));
    dispatch(getListPostalCode(parameter));
  };

  // get data from redux
  const { list_country } = useSelector((state) => state.regional);
  const { list_island } = useSelector((state) => state.regional);
  const { list_province } = useSelector((state) => state.regional);
  const { list_district } = useSelector((state) => state.regional);
  const { list_sub_district } = useSelector((state) => state.regional);
  const { list_urban_village } = useSelector((state) => state.regional);
  const { list_postal_code } = useSelector((state) => state.regional);
  const { store_data } = useSelector((state) => state.masterStore);

  const onSubmit = (values, action) => {
    dispatch(addMasterStore(values)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
        action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };
  // modal success or failed
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.STORE.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  // parameter customer
  const filter = `customer_name like ${filterCustomerName}`;
  const params =
    filterCustomerName === "" ? { page, limit } : { page, limit, filter };

  const [idCustomer, setIdCustomer] = useState("");
  const dataCustomerCode = useMemo(() => {
    // find customer code by selected customer id
    return dataCustomer?.find((search) => search.id === idCustomer);
  }, [idCustomer]);


  const fetchCustomer = () => {
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPage(1);
      setDataCustomer(response.payload.response);
    });
  };

  // UseEffect
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchCustomer();
  }, [page]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // Formik
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      store_id: store_data.store_id,
      store_code: store_data.store_code,
      customer_id: store_data.customer_id,
      store_name: store_data.store_name,
      phone_country_code: store_data.phone_country_code,
      phone: store_data.phone,
      status: store_data.status,
      address_1: store_data.address_1,
      address_2: store_data.address_2,
      address_3: store_data.address_3,
      country_id: store_data.country_id,
      island_id: store_data.island_id,
      province_id: store_data.province_id,
      district_id: store_data.district_id,
      sub_district_id: store_data.sub_district_id,
      urban_village_id: store_data.urban_village_id,
      postal_code_id: store_data.postal_code_id,
      npwp: store_data.npwp,
      hamlet: store_data.hamlet,
      neighbourhood: store_data.neighbourhood,
      email: store_data.email,
      alternative_email: store_data.alternative_email,
      fax: store_data.fax,
      company_title_id: store_data.company_title_id,
      additional_data: store_data.additional_data,
    },
    validationSchema: addSchema,
    onSubmit,
  });

  // custom handleChange select option
  const handleChangeSelect = (name, value, option) => {
    if (name === "country_id") {
      setFieldValue("country_id", value);
      setFieldValue("island_id", null);
      setFieldValue("province_id", null);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "province_id") {
      setFieldValue("province_id", value);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "island_id") {
      setFieldValue("island_id", value);
    } else if (name === "district_id") {
      setFieldValue("district_id", value);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "sub_district_id") {
      setFieldValue("sub_district_id", value);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "urban_village_id") {
      setFieldValue("urban_village_id", value);
      setFieldValue("postal_code_id", null);
    } else if (name === "postal_code_id") {
      setFieldValue("postal_code_id", value);
    } else if (name === "customer_id") {
      setFieldValue("customer_id", value);
      setIdCustomer(value);
    }
    else {
      setFieldValue(name, value);
    }
  };

  // Dropdown Custom, jadiin function component
  const dropdownCustom = (menu, search, value) => (
    <>
      <div style={{ padding: "2px 8px" }}>
        <Input
          placeholder="Search"
          size="middle"
          onChange={search}
          allowClear
          defaultValue={value}
        // onKeyDown={(e) => {
        //   _ignoreBackspace(e);
        // }}
        />
      </div>
      <div style={{ padding: 2 }}>{menu}</div>
    </>
  );

  const handleScrollStore = (event) => {
    setPage(page + 1);
  };
  const handleSearchStoreType = (event) => {
    setfilterCustomerName(event.target.value);
  };

  return (
    <Box p={4}>
      <HeadTitle
        title={t("STORE:ADD_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:SUBTITLE_2")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("STORE:TITLE_LIST")}
            </span>{" "}
            - {t("STORE:BUTTON_1")}
          </>
        }
      />
      {/* end head title */}

      {/* White body */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          // justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius={10}
        >
          {/* Left side */}
          <Box p={1} minWidth="37vw">
            {/* Store ID */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STORE_ID")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  name="store_id"
                  value={values.store_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  width="250px"
                  // disabled
                  id={ID_STORE_MASTER.ADD.STORE_ID}
                />
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Customer Name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.ADD.CUSTOMER_NAME}
                  style={inputTheme}
                  value={values.customer_id}
                  onPopupScroll={handleScrollStore}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) =>
                    dropdownCustom(menu, handleSearchStoreType)
                  }
                  showSearch={false}
                  allowClear="true"
                  name="customer_id"
                >
                  {dataCustomer &&
                    dataCustomer.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                          code={data?.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* Customer Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  type="text"
                  width="250px"
                  name="customer_code"
                  value={dataCustomerCode ? dataCustomerCode.customer_code : ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                  backgroundColor={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Store Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  name="store_code"
                  value={values.store_code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  width="250px"
                // disabled
                />
                {errors.store_code && touched.store_code && (
                  <ValidationError text={errors.store_code} />
                )}
              </Box>
            </Box>
            {/* Store Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STORE_NAME")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  type="text"
                  width="250px"
                  name="store_name"
                  value={values.store_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // disabled
                  id={ID_STORE_MASTER.ADD.STORE_NAME} />
                {errors.store_name && touched.store_name && (
                  <ValidationError text={errors.store_name} />
                )}
              </Box>
            </Box>
            {/* Phone */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_PHONE")}</Text>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                  <Box className="my-select-container-phone">
                    <Select
                      id={ID_STORE_MASTER.ADD.COUNTRY_CODE}
                      style={{
                        width: "80px",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: 6,
                      }}
                      size="large"
                      value={values?.phone_country_code}
                      onChange={(value, opt) =>
                        handleChangeSelect("phone_country_code", value, opt)
                      }
                    >
                      {list_country?.map((value, index) => {
                        return (
                          <Option key={index} value={value.phone_code}>
                            {value.phone_code}
                          </Option>
                        );
                      })}
                    </Select>
                  </Box>
                  <Box>
                    <Input
                      id={ID_STORE_MASTER.ADD.PHONE}
                      type="tel"
                      style={{
                        width: "170px",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                      }}
                      size="large"
                      value={values?.phone}
                      onChange={handleChange}
                      name="phone"
                    />
                  </Box>
                </Box>
                {errors.phone && touched.phone && (
                  <ValidationError text={errors.phone} />
                )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  width="250px"
                  id={ID_STORE_MASTER.ADD.STATUS}                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
          </Box>
          {/* Right Side */}
          <Box p={1}>
            {/* address_1 */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_ADDRESS")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  name="address_1"
                  value={values.address_1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  width="250px"
                  // disabled
                  id={ID_STORE_MASTER.ADD.ADDRESS} />
                {errors.address_1 && touched.address_1 && (
                  <ValidationError text={errors.address_1} />
                )}
              </Box>
            </Box>
            {/* country_id */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_COUNTRY")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="country_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("country_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  value={values.country_id}
                  allowClear="true"
                  id={ID_STORE_MASTER.ADD.COUNTRY}
                >
                  {list_country?.map((value, index) => {
                    return (
                      <Option key={index} value={value.id}>
                        {" "}
                        {value.name}
                      </Option>
                    );
                  })}
                </Select>
                {errors.country_id && touched.country_id && (
                  <ValidationError text={errors.country_id} />
                )}
              </Box>
            </Box>
            {/* island_id */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_ISLAND")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="island_id"
                  id={ID_STORE_MASTER.ADD.ISLAND}
                  // placeholder="Please select a island"
                  onChange={(value, opt) =>
                    handleChangeSelect("island_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  value={values.island_id}
                  allowClear="true"
                >
                  {list_island
                    ?.filter(
                      (filter) => filter.country_id === values?.country_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.island_id && touched.island_id && (
                  <ValidationError text={errors.island_id} />
                )}
              </Box>
            </Box>
            {/* province_id */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_PROVINCE")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="province_id"
                  id={ID_STORE_MASTER.ADD.PROVINCE}
                  // placeholder="Please select province"
                  value={values.province_id}
                  onChange={(value, opt) =>
                    handleChangeSelect("province_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                >
                  {list_province
                    ?.filter(
                      (filter) => filter.country_id === values?.country_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.province_id && touched.province_id && (
                  <ValidationError text={errors.province_id} />
                )}
              </Box>
            </Box>
            {/* District */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_DISTRICT")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="district_id"
                  id={ID_STORE_MASTER.ADD.DISTRICT}
                  // placeholder="Please select a distrrict"
                  onChange={(value, opt) =>
                    handleChangeSelect("district_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.district_id}
                >
                  {list_district
                    ?.filter(
                      (filter) => filter.province_id === values?.province_id
                    )
                    .map((value) => {
                      return (
                        <Option key={value.id} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.district_id && touched.district_id && (
                  <ValidationError text={errors.district_id} />
                )}
              </Box>
            </Box>
            {/* Sub-District */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_SUB_DISTRICT")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="sub_district_id"
                  id={ID_STORE_MASTER.ADD.SUB_DISTRICT}
                  // placeholder="Please select a distrrict"
                  onChange={(value, opt) =>
                    handleChangeSelect("sub_district_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.sub_district_id}
                >
                  {list_sub_district
                    ?.filter(
                      (filter) => filter.district_id === values.district_id
                    )
                    .map((value) => {
                      return (
                        <Option key={value.id} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.sub_district_id && touched.sub_district_id && (
                  <ValidationError text={errors.sub_district_id} />
                )}
              </Box>
            </Box>
            {/* Urban */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_URBAN_VILLAGE")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.ADD.URBAN_VILLAGE}
                  name="urban_village_id"
                  // placeholder={t("STORE:FIELD_URBAN_VILLAGE")}
                  onChange={(value, opt) =>
                    handleChangeSelect("urban_village_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.urban_village_id}
                >
                  {list_urban_village
                    ?.filter(
                      (filter) =>
                        filter.sub_district_id === values.sub_district_id
                    )
                    .map((value) => {
                      return (
                        <Option key={value.id} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.urban_village_id && touched.urban_village_id && (
                  <ValidationError text={errors.urban_village_id} />
                )}
              </Box>
            </Box>
            {/* Postal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_POSTAL_CODE")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  name="postal_code_id"
                  id={ID_STORE_MASTER.ADD.POSTAL_CODE}
                  // placeholder={t("STORE:FIELD_URBAN_VILLAGE")}
                  onChange={(value, opt) =>
                    handleChangeSelect("postal_code_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.postal_code_id}
                >
                  {list_postal_code
                    ?.filter(
                      (filter) =>
                        filter.urban_village_id === values.urban_village_id
                    )
                    .map((value) => {
                      return (
                        <Option key={value.id} value={value.id}>
                          {" "}
                          {value.code}
                        </Option>
                      );
                    })}
                </Select>
                {errors.postal_code_id && touched.postal_code_id && (
                  <ValidationError text={errors.postal_code_id} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_BUYER.STORE.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_STORE_MASTER.ADD.BTN_CANCEL} />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            // onClick={alert("diklik")}
            id={ID_STORE_MASTER.ADD.BTN_SAVE} />
        </Box>
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
}

export default AddStore;
