import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  change_pass: {},
  reset_pass: {},

  status: "",
  errorNotification: "",
};
export const changePassword = createAsyncThunk(
  "changePassword",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/change-password`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const setLogout = createAsyncThunk(
  "setLogout",
  async (_, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/session/logout`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const navbarMenu = createSlice({
  name: "navbarMenu",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxNavBarMenu() {
      return INITIAL_STATE;
    },
  },

  extraReducers: {
    [changePassword.pending]: (state, action) => {
      state.status = "loading";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [changePassword.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [setLogout.pending]: (state, action) => {
      state.status = "loading";
    },
    [setLogout.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [setLogout.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
  },
});

export const navbarMenuAction = navbarMenu.actions;

export default navbarMenu.reducer;
