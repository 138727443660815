const formatPhotoUrl = ({ filename, path, host }) => {
  if (!filename || !path || !host) return "";
  let photoUrl = "";
  if (path && path.endsWith("/") && !path.includes(".")) {
    photoUrl = host + path + filename;
  } else {
    photoUrl = host + path;
  }

  return photoUrl;
};

const formatPhotoNormal = ({ filename, path, host }) => {
  let url = "";
  const photoFull = host?.concat("/").concat(path).concat(filename);
  const photoFinal = photoFull ? photoFull : null;
  url = photoFinal;
  return url;
};

const FormatUtil = {
  formatPhotoUrl,
  formatPhotoNormal
};

export default FormatUtil;
