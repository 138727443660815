import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
    list:[],
    table_loading : false,
    status : "",
    detail_product:[]
}

export const getListProductCustomer = createAsyncThunk(
    "getListProductCustomer",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

  export const getInitListProductCustomer = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/product-customer/initiateGetList",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer/initiateGetList`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

  export const detailProductCustomer = createAsyncThunk(
    "Get Detail Product Customer",
    async (productId, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer/${productId}`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
  
      return result;
    }
  );
  
  export const addProductCustomer = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/product-customer/add",
    async (object, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer`;
      const { auth } = getState();
      const token = auth.token;
  
      try {
        const response = await baseApi.doPost(url, token, object);
        if (response) {
          result.ok = true;
          result.response = response.data;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
          message.error(error.response.data.nexsoft.payload.status.message);
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );
  
  export const updateProductCustomer = createAsyncThunk(
    "http://sandbox-web.farmindo.com:4080/v1/farmindo/product-customer/197",
    async (object, { getState, rejectWithValue }) => {
      let result = { ok: false };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer/${object.id}`;
      const { auth } = getState();
      const token = auth.token;
      
      try {
        const response = await baseApi.doPut(url, token, object.values);
        if (response) {
          result.ok = true;
          result.response = response.data;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
  
      return result;
    }
  );
  
  export const deleteProductCustomer = createAsyncThunk(
    "Delete Product Customer",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer/${params.Id}`;
      const { auth } = getState();
      const token = auth.token;
      const body = {
        updated_at: params.updated_at,
      };
      try {
        const response = await baseApi.doDelete(url, token, body);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.status.message;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
  
      return result;
    }
  );

  const productCustomer = createSlice({
    name : "productCustomer",
    initialState : INITIAL_STATE,
    reducers :{
        resetReduxProductCustomer(){
            return INITIAL_STATE
        }
    },
    extraReducers :{
        [getListProductCustomer.pending] : (state,action)=>{
            state.status = 'loading';
            state.table_loading = true;
        },
        [getListProductCustomer.fulfilled] : (state,action)=>{
            state.status = 'success';
            state.table_loading = false;
            state.list = action.payload.response
        },
        [getListProductCustomer.rejected]:(state,action)=>{
            state.status = 'failed';
            state.table_loading = false;
        },
        [detailProductCustomer.pending]: (state, action) => {
          state.status = "loading";
        },
        [detailProductCustomer.fulfilled]: (state, action) => {
          state.status = "success";
          state.detail_product = action.payload.response;
        },
        [detailProductCustomer.rejected]: (state, action) => {
          state.status = "failed";
        },
        [addProductCustomer.pending]: (state, action) => {
          state.status = "loading";
        },
        [addProductCustomer.fulfilled]: (state, action) => {
          state.status = "success";
        },
        [addProductCustomer.rejected]: (state, action) => {
          state.status = "failed";
        },
        [updateProductCustomer.pending]: (state, action) => {
          state.status = "loading";
        },
        [updateProductCustomer.fulfilled]: (state, action) => {
          state.status = "success";
          state.update_store = action.payload.response;
         },
        [updateProductCustomer.rejected]: (state, action) => {
          state.status = "failed";
          state.errorNotification = action.payload;
        },
        [deleteProductCustomer.pending]: (state, action) => {
          state.status = "loading";
        },
        [deleteProductCustomer.fulfilled]: (state, action) => {
          state.status = "success";
        },
        [deleteProductCustomer.rejected]: (state, action) => {
          state.status = "failed";
          state.errorNotification = action.payload;
        },
    }
  });
  export const productCustomerAction = productCustomer.actions;

  export default productCustomer.reducer;