import { Box, Center, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { CloseIcon, EditIcon, PhotoIcon } from "../../components/icons";
import { MIME } from "../../constant/mimeConstant";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ButtonTooltip from "../button/ButtonTooltip";
import {
  BLACK_COLOR,
  GRAY_BG_MEDIUM,
  GRAY_DISABLED,
  PRIMARY_COLOR,
  RED_COLOR,
} from "../../constant/propertiesConstant";

const focusedStyle = {
  borderColor: PRIMARY_COLOR,
  color: PRIMARY_COLOR,
};

const rejectStyle = {
  borderColor: RED_COLOR,
  color: RED_COLOR,
};

const DropzoneImageLogo = ({
  defaultValue,
  onChange,
  onBlur,
  noClick,
  accept,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const onDropImage = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles?.length > 0) {
        onChange && onChange(acceptedFiles, rejectedFiles);
        return;
      }

      const imageFile = acceptedFiles[0];
      const imageUrl = URL.createObjectURL(imageFile);
      onChange && onChange(acceptedFiles, rejectedFiles);
      setImagePreviewUrl((prevState) => {
        URL.revokeObjectURL(prevState);
        return imageUrl;
      });
    },
    [onChange]
  );

  const handleClearImage = () => {
    URL.revokeObjectURL(imagePreviewUrl);
    setImagePreviewUrl("");
    onChange("", true);
  };

  useEffect(() => {
    if (!defaultValue) return;
    if (typeof defaultValue === "object" && Array.isArray(defaultValue)) {
      const imageFile = defaultValue[0];
      let imageUrl = "";
      try {
        imageUrl = URL.createObjectURL(imageFile);
      } catch (error) {
        
      }
      setImagePreviewUrl((prevState) => {
        URL.revokeObjectURL(prevState);
        return imageUrl;
      });
    }

    if (typeof defaultValue === "string") {
      setImagePreviewUrl((prevState) => {
        URL.revokeObjectURL(prevState);
        return defaultValue;
      });
    }
  }, [defaultValue]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    multiple: false,
    onDrop: onDropImage,
    accept: accept ?? MIME.acceptImageType,
    noClick: Boolean(imagePreviewUrl),
  });

  const style = useMemo(
    () => ({
      ...(isFocused || isDragAccept ? focusedStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragReject, isDragAccept]
  );

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imagePreviewUrl);
    };
  }, [imagePreviewUrl]);

  return (
    <Center
      width="195px"
      height="90px"
      position="relative"
      color={GRAY_BG_MEDIUM}
      borderRadius="md"
      border="3.5px dashed black"
      borderColor={GRAY_DISABLED}
      _hover={{
        borderColor: PRIMARY_COLOR,
        color: PRIMARY_COLOR,
      }}
      transition="ease-in-out 0.25s"
      cursor="pointer"
      {...getRootProps({ role: "group" })}
      sx={style}
      onBlur={onBlur}
    >
      <input {...getInputProps()} />
      <Box
        position="absolute"
        zIndex={imagePreviewUrl ? "2" : "0"}
        width="195px"
        height="90px"
        padding="1"
      >
        {imagePreviewUrl && (
          <Image
            src={imagePreviewUrl}
            objectFit="fill"
            boxSize="100%"
            borderRadius="md"
          />
        )}

        {imagePreviewUrl && (
          <Stack
            direction="row"
            spacing="1"
            position="absolute"
            top="2.5"
            right="2.5"
          >
            <ButtonTooltip onClick={open}>
              <EditIcon width="12px" height="12px" marginRight="1" />
              {t("COMMON:EDIT")}
            </ButtonTooltip>
            <ButtonTooltip width="24px" onClick={handleClearImage}>
              <CloseIcon color={BLACK_COLOR} width="12px" height="12px" />
            </ButtonTooltip>
          </Stack>
        )}
      </Box>
      {!imagePreviewUrl && (
        <Flex
          direction="column"
          alignItems="center"
          sx={style}
          position="absolute"
          zIndex="1"
        >
          <PhotoIcon
            _groupHover={{ color: PRIMARY_COLOR }}
            color={style?.color}
            transition="ease-in-out 0.25s"
          />
          <Text fontWeight="500">{t("COMMON:UPLOAD_PHOTO")}</Text>
        </Flex>
      )}
    </Center>
  );
};

export default DropzoneImageLogo;
