import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addProductBklMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_NAME"),
          })
        ),
      store_id: yup
        .array()
        .min(
          1,
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_STORE_NAME"),
          })
        )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_STORE_NAME"),
          })
        ),
      distributor_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      product_principal_id: yup
        .number()
        // .array()
        // .min(
        //   1,
        //   i18next.t("COMMON:ERROR_REQUIRED", {
        //     field: i18next.t(
        //       "PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"
        //     ),
        //   })
        // )
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"
            ),
          })
        ),
      product_customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME"),
          })
        ),
      type: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_BKL_TYPE"),
        })
      ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_STATUS"),
        })
      ),
    });
};
export const editProductBklMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      customer_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_NAME"),
              })
            ),
        }),
      store_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_STORE_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_BKL_MAPPING:FIELD_STORE_NAME"),
              })
            ),
        }),
      distributor_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_DISTRIBUTOR_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_BKL_MAPPING:FIELD_DISTRIBUTOR_NAME"),
              })
            ),
        }),
      product_principal_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"
            ),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"),
              })
            ),
        }),
      product_customer_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME"),
              })
            ),
        }),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_STATUS"),
        })
      ),
      type: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_BKL_MAPPING:FIELD_BKL_TYPE"),
        })
      ),
    });
};
