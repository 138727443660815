import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import AuthReducer from "../reducers/authReducer";
import PageReducer from "../reducers/pageReducer";
import PermissionReducer from "../reducers/permissionReducer";
import DataGroupReducer from "../services/main/dataGroupReducer";
import RoleReducer from "../services/main/roleReducer";
import userReducer from "../services/main/userReducer";
import masterStoreReducer from "../services/main/masterStoreReducer";
import customerReducer from "../services/main/customerReducer";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import regionalReducer from "../services/main/regionalReducer";
import productPrincipalReducer from "../services/main/productPrincipalReducer";
import productCustomerReducer from "../services/main/productCustomerReducer";
import productCategoryReducer from "../services/main/productCategoryReducer";
import productMappingReducer from "../services/main/productMappingReducer";
import productMappingHoReducer from "../services/main/productMappingHoReducer";
import bklMappingReducer from "../services/main/bklMappingReducer";
import productBklMappingReducer from "../services/main/productBklMappingReducer";
import productCategoryMappingReducer from "../services/main/productCategoryMappingReducer";
import productSubtituteReducer from "../services/main/productSubtituteReducer";
import storeSupplierConnectionReducer from "../services/main/storeSupplierConnectionReducer";
import notificationReducer from "../services/main/notificationReducer";
import principalReducer from "../services/main/principalReducer";
import processedPOReducer from "../services/main/processedPOReducer";
import reprocessedPOReducer from "../services/main/reprocessedPOReducer";
import reportPOReducer from "../services/main/report/reportPOReducer";
import reportPODetailReducer from "../services/main/report/reportPODetailReducer";
import monitoringListProcessPOReducer from "../services/main/monitoring/monitoringListProcessPOReducer";
import monitoringPOReducer from "../services/main/monitoring/monitoringPO";
import UnmappedProductReducer from "../services/main/monitoring/monitoringUnmappedProduct";
import UnmappedStoreReducer from "../services/main/monitoring/monitoringUnmappedStore";
import warehouseReducer from "../services/main/warehouseReducer";
import distributorReducer from "../services/main/distributorReducer";
import proccessApprovePoReducer from "../services/main/proccessApprovePoReducer";
import sftpLogReducer from "../services/main/monitoring/monitoringAS2Log";
import bklSummaryReducer from "../services/main/bklSummaryReducer";
import reportPOvsSo from "../services/main/report/reportPOvsSO";
import dashboardReducer from "../services/main/dashboardReducer";
import navbarMenuReducer from "../services/main/navbarMenuReducer";
import importSoReducer from "../services/main/importSoReducer";
import sessionReducer from "../services/main/sessionReducer";
import reportListConnection from "../services/main/report/reportListConnection";
import copyMappingReducer from "../services/main/copyMappingReducer";
// const rootReducer =  combineReducers ({
//     userReducer,
// });

const persistConfig = {
  key: "rootUser",
  storage,
  whitelist : ['auth','page']
};

const reducers = combineReducers({
  auth: AuthReducer.reducer,
  page: PageReducer.reducer,
  permisi: PermissionReducer,
  dataGroup: DataGroupReducer,
  role: RoleReducer,
  user: userReducer,
  regional: regionalReducer,
  masterStore: masterStoreReducer,
  customer: customerReducer,
  productPrincipal: productPrincipalReducer,
  productCustomer: productCustomerReducer,
  productCategory: productCategoryReducer,
  productMapping: productMappingReducer,
  productMappingHo: productMappingHoReducer,
  bklMapping: bklMappingReducer,
  productBklMapping: productBklMappingReducer,
  productCategoryMapping: productCategoryMappingReducer,
  productSubtitute: productSubtituteReducer,
  storeSupplierConnection: storeSupplierConnectionReducer,
  reportListConnection: reportListConnection,
  notification: notificationReducer,
  principal: principalReducer,
  processedPO: processedPOReducer,
  reprocessedPO: reprocessedPOReducer,
  reportPO: reportPOReducer,
  reportPODetail: reportPODetailReducer,
  monitoringListProcessPO: monitoringListProcessPOReducer,
  monitoringPO: monitoringPOReducer,
  unmappedProduct: UnmappedProductReducer,
  unmappedStore: UnmappedStoreReducer,
  warehouse: warehouseReducer,
  aslogSftp: sftpLogReducer,
  distributor: distributorReducer,
  proccessApprovePo: proccessApprovePoReducer,
  bklSummary: bklSummaryReducer,
  reportPOvsSO: reportPOvsSo,
  dashboard: dashboardReducer,
  navbarMenu: navbarMenuReducer,
  importSo : importSoReducer,
  copyMapping : copyMappingReducer,
  session: sessionReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;
