import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";
import * as yup from "yup";
import i18next from "i18next";

export const addProductCategoryMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      product_category_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY_NAME"
            ),
          })
        ),
      product_principal_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"
            ),
          })
        ),
      product_customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME"
            ),
          })
        ),
      customer_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME"),
          })
        ),
      distributor_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_NAME"),
          })
        ),
      store_id: yup
        .number()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME"),
          })
        ),
      store_supplier_connection_id: yup
        .string()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_STORE_SUPPLIER_CODE"
            ),
          })
        ),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_STATUS"),
        })
      ),
    });
};

export const editProductCategoryMappingSchema = (t) => {
  return () =>
    yup.object().shape({
      product_category_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY_NAME"
            ),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t(
                  "PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY_NAME"
                ),
              })
            ),
        }),
      product_principal_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"
            ),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t(
                  "PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME"
                ),
              })
            ),
        }),
      product_customer_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME"
            ),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t(
                  "PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME"
                ),
              })
            ),
        }),
      distributor_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_CATEGORY_MAPPING:FIELD_DISTRIBUTOR_NAME"),
              })
            ),
        }),
      customer_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME"),
              })
            ),
        }),
      store_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME"),
          })
        )
        .shape({
          value: yup
            .number()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_NAME"),
              })
            ),
        }),
      store_supplier_connection_id: yup
        .object()
        .nullable()
        .required(
          i18next.t("COMMON:ERROR_REQUIRED", {
            field: i18next.t(
              "PRODUCT_CATEGORY_MAPPING:FIELD_STORE_SUPPLIER_CODE"
            ),
          })
        )
        .shape({
          value: yup
            .string()
            .nullable()
            .required(
              t("COMMON:ERROR_REQUIRED", {
                field: t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_SUPPLIER_CODE"),
              })
            ),
        }),
      status: yup.string().required(
        i18next.t("COMMON:ERROR_REQUIRED", {
          field: i18next.t("PRODUCT_CATEGORY_MAPPING:FIELD_STATUS"),
        })
      ),
    });
};
