import {
  Box, Divider, Stack
} from "@chakra-ui/react";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import {
  PRIMARY_COLOR,
  WHITE_COLOR
} from "../../../../../constant/propertiesConstant";
import {
  detailDistributor,
  distributorAction,
  editDistributor
} from "../../../../../services/main/distributorReducer";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage
} from "../../../../../services/main/regionalReducer";
import "../Distributor.css";
import BodyCompanyProfile from "./body/BodyCompanyProfile";
import BodyContactPerson from "./body/BodyContactPerson";
import BodyPreferences from "./body/BodyPreferences";
const EditDistributor = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { curr_page } = useSelector((state) => state.distributor);
  // const { detail_distributor } = useSelector((state) => state.distributor);
  const { detail_company_profile } = useSelector((state) => state.distributor); 
  // const { preferences } = useSelector((state) => state.distributor);
  // const { detail_contact_person } = useSelector((state) => state.distributor);

  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  /// fungsi untuk modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const setCurrPage = () => {
    // current page
    dispatch(
      distributorAction.setCurrPage(
        t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
      )
    );
    // index page
    dispatch(distributorAction.setIndexPage(0));
  };

  const handleChangeCurrPage = (page) => {
    if (page === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")) {
      dispatch(
        distributorAction.setCurrPage(
          t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
        )
      );
    }
    if (page === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")) {
      dispatch(
        distributorAction.setCurrPage(
          t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")
        )
      );
    }
    if (page === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")) {
      dispatch(
        distributorAction.setCurrPage(
          t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
        )
      );
    }
  };

  const renderMainBody = () => {
    if (curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")) {
      return <BodyCompanyProfile />;
    }
    if (curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")) {
      return <BodyPreferences />;
    }
    if (curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")) {
      return <BodyContactPerson />;
    }
  };
  const handleOnSubmit = () => {
    const distributorId = location.state.id;
    dispatch(editDistributor(distributorId)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const paramsIslandId = {
    page: 1,
    limit: 100,
    filter: `country_id eq ${detail_company_profile?.country_id}`,
  };
  // const params
  const fetchData = (response) => {
    const params = { page, limit };
    const paramsProvinceId = {
      page: 1,
      limit: 100,
      filter: `country_id eq ${response.country_id}`,
    };
    const paramsIslandId = {
      page: 1,
      limit: 100,
      filter: `country_id eq ${response.country_id}`,
    };
    const paramsDistrictId = {
      page: 1,
      limit: 100,
      filter: `province_id eq ${response.province_id}`,
    };
    const paramsSubDistrictId = {
      page: 1,
      limit: 100,
      filter: `district_id eq ${response.district_id}`,
    };
    const paramsUrbanVillageId = {
      page: 1,
      limit: 100,
      filter: `sub_district_id eq ${response.sub_district_id}`,
    };
    const paramsPostalCodeId = {
      page: 1,
      limit: 100,
      filter: `urban_village_id eq ${response.urban_village_id}`,
    };
    dispatch(getListCountry(params));
    dispatch(getListIsland(paramsIslandId));
    dispatch(getListProvince(paramsProvinceId));
    dispatch(getListDistrict(paramsDistrictId));
    dispatch(getListSubDistrict(paramsSubDistrictId));
    dispatch(getListUrbanVillage(paramsUrbanVillageId));
    dispatch(getListPostalCode(paramsPostalCodeId));
  };
  // const fetchDetail = async () => {
  //   const res = await dispatch(detailDistributor(location.state.id));
  //   const response = await res;
  //   if (response.payload.ok) {
  //     dispatch(
  //       distributorAction.setDetailCompanyProfile(
  //         response.payload.response.company_profile
  //       )
  //     );
  //     dispatch(
  //       distributorAction.setDetailDistributor(
  //         response.payload.response.distributor
  //       )
  //     );
  //     dispatch(
  //       distributorAction.setDataPreferences(
  //         response.payload.response.preferences
  //       )
  //     );
  //   } else {
  //     message.error(
  //       response.payload.response.data.nexsoft.payload.status.message
  //     );
  //   }
  // };
  useEffect(() => {
    dispatch(distributorAction.resetReduxDistributor());
    setCurrPage();
    dispatch(detailDistributor(location.state.id)).then((response) => { 
      if (response.payload.ok) {
        dispatch(
          distributorAction.setDetailCompanyProfile(
            response.payload.response.company_profile
          )
        );
        dispatch(
          distributorAction.setDetailDistributor(
            response.payload.response.distributor
          )
        );
        dispatch(
          distributorAction.setDataPreferences(
            response.payload.response.preferences
          )
        );
      } else {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
      fetchData(response.payload.response.company_profile);
    });
 
  }, []);
  return (
    // overflow nya di none supaya ga muncul scroll
    <Box padding={4}>
      <HeadTitle
        title={t("DISTRIBUTOR:EDIT_DISTRIBUTOR")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:FIELD_MASTER")}
            </span>{" "}
            -
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:FIELD_DISTRIBUTOR")}
            </span>{" "}
            - {t("DISTRIBUTOR:EDIT_DISTRIBUTOR")}
          </>
        }
      />
      {/* render button  & body*/}
      <Box backgroundColor="white" minHeight="300px" padding={4} marginTop={3}>
        <Stack direction="row" marginBottom={4} marginTop={2}>
          <ButtonCustom
            text={t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")}
            backgroundcolor={
              curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={() =>
              handleChangeCurrPage(
                t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
              )
            }
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")}
            backgroundcolor={
              curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={() =>
              handleChangeCurrPage(t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES"))
            }
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")}
            backgroundcolor={
              curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
                ? PRIMARY_COLOR
                : WHITE_COLOR
            }
            variant={
              curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
                ? "solid"
                : "outline"
            }
            colorScheme="blue"
            onClick={() =>
              handleChangeCurrPage(
                t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")
              )
            }
          />
        </Stack>

        {/* ------ render main body ------  */}
        {renderMainBody()}
      </Box>
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <DeleteSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default EditDistributor;
