import { Box, Button, ButtonProps } from "@chakra-ui/react";
import { LinkProps } from "react-router-dom";
//   import { COLORS } from '../../../constant/themeConstant';
import {
  BLACK_COLOR,
  GRAY_BG_MEDIUM,
  GRAY_COLOR,
  GRAY_DISABLED,
  PRIMARY_COLOR,
  RED_COLOR,
  WHITE_COLOR,
} from "../../constant/propertiesConstant";

/**
 * Base Button Component
 * @param {ButtonProps} props
 * @returns
 */
const ButtonTooltip = (props) => {
  const isOutline = props.variant === "outline";
  let mode = props.mode || "default";

  const modeVariant = {
    default: {
      color: BLACK_COLOR,
      backgroundColor: !isOutline ? GRAY_DISABLED : "",
      colorScheme: "gray",
    },
    primary: {
      color: isOutline ? PRIMARY_COLOR : WHITE_COLOR,
      backgroundColor: !isOutline ? PRIMARY_COLOR : "",
      colorScheme: "blue",
    },
    secondary: {
      color: isOutline ? GRAY_BG_MEDIUM : WHITE_COLOR,
      backgroundColor: !isOutline ? GRAY_BG_MEDIUM : "",
      colorScheme: "gray",
      _hover: {
        backgroundColor: GRAY_COLOR,
      },
    },
    danger: {
      color: isOutline ? RED_COLOR : WHITE_COLOR,
      backgroundColor: !isOutline ? RED_COLOR : "",
      colorScheme: "red",
    },
    disable: {
      color: WHITE_COLOR,
      backgroundColor: GRAY_COLOR,
      colorScheme: "gray",
      pointerEvents: "none",
      cursor: "not-allowed",
      _hover: {
        backgroundColor: GRAY_COLOR,
      },
    },
  };

  if (props.isDisabled) mode = "disable";
  if (props.variant === "unstyled") modeVariant[mode] = {};

  return (
    <Button
      height="20px"
      minWidth="24px"
      paddingX="1.5"
      paddingY="1"
      {...modeVariant[mode]}
      fontSize="xs"
      fontWeight="500"
      borderRadius="4px"
      leftIcon={props.icon}
      {...props}
    />
  );
};

export default ButtonTooltip;
