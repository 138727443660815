import {
  Box, CloseButton, Icon,
  Input,
  InputGroup,
  InputRightElement, Select, Stack, Tag, Text
} from "@chakra-ui/react";
import { Pagination, Table } from "antd";
import { subHours } from "date-fns";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import {
  AddIcon, DetailIcon, EditIcon, SearchIcon,
  StatusActive,
  StatusNonActive
} from "../../../components/icons";
import CustomPopOver from "../../../components/popOver/CustomPopOver";
import { ID_DATA_GROUP } from "../../../constant/idConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR, PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  WHITE_COLOR
} from "../../../constant/propertiesConstant";
import { ROUTES } from "../../../constant/routeConstant";
import { selectPaginationOption } from "../../../constant/selectOptConstant";
import "../../../css/GlobalAntd.css";
import {
  getInitiateListDataGroup, getListDataGroup
} from "../../../services/main/dataGroupReducer";
import DateUtil, { FORMAT } from "../../../utils/dateUtil";
import StringUtil from "../../../utils/stringUtil";
const ListDataGroup = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const data = useSelector((state) => state.dataGroup.list);
  const { token } = useSelector((state) => state.auth);
  const { table_loading } = useSelector((state) => state.dataGroup);
  const [isDisabled, setisDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  //pagination
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const dispatch = useDispatch();
  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      group_id: item.group_id,
      description: item.description,
      level: item.level,
      updated_at: DateUtil.formatDate(subHours(new Date(item.updated_at), 7), FORMAT.DATE_TIME),
      status: item.status,
      action: (
        <div key={index}>
          <ButtonCustom
            link={{ pathname: ROUTES.DATA_GROUP.EDIT, state: item }}
            text={<EditIcon />}
            backgroundcolor="transparent"
            id={ID_DATA_GROUP.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{ pathname: ROUTES.DATA_GROUP.DETAIL, state: item }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
            id={ID_DATA_GROUP.LIST.BTN_VIEW + "_" + item.id}
          />
        </div>
      ),
    }));
  // const dataFilter =
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DATA_GROUP:FIELD_DATA_GROUP_ID")}
        </Box>
      ),
      dataIndex: "group_id",
      key: "group_id",
      sorter: (a, b) => a.group_id.localeCompare(b.group_id),
      sortDirections: ["descend", "ascend"],
      align: "left",
      width: 140,
      ellipsis: true,
      // fixed: "left",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DATA_GROUP:FIELD_DESCRIPTION")}
        </Box>
      ),
      dataIndex: "description",
      key: "description",
      width: 350,
      sorter: (a, b) => a.description.localeCompare(b.description),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DATA_GROUP:FIELD_LEVEL")}
        </Box>
      ),
      dataIndex: "level",
      key: "level",
      width: 100,
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.level - b.level,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at-b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("DATA_GROUP:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 110,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:ACTION")}
        </Box>
      ),
      dataIndex: "action",
      key: "action",
      width :105
    },
  ];
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          id={ID_DATA_GROUP.LIST.BTN_X}
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };
  const [filterDataGroup, setFilterDataGroup] = useState("");
  const [form, setForm] = useState("");
  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setisDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setisDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };
  const submitOk = () => {
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      setFilterDataGroup(form);
      setPopOverOpen(false);
    }
  };
  const resetFilter = () => {
    setForm("");
    setFilterDataGroup("");
    setButtonSearchOrClose("search");
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
  };
  const handleCloseButtonSearch = () => {
    setForm("");
    setButtonSearchOrClose("search");
    setisDisabled(true);
    setPopOverOpen(false);
    setPage(1);
    setLimit(10);
  };
  const filter = "group_id like " + filterDataGroup;
  const params =
    filterDataGroup === ""
      ? { page, limit, order }
      : { page, limit, filter, order };
  useEffect(() => {
    dispatch(getInitiateListDataGroup(params)).then((resp) => {
      setTotal(resp.payload.response.count_data);
    });

    const fetchData = () => {
      dispatch(getListDataGroup(params));
    };
    fetchData();
  }, [page, limit, token, total, order, filterDataGroup, dispatch]);
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("DATA_GROUP:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("COMMON:HOME")}</span> -{" "}
            {t("DATA_GROUP:TITLE_LIST")}
          </>
        }
        button={
          <ButtonPrimary
            link={ROUTES.DATA_GROUP.ADD}
            icon={<Icon as={AddIcon} />}
            text={t("DATA_GROUP:ADD_DATA_GROUP")}
            id={ID_DATA_GROUP.LIST.BTN_ADD}
          />
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"18.5rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={5}>
          <InputGroup
            width="257px"
            height="40px"
            borderRadius={6}
            backgroundColor={WHITE_COLOR}
          >
            <Input
              type="text"
              placeholder={t("DATA_GROUP:SEARCH_GROUP_ID")}
              fontSize="14px"
              onChange={(e) => handleChange(e)}
              name="group_id"
              id={ID_DATA_GROUP.LIST.SEARCH}
              value={form}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>

          <Box marginRight="23px" marginLeft="12px">
            <ButtonCustom
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              isDisabled={isDisabled}
              id={ID_DATA_GROUP.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_DATA_GROUP.LIST.RESET_FILTER}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleTableChange}
          loading={table_loading}
          size="small"
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  borderRadius="6px"
                  borderColor="#CCCCCC"
                  id={ID_DATA_GROUP.LIST.ROW_PER_PAGE}
                  size="sm"
                  width="70px"
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                >
                  {selectPaginationOption?.map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  id={ID_DATA_GROUP.LIST.PAGINATION}
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListDataGroup;
