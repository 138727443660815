import {
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  inputTheme,
  multiSelectTheme,
} from "../../../../../constant/themeConstant";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  addProductSubtitute,
  getListPrincipal,
  getListProductCategory,
  getListProductPrincipal,
} from "../../../../../services/main/productSubtituteReducer";
import { useFormik } from "formik";
import { addProductSubtituteSchema } from "./productSubtituteSchema";
import ModalCustom from "../../../../../components/ModalCustom";
import {
  SavedSuccess,
  UnSuccess,
} from "../../../../../components/icons";
import { Redirect } from "react-router-dom";
import StringUtil from "../../../../../utils/stringUtil";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { useMemo } from "react";
import { ID_PRODUCT_SUBSTITUTE } from "../../../../../constant/idConstant";
const AddProductSubtitute = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);

  const [pageProductPrincipalFrom, setPageProductPrincipalFrom] = useState(1);
  const [limitProductPrincipalFrom, setLimitProductPrincipalFrom] =
    useState(10);

  const [pageProductPrincipalTo, setPageProductPrincipalTo] = useState(1);
  const [limitProductPrincipalTo, setLimitProductPrincipalTo] = useState(10);

  const [pageProductCategory, setPageProductCategory] = useState(1);
  const [limitProductCategory, setLimitProductCategory] = useState(10);

  // Code(for disabled input)
  const [principalCode, setPrincipalCode] = useState("");
  const [productCategoryCode, setproductCategoryCode] = useState("");
  const [principalProductCodeFrom, setPrincipalProductCodeFrom] = useState("");
  const [principalProductCodeTo, setPrincipalProductCodeTo] = useState("");

  const handleClear = (name) => {
    switch (name) {
      case "product_principal_id_from":
        setFieldValue("product_principal_id_to", null);
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "product_category_id":
        setFieldValue("product_category_id", value);
        setproductCategoryCode(option?.fulldata?.product_category_code);
        break;
      case "product_principal_id_from":
        setFieldValue("product_principal_id_from", value);
        setPrincipalProductCodeFrom(option);
        // fetchDataPrincipalProductTo();
        break;
      case "product_principal_id_to":
        setFieldValue("product_principal_id_to", value);
        setPrincipalProductCodeTo(option?.fulldata?.product_code);
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };
  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  // product category
  const [dataProductCategory, setDataProductCategory] = useState([]);
  const [filterProductCategory, setfilterProductCategory] = useState("");
  const fetchDataProductCategory = () => {
    const page = pageProductCategory;
    const limit = limitProductCategory;
    const is_check_status = true;
    const params = { page, limit, is_check_status };
    dispatch(getListProductCategory(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductCategory([...dataProductCategory, ...resp]);
      // setPrincipalCode(resp[0]?.principal_code);
    });
  };
  const fetchSearchProductCategory = () => {
    const page = pageProductCategory;
    const limit = limitProductCategory;
    const is_check_status = true;
    const filter = StringUtil.formatFilter({
      product_category_name: `like ${filterProductCategory ?? ""}`,
    });
    const params = { page, limit, filter, is_check_status };
    dispatch(getListProductCategory(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductCategory(1);
      setDataProductCategory(response.payload.response);
    });
  };

  // const handleScrollProductCategory = () => {
  //   setPageProductCategory(pageProductCategory + 1);
  // };

  // principal product from
  const [dataPrincipalProductFrom, setDataPrincipalProductFrom] = useState([]);
  const fetchDataPrincipalProductFrom = () => {
    const page = pageProductPrincipalFrom;
    const limit = limitProductPrincipalFrom;
    const is_check_status = true;
    const params = { page, limit, is_check_status };
    dispatch(getListProductPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipalProductFrom([...dataPrincipalProductFrom, ...resp]);
      // setPrincipalCode(resp[0]?.principal_code);
    });
  };

  const handleScrollPrincipalProductFrom = () => {
    setPageProductPrincipalFrom(pageProductPrincipalFrom + 1);
  };

  // principal product to
  const [dataPrincipalProductTo, setDataPrincipalProductTo] = useState([]);
  const [idWithUnderScore, setidWithUnderScore] = useState("");
  const [filterProductPrincipalTo, setFilterProductPrincipalTo] = useState("");

  const fetchDataPrincipalProductTo = () => {
    const page = pageProductPrincipalTo;
    const limit = limitProductPrincipalTo;
    const is_check_status = true;
    // const valueWithUnderScore = values?.product_principal_id_from.join("_");
    const filter = StringUtil.formatFilter({
      id: `not_list_id ${idWithUnderScore ?? ""}`,
    });
    const params = { page, limit, filter, is_check_status };
    if (!values?.product_principal_id_from) {
      setDataPrincipalProductTo([]);
    } else {
      dispatch(getListProductPrincipal(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataPrincipalProductTo([...dataPrincipalProductTo, ...resp]);
      });
    }
  };

  const fetchSearchPrincipalProductTo = () => {
    const page = pageProductPrincipalTo;
    const limit = limitProductPrincipalTo;
    const is_check_status = true;
    const filter = StringUtil.formatFilter({
      product_code: `like ${filterProductPrincipalTo ?? ""}`,
      id: `not_list_id ${idWithUnderScore ?? ""}`,
    });

    const params = { page, limit, filter, is_check_status };
    if (!values?.product_principal_id_from) {
      setDataPrincipalProductTo([]);
    } else {
      dispatch(getListProductPrincipal(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        setDataPrincipalProductTo(resp);
      });
    }
  };

  const handleScrollProductPrincipalTo = () => {
    setPageProductPrincipalTo(pageProductPrincipalTo + 1);
  };

  const onSubmit = () => {
    dispatch(addProductSubtitute(values)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      product_category_id: null,
      product_principal_id_from: [],
      product_principal_id_to: null,
      status: "",
    },
    validationSchema: addProductSubtituteSchema(t),
    onSubmit,
  });

  useMemo(
    () => setidWithUnderScore(values?.product_principal_id_from.join("_")),
    [values?.product_principal_id_from]
  );

  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);
  // product category
  useEffect(() => {
    fetchDataProductCategory();
  }, [pageProductCategory]);
  useEffect(() => {
    fetchSearchProductCategory();
  }, [filterProductCategory]);
  // principal product from
  useEffect(() => {
    fetchDataPrincipalProductFrom();
  }, [pageProductPrincipalFrom]);

  // principal product to
  useEffect(() => {
    fetchDataPrincipalProductTo();
  }, [pageProductPrincipalTo]);
  useEffect(() => {
    fetchSearchPrincipalProductTo();
  }, [filterProductPrincipalTo, values?.product_principal_id_from]);

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_SUBTITUTE:ADD_PRODUCT_PRODUCT_SUBTITUTE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_SUBTITUTE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_SUBTITUTE:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_SUBTITUTE:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_SUBTITUTE:ADD_PRODUCT_PRODUCT_SUBTITUTE")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* principal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_SUBSTITUTE.ADD.PRINCIPAL_CODE}
                  disabled
                  value={principalCode ? principalCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* product category */}
            {/* <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_SUBTITUTE:FIELD_PRODUCT_CATEGORY")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  value={productCategoryCode ? productCategoryCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box> */}
            {/* principal product code from */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_CODE_FROM")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_SUBSTITUTE.ADD.PRINCIPAL_PRODUCT_CODE_FROM}
                  disabled
                  value={
                    principalProductCodeFrom
                      ? principalProductCodeFrom?.map((val) => {
                          return val.fulldata.product_code;
                        })
                      : []
                  }
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* principal product code to */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_CODE_TO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_PRODUCT_SUBSTITUTE.ADD.PRINCIPAL_PRODUCT_CODE_TO}
                  style={inputTheme}
                  disabled
                  value={principalProductCodeTo ? principalProductCodeTo : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* Principal Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  id={ID_PRODUCT_SUBSTITUTE.ADD.PRINCIPAL_NAME}
                  style={inputTheme}
                  disabled
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Product Category Name */}
            {/* <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRODUCT_CATEGORY_NAME")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  onPopupScroll={handleScrollProductCategory}
                  size="large"
                  allowClear
                  value={values?.product_category_id}
                  name="product_category_id"
                  onBlur={handleBlur("product_category_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_category_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductCategory(e.target.value)}
                    />
                  )}
                >
                  {dataProductCategory?.map((data, index) => {
                    return (
                      <Select.Option
                        value={data.id}
                        key={index.toString()}
                        fulldata={data}
                      >
                        {data.product_category_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.product_category_id && touched.product_category_id && (
                  <ValidationError text={errors.product_category_id} />
                )}
              </Box>
            </Box> */}
            {/* principal product name from*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_FROM")}
                </Text>
              </Box>
              <Box width="250px">
                <Select
                  style={multiSelectTheme}
                  showArrow
                  id={ID_PRODUCT_SUBSTITUTE.ADD.PRINCIPAL_PRODUCT_NAME_FROM}
                  size="large"
                  maxTagCount={1}
                  onClear={() => handleClear("product_principal_id_from")}
                  onPopupScroll={handleScrollPrincipalProductFrom}
                  allowClear
                  mode="multiple"
                  value={values?.product_principal_id_from}
                  name="product_principal_id_from"
                  onBlur={handleBlur("product_principal_id_from")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_principal_id_from", value, opt)
                  }
                >
                  {dataPrincipalProductFrom?.map((data, index) => {
                    return (
                      <Select.Option
                        value={data.id}
                        key={index.toString()}
                        fulldata={data}
                        title={data.product_code}
                      >
                        {data.product_code}
                        {" - "}
                        {data.product_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.product_principal_id_from &&
                  touched.product_principal_id_from && (
                    <ValidationError text={errors.product_principal_id_from} />
                  )}
              </Box>
            </Box>
            {/* principal product name to */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_SUBTITUTE:FIELD_PRINCIPAL_PRODUCT_TO")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_PRODUCT_SUBSTITUTE.ADD.PRINCIPAL_PRODUCT_NAME_TO}
                  onPopupScroll={handleScrollProductPrincipalTo}
                  allowClear
                  value={values?.product_principal_id_to}
                  name="product_principal_id_to"
                  onBlur={handleBlur("product_principal_id_to")}
                  onChange={(value, opt) =>
                    handleChangeSelect("product_principal_id_to", value, opt)
                  }
                  disabled={
                    values?.product_principal_id_from.length > 0 ? false : true
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) =>
                        setFilterProductPrincipalTo(e.target.value)
                      }
                    />
                  )}
                >
                  {dataPrincipalProductTo?.map((data, index) => {
                    return (
                      <Select.Option
                        value={data.id}
                        key={index.toString()}
                        fulldata={data}
                        title={data.product_code}
                      >
                        {data.product_code}
                        {" - "}
                        {data.product_name}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.product_principal_id_to &&
                  touched.product_principal_id_to && (
                    <ValidationError text={errors.product_principal_id_to} />
                  )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_SUBTITUTE:FIELD_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  id={ID_PRODUCT_SUBSTITUTE.ADD.STATUS}
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_SUBTITUTE.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_SUBSTITUTE.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_SUBSTITUTE.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <UnSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddProductSubtitute;
