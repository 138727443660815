import { Box, Stack, Text } from "@chakra-ui/react";
import { Select } from "antd";
import {
  ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../components/button/ButtonCustom";
import InputDateRangePicker from "../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../components/DropDownCustom";
import HeadTitle from "../../components/HeadTitle";
import { ID_DASHBOARD } from "../../constant/idConstant";
import {
  GRAY_COLOR,
  GRAY_DISABLED,
  PRIMARY_COLOR,
  WHITE_COLOR
} from "../../constant/propertiesConstant";
import { selectOptFilterPrincipal } from "../../constant/themeConstant";
import { getListDashboardBuyer, getListPrincipalDDS } from "../../services/main/dashboardReducer";
import { getListPrincipal } from "../../services/main/warehouseReducer";
import StringUtil from "../../utils/stringUtil";
const Dashboard = () => {
  const today = new Date();
  const currentWeek = moment().subtract(7, "days").toDate();
  const thisMonth = moment().startOf("month").toDate();

  const [filterPicker, setfilterPicker] = useState("This Week");

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [listData, setListData] = useState([]);
  const data = useSelector((state) => state.dashboard.listDashboardCS);
  const { token } = useSelector((state) => state.auth);
  const [dateRange, setDateRange] = useState([currentWeek, today]);

  const [principalId, setPrincipalId] = useState(9);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);
  const body = {
    start_date: moment(dateRange[0]).format("YYYY-MM-DD") +
      "T00:00:00Z",
    end_date: moment(dateRange[1]).format("YYYY-MM-DD") +
      "T23:59:59Z",
    principal_id: principalId,
    // sementara make customer_id kalo nanti kosong, hapus, BE udah berubah barti

  };
  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };

  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "principal_id") {
      setPrincipalId(value);
    }
  };

  useEffect(() => {
    dispatch(getListDashboardBuyer(body));
    setSubmit(false);
    setIsDisabled(true);
  }, [token, submit, dispatch]);

  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setDateRange([start, end]);
    } else setDateRange([]);
    setIsDisabled(false);
  };

  const submitOk = () => {
    setSubmit(true);
  };

  const resetFilter = () => {
    setSubmit(true);
    setIsDisabled(true);
    setfilterPicker("This Week");
    setDateRange([currentWeek, today]);
  };
  const handleChangeSelectPicker = (name, value, option) => {
    setIsDisabled(false);
    setfilterPicker(option.children);
    if (option.value === "thisWeek") {
      setDateRange([currentWeek, today]);
    } else if (option.value === "thisMonth") {
      setDateRange([thisMonth, today]);
    }
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
  );
  useEffect(() => {
    setListData(data);
  }, [data]);
  const dataPODaily =
    listData.length === 0
      ? []
      : listData?.total_po_buyer_per_seller.reduce(
        (previousValue, currentValue, index) => {
          const { Success_PO } = previousValue;

          // Failed_PO.push({
          //   x: currentValue.principal_alias,
          //   y: currentValue.failed_po,
          // });
          Success_PO.push({
            x: currentValue.principal_alias,
            y: currentValue.success_po,
          });

          return previousValue;
        },
        {
          // Failed_PO: [],
          Success_PO: [],
        }
      );

  const DASHBOARDPO =
    listData.length === 0
      ? []
      : listData?.po_daily_buyer?.reduce(
        (previousValue, currentValue, index) => {
          const { date, value } = previousValue;

          value.push(currentValue.total);
          date.push(currentValue.created_at);

          return previousValue;
        },
        {
          date: [],
          value: [],
        }
      );
  const datatranslate =
    DASHBOARDPO?.length === 0
      ? []
      : DASHBOARDPO?.date.map((item) =>
        moment(item, "YYYY-DD-MM").format("D MMM")
      );
  const POBuyer =
    listData.length === 0
      ? []
      : Object.entries(listData?.total_po_buyer).reduce(
        (prev, curr, index) => {
          const {  data } = prev;
          const [ value] = curr;
          data.push(value);
          return prev;
        },
        {
          data: [],
        }
      );

  const StoreBuyer =
    listData.length === 0
      ? []
      : Object.entries(listData?.total_store_buyer).reduce(
        (prev, curr, index) => {
          const { data } = prev;
          const [value] = curr;
          data.push(value);
          return prev;
        },
        {
          data: [],
        }
      );

  const options = {
    barThickness: 20,
    borderRadius: 5,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // buat ngeubah title
      title: {
        display: false,
        text: "Chart.js Bar Chart - Stacked",
      },

      // buat ngeubah sub title
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
      },
    },
    // ini buat matin atau aktifin garis grid sesuai axis X atau Y
    scales: {
      x: {
        // numpuk kearah atas
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
      y: {
        // numpuk kearah kanan
        stacked: true,
        grid: {
          drawBorder: false,
          color: GRAY_DISABLED,
        },
      },
    },
  };

  const dataDASHBOARDPO = {
    labels: [],
    datasets: [
      {
        label: t("DASHBOARD:SUB_SUCCESS_PO"),
        data: dataPODaily?.Success_PO,
        backgroundColor: "#5DBB55",
      },
    ],
  };

  const optionsPOSeller = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: 20,
    // borderRadius: 5,
    plugins: {
      // buat ngeubah title
      title: {
        display: true,
        text: "Total PO By Seller",
        labels: {
          usePointStyle: true,
        },
        color: "black",
        font: {
          size: 16,
          weight: "bold",
        },
      },

      // buat ngeubah sub title
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
    },
    // ini buat matin atau aktifin garis grid sesuai axis X atau Y
    scales: {
      x: {
        // numpuk kearah atas
        stacked: true,
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
      },
      y: {
        // numpuk kearah kanan
        stacked: true,
        grid: {
          drawBorder: false,
          color: GRAY_DISABLED,
        },
      },
    },
  };
  const dataPOSeller = {
    labels: datatranslate,
    datasets: [
      {
        // label: "Dataset 1",
        data: DASHBOARDPO?.value,
        backgroundColor: "#9257DE",
      },
    ],
  };

  // pie total PO
  const dataPO = {
    labels: [t("DASHBOARD:SUB_SUCCESS_PO"), t("DASHBOARD:SUB_FAILED_PO")],
    datasets: [
      {
        data: POBuyer?.data,
        backgroundColor: ["#5DBB55", "hsl(0, 100%, 47%)"],
        // borderWidth: 0,
      },
    ],
  };
  const optionsPO = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // buat ngeubah title
      title: {
        display: true,
        text: "Total PO ",
        labels: {
          usePointStyle: true,
        },
        color: "black",
        font: {
          size: 16,
          weight: "bold",
        },
      },

      // buat ngeubah sub title
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 40,
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
    },
  };

  // pie total PO
  const dataStore = {
    labels: [t("DASHBOARD:SUB_STORE_MAPPING"), t("DASHBOARD:SUB_STORE_UNMAPPING")],
    datasets: [
      {
        data: StoreBuyer?.data,
        backgroundColor: ["#9257DE", "#EAB52C"],
      },
    ],
  };
  const optionsStore = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // buat ngeubah title
      title: {
        display: true,
        text: "Total Store",
        labels: {
          usePointStyle: true,
        },
        color: "black",
        font: {
          size: 16,
          weight: "bold",
        },
      },

      // buat ngeubah sub title
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 40,
          usePointStyle: true,
          font: {
            size: 10,
          },
        },
      },
    },
  };
  return (
    <Box p={4}>
      <HeadTitle
        title={t("DASHBOARD:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DASHBOARD:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DASHBOARD:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DASHBOARD:SUBTITLE_3")}
            </span>{" "}
            - {t("DASHBOARD:TITLE_LIST")}
          </>
        }
        button={
          <Stack direction="row" alignItems="center">
            <Box width="240px">
              <InputDateRangePicker
                elementId={ID_DASHBOARD.LIST.DATE}
                values={dateRange}
                onChange={handleChangeDate}
                defaultValue={[currentWeek, today]}
              />
            </Box>
            <Box className="my-select-container">
              <Select
                style={selectOptFilterPrincipal}
                onChange={(value, opt) =>
                  handleChangeSelectPicker("datePick", value, opt)
                }
                allowClear="true"
                size="large"
                placeholder="Range Pick Date"
                value={filterPicker}
              >
                <Select.Option value={"thisMonth"}>This Month</Select.Option>
                <Select.Option value={"thisWeek"}>This Week</Select.Option>
              </Select>
            </Box>
            <Box className="my-select-container">
              <Select
                id={ID_DASHBOARD.LIST.PRINCIPAL}
                style={selectOptFilterPrincipal}
                value={principalId}
                onPopupScroll={handleScrollPrincipal}
                onChange={(value, opt) =>
                  handleChangeSelect("principal_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(e) => setFilterPrincipalName(e.target.value)}
                  />
                )}
                showSearch={false}
                allowClear="true"
                placeholder={t("COMMON:SELECT_PRINCIPAL")}
                size="large"
              >
                {dataPrincipal &&
                  dataPrincipal.map((data, index) => {
                    return (
                      <Select.Option key={index} value={data.id}>
                        {data.company_profile_name}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Box>
            <Box>
              <ButtonCustom
                id={ID_DASHBOARD.LIST.BTN_SUBMIT}
                text={t("COMMON:BUTTON_APPLY")}
                backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
                color={WHITE_COLOR}
                fontWeight={500}
                fontSize={14}
                width="93px"
                onClick={submitOk}
                marginRight={2}
                isDisabled={isDisabled}
              />
            </Box>
            <Box>
              <Text
                id={ID_DASHBOARD.LIST.BTN_RESET}
                color={PRIMARY_COLOR}
                fontWeight={500}
                fontSize={14}
                onClick={resetFilter}
                pointerEvents="auto"
                cursor="pointer"
              >
                {t("COMMON:BUTTON_RESET_FILTER")}
              </Text>
            </Box>
          </Stack>
        }
      />
      {/* white body dashboard single */}
      <Box
        backgroundColor="white"
        height="365px"
        padding={4}
        display="flex"
        flexDirection={"column"}
        marginTop={4}
        marginBottom={4}
        borderRadius="10px"
      >
        <Box
          pt={2}
          pb={4}
          display="flex"
          justifyContent="space-between"
          textAlign="center"
        >
          <Box>
            <HeadTitle titleSection={t("DASHBOARD:PROCESSED_PO")} />
          </Box>
        </Box>
        <Box height="365px">
          <Bar options={options} data={dataPOSeller} />
        </Box>
      </Box>

      {/* triple white board */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginTop={4}
      >
        {/* Po Seller */}
        <Box
          p={4}
          width="25vw"
          height="20vw"
          backgroundColor="white"
          borderRadius="10px"
        >
          <Bar options={optionsPOSeller} data={dataDASHBOARDPO} />
        </Box>
        <Box
          p={4}
          width="25vw"
          height="20vw"
          backgroundColor="white"
          borderRadius="10px"
        >
          <Pie options={optionsPO} data={dataPO} />
        </Box>
        <Box
          p={4}
          width="25vw"
          height="20vw"
          backgroundColor="white"
          borderRadius="10px"
        >
          <Pie options={optionsStore} data={dataStore} />
        </Box>
      </Box>
    </Box>
  );
};
export default Dashboard;
