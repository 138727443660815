import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  inputTheme,
  multiSelectTheme,
} from "../../../../../constant/themeConstant";
import { selectStatus } from "../../../../../constant/selectOptConstant";
import {
  detailProductCategoryForEdit,
  editProductCategory,
  getListDistributor,
  getListPrincipal,
} from "../../../../../services/main/productCategoryReducer";
import { useFormik } from "formik";
import { editProductCategorySchema } from "./productCategorySchema";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import { Redirect, useLocation } from "react-router-dom";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { ID_PRODUCT_CATEGORY } from "../../../../../constant/idConstant";
const EditProductCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const principalName = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const data_detail = useSelector(
    (state) => state.productCategory.edit_product_category
  );
  const [isLoading, setIsLoading] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  /// modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };
  //page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);

  //Code Distributor
  // const [codeDistributor, setcodeDistributor] = useState();

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "distributor_id":
        setFieldValue(
          "distributor_id",
          option.map((val) => {
            return {
              value: val.value,
              label: val.children,
            };
          })
        );
        setFieldValue(
          "distributor_code",
          option?.map((val) => {
            return val.code;
          })
        );
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };

  const onSubmit = () => {
    const product_id = location.state.id;
    const payload = {
      ...values,
      principalId,
      product_id,
    };
    dispatch(editProductCategory(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      product_category_name: data_detail?.product_category_name,
      product_category_code: data_detail?.product_category_code,
      distributor_id:
        data_detail?.distributor?.map((val) => ({
          value: val.distributor_id,
          label: val.distributor_name,
          code: val.distributor_code,
        })) ?? [],
      distributor_code: data_detail?.distributor?.map((val) => {
        return val.distributor_code;
      }),
      status: data_detail?.status,
      updatedAt: updatedAt,
    },
    validationSchema: editProductCategorySchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  });
  const fetchDataDetail = () => {
    setIsLoading(true);
    dispatch(detailProductCategoryForEdit(location.state.id)).then(
      (response) => {
        if (response.payload.ok) {
          setUpdatedAt(response.payload.response.updated_at);
        }
      }
    );
    setIsLoading(false);
  };

  ///Principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [principalCode, setPrincipalCode] = useState("");
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };

  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const is_check_status = true;
    const params = { page, limit, is_check_status };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const handleScrollDistributor = () => {
    setPageDistributor(pageDistributor + 1);
  };

  // const distributorCode = useMemo(
  //   () =>
  //     values?.distributor_id.reduce((prev, current, index, arr) => {
  //       if (index === arr.length - 1) {
  //         prev += current.code;
  //       } else {
  //         prev += `${current.code}, `;
  //       }

  //       return prev;
  //     }, ""),
  //   [values?.distributor_id]
  // );
  // Use Effect
  useEffect(() => {
    fetchDataDetail();
  }, []);
  // Principal
  useEffect(() => {
    fetchDataPrincipal();
  }, [pagePrincipal]);
  // Distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY:EDIT_PRODUCT_CATEGORY")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_CATEGORY:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY:BUTTON_1")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* Principal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  disabled
                  id={ID_PRODUCT_CATEGORY.EDIT.PRINCIPAL_CODE}
                  backgroundColor={GRAY_COLOR}
                  value={
                    principalCode ? principalCode : data_detail?.principal_code
                  }
                />
              </Box>
            </Box>
            {/* Distributor Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_CODE")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_CATEGORY.EDIT.DISTRIBUTOR_CODE}
                  disabled
                  backgroundColor={GRAY_COLOR}
                  // value={distributorCode}
                  value={values?.distributor_code}
                />
              </Box>
            </Box>
            {/* Product Category Code*/}
            <Box padding={1} display="flex">
              <Box width="10rem" pt={2} pl={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  style={inputTheme}
                  onChange={handleChange}
                  id={ID_PRODUCT_CATEGORY.EDIT.PRODUCT_CATEGORY_CODE}
                  name="product_category_code"
                  value={values?.product_category_code}
                />
                {errors.product_category_code &&
                  touched.product_category_code && (
                    <ValidationError text={errors.product_category_code} />
                  )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* principal name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_NAME")}</Text>
              </Box>
              <Box width="250px">
                <Input
                  id={ID_PRODUCT_CATEGORY.EDIT.PRINCIPAL_NAME}
                  type="text"
                  name="principal_name"
                  style={inputTheme}
                  disabled
                  background={GRAY_COLOR}
                  value={principalName}
                />
              </Box>
            </Box>
            {/* distributor name  */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2.5}>
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME")}
                </Text>
              </Box>
              <Box width={"250px"}>
                <Select
                  style={multiSelectTheme}
                  size="large"
                  showArrow
                  mode="multiple"
                  allowClear
                  id={ID_PRODUCT_CATEGORY.EDIT.DISTRIBUTOR_NAME}
                  maxTagCount={1}
                  onPopupScroll={handleScrollDistributor}
                  name="distributor_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                  value={values?.distributor_id}
                >
                  {dataDistributor &&
                    dataDistributor?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data?.id}
                          name={data?.name}
                          code={data?.distributor_code}
                          fulldata={data}
                        >
                          {data?.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && touched.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>

            {/* <Box marginLeft={"10.3rem"}>
             
            </Box> */}
            {/* product category name */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2} pt={0} pr={2}>
                <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME")}</Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  name="product_category_name"
                  onChange={handleChange}
                  style={inputTheme}
                  background={GRAY_COLOR}
                  value={values?.product_category_name}
                  id={ID_PRODUCT_CATEGORY.EDIT.PRODUCT_CATEGORY_NAME}
                />
                {errors.product_category_name &&
                  touched.product_category_name && (
                    <ValidationError text={errors.product_category_name} />
                  )}
              </Box>
            </Box>

            {/* product status */}
            <Box padding={1} display="flex">
              <Box width="10rem" pl={2.5}>
                <Text overflowWrap="break-word" width="80px">
                  {t("PRODUCT_CATEGORY:FIELD_PRODUCT_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  id={ID_PRODUCT_CATEGORY.EDIT.STATUS}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_CATEGORY.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_CATEGORY.EDIT.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default EditProductCategory;
