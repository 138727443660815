import FileSaver from "file-saver";

const DownloadFunctionCSV = ( fileContent, fileName ) => {
  var dataType = fileName.split(".")[1];
  var bs = atob(fileContent);
  var buffer = new ArrayBuffer(bs.length);
  var ba = new Uint8Array(buffer);
  for (var i = 0; i < bs.length; i++) {
    ba[i] = bs.charCodeAt(i);
  }
  var file = new Blob([ba], { type: `application/${dataType}` });

  FileSaver.saveAs(file, fileName);
};

export default DownloadFunctionCSV;
