import React from "react";
import { Icon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { sortIcon } from "./icons";

const SortButton = ({ sortField, thisField, sortType }) => {
  return sortField === thisField ? (
    sortType === "asc" ? (
      <TriangleUpIcon w={2} h={2} color="#4D4F5C" opacity=".4" marginBottom="2px" marginLeft="1px" />
    ) : this.props?.sortType === "desc" ? (
      <TriangleDownIcon w={2} h={2} color="#4D4F5C" opacity=".4" marginBottom="2px" marginLeft="1px" />
    ) : (
      <Icon as={sortIcon} w={3} h={3} marginBottom="2px" marginLeft="1px" />
    )
  ) : (
    <Icon as={sortIcon} w={3} h={3} marginBottom="2px" marginLeft="1px" />
  );
};

export default SortButton;
