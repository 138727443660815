import {
  Badge, Box, Center, Divider, Stack, Text
} from "@chakra-ui/react";
import { Spin } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  badgeColor, BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR
} from "../../../../constant/propertiesConstant";
import { detailRoles, roleAction } from "../../../../services/main/roleReducer";
import BodyPermission3 from "./body/BodyPermission3";
import BodyRole from "./body/BodyRole";
const EditRole = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  // const [curr_page, setcurr_page] = useState(t("ROLE:FIELD_ROLE"));
  // const [index_page, setindex_page] = useState(0);
  const { index_page } = useSelector((state) => state.role);
  const { curr_page } = useSelector((state) => state.role);
  const { detail_for_edit } = useSelector((state) => state.role);
  const { detail_loading } = useSelector((state) => state.role);
  const renderMainBody = () => {
    if (detail_loading) {
      return (
        <>
          <Center marginTop={100}>
            <Spin size="small" />
          </Center>
        </>
      );
    } else {
      if (curr_page === t("ROLE:FIELD_ROLE")) {
        return <BodyRole />;
      }
      if (curr_page === t("ROLE:FIELD_PERMISSION")) {
        return <BodyPermission3 />;
      }
    }
  };
  const fetchDataDetail = () => {
    dispatch(detailRoles(location.state.id)).then((response) => {
      if (response.payload.ok) {
        dispatch(
          roleAction.setUpdatedAt(
            response.payload.response.nexsoft.payload.data.content.updated_at
          )
        );
        dispatch(
          roleAction.setDetailRoleForEdit(
            response.payload.response.nexsoft.payload.data.content
          )
        );
        dispatch(
          roleAction.setDetailRoleRole(
            response.payload.response.nexsoft.payload.data.content
          )
        );
      } else {
      }
    });
  };
  const setCurrPage = () => {
    dispatch(roleAction.setCurrPage(t("ROLE:FIELD_ROLE")));
    dispatch(roleAction.setIndexPage(0));
  };
  useEffect(() => {
    dispatch(roleAction.resetReduxRole);
    setCurrPage();
    fetchDataDetail();
  }, []);

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("ROLE:EDIT_ROLE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>{t("COMMON:HOME")}</span> -{" "}
            <span style={{ color: PRIMARY_COLOR }}>{t("ROLE:TITLE_LIST")}</span>{" "}
            - {t("ROLE:EDIT_ROLE")}
          </>
        }
      />
      <Box marginTop={6} minHeight="4.375rem">
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <Box
            backgroundColor="white"
            width={230}
            marginBottom={3}
            height="64px"
            display="flex"
            borderRadius="8px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.31)"
          >
            <Stack direction="row" p={4}>
              <Badge
                borderRadius="full"
                paddingTop={0.5}
                px="2"
                width="24px"
                height="24px"
                variant="solid"
                colorScheme={
                  index_page >= 0
                    ? badgeColor.primaryColor
                    : badgeColor.primaryGray
                }
              >
                <Center>
                  <Text color={WHITE_COLOR}>{t("ROLE:NUMBER_ONE")}</Text>
                </Center>
              </Badge>
              <Text color={index_page >= 0 ? PRIMARY_COLOR : BLACK_COLOR}>
                {t("ROLE:FIELD_ROLE")}
              </Text>
              <Divider
                orientation="vertical"
                borderColor="blackAlpha.500"
                marginEnd={2}
              />
              <Badge
                borderRadius="full"
                px="2"
                paddingTop={0.5}
                width="24px"
                height="24px"
                variant="solid"
                colorScheme={
                  index_page >= 1
                    ? badgeColor.primaryColor
                    : badgeColor.primaryGray
                }
              >
                <Center>
                  <Text color={WHITE_COLOR}>{t("ROLE:NUMBER_TWO")}</Text>
                </Center>
              </Badge>
              <Text color={index_page >= 1 ? PRIMARY_COLOR : BLACK_COLOR}>
                {t("ROLE:FIELD_PERMISSION")}
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Box>
      {/* main body */}
      {renderMainBody()}
    </Box>
  );
};
export default EditRole;
