import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  dataGroupAction,
  getInitiateListDataGroup,
  getListDataGroupOption,
} from "../../../../../services/main/dataGroupReducer";
import {
  getInitiateListRole,
  getListRoleOption,
  roleAction,
} from "../../../../../services/main/roleReducer";
import React, { useEffect, useState, useCallback } from "react";
import { userAction } from "../../../../../services/main/userReducer";
import useEffectOnce from "../../../../../hooks/useEffectOnce";
import {
  GRAY_DISABLED,
  userCategoryType,
} from "../../../../../constant/propertiesConstant";
import { addAccessSettingSchema } from "../../userSchema";
import { useFormik } from "formik";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { inputTheme } from "../../../../../constant/themeConstant";
import { ID_USER } from "../../../../../constant/idConstant";
const BodyAccessSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // get data from redux
  const { access_setting } = useSelector((state) => state.user.detail_user);
  const { company_profile_access } = useSelector((state) => state.auth);
  const { index_page } = useSelector((state) => state.user);
  const { detail_user } = useSelector((state) => state.user);
  const accessSetting = detail_user.access_setting;
  const company_profile_name = useSelector((state) => state.auth.company_name);
  // role
  const [pageRole, setPageRole] = useState(1);
  const [limitRole, setLimitRole] = useState(10);
  const listRole = useSelector((state) => state.role.list_option);
  const { curr_role } = useSelector((state) => state.user);
  const { curr_group } = useSelector((state) => state.user);
  const [dataRole, setDataRole] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const paramsLevel14 = {
    filter: "level eq 14",
  };
  const paramsLevel13 = {
    filter: "level eq 13",
  };
  // data group
  const listDataGroup = useSelector((state) => state.dataGroup.list_option);
  // buat konversi dari backend biar jadi id lagi
  const [pageDataGroup, setpageDataGroup] = useState(1);
  const [limitDataGroup, setlimitDataGroup] = useState(10);
  const countDataGroup = useSelector(
    (state) => state.dataGroup.count_data_option
  );
  // untuk data buat di select opt di data grou
  const handleChangeRole = (name, value, opt) => {
    setFieldValue("role_id", {
      value: value,
      label: opt?.children,
    });
    dispatch(userAction.setCurrRole(opt.children));
  };
  const handleChangeDataGroup = (name, value, opt) => {
    setFieldValue("group_id", {
      value: value,
      label: opt?.children,
    });
    dispatch(userAction.setCurrGroup(opt.children));
  };
  const fetchDataRole2 = () => {
    let params = {
      page: pageRole,
      limit: limitRole,
      filter:
        detail_user?.access_setting?.user_category === userCategoryType.ADMIN
          ? paramsLevel13.filter
          : paramsLevel14.filter,
    };
    dispatch(getListRoleOption(params)).then((res) => {
      if (!res.payload.ok) return;
      const resp = res.payload.response;
      if (!resp) return;
      setDataRole([...dataRole, ...resp]);
    });
  };
  const [roleIdWithFilter, setRoleIdWithFilter] = useState(null);
  const fetchDataRoleWithFilter = useCallback(() => {
    let params = {
      page: pageRole,
      limit: limitRole,
      filter:
        "role_id like " +
        (curr_role === "" ? accessSetting.role_name : curr_role),
    };
    dispatch(getListRoleOption(params)).then((res) => {
      setRoleIdWithFilter(res?.payload?.response[0]);
    });
  }, [curr_role, accessSetting, dispatch]);

  const fetchDataGroup = useCallback(() => {
    let params = {
      page: pageDataGroup,
      limit: limitDataGroup,
    };
    dispatch(getListDataGroupOption(params)).then((res) => {});
  }, [pageDataGroup, limitDataGroup, dispatch]);
  const fetchDataGroup2 = () => {
    // const filter = StringUtil.formatFilter({
    //   principal_id: `eq ${values.principal_id ?? ""}`,
    //   customer_id: `eq ${values?.customer_id ?? ""}`,
    // });
    let params = {
      page: pageDataGroup,
      limit: limitDataGroup,
      filter:
        detail_user?.access_setting?.user_category === userCategoryType.ADMIN
          ? paramsLevel13.filter
          : paramsLevel14.filter,
    };
    dispatch(getListDataGroupOption(params)).then((res) => {
      if (!res.payload.ok) return;
      const resp = res.payload.response;
      if (!resp) return;
      setDataGroup([...dataGroup, ...resp]);
    });
  };
  const [groupIdWithFilter, setGroupIdWithFilter] = useState(null);
  const fetchDataGroupWithFilter = useCallback(() => {
    let params = {
      page: pageDataGroup,
      limit: limitDataGroup,
      filter:
        "group_id like " +
        (curr_group === "" ? accessSetting.group_name : curr_group),
    };

    dispatch(getListDataGroupOption(params)).then((res) => {
      setGroupIdWithFilter(res?.payload?.response[0]);
    });
  }, [curr_group, accessSetting, dispatch]);

  const handleScroll = (name) => {
    if (name === "role") {
      setPageRole(pageRole + 1);
    }
    if (name === "datagroup") {
      setpageDataGroup(pageDataGroup + 1);
    }
  };

  const renderUserCategory = (name) => {
    if (name === userCategoryType.ADMIN) return "Admin User";
    if (name === userCategoryType.CUSTOM) return "Custom User";
    if (name === userCategoryType.PRINCIPAL) return "Principal User";
    if (name === userCategoryType.SUPPORT) return "Support User";
  };
  const onSubmit = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACTIVATION")));
    // set index page
    dispatch(userAction.setIndexPage(index_page + 1));
    dispatch(
      userAction.setAccessSetting({
        ...access_setting,
        role_id: values?.role_id,
        group_id: values?.group_id,
      })
    );
    dispatch(
      userAction.setDetailUser({
        ...detail_user,
        accessSetting: {
          role_id: values?.role_id,
          group_id: values?.group_id,
        },
      })
    );
  };
  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
    dispatch(
      userAction.setAccessSetting({
        ...access_setting,
        role_id: values?.role_id,
        group_id: values?.group_id,
      })
    );
    dispatch(
      userAction.setDetailUser({
        ...detail_user,
        accessSetting: {
          role_id: values?.role_id,
          group_id: values?.group_id,
        },
      })
    );
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      role_id: {
        // value: roleIdWithFilter?.id,
        value: access_setting?.role_id,
        label: access_setting?.role_name,
      },
      group_id: {
        value: access_setting?.group_id,
        label: access_setting?.group_name,
      },
    },
    validationSchema: addAccessSettingSchema(t),
    onSubmit,
    isInitialValid: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    dispatch(getInitiateListRole()).then((response) => {
      fetchDataRole2();
      // fetchDataRoleWithFilter();
      dispatch(roleAction.setIsOption());
      dispatch(
        roleAction.setCountDataOption(response.payload.response.count_data)
      );
    });
  }, [pageRole]);
  useEffect(() => {
    dispatch(getInitiateListDataGroup()).then((response) => {
      fetchDataGroup2();
      // fetchDataGroupWithFilter();
      dispatch(dataGroupAction.setIsOption());
      dispatch(
        dataGroupAction.setCountDataOption(response.payload.response.count_data)
      );
    });
  }, [pageDataGroup]);

  useEffectOnce(() => {
    dispatch(
      userAction.setAccessSetting({
        ...access_setting,
        company_profile_access: company_profile_access,
      })
    );
  });
  return (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* User Category  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_USER_CATEGORY")}
          </Box>
          <Box>
            <Select
              placeholder={renderUserCategory(
                detail_user?.access_setting?.user_category
              )}
              style={inputTheme}
              id={ID_USER.EDIT.USER_CATEGORY}
              width="250px"
              size="large"
              disabled
              backgroundColor={GRAY_DISABLED}
              textColor="black"
            ></Select>
          </Box>
        </Box>
        {/* Company Profile   */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_COMPANY_PROFILE")}
          </Box>
          <Box>
            <Select
              placeholder={company_profile_name}
              width="250px"
              disabled
              style={inputTheme}
              textColor="black"
              id={ID_USER.EDIT.COMPANY_PROFILE}
              size="large"
              backgroundColor={GRAY_DISABLED}
            ></Select>
          </Box>
        </Box>

        {/* Role  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_ROLE")}
          </Box>
          <Box>
            <Select
              style={inputTheme}
              name="role_id"
              onBlur={handleBlur}
              value={values?.role_id}
              id={ID_USER.EDIT.ROLE}
              onPopupScroll={() => handleScroll("role")}
              onChange={(value, opt) => handleChangeRole("role_id", value, opt)}
              dropdownStyle
              allowClear="true"
              size="large"
            >
              {dataRole &&
                dataRole?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.role_id}
                    </option>
                  );
                })}
            </Select>
            {errors?.role_id?.value && touched.role_id?.value && (
              <ValidationError text={errors?.role_id?.value} />
            )}
          </Box>
        </Box>

        {/* Data Group  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("USER:FIELD_DATA_GROUP")}
          </Box>
          <Box>
            <Select
              style={inputTheme}
              id={ID_USER.EDIT.DATA_GROUP}
              name="group_id"
              value={values?.group_id}
              onPopupScroll={() => handleScroll("datagroup")}
              onChange={(value, opt) =>
                handleChangeDataGroup("group_id", value, opt)
              }
              allowClear="true"
              size="large"
              onBlur={handleBlur}
            >
              {dataGroup &&
                dataGroup?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.group_id}
                    </option>
                  );
                })}
            </Select>
            {errors?.group_id?.value && touched?.group_id?.value && (
              <ValidationError text={errors?.group_id?.value} />
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          type="button"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
          id={ID_USER.EDIT.BTN_PREVIOUS}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          id={ID_USER.EDIT.BTN_NEXT}
        />
      </Box>
    </form>
  );
};

export default BodyAccessSetting;
