import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import {
  DetailIcon,
  DownloadIcon,
  SearchIcon,
} from "../../../../components/icons";
import {
  GRAY_COLOR,
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import FileSaver from "file-saver";
import CustomCheckBox from "../../../../components/customCheckBox/CustomCheckBox";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../components/DropDownCustom";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { selectDropDownMonitoringUnmappedProduct } from "../../../../constant/selectOptConstant";
import {
  selectOptFilterPrincipal,
  selectOptFilterUserTheme,
} from "../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../hooks/useCheckUserCategory";
import { getListCustomer } from "../../../../services/main/customerReducer";
import {
  downloadUnmappedProduct,
  getInitListUnmappedProduct,
  getListCustomerDDS,
  getListPrincipalDDS,
  getListUnmappedProduct,
  unmappedProductReducerAction,
} from "../../../../services/main/monitoring/monitoringUnmappedProduct";
import { getListPrincipal } from "../../../../services/main/principalReducer";
import DateUtil, { FORMAT } from "../../../../utils/dateUtil";
import StringUtil from "../../../../utils/stringUtil";
import { ID_MONITORING } from "../../../../constant/idConstant";
const UnmappedProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.unmappedProduct.list_unmapped_product
  );
  const table_loading = useSelector(
    (state) => state.unmappedProduct.table_loading
  );
  const { status: statusCustomer } = useSelector((state) => state.customer);
  const { status: statusPrincipal } = useSelector((state) => state.principal);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState("");
  const [total, setTotal] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [form, setForm] = useState("");
  const [order, setOrder] = useState("");
  const [countSearch, setcountSearch] = useState("");
  const [popOverOpen, setPopOverOpen] = useState(false);
  // filter
  // filter transactiondate
  const [transactionDate, setTransactionDate] = useState("");
  // principal
  const [principalId, setPrincipalId] = useState(null);
  // get user role
  const role = useSelector((state) => state.auth.role);
  const principal_id_auth = useSelector((state) => state.auth.principal_id);

  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(null);
  const { token } = useSelector((state) => state.auth);

  const [objectFilter, setObjectFilter] = useState({
    idPrincipal: 1,
    idCustomer: null,
    form: "",
    updated_at_start: "",
    updated_at_end: "",
  });
  const { isAccepted } = useCheckUserCategory([
    userCategoryType.SUPPORT,
    userCategoryType.CUSTOM,
  ]);

  // List id data di page yang aktif
  const [listID, setListID] = useState([]);
  const setPrincipal = useMemo(() => {
    if (!isAccepted) {
      return setPrincipalId(principal_id_auth);
    }
    return setPrincipalId(1);
  }, [role]);

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const filter = StringUtil.formatFilter({
    principal_id: `eq ${objectFilter.idPrincipal}`,
    customer_id: `eq ${objectFilter.idCustomer}`,
    [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
  });

  const updated_at_start = objectFilter.updated_at_start;
  const updated_at_end = objectFilter.updated_at_end;

  const params = {
    page,
    limit,
    filter,
    updated_at_start,
    updated_at_end,
    order,
  };
  const fetchData = () => {
    dispatch(getListUnmappedProduct(params)).then((response) => {
      if (!response.payload.ok) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListUnmappedProduct(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    dispatch(unmappedProductReducerAction.resetCBX());

    fetchData();
    setSubmit(false);
    setIsDisabled(true);
  }, [token, total, submit, order, dispatch]);

  useEffect(() => {
    dispatch(getInitListUnmappedProduct(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });

    fetchData();
  }, [page, limit]);

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };

    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };

    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    if (statusPrincipal === "success") {
      setPagePrincipal(pagePrincipal + 1);
    }
  };
  // end fungsi princapl

  // fungsi customer
  const handleScrollCustomer = (event) => {
    if (statusCustomer === "success") {
      setPageCustomer(pageCustomer + 1);
    }
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  // filter transaction date
  const handleChangeTransactionDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setTransactionDate([start, end]);
    } else setTransactionDate([]);

    setIsDisabled(false);
  };

  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    } else setForm("");
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    setObjectFilter({
      idPrincipal: principalId,
      idCustomer: idCustomer,
      form: form,
      updated_at_start:
        transactionDate.length > 1
          ? moment(transactionDate[0]).format("YYYY-MM-DD")
          : "",
      updated_at_end:
        transactionDate.length > 1
          ? moment(transactionDate[1]).format("YYYY-MM-DD")
          : "",
    });
  };
  const resetFilter = () => {
    setForm("");
    setIsDisabled(true);
    setSubmit(true);
    setFilterPrincipalName("");
    setIdCustomer(null);
    setfilterCustomerName("");
    setButtonSearchOrClose("search");
    setTransactionDate([]);
    setSelectDropDownFilter("");
    setPage(1);
    setLimit(10);
    setcountSearch("");
    setPopOverOpen(false);
    dispatch(unmappedProductReducerAction.resetCBX());
    setObjectFilter({
      idPrincipal: 1,
      idCustomer: null,
      form: "",
      updated_at_start: "",
      updated_at_end: "",
    });
    if (isAccepted) {
      return setPrincipalId(1);
    }
    // principalId("")
  };
  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "principal_id") {
      setPrincipalId(value);
    } else if (name === "select_drop_down") {
      setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
    } else if (name === "customer_id") {
      setIdCustomer(value);
    }
  };

  const dataSelectedRowKeys = useSelector(
    (state) => state.unmappedProduct.selectedRowKeys
  );

  // ngecek ada data yang di select apa tidak
  const hasSelected = dataSelectedRowKeys?.length > 0;
  // fungsi buat button print
  const [printed, setPrinted] = useState(false);
  const print = () => {
    // setSelectedRowKeys([]);
    const body = {
      principal_id: principalId,
      list_id: dataSelectedRowKeys,
    };
    dispatch(unmappedProductReducerAction.setTableLoadingTrue());
    dispatch(downloadUnmappedProduct(body))
      .then((resp) => {
        if (!resp.payload.ok) {
          message.error(
            resp.payload.response.data.nexsoft.payload.status.message
          );
        } else {
          const { data, headers } = resp.payload.response;
          const [, filename] = headers["content-disposition"].split(";");
          const [, name] = filename.trim().split("=");
          const newName = name.replace(
            ".",
            `_${DateUtil.formatDate(new Date(), FORMAT.DEFAULT_DATE_XLSX)}.`
          );
          FileSaver.saveAs(data, newName);
        }
      })
      .then(() => {
        dispatch(unmappedProductReducerAction.resetCBX());
        dispatch(unmappedProductReducerAction.setTableLoadingFalse());
      });
  };

  const handleOnChangeChecked = (event, id) => {
    dispatch(unmappedProductReducerAction.setSingleCheck(id));
  };

  const handleChangeAllChecked = () => {
    dispatch(unmappedProductReducerAction.setAllCheck(listID));
  };

  useMemo(() => {
    setListID(data?.map((item) => item.id));
  }, [data]);
  const getCheckedAll = () => {
    // listID
    if (listID?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of listID) {
        isCheckedAll = false;
        for (const id of dataSelectedRowKeys) {
          if (ListIdData === id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) break;
      }
      return isCheckedAll;
    }
  };

  useEffect(() => {
    return () => {
      dispatch(unmappedProductReducerAction.resetReduxUnmapped());
    };
  }, []);

  const checkedAll = getCheckedAll();
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: item.id,
      distributor_name: item.distributor_name,
      customer_name: item.customer_name,
      po_number: item.po_number,
      store_name: item.store_name,
      transaction_date: moment(item.transaction_date)
        .subtract(7, "hours")
        .format("DD/MM/YYYY"),
      time_process: moment(item.time_process)
        .subtract(7, "hours")
        .format("DD/MM/YYYY"),
      product_code: item.product_code,
      product_name:
        item.product_name === "" ? item.suggestion_name : item.product_name,
      check_box: (
        <CustomCheckBox
          onChange={(event) => handleOnChangeChecked(event, item.id)}
          isChecked={dataSelectedRowKeys?.includes(item.id)}
        />
      ),

      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_BUYER.PROCESS.DETAIL,
              state: [item.id, principalId],
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));

  const columns = [
    {
      title: (
        <CustomCheckBox
          onChange={handleChangeAllChecked}
          isChecked={checkedAll}
        />
      ),
      dataIndex: "check_box",
      key: "check_box",
      width: 50,
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name - b.distributor_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_STORE")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_TRANSACTION_DATE")}
        </Box>
      ),
      dataIndex: "transaction_date",
      key: "transaction_date",
      sorter: (a, b) => a.transaction_date - b.transaction_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "product_code",
      key: "product_code",
      sorter: (a, b) => a.product_code - b.product_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_PRODUCT_NAME")}
        </Box>
      ),
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) => a.product_name - b.product_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("UNMAPPED_PRODUCT:FIELD_TIME_PROCESS")}
        </Box>
      ),
      dataIndex: "time_process",
      key: "time_process",
      sorter: (a, b) => a.time_process - b.time_process,
      sortDirections: ["descend", "ascend"],
    },
  ];
  return (
    <Box p={4}>
      <HeadTitle
        title={t("UNMAPPED_PRODUCT:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("UNMAPPED_PRODUCT:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("UNMAPPED_PRODUCT:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("UNMAPPED_PRODUCT:SUBTITLE_3")}
            </span>{" "}
            - {t("UNMAPPED_PRODUCT:TITLE_LIST")}
          </>
        }
        button={
          <ButtonCustom
            id={ID_MONITORING.LIST.BTN_PRINT}
            onClick={print}
            disabled={!hasSelected}
            icon={<DownloadIcon />}
            width="156px"
            height="40px"
            text={t("COMMON:DOWNLOAD_XLSX")}
            fontSize="14px"
            marginStart={2}
            colorScheme="white"
            // variant="outline"
            backgroundcolor={hasSelected === true ? PRIMARY_COLOR : "grey"}
          />
        }
      />

      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* Transaction date */}
          <Box width="200px">
            <InputDateRangePicker
              elementId={ID_MONITORING.LIST.DELIVERY_DATE}
              placeHolder={t("COMMON:TRANSACTION_DATE")}
              values={transactionDate}
              onChange={handleChangeTransactionDate}
            />
          </Box>

          {/* By Principal */}
          <Select
            id={ID_MONITORING.LIST.PRINCIPAL}
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={false}
            placeholder={t("COMMON:SELECT_PRINCIPAL")}
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal?.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>

          {/* By customer */}
          <Select
            id={ID_MONITORING.LIST.CUSTOMER}
            style={selectOptFilterPrincipal}
            size="large"
            value={idCustomer}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("COMMON:SELECT_CUSTOMER")}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
          >
            {dataCustomer &&
              dataCustomer?.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>

          <Box>
            <ButtonPrimary
              id={ID_MONITORING.LIST.BTN_SUBMIT}
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              id={ID_MONITORING.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Box marginLeft={"19rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            id={ID_MONITORING.LIST.DDL_PILIHAN}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            onClear={handleClear}
            allowClear={true}
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            value={
              selectDropDownFilter.value ? selectDropDownFilter.value : null
            }
          >
            {selectDropDownMonitoringUnmappedProduct?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              id={ID_MONITORING.LIST.SEARCH}
              style={{ border: "1px solid #d9d9d9" }}
              type="text"
              placeholder={
                selectDropDownFilter.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={
                selectDropDownFilter.value === undefined ? "none" : "auto"
              }
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
        </Stack>
        <Table
          rowKey="uid"
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleTableChange}
          size="small"
          loading={table_loading}
          scroll={{
            x: 1600,
            y: 350,
          }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_MONITORING.LIST.ROW_PER_PAGE}
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  id={ID_MONITORING.LIST.NEXT_PAGE}
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default UnmappedProduct;
