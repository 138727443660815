import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  list_detail: [],
  list_warehouse: [],
  list_customer : [],
  status: "",
  table_loading: false,
  download_loading : false,
  detail_table_loading: false,
  selectedRowKeys: [],
  ids: [],
  errorNotification: null,
  filter: {
    idDistributor: null,
    idStore: null,
    idWarehouse: null,
    countSearch: "",
    status: "",
    filterDeliveryDate: "",
    filterTransactionDate: "",
  },
};

export const getInitiateListProccessApprovePo = createAsyncThunk(
  "getInitiateListProccessApprovePo",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProcessApprovePo = createAsyncThunk(
  "getListProcessApprovePo",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error);
    }
    return result;
  }
);
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStore = createAsyncThunk(
  "getListStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListWarehouse = createAsyncThunk(
  "getListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-connection-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const approvePO = createAsyncThunk(
  "approvePO",
  async (body, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailApprovePO = createAsyncThunk(
  "detailApprovePO",
  async (id, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/${id}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const changeWarehouse = createAsyncThunk(
  "changeWarehouse",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const token = auth.token;
    const body = {
      warehouse_id: object?.warehouse_id?.value
        ? object?.warehouse_id?.value
        : object?.warehouse_id,
      updated_at: object?.updated_at,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/change-warehouse/${object?.id}`;
    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const previewPo = createAsyncThunk(
  "previewPo",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const token = auth.token;
    const body = object;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/preview`;
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailInitiateApprovePO = createAsyncThunk(
  "detailInitiateApprovePO",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const id = object?.id;
    const params = object?.params;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/detail/initiate/${id}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailListApprovePO = createAsyncThunk(
  "detailListApprovePO",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const id = object?.id;
    const params = object?.params;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/detail/${id}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const printApprovePO = createAsyncThunk(
  "printApprovePO",
  async (body, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/process-approve-po/download`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const proccessApprovePo = createSlice({
  name: "proccessApprovePo",
  initialState: INITIAL_STATE,
  reducers: {
    resetRedux() {
      return INITIAL_STATE;
    },
    setSelectedRowKeys(state, action) {
      state.selectedRowKeys = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setSingleCheck(state, action) {
      // if (!payload || typeof payload !== 'number')
      let tempSelected = [...state.selectedRowKeys];
      const isExist = state.selectedRowKeys?.find(
        (data) => data.id === action.payload.id
      );
      if (isExist) {
        tempSelected = tempSelected.filter(
          (data) => data.id !== action.payload.id
        );
        state.selectedRowKeys = tempSelected;
        return;
      }
      state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
    },
    setAllCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      let tempsId = action.payload;
      let isExist = false;

      for (const data of tempsId) {
        isExist = false;
        for (const existingId of state.selectedRowKeys) {
          if (data.id === existingId.id) {
            isExist = true;
            break;
          }
        }
        if (!isExist) break;
      }

      if (isExist) {
        for (const data of tempsId) {
          tempSelected = tempSelected.filter((item) => item.id !== data.id);
          state.selectedRowKeys = tempSelected;
        }
      } else {
        for (const data of tempsId) {
          const isExistOnRedux = state.selectedRowKeys?.find(
            (item) => item.id === data.id
          );
          if (!isExistOnRedux) {
            state.selectedRowKeys = [...state.selectedRowKeys, data];
          }
        }
        // state.selectedRowKeys = Array.from(new Set([...state.selectedRowKeys, ...tempsId]));
      }
    },
    resetCBX(state) {
      state.selectedRowKeys = [];
    },
    setTableLoadingFalse(state) {
      state.table_loading = false;
    },
    setTableLoadingTrue(state) {
      state.table_loading = true;
    },
  },
  extraReducers: {
    // Proccess Approve Po
    [getInitiateListProccessApprovePo.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProccessApprovePo.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProccessApprovePo.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [printApprovePO.pending]: (state, action) => {
      state.download_loading = true;
    },
    [printApprovePO.fulfilled]: (state, action) => {
      state.download_loading = false;
    },
    [printApprovePO.rejected]: (state, action) => {
      state.download_loading = false;
    },


    [getListProcessApprovePo.pending]: (state, action) => {
      state.status = "loading";
      state.list = [];
      state.table_loading = true;
    },
    [getListProcessApprovePo.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading = false;
    },
    [getListProcessApprovePo.rejected]: (state, action) => {
      state.status = "failed";
      state.list = [];
      state.errorNotification = action.payload;
      state.table_loading = false;
    },

    [detailListApprovePO.pending]: (state, action) => {
      state.status = "loading";
      state.list_detail = [];
      state.detail_table_loading = true;
    },
    [detailListApprovePO.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_detail = action.payload.respose;
      state.detail_table_loading = false;
    },
    [detailListApprovePO.rejected]: (state, action) => {
      state.status = "failed";
      state.list_detail = [];
      state.errorNotification = action.payload;
      state.detail_table_loading = false;
    },

    // customer
    [getListCustomerDDS.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListCustomerDDS.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_customer = action.payload.response;
    },
    [getListCustomerDDS.rejected]: (state, action) => {
      state.status = "failed";
    },

    // distributor
    [getListDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_distributor = action.payload.response;
    },
    [getListDistributor.rejected]: (state, action) => {
      state.status = "failed";
    },

    // store
    [getListStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListStore.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list_store = action.payload.response;
    },
    [getListStore.rejected]: (state, action) => {
      state.status = "failed";
    },

    // warehouse
    [getListWarehouse.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_warehouse = action.payload.response;
    },
    [getListWarehouse.rejected]: (state, action) => {
      state.status = "failed";
    },

    [approvePO.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [approvePO.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
    },
    [approvePO.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
  },
});

export const proccessApprovePoAction = proccessApprovePo.actions;

export default proccessApprovePo.reducer;
