import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  Divider,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { HOVER_PRIMARY, PRIMARY_COLOR } from "../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../constant/routeConstant";
import DateUtil from "../utils/dateUtil";
import ButtonCustom from "./button/ButtonCustom";

const ModalContactPerson = ({
  isOpen,
  onClose,
  icon,
  message,
  onOk,
  messageModal,
  messageConfirm,
  data,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex gap="20rem" marginTop={4}>
            {/* left side  */}
            <Box padding={1}>
              {/* Person Title */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_PERSON_TITLE")}</Box>
                <Box>
                  <Text>
                    <b>
                      {data?.person_title_name ? data?.person_title_name : "-"}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* First Name */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_FIRST_NAME")}</Box>
                <Box>
                  <Text>
                    <b>{data?.first_name ? data?.first_name : "-"}</b>
                  </Text>
                </Box>
              </Box>
              {/* Last Name */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_LAST_NAME")}</Box>
                <Box>
                  <Text>
                    <b>{data?.last_name ? data?.last_name : "-"}</b>
                  </Text>
                </Box>
              </Box>
              {/* Nik */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER")}</Box>
                <Box>
                  <Text>
                    <b>{data?.nik ? data?.nik : "-"}</b>
                  </Text>
                </Box>
              </Box>
            </Box>
            {/* right side  */}
            <Box padding={1}>
              {/* Phone */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_PHONE")}</Box>
                <Box>
                  <Text>
                    <b>{data?.phone ? data?.phone : "-"}</b>
                  </Text>
                </Box>
              </Box>
              {/* Email */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_EMAIL")}</Box>
                <Box>
                  <Text>
                    <b>{data?.email ? data?.email : "-"}</b>
                  </Text>
                </Box>
              </Box>
              {/* Join Date */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_JOIN_DATE")}</Box>
                <Box>
                  <Text>
                    {/* <b>{DateUtil.formatDate(data?.join_date, null, "-")}</b> */}
                    <b>
                      {data?.join_date
                        ? moment(data?.join_date).format("DD/MM/YYYY")
                        : "-"}
                    </b>
                  </Text>
                </Box>
              </Box>
              {/* Resign Date */}
              <Box padding={1}>
                <Box>{t("DISTRIBUTOR:FIELD_RESIGN_DATE")}</Box>
                <Box>
                  <Text>
                    {/* <b>{DateUtil.formatDate(data?.resign_date, null, "-")}</b> */}
                    <b>
                      {data?.resign_date
                        ? moment(data?.resign_date).format("DD/MM/YYYY")
                        : "-"}
                    </b>
                  </Text>
                </Box>
              </Box>
            </Box>
          </Flex>
          {/* button  */}
          <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
            <ButtonCustom
              colorScheme="blue"
              variant="outline"
              marginRight={2}
              width="9.375rem"
              link={{
                pathname: ROUTES_USER_SELLER.DISTRIBUTOR.DETAIL,
                state: {
                  id: location.state.id,
                },
              }}
              text={t("COMMON:BUTTON_CANCEL")}
              onClick={onClose}
              id="BTN-UserSeller-ProductPrincipal-EditProductPrincipal-Cancel"
            />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalContactPerson;
