import { Box, Icon, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const SidebarButtonParent = ({ onClick, to, icon, label, isActive }) => {
  return (
    <Box width="60px" height="48px">
      <Tooltip hasArrow label={label} placement="right" bg="#003C7C" color="white" fontWeight={500} fontSize={14}>
        <Box
          as={Link}
          to={to}
          height="full"
          alignItems="center"
          justifyContent="center"
          display="flex"
          cursor="pointer"
          color={isActive ? "white" : "#668AB0"}
          backgroundColor={isActive && "#00366F"}
          _hover={{ color: "white", backgroundColor: "#00366F" }}
          onClick={onClick}
        >
          <Icon as={icon} width={5} height={7} />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default SidebarButtonParent;
