import { Box, Text} from "@chakra-ui/react";
import { Input, Select } from "antd";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {userAction } from "../../../../../services/main/userReducer";
import {
  gender,
  personTitle,
  locale,
} from "../../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../../constant/themeConstant";
import { useFormik } from "formik";
import { addUserProfileSchema } from "../../userSchema";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import { ROUTES } from "../../../../../constant/routeConstant";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage,
} from "../../../../../services/main/regionalReducer";
import { formatFilter } from "../../../../../utils/stringUtil";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { useEffect, useMemo, useState } from "react";
import DropzoneImageLogo from "../../../../../components/button/DropzoneImageLogo";
import { ID_USER } from "../../../../../constant/idConstant";

const BodyUserProfile = () => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { list_country } = useSelector((state) => state.regional);
  const { list_island } = useSelector((state) => state.regional);
  const { list_province } = useSelector((state) => state.regional);
  const { list_district } = useSelector((state) => state.regional);
  const { list_sub_district } = useSelector((state) => state.regional);
  const { list_urban_village } = useSelector((state) => state.regional);
  const { list_postal_code } = useSelector((state) => state.regional);
  const { user_profile } = useSelector((state) => state.user);
  const { user_check } = useSelector((state) => state.user);
  const { index_page } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const onSubmit = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")));
    //set indexpage
    dispatch(userAction.setIndexPage(index_page + 1));

    dispatch(
      userAction.setUserProfile({
        ...values,
      })
    );
    if (
      values.photo &&
      values.photo instanceof Array &&
      values.photo.length > 0
    ) {
      dispatch(userAction.setPhoto(values?.photo[0]));
    } else {
      dispatch(userAction.setPhoto(null));
    }
  };

  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      photo: user_profile?.photo,
      nik: user_check.nik,
      person_title_id: user_profile.person_title_id,
      first_name: user_profile.first_name,
      last_name: user_profile.last_name,
      sex: user_profile.sex,
      email: user_check.email,
      phone_code: user_profile.phone_code,
      phone: user_profile.phone,
      address: user_profile.address,
      locale: user_profile.locale,
      country_id: user_profile.country_id,
      island_id: user_profile.island_id,
      province_id: user_profile.province_id,
      district_id: user_profile.district_id,
      sub_district_id: user_profile.sub_district_id,
      urban_village_id: user_profile.urban_village_id,
      postal_code_id: user_profile.postal_code_id,
      hamlet: user_profile.hamlet,
      neighbourhood: user_profile.neighbourhood,
    },
    validationSchema: addUserProfileSchema(t),
    onSubmit,
    enableReinitialize: true,
    isInitialValid: user_profile.nik !== "" ? true : false,
  }); 

  const [inputSearchDDSCountry, setInputSearchDDSCountry] = useState("");
  const filterSearchDDSCountry = `name like ${inputSearchDDSCountry}`;
  const fetchDataCountry = () => {
    const page = 1;
    const limit = 100;
    const filter = filterSearchDDSCountry;
    const params =
      inputSearchDDSCountry === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCountry(params));
  };
  // island
  const [searchIsland, setSearchIsland] = useState("");
  const ddsIsland = useMemo(() => {
    return list_island?.filter((values) =>
      values?.name.toLowerCase().includes(searchIsland.toLowerCase())
    );
  }, [list_island, searchIsland]);
  // province
  const [searchProvince, setSearchProvince] = useState("");
  const ddsProvince = useMemo(() => {
    return list_province?.filter((values) =>
      values?.name.toLowerCase().includes(searchProvince.toLowerCase())
    );
  }, [list_province, searchProvince]);
  // district
  const [searchDistrict, setSearchDistrict] = useState("");
  const ddsDistrict = useMemo(() => {
    return list_district?.filter((values) =>
      values?.name?.toLowerCase()?.includes(searchDistrict.toLowerCase())
    );
  }, [list_district, searchDistrict]);
  // sub district
  const [searchSubDistrict, setSearchSubDistrict] = useState("");
  const ddsSubDistrict = useMemo(() => {
    return list_sub_district?.filter((values) =>
      values?.name?.toLowerCase()?.includes(searchSubDistrict.toLowerCase())
    );
  }, [list_sub_district, searchSubDistrict]);
  // urban_viilage
  const [searchUrbanVillage, setSearchUrbanVillage] = useState("");
  const ddsUrbanVillage = useMemo(() => {
    return list_urban_village?.filter((values) =>
      values?.name?.toLowerCase()?.includes(searchUrbanVillage.toLowerCase())
    );
  }, [list_urban_village, searchUrbanVillage]);
  // postal
  const [searchPostalCode, setSearchPostalCode] = useState("");
  const ddsPostalCode = useMemo(() => {
    return list_postal_code?.filter((values) =>
      values?.code?.includes(searchPostalCode)
    );
  }, [list_postal_code, searchPostalCode]);

  const handleChangeSelect = (name, value, option) => {
    if (name === "country_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ country_id: `eq ${value}` }),
        };

        dispatch(getListProvince(params));
        dispatch(getListIsland(params));
      }
      setFieldValue("country_id", value);
      setFieldValue("province_id", null);
      setFieldValue("island_id", null);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "province_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ province_id: `eq ${value}` }),
        };
        dispatch(getListDistrict(params));
      }
      setFieldValue("province_id", value);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ district_id: `eq ${value}` }),
        };
        dispatch(getListSubDistrict(params));
      }
      setFieldValue("district_id", value);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "sub_district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ sub_district_id: `eq ${value}` }),
        };
        dispatch(getListUrbanVillage(params));
      }
      setFieldValue("sub_district_id", value);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "urban_village_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ urban_village_id: `eq ${value}` }),
        };
        dispatch(getListPostalCode(params));
      }
      setFieldValue("urban_village_id", value);
      setFieldValue("postal_code_id", null);
    } else {
      setFieldValue(name, value);
    }
  };
  const handleCancel = () => {
    dispatch(userAction.resetReduxUser());
  };
  useEffect(() => {
    fetchDataCountry();
  }, [filterSearchDDSCountry]);
 

  return (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
        // justifyContent="space-between"
        display="flex"
      >
        {/* left side */}
        <Box padding={1}>
          {/* left title */}
          <Box fontWeight={600} fontSize={14} color="black" marginBottom={4}>
            {t("USER:TITTLE_LEFT")}
          </Box>
          {/* Add Photo File */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem">
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_ADD_PHOTO_FILE")}
                {/* <Text fontWeight="light">(Optional)</Text> */}
              </Text>
            </Box>
            <Box>
              <DropzoneImageLogo
                onChange={(data) => setFieldValue("photo", data)}
                onBlur={handleBlur}
                defaultValue={values?.photo}
              />
              {errors.photo && touched.photo && (
                <ValidationError text={errors.photo} />
              )}
            </Box>
          </Box>
          {/* Identification Number  */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_IDENTIFICATION_NUMBER")}
              </Text>
            </Box>
            <Box marginRight={2}>
              <Input
                type="text"
                backgroundColor={GRAY_COLOR}
                style={inputTheme}
                id={ID_USER.ADD.NIK}
                name="nik"
                onChange={handleChange}
                onBlur={handleBlur}
                // onChange={(e) => handleChange("nik", e)}
                size="large"
                disabled
                value={values?.nik}
              />
              {errors.nik && touched.nik && (
                <ValidationError text={errors.nik} />
              )}
            </Box>
          </Box>
          {/* Title */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_TITLE")}</Box>
            <Box>
              <Select
                style={inputTheme}
                id={ID_USER.ADD.PERSON_TITLE}
                width="250px"
                onChange={(value) =>
                  handleChangeSelect("person_title_id", value)
                }
                size="large"
                value={values?.person_title_id}
              >
                {personTitle?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.id}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
              {errors.person_title_id && touched.person_title_id && (
                <ValidationError text={errors.person_title_id} />
              )}
            </Box>
          </Box>
          {/* First Name */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_FIRST_NAME")}</Box>
            <Box maxWidth={250}>
              <Input
                type="text"
                style={inputTheme}
                id={ID_USER.ADD.FIRST_NAME}
                value={values?.first_name}
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.first_name && touched.first_name && (
                <ValidationError text={errors.first_name} />
              )}
            </Box>
          </Box>
          {/* Last Name */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_LAST_NAME")}
              </Text>
            </Box>
            <Box maxWidth={250}>
              <Input
                type="text"
                value={values?.last_name}
                style={inputTheme}
                name="last_name"
                id={ID_USER.ADD.LAST_NAME}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.last_name && touched.last_name && (
                <ValidationError text={errors.last_name} />
              )}
            </Box>
          </Box>
          {/* Gender  */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_GENDER")}</Box>
            <Box>
              <Select
                style={inputTheme}
                value={values?.sex}
                width="250px"
                onChange={(value, opt) => handleChangeSelect("sex", value, opt)}
                size="large"
              >
                {gender?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
              {errors.sex && touched.sex && (
                <ValidationError text={errors.sex} />
              )}
            </Box>
          </Box>
          {/* Email  */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_EMAIL")}</Box>
            <Box>
              <Input
                type="text"
                id={ID_USER.ADD.EMAIL}
                style={inputTheme}
                name="email"
                disabled
                backgroundColor={GRAY_COLOR}
                value={values?.email}
              />
            </Box>
          </Box>
          {/* Phone */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_PHONE")}</Box>
            <Box>
              <Select
                style={{
                  width: "80px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontStyle: "normal",
                }}
                size="large"
                value={values?.phone_code}
                onChange={(value, opt) =>
                  handleChangeSelect("phone_code", value, opt)
                }
              >
                {list_country?.map((value, index) => {
                  return (
                    <Option key={index} value={value.phone_code}>
                      {value.phone_code}
                    </Option>
                  );
                })}
              </Select>
            </Box>
            <Box>
              <Input
                type="tel"
                id={ID_USER.ADD.PHONE}
                style={{
                  width: "170px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontStyle: "normal",
                }}
                size="large"
                value={values?.phone}
                onChange={handleChange}
                name="phone"
              />
              {errors.phone && touched.phone && (
                <ValidationError text={errors.phone} />
              )}
            </Box>
          </Box>
        </Box>
        {/* end of left side */}

        {/* right side */}
        <Box padding={1} marginRight={8}>
          {/* right title */}
          <Box fontWeight={600} fontSize={14} color="black" marginBottom={4}>
            {t("USER:TITTLE_RIGHT")}
          </Box>
          {/* address */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:TITTLE_RIGHT")}</Text>
            </Box>
            <Box>
              <Input
                type="text"
                id={ID_USER.ADD.ADDRESS_1}
                name="address"
                style={inputTheme}
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address && (
                <ValidationError text={errors.address} />
              )}
            </Box>
          </Box>
          {/* locale */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_LOCALE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                allowClear="true"
                id={ID_USER.ADD.LOCALE}
                onBlur={() => setFieldTouched("locale", true)}
                value={values?.locale}
                onChange={(value, opt) =>
                  handleChangeSelect("locale", value, opt)
                }
                size="large"
              >
                {locale?.map((value) => {
                  return <option value={value}>{value}</option>;
                })}
              </Select>
              {errors.locale && touched.locale && (
                <ValidationError text={errors.locale} />
              )}
            </Box>
          </Box>
          {/* country */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_COUNTRY")}</Text>
            </Box>
            <Box>
              <Select
                id={ID_USER.ADD.COUNTRY}
                style={inputTheme}
                width="250px"
                size="large"
                onBlur={() => setFieldTouched("country_id", true)}
                // onBlur={handleBlur}
                allowClear="true"
                showSearch={false}
                value={values?.country_id}
                onChange={(value, opt) =>
                  handleChangeSelect("country_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(e) => setInputSearchDDSCountry(e.target.value)}
                  />
                )}
              >
                {list_country?.map((value) => {
                  return (
                    <Select.Option value={value.id}>{value.name}</Select.Option>
                  );
                })}
              </Select>
              {errors.country_id && touched.country_id && (
                <ValidationError text={errors.country_id} />
              )}
            </Box>
          </Box>

          {/* island */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text overflowWrap="break-word" width="80px">
                {t("USER:FIELD_ISLAND")}
              </Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                width="250px"
                id={ID_USER.ADD.ISLAND}
                size="large"
                onBlur={() => setFieldTouched("island_id", true)}
                showSearch={false}
                value={values?.island_id}
                onChange={(value, opt) =>
                  handleChangeSelect("island_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchIsland(event.target.value)}
                  />
                )}
                disabled={values?.country_id === null ? true : false}
                allowClear="true"
              >
                {ddsIsland?.map((value) => {
                  return <option value={value.id}>{value.name}</option>;
                })}
              </Select>
              {errors.island_id && (
                // touched.island_id &&
                <ValidationError text={errors.island_id} />
              )}
            </Box>
          </Box>

          {/* province */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_PROVINCE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                allowClear="true"
                id={ID_USER.ADD.PROVINCE}
                onBlur={() => setFieldTouched("province_id", true)}
                size="large"
                disabled={values?.country_id ? false : true}
                value={values?.province_id}
                onChange={(value, opt) =>
                  handleChangeSelect("province_id", value, opt)
                }
                showSearch={false}
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchProvince(event.target.value)}
                  />
                )}
              >
                {ddsProvince?.map((value) => {
                  return (
                    <Select.Option value={value.id}>{value.name}</Select.Option>
                  );
                })}
              </Select>
              {errors.province_id && touched.province_id && (
                <ValidationError text={errors.province_id} />
              )}
            </Box>
          </Box>

          {/* district */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_DISTRICT")}</Text>
            </Box>
            <Box>
              <Select
                allowClear="true"
                style={inputTheme}
                id={ID_USER.ADD.DISTRICT}
                onBlur={() => setFieldTouched("district_id", true)}
                showSearch={false}
                size="large"
                disabled={values?.province_id ? false : true}
                value={values?.district_id}
                onChange={(value, opt) =>
                  handleChangeSelect("district_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchDistrict(event.target.value)}
                  />
                )}
              >
                {
                  // ?.filter((val) => val.province_id === values.province_id)
                  ddsDistrict?.map((value) => {
                    return (
                      <Select.Option value={value.id}>
                        {value.name}
                      </Select.Option>
                    );
                  })
                }
              </Select>
              {errors.district_id && touched.district_id && (
                <ValidationError text={errors.district_id} />
              )}
            </Box>
          </Box>

          {/* sub district */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_SUB_DISTRICT")}</Text>
            </Box>
            <Box>
              <Select
                onBlur={() => setFieldTouched("sub_district_id", true)}
                style={inputTheme}
                size="large"
                id={ID_USER.ADD.SUB_DISTRICT}
                disabled={values?.district_id ? false : true}
                value={values?.sub_district_id}
                onChange={(value, opt) =>
                  handleChangeSelect("sub_district_id", value, opt)
                }
                showSearch={false}
                allowClear="true"
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchSubDistrict(event.target.value)}
                  />
                )}
              >
                {ddsSubDistrict?.map((value) => {
                  return <option value={value.id}>{value.name}</option>;
                })}
              </Select>
              {errors.sub_district_id && touched.sub_district_id && (
                <ValidationError text={errors.sub_district_id} />
              )}
            </Box>
          </Box>

          {/* urban village */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_URBAN_VILLAGE")}</Text>
            </Box>
            <Box>
              <Select
                showSearch={false}
                onBlur={() => setFieldTouched("urban_village_id", true)}
                style={inputTheme}
                disabled={values?.sub_district_id ? false : true}
                allowClear="true"
                value={values?.urban_village_id}
                id={ID_USER.ADD.URBAN_VILLAGE}
                size="large"
                onChange={(value, opt) =>
                  handleChangeSelect("urban_village_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) =>
                      setSearchUrbanVillage(event.target.value)
                    }
                  />
                )}
              >
                {ddsUrbanVillage?.map((value) => {
                  return <option value={value.id}>{value.name}</option>;
                })}
              </Select>
              {errors.urban_village_id && touched.urban_village_id && (
                <ValidationError text={errors.urban_village_id} />
              )}
            </Box>
          </Box>
          {/* postal code */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_POSTAL_CODE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                id={ID_USER.ADD.POSTAL_CODE}
                onBlur={() => setFieldTouched("postal_code_id", true)}
                showSearch={false}
                disabled={values.urban_village_id ? false : true}
                name="postal_code_id"
                value={values?.postal_code_id}
                allowClear="true"
                onChange={(value, opt) =>
                  handleChangeSelect("postal_code_id", value, opt)
                }
                size="large"
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchPostalCode(event.target.value)}
                  />
                )}
              >
                {ddsPostalCode?.map((value) => {
                  return <option value={value.id}>{value.code}</option>;
                })}
              </Select>
              {errors.postal_code_id && touched.postal_code_id && (
                <ValidationError text={errors.postal_code_id} />
              )}
            </Box>
          </Box>
          {/* hamlet */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_HAMLET")}</Text>
            </Box>
            <Box>
              <Input
                size="large"
                type="text"
                id={ID_USER.ADD.HAMLET}
                style={inputTheme}
                onBlur={handleBlur}
                value={values?.hamlet}
                onChange={handleChange}
                name="hamlet"
              />
              {errors.hamlet && touched.hamlet && (
                <ValidationError text={errors.hamlet} />
              )}
            </Box>
          </Box>

          {/* neighborhood */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_NEIGHBORHOOD")}</Text>
            </Box>
            <Box>
              <Input
                type="text"
                value={values?.neighbourhood}
                style={inputTheme}
                id={ID_USER.ADD.NEIGHBOURHOOD}
                onBlur={handleBlur}
                name="neighbourhood"
                onChange={handleChange}
                // onChange={(e) => handleChange("neighbourhood", e)}
              />
              {errors.neighbourhood && touched.neighbourhood && (
                <ValidationError text={errors.neighbourhood} />
              )}
            </Box>
          </Box>
        </Box>
        {/* end of right side */}
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          onClick={handleCancel}
          width="9.375rem"
          link={ROUTES.USER.LIST}
          text={t("COMMON:BUTTON_CANCEL")}
          id={ID_USER.ADD.BTN_CANCEL}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          id={ID_USER.ADD.BTN_NEXT}
        />
      </Box>
    </form>

    //  end of big white box
  );
};

export default BodyUserProfile;
