import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  connection_data: {},
  list_connection_store: [],
  detail_connection_store: [],
};

export const getListStoreDDS = createAsyncThunk(
  "getListStoreDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListWarehouseDDS = createAsyncThunk(
  "getListWarehouseDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListNotification = createAsyncThunk(
    "getListNotification",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/notification`;
      const { auth } = getState();
      const token = auth.token;
      try {
        const response = await baseApi.doGet(url, token, params);
        if (response) {
          result.ok = true;
          result.response = response.data.nexsoft.payload.data.content;
        }
      } catch (error) {
        if (error.response) {
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
      }
      return result;
    }
  );

export const getListStoreSupplier = createAsyncThunk(
  "getListStoreSupplier",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitListStoreSupplier = createAsyncThunk(
  "getInitListStoreSupplier",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailStoreSupplier = createAsyncThunk(
  "Get Detail Product Customer",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const addStoreSupplier = createAsyncThunk(
  "addStoreSupplier",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping`;
    const { auth } = getState();
    const token = auth.token;

    // const body = {
    //   farmindo_store: object.farmindo_store,
    //   principal_id: object.principal_id,
    //   customer_id: object.customer_id,
    //   store_id: object.store_id,
    //   distributor_id: object.distributor_id,
    //   warehouse_id: object.warehouse_id,
    //   supplier_code: object.supplier_code,
    //   principal_connection_store_id: object.principal_connection_store_id,
    //   is_bkl: object.is_bkl,
    //   is_product_mapping: object.is_product_mapping,
    //   pm_handling: object.pm_handling,
    //   notification_id: object.notification_id,
    //   notification_alert_id: object.notification_alert_id,
    //   status: object.status,
    //   additional_data: {
    //     salesman_code: object.additional_data.sales_code,
    //     salesman_name: object.additional_data.sales_name,
    //   },
    // };

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const updateStoreSupplier = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/customer-store-mapping/197",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/${object.id}`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      farmindo_store: object.values.farmindo_store,
      principal_id: object.principal_id,
      customer_id: object.values.customer_id,
      store_id: object.values.store_id,
      distributor_id: object.values.distributor_id,
      warehouse_id: object.values.warehouse_id,
      supplier_code: object.values.supplier_code,
      principal_connection_store_id:
        object.values.principal_connection_store_id,
      is_bkl: object.values.is_bkl,
      is_product_mapping: object.values.is_product_mapping,
      pm_handling: object.values.pm_handling,
      notification_id: object.values.notification_id,
      notification_alert_id: object.values.notification_alert_id,
      status: object.values.status,
      additional_data: {
        salesman_code: object.values.additional_data.salesman_code,
        salesman_name: object.values.additional_data.salesman_name,
      },
      updated_at: object.values.updated_at,
    };
    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const deleteStoreSupplier = createAsyncThunk(
  "Delete Product Customer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping/${params.Id}`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      updated_at: params.updated_at,
    };
    try {
      const response = await baseApi.doDelete(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

const storeConnectionSupplier = createSlice({
  name: "createConnectionSupplier",
  initialState: INITIAL_STATE,
  reducers: {
    resetDetailStoreConnection() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListStoreSupplier.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListStoreSupplier.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_connection_store = action.payload.response;
    },
    [getListStoreSupplier.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [detailStoreSupplier.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailStoreSupplier.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_connection_store = action.payload.response;
    },
    [detailStoreSupplier.rejected]: (state, action) => {
      state.status = "failed";
    },
    [addStoreSupplier.pending]: (state, action) => {
      state.status = "loading";
    },
    [addStoreSupplier.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addStoreSupplier.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateStoreSupplier.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateStoreSupplier.fulfilled]: (state, action) => {
      state.status = "success";
      state.update_store = action.payload.response;
     },
    [updateStoreSupplier.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [deleteStoreSupplier.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteStoreSupplier.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteStoreSupplier.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
  },
});

export const storeConnectionSupplierAction = storeConnectionSupplier.actions;

export default storeConnectionSupplier.reducer;
