import { Redirect, useHistory } from "react-router-dom";
import { ROUTES } from "../../constant/routeConstant";
import classes from "./NotFound.module.css";
const NotFound = () => {
  const history = useHistory();
  const navigateHome = () => {};
  return (
    <>
      <div id={classes.bodyNotFound}>
        <div id={classes.notfound}>
          <div className={classes.notfound}>
            <div className={classes.notfound404}>
              <h1>404</h1>
            </div>
            <h2>We are sorry, Page not found!</h2>
            {/* <button onClick={()=>history.push(ROUTES.HOME)}>Back To Home Page</button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
