import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Divider,
  Text,
  Badge,
  Select as SelectChakra,
  Button,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import DayPicker, { DateUtils } from "react-day-picker";
import { format } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { Pagination, Select, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PRIMARY_COLOR } from "../../../constant/propertiesConstant";
import ButtonCustom from "../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../components/button/ButtonPrimary";
import HeadTitle from "../../../components/HeadTitle";
import SortButton from "../../../components/SortButton";
import 'react-day-picker/lib/style.css';
import {
  AddIcon,
  SearchIcon,
  DateIcon,
  EditIcon,
  DetailIcon,
} from "../../../components/icons";
// import { PRIMARY_COLOR } from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../constant/routeConstant";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "../../../components/date/DateRangePicker";
import { getListActivityUser } from "../../../services/report/userActivity/userActivity";
const ListUserActivity = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { table_loading } = useSelector((state) => state.page);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [data, setData] = useState([]);
  const { token } = useSelector((state) => state.auth);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();
  const dataSource =
  data.length > 0 &&
  data.map((item, index) => ({
    key: index,
    id: item.id,
    username: item.username,
    created_date : item.created_date,
    company: item.company,
    activity: item.activity,
    action:
    <div>
      <ButtonCustom link={`${ROUTES.USER_ACTIVITY.LIST}/detail/${item.id}`} text={<DetailIcon/>} backgroundcolor="transparent"/>
    </div>

  }));
  const columns = [
    {
      title: (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          cursor="pointer"
        >
          Client Id
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "left",
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          Username
          <SortButton thisField="username" />
        </Box>
      ),
      dataIndex: "username",
      key: "username",
      width: 270,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          Created Date
          <SortButton thisField="level" />
        </Box>
      ),
      dataIndex: "created_date",
      key: "created_date",
      width: 250,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          Company
          <SortButton thisField="company" />
        </Box>
      ),
      dataIndex: "company",
      key: "company",
      width: 250,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          Activity
          <SortButton thisField="activity" />
        </Box>
      ),
      dataIndex: "activity",
      key: "activity",
      width: 200,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          ACTION
        </Box>
      ),
      dataIndex: "action",
      key: "action",
      width:150,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      const params = { page, limit };
      const { ok, response } = await getListActivityUser(params, dispatch);  
      if (ok) {
        setData(response.data);
        setTotal(response.count);
      }
    };

    fetchData();
  }, [page, limit, token, dispatch]);
  return (
    <Box padding={4}>
      <HeadTitle
        title={t("USER_ACTIVITY:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>Home</span> -{" "}
            <span style={{ color: "#003C7C" }}>Report</span> -{" "}
            <span style={{ color: "#003C7C" }}>Log</span> - User Activity
          </>
        }
      />
      <Box marginTop={6} minHeight="96px">
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <DateRangePicker
            width="14.25rem"
            handleOk
            handleReset
          />
          <Box>
            <ButtonPrimary text="Apply" fontWeight={500} fontSize={14} width="93px"/>
          </Box>
          <Box>
            <ButtonCustom
              colorScheme="whiteAlpha"
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              text="Reset Filter"
            />
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          // loading={table_loading}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>Row Per Page</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(event) => setLimit(event.target.value)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListUserActivity;
