import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
// import { WHITE_COLOR } from "../../../../../constant/propertiesConstant";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import DropDownCustom from "../../../../components/DropDownCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ROUTES } from "../../../../constant/routeConstant";
import {
  gender, locale, personTitle
} from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import { addUserProfileSchema } from "../../../../pages/main/User/userSchema";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage
} from "../../../../services/main/regionalReducer";
import { userAction } from "../../../../services/main/userReducer";
import { formatFilter } from "../../../../utils/stringUtil";
// import UploadDropZone from "../../../../components/button/UploadDropZone";
import DropzoneImageLogo from "../../../../components/button/DropzoneImageLogo";
import FormatUtil from "../../../../utils/formatUtil";

const BodyUserProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;
  const { list_country } = useSelector((state) => state.regional);
  const { list_island } = useSelector((state) => state.regional);
  const { list_province } = useSelector((state) => state.regional);
  const { list_district } = useSelector((state) => state.regional);
  const { list_sub_district } = useSelector((state) => state.regional);
  const { list_urban_village } = useSelector((state) => state.regional);
  const { list_postal_code } = useSelector((state) => state.regional);
  const { index_page } = useSelector((state) => state.user);
  const { detail_user } = useSelector((state) => state.user);
  const { user_profile } = useSelector((state) => state.user);
  const userProfile = detail_user?.user_profile;

  const phoneFull = user_profile?.phone
    ? user_profile?.phone
    : userProfile?.phone;
  const phoneCode = phoneFull?.split("-")[0] ?? "";
  const phoneNumber = phoneFull?.split("-")[1] ?? "";

  const onSubmit = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_LOGIN_ACCOUNT")));
    //set indexpage
    dispatch(userAction.setIndexPage(index_page + 1));

    dispatch(
      userAction.setUserProfile({
        ...values,
        phone: values?.phone_code.concat("-").concat(values?.phone),
      })
    );

    // dispatch(
    //   userAction.setDetailUser({
    //     ...detail_user,
    //     user_profile: {
    //       ...values,
    //       photo: [values?.photo],
    //     },
    //   })
    // );
  };

  const handleClear = (name) => {
    switch (name) {
      case "locale":
        setFieldTouched("locale", true);
        break;
      default:
        break;
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      // photo: photo,
      // jika data di redux yang bakal diubah kosong maka abil dari detail
      nik: user_profile?.nik ? user_profile?.nik : userProfile?.nik,
      person_title_id: user_profile?.person_title_id
        ? user_profile?.person_title_id
        : userProfile?.person_title_id,
      first_name: user_profile?.first_name
        ? user_profile.first_name
        : userProfile?.first_name,
      last_name: user_profile?.last_name
        ? user_profile?.last_name
        : userProfile?.last_name,
      sex: user_profile?.sex ? user_profile?.sex : userProfile?.sex,
      email: user_profile?.email ? user_profile?.email : userProfile?.email,
      phone_code: phoneCode,
      phone: phoneNumber,
      address: user_profile?.address
        ? user_profile?.address
        : userProfile?.address,
      locale: user_profile?.locale ? user_profile?.locale : userProfile?.locale,
      country_id: user_profile?.country_id
        ? user_profile?.country_id
        : userProfile?.country_id,
      island_id: user_profile?.island_id
        ? user_profile?.island_id
        : userProfile?.island_id,
      province_id: user_profile?.province_id
        ? user_profile?.province_id
        : userProfile?.province_id,
      district_id: user_profile?.district_id
        ? user_profile?.district_id
        : userProfile?.district_id,
      sub_district_id: user_profile?.sub_district_id
        ? user_profile?.sub_district_id
        : userProfile?.sub_district_id,
      urban_village_id: user_profile?.urban_village_id
        ? user_profile?.urban_village_id
        : userProfile?.urban_village_id,
      postal_code_id: user_profile?.postal_code_id
        ? user_profile?.postal_code_id
        : userProfile?.postal_code_id,
      hamlet: user_profile?.hamlet ? user_profile?.hamlet : userProfile?.hamlet,
      images: user_profile?.images
        ? user_profile?.images
        : userProfile?.photo
        ? FormatUtil.formatPhotoUrl(userProfile.photo[0])
        : "",
      photo: userProfile?.photo ? userProfile?.photo[0] : {},
      have_default_photo: Boolean(userProfile?.photo),
      neighbourhood: user_profile?.neighbourhood
        ? user_profile?.neighbourhood
        : userProfile?.neighbourhood,
    },
    validationSchema: addUserProfileSchema(t),
    onSubmit,
    isInitialValid: true,
    enableReinitialize: true,
  });
   
  const [inputSearchDDSCountry, setInputSearchDDSCountry] = useState("");
  const filterSearchDDSCountry = `name like ${inputSearchDDSCountry}`;
  const fetchDataCountry = () => {
    const page = 1;
    const limit = 100;
    const filter = filterSearchDDSCountry;
    const params =
      inputSearchDDSCountry === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCountry(params));
  };
  // island
  const [searchIsland, setSearchIsland] = useState("");
  const ddsIsland = useMemo(() => {
    return list_island?.filter((values) =>
      values?.name.toLowerCase().includes(searchIsland.toLowerCase())
    );
  }, [list_island, searchIsland]);
  // province
  const [searchProvince, setSearchProvince] = useState("");
  const ddsProvince = useMemo(() => {
    return list_province?.filter((values) =>
      values?.name.toLowerCase().includes(searchProvince.toLowerCase())
    );
  }, [list_province, searchProvince]);
  // district
  const [searchDistrict, setSearchDistrict] = useState("");
  const ddsDistrict = useMemo(() => {
    return list_district?.filter((values) =>
      values?.name.toLowerCase().includes(searchDistrict.toLowerCase())
    );
  }, [list_district, searchDistrict]);
  // sub district
  const [searchSubDistrict, setSearchSubDistrict] = useState("");
  const ddsSubDistrict = useMemo(() => {
    return list_sub_district?.filter((values) =>
      values?.name.toLowerCase().includes(searchSubDistrict.toLowerCase())
    );
  }, [list_sub_district, searchSubDistrict]);
  // urban_viilage
  const [searchUrbanVillage, setSearchUrbanVillage] = useState("");
  const ddsUrbanVillage = useMemo(() => {
    return list_urban_village?.filter((values) =>
      values?.name.toLowerCase().includes(searchUrbanVillage.toLowerCase())
    );
  }, [list_urban_village, searchUrbanVillage]);
  // postal
  const [searchPostalCode, setSearchPostalCode] = useState("");
  const ddsPostalCode = useMemo(() => {
    return list_postal_code?.filter((values) =>
      values?.code.includes(searchPostalCode)
    );
  }, [list_postal_code, searchPostalCode]);
  const handleChangeSelect = (name, value, option) => {
    if (name === "country_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ country_id: `eq ${value}` }),
        };

        dispatch(getListProvince(params));
        dispatch(getListIsland(params));
      }
      setFieldValue("country_id", value);
      setFieldValue("province_id", null);
      setFieldValue("island_id", null);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "province_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ province_id: `eq ${value}` }),
        };
        dispatch(getListDistrict(params));
      }
      setFieldValue("province_id", value);
      setFieldValue("district_id", null);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ district_id: `eq ${value}` }),
        };
        dispatch(getListSubDistrict(params));
      }
      setFieldValue("district_id", value);
      setFieldValue("sub_district_id", null);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "sub_district_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ sub_district_id: `eq ${value}` }),
        };
        dispatch(getListUrbanVillage(params));
      }
      setFieldValue("sub_district_id", value);
      setFieldValue("urban_village_id", null);
      setFieldValue("postal_code_id", null);
    } else if (name === "urban_village_id") {
      if (value) {
        const params = {
          page: 1,
          limit: 100,
          filter: formatFilter({ urban_village_id: `eq ${value}` }),
        };
        dispatch(getListPostalCode(params));
      }
      setFieldValue("urban_village_id", value);
      setFieldValue("postal_code_id", null);
    } else {
      setFieldValue(name, value);
    }
  };
  const handleCancel = () => {
    dispatch(userAction.resetReduxUser());
  };
  const handlePhoto = (data) => {
    setFieldValue("images", data);
  };

  useEffect(() => {
    fetchDataCountry();
  }, [filterSearchDDSCountry, ddsIsland, ddsProvince]);

  return (
    <form onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
        justifyContent="space-between"
        display="flex"
      >
        {/* left side */}
        <Box padding={1}>
          {/* left title */}
          <Box fontWeight={600} fontSize={14} color="black" marginBottom={4}>
            {t("USER:TITTLE_LEFT")}
          </Box>
          {/* Add Photo File */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem">
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_ADD_PHOTO_FILE")}
                <Text fontWeight="light">(Optional)</Text>
              </Text>
            </Box>
            <Box>
              <DropzoneImageLogo
                onChange={(data) => handlePhoto(data)}
                onBlur={handleBlur}
                defaultValue={values?.images}
              />
              {errors.images && touched.images && (
                <ValidationError text={errors.images} />
              )}
            </Box>
          </Box>
          {/* Identification Number  */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_IDENTIFICATION_NUMBER")}
              </Text>
            </Box>
            <Box marginRight={2}>
              <Input
                type="text"
                style={inputTheme}
                name="nik"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                // onChange={(e) => handleChange("nik", e)}
                size="large"
                value={values?.nik}
              />
              {errors.nik && touched.nik && (
                <ValidationError text={errors.nik} />
              )}
            </Box>
          </Box>
          {/* Title */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_TITLE")}</Box>
            <Box>
              <Select
                style={inputTheme}
                width="250px"
                onChange={(value) =>
                  handleChangeSelect("person_title_id", value)
                }
                size="large"
                value={values?.person_title_id}
              >
                {personTitle?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.id}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
              {errors.person_title_id && touched.person_title_id && (
                <ValidationError text={errors.person_title_id} />
              )}
            </Box>
          </Box>
          {/* First Name */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_FIRST_NAME")}</Box>
            <Box maxWidth={250}>
              <Input
                type="text"
                style={inputTheme}
                // onChange={(e) => handleChange("first_name", e)}
                value={values?.first_name}
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.first_name && touched.first_name && (
                <ValidationError text={errors.first_name} />
              )}
            </Box>
          </Box>
          {/* Last Name */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text overflowWrap="break-word" width="100px">
                {t("USER:FIELD_LAST_NAME")}
              </Text>
            </Box>
            <Box maxWidth={250}>
              <Input
                type="text"
                value={values?.last_name}
                style={inputTheme}
                // onChange={(e) => handleChange("last_name", e)}
                name="last_name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.last_name && touched.last_name && (
                <ValidationError text={errors.last_name} />
              )}
            </Box>
          </Box>
          {/* Gender  */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_GENDER")}</Box>
            <Box>
              <Select
                style={inputTheme}
                value={values?.sex}
                width="250px"
                onChange={(value, opt) => handleChangeSelect("sex", value, opt)}
                size="large"
              >
                {gender?.map((value, index) => {
                  return (
                    <>
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </Box>
          </Box>
          {/* Email  */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_EMAIL")}</Box>
            <Box>
              <Input
                type="text"
                style={inputTheme}
                name="email"
                disabled
                onChange={handleChange}
                // onChange={(e) => handleChange("email", e)}
                value={values?.email}
              />
            </Box>
          </Box>
          {/* Phone */}
          <Box padding={1} display="flex">
            <Box width="10rem">{t("USER:FIELD_PHONE")}</Box>
            <Box>
              <Select
                style={{
                  width: "80px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontStyle: "normal",
                }}
                size="large"
                value={values?.phone_code}
                onChange={(value, opt) =>
                  handleChangeSelect("phone_code", value, opt)
                }
              >
                {list_country?.map((value, index) => {
                  return (
                    <Option key={index} value={value.phone_code}>
                      {value.phone_code}
                    </Option>
                  );
                })}
              </Select>
            </Box>
            <Box>
              <Input
                type="tel"
                style={{
                  width: "170px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontStyle: "normal",
                }}
                size="large"
                value={values?.phone}
                onChange={handleChange}
                name="phone"
              />
              {errors.phone && touched.phone && (
                <ValidationError text={errors.phone} />
              )}
            </Box>
          </Box>
        </Box>
        {/* end of left side */}

        {/* right side */}
        <Box padding={1} marginRight={8}>
          {/* right title */}
          <Box fontWeight={600} fontSize={14} color="black" marginBottom={4}>
            {t("USER:TITTLE_RIGHT")}
          </Box>
          {/* address */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:TITTLE_RIGHT")}</Text>
            </Box>
            <Box>
              <Input
                type="text"
                name="address"
                style={inputTheme}
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.address && touched.address && (
                <ValidationError text={errors.address} />
              )}
            </Box>
          </Box>
          {/* locale */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_LOCALE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                allowClear="true"
                onClear={() => handleClear("locale")}
                onBlur={() => setFieldTouched("locale", true)}
                value={values?.locale}
                onChange={(value, opt) =>
                  handleChangeSelect("locale", value, opt)
                }
                size="large"
              >
                {locale?.map((value) => {
                  return <option value={value}>{value}</option>;
                })}
              </Select>
              {errors.locale && touched.locale && (
                <ValidationError text={errors.locale} />
              )}
            </Box>
          </Box>
          {/* country */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_COUNTRY")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                width="250px"
                // onClear={() => handleClear("country_id")}
                size="large"
                showSearch={false}
                allowClear="true"
                value={values?.country_id === 0 ? "" : values?.country_id}
                onChange={(value, opt) =>
                  handleChangeSelect("country_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(e) => setInputSearchDDSCountry(e.target.value)}
                  />
                )}
              >
                {list_country?.map((value) => {
                  return (
                    <Select.Option value={value.id} key={value.id}>
                      {value.name}
                    </Select.Option>
                  );
                })}
              </Select>
              {errors.country_id && (
                // touched.country_id &&
                <ValidationError text={errors.country_id} />
              )}
            </Box>
          </Box>

          {/* island */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text overflowWrap="break-word" width="80px">
                {t("USER:FIELD_ISLAND")}
              </Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                showSearch={false}
                width="250px"
                size="large"
                value={values?.island_id}
                onChange={(value, opt) =>
                  handleChangeSelect("island_id", value, opt)
                }
                disabled={values?.country_id === null ? true : false}
                allowClear="true"
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchIsland(event.target.value)}
                  />
                )}
              >
                {ddsIsland?.map((value) => {
                  return <option value={value.id}>{value.name}</option>;
                })}
              </Select>
              {errors.island_id && (
                // touched.island_id &&
                <ValidationError text={errors.island_id} />
              )}
            </Box>
          </Box>

          {/* province */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_PROVINCE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                allowClear="true"
                showSearch={false}
                size="large"
                disabled={values?.country_id ? false : true}
                value={values?.province_id}
                onChange={(value, opt) =>
                  handleChangeSelect("province_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchProvince(event.target.value)}
                  />
                )}
              >
                {ddsProvince?.map((value) => {
                  return (
                    <Select.Option value={value.id}>{value.name}</Select.Option>
                  );
                })}
              </Select>
              {errors.province_id && (
                // touched.province_id &&
                <ValidationError text={errors.province_id} />
              )}
            </Box>
          </Box>

          {/* district */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_DISTRICT")}</Text>
            </Box>
            <Box>
              <Select
                showSearch={false}
                allowClear="true"
                style={inputTheme}
                size="large"
                disabled={values?.province_id ? false : true}
                value={values?.district_id}
                onChange={(value, opt) =>
                  handleChangeSelect("district_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchDistrict(event.target.value)}
                  />
                )}
              >
                {
                  // ?.filter((val) => val.province_id === values.province_id)
                  ddsDistrict?.map((value) => {
                    return (
                      <Select.Option value={value.id}>
                        {value.name}
                      </Select.Option>
                    );
                  })
                }
              </Select>
              {errors.district_id && (
                // touched.district_id &&
                <ValidationError text={errors.district_id} />
              )}
            </Box>
          </Box>

          {/* sub district */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_SUB_DISTRICT")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                size="large"
                disabled={values?.district_id ? false : true}
                value={values?.sub_district_id}
                onChange={(value, opt) =>
                  handleChangeSelect("sub_district_id", value, opt)
                }
                allowClear="true"
                showSearch={false}
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchSubDistrict(event.target.value)}
                  />
                )}
              >
                {ddsSubDistrict?.map((value) => {
                  return <option value={value.id}>{value.name}</option>;
                })}
              </Select>
              {errors.sub_district_id && (
                // touched.sub_district_id &&
                <ValidationError text={errors.sub_district_id} />
              )}
            </Box>
          </Box>

          {/* urban village */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_URBAN_VILLAGE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                showSearch={false}
                disabled={values?.sub_district_id ? false : true}
                allowClear="true"
                value={values?.urban_village_id}
                size="large"
                onChange={(value, opt) =>
                  handleChangeSelect("urban_village_id", value, opt)
                }
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) =>
                      setSearchUrbanVillage(event.target.value)
                    }
                  />
                )}
              >
                {ddsUrbanVillage?.map((value) => {
                  return <option value={value.id}>{value.name}</option>;
                })}
              </Select>
              {errors.urban_village_id && (
                // touched.urban_village_id &&
                <ValidationError text={errors.urban_village_id} />
              )}
            </Box>
          </Box>
          {/* postal code */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_POSTAL_CODE")}</Text>
            </Box>
            <Box>
              <Select
                style={inputTheme}
                showSearch={false}
                disabled={values.urban_village_id ? false : true}
                name="postal_code_id"
                value={values?.postal_code_id}
                allowClear="true"
                onChange={(value, opt) =>
                  handleChangeSelect("postal_code_id", value, opt)
                }
                size="large"
                dropdownRender={(menu) => (
                  <DropDownCustom
                    menu={menu}
                    search={(event) => setSearchPostalCode(event.target.value)}
                  />
                )}
              >
                {ddsPostalCode?.map((value) => {
                  return <option value={value.id}>{value.code}</option>;
                })}
              </Select>
              {errors.postal_code_id && touched.postal_code_id && (
                <ValidationError text={errors.postal_code_id} />
              )}
            </Box>
          </Box>
          {/* hamlet */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_HAMLET")}</Text>
            </Box>
            <Box>
              <Input
                size="large"
                type="text"
                style={inputTheme}
                value={values?.hamlet}
                onChange={handleChange}
                name="hamlet"
              />
              {errors.hamlet && touched.hamlet && (
                <ValidationError text={errors.hamlet} />
              )}
            </Box>
          </Box>

          {/* neighborhood */}
          <Box padding={1} display="flex">
            <Box width="10rem">
              <Text>{t("USER:FIELD_NEIGHBORHOOD")}</Text>
            </Box>
            <Box>
              <Input
                type="text"
                value={values?.neighbourhood}
                style={inputTheme}
                name="neighbourhood"
                onChange={handleChange}
                // onChange={(e) => handleChange("neighbourhood", e)}
              />
              {errors.neighbourhood && touched.neighbourhood && (
                <ValidationError text={errors.neighbourhood} />
              )}
            </Box>
          </Box>
        </Box>
        {/* end of right side */}
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          onClick={handleCancel}
          link={ROUTES.HOME}
          text={t("COMMON:BUTTON_CANCEL")}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          // isDisabled={!isValid}
        />
      </Box>
    </form>

    //  end of big white box
  );
};

export default BodyUserProfile;
