import {
  Box,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
const BodyAuthentication = () => {
  const { t } = useTranslation();
  const getAuthentication = useSelector(
    (state) => state.user.detail_user.authentication
  );
  return (
    <Box padding={1}>
      {/* Authentication Id */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_AUTHENTICATION_ID")}</Box>
        <Box>
          <Text>
            <b>
              {getAuthentication.authentication_id
                ? getAuthentication.authentication_id
                : "0"}
            </b>
          </Text>
        </Box>
      </Box>
      {/* Client Id */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_CLIENT_ID")}</Box>
        <Box>
          <Text>
            <b>
              {getAuthentication.client_id
                ? getAuthentication.client_id
                : "client id"}
            </b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyAuthentication;
