import { Box, Button, Center, Modal, ModalBody, ModalContent, ModalOverlay, Stack } from "@chakra-ui/react";
import React from "react";
import { HOVER_PRIMARY, PRIMARY_COLOR } from "../constant/propertiesConstant";

const ModalCustom = ({ isOpen, onClose, icon, message, onOk }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent width={320}>
        <ModalBody>
          <Stack>
            <Center marginTop={6} marginBottom={2} children={icon} />
            
            <Center>
              <Box fontWeight={700} fontSize={20} textAlign="center" marginY={2} children={message} />
            </Center>

            <Center>
              <Button
                marginTop={2}
                marginBottom={6}
                width="32"
                color="white"
                background={PRIMARY_COLOR}
                fontWeight="normal"
                _hover={{ background: HOVER_PRIMARY }}
                _focus={{ boxShadow: 0 }}
                onClick={onOk ? onOk : onClose}
              >
                OK
              </Button>
            </Center>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalCustom;
