import {
  Box,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
const BodyActivation = () => {
  const { t } = useTranslation();
  const UpdatedAt = useSelector((state) => state.user.detail_user.created_at);
  const getActivation = useSelector(
    (state) => state.user.detail_user.activation
  );
  const defaultValuePeriod = "0001-01-01T00:00:00Z";
  let status =
    getActivation?.status === "A"
      ? t("COMMON:STATUS_ACTIVE")
      : t("COMMON:STATUS_INACTIVE");
  return (
    <Box padding={1}>
      {/*Status  */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_STATUS")}</Box>
        <Box>
          <Text>
            <b>{status ? status : "-"}</b>
          </Text>
        </Box>
      </Box>
      <Box display="flex" alignContent="space-between">
        <Box padding={1} marginBottom={2} marginRight={4}>
          <Box>{t("USER:FIELD_START_ACTIVE_PERIOD")}</Box>
          <Box>
            <Text>
              {/* <b>{moment(UpdatedAt).format("DD/MM/YYYY")}</b> */}
              <b>
                {getActivation?.start_active_period === defaultValuePeriod
                  ? "-"
                  : moment(getActivation?.start_active_period).format(
                      "DD/MM/YYYY"
                    )}
              </b>
            </Text>
          </Box>
        </Box>
        {/* End Active Period  */}
        <Box padding={1} marginBottom={2} marginLeft={4}>
          <Box>{t("USER:FIELD_END_ACTIVE_PERIOD")}</Box>
          <Box>
            <Text>
              <b>
                {getActivation?.end_active_period === defaultValuePeriod
                  ? "-"
                  : moment(getActivation?.end_active_period).format(
                      "DD/MM/YYYY"
                    )}
              </b>
              {/* <b>{moment(getActivation.end_active_period).format("DD/MM/YYYY")}</b> */}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyActivation;
