import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import HeadTitle from "../../../../../components/HeadTitle";

const TransactionTotal = () => {
    const { t } = useTranslation();
  const transaction_total = useSelector(
    (data) => data.processedPO?.detail_processed_po?.transaction_total
  );
    return ( 
        <>
      <Box pt={4} pb={2}>
        <HeadTitle titleSection={t("PROCESSED:TRANSACTION_TOTAL")} />
      </Box>
      {/* white body */}
      <Box
        backgroundColor="white"
        padding={4}
        display="flex"
        justifyContent="space-between"
        marginTop={2}
        borderRadius="10px"
      >
          {/* Address */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_ITEM_TOTAL")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.item_total === "" ? "-" : transaction_total?.item_total}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Address */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_DISCOUNT1")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.discount_1 === "" ? "-" : transaction_total?.discount_1}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Address */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_DISCOUNT2")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.discount_2 === "Coba" ? "-" : transaction_total?.discount_2}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Address */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_DISCOUNT3")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.discount_3 === "" ? "-" : transaction_total?.discount_3}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Address */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_TAX")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.tax === "Coba" ? "-" : transaction_total?.tax}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* net amount */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_NET_AMOUNT")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.net_amount === "" ? "-" : transaction_total?.net_amount}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* gross amount */}
          <Box padding={1} >
            <Box>{t("PROCESSED:FIELD_GROSS_AMOUNT")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {transaction_total?.gross_amount === "Coba" ? "-" : transaction_total?.gross_amount}{" "}
                </b>
              </Text>
            </Box>
          </Box>
      </Box>
    </>
     );
}
 
export default TransactionTotal;