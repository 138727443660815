import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import banner from "../../assets/banner.png";
import farmindoLogo from "../../assets/farmindo.svg";
import { Eye, HideEye, MediumSuccess } from "../../components/icons";
import ModalCustom from "../../components/ModalCustom";
import {
  HOVER_PRIMARY,
  PRIMARY_COLOR,
} from "../../constant/propertiesConstant";
import { REGEX_CONSTANT } from "../../constant/regexConstant";
import { ROUTES } from "../../constant/routeConstant";
import { resetPassword } from "../../services/authService";
import classes from "./ResetPassword.module.css";

const ResetPassword = () => {
  const { is_authenticated } = useSelector((state) => state.auth);
  const { auth_loading } = useSelector((state) => state.page);
  const { t } = useTranslation();
  const [hideNew, setHideNew] = useState(true);
  const [hideConfirm, setHideConfirm] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!searchParams.get('email') || !searchParams.get('forget_code') || !searchParams.get('user_id')) {
      history.replace(ROUTES.LOGIN, { replace: true })
    }
  }, [searchParams, history]);

  const onSubmit = async (data) => {
    const forget_code=searchParams.get('forget_code')
    let obj = {
      // email:,
      email:searchParams.get('email'),
      forget_code:searchParams.get('forget_code'),
      new_password: data.new_password,
      verify_new_password: data.confirm_password,
    };
    if (forget_code) {
      const { ok } = await resetPassword(obj, dispatch);
      if (ok) setIsSuccess(true);
    } else {
      setError(t("COMMON:INCORRECT"));
      setIsSuccess(false);
    }
  };

  return is_authenticated ? (
    <Redirect to={ROUTES.HOME} />
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <div
          className={classes.imgWrapper}
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div className={classes.formWrapper}>
          <Center>
            <img src={farmindoLogo} alt="logo" width="256px" />
          </Center>
          <div className={classes.formTitle}>
            <span>{t("COMMON:RESET_TITLE")}</span>
            <Box width={8} height="3px" backgroundColor={PRIMARY_COLOR} />
          </div>
          <div className={classes.form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Password */}
              <FormControl
                marginTop={4}
                marginBottom={8}
                isInvalid={errors.new_password}
              >
                <FormLabel
                  margin={0}
                  fontWeight={400}
                  fontSize={14}
                  color="#666666"
                >
                  {t("COMMON:FIELD_PASSWORD")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={hideNew ? "password" : "text"}
                    autoComplete="off"
                    {...register("new_password", {
                      required: true,
                      pattern: REGEX_CONSTANT.PASSWORD,
                    })}
                    variant="flushed"
                    placeholder={t("COMMON:NEW_PASSWORD")}
                  />
                  <InputRightElement width="3rem">
                    {/* {hide ? <ViewIcon onClick={() => setHide(!hide)} /> : <ViewOffIcon onClick={() => setHide(!hide)} />} */}
                    {hideNew ? (
                      <Icon as={Eye} onClick={() => setHideNew(!hideNew)} />
                    ) : (
                      <Icon as={HideEye} onClick={() => setHideNew(!hideNew)} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {!errors.new_password ? (
                  <FormHelperText fontWeight={400} fontSize={12}>
                    {t("COMMON:PASSWORD_DETAIL")}
                  </FormHelperText>
                ) : (
                  <FormErrorMessage fontWeight={400} fontSize={12}>
                    {t("COMMON:PASSWORD_DETAIL")}
                  </FormErrorMessage>
                )}
              </FormControl>

              {/* Password */}
              <FormControl
                marginTop={4}
                marginBottom={8}
                isInvalid={errors.confirm_password}
              >
                <FormLabel
                  margin={0}
                  fontWeight={400}
                  fontSize={14}
                  color="#666666"
                >
                  {t("COMMON:FIELD_PASSWORD")}
                </FormLabel>
                <InputGroup>
                  <Input
                    type={hideConfirm ? "password" : "text"}
                    autoComplete="off"
                    {...register("confirm_password", {
                      required: true,
                      validate: (value) =>
                        value === watch("new_password") ||
                         t("COMMON:PASSWORD_NOT_MATCH"),
                    })}
                    variant="flushed"
                    placeholder={t("COMMON:CONFIRM_PASSWORD")}
                  />
                  <InputRightElement width="3rem">
                    {/* {hide ? <ViewIcon onClick={() => setHide(!hide)} /> : <ViewOffIcon onClick={() => setHide(!hide)} />} */}
                    {hideConfirm ? (
                      <Icon
                        as={Eye}
                        onClick={() => setHideConfirm(!hideConfirm)}
                      />
                    ) : (
                      <Icon
                        as={HideEye}
                        onClick={() => hideConfirm(!hideConfirm)}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>

                <FormErrorMessage fontWeight={400} fontSize={12}>
                  {errors.confirm_password && errors.confirm_password.message}
                </FormErrorMessage>
              </FormControl>

              {/* Submit */}
              <div className={classes.formButton}>
                <Button
                  type="submit"
                  width="full"
                  color="white"
                  background={PRIMARY_COLOR}
                  _hover={{ background: HOVER_PRIMARY }}
                  isLoading={auth_loading}
                >
                  {t("COMMON:BUTTON_SET")}
                </Button>
              </div>
              <div className={classes.buttonCancel}>
                <Link
                  to={ROUTES.HOME}
                  style={{ textDecoration: "none", color: PRIMARY_COLOR }}
                >
                  <Button
                    width="full"
                    colorScheme={PRIMARY_COLOR}
                    variant="outline"
                    _hover={{ background: "white[500]" }}
                  >
                    {t("COMMON:BUTTON_CANCEL")}
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ModalCustom
        isOpen={isSuccess}
        onClose={() => history.push(ROUTES.LOGIN)}
        icon={<MediumSuccess />}
        message={t("COMMON:MESSAGE_SUCCESFULL_PASSWORD")}
      />
    </React.Fragment>
  );
};

export default ResetPassword;
