import {
  Box,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const BodyLoginAccount = () => {
  const { t } = useTranslation();
  const getLoginAccount = useSelector((state)=>state.user.detail_user.login_account);
  return (
    <Box padding={1}>
      {/* Username */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_USERNAME")}</Box>
        <Box>
          <Text>
            <b>{getLoginAccount.username ? getLoginAccount.username : "username"}</b>
          </Text>
        </Box>
      </Box>
      {/* Password */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_PASSWORD")}</Box>
        <Box>
          <Text>
            <b>xxxx</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyLoginAccount;
