import {
  Box,
  CloseButton,
  Icon,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Input, Pagination, Select, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import CustomCheckBox from "../../../../components/customCheckBox/CustomCheckBox";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  DetailIcon,
  RedDeleteIcon,
  SavedSuccess,
  SearchIcon,
} from "../../../../components/icons";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { ID_PROCESS_PO } from "../../../../constant/idConstant";
import {
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { selectOptFilterUserTheme } from "../../../../constant/themeConstant";
import { pageActions } from "../../../../reducers/pageReducer";
import {
  getInitListProcessedPO,
  getListDistributorDDS,
  getListMasterStoreDDS,
  getListPrincipalDDS,
  getListProcessedPO,
  multiDeleteProcessedPO,
  processedPOAction,
} from "../../../../services/main/processedPOReducer";
import StringUtil from "../../../../utils/stringUtil";

const ListProcessPO = () => {
  const { t } = useTranslation();
  const { list_processed_po: data } = useSelector((state) => state.processedPO);
  const dataSelectedRowKeys = useSelector(
    (state) => state.processedPO.selectedRowKeys
  );
  const table_loading = useSelector((state) => state.processedPO.table_loading);
  const { filters: objectFilter } = useSelector((state) => state.page);
  const [form, setForm] = useState(objectFilter?.form);
  const [total, setTotal] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [listID, setListID] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isSubmit, setIsSubmit] = useState(false);
  const [order, setOrder] = useState("");
  // filter distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");
  const [idDistributor, setidDistributor] = useState(
    objectFilter?.idDistributor
  );

  // principal
  const [principalId, setPrincipalId] = useState(objectFilter?.schema ?? 1);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);

  // store
  const [dataStore, setDataStore] = useState([]);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore] = useState(10);
  const [filterStoreName, setfilterStoreName] = useState("");
  const [idStore, setIdStore] = useState(objectFilter?.idStore);

  //  date range
  const [updatedDate, setUpdatedDate] = useState(
    objectFilter?.updatedDate ?? []
  );
  const [filterUpdatedDate, setfilterUpdatedDate] = useState(
    objectFilter?.filterUpdatedDate
  );

  // modal
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isReprocess, setisReprocess] = useState(false);
  const [reprocess, setReprocess] = useState(false);
  const [message, setMessage] = useState("");

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useMemo(() => {
    const [start, end] = updatedDate;
    if (start && end) {
      setfilterUpdatedDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterUpdatedDate([]);
  }, [updatedDate]);
  // const [objectFilter, setObjectFilter] = useState({
  //   idPrincipal: 1,
  //   idStore: null,
  //   idDistributor: null,
  //   transaction_date: "",
  //   form: "",
  // });
  const filter = StringUtil.formatFilter({
    schema: `eq ${objectFilter.schema ?? 1}`,
    distributor_id: `eq ${objectFilter?.idDistributor}`,
    // store_id: `eq ${objectFilter.idStore}`,
    store_id: `eq ${objectFilter?.idStore}`,
    // process_date: `range ${filterTransactionDate}`,
    process_date: `range ${objectFilter?.filterUpdatedDate}`,
    nopo: `like ${form}`,
  });

  const params = { page, limit, filter, order };
  const fetchData = () => {
    dispatch(getListProcessedPO(params));
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  useEffect(() => {
    dispatch(getInitListProcessedPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    // setIsDisabled(true);
    setSubmit(false);
    fetchData();
    dispatch(processedPOAction.resetselectedRowKeysReprocessedPO());
  }, [token, isSubmit, submit, order, dispatch]);

  useEffect(() => {
    dispatch(getInitListProcessedPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
  }, [page, limit]);

  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      // setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      // setIsDisabled(true);
      setPopOverOpen(true);
    }
    setForm(e.target.value);
  };

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    // setIsDisabled(true);
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    // setObjectFilter({
    //   idPrincipal: principalId,
    //   idStore: idStore,
    //   idDistributor: idDistributor,
    //   transaction_date: filterTransactionDate,
    //   form: form,
    // });
    dispatch(
      pageActions.setFilters({
        idPrincipal: principalId,
        idStore: idStore,
        idDistributor: idDistributor,
        filterUpdatedDate: filterUpdatedDate,
        updatedDate: updatedDate,
        form: form,
        schema: principalId,
      })
    );
  };
  const resetFilter = () => {
    setForm("");
    setIsDisabled(false);
    setButtonSearchOrClose("search");
    setPrincipalId(1);
    setFilterPrincipalName("");
    setIdStore(null);
    // setObjectFilter({
    //   idPrincipal: 1,
    //   idStore: null,
    //   idDistributor: null,
    //   transaction_date: "",
    //   form: "",
    // });
    setPopOverOpen(false);
    setfilterStoreName("");
    setFilterDistributor("");
    setidDistributor(null);
    setSubmit(true);
    setUpdatedDate([]);
    setPage(1);
    setLimit(10);
    dispatch(processedPOAction.resetselectedRowKeysReprocessedPO());
    setIsSubmit(!isSubmit);
    dispatch(pageActions.resetFilters());
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "distributor_id") {
      setidDistributor(value);
    } else if (name === "principal_id") {
      setPrincipalId(value);
    } else if (name === "store_id") {
      setIdStore(value);
    } else {
      setSelectDropDownFilter(option);
    }
  };

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi princapl

  // fungsi untuk DDS filter distributor
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    // const filter = `distributor_name like ${filterDistributor}`;
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };

  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };
  const handleSelectDistributor = (name, value, option) => {
    setidDistributor(value);
    setIsDisabled(false);
  };

  const handleDistributorClear = () => {
    setPageDistributor(1);
    setidDistributor("");
    setFilterDistributor("");
    setDataDistributor([]);
  };
  // end fungsi distributor

  // fungsi store
  const fetchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idStore ?? ""}`,
      store_name: `like ${filterStoreName}`,
    });
    const params = { page, limit, filter };

    dispatch(getListMasterStoreDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataStore([...dataStore, ...resp]);
    });
  };

  const fetchStoreSearch = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idStore ?? ""}`,
      store_name: `like ${filterStoreName}`,
    });
    const params =
      filterStoreName === "" ? { page, limit } : { page, limit, filter };

    dispatch(getListMasterStoreDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageStore(1);
      setDataStore(response.payload.response);
    });
  };

  useEffect(() => {
    fetchStore();
  }, [pageStore]);
  useEffect(() => {
    fetchStoreSearch();
  }, [filterStoreName]);

  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };

  // end fungsi store

  // fungsi date range
  const handleChangeDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setUpdatedDate([start, end]);
    } else setUpdatedDate([]);
    setIsDisabled(false);
  };

  const handleOnChangeChecked = (event, { id, updated_at }) => {
    dispatch(
      processedPOAction.setSingleCheck({ id: id, updated_at: updated_at })
    );
  };

  const handleChangeAllChecked = () => {
    dispatch(processedPOAction.setAllCheck(listID));
  };
  useEffect(() => {
    setListID(
      data?.map((item) => ({ id: item.id, updated_at: item.updated_at }))
    );
  }, [data]);
  const getCheckedAll = () => {
    // listID
    if (listID?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of listID) {
        isCheckedAll = false;
        for (const CheckedData of dataSelectedRowKeys) {
          if (ListIdData.id === CheckedData.id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) break;
      }
      return isCheckedAll;
    } else {
      return;
    }
  };
  const checkedAll = getCheckedAll();
  const hasSelected = dataSelectedRowKeys?.length > 0;

  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    // setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };
  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalReprocess = () => {
    const body = {
      schema_id: principalId,
      deleted_id: dataSelectedRowKeys,
    };
    setReprocess(true);
    setModalOpen(true);
    dispatch(multiDeleteProcessedPO(body)).then((resp) => {
      if (resp.payload.ok) {
        setReprocess(true);
        setModalOpen(true);
        setMessage(resp.payload.response);
      } else {
        setReprocess(true);
        setMessage(resp.payload.response);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setSubmit(true);
    setPage(1);
    setModalOpen(false);
    setModalConfirmOpen(false);
    dispatch(processedPOAction.resetselectedRowKeysReprocessedPO());
  };

  const confirmModal = () => {
    setModalConfirmOpen(true);
  };

  useEffect(() => {
    return () => {
      dispatch(processedPOAction.resetDetailProcessedPO());
    };
  }, []);
  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      distributor_name: item.distributor_name,
      po_number: item.po_number,
      transaction_date: moment(item.transaction_date)
        .subtract(361, "minutes")
        .format("DD/MM/YYYY"),
      process_date: moment(item.process_date)
        .subtract(361, "minutes")
        .format("DD/MM/YYYY"),
      store_name: item.store_name,
      checkBox: (
        <CustomCheckBox
          isChecked={dataSelectedRowKeys?.find((data) => data.id === item.id)}
          onChange={(event) =>
            handleOnChangeChecked(event, {
              id: item.id,
              updated_at: item.updated_at,
            })
          }
        />
      ),
      action: (
        <div>
          <ButtonCustom
            id={ID_PROCESS_PO.LIST.PRINCIPAL + "_" + item.id}
            link={{
              pathname: ROUTES_USER_BUYER.PROCESS.DETAIL,
              state: [item.id, principalId],
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));

  const columns = [
    {
      title: (
        <CustomCheckBox
          isChecked={checkedAll}
          onChange={handleChangeAllChecked}
        />
      ),
      dataIndex: "checkBox",
      key: "checkBox",
      width: 50,
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCESSED:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name.localeCompare(b.distributor_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCESSED:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number.localeCompare(b.po_number),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCESSED:FIELD_TRANSACTION_DATE")}
        </Box>
      ),
      dataIndex: "transaction_date",
      key: "transaction_date",
      sorter: (a, b) => a.transaction_date - b.transaction_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCESSED:FIELD_PROCESS_DATE")}
        </Box>
      ),
      dataIndex: "process_date",
      key: "process_date",
      sorter: (a, b) => a.process_date - b.process_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCESSED:FIELD_STORE_NAME")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name.localeCompare(b.store_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <Box p={4}>
      <HeadTitle
        title={t("PROCESSED:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCESSED:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCESSED:SUBTITLE_2")}
            </span>{" "}
            - {t("PROCESSED:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonCustom
              disabled={!hasSelected}
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={confirmModal}
              id="btn-UserSeller-ProductPrincipal-DetailProductPrincipal-Delete"
            />
          </>
        }
      />

      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* By Schema */}
          <Select
            id={ID_PROCESS_PO.LIST.PRINCIPAL}
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            placeholder="Select Principal"
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By date */}
          <Box width="240px">
            <InputDateRangePicker
              elementId={ID_PROCESS_PO.LIST.PROCESS_DATE}
              values={updatedDate}
              onChange={handleChangeDate}
              placeHolder={t("COMMON:PROCESS_DATE")}
            />
          </Box>
          {/* By Distributor */}
          <Select
            id={ID_PROCESS_PO.LIST.DISTRIBUTOR}
            style={{ width: 169 }}
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={handleDistributorClear}
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By Store */}
          <Select
            id={ID_PROCESS_PO.LIST.STORE}
            style={{ width: 169 }}
            size="large"
            value={idStore}
            onPopupScroll={handleScrollStore}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("store_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterStoreName}
                menu={menu}
                search={(e) => setfilterStoreName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_STORE")}
          >
            {dataStore &&
              dataStore.map((data, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={data.id}
                    title={data.store_name}
                  >
                    {data.store_name}
                  </Select.Option>
                );
              })}
          </Select>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              // hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              hoverPrimary={HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              // isDisabled={isDisabled}
              id={ID_PROCESS_PO.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
              id={ID_PROCESS_PO.LIST.BTN_RESET}
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Box>
          <Box marginLeft={"7.9rem"}>
            <CustomPopOver isOpen={popOverOpen} />
          </Box>
          <Stack className="my-select-container" marginBottom={5}>
            <InputGroup
              width="257px"
              height="40px"
              backgroundColor="white"
              borderRadius={5}
            >
              <Input
                id={"searchbynopo"}
                style={{ border: "1px solid #d9d9d9" }}
                type="text"
                placeholder={
                  selectDropDownFilter?.value
                    ? selectDropDownFilter.children
                    : t("PROCESSED:SEARCH_BY_NO_PO")
                }
                fontSize="14px"
                onChange={(e) => handleSearch(e)}
                name="group_id"
                pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
                value={form}
              />
              {renderSearchOrCloseButton()}
            </InputGroup>
          </Stack>
        </Box>

        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          onChange={handleTableChange}
          loading={table_loading}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_PROCESS_PO.LIST.BTN_PAGINATION}
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>

      <ModalCustom
        icon={<SavedSuccess />}
        message={message}
        onOk={clickOk}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalReprocess}
        isOpen={modalConfirmOpen}
      />
    </Box>
  );
};

export default ListProcessPO;
