import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  listDashboard: [],
  listDashboardCS: [],
};

export const getListPrincipalDDS = createAsyncThunk(
  "getListPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDashboard = createAsyncThunk(
  "List Dashboard Seller",
  async (body, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/dashboard`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, body);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error)
    }
    return result;
  }
);

export const getListDashboardBuyer = createAsyncThunk(
    "List Dashboard Buyer",
    async (params, { getState, rejectWithValue }) => {
      let result = { ok: false, response: null };
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/dashboard/buyer`;
      const { auth } = getState();
      const token = auth.token;
  
      try {
        const response = await baseApi.doPost(url, token, params);
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      } catch (error) {
        if (error.response) {
          result.ok = false;
          result.response = error.response;
        } else {
          message.error(i18next.t("COMMON:ERROR_NETWORK"));
        }
        return rejectWithValue(error)
      }
      return result;
    }
  );

  const dashboard = createSlice({
    name: "dashboard",
    initialState: INITIAL_STATE,
    reducers: {},
    extraReducers: {
      [getListDashboard.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListDashboard.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.listDashboard = action.payload.response;
      },
      [getListDashboard.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },
      [getListDashboardBuyer.pending]: (state, action) => {
        state.status = "loading";
        state.table_loading = true;
      },
      [getListDashboardBuyer.fulfilled]: (state, action) => {
        state.status = "success";
        state.table_loading = false;
        state.listDashboardCS = action.payload.response;
      },
      [getListDashboardBuyer.rejected]: (state, action) => {
        state.status = "failed";
        state.table_loading = false;
      },
    },
  });
  
  export const dashboardAction = dashboard.actions;
  
  export default dashboard.reducer;