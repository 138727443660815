import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Flex,
  Portal,
  Box,
  Spinner as ChakraSpinner,
  useBoolean
} from '@chakra-ui/react';
import { MediumFailed, MediumSuccess } from './icons';
import { BLACK_COLOR, PRIMARY_COLOR } from '../constant/propertiesConstant';
import ButtonPrimary from './button/ButtonPrimary';

const Spinner = (props) => (<ChakraSpinner
      thickness='3px'
      speed='0.65s'
      emptyColor='gray.200'
      color={PRIMARY_COLOR}
      size='lg'
      {...props}
    />)

const ModalNotificationWithLoading = ({ 
  initialIsOpen, 
  isLoading, 
  isSuccess, 
  loadingMessage, 
  successMessage, 
  errorMessage,
  buttonText,
  onClose
}) => {
  const { t } = useTranslation();
  const [isOpen, { off: _onClose }] = useBoolean(initialIsOpen);

  const handleOnClose = () => {
    _onClose();
    onClose && onClose();
  }

  return (
    <Portal key="farmindo:admin-modal-notif-loading">
      <Modal
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          width='300px'
          minHeight='260px'
        >
          <ModalBody>
            <Flex
              direction='column'
              alignItems='center'
              width='full'
              paddingY='8'
            >
              {isLoading ? (
                <>
                  <Spinner h='60px' w='60px' borderWidth='4px' />
                  <Text
                    fontSize='xl'
                    fontWeight='700'
                    color={BLACK_COLOR}
                    textAlign='center'
                    mt='4'
                  >
                    {loadingMessage}
                  </Text>
                </>
              ) : (
                <>
                  {isSuccess && !isLoading ? <MediumSuccess /> : <MediumFailed />}
                  <Text
                    fontSize='xl'
                    fontWeight='700'
                    color={BLACK_COLOR}
                    textAlign='center'
                    mt='3'
                  >
                    {isSuccess ? successMessage : errorMessage}
                  </Text>
                </>
              )}
              <Box mt='6'>
                <ButtonPrimary 
                  onClick={handleOnClose}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  text={buttonText}
                />
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

export default ModalNotificationWithLoading;
