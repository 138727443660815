import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";
import { checkRejectErrorWithValue, formatError, formatErrorResponse } from "../../helpers/errorHelper";

const SessionApi = {
  getUserAuthenticationId: (username, token) => {
    return baseApi.doPost(`${API_URL}/${API_VERSION}/${API_PREFIX}/users/activation-get-auth`, token, { username });
  },
  requestActivationCode: (id, email, token) => {
    return baseApi.doPut(`${API_URL}/${API_VERSION}/${API_PREFIX}/users/activation`, token, { user_id: String(id), email });
  },
  confirmationActivationCode: (id, authCode, email, token) => {
    return baseApi.doPost(`${API_URL}/${API_VERSION}/${API_PREFIX}/users/activation`, token, { user_id: String(id), email, email_code: authCode });
  }
}

const initialState = {
  is_submitting: false,
  has_error: false,
  error: {
    code: '',
    message: ''
  },
  response: {
    success: null,
    code: '',
    message: '',
    detail: ''
  }
}

const sendRequestActivationCode = createAsyncThunk(
  'sendRequestActivationCode',
  async ({ username, email }, { getState, rejectWithValue }) => {
    const { auth } = getState();
    try {
      const responseAuthId = await SessionApi.getUserAuthenticationId(username, auth.token);
      const authenticationId = responseAuthId.data?.nexsoft?.payload?.data?.content?.authentication?.authentication_id;

      const responseRequestActivation = await SessionApi.requestActivationCode(authenticationId, email, auth.token);
      const message = responseRequestActivation.data?.nexsoft?.payload?.status?.message;
      return { ok: true, success: true, response: message, message }
    } catch (error) {
      return rejectWithValue(formatError(error, formatErrorResponse));
    }
  }
);

const confirmationActivationUser = createAsyncThunk(
  'confirmationActivationUser',
  async ({ userId, activationCode, email }, { getState ,rejectWithValue }) => {
    const { auth } = getState();
    try {
      const { data } = await SessionApi.confirmationActivationCode(userId, activationCode, email, auth.token);
      const message = data?.nexsoft?.payload?.status?.message;
      return { ok: true, success: true, response: message, message }
    } catch (error) {
      return rejectWithValue(formatError(error, formatErrorResponse));
    }
  }
)

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(sendRequestActivationCode.pending, ( state, { payload }) => {
      state.is_submitting = true;
      state.has_error = false;
      state.error = null;
      state.response = null;
    });
    builder.addCase(sendRequestActivationCode.fulfilled, ( state, { payload }) => {
      state.is_submitting = false;
      state.response = payload;
    });
    builder.addCase(sendRequestActivationCode.rejected, ( state, { payload }) => {
      state.is_submitting = false;
      state.response = null;
      state.has_error = true;
      state.error = payload;
    });

    builder.addCase(confirmationActivationUser.pending, ( state, { payload }) => {
      state.is_submitting = true;
      state.has_error = false;
      state.error = null;
      state.response = null;
    });
    builder.addCase(confirmationActivationUser.fulfilled, ( state, { payload }) => {
      state.is_submitting = false;
      state.response = payload;
    });
    builder.addCase(confirmationActivationUser.rejected, ( state, { payload }) => {
      state.is_submitting = false;
      state.response = null;
      state.has_error = true;
      state.error = payload;
    });
  }
});

export const useWithRequestActivationCode = ({ onSuccess, onError, onFinally } = {}) => {
  const dispatch = useDispatch();
  const { is_submitting, error, response, has_error } = useSelector((state) => state.session);

  const handleOnSubmit = useCallback((data) => {
    dispatch(sendRequestActivationCode(data))
      .then((withValue) => {
        if (checkRejectErrorWithValue(withValue)) {
          onError && onError(withValue.payload);
        } else {
          onSuccess && onSuccess(withValue.payload);
        }
      })
      .finally(onFinally);
  }, [dispatch, onSuccess, onError, onFinally]);

  return {
    error,
    response,
    is_submitting,
    handleOnSubmit,
    has_error
  }
}

export const useWithConfirmationActivation = ({ onSuccess, onError, onFinally } = {}) => {
  const dispatch = useDispatch();
  const { is_submitting, error, response } = useSelector((state) => state.session);

  const handleOnSubmit = useCallback(({ userId, email, activationCode }) => {
    dispatch(confirmationActivationUser({ userId, email, activationCode }))
    .then((withValue) => {
      if (checkRejectErrorWithValue(withValue)) {
        onError && onError(withValue.payload);
      } else {
        onSuccess && onSuccess(withValue.payload);
      }
    })
    .finally(onFinally);
  }, [dispatch, onSuccess, onError, onFinally]);

  return {
    error,
    response,
    is_submitting,
    handleOnSubmit
  }
}

export default sessionSlice.reducer;