import { Box, Flex, Text } from "@chakra-ui/react";
import { Form, Input, Select, Space } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ButtonCustom from "../../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../../components/button/ButtonPrimary";
import InputDatePicker from "../../../../../../components/datePicker/InputDatePicker";
import {
  AddIcon,
  DeleteSuccess,
  RedDeleteIcon,
  SavedSuccess
} from "../../../../../../components/icons";
import ModalCustom from "../../../../../../components/ModalCustom";
import { ID_DISTRIBUTOR } from "../../../../../../constant/idConstant";
import { GRAY_COLOR } from "../../../../../../constant/propertiesConstant";
import { REGEX_CONSTANT } from "../../../../../../constant/regexConstant";
import { ROUTES_USER_SELLER } from "../../../../../../constant/routeConstant";
import { personTitle } from "../../../../../../constant/selectOptConstant";
import {
  inputForContactPerson,
  inputTheme
} from "../../../../../../constant/themeConstant";
import {
  addDistributor,
  distributorAction
} from "../../../../../../services/main/distributorReducer";
const BodyContactPerson = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Option } = Select;
  const { curr_page } = useSelector((state) => state.distributor);
  const { index_page } = useSelector((state) => state.distributor);
  const { company_profile } = useSelector((state) => state.distributor);
  const [dataContactPerson, setDataContactPerson] = useState({});

  const [indexContactPerson, setIndexContactPerson] = useState(0);
  const toPreviousPage = () => {
    dispatch(distributorAction.setIndexPage(index_page - 1));
    dispatch(
      distributorAction.setCurrPage(t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES"))
    );
    dispatch(distributorAction.setDataContactPerson());
  };

  /// fungsi untuk modal
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  const handleFormChange = (event, index) => {
    let data = [...formFields];
    data[index][event.target.name] = event.target.value;
    setFormFields(data);
  };
  const handleChangeSelect = (name, value, index) => {
    let data = [...formFields];
    data[index][name] = value;
    setFormFields(data);
  };
  const handleChangeDate = (name, value, index) => {
    let data = [...formFields];
    data[index][name] = moment(value[0]).format("YYYY-MM-DD");
    setFormFields(data);
  };
  const handleAddNewConPer = () => {
    // dia gabungin form field dan initial value dan nambahin index +1
    setIndexContactPerson(indexContactPerson + 1);
    // if (formFields.length === 0) {
    //   setFormFields(initialValue);
    // }
    // setFormFields([...formFields, initialValue]);
    // add();
  };
  const handleDeleteNewConPer = () => {
    // dia ngeset index kembali ke 0 kalo index nya kurang dari 0
    // ngosongin form field ke initial value lagi
    if (indexContactPerson < 0) {
      setIndexContactPerson(0);
    }
    setIndexContactPerson(indexContactPerson - 1);
    // setFormFields([]);
  };

  const initialValue = {
    person_title_id: null,
    first_name: "",
    last_name: "",
    nik: "",
    phone: "",
    phone_code: "+62",
    email: "",
    join_date: null,
    resign_date: null,
  };

  const [formFields, setFormFields] = useState([]);

  const onHandleSubmit = (values) => {
    setDataContactPerson(values);
    // dispatch(distributorAction.setDataContactPerson(values));
    dispatch(addDistributor(values)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };

  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.DISTRIBUTOR.LIST} />
  ) : (
    <>
      <Form
        onFinish={onHandleSubmit}
        // autoComplete="off"
      >
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          borderRadius="10px"
        >
          <Form.List name="contact_person">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <Box // create new contact person
                      key={index}
                      border="1px dashed #666666"
                      borderRadius="8px"
                      padding={4}
                      marginBottom={5}
                    >
                      <Flex justifyContent="space-between" marginBottom="6">
                        <Text fontSize="2xl" fontWeight="700">
                          {t("DISTRIBUTOR:CREATE_NEW_CONTACT_PERSON")}
                        </Text>
                        <ButtonCustom
                          variant="outline"
                          colorScheme="red"
                          marginRight={2}
                          width="5rem"
                          id={ID_DISTRIBUTOR.ADD.BTN_DELETE}
                          icon={<RedDeleteIcon />}
                          text={t("COMMON:BUTTON_DELETE")}
                          onClick={() => {
                            remove(field.name);
                            handleDeleteNewConPer();
                          }}
                        />
                      </Flex>
                      {/* Person Title */}
                      <Form.Item
                        name={[field.name, "person_title_id"]}
                        // key={field.key}
                        labelAlign="left"
                        labelCol={{ style: { width: 200 } }}
                        colon={false}
                        label={t("DISTRIBUTOR:FIELD_PERSON_TITLE")}
                        rules={[
                          {
                            required: true,
                            message: t("COMMON:ERROR_REQUIRED", {
                              field: t("DISTRIBUTOR:FIELD_PERSON_TITLE"),
                            }),
                          },
                        ]}
                      >
                        <Select
                          style={inputForContactPerson}
                          size="large"
                          id={ID_DISTRIBUTOR.ADD.PERSON_TITLE}
                        >
                          {personTitle?.map((value, index) => {
                            return (
                              <>
                                <Option key={index} value={value.id}>
                                  {t(value.label)}
                                </Option>
                              </>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      {/* First Name*/}
                      <Form.Item
                        key={field.key}
                        name={[field.name, "first_name"]}
                        labelAlign="left"
                        labelCol={{ style: { width: 200 } }}
                        colon={false}
                        label={t("DISTRIBUTOR:FIELD_FIRST_NAME")}
                        rules={[
                          {
                            required: true,
                            message: t("COMMON:ERROR_REQUIRED", {
                              field: t("DISTRIBUTOR:FIELD_FIRST_NAME"),
                            }),
                          },
                          {
                            pattern: REGEX_CONSTANT.NAME,
                            message: t("COMMON:ERROR_FORMAT", {
                              field: t("DISTRIBUTOR:FIELD_FIRST_NAME"),
                            }),
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          style={inputTheme}
                          size="large"
                          id={ID_DISTRIBUTOR.ADD.FIRST_NAME}
                        />
                      </Form.Item>
                      {/* Last Name*/}
                      <Form.Item
                        name={[field.name, "last_name"]}
                        labelAlign="left"
                        labelCol={{ style: { width: 200 } }}
                        colon={false}
                        label={t("DISTRIBUTOR:FIELD_LAST_NAME")}
                        rules={[
                          {
                            pattern: REGEX_CONSTANT.NAME,
                            message: t("COMMON:ERROR_FORMAT", {
                              field: t("DISTRIBUTOR:FIELD_LAST_NAME"),
                            }),
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          style={inputTheme}
                          size="large"
                          id={ID_DISTRIBUTOR.ADD.LAST_NAME}
                        />
                      </Form.Item>
                      {/* Identification Number*/}
                      <Form.Item
                        name={[field.name, "nik"]}
                        labelCol={{ style: { width: 200 } }}
                        labelAlign="left"
                        colon={false}
                        label={t("DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER")}
                        rules={[
                          {
                            required: true,
                            message: t("COMMON:ERROR_REQUIRED", {
                              field: t(
                                "DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER"
                              ),
                            }),
                          },
                          {
                            pattern: REGEX_CONSTANT.NIK,
                            message: t("COMMON:ERROR_FORMAT", {
                              field: t(
                                "DISTRIBUTOR:FIELD_IDENTIFICATION_NUMBER"
                              ),
                            }),
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          style={inputTheme}
                          size="large"
                          id={ID_DISTRIBUTOR.ADD.NIK}
                        />
                      </Form.Item>
                      <Space direction="horizontal">
                        {/* Phone code*/}
                        <Form.Item
                          name={[field.name, "phone_code"]}
                          labelCol={{ style: { width: 200 } }}
                          labelAlign="left"
                          colon={false}
                          label={t("USER:FIELD_PHONE")}
                          rules={[
                            {
                              required: true,
                              message: t("COMMON:ERROR_REQUIRED", {
                                field: t("DISTRIBUTOR:FIELD_PHONE_CODE"),
                              }),
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: "80px",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStyle: "normal",
                            }}
                            size="large"
                            id={ID_DISTRIBUTOR.ADD.PHONE_CODE}
                            name="phone_code"
                          >
                            <option value="+62">+62</option>
                          </Select>
                        </Form.Item>
                        {/* Phone*/}
                        <Form.Item
                          name={[field.name, "phone"]}
                          labelCol={{ style: { width: 200 } }}
                          labelAlign="left"
                          colon={false}
                          // label={t("USER:FIELD_PHONE")}
                          rules={[
                            {
                              required: true,
                              message: t("COMMON:ERROR_REQUIRED", {
                                field: t("DISTRIBUTOR:FIELD_PHONE"),
                              }),
                            },
                            {
                              pattern: REGEX_CONSTANT.PHONE_NUMBER,
                              message: t("COMMON:ERROR_FORMAT", {
                                field: t("DISTRIBUTOR:FIELD_PHONE"),
                              }),
                            },
                          ]}
                        >
                          <Input
                            type="tel"
                            style={{
                              width: "170px",
                              fontSize: "16px",
                              fontWeight: "500",
                              fontStyle: "normal",
                              marginLeft: "-10px",
                            }}
                            size="large"
                            name="phone"
                            id={ID_DISTRIBUTOR.ADD.PHONE}
                          />
                        </Form.Item>
                      </Space>

                      {/* Email*/}
                      <Form.Item
                        name={[field.name, "email"]}
                        labelCol={{ style: { width: 200 } }}
                        labelAlign="left"
                        colon={false}
                        label={t("DISTRIBUTOR:FIELD_EMAIL")}
                        rules={[
                          {
                            required: true,
                            message: t("COMMON:ERROR_REQUIRED", {
                              field: t("DISTRIBUTOR:FIELD_EMAIL"),
                            }),
                          },
                          {
                            pattern: REGEX_CONSTANT.EMAIL,
                            message: t("COMMON:ERROR_FORMAT", {
                              field: t("DISTRIBUTOR:FIELD_EMAIL"),
                            }),
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          style={inputTheme}
                          size="large"
                          id={ID_DISTRIBUTOR.ADD.EMAIL}
                        />
                      </Form.Item>
                      {/* Join Date & Resign Date*/}
                      <Box display={"flex"}>
                        {/* box join date  */}
                        <Box marginEnd={3}>
                          <Form.Item
                            name={[field.name, "join_date"]}
                            labelCol={{ style: { width: 200 } }}
                            labelAlign="left"
                            colon={false}
                            label={t("DISTRIBUTOR:FIELD_JOIN_DATE")}
                          >
                            <InputDatePicker
                              marginend={2}
                              width="250px"
                              id={ID_DISTRIBUTOR.ADD.JOIN_DATE}
                            />
                          </Form.Item>
                        </Box>
                        {/* Box resign date */}
                        <Box marginStart={3}>
                          <Form.Item
                            name={[field.name, "resign_date"]}
                            labelCol={{ style: { width: 150 } }}
                            labelAlign="left"
                            colon={false}
                            label={t("DISTRIBUTOR:FIELD_RESIGN_DATE")}
                          >
                            <InputDatePicker
                              width="250px"
                              id={ID_DISTRIBUTOR.ADD.RESIGN_DATE}
                            />
                          </Form.Item>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
                {/* box dash add new contact person  */}
                <Form.Item>
                  <Box
                    border="1px dashed #666666"
                    borderRadius="8px"
                    padding={4}
                  >
                    <Flex justifyContent="space-between">
                      <Text fontSize="2xl" fontWeight="700">
                        {indexContactPerson === 0
                          ? t("DISTRIBUTOR:CREATE_NEW_CONTACT_PERSON")
                          : `${t("DISTRIBUTOR:CONTACT_INDEX")} ${
                              indexContactPerson + 1
                            }`}
                      </Text>
                      <ButtonPrimary
                        text={t("DISTRIBUTOR:ADD_CONTACT")}
                        icon={<AddIcon />}
                        fontWeight={500}
                        fontSize={14}
                        onClick={() => {
                          // add();
                          add({
                            ...initialValue,
                            isNew: true,
                          });
                          handleAddNewConPer();
                        }}
                        backgroundColor={GRAY_COLOR}
                        id={ID_DISTRIBUTOR.ADD.BTN_ADD_CONTACT}
                      />
                    </Flex>
                  </Box>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            onClick={toPreviousPage}
            text={t("COMMON:BUTTON_PREVIOUS")}
            id={ID_DISTRIBUTOR.ADD.BTN_PREVIOUS}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_DISTRIBUTOR.ADD.BTN_SAVE}
          />
        </Box>
        <ModalCustom
          message={resp}
          isOpen={modalOpen}
          onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Form>
    </>
  );
};

export default BodyContactPerson;
