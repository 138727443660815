import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";
import { arrayOrArrOfObj } from "../../utils/arrayOrArrOfObjUtil";

const INITIAL_STATE = {
  list: [],
  status: "",
  table_loading: false,
  list_product_principal: [],
  list_product_category: [],
};
export const getInitiateListProductSubtitute = createAsyncThunk(
  "getInitiateListProductSubtitute",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-substitute/initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductSubtitute = createAsyncThunk(
  "getListProductSubtitute",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-substitute`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailProductSubtitute = createAsyncThunk(
  "detailProductSubtitute",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-substitute/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const deleteProductSubtitute = createAsyncThunk(
  "deleteProductSubtitute",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      productId: object.productId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-substitute/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addProductSubtitute = createAsyncThunk(
  "addProductSubtitute",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-substitute`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editProductSubtitute = createAsyncThunk(
  "editSubtitute",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const isArrayOfObj = arrayOrArrOfObj(object?.product_principal_id_from);
    const body = {
      product_principal_id_from: isArrayOfObj
        ? object?.product_principal_id_from.map((val) => {
            return val.value;
          })
        : object?.product_principal_id_from.map((val) => {
            return val;
          }),
      // product_principal_id_from: [object?.product_principal_id_from?.value],
      product_principal_id_to: object?.product_principal_id_to?.value,
      old_product_principal_id_to: object?.old_product_principal_id_to?.value,
      product_category_id: object?.product_category_id?.value,
      status: object?.status,
      updated_at: object.updatedAt,
      // id : object?.productId
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-substitute/${object.productId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductPrincipal = createAsyncThunk(
  "getListProductPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCategory = createAsyncThunk(
  "getListProductCategory",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const productSubtitute = createSlice({
  name: "productSubtitute",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxProductSubtitute() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getInitiateListProductSubtitute.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProductSubtitute.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProductSubtitute.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListProductSubtitute.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductSubtitute.fulfilled]: (state, action) => {
      state.status = "succcess";
      state.table_loading = false;
      state.list = action.payload.response;
    },
    [getListProductSubtitute.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [detailProductSubtitute.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductSubtitute.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailProductSubtitute.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteProductSubtitute.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductSubtitute.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductSubtitute.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [addProductSubtitute.pending]: (state, action) => {
      state.status = "loading";
    },
    [addProductSubtitute.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addProductSubtitute.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editProductSubtitute.pending]: (state, action) => {
      state.status = "loading";
    },
    [editProductSubtitute.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editProductSubtitute.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    // principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
    // product principal
    [getListProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_principal = action.payload.response;
    },
    [getListProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
    },
    // product category
    [getListProductCategory.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListProductCategory.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_category = action.payload.response;
    },
    [getListProductCategory.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const productSubtituteAction = productSubtitute.actions;

export default productSubtitute.reducer;
