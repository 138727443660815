import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BackToList from "../../../../components/BackToList";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  BlueEditIcon,
  DeleteSuccess, RedDeleteIcon,
  SavedSuccess
} from "../../../../components/icons";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import { ID_CSM_MASTER } from "../../../../constant/idConstant";
import { PRIMARY_COLOR } from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { deleteStoreSupplier, detailStoreSupplier } from "../../../../services/main/storeSupplierConnectionReducer";

const DetailStoreSuppConn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [messageConfirm, setMessageConfirm] = useState("");
  const [deletedStore, setDeletedStore] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const customer_store_mapping = useSelector(
    (data) =>
      data.storeSupplierConnection?.detail_connection_store
        .customer_store_mapping
  );
  const data_management_history = useSelector(
    (data) =>
      data.storeSupplierConnection?.detail_connection_store
        .data_management_history
  );
  const fetchData = async () => {
    dispatch(detailStoreSupplier(location.state));
    
  };

  useEffect(() => {
    fetchData();
  }, []);

  // fungsi modal delete
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.LIST);
    setModalOpen(false);
  };
  const modalFailed = () => {
    setModalConfirm(false);
    setModalOpen(false);
  };
  const confirmModal = () => {
    setModalConfirm(true);
    setMessageConfirm(t("COMMON:DELETE_CONFIRM"));
  };

  // fungsi delete
  const confirmedDelete = () => {
    setModalConfirm(false);
    const Id = location.state;
    const updated_at = data_management_history.modified_on;
    const params = { Id, updated_at };
    dispatch(deleteStoreSupplier(params)).then((resp) => {
      if (resp.payload.ok) {
        setErr(true);
        setModalOpen(true);
        setResponse(resp.payload.response);
        setDeletedStore(true);
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response);
        setErr(true);
      }
    });
  };

  return (
    <Box p={4}>
      <HeadTitle
        title={t("STORE_SUPPLIER_CONNECTION:DETAIL_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_3")}
            </span>{" "}-{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE_SUPPLIER_CONNECTION:TITLE_LIST")}
            </span>{" "}
            - {t("STORE_SUPPLIER_CONNECTION:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.EDIT,
                state: location.state,
              }}
              id={ID_CSM_MASTER.DETAIL.BTN_EDIT}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={confirmModal}
              id={ID_CSM_MASTER.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
      id={ID_CSM_MASTER.DETAIL.BTN_BACK}
        link={ROUTES_USER_BUYER.STORE_SUPPLIER_CONNECTION.LIST}
        name={data_management_history?.created_name}
        date={moment(data_management_history?.created_on).format("DD/MM/YYYY")}
        modifiedDate={moment(data_management_history?.modified_on).format(
          "DD/MM/YYYY"
        )}
        modifiedBy={data_management_history?.modified_name}
      />
      {/* White Box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        display="flex"
        marginTop={2}
        borderRadius="10px"
        gap="16px"
      >
        {/* left side */}
        <Box
          minWidth="37vw"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="16px"
        >
          {/* CUSTOMER CODE */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE_SUPPLIER_CONNECTION:FIELD_SUPPLIER_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {customer_store_mapping?.supplier_code === ""
                    ? "-"
                    : customer_store_mapping?.supplier_code}
                </b>
              </Text>
            </Box>
          </Box>
          {/* DISTRIBUTOR CODE*/}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_CODE")}
              </Text>
              <Text>
                <b>
                  {customer_store_mapping?.distributor_code === ""
                    ? "-"
                    : customer_store_mapping?.distributor_code}
                </b>
              </Text>
            </Box>
          </Box>
          {/* CUSTOMER NAME*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_CUSTOMER_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.customer_code === ""
                  ? "-"
                  : customer_store_mapping?.customer_code}
              </b>
            </Text>
          </Box>
          {/* alias store*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_ALIAS")}</Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.store_alias === ""
                  ? "-"
                  : customer_store_mapping?.store_alias}
              </b>
            </Text>
          </Box>{/* store id*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.store_code === ""
                  ? "-"
                  : customer_store_mapping?.store_code}
              </b>
            </Text>
          </Box>
          {/* Alias*/}
          <Box padding={1} width="20rem">
            <Box>
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_ALIAS")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.alias === ""
                  ? "-"
                  : customer_store_mapping?.alias}
              </b>
            </Text>
          </Box>
          {/* pm Handling*/}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_PM_HANDLING")}</Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.pm_handling === "A"
                  ? t("COMMON:DROP_ALL")
                  : t("COMMON:DROP_ITEM")}
              </b>
            </Text>
          </Box>
          {/* notification name*/}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_NOTIFICATION_NAME")}
              </Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.notification_name === ""
                  ? "-"
                  : customer_store_mapping?.notification_name}
              </b>
            </Text>
          </Box>
          {/* Alert */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_ALERT")}</Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.notification_alert_name === ""
                  ? "-"
                  : customer_store_mapping?.notification_alert_name}
              </b>
            </Text>
          </Box>
          {/* status*/}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STATUS")}</Text>
            </Box>
            <Text>
              <b>
                {customer_store_mapping?.status === "A"
                  ? "Active"
                  : "Non active"}
              </b>
            </Text>
          </Box>
        </Box>

        {/* Right side */}
        <Box
          width= "329px"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="16px"
        >
          {/* Customer Name */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE_SUPPLIER_CONNECTION:FIELD_CUSTOMER_NAME")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {customer_store_mapping?.customer_name === ""
                    ? "-"
                    : customer_store_mapping?.customer_name}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* principal id */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.principal_name === ""
                  ? "-"
                  : customer_store_mapping?.principal_name}{" "}
              </b>
            </Text>
          </Box>
          {/* Distributor name */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE_SUPPLIER_CONNECTION:FIELD_DISTRIBUTOR_NAME")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {" "}
                  {customer_store_mapping?.distributor_name === ""
                    ? "-"
                    : customer_store_mapping?.distributor_name}{" "}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Salesman code */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_SALESMAN_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.additional_data.salesman_code === ""
                  ? "-"
                  : customer_store_mapping?.additional_data.salesman_code}{" "}
              </b>
            </Text>
          </Box>
          {/* salesman name */}
          <Box padding={1} width="20rem">
            <Box>{t("STORE_SUPPLIER_CONNECTION:FIELD_SALESMAN_NAME")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {customer_store_mapping?.additional_data.salesman_name === ""
                    ? "-"
                    : customer_store_mapping?.additional_data.salesman_name}
                </b>
              </Text>
            </Box>
          </Box>
          {/* warehouse id */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_WAREHOUSE_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.warehouse_code === ""
                  ? "-"
                  : customer_store_mapping?.warehouse_code}{" "}
              </b>
            </Text>
          </Box>
          {/* warehouse name */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_WAREHOUSE_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.warehouse_name === ""
                  ? "-"
                  : customer_store_mapping?.warehouse_name}{" "}
              </b>
            </Text>
          </Box>
          {/* store name */}
          <Box padding={1} width= "30vw">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_STORE_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.store_name === ""
                  ? "-"
                  : customer_store_mapping?.store_name}{" "}
              </b>
            </Text>
          </Box>
          {/* BKL */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>{t("STORE_SUPPLIER_CONNECTION:FIELD_BKL")}</Text>
            </Box>
            <Text>
              <b> {customer_store_mapping?.is_bkl === "Y" ? "Yes" : "No"} </b>
            </Text>
          </Box>
          {/* product mapping */}
          <Box padding={1} width="20rem">
            <Box width="10rem">
              <Text>
                {t("STORE_SUPPLIER_CONNECTION:FIELD_PRODUCT_MAPPING")}
              </Text>
            </Box>
            <Text>
              <b>
                {" "}
                {customer_store_mapping?.is_product_mapping === "Y"
                  ? "Yes"
                  : "No"}{" "}
              </b>
            </Text>
          </Box>
        </Box>
        <ModalConfirm
          isOpen={modalConfirm}
          messageModal={t("COMMON:DELETE_TITLE")}
          messageConfirm={messageConfirm}
          onOk={confirmedDelete}
          onClose={modalFailed}
          confirmYes={t("COMMON:YES")}
        confirmNo={t("COMMON:NO")}
        />
        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={deletedStore ? modalSuccess : modalFailed}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </Box>
    </Box>
  );
};

export default DetailStoreSuppConn;
