import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Pagination, Select, Table, message } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HeadTitle from "../../../../../components/HeadTitle";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import {
  BlueDownloadIcon,
  DetailIcon,
  SearchIcon,
} from "../../../../../components/icons";
import {
  GRAY_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER
} from "../../../../../constant/routeConstant";
import { useRef } from "react";
import DropDownCustom from "../../../../../components/DropDownCustom";
import ButtonIcon from "../../../../../components/button/ButtonIcon";
import CustomCheckBox from "../../../../../components/customCheckBox/CustomCheckBox";
import InputDateRangePicker from "../../../../../components/datePicker/InputDateRangePicker";
import DownloadFunction from "../../../../../components/downloadFunct/DownloadFucntion";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import { API_URL } from "../../../../../constant/apiConstant";
import {
  selectDropDownPoStatus,
  selectDropDownProccessApprovePo
} from "../../../../../constant/selectOptConstant";
import {
  selectFilterProcessTheme,
  selectOptFilterPrincipal,
  selectOptFilterUserTheme,
  selectOptFilterWarehouse,
} from "../../../../../constant/themeConstant";
import {
  RenderColorStatusImportSo,
  RenderIconStatusImportSo,
  RenderStatusImportSo,
} from "../../../../../function/RenderStatus";
import { pageActions } from "../../../../../reducers/pageReducer";
import {
  approvePO,
  getInitiateListProccessApprovePo,
  getListCustomerDDS,
  getListDistributor,
  getListProcessApprovePo,
  getListStore,
  getListWarehouse,
  previewPo,
  printApprovePO,
  proccessApprovePoAction,
} from "../../../../../services/main/proccessApprovePoReducer";
import StringUtil from "../../../../../utils/stringUtil";

const ListProcessApprovePo = () => {
  const { t } = useTranslation();
  //   const { list_PROCCESS_APPROVE_PO_po: data } = useSelector((state) => state.PROCCESS_APPROVE_POPO);
  // const [data, setData] = useState([]);
  const data = useSelector((state) => state.proccessApprovePo.list);
  const dataSelectedRowKeys = useSelector(
    (state) => state.proccessApprovePo.selectedRowKeys
  );
  const table_loading = useSelector(
    (state) => state.proccessApprovePo.table_loading
  );
  const download_loading = useSelector(
    (state) => state.proccessApprovePo.download_loading
  );
  const { filters: objectFilter } = useSelector((state) => state.page);
  const princpId = useSelector((state) => state.auth?.principal_id);

  const [total, setTotal] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  const [countSearch, setcountSearch] = useState(objectFilter?.countSearch);
  const [popOverOpen, setPopOverOpen] = useState(false);

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(objectFilter?.idCustomer);
  const prevIdCustomerValue = useRef(idCustomer?.value);

  // distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState([]);
  const [idDistributor, setidDistributor] = useState(
    objectFilter?.idDistributor
  );

  // store
  const [storeId, setStoreId] = useState(objectFilter?.idStore);
  const [data_Store, setData_Store] = useState([]);
  const [inputStore, setInputStore] = useState("");
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);

  // Definisikan variabel refs untuk melacak perubahan inputStore
  const prevInputStore = useRef(inputStore);
  const isSearchAction = useRef(false);
  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [inputWarehouse, setInputWarehouse] = useState("");
  const [warehouseId, setWarehouseId] = useState(objectFilter?.idWarehouse);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  // const [limitWarehouse, setLimitWarehouse] = useState(10);
  const [limitWarehouse] = useState(50);

  const [transactionDate, setTransactionDate] = useState(
    objectFilter?.transactionDate ?? []
  );
  const [filterTransactionDate, setfilterTransactionDate] = useState(
    objectFilter?.filterTransactionDate
  );

  const [deliveryDate, setDeliveryDate] = useState(
    objectFilter?.deliveryDate ?? []
  );
  const [filterDeliveryDate, setfilterDeliveryDate] = useState(
    objectFilter?.filterDeliveryDate
  );

  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [form, setForm] = useState("");
  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  // filter
  const [status, setStatus] = useState(objectFilter?.status);

  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useMemo(() => {
    const [start, end] = transactionDate;
    if (start && end) {
      setfilterTransactionDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterTransactionDate([]);
  }, [transactionDate]);
  useMemo(() => {
    const [start, end] = deliveryDate;
    if (start && end) {
      setfilterDeliveryDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterDeliveryDate([]);
  }, [deliveryDate]);

  // const [objectFilter, setObjectFilter] = useState({
  //   idDistributor: null,
  //   idStore: null,
  //   idWarehouse: null,
  //   countSearch: "",
  //   status: "",
  //   filterDeliveryDate: "",
  //   filterTransactionDate: "",
  // });
  const filter = StringUtil.formatFilter({
    delivery_date: `range ${objectFilter?.filterDeliveryDate}`,
    transaction_date: `range ${objectFilter?.filterTransactionDate}`,
    distributor_id: `eq ${objectFilter?.idDistributor?.value}`,
    customer_id: `eq ${objectFilter?.idCustomer?.value}`,
    store_id: `eq ${objectFilter?.idStore?.value}`,
    // warehouse_id: `eq ${objectFilter?.idWarehouse?.value}`,
    warehouse_id: `list_id ${objectFilter?.idWarehouse?.join("_")}`,
    status: `eq ${objectFilter?.status}`,
    [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
  });
  const params = {
    page,
    limit,
    filter,
    order,
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const fetchData = () => {
    dispatch(getListProcessApprovePo(params));
  };

  useEffect(() => {
    dispatch(getInitiateListProccessApprovePo(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    dispatch(proccessApprovePoAction.resetCBX());
    setIsSubmit(false);
    setIsDisabled(true);
  }, [page, limit, token, isSubmit, order, dispatch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
    // setIsDisabled(true);
    // setFilterDataStore("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  const submitOk = () => {
    // setFilterDataStore(form);
    setPage(1);
    setIsSubmit(true);
    dispatch(
      pageActions.setFilters({
        idDistributor: idDistributor,
        idStore: storeId,
        idCustomer: idCustomer,
        idWarehouse: warehouseId?.map((val) => {
          console.log(warehouseId)
          return val?.value;
        }),
        countSearch: countSearch,
        status: status,
        deliveryDate: deliveryDate,
        transactionDate: transactionDate,
        filterDeliveryDate: filterDeliveryDate,
        filterTransactionDate: filterTransactionDate,
        selectDropDownFilter: selectDropDownFilter,
      })
    );
  };
  
  const resetFilter = () => {
    setForm("");
    setWarehouseId([]);
    setidDistributor(null);
    setFilterDistributor("");
    setStoreId(null);
    setDeliveryDate([]);
    setTransactionDate([]);
    setStatus(null);
    setSelectDropDownFilter(null);
    setIsDisabled(false);
    setButtonSearchOrClose("search");
    setcountSearch("");
    setPage(1);
    setPageDistributor(1);
    setPageStore(1);
    setPageCustomer(1);
    setPageWarehouse(1);
    setLimit(10);
    setLimitDistributor(10);
    setLimitStore(10);
    // setLimitWarehouse(10);
    setIsSubmit(true);
    setIdCustomer(null);
    setfilterCustomerName("");
    setInputWarehouse("");
    setInputStore("");
    dispatch(pageActions.resetFilters());
  };
  useMemo(
    () => setIsDisabled(false),
    [warehouseId, idDistributor, storeId, status, transactionDate, deliveryDate]
  );

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeSelect = (name, value, option) => {
    if (name === "distributor_id") {
      setidDistributor(option);
    } else if (name === "customer_id") {
      setIdCustomer(value);
      setStoreId(null);
      setPageStore(1);
      setInputStore("");
    } else if (name === "warehouse_id") {
      setWarehouseId(value);
    } else if (name === "store_id") {
      setStoreId(value);
    } else setSelectDropDownFilter(value);
  };
  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  const [listID, setListID] = useState([]);
  const handleOnChangeChecked = (event, { id, updated_at }) => {
    dispatch(
      proccessApprovePoAction.setSingleCheck({ id: id, updated_at: updated_at })
    );
  };

  const handleChangeAllChecked = () => {
    dispatch(proccessApprovePoAction.setAllCheck(listID));
  };

  // const filter = `${selectDropDownFilter.value} like ${filterDataStore}`;
  // const params =
  //   filterDataStore === "" ? { page, limit } : { page, limit, filter };

  const handleChangeDate = (name, value) => {
    if (name === "transaction_date") {
      const [start, end] = value;
      if (start && end) {
        setTransactionDate([start, end]);
      } else setTransactionDate([]);
    }
    if (name === "delivery_date") {
      const [start, end] = value;
      if (start && end) {
        setDeliveryDate([start, end]);
      } else setDeliveryDate([]);
    }
  };
  const onPoNumberClick = (event, id) => {
    event.preventDefault();
    const body = {
      list_id: [id],
    };
    dispatch(previewPo(body)).then((response) => {
      if (response?.payload?.ok) {
        var file_content = response?.payload?.response?.file_content;
        var file_name = response?.payload?.response?.file_name;
        var dataType = file_name?.substring(file_name.lastIndexOf(".") + 1);

        // switch (dataType?.toLowerCase()) {
        //   case "csv":
        //     const csvData = atob(file_content);
        //     const parsedData = Papa.parse(csvData);
        //     const csvContent = parsedData.data
        //       .map((row) => row.join(","))
        //       .join("\n");
        //     const blobCsv = new Blob([csvContent], {
        //       type: "text/plain;charset=utf-8",
        //     });
        //     const urlCsv = URL.createObjectURL(blobCsv);
        //     window.open(urlCsv, "_blank"); // Membuka preview CSV dalam tab baru
        //     break;
        //   case "txt":
        //     const txtData = atob(file_content);
        //     const blobTxt = new Blob([txtData], {
        //       type: "text/plain;charset=utf-8",
        //     });
        //     const urlTxt = URL.createObjectURL(blobTxt);
        //     window.open(urlTxt, "_blank"); // Membuka preview TXT dalam tab baru
        //     break;
        //   case "pdf":
        //     const base64ToUint8Array = (base64) => {
        //       const raw = atob(base64);
        //       const uint8Array = new Uint8Array(raw.length);
        //       for (let i = 0; i < raw.length; i++) {
        //         uint8Array[i] = raw.charCodeAt(i);
        //       }
        //       return uint8Array;
        //     };

        //     // Parse the Base64 data
        //     const uint8Array = base64ToUint8Array(file_content);

        //     // Create a Blob from Uint8Array
        //     const blob = new Blob([uint8Array], { type: "application/pdf" });

        //     // Create an object URL for the Blob
        //     const url = URL.createObjectURL(blob);

        //     // Open the URL in a new window
        //     window.open(url, "_blank");
        //     break;
        //   default:
        //     return;
        // }
        const url = `${API_URL}/${file_name}`;
        window.open(url, "_blank");
      } else {
        message.error(
          response?.payload?.response?.data?.nexsoft?.payload?.status?.message
        );
      }
    });
  };

  const dataSource =
    data?.length > 0 &&
    data?.map((item) => ({
      key: item.id,
      po_number: (
        <>
          <Link
            onClick={(event) => onPoNumberClick(event, item?.id)}
            color="blue.500" // Ganti warna sesuai keinginan
            _hover={{ textDecoration: "underline" }} // Efek hover ketika kursor berada di atas tautan
            _focus={{ outline: "none" }} // Hapus garis fokus yang muncul ketika tautan di-tab
          >
            {item.po_number}
          </Link>
        </>
      ),
      so_number: item.so_number,
      do_number: item.do_number,
      store: item.store_name,
      po_date: moment(item.po_date).format("DD/MM/YYYY"),
      delivery_date: moment(item.delivery_date).format("DD/MM/YYYY"),
      expired_date: moment(item.expired_date).format("DD/MM/YYYY"),
      warehouse_code: item.warehouse_code,
      amount_po: item.amount_po,
      amount_so: item.amount_so,
      status: item.so_approval_status,
      suggestion_whs: item.suggestion_warehouse,
      qty_dpd_logic: item.qty_not_dpd_logic,
      gap_amount: item.gap_amount,
      price_check: item.price_check,
      credit_limit_status: item.credit_limit_status,
      checkBox: (
        <CustomCheckBox
          isChecked={dataSelectedRowKeys?.find((data) => data.id === item.id)}
          onChange={(event) =>
            handleOnChangeChecked(event, {
              id: item.id,
              updated_at: item.updated_at,
            })
          }
        />
      ),
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PROCESS_APPROVE_PO.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));

  useEffect(() => {
    setListID(
      data?.map((item) => ({ id: item.id, updated_at: item.updated_at }))
    );
  }, [data]);
  const getCheckedAll = () => {
    // listID
    if (data?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of data) {
        isCheckedAll = false;
        for (const CheckedData of dataSelectedRowKeys) {
          if (ListIdData.id === CheckedData.id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) break;
      }
      return isCheckedAll;
    } else {
      return;
    }
  };
  const checkedAll = getCheckedAll();

  // fungsi customer
  const handleScrollCustomer = (event) => {
    setPageCustomer(pageCustomer + 1);
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${princpId}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      // Filter out duplicate entries before adding to dataCustomer
      const uniqueResp = resp.filter(
        (entry) => !dataCustomer.some((item) => item.id === entry.id)
      );

      setDataCustomer([...dataCustomer, ...uniqueResp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
      principal_id: `eq ${princpId}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      store_name: `like ${inputStore}`,
      customer_id: `eq ${idCustomer?.value}`,
    });
    const params = { page, limit, filter };
    dispatch(getListStore(params)).then((response) => {
      if (!response.payload.ok) return;
      // setPageStore(1);

      const resp = response.payload.response;
      if (!resp) return;

      if (isSearchAction.current) {
        setData_Store(resp);
        prevIdCustomerValue.current = idCustomer?.value;
        prevInputStore.current = inputStore;
      } else if (
        idCustomer?.value !== prevIdCustomerValue.current ||
        inputStore !== prevInputStore.current
      ) {
        setData_Store(resp);
        prevIdCustomerValue.current = idCustomer?.value;
        prevInputStore.current = inputStore;
      } else {
        setData_Store([...data_Store, ...resp]);
      }
    });
  };

  const handleScrollStore = () => {
    isSearchAction.current = false;
    setPageStore(pageStore + 1);
  };
  const handleSearchStore = (event) => {
    // Set isSearchAction to true to reset pageStore on search
    isSearchAction.current = true;
    setInputStore(event);
    setPageStore(1);
  };

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, idCustomer?.value]);

  useEffect(() => {
    if (isSearchAction.current) {
      fetchDataStore();
    }
  }, [inputStore]);

  // useEffect(() => {
  //   fetchDataStoreSearch();
  // }, [inputStore, idCustomer?.value]);

  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };

    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };
  const fetchDataDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  const handleScrollDistributor = () => {
    setPageDistributor(pageDistributor + 1);
  };

  const fetchDataWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const fetchDataWarehouseSearch = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params = { page, limit, filter };
    dispatch(getListWarehouse(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageWarehouse(1);
      setDataWarehouse(response.payload.response);
    });
  };
  const handleScrollWarehouse = () => {
    setPageWarehouse(pageWarehouse + 1);
  };

  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor]);

  useEffect(() => {
    fetchDataDistributorSearch();
  }, [filterDistributor]);

  // warehouse
  useEffect(() => {
    fetchDataWarehouse();
  }, [pageWarehouse]);

  useEffect(() => {
    fetchDataWarehouseSearch();
  }, [inputWarehouse]);

  const print = () => {
    const temp = dataSelectedRowKeys.map((data) => {
      return data.id;
    });
    const body = { list_id: temp };
    dispatch(printApprovePO(body)).then((resp) => {
      if (!resp.payload.ok) {
        message.error(
          resp.payload.response.data.nexsoft.payload.status.message
        );
      } else {
        dispatch(proccessApprovePoAction.setTableLoadingTrue());
        DownloadFunction(
          resp.payload.response.file_content,
          resp.payload.response.file_name
        );
        dispatch(proccessApprovePoAction.resetCBX());
        dispatch(proccessApprovePoAction.setTableLoadingFalse());
      }
    });
  };

  const approve = () => {
    const temp = dataSelectedRowKeys.map((data) => ({
      ...data,
      approve_status: "APPROVED",
    }));
    const body = { updated_id: temp };
    dispatch(approvePO(body)).then((resp) => {
      if (resp) {
        setIsSubmit(true);
        dispatch(proccessApprovePoAction.resetCBX());
      }
    });
  };

  const rejected = () => {
    const temp = dataSelectedRowKeys.map((data) => ({
      ...data,
      approve_status: "REJECTED",
    }));
    const body = { updated_id: temp };
    dispatch(approvePO(body)).then((resp) => {
      if (resp) {
        setIsSubmit(true);
        dispatch(proccessApprovePoAction.resetCBX());
      }
    });
  };
  const hasValue = Object.values(objectFilter).some((value) => {
    return value !== null && value !== undefined && value !== "";
  });

  const columns = [
    {
      title: (
        <CustomCheckBox
          isChecked={checkedAll}
          onChange={handleChangeAllChecked}
        />
      ),
      dataIndex: "checkBox",
      key: "checkBox",
      width: 100,
      fixed: "left",
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      fixed: "left",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_SO_NUMBER")}
        </Box>
      ),
      dataIndex: "so_number",
      key: "so_number",
      fixed: "left",
      sorter: (a, b) => a.so_number - b.so_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_DO_NUMBER")}
        </Box>
      ),
      dataIndex: "do_number",
      key: "do_number",
      sorter: (a, b) => a.do_number - b.do_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_STORE")}
        </Box>
      ),
      dataIndex: "store",
      key: "store",
      sorter: (a, b) => a.store.localeCompare(b.store),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_PO_DATE")}
        </Box>
      ),
      dataIndex: "po_date",
      key: "po_date",
      sorter: (a, b) => a.po_date - b.po_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_DELIVERED_DATE")}
        </Box>
      ),
      dataIndex: "delivery_date",
      key: "delivery_date",
      sorter: (a, b) => a.delivery_date - b.delivery_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_EXPIRED_DATE")}
        </Box>
      ),
      dataIndex: "expired_date",
      key: "expired_date",
      sorter: (a, b) => a.expired_date - b.expired_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE_CODE")}
        </Box>
      ),
      dataIndex: "warehouse_code",
      key: "warehouse_code",
      sorter: (a, b) => a.warehouse_code.localeCompare(b.warehouse_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_AMOUNT_PO")}
        </Box>
      ),
      dataIndex: "amount_po",
      key: "amount_po",
      sorter: (a, b) => a.amount_po - b.amount_po,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_AMOUNT_SO")}
        </Box>
      ),
      dataIndex: "amount_so",
      key: "amount_so",
      sorter: (a, b) => a.amount_so - b.amount_so,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("WAREHOUSE:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>{RenderIconStatusImportSo(status)}</Box>
              <Box color={RenderColorStatusImportSo(status)}>
                {RenderStatusImportSo(status)}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_SUGGESTION_WHS")}
        </Box>
      ),
      dataIndex: "suggestion_whs",
      key: "suggestion_whs",
      sorter: (a, b) => a.suggestion_whs.localeCompare(b.suggestion_whs),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_QTY_NOT_DPD_LOGIC")}
        </Box>
      ),
      dataIndex: "qty_dpd_logic",
      key: "qty_dpd_logic",
      sorter: (a, b) => a.qty_dpd_logic.localeCompare(b.qty_dpd_logic),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_GAP_AMOUNT(SO_PO)")}
        </Box>
      ),
      dataIndex: "gap_amount",
      key: "gap_amount",
      sorter: (a, b) => a.gap_amount - b.gap_amount,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_PRICE_CHECK")}
        </Box>
      ),
      dataIndex: "price_check",
      key: "price_check",
      sorter: (a, b) => a.price_check.localeCompare(b.price_check),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("PROCCESS_APPROVE_PO:FIELD_CREDIT_LIMT_STATUS")}
        </Box>
      ),
      dataIndex: "credit_limit_status",
      key: "credit_limit_status",
      sorter: (a, b) =>
        a.credit_limit_status.localeCompare(b.credit_limit_status),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
    },
  ];
  return (
    <Box p={4}>
      <HeadTitle
        title={t("PROCCESS_APPROVE_PO:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCCESS_APPROVE_PO:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCCESS_APPROVE_PO:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PROCCESS_APPROVE_PO:SUBTITLE_3")}
            </span>{" "}
            - {t("PROCCESS_APPROVE_PO:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonIcon
              onClick={print}
              icon={<BlueDownloadIcon />}
              marginStart={2}
              id="BTN-UserBuyer-Store-UploadFile"
              colorScheme="blue"
              variant="outline"
              disabled={dataSelectedRowKeys?.length < 1 || download_loading}
              loading={download_loading}
            />
            <ButtonCustom
              onClick={approve}
              text={t("PROCCESS_APPROVE_PO:BUTTON_APPROVE")}
              marginStart={2}
              id="BTN-UserBuyer-Store-UploadFile"
              colorScheme="customgreen"
              variant="outline"
              disabled={dataSelectedRowKeys?.length < 1}
            />
            <ButtonCustom
              onClick={rejected}
              text={t("PROCCESS_APPROVE_PO:BUTTON_REJECT")}
              marginStart={2}
              id="BTN-UserBuyer-Store-UploadFile"
              colorScheme="red"
              variant="outline"
              disabled={dataSelectedRowKeys?.length < 1}
            />
          </>
        }
      />

      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Box width="200px">
            <InputDateRangePicker
              width="200px"
              onChange={(val) => handleChangeDate("transaction_date", val)}
              placeHolder={t("COMMON:TRANSACTION_DATE")}
              name="transaction_date"
              values={transactionDate}
            />
          </Box>
          <Box width="200px">
            <InputDateRangePicker
              onChange={(val) => handleChangeDate("delivery_date", val)}
              placeHolder={t("COMMON:DELIVERY_DATE")}
              name="delivery_date"
              values={deliveryDate}
            />
          </Box>

          {/* By Distributor */}
          <Select
            placeholder={t("PROCCESS_APPROVE_PO:FIELD_DISTRIBUTOR")}
            style={selectOptFilterUserTheme}
            onPopupScroll={handleScrollDistributor}
            value={idDistributor}
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            size="large"
            fontSize="14px"
            textColor={GRAY_COLOR}
            // id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            backgroundColor="white"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            allowClear="true"
          >
            {dataDistributor?.map((value, index) => {
              return (
                <>
                  <option
                    key={index.toString()}
                    value={value.id}
                    label={value.name}
                  >
                    {value.name}
                  </option>
                </>
              );
            })}
          </Select>
          {/* By Customer */}
          <Select
            // id={ID_REPORT_POvsSO.LIST.CUSTOMER}
            style={selectOptFilterPrincipal}
            size="large"
            value={idCustomer}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("PROCCESS_APPROVE_PO:FIELD_CUSTOMER")}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            labelInValue={true}
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={data.id}
                    title={data.name}
                    label={data}
                  >
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>

          {/* By Store */}
          <Select
            placeholder={t("BKL_SUMMARY:FIELD_STORE")}
            style={selectOptFilterUserTheme}
            size="large"
            value={storeId}
            onPopupScroll={handleScrollStore}
            fontSize="14px"
            onChange={(value, opt) =>
              handleChangeSelect("store_id", value, opt)
            }
            backgroundColor="white"
            showSearch={false}
            textColor={GRAY_COLOR}
            dropdownRender={(menu) => (
              <DropDownCustom
                value={inputStore}
                menu={menu}
                search={(e) => handleSearchStore(e.target.value)}
              />
            )}
            labelInValue={true}
          >
            {data_Store?.map((value, index) => {
              return (
                <option key={index} value={value.id} label={value}>
                  {value.store_name}
                </option>
              );
            })}
          </Select>

          {/* By date */}

          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              // isDisabled={isDisabled}
              id="BTN-UserSeller-ProductPrincipal-Apply"
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Box marginLeft={"18.9rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* Po status */}
          <Select
            style={{ selectFilterProcessTheme }}
            onChange={(value, opt) => setStatus(value, opt)}
            size="large"
            name="po_status"
            placeholder="PO status"
            value={status}
            allowClear={true}
          >
            {selectDropDownPoStatus?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>

          <Select
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            value={selectDropDownFilter}
            size="large"
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            onClear={handleClear}
            allowClear={true}
            textColor={GRAY_COLOR}
            fontSize="14px"
            showSearch={false}
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            labelInValue={true}
          >
            {selectDropDownProccessApprovePo?.map((value, index) => {
              return (
                <>
                  <option
                    key={index.toString()}
                    value={value.value}
                    label={value.label}
                  >
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              style={{ border: "1px solid #d9d9d9" }}
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter?.label
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          {/* warehouse  */}
          <Box className="my-select-container">
            <Select
              style={selectOptFilterWarehouse}
              maxTagCount={2}
              name="warehouse_id"
              mode="multiple"
              onChange={(value, opt) =>
                handleChangeSelect("warehouse_id", value, opt)
              }
              size="large"
              placeholder={"Search " + t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE")}
              value={warehouseId}
              onPopupScroll={handleScrollWarehouse}
              fontSize="14px"
              backgroundColor="white"
              textColor={GRAY_COLOR}
              allowClear="true"
              // onSearch={(e)=>setSearchWarehouse(e.target.value)}
              onSearch={(e) => setInputWarehouse(e)}
              optionFilterProp="children"
              showArrow
              // dropdownRender={(menu) => (
              //   <DropDownCustom
              //     // value={inputWarehouse}
              //     value={searchWarehouse}
              //     menu={menu}
              //     // search={(e) => setInputWarehouse(e.target.value)}
              //     search={(e) => setSearchWarehouse(e.target.value)}
              //   />
              // )}
              labelInValue={true}
            >
              {dataWarehouse?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value.id} label={value}>
                    {value.warehouse_code}
                  </option>
                );
              })}
            </Select>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          rowKey="id"
          columns={columns}
          pagination={false}
          size="small"
          onChange={handleTableChange}
          loading={table_loading}
          scroll={{
            x: 3000,
            y: 400,
          }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  // showQuickJumper
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListProcessApprovePo;
