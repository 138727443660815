import * as yup from "yup";
import i18next from "i18next";
import { REGEX_CONSTANT } from "../../../../../constant/regexConstant";

export const addSchema = (t) => {
  return () =>
    yup.object().shape({
      principal_id: yup
        .string()
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      product_code: yup
        .string()
        .min(
          3,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: i18next.t(3),
          })
        )
        .max(
          35,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(35),
          })
        )
        .matches(
          REGEX_CONSTANT.PRODUCT_CODE,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_PRODUCT_CODE")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      // product_name: yup
      //   .string()
      //   .min(
      //     5,
      //     t("COMMON:ERROR_MIN_LENGTH", {
      //       length: i18next.t(5),
      //     })
      //   )
      //   .max(
      //     100,
      //     t("COMMON:ERROR_MAX_LENGTH", {
      //       length: i18next.t(100),
      //     })
      //   )
      //   .matches(
      //     REGEX_CONSTANT.PRODUCT_NAME,
      //     i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_PRODUCT_NAME")
      //   )
      //   .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      barcode: yup
        .string()
        .max(
          30,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(30),
          })
        )
        .matches(
          REGEX_CONSTANT.BARCODE,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_BARCODE")
        ),
      uom_1: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      uom_2: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_3: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_4: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      order_unit: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.ORDER_UNIT,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_ORDER_UNIT")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      product_description: yup.string().max(
        256,
        t("COMMON:ERROR_MAX_LENGTH", {
          length: i18next.t(256),
        })
      ),
      conversion_1_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      conversion_2_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      conversion_3_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      status: yup.string().required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      packaging: yup
        .string()
        .matches(
          REGEX_CONSTANT.PRODUCT_NAME,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_PRODUCT_NAME")
        ),
      price_uom: yup
        .string()
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
    });
};

export const editProductPrincipalSchema = (t) => {
  return () =>
    yup.object().shape({
      // principal_id: yup
      //   .string(),
      product_code: yup
        .string()
        .min(
          3,
          t("COMMON:ERROR_MIN_LENGTH", {
            length: i18next.t(3),
          })
        )
        .max(
          35,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(35),
          })
        )
        .matches(
          REGEX_CONSTANT.PRODUCT_CODE,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_PRODUCT_CODE")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      // product_name: yup
      //   .string()
      //   .min(
      //     5,
      //     t("COMMON:ERROR_MIN_LENGTH", {
      //       length: i18next.t(5),
      //     })
      //   )
      //   .max(
      //     100,
      //     t("COMMON:ERROR_MAX_LENGTH", {
      //       length: i18next.t(100),
      //     })
      //   )
      //   .matches(
      //     REGEX_CONSTANT.PRODUCT_NAME,
      //     i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_PRODUCT_NAME")
      //   )
      //   .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      barcode: yup
        .string()
        .max(
          30,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(30),
          })
        )
        .matches(
          REGEX_CONSTANT.BARCODE,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_BARCODE")
        ),
      uom_1: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      uom_2: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_3: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      uom_4: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: i18next.t(10),
          })
        )
        .matches(
          REGEX_CONSTANT.UOM,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_UOM")
        ),
      order_unit: yup
        .string()
        .max(
          10,
          t("COMMON:ERROR_MAX_LENGTH", {
            length: 10,
          })
        )
        .matches(
          REGEX_CONSTANT.ORDER_UNIT,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_ORDER_UNIT")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      product_description: yup.string().max(
        256,
        t("COMMON:ERROR_MAX_LENGTH", {
          length: 256,
        })
      ),
      conversion_1_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      conversion_2_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      conversion_3_to_4: yup
        .string()
        .max(4, i18next.t("PRODUCT_CUSTOMER:FIELD_ERROR_9999"))
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        )
        .required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      status: yup.string().required(i18next.t("PRODUCT_PRINCIPAL:FIELD_EMPTY")),
      packaging: yup
        .string()
        .matches(
          REGEX_CONSTANT.PRODUCT_NAME,
          i18next.t("PRODUCT_PRINCIPAL:FIELD_ERROR_PRODUCT_NAME")
        ),
      price_uom: yup
        .string()
        .matches(
          REGEX_CONSTANT.INTEGER,
          i18next.t("PRODUCT_CUSTOMER:FIELD_NUMBER")
        ),
    });
};
