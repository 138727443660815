import { BackIcon, DetailIcon } from "./icons";
import ButtonCustom from "./button/ButtonCustom";
import { Box, Stack, Text, Select as SelectChakra } from "@chakra-ui/react";
import { PRIMARY_COLOR } from "../constant/propertiesConstant";
import { useTranslation } from "react-i18next";

const BackToList = ({ link, name, date, modifiedBy, modifiedDate , id}) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Back To List , Created By ,dll  */}
      <Box
        marginTop={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <ButtonCustom
            text={t("COMMON:BUTTON_BACK_TO_LIST")}
            color={PRIMARY_COLOR}
            link={link}
            icon={<BackIcon />}
            id={id}
          />
        </Box>
        <Box>
          <Stack direction="row" spacing="4rem">
            <Box display="flex" alignItems="center">
              <Box marginRight={2}>
                {t("COMMON:CREATED_BY")} <b>{name ? name : "Admin"}</b>{" "}
                {t("COMMON:ON")} <b>{date}</b> | {t("COMMON:MODIFIED_BY")}{" "}
                <b>{modifiedBy ? modifiedBy : "Admin"}</b> {t("COMMON:ON")}{" "}
                <b>{modifiedDate}</b>
              </Box>
              <Box>
                <DetailIcon />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default BackToList;
