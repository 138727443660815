import { Box, Select } from "@chakra-ui/react";
import { Pagination, Table } from "antd";
import { useTranslation } from "react-i18next";
import { selectPaginationOption } from "../../constant/selectOptConstant";
const TableCustom = ({
  dataSource,
  columns,
  onChangeTable,
  loading,
  scroll,
  onChangeSelect,
  total,
  currentPage,
  onChangePagination,
  limit,
  idSelect,
  idPagination,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="uid"
        pagination={false}
        onChange={onChangeTable}
        size="small"
        loading={loading}
        scroll={scroll ? scroll : { y: 400 }}
        footer={() => (
          <Box
            backgroundColor="#E5EBF1"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Box display="flex" alignItems="center" marginX={2}>
              <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
              <Select
                border="1px solid"
                borderRadius="6px"
                borderColor="#CCCCCC"
                id={idSelect}
                size="sm"
                width="70px"
                _focus={{ outline: "none" }}
                value={limit}
                onChange={onChangeSelect}
                {...rest}
              >
                {selectPaginationOption?.map((value, index) => {
                  return (
                    <option key={index.toString()} value={value}>
                      {value}
                    </option>
                  );
                })}
              </Select>
            </Box>
            <Box>
              <Pagination
                id={idPagination}
                size="small"
                current={currentPage}
                total={total}
                onChange={onChangePagination}
                showSizeChanger={false}
                pageSize={limit}
                defaultCurrent={1}
                {...rest}
              />
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default TableCustom;
