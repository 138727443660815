import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputRightElement
} from "@chakra-ui/react";
import { Select } from "antd";
import { useFormik } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import { DateIcon } from "../../../../components/icons";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import {
  GRAY_DISABLED
} from "../../../../constant/propertiesConstant";
import { selectStatus } from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import { editActivation } from "../../../../pages/main/User/userSchema";
import { userAction } from "../../../../services/main/userReducer";
const BodyActivation = () => {
  const { t } = useTranslation();
  const { activation } = useSelector((state) => state.user);
  const { index_page } = useSelector((state) => state.user);
  const { detail_user } = useSelector((state) => state.user);
  const detailUserActivation = detail_user.activation;
  const defaultValuePeriod = "0001-01-01T00:00:00Z";
  var today = new Date();
  const todayDate = moment(today).format("DD/MM/YYYY");
  const endOfPeriod = moment(detailUserActivation?.end_active_period).format(
    "DD/MM/YYYY"
  );
  const dispatch = useDispatch();
  const onSubmit = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_AUTHENTICATION")));
    // set index page
    dispatch(userAction.setIndexPage(index_page + 1));
    dispatch(
      userAction.setActivation({
        status: values?.status,
      })
    );
    dispatch(
      userAction.setDetailUser({
        ...detail_user,
        activation: {
          status: values?.status,
        },
      })
    );
  };
  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACCESS_SETTING")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
    dispatch(
      userAction.setActivation({
        status: values?.status,
      })
    );
    dispatch(
      userAction.setDetailUser({
        ...detail_user,
        activation: {
          status: values?.status,
        },
      })
    );
  };

  const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      status: detailUserActivation?.status,
    },
    validationSchema: editActivation(t),
    onSubmit,
    isInitialValid: true,
    enableReinitialize: true,
  });
  const handleChangeSelect = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          borderRadius="10px"
        >
          {/* Status  */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}>
              {t("USER:FIELD_STATUS")}
            </Box>
            <Box>
              <Select
                value={values?.status}
                style={inputTheme}
                name="status"
                size="large"
                onChange={(value, opt) =>
                  handleChangeSelect("status", value, opt)
                }
              >
                {selectStatus.map((value) => {
                  return <option value={value.value}>{t(value.label)}</option>;
                })}
              </Select>
              {errors.status && touched.status && (
                <ValidationError text={errors.status} />
              )}
            </Box>
          </Box>
          {/* Active Period  */}
          <Box padding={1} display="flex" marginBottom={4}>
            <Box width="10rem" marginTop={2}>
              {t("USER:FIELD_ACTIVE_PERIOD")}
            </Box>
            <Box>
              <InputGroup width="250px">
                <Input
                  type="text"
                  name="nik"
                  value={
                    detailUserActivation?.start_active_period ===
                    defaultValuePeriod
                      ? "-"
                      : moment(
                          detailUserActivation?.start_active_period
                        ).format("DD/MM/YYYY")
                  }
                  disabled
                  backgroundColor={GRAY_DISABLED}
                />
                <InputRightElement width="3rem">
                  <Icon as={DateIcon} />
                </InputRightElement>
              </InputGroup>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            type="button"
            onClick={toPreviousPage}
            text={t("USER:BUTTON_PREVIOUS")}
          />
          <ButtonPrimary
            text={t("USER:BUTTON_NEXT")}
            width="9.375rem"
            onClick={onSubmit}
          />
        </Box>
      </form>
    </>
  );
};

export default BodyActivation;
