import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";
import { HasDash } from "../../function/HasDash";
import useCheckUserCategory from "../../hooks/useCheckUserCategory";
import { userCategoryType } from "../../constant/propertiesConstant";
const INITIAL_STATE = {
  list: [],
  user_check: {
    nik: "",
    email: "",
  },
  user_profile: {
    nik: "",
    person_title_id: null,
    first_name: "",
    last_name: "",
    sex: "",
    email: "",
    phone_code: "",
    phone: "",
    address: "",
    locale: "",
    country_id: null,
    island_id: null,
    province_id: null,
    district_id: null,
    sub_district_id: null,
    urban_village_id: null,
    postal_code_id: null,
    hamlet: "",
    neighbourhood: "",
    photo: null,
  },
  login_account: {
    username: "",
    password: "",
  },
  access_setting: {
    user_category: "principal_user",
    role_id: "",
    group_id: "",
    company_profile_access: null,
  },
  activation: {
    status: "",
  },
  status: "",
  username_auth: "",
  table_loading: false,
  detail_user: {},
  detail_user_loading: false,
  curr_page: "",
  curr_role: "",
  curr_group: "",
  index_page: 1,
  updated_at: "",
  photo: null,
  is_checked: false,
  check_user_loading: false,
  is_change_profile: false,
};
export const getListUser = createAsyncThunk(
  "getListUser",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getInitiateListUser = createAsyncThunk(
  "getInitiateListUser",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account-list-initiate`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addUsers = createAsyncThunk(
  "addUsers",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account`;
    const { auth } = getState();
    const token = auth.token;
    const { user } = getState();
    const userCategory = auth.user_category;
    const body = {
      user_profile: user?.user_profile,
      login_account: user?.login_account,
      access_setting: {
        user_category: "principal_user",
        role_id: user?.access_setting?.role_id?.value,
        group_id: user?.access_setting?.group_id?.value,
        // company_profile_access: "all",
        company_profile_access: (auth?.company_profile_access).toString(),
      },
      activation: user?.activation,
    };
    const formData = new FormData();
    formData.append("content", JSON.stringify(body));
    if (user.photo) {
      formData.append("photo1", user.photo);
    }
    try {
      const response = await baseApi.doPost(url, token, formData);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editUsers = createAsyncThunk(
  "editUsers",
  async (userId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account/${userId}`;
    const { auth } = getState();
    const token = auth.token;
    const { user } = getState();
    const userCategory = auth.user_category;
    const detail_user = user.detail_user;
    const { have_default_photo, images, photo, ...rest } = user?.user_profile;

    const body = {
      user_profile: {
        ...rest,
        deleted_photo_id: [0],
        phone: HasDash(user?.user_profile?.phone)
          ? user?.user_profile?.phone.split("-")[1] /// kalau phone punya dash maka split dan ambil index ke 1
          : user?.user_profile?.phone, // jika tidak maka langsung pakai
      },
      login_account: user?.login_account,
      access_setting: {
        user_category: user?.access_setting?.user_category,
        role_id: user?.access_setting?.role_id?.value,
        group_id: user?.access_setting?.group_id?.value,
        company_profile_access: 
        // detail_user?.access_setting?.user_category === userCategoryType.ADMIN ?
        // userCategoryType.PRINCIPAL
        // :
        // userCategoryType.
          (detail_user?.access_setting.company_profile_id).toString(),
      },
      activation: user?.activation,
      updated_at: user?.updated_at,
      is_change_profile: false,
    };
     const formData = new FormData();

    if (have_default_photo && (!images || Array.isArray(images))) {
      const { id } = photo;
      body.deleted_photo_id = [id];
      if (images) {
        formData.append("photo1", images[0]);
      }
    } else {
      if (images) {
        formData.append("photo1", images[0]);
      }
      body.deleted_photo_id = [0];
    }
     formData.append("content", JSON.stringify(body));
    try {
      const response = await baseApi.doPut(url, token, formData);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editChangeProfile = createAsyncThunk(
  "editChangeProfile",
  async (userId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/change-profile/${userId}`;
    const { auth } = getState();
    const userCategory = auth.user_category;
    const token = auth.token;
    const { user } = getState();
    const detail_user = user.detail_user;
    const { have_default_photo, images, photo, ...rest } = user?.user_profile;

    const checkCompanyProfileAccess = (id) => {
      if (id === 0) return "all";
      return id.toString();
    };
    const body = {
      user_profile: {
        ...rest,
        // deleted_photo_id: [0],
        phone: HasDash(user?.user_profile?.phone)
          ? user?.user_profile?.phone.split("-")[1] /// kalau phone punya dash maka split dan ambil index ke 1
          : user?.user_profile?.phone, // jika tidak maka langsung pakai
      },
      login_account: user?.login_account,
      access_setting: {
        user_category: userCategory,
        role_id: user?.access_setting?.role_id?.value,
        group_id: user?.access_setting?.group_id?.value,
        company_profile_access:
          userCategory === userCategoryType.CUSTOM ||
          userCategory === userCategoryType.SUPPORT
            ? "all"
            : (detail_user?.access_setting.company_profile_id).toString(),
      },
      activation: user?.activation,
      updated_at: user?.updated_at,
      is_change_profile: true,
    };
     const formData = new FormData();

    if (have_default_photo && (!images || Array.isArray(images))) {
      const { id } = photo;
      // body.deleted_photo_id = [id];
      body.deleted_photo_id = [id];
      if (images) {
        formData.append("photo1", images[0]);
      }
    } else {
      if (images) {
        formData.append("photo1", images[0]);
      }
      body.deleted_photo_id = [0];
    }
     formData.append("content", JSON.stringify(body));
    try {
      const response = await baseApi.doPut(url, token, formData);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailUsers = createAsyncThunk(
  "detailUsers",
  async (userId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account/${userId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
export const deleteUser = createAsyncThunk(
  "deleteUser",
  async (object, { getState, rejectWithValue }) => {
    object = {
      updated_at: object.updated_at,
      userId: object.userId,
    };
    let result = { ok: false, response: null };

    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account/${object.userId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
export const checkUserAcc = createAsyncThunk(
  "checkUserAcc",
  async (body, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/user-account-check`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const user = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    setReset(state, action) {
      state.status = null;
      state.errorNotification = null;
      state.successNotification = null;
    },
    setUserCheck(state, action) {
       state.user_check.nik = action.payload.person_profile.nik;
      state.user_check.email = action.payload.email;
    },
    setUserProfile(state, action) {
      state.user_profile = action.payload;
    },
    setPhoto(state, action) {
      state.photo = action.payload;
    },
    // setLoginAccount(state, action) {
    //   state.login_account.username = action.payload.username;
    //   state.login_account.password = action.payload.password;
    // },
    setLoginAccount(state, action) {
      state.login_account = action.payload;
    },
    setAccessSetting(state, action) {
      state.access_setting = action.payload;
    },
    setActivation(state, action) {
      state.activation.status = action.payload.status;
    },
    setCurrPage(state, action) {
      state.curr_page = action.payload;
    },
    setIndexPage(state, action) {
      state.index_page = action.payload;
    },
    setDetailUser(state, action) {
      state.detail_user = action.payload;
    },
    setUpdatedAt(state, action) {
      state.updated_at = action.payload;
    },
    setCurrRole(state, action) {
      state.curr_role = action.payload;
    },
    setCurrGroup(state, action) {
      state.curr_group = action.payload;
    },
    setUsernameAuth(state, action) {
      state.username_auth = action.payload;
    },
    setIsChecked(state, action) {
      state.is_checked = action.payload;
    },
    setIsChangeProfile(state, action) {
      state.is_change_profile = action.payload;
    },
    resetReduxUser() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListUser.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListUser.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading = false;
    },
    [getListUser.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
      state.table_loading = false;
    },
    [getInitiateListUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListUser.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListUser.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [detailUsers.pending]: (state, action) => {
      state.status = "loading";
      state.detail_user_loading = true;
    },
    [detailUsers.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_user = action.payload.response.nexsoft.payload.data.content;
      state.detail_user_loading = false;
    },
    [detailUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.detail_user_loading = false;
    },

    [checkUserAcc.pending]: (state, action) => {
      state.check_user_loading = true;
    },
    [checkUserAcc.fulfilled]: (state, action) => {
      state.check_user_loading = false;
    },
    [checkUserAcc.rejected]: (state, action) => {
      state.check_user_loading = false;
    },

    ["auth/resetRedux"]: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const userAction = user.actions;

export default user.reducer;
