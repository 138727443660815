import {
  StatusActive,
  StatusNonActive,
  StatusUploaded,
} from "../components/icons";
import {
  GREEN_COLOR,
  LABEL_STATUS_ACTIVE,
  LABEL_STATUS_APPROVED,
  LABEL_STATUS_INACTIVE,
  LABEL_STATUS_REJECTED,
  LABEL_STATUS_UPLOADED,
  RED_COLOR,
  STATUS_ACTIVE,
  STATUS_APPROVED,
  STATUS_INACTIVE,
  STATUS_REJECTED,
  STATUS_UPLOADED,
  WHITE_COLOR,
  YELLOW_COLOR,
} from "../constant/propertiesConstant";

export function RenderStatus(status) {
  if (status === STATUS_ACTIVE) return LABEL_STATUS_ACTIVE;
  if (status === "") return LABEL_STATUS_ACTIVE;
  if (status === STATUS_INACTIVE) return LABEL_STATUS_INACTIVE;
  return "";
}

export function RenderStatusImportSo(status) {
  if (status === STATUS_APPROVED) return LABEL_STATUS_APPROVED;
  if (status === STATUS_UPLOADED) return LABEL_STATUS_UPLOADED;
  if (status === STATUS_REJECTED) return LABEL_STATUS_REJECTED;
  return "";
}

export function RenderIconStatus(status) {
  if (status === STATUS_ACTIVE)
    return (
      <>
        <StatusActive />
      </>
    );
  if (status === STATUS_INACTIVE)
    return (
      <>
        <StatusNonActive />
      </>
    );
  return <StatusActive />;
}

export function RenderIconStatusImportSo(status) {
  if (status === STATUS_APPROVED)
    return (
      <>
        <StatusActive />
      </>
    );
  if (status === STATUS_REJECTED)
    return (
      <>
        <StatusNonActive />
      </>
    );
  if (status === STATUS_UPLOADED)
    return (
      <>
        <StatusUploaded />
      </>
    );
  return <></>;
}

export function RenderColorStatus(status) {
  if (status === STATUS_ACTIVE) return GREEN_COLOR;
  if (status === STATUS_INACTIVE) return RED_COLOR;
  return GREEN_COLOR;
}

export function RenderColorStatusImportSo(status) {
  if (status === STATUS_APPROVED) return GREEN_COLOR;
  if (status === STATUS_REJECTED) return RED_COLOR;
  if (status === STATUS_UPLOADED) return YELLOW_COLOR;
  return WHITE_COLOR;
}
