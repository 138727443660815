import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";
import EditProductCategoryMapping from "../../pages/main/Master/Mapping/productCategoryMapping/EditProductCategoryMapping";

const INITIAL_STATE = {
  list: [],
  list_product_category: [],
  list_distributor: [],
  list_customer: [],
  list_principal: [],
  list_store: [],
  list_product_principal: [],
  list_product_customer: [],
  list_store_supplier_connection: [],
  table_loading: false,
  store_loading: false,
  status: "",
};

// product category mapping
export const getInitiateListProductCategoryMapping = createAsyncThunk(
  "initiateListProductCategoryMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category-mapping/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCategoryMapping = createAsyncThunk(
  "getListProductCategoryMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailProductCategoryMapping = createAsyncThunk(
  "detailProductCategoryMapping",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category-mapping/${productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addProductCategoryMapping = createAsyncThunk(
  "addProductCategoryMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category-mapping`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      customer_id: object?.customer_id,
      supplier_code: (object?.store_supplier_connection_id).toString(),
      store_id: object?.store_id,
      distributor_id: object?.distributor_id,
      product_principal_id: object?.product_principal_id,
      product_customer_id: object?.product_customer_id,
      product_category_id: object?.product_category_id,
      status: object?.status,
    };
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const editProductCategoryMapping = createAsyncThunk(
  "editProductCategoryMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      customer_id: object?.customer_id?.value,
      supplier_code: (object?.store_supplier_connection_id?.value).toString(),
      store_id: object?.store_id?.value,
      distributor_id: object?.distributor_id?.value,
      product_principal_id: object?.product_principal_id?.value,
      product_customer_id: object?.product_customer_id?.value,
      product_category_id: object?.product_category_id?.value,
      status: object?.status,
      updated_at: object.updatedAt,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category-mapping/${object.productId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const deleteProductCategoryMapping = createAsyncThunk(
  "deleteProductCategoryMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      productId: object.productId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category-mapping/${body.productId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProductCategory = createAsyncThunk(
  "getListProductCategory",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-category`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const urlold = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-connection-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStore = createAsyncThunk(
  "getListStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const urlold = `${API_URL}/${API_VERSION}/${API_PREFIX}/store`;
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-connection`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProductPrincipal = createAsyncThunk(
  "getListProductPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProductPrincipalDDS = createAsyncThunk(
  "getListProductPrincipalDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStoreSupplierConnection = createAsyncThunk(
  "getListStoreSupplierConnection",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-store-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListProductCustomer = createAsyncThunk(
  "getListProductCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCustomerDDS = createAsyncThunk(
  "getListProductCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const productCategoryMapping = createSlice({
  name: "productCategoryMapping",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxProductCategoryMapping() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // product category mapping
    [getInitiateListProductCategoryMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProductCategoryMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProductCategoryMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListProductCategoryMapping.pending]: (state, action) => {
      state.table_loading = true;
      state.status = "loading";
    },
    [getListProductCategoryMapping.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "succcess";
      state.list = action.payload.response;
    },
    [getListProductCategoryMapping.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [detailProductCategoryMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductCategoryMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailProductCategoryMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListProductCategory.pending]: (state, action) => {
      state.table_loading = true;
      state.status = "loading";
    },
    [getListProductCategory.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "succcess";
      state.list_product_category = action.payload.response;
    },
    [getListProductCategory.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [addProductCategoryMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [addProductCategoryMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addProductCategoryMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editProductCategoryMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [editProductCategoryMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editProductCategoryMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [deleteProductCategoryMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductCategoryMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductCategoryMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    // principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
    // customer
    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
    // store
    [getListStore.pending]: (state, action) => {
      state.status = "loading";
      state.store_loading = true;
    },
    [getListStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_store = action.payload.response;
      state.store_loading = false;
    },
    [getListStore.rejected]: (state, action) => {
      state.store_loading = false;
      state.status = "failed";
    },
    // product principal
    [getListProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_principal = action.payload.response;
    },
    [getListProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
    },
    // product customer
    [getListProductCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListProductCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    //store supplier connection
    [getListStoreSupplierConnection.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListStoreSupplierConnection.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_store_supplier_connection = action.payload.response;
      state.table_loading = false;
    },
    [getListStoreSupplierConnection.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
  },
});

export const productCategoryMappingAction = productCategoryMapping.actions;

export default productCategoryMapping.reducer;
