import {
  Box, InputGroup
} from "@chakra-ui/react";
import { Input } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { inputTheme } from "../../../../constant/themeConstant";
import "../../../../css/GlobalAntd.css";
import { editLoginAccountSchema } from "../../../../pages/main/User/userSchema";
import { userAction } from "../../../../services/main/userReducer";
const BodyLoginAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hide, setHide] = useState(true);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
  const { index_page } = useSelector((state) => state.user);
  const { detail_user } = useSelector((state) => state.user);
  const userProfile = detail_user?.user_profile;
  const loginAccount = detail_user?.login_account;
  const { user_profile } = useSelector((state) => state.user);
  
  const toPreviousPage = () => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_USER_PROFILE")));
    // set index page
    dispatch(userAction.setIndexPage(index_page - 1));
    dispatch(userAction.setLoginAccount(values));
    dispatch(userAction.setDetailUser({
      ...detail_user,
      login_account : values
    }))
  };
  const onSubmit = (values) => {
    // set page
    dispatch(userAction.setCurrPage(t("USER:MULTIPLE_STEP_ACCESS_SETTING")));
    // set index page
    dispatch(userAction.setIndexPage(index_page + 1));
    dispatch(userAction.setLoginAccount(values));
    dispatch(userAction.setDetailUser({
      ...detail_user,
      login_account : values
    }))
  };
  const {
    values,
    errors,
    touched,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      username: loginAccount?.username,
    },
    validationSchema: editLoginAccountSchema(t),
    onSubmit,
    isInitialValid: true,
  });
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        {/* Username  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("COMMON:FIELD_USERNAME")}
          </Box>
          <Box className="my-select-container">
            <Input
              type="text"
              width="250px"
              autoComplete="off"
              name="username"
              onChange={handleChange}
              disabled
              onBlur={handleBlur}
              style={inputTheme}
              value={values?.username}
            />
            {errors.username && touched.username && (
              <ValidationError text={errors.username} />
            )}
          </Box>
        </Box>
        {/* Password  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("COMMON:FIELD_PASSWORD")}
          </Box>
          <Box className="my-select-container">
            <InputGroup width="250px">
              <Input
                onBlur={handleBlur}
                type={hide ? "password" : "text"}
                autoComplete="off"
                name="password"
                onChange={handleChange}
                style={inputTheme}
                value="fakepassword"
                disabled
              />
            </InputGroup>
            {errors.password && touched.password && (
              <ValidationError text={errors.password} />
            )}
          </Box>
        </Box>
        {/*Confirm Password  */}
        <Box padding={1} display="flex" marginBottom={4}>
          <Box width="10rem" marginTop={2}>
            {t("COMMON:FIELD_CONFIRM_NEW_PASSWORD")}
          </Box>
          <Box className="my-select-container">
            <InputGroup width="250px">
              <Input
                disabled
                onBlur={handleBlur}
                type={hideConfirmPassword ? "password" : "text"}
                autoComplete="off"
                value="fakepassword"
                name="repassword"
                onChange={handleChange}
                style={inputTheme}
              />
            </InputGroup>
            {errors.repassword && touched.repassword && (
              <ValidationError text={errors.repassword} />
            )}
          </Box>
        </Box>
      </Box>
      {/* button */}
      <Box display="flex" marginTop={4} float="right" paddingBottom="64px">
        <ButtonCustom
          colorScheme="blue"
          variant="outline"
          marginRight={2}
          width="9.375rem"
          onClick={toPreviousPage}
          type="button"
          text={t("USER:BUTTON_PREVIOUS")}
        />
        <ButtonPrimary
          text={t("USER:BUTTON_NEXT")}
          width="9.375rem"
          type="submit"
          // isDisabled={!isValid}
        />
      </Box>
    </form>
  );
};

export default BodyLoginAccount;
