import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import {
  DetailIcon,
  DownloadIcon,
  SearchIcon,
} from "../../../../components/icons";
import {
  GRAY_COLOR,
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  userCategoryType,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../constant/routeConstant";
// import "./ProcessPOStyle.css";
import CustomCheckBox from "../../../../components/customCheckBox/CustomCheckBox";
import InputDateRangePicker from "../../../../components/datePicker/InputDateRangePicker";
import DownloadFunction from "../../../../components/downloadFunct/DownloadFucntion";
import DropDownCustom from "../../../../components/DropDownCustom";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { ID_MONITORING } from "../../../../constant/idConstant";
import { selectDropDownMonitoringListPO } from "../../../../constant/selectOptConstant";
import {
  selectFilterProcessTheme,
  selectOptFilterUserTheme,
  selectOptFilterWarehouse,
} from "../../../../constant/themeConstant";
import useCheckUserCategory from "../../../../hooks/useCheckUserCategory";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import { customerAction } from "../../../../services/main/customerReducer";
import {
  distributorAction,
  getListDistributorDDS,
} from "../../../../services/main/distributorReducer";
import {
  getInitListProcessPO,
  getListCustomerDDS,
  getListPrincipalDDS,
  getListProcessPO,
  getListWarehouseDDS,
  monitoringListProcessPOReducerAction,
  previewPo,
  printListProcessPO,
} from "../../../../services/main/monitoring/monitoringListProcessPOReducer";
import { warehouseAction } from "../../../../services/main/warehouseReducer";
import StringUtil from "../../../../utils/stringUtil";
import DateUtil, { FORMAT } from "../../../../utils/dateUtil";
import { subHours } from "date-fns";
import Papa from "papaparse";
import { pageActions } from "../../../../reducers/pageReducer";
import { API_URL } from "../../../../constant/apiConstant";
const MonitoringListProcessPO = () => {
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state.monitoringListProcessPO.list_process_po
  );
  const table_loading = useSelector(
    (state) => state.monitoringListProcessPO.table_loading
  );
  const { status: statusCustomer } = useSelector((state) => state.customer);
  const { status: statusWarehouse } = useSelector((state) => state.warehouse);
  const { status: statusDistributor } = useSelector(
    (state) => state.distributor
  );
  const { filters: objectFilter } = useSelector((state) => state.page);
  const { status: statusPrincipal } = useSelector((state) => state.principal);
  const { isAccepted } = useCheckUserCategory([userCategoryType.SUPPORT]);

  const [total, setTotal] = useState(0);
  const [form, setForm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  // filter transactiondate
  const [transactionDate, setTransactionDate] = useState(
    objectFilter?.transactionDate ?? []
  );
  const [filterTransactionDate, setfilterTransactionDate] = useState(
    objectFilter?.filterTransactionDate
  );

  // filter transactiondate
  const [deliveryDate, setDeliveryDate] = useState(
    objectFilter?.deliveryDate ?? []
  );
  const [filterDeliveryDate, setfilterDeliveryDate] = useState(
    objectFilter?.filterDeliveryDate
  );

  const [submit, setSubmit] = useState(false);
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [countSearch, setcountSearch] = useState(objectFilter?.form);
  const [popOverOpen, setPopOverOpen] = useState(false);

  // filter distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");
  const [idDistributor, setidDistributor] = useState(
    objectFilter?.idDistributor
  );

  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer] = useState(10);
  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [idCustomer, setIdCustomer] = useState(objectFilter?.idCustomer);

  // warehouse
  const [dataWarehouse, setDataWarehouse] = useState([]);
  const [pageWarehouse, setPageWarehouse] = useState(1);
  // const [limitWarehouse] = useState(10);
  const [limitWarehouse] = useState(50);
  const [filterWarehouseName, setfilterWarehouseName] = useState("");
  const [inputWarehouse, setInputWarehouse] = useState("");
  const [warehouseId, setWarehouseId] = useState(objectFilter?.idWarehouse);
  // const [idWarehouse, setIdWarehouse] = useState(objectFilter?.idWarehouse);

  // principal
  const [principalId, setPrincipalId] = useState(objectFilter?.schema ?? 1);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(10);

  // checkbox selected print
  const [listID, setListID] = useState([]);

  const dispatch = useDispatch();

  // variabel untuk menampung principal id berdasarkan jenis user yang login
  // get principal_id form auth
  const principal_id_auth = useSelector((state) => state.auth.principal_id);
  // principal id untuk detail atau edit
  const principal_id_selected = isAccepted ? principalId : principal_id_auth;

  const { token } = useSelector((state) => state.auth);
  useEffectOnce(() => {
    dispatch(customerAction.resetReduxUser);
    dispatch(distributorAction.resetReduxDistributor);
    dispatch(warehouseAction.resetReduxWarehouse);
    // if (isAccepted) {
    //   setPrincipalId(1);
    // }
  });
  useMemo(() => {
    const [start, end] = transactionDate;
    if (start && end) {
      setfilterTransactionDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterTransactionDate([]);
  }, [transactionDate]);
  useMemo(() => {
    const [start, end] = deliveryDate;
    if (start && end) {
      setfilterDeliveryDate(
        moment(start).format("YYYY-MM-DD") +
          "T00:00:00Z" +
          ">>" +
          moment(end).format("YYYY-MM-DD") +
          "T23:59:59Z"
      );
    } else setfilterDeliveryDate([]);
  }, [deliveryDate]);

  const filterData = StringUtil.formatFilter({
    delivery_date: `range ${objectFilter.filterDeliveryDate}`,
    transaction_date: `range ${objectFilter.filterTransactionDate}`,
    distributor_id: `eq ${objectFilter?.idDistributor?.value}`,
    customer_id: `eq ${objectFilter?.idCustomer?.value}`,
    // warehouse_id: `eq ${objectFilter?.idWarehouse?.value}`,
    warehouse_id: `list_id ${objectFilter?.idWarehouse?.join("_")}`,
    [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
  });
  const filterCS = StringUtil.formatFilter({
    delivery_date: `range ${objectFilter.filterDeliveryDate}`,
    transaction_date: `range ${objectFilter.filterTransactionDate}`,
    distributor_id: `eq ${objectFilter?.idDistributor?.value}`,
    customer_id: `eq ${objectFilter?.idCustomer?.value}`,
    // warehouse_id: `eq ${objectFilter?.idWarehouse?.value}`,
    warehouse_id: `list_id ${objectFilter?.idWarehouse?.join("_")}`,
    schema: `eq ${objectFilter.schema ?? 1}`,
    [selectDropDownFilter?.value]: `like ${objectFilter.form}`,
  });

  const filter = isAccepted ? filterCS : filterData;

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const params = { page, limit, filter, order };
  const fetchData = () => {
    dispatch(getListProcessPO(params)).then((response) => {
      if (!response.payload.ok) {
        message.error(
          response.payload.response.data.nexsoft.payload.status.message
        );
      }
    });
  };
  useEffect(() => {
    dispatch(getInitListProcessPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    setSubmit(false);
    setIsDisabled(true);
    dispatch(monitoringListProcessPOReducerAction.resetCBX());
  }, [page, limit, token, submit, dispatch]);

  useEffect(() => {
    dispatch(getInitListProcessPO(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    dispatch(monitoringListProcessPOReducerAction.resetIds());
  }, [order]);

  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    } else setForm("");
  }, [countSearch]);
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = StringUtil.formatFilter({
      name: `like ${filterPrincipalName}`,
    });
    const order = "id asc";
    const params = { page, limit, order, filter };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    if (statusPrincipal === "success") {
      setPagePrincipal(pagePrincipal + 1);
    }
  };
  // end fungsi princapl

  const renderFilterButton = () => {
    if (isAccepted) {
      return (
        <>
          <Select
            id={ID_MONITORING.LIST.PRINCIPAL}
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={false}
            placeholder="Select Principal"
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
        </>
      );
    }
  };

  // fungsi untuk DDS filter distributor
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    // const filter = `distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };

  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    if (statusDistributor === "success") {
      setPageDistributor(pageDistributor + 1);
    }
  };
  const handleSelectDistributor = (name, value, option) => {
    setidDistributor(value);
    setIsDisabled(false);
  };

  const handleDistributorClear = () => {
    setPageDistributor(1);
    setidDistributor("");
    setFilterDistributor("");
    setDataDistributor([]);
  };
  // end fungsi distributor

  // fungsi customer
  const handleScrollCustomer = () => {
    if (statusCustomer === "success") {
      setPageCustomer(pageCustomer + 1);
    }
  };

  const fetchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    // const params = { page, limit };
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = StringUtil.formatFilter({
      customer_name: `like ${filterCustomerName}`,
    });
    const params =
      filterCustomerName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageCustomer(1);
        setDataCustomer(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // end fungsi customer

  // fungsi warehouse
  const handleScrollWarehouse = (event) => {
    if (statusWarehouse === "success") {
      setPageWarehouse(pageWarehouse + 1);
    }
  };

  const fetchWarehouse = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    // const params = { page, limit };
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params =
      filterWarehouseName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListWarehouseDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataWarehouse([...dataWarehouse, ...resp]);
    });
  };
  const fetchWarehouseSearch = () => {
    const page = pageWarehouse;
    const limit = limitWarehouse;
    const filter = StringUtil.formatFilter({
      warehouse_code: `like ${inputWarehouse}`,
    });
    const params =
      filterWarehouseName === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListWarehouseDDS(params)).then((response) => {
      if (response.payload.ok) {
        setPageWarehouse(1);
        setDataWarehouse(response.payload.response);
      }
    });
  };

  useEffect(() => {
    fetchWarehouse();
  }, [pageWarehouse]);
  useEffect(() => {
    fetchWarehouseSearch();
  }, [filterWarehouseName]);
  // end fungsi warehouse

  // filter transaction date
  const handleChangeTransactionDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setTransactionDate([start, end]);
    } else setTransactionDate([]);
    setIsDisabled(false);
  };

  // filter delivery date
  const handleChangeDeliveryDate = (value) => {
    const [start, end] = value;
    if (start && end) {
      setDeliveryDate([start, end]);
    } else setDeliveryDate([]);
    setIsDisabled(false);
  };

  const submitOk = () => {
    setPage(1);
    setSubmit(true);
    // setObjectFilter({
    //   idCustomer: idCustomer,
    //   idWarehouse: idWarehouse,
    //   idDistributor: idDistributor,
    //   deliveryDate: filterDeliveryDate,
    //   transactionDate: filterTransactionDate,
    //   form: form,
    //   schema: principalId,
    // });
    dispatch(
      pageActions.setFilters({
        idCustomer: idCustomer,
        idWarehouse: warehouseId?.map((val) => {
          return val?.value;
        }),
        idDistributor: idDistributor,
        deliveryDate: deliveryDate,
        transactionDate: transactionDate,
        filterDeliveryDate: filterDeliveryDate,
        filterTransactionDate: filterTransactionDate,
        selectDropDownFilter: selectDropDownFilter,
        form: form,
        schema: principalId,
      })
    );
  };
  const resetFilter = () => {
    if (isAccepted) {
      setPrincipalId(1);
      setFilterPrincipalName("");
    }
    setForm("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSubmit(true);
    setIdCustomer(null);
    setfilterCustomerName("");
    // setIdWarehouse(null);
    setWarehouseId([]);
    setInputWarehouse("");
    setfilterWarehouseName("");
    setidDistributor(null);
    setFilterDistributor("");
    setFilterPrincipalName("");
    setTransactionDate([]);
    setDeliveryDate([]);
    setPage(1);
    setLimit(10);
    setcountSearch("");
    setPopOverOpen(false);
    setSelectDropDownFilter(null);
    dispatch(monitoringListProcessPOReducerAction.resetCBX());
    dispatch(pageActions.resetFilters());
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleClear = () => {
    setcountSearch("");
    setForm("");
    setSelectDropDownFilter("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setPopOverOpen(false);
  };
  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "customer_id") {
      setIdCustomer(value);
    } else if (name === "warehouse_id") {
      // setIdWarehouse(value);
      setWarehouseId(value);
    } else if (name === "distributor_id") {
      setidDistributor(option);
    } else if (name === "principal_id") {
      setPrincipalId(value);
    } else {
      setSelectDropDownFilter(value);
    }
  };

  const dataSelectedRowKeys = useSelector(
    (state) => state.monitoringListProcessPO.selectedRowKeys
  );

  // ngecek ada data yang di select apa tidak
  const hasSelected = dataSelectedRowKeys?.length > 0;

  // fungsi buat button print
  const print = (event) => {
    const body = {
      schema_id: principal_id_selected,
      list_id: dataSelectedRowKeys,
    };
    dispatch(printListProcessPO(body)).then((resp) => {
      if (!resp.payload.ok) {
        message.error(
          resp.payload.response.data.nexsoft.payload.status.message
        );
      } else {
        dispatch(monitoringListProcessPOReducerAction.setTableLoadingTrue());
        DownloadFunction(
          resp.payload.response.file_content,
          resp.payload.response.file_name
        );
        dispatch(monitoringListProcessPOReducerAction.resetCBX());
        dispatch(monitoringListProcessPOReducerAction.setTableLoadingFalse());
      }
    });
  };

  const handleOnChangeChecked = (event, id) => {
    dispatch(monitoringListProcessPOReducerAction.setSingleCheck(id));
  };

  const handleChangeAllChecked = () => {
    dispatch(monitoringListProcessPOReducerAction.setAllCheck(listID));
  };

  useEffect(() => {
    setListID(data?.map((item) => item.id));
  }, [data]);

  const getCheckedAll = () => {
    // listID
    if (listID?.length > 0) {
      let isCheckedAll = false;
      for (const ListIdData of listID) {
        isCheckedAll = false;
        for (const id of dataSelectedRowKeys) {
          if (ListIdData === id) {
            isCheckedAll = true;
            break;
          }
        }
        if (!isCheckedAll) break;
      }
      return isCheckedAll;
    }
  };

  const onPoNumberClick = (event, id) => {
    event.preventDefault();
    const body = {
      schema_id: principal_id_selected,
      list_id: [id],
    };
    dispatch(previewPo(body)).then((response) => {
      if (response?.payload?.ok) {
        var file_content = response?.payload?.response?.file_content;
        var file_name = response?.payload?.response?.file_name;
        var dataType = file_name?.substring(file_name.lastIndexOf(".") + 1);
        const url = `${API_URL}/${file_name}`;
        window.open(url, "_blank");
      } else {
        message.error(
          response?.payload?.response?.data?.nexsoft?.payload?.status?.message
        );
      }
    });
  };

  const dataSource =
    data?.length > 0 &&
    data?.map((item) => ({
      key: item.id,
      distributor_name: item.distributor_name,
      po_number: (
        <>
          <Link
            onClick={(event) => onPoNumberClick(event, item?.id)}
            color="blue.500" // Ganti warna sesuai keinginan
            _hover={{ textDecoration: "underline" }} // Efek hover ketika kursor berada di atas tautan
            _focus={{ outline: "none" }} // Hapus garis fokus yang muncul ketika tautan di-tab
          >
            {item.po_number}
          </Link>
        </>
      ),
      do_number: item.do_number,
      so_number: item.so_number,
      customer_name: item.customer_name,
      store_name: item.store_name,
      po_date: moment(item.po_date).format("DD-MM-YYYY"),
      delivery_date: moment(item.delivery_date).format("DD-MM-YYYY"),
      expired_date: moment(item.expired_date).format("DD-MM-YYYY"),
      warehouse_code: item.warehouse_code,
      store_address: item.store_address,
      download_name: item.download_name,
      download_time:
        item.download_time === "0001-01-01T00:00:00Z"
          ? "-"
          : DateUtil.formatDate(
              subHours(new Date(item.download_time), 7),
              FORMAT.DATE_TIME
            ),
      checkBox: (
        <CustomCheckBox
          isChecked={dataSelectedRowKeys?.includes(item.id)}
          onChange={(event) => handleOnChangeChecked(event, item.id)}
        />
      ),

      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES.LIST_PROCCESS_PO.DETAIL,
              state: [item.id, principal_id_selected],
            }}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));

  const checkedAll = getCheckedAll();

  const columns = [
    {
      title: (
        <CustomCheckBox
          isChecked={checkedAll}
          onChange={handleChangeAllChecked}
        />
      ),
      width: 40,
      dataIndex: "checkBox",
      key: "checkBox",
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_PO_NUMBER")}
        </Box>
      ),
      dataIndex: "po_number",
      key: "po_number",
      sorter: (a, b) => a.po_number - b.po_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("COMMON:DO_NUMBER")}
        </Box>
      ),
      dataIndex: "do_number",
      key: "do_number",
      sorter: (a, b) => a.do_number - b.do_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("COMMON:SO_NUMBER")}
        </Box>
      ),
      dataIndex: "so_number",
      key: "so_number",
      sorter: (a, b) => a.so_number - b.so_number,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_DISTRIBUTOR_NAME")}
        </Box>
      ),
      dataIndex: "distributor_name",
      key: "distributor_name",
      sorter: (a, b) => a.distributor_name - b.distributor_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name - b.customer_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_STORE")}
        </Box>
      ),
      dataIndex: "store_name",
      key: "store_name",
      sorter: (a, b) => a.store_name - b.store_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_PO_DATE")}
        </Box>
      ),
      dataIndex: "po_date",
      key: "po_date",
      sorter: (a, b) => a.po_date - b.po_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_DELIVERY_DATE")}
        </Box>
      ),
      dataIndex: "delivery_date",
      key: "delivery_date",
      sorter: (a, b) => a.delivery_date - b.delivery_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_EXPIRED_DATE")}
        </Box>
      ),
      dataIndex: "expired_date",
      key: "expired_date",
      sorter: (a, b) => a.expired_date - b.expired_date,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_WAREHOUSE_CODE")}
        </Box>
      ),
      dataIndex: "warehouse_code",
      key: "warehouse_code",
      sorter: (a, b) => a.warehouse_code - b.warehouse_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_STORE_ADDRESS")}
        </Box>
      ),
      dataIndex: "store_address",
      key: "store_address",
      sorter: (a, b) => a.store_address - b.store_address,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_DOWNLOADED_BY")}
        </Box>
      ),
      dataIndex: "download_name",
      key: "download_name",
      sorter: (a, b) => a.download_name - b.download_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("MONITORING_LIST_PO:FIELD_DOWNLOADED_TIME")}
        </Box>
      ),
      dataIndex: "download_time",
      key: "download_time",
      sorter: (a, b) => a.download_time - b.download_time,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: "70px",
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(monitoringListProcessPOReducerAction.resetIds());
    };
  }, []);

  return (
    <Box p={4}>
      <HeadTitle
        title={t("MONITORING_LIST_PO:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_LIST_PO:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_LIST_PO:SUBTITLE_2")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_LIST_PO:SUBTITLE_3")}
            </span>{" "}
            - {t("MONITORING_LIST_PO:TITLE_LIST")}
          </>
        }
        button={
          <ButtonCustom
            id={ID_MONITORING.LIST.DOWNLOAD_PDF}
            onClick={() => print()}
            disabled={!hasSelected}
            icon={<DownloadIcon />}
            width="156px"
            height="40px"
            text={t("COMMON:DOWNLOAD_PDF")}
            fontSize="14px"
            marginStart={2}
            colorScheme="white"
            // variant="outline"
            backgroundcolor={hasSelected === true ? PRIMARY_COLOR : "grey"}
          />
        }
      />

      <Box marginTop={6}>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* Transaction date */}
          <Box width="140px">
            <InputDateRangePicker
              elementId={ID_MONITORING.LIST.TRX_DATE}
              placeHolder={t("COMMON:TRANSACTION_DATE")}
              values={transactionDate}
              onChange={handleChangeTransactionDate}
            />
          </Box>
          {/* delivery date */}
          <Box width="140px">
            <InputDateRangePicker
              elementId={ID_MONITORING.LIST.DELIVERY_DATE}
              placeHolder={t("COMMON:DELIVERY_DATE")}
              values={deliveryDate}
              onChange={handleChangeDeliveryDate}
            />
          </Box>

          {/* By Distributor */}
          <Select
            id={ID_MONITORING.LIST.DISTRIBUTOR}
            style={{ width: 169 }}
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={handleDistributorClear}
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={data.id}
                    title={data.name}
                    label={data.name}
                  >
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* By Customer */}
          <Select
            id={ID_MONITORING.LIST.CUSTOMER}
            value={idCustomer}
            style={{ selectFilterProcessTheme, width: 169 }}
            onPopupScroll={handleScrollCustomer}
            placeholder={t("COMMON:SELECT_CUSTOMER")}
            size="large"
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("customer_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterCustomerName}
                menu={menu}
                search={(e) => setfilterCustomerName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear={true}
            labelInValue={true}
          >
            {dataCustomer &&
              dataCustomer.map((data, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={data.id}
                    title={data.name}
                    label={data}
                  >
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          {/* warehouse  */}
          <Box className="my-select-container">
            <Select
              style={selectOptFilterWarehouse}
              maxTagCount={2}
              name="warehouse_id"
              mode="multiple"
              onChange={(value, opt) =>
                handleChangeSelect("warehouse_id", value, opt)
              }
              size="large"
              placeholder={"Search " + t("PROCCESS_APPROVE_PO:FIELD_WAREHOUSE")}
              value={warehouseId}
              onPopupScroll={handleScrollWarehouse}
              fontSize="14px"
              backgroundColor="white"
              textColor={GRAY_COLOR}
              allowClear="true"
              // onSearch={(e)=>setSearchWarehouse(e.target.value)}
              onSearch={(e) => setInputWarehouse(e)}
              optionFilterProp="children"
              showArrow
              // dropdownRender={(menu) => (
              //   <DropDownCustom
              //     // value={inputWarehouse}
              //     value={searchWarehouse}
              //     menu={menu}
              //     // search={(e) => setInputWarehouse(e.target.value)}
              //     search={(e) => setSearchWarehouse(e.target.value)}
              //   />
              // )}
              labelInValue={true}
            >
              {dataWarehouse?.map((value, index) => {
                return (
                  <option key={index.toString()} value={value.id} label={value}>
                    {value.warehouse_code}
                  </option>
                );
              })}
            </Select>
          </Box>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              isDisabled={isDisabled}
              id={ID_MONITORING.LIST.BTN_SUBMIT}
            />
          </Box>
          <Box>
            <Text
              id={ID_MONITORING.LIST.BTN_RESET}
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        {/* baris kedua */}
        <Box marginLeft={isAccepted ? "29.8rem" : "18.9rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          {/* principal */}
          {renderFilterButton()}
          <Select
            id={ID_MONITORING.LIST.DDL}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            value={selectDropDownFilter}
            onClear={handleClear}
            allowClear={true}
            size="large"
            placeholder={t("COMMON:SELECT_FILTER_BY")}
            labelInValue={true}
          >
            {selectDropDownMonitoringListPO?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value} label={value.label}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              id={ID_MONITORING.LIST.DDL_PILIHAN}
              style={{ border: "1px solid #d9d9d9" }}
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter?.label
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
        </Stack>
        <Table
          onChange={handleTableChange}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          loading={table_loading}
          scroll={{
            x: 2000,
            y: 400,
          }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  id={ID_MONITORING.LIST.ROW_PER_PAGE}
                  border="1px solid"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default MonitoringListProcessPO;
