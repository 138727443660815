import {
  Badge, Box, Center, Divider, Stack, Text
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  badgeColor, BLACK_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR
} from "../../../../../constant/propertiesConstant";
import { distributorAction } from "../../../../../services/main/distributorReducer";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage
} from "../../../../../services/main/regionalReducer";
import "../Distributor.css";
import BodyCompanyProfile from "./body/BodyCompanyProfile";
import BodyContactPerson from "./body/BodyContactPerson";
import BodyPreferences from "./body/BodyPreferences";
const AddDistributor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [indexPage, setIndexPage] = useState(0);
  const { curr_page } = useSelector((state) => state.distributor);
  const { index_page } = useSelector((state) => state.distributor);
  // params
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [currentPage, setCurrentPage] = useState(
  //   t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
  // );
  const setCurrPage = () => {
    // current page
    dispatch(
      distributorAction.setCurrPage(
        t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")
      )
    );
    // index page
    dispatch(distributorAction.setIndexPage(0));
  };
  const renderMainBody = () => {
    if (curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")) {
      return <BodyCompanyProfile />;
    }
    if (curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")) {
      return <BodyPreferences />;
    }
    if (curr_page === t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")) {
      return <BodyContactPerson />;
    }
  };
  const fetchData = async () => {
    const params = { page, limit };
    await dispatch(getListCountry(params));
    await dispatch(getListIsland(params));
    await dispatch(getListProvince(params));
    await dispatch(getListDistrict(params));
    await dispatch(getListSubDistrict(params));
    await dispatch(getListUrbanVillage(params));
  };
  useEffect(() => {
    dispatch(distributorAction.resetReduxDistributor());
    setCurrPage();
    fetchData();
  }, []);
  return (
    // overflow nya di none supaya ga muncul scroll 
    <Box padding={4}>
      <HeadTitle
        title={t("DISTRIBUTOR:ADD_DISTRIBUTOR")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:FIELD_MASTER")}
            </span>{" "}
            -
            <span style={{ color: PRIMARY_COLOR }}>
              {t("DISTRIBUTOR:FIELD_DISTRIBUTOR")}
            </span>{" "}
            - {t("DISTRIBUTOR:ADD_DISTRIBUTOR")}
          </>
        }
      />

      <Box marginTop={6} minHeight="4.375rem" height="64px" marginBottom={3}>
        <Stack direction="row" alignItems="center" marginBottom={2}>
          <Box
            backgroundColor="white"
            width={570}
            height="64px"
            display="flex"
            borderRadius="8px"
            boxShadow="0px 2px 6px rgba(0, 0, 0, 0.31)"
          >
            <Stack direction="row" p={4}>
              {/* -------Company Profile -------  */}
              <Box display="flex" width="180px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  variant="solid"
                  colorScheme={
                    index_page >= 0
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>1</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 0 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("DISTRIBUTOR:MULTIPLE_STEP_COMPANY_PROFILE")}
                </Text>
              </Box>
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* ------- Preferences -------  */}
              <Box display="flex" width="160px" height="24px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  px="2"
                  variant="solid"
                  colorScheme={
                    index_page >= 1
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>2</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 1 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("DISTRIBUTOR:MULTIPLE_STEP_PREFERENCES")}
                </Text>
              </Box>
              <Divider orientation="vertical" borderColor="blackAlpha.500" />
              {/* ----- Contact Person ------  */}
              <Box display="flex" width="180px" height="24px" px={2}>
                <Badge
                  borderRadius="full"
                  width="24px"
                  height="24px"
                  px="2"
                  variant="solid"
                  colorScheme={
                    index_page >= 2
                      ? badgeColor.primaryColor
                      : badgeColor.primaryGray
                  }
                >
                  <Text fontWeight={700} fontSize={16} color={WHITE_COLOR}>
                    <Center>3</Center>
                  </Text>
                </Badge>
                <Text
                  fontWeight={700}
                  fontSize={16}
                  color={index_page >= 2 ? PRIMARY_COLOR : BLACK_COLOR}
                  marginStart={2}
                >
                  {t("DISTRIBUTOR:MULTIPLE_STEP_CONTACT_PERSON")}
                </Text>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {/* ------ render main body ------  */}
      {renderMainBody()}

    </Box>
  );
};

export default AddDistributor;
