import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  list_distributor: [],
  list_principal: [],
  list_store: [],
  list_customer: [],
  list_product_principal: [],
  list_product_customer: [],
  table_loading: false,
  status: "",
};
export const validateImportProductBklMapping = createAsyncThunk(
  "validateImportProductBklMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import`;
    const { auth } = getState();
    const token = auth.token;
    const formData = new FormData();
    formData.append("content", JSON.stringify(object.content));
    formData.append("file", object.file);
    try {
      const response = await baseApi.doPost(url, token, formData);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const importConfirmProductBklMapping = createAsyncThunk(
  "importConfirmProductBklMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/import/confirm`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getInitiateListProductBKLMapping = createAsyncThunk(
  "getInitiateListProductBKLMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-bkl-mapping/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductBklMapping = createAsyncThunk(
  "getListProductBklMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-bkl-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailproductBKLMapping = createAsyncThunk(
  "detailproductBKLMapping",
  async (bklMappingId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-bkl-mapping/${bklMappingId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const addProductBklMapping = createAsyncThunk(
  "addProductBklMapping",
  //todo : harus bikin add product bkl mapping jadi array tapi cuma ngirim 1 value
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-bkl-mapping`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const deleteProductBklMapping = createAsyncThunk(
  "deleteProductBKLMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      productBklId: object.productBklId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-bkl-mapping/${body.productBklId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editProductBKLMapping = createAsyncThunk(
  "editProductBKLMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      customer_id: object?.customer_id.value,
      distributor_id: object?.distributor_id.value,
      store_id : [object?.store_id.value],
      // store_id: object?.store_id.map((val) => {
      //   return val.value;
      // }),
      // product_principal_id: object?.product_principal_id.map((val) => {
      //   return val.value;
      // }),
      product_principal_id : [object?.product_principal_id.value],
      product_customer_id: object?.product_customer_id.value,
      type: object?.type,
      status: object?.status,
      updated_at: object?.updated_at,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-bkl-mapping/${object.productId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);


export const getListBklCSM = createAsyncThunk(
  "getListBklCSM",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping/bkl-filter-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListStore = createAsyncThunk(
  "getListStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductCustomer = createAsyncThunk(
  "getListProductCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListProductPrincipal = createAsyncThunk(
  "getListProductPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
const productBklMapping = createSlice({
  name: "productBklMapping",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxProductBklMapping() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // product bkl mapping
    [validateImportProductBklMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [validateImportProductBklMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [validateImportProductBklMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [importConfirmProductBklMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [importConfirmProductBklMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [importConfirmProductBklMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getInitiateListProductBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListProductBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListProductBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListProductBklMapping.pending]: (state, action) => {
      state.table_loading = true;
      state.status = "loading";
    },
    [getListProductBklMapping.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list = action.payload.response;
    },
    [getListProductBklMapping.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    [detailproductBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailproductBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailproductBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
    },

    [deleteProductBklMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductBklMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductBklMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editProductBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [editProductBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editProductBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [addProductBklMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [addProductBklMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addProductBklMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    // distributor
    [getListDistributor.pending]: (state, action) => {
      state.table_loading = true;
      state.status = "loading";
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list_distributor = action.payload.response;
    },
    [getListDistributor.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    // principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    // store
    [getListStore.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListStore.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list_store = action.payload.response;
    },
    [getListStore.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    // customer
    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    // product principal
    [getListProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_principal = action.payload.response;
    },
    [getListProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
    },
    // product customer
    [getListProductCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListProductCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_product_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListProductCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },
  },
});

export const productBklMappingAction = productBklMapping.actions;

export default productBklMapping.reducer;
