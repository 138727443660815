import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { Pagination, Select, Table } from "antd";
import { subHours } from "date-fns";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  SearchIcon,
  StatusActive,
  StatusNonActive,
} from "../../../../components/icons";
import CustomPopOver from "../../../../components/popOver/CustomPopOver";
import { ID_NOTIFICATION_MASTER } from "../../../../constant/idConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  HOVER_PRIMARY,
  PRIMARY_COLOR,
  RED_COLOR,
  WHITE_COLOR,
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { selectDropDownFilterNotification } from "../../../../constant/selectOptConstant";
import {
  inputTheme,
  selectOptFilterUserTheme,
} from "../../../../constant/themeConstant";
import "../../../../css/GlobalAntd.css";
import { pageActions } from "../../../../reducers/pageReducer";
import {
  getInitListNotification,
  getListDistributorDDS,
  getListNotification,
  getListPrincipalDDS,
} from "../../../../services/main/notificationReducer";
import DateUtil, { FORMAT } from "../../../../utils/dateUtil";
import StringUtil from "../../../../utils/stringUtil";

const ListNotification = () => {
  const { t } = useTranslation();

  const data = useSelector((state) => state.notification.list_notification);
  const table_loading = useSelector(
    (state) => state.notification.table_loading
  );
  const { filters: objectFilter } = useSelector((state) => state.page);
  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");

  const [countSearch, setcountSearch] = useState(objectFilter?.countSearch);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [form, setForm] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [search, setSearch] = useState(false);
  const [reset, setReset] = useState(true);

  // principal
  const [principalId, setPrincipalId] = useState(objectFilter?.idPrincipal);
  const [filterPrincipalName, setFilterPrincipalName] = useState("");
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const [limitPrincipal] = useState(100);

  // filter distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");
  const [idDistributor, setidDistributor] = useState(
    objectFilter?.idDistributor
  );

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // filter
  const [isDisabled, setIsDisabled] = useState(true);
  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const handleSearch = (e) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setButtonSearchOrClose("close");
      setIsDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setIsDisabled(true);
      setPopOverOpen(true);
    }
    setcountSearch(e.target.value);
  };

  useMemo(() => {
    if (countSearch?.length > 2) {
      setForm(countSearch);
    }
  }, [countSearch]);
  const handleCloseButtonSearch = () => {
    // ketika dia klik close button
    setForm("");
    setButtonSearchOrClose("search");
    setIsDisabled(true);
    setcountSearch("");
  };

  // auto switch icon search and icon close
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };

  // fungsi principal
  const fetchPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
    });
  };
  const fetchPrincipalSearch = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const filter = `name like ${filterPrincipalName}`;
    const params =
      filterPrincipalName === "" ? { page, limit } : { page, limit, filter };

    dispatch(getListPrincipalDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPagePrincipal(1);
      setDataPrincipal(response.payload.response);
    });
  };
  useEffect(() => {
    fetchPrincipal();
  }, [pagePrincipal]);
  useEffect(() => {
    fetchPrincipalSearch();
  }, [filterPrincipalName]);

  const handleScrollPrincipal = (event) => {
    setPagePrincipal(pagePrincipal + 1);
  };
  // end fungsi princapl

  // fungsi untuk DDS filter distributor
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    // const filter = `distributor_name like ${filterDistributor}`;
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };

  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      id: `eq ${objectFilter.idDistributor ?? ""}`,
      distributor_name: `like ${filterDistributor}`,
    });
    const params = { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };
  const handleSelectDistributor = (name, value, option) => {
    setidDistributor(value);
    setIsDisabled(false);
  };

  // end fungsi distributor
  const submitOk = () => {
    // cek filternya dipilih apa engga
    setSearch(true);
    setPage(1);
    dispatch(
      pageActions.setFilters({
        idPrincipal: principalId,
        idDistributor: idDistributor,
        selectDropDownFilter: selectDropDownFilter,
        countSearch: countSearch,
      })
    );
    setIsSubmit(!isSubmit);
  };
  const resetFilter = () => {
    setForm("");
    setIsDisabled(true);
    setButtonSearchOrClose("search");
    setSelectDropDownFilter(null);
    setSearch(false);
    setReset(!reset);
    setPrincipalId(null);
    setidDistributor(null);
    setFilterDistributor("");
    setFilterPrincipalName("");
    setPage(1);
    setLimit(10);
    setcountSearch("");
    dispatch(pageActions.resetFilters());
    setIsSubmit(!isSubmit);
  };

  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };

  const handleChangeSelect = (name, value, option) => {
    setIsDisabled(false);
    if (name === "principal_id") {
      setPrincipalId(value);
    } else if (name === "distributor_id") {
      setidDistributor(value);
    } else {
      setSelectDropDownFilter(option);
    }
  };

  const filter = StringUtil.formatFilter({
    principal_id: `eq ${objectFilter?.idPrincipal}`,
    [selectDropDownFilter?.value]: `like ${objectFilter?.countSearch}`,
    distributor_id: `eq ${objectFilter?.idDistributor}`,
  });

  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });
  const params = { page, limit, filter, order };

  const fetchData = () => {
    dispatch(getListNotification(params));
  };

  useEffect(() => {
    dispatch(getInitListNotification(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
    fetchData();
    setSearch(false);
  }, [page, limit, token, isSubmit, search, order, dispatch, reset]);

  const dataSource =
    data?.length > 0 &&
    data.map((item, index) => ({
      key: index,
      notification_code: item.notification_code,
      name: item.name,
      principal_name: item.principal_name,
      category: item.category,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_BUYER.NOTIFICATION.EDIT,
              state: item.id,
            }}
            id={ID_NOTIFICATION_MASTER.LIST.BTN_EDIT + "_" + item.id}
            text={<EditIcon />}
            backgroundColor="transparent"
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_BUYER.NOTIFICATION.DETAIL,
              state: item.id,
            }}
            id={ID_NOTIFICATION_MASTER.LIST.BTN_VIEW + "_" + item.id}
            text={<DetailIcon />}
            backgroundColor="transparent"
          />
        </div>
      ),
    }));

  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("NOTIFICATION:FIELD_NOTIFICATION_CODE")}
        </Box>
      ),
      dataIndex: "notification_code",
      key: "notification_code",
      sorter: (a, b) => a.notification_code - b.notification_code,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("NOTIFICATION:FIELD_NOTIFICATION_NAME")}
        </Box>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("NOTIFICATION:FIELD_CATEGORY")}
        </Box>
      ),
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category - b.category,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("NOTIFICATION:FIELD_PRINCIPAL_NAME")}
        </Box>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      sorter: (a, b) => a.principal_name - b.principal_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("NOTIFICATION:FIELD_STATUS")}
        </Box>
      ),

      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status - b.status,
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === "A" ? <StatusActive /> : <StatusNonActive />}
              </Box>
              <Box color={status === "A" ? GREEN_COLOR : RED_COLOR}>
                {status === "A" ? "Active" : "Inactive"}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 120,
    },
  ];

  return (
    <Box p={4}>
      <HeadTitle
        title={t("NOTIFICATION:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("NOTIFICATION:SUBTITLE_2")}
            </span>{" "}
            - <span>{t("NOTIFICATION:SUBTITLE_3")}</span>
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_BUYER.NOTIFICATION.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("NOTIFICATION:BUTTON_1")}
              id={ID_NOTIFICATION_MASTER.LIST.ADD_NOTIFICATION}
            />
          </>
        }
      />

      <Box marginTop={6}>
        <Box marginLeft={"46rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          marginBottom={5}
          className="my-select-container"
        >
          <Select
            style={selectOptFilterUserTheme}
            value={principalId}
            onPopupScroll={handleScrollPrincipal}
            onChange={(value, opt) =>
              handleChangeSelect("principal_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterPrincipalName}
                menu={menu}
                search={(e) => setFilterPrincipalName(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_PRINCIPAL")}
            id={ID_NOTIFICATION_MASTER.LIST.SELECT_PRINCIPAL}
            size="large"
          >
            {dataPrincipal &&
              dataPrincipal.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id}>
                    {data.company_profile_name}
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            style={inputTheme}
            value={idDistributor}
            onPopupScroll={handleScrollDistributor}
            // menggunakan onchange
            onChange={(value, opt) =>
              handleChangeSelect("distributor_id", value, opt)
            }
            dropdownRender={(menu) => (
              <DropDownCustom
                value={filterDistributor}
                menu={menu}
                search={(e) => setFilterDistributor(e.target.value)}
              />
            )}
            showSearch={false}
            allowClear="true"
            placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
            id={ID_NOTIFICATION_MASTER.LIST.SELECT_DISTRIBUTOR}
            size="large"
          >
            {dataDistributor &&
              dataDistributor.map((data, index) => {
                return (
                  <Select.Option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </Select.Option>
                );
              })}
          </Select>
          <Select
            value={selectDropDownFilter}
            style={selectOptFilterUserTheme}
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            size="large"
            id={ID_NOTIFICATION_MASTER.LIST.DDL}
            placeholder={t("COMMON:SELECT_FILTER_BY")}
          >
            {selectDropDownFilterNotification?.map((value, index) => {
              return (
                <>
                  <Select.Option key={index} value={value.value}>
                    {t(value.label)}
                  </Select.Option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            backgroundColor="white"
            borderRadius={5}
          >
            <Input
              type="text"
              placeholder={
                selectDropDownFilter?.value
                  ? ` ${t("COMMON:SELECT_FILTER_BY")} ${
                      selectDropDownFilter.children
                    }`
                  : ""
              }
              fontSize="14px"
              onChange={(e) => handleSearch(e)}
              name="group_id"
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={countSearch}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              hoverPrimary={isDisabled ? GRAY_COLOR : HOVER_PRIMARY}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              // isDisabled={isDisabled}
              id={ID_NOTIFICATION_MASTER.LIST.BTN_APPLY}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              id={ID_NOTIFICATION_MASTER.LIST.BTN_RESET}
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          size="small"
          onChange={handleTableChange}
          loading={table_loading}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  _focus={{ outline: "none" }}
                  value={limit}
                  id={ID_NOTIFICATION_MASTER.LIST.ROW_PAGE}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <Select.Option value="10">10</Select.Option>
                  <Select.Option value="25">25</Select.Option>
                  <Select.Option value="50">50</Select.Option>
                  <Select.Option value="100">100</Select.Option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  total={total}
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export default ListNotification;
