import {
  Box, Stack, Text
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StatusActive, StatusNonActive } from "../../../../../components/icons";
const BodyRole = () => {
  const { t } = useTranslation();
  const getDetailRole = useSelector((state) => state.role.detail_role.role);
  const { company_name } = useSelector((state) => state.auth);
  return (
    <Box padding={1}>
      {/* Role */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("ROLE:TITLE_LIST")}</Box>
        <Box>
          <Text>
            <b>{getDetailRole?.role_id}</b>
          </Text>
        </Box>
      </Box>
      {/* Farmindo Account */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("ROLE:FIELD_FARMINDO_ACCOUNT")}</Box>
        <Box>
          <Text>
            <b>{company_name}</b>
          </Text>
        </Box>
      </Box>
      {/* Description */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("ROLE:FIELD_DESCRIPTION")}</Box>
        <Box>
          <Text>
            <b>{getDetailRole?.description}</b>
          </Text>
        </Box>
      </Box>
      {/* Level */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("ROLE:FIELD_ROLE_LEVEL")}</Box>
        <Box>
          <Text>
            <b>{getDetailRole?.level}</b>
          </Text>
        </Box>
      </Box>
      {/* Status */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("ROLE:FIELD_STATUS")}</Box>
        <Box>
          <Text color={getDetailRole?.status === "A" ? "#13A780" : "#BC2023"}>
            <Stack direction="row" spacing="4rem">
              <Box display="flex" alignItems="center">
                <Box marginRight={2}>
                  {getDetailRole?.status === "A" ? (
                    <StatusActive />
                  ) : (
                    <StatusNonActive />
                  )}
                </Box>
                <Box>
                  {getDetailRole?.status === "A" ? (
                    <b>Active</b>
                  ) : (
                    <b>Inactive</b>
                  )}
                </Box>
              </Box>
            </Stack>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyRole;
