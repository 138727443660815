import { Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const ButtonIcon = (props) => {
  return props.link ? (
    <Link to={props.link}>
      <Button
        background={props.backgroundcolor}
        _focus={{
          ringColor: "hwb(211deg 0% 51%)",
        }}
        colorScheme={props?.colorScheme}
        isLoading={props.loading}
        {...props}
      >
        {props.icon}
      </Button>
    </Link>
  ) : (
    <Button
      background={props.backgroundcolor}
      _focus={{
        ringColor: "hwb(211deg 0% 51%)",
      }}
      isLoading={props.loading}
      {...props}
    >
      {props.icon}{" "}
    </Button>
  );
};

export default ButtonIcon;
