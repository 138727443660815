import {
  Box,
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { message, Pagination, Select, Table } from "antd";
import { subHours } from "date-fns";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import DropDownCustom from "../../../../../components/DropDownCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  AddIcon,
  DetailIcon,
  EditIcon,
  SavedSuccess,
  SearchIcon,
  StatusActive,
  StatusNonActive,
  UnSuccess,
  UploadIcon,
} from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import ModalImport from "../../../../../components/ModalImport";
import ModalImportPreview from "../../../../../components/ModalImportPreview";
import CustomPopOver from "../../../../../components/popOver/CustomPopOver";
import SortButton from "../../../../../components/SortButton";
import { ID_PRODUCT_CATEGORY_MAPPING } from "../../../../../constant/idConstant";
import { typeProductCategoryMapping } from "../../../../../constant/importEndPointConstant";
import {
  GRAY_COLOR,
  GREEN_COLOR,
  LABEL_STATUS_ACTIVE,
  LABEL_STATUS_INACTIVE,
  PRIMARY_COLOR,
  RED_COLOR,
  STATUS_ACTIVE,
  WHITE_COLOR,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import { selectDropDownProductCategoryMapping } from "../../../../../constant/selectOptConstant";
import { selectOptFilterUserTheme } from "../../../../../constant/themeConstant";
import { pageActions } from "../../../../../reducers/pageReducer";
import {
  getInitiateListProductCategoryMapping,
  getListCustomer,
  getListProductCategoryMapping,
} from "../../../../../services/main/productCategoryMappingReducer";
import DateUtil, { FORMAT } from "../../../../../utils/dateUtil";
import {
  confirmFile,
  confirmInfoFile,
  downloadJobProccessFile,
  validateFile,
} from "../../../../../utils/importFileUtil";
import StringUtil from "../../../../../utils/stringUtil";
const ListProductCategoryMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.productCategoryMapping.list);
  const { filters: objectFilter } = useSelector((state) => state.page);
  const { table_loading } = useSelector(
    (state) => state.productCategoryMapping
  );
  const { token } = useSelector((state) => state.auth);
  const [total, setTotal] = useState(0);
    
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(100);

  //pagination
  const handleSelectPagination = (value, option) => {
    setPage(1);
    setLimit(value);
  };
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  // pop over
  const [popOverOpen, setPopOverOpen] = useState(false);

  // modal
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [fileImport, setFileImport] = useState(null);
  const [dataImport, setDataImport] = useState([]);
  const [filename, setFilename] = useState("");
  const [isValidateAndConfirm, setIsValidateAndConfirm] = useState(false);
  const [columnImport, setColumnImport] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [proccessLoading, setProccessLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(true); // tutup modal import
    setProccessLoading(true);
    validateImport();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setFileImport(null);
  };
  const handleCancelModalPreview = () => {
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setFileImport(null);
  };
  const onChangeModalImport = (info) => {
    setFileImport(info);
    if (info.file.status === "uploading") {
    }
    if (info.file.status === "done") {
      message.success(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "error") {
      message.error(`${t("COMMON:ANALYZE_FILE_DONE")} : ${info.file.name}`);
    } else if (info.file.status === "removed") {
      setFileImport(null);
    }
  };
  const validateImport = async () => {
    const object = {
      content: { type: typeProductCategoryMapping },
      file: fileImport?.file.originFileObj,
    };
    try {
      const response = await validateFile(object, token);
      if (response.ok) {
        const [column, ...dataSource] = response.response.data;
        if (dataSource) setDataImport(dataSource);
        if (column) setColumnImport(column);
        setFilename(response.response.filename);
        setIsModalOpen(false);
        setIsModalPreviewOpen(true); // buka modal import preview
      } else {
        setErr(true);
        setResp(response.response.data.nexsoft.payload.status.message);
        setModalOpen(true);
        setIsModalOpen(false);
        setIsModalPreviewOpen(false);
        setFileImport(null); // reset File jadi null lagi
      }
    } catch (error) {
      message.error(error);
    }
    setProccessLoading(false);
  };

  const importConfirm = async () => {
    const object = {
      type: typeProductCategoryMapping,
      filename: filename,
      confirm: true,
    };
    const { ok, response } = await confirmFile(object, token);
    const payload = {
      job_id: response,
    };
    confirmInfo(payload);
    setUploadLoading(true);
  };

  const confirmInfo = async (object) => {
    const response = await confirmInfoFile(object, token);
    const totalData = response?.response?.total;
    const message = response.response.message_process;
    if (response.response.status === "ERROR") {
      jobProccess(response?.response?.job_id);
      setModalOpen(true);
      setErr(true);
      setFileImport(null);
      // setResp(t("COMMON:UPLOAD_FAILED"));
      setResp(message);
      setProccessLoading(false);
      setIsModalPreviewOpen(false);
      setUploadLoading(false);
    } else {
      setModalOpen(true);
      setErr(false);
      setResp(message);
      setIsModalPreviewOpen(false);
      setFileImport(null);
      setUploadLoading(false);
    }
  };

  const jobProccess = async (jobId) => {
    const response = await downloadJobProccessFile(jobId, token);
    FileSaver.saveAs(response.response.data, filename);
    setUploadLoading(false);
  };

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setIsValidateAndConfirm(true);
    setModalOpen(false);
    setIsModalPreviewOpen(false);
    setIsModalOpen(false);
    setDataChanged(!dataChanged);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setIsValidateAndConfirm(false);
    setModalOpen(false);
  };

  // Customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomer(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomer(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };
  // for filter
  const [inputSearch, setInputSearch] = useState("");
  const [form, setForm] = useState(objectFilter?.form);
  const [formCustomer, setFormCustomer] = useState(0);
  const [isDisabled, setisDisabled] = useState(true);
  const [dropDownCustomerIsDisabled, setDropDownCustomerIsDisabled] =
    useState(false);
  const [dropDownFilterIsDisabled, setDropDownFilterDisabled] = useState(false);

  const [buttonSearchOrClose, setButtonSearchOrClose] = useState("search");
  const handleChange = (e) => {
    if (e.target.value.length > 0) {
      setButtonSearchOrClose("close");
      setisDisabled(false);
      setPopOverOpen(false);
    } else {
      setButtonSearchOrClose("search");
      setisDisabled(true);
      setPopOverOpen(false);
    }
    setForm(e.target.value);
  };

  const submitOk = () => {
    const length = form?.length;
    if (length > 0 && length < 3) {
      setPopOverOpen(true);
    } else {
      setInputSearch(form);
      setFormCustomer(customerId);
      setPage(1);
      setPopOverOpen(false);
      dispatch(
        pageActions.setFilters({
          idCustomer: customerId,
          form: form,
          selectDropDownFilter: selectDropDownFilter,
        })
      );
      setIsSubmit(!isSubmit);
    }
  };
  const resetFilter = () => {
    setForm("");
    setInputSearch("");
    setCustomerId(null);
    setisDisabled(true);
    setSelectDropDownFilter(null);
    setDropdownValue("");
    setFormCustomer(null);
    setPopOverOpen(false);
    setButtonSearchOrClose("search");
    setPage(1);
    setLimit(10);
    setIsSubmit(!isSubmit);
    dispatch(pageActions.resetFilters());
  };
  const handleCloseButtonSearch = () => {
    setForm("");
    setButtonSearchOrClose("search");
    setisDisabled(true);
    setPopOverOpen(false);
  };
  const renderSearchOrCloseButton = () => {
    if (buttonSearchOrClose === "search") {
      return (
        <InputRightElement
          pointerEvents="none"
          children={<Icon as={SearchIcon} color="gray.300" />}
        />
      );
    } else {
      return (
        <InputRightElement
          onClick={handleCloseButtonSearch}
          children={<CloseButton />}
        />
      );
    }
  };
  const [selectDropDownFilter, setSelectDropDownFilter] = useState(
    objectFilter?.selectDropDownFilter
  );
  const [dropdownValue, setDropdownValue] = useState("");
  const handleChangeSelect = (name, value, option) => {
    // setSelectDropDownFilter({ ...selectDropDownFilter, ...option, value });
    // setDropdownValue(value);
    setSelectDropDownFilter(option);
  };
  const [customerId, setCustomerId] = useState(objectFilter?.idCustomer);
  const handleChangeSelectCustomer = (name, value, option) => {
    setCustomerId(value);
    setButtonSearchOrClose("close");
  };
  const fetchData = async () => {
    const filter = StringUtil.formatFilter({
      [selectDropDownFilter?.value]: `like ${objectFilter?.form}`,
      customer_id: `eq ${objectFilter?.idCustomer ?? ""}`,
    });

    const params = { page, limit, filter, order };
    dispatch(getListProductCategoryMapping(params));
  };

  const fetchInitiate = () => {
    const filter = StringUtil.formatFilter({
      [selectDropDownFilter?.value]: `like ${objectFilter?.form}`,
      customer_id: `eq ${objectFilter?.idCustomer ?? ""}`,
    });
    const params = { page, limit, filter, order };
    dispatch(getInitiateListProductCategoryMapping(params)).then((response) => {
      setTotal(response.payload.response.count_data);
    });
  };

  useEffect(() => {
    fetchInitiate();
    fetchData();
  }, [
    page,
    limit,
    order,
    isSubmit,
    dataChanged,
    isValidateAndConfirm,
    dispatch,
  ]);

  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);

  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  const dataSourceImport =
    dataImport?.length > 0 &&
    dataImport?.map((item, index) => {
      return columnImport.reduce((prev, accumulator, index) => {
        prev[accumulator] = item[index];
        return prev;
      }, {});
    });

  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      principal_code: item.principal_code,
      principal_name: item.principal_name,
      customer_code: item.customer_code,
      customer_name: item.customer_name,
      product_category_name: item.product_category_name,
      store_code: item.store_code,
      updated_at: DateUtil.formatDate(
        subHours(new Date(item.updated_at), 7),
        FORMAT.DATE_TIME
      ),
      status: item.status,
      action: (
        <div>
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.EDIT,
              state: item,
            }}
            text={<EditIcon />}
            backgroundcolor="transparent"
            id={ID_PRODUCT_CATEGORY_MAPPING.LIST.BTN_EDIT + "_" + item.id}
          />
          <ButtonCustom
            link={{
              pathname: ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.DETAIL,
              state: item,
            }}
            text={<DetailIcon />}
            backgroundcolor="transparent"
            id={ID_PRODUCT_CATEGORY_MAPPING.LIST.BTN_EDIT + "_" + item.id}
          />
        </div>
      ),
    }));
  const columnsImport = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* distributor code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[0]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[0],
      key: columnImport[0],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* customer code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[1]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[1],
      key: columnImport[1],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* store code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[2]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[2],
      key: columnImport[2],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* supplier code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[3]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[3],
      key: columnImport[3],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* customer product code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[4]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[4],
      key: columnImport[4],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* principal product code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[5]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[5],
      key: columnImport[5],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* product category code  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[6]}`)}
          <SortButton thisField="id" />
        </Box>
      ),
      dataIndex: columnImport[6],
      key: columnImport[6],
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {/* status  */}
          {t(`PRODUCT_CATEGORY_MAPPING:FIELD_${columnImport[7]}`)}
        </Box>
      ),
      dataIndex: columnImport[7],
      key: columnImport[7],
      sorter: (a, b) => a.columnImport[7].localeCompare(b.columnImport[7]),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? LABEL_STATUS_ACTIVE
                  : LABEL_STATUS_INACTIVE}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
  ];
  const columns = [
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_CODE")}
        </Box>
      ),
      dataIndex: "principal_code",
      key: "principal_code",
      sorter: (a, b) => a.principal_code.localeCompare(b.principal_code),
      sortDirections: ["descend", "ascend"],
      width: 120,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRINCIPAL_NAME")}
        </Box>
      ),
      dataIndex: "principal_name",
      key: "principal_name",
      sorter: (a, b) => a.principal_name.localeCompare(b.principal_name),
      sortDirections: ["descend", "ascend"],
      width: 125,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_CODE")}
        </Box>
      ),
      dataIndex: "customer_code",
      key: "customer_code",
      sorter: (a, b) => a.customer_code.localeCompare(b.customer_code),
      sortDirections: ["descend", "ascend"],
      width: 123,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_CUSTOMER_NAME")}
        </Box>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      sortDirections: ["descend", "ascend"],
      width: 140,
      ellipsis: true,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_PRODUCT_CATEGORY_NAME")}
        </Box>
      ),
      dataIndex: "product_category_name",
      key: "product_category_name",
      sorter: (a, b) =>
        a.product_category_name.localeCompare(b.product_category_name),
      sortDirections: ["descend", "ascend"],
      width: 165,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_STORE_CODE")}
        </Box>
      ),
      dataIndex: "store_code",
      key: "store_code",
      sorter: (a, b) => a.store_code.localeCompare(b.store_code),
      sortDirections: ["descend", "ascend"],
      width: 100,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("COMMON:MODIFIED_DATE")}
        </Box>
      ),
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: (a, b) => a.updated_at - b.updated_at,
      sortDirections: ["descend", "ascend"],
      width: 175,
    },
    {
      title: (
        <Box display="flex" alignItems="center" cursor="pointer">
          {t("PRODUCT_CATEGORY_MAPPING:FIELD_STATUS")}
        </Box>
      ),
      dataIndex: "status",
      key: "status",
      width: 110,
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (status) => (
        <>
          <Stack direction="row">
            <Tag rounded="full" px="2" display="flex" alignItems="center">
              <Box marginRight={2}>
                {status === STATUS_ACTIVE ? (
                  <StatusActive />
                ) : (
                  <StatusNonActive />
                )}
              </Box>
              <Box color={status === STATUS_ACTIVE ? GREEN_COLOR : RED_COLOR}>
                {status === STATUS_ACTIVE
                  ? t("COMMON:STATUS_ACTIVE")
                  : t("COMMON:STATUS_INACTIVE")}
              </Box>
            </Tag>
          </Stack>
        </>
      ),
    },
    {
      title: t("COMMON:ACTION"),
      dataIndex: "action",
      key: "action",
      width: 105,
    },
  ];

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY_MAPPING:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY_MAPPING:SUBTITLE_3")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY_MAPPING:TITLE_LIST")}
          </>
        }
        button={
          <>
            <ButtonPrimary
              link={ROUTES_USER_SELLER.PRODUCT_CATEGORY_MAPPING.ADD}
              icon={<Icon as={AddIcon} />}
              text={t("PRODUCT_CATEGORY_MAPPING:BUTTON_9")}
              id={ID_PRODUCT_CATEGORY_MAPPING.LIST.BTN_ADD}
            />
            <ButtonCustom
              icon={<UploadIcon />}
              text={t("COMMON:BUTTON_UPLOAD_FILE")}
              marginStart={2}
              onClick={showModal}
              id={ID_PRODUCT_CATEGORY_MAPPING.LIST.BTN_UPLOAD}
              variant="outline"
              colorScheme="blue"
            />
          </>
        }
      />

      <Box marginTop={6} minHeight="96px">
        <Box marginLeft={"30rem"}>
          <CustomPopOver isOpen={popOverOpen} />
        </Box>
        <Stack direction="row" alignItems="center" marginBottom={5}>
          <Select
            placeholder={t("PRODUCT_CATEGORY_MAPPING:PLACE_HOLDER_SEARCH_2")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            textColor={GRAY_COLOR}
            size="large"
            value={customerId}
            onChange={(value, opt) =>
              handleChangeSelectCustomer(
                "select_drop_down_customer",
                value,
                opt
              )
            }
            disabled={dropDownCustomerIsDisabled}
            onPopupScroll={handleScrollCustomer}
            fontSize="14px"
            id="DDS-UserSeller-ProductPrincipal-SelectPrincipal"
            showSearch={false}
            dropdownRender={(menu) => (
              <DropDownCustom
                menu={menu}
                search={(e) => setfilterCustomer(e.target.value)}
              />
            )}
          >
            {dataCustomer &&
              dataCustomer?.map((data, index) => {
                return (
                  <option key={index} value={data.id} title={data.name}>
                    {data.name}
                  </option>
                );
              })}
          </Select>
          <Select
            placeholder={t("COMMON:SEARCH_BY")}
            style={selectOptFilterUserTheme}
            backgroundColor="white"
            // disabled={dropDownFilterIsDisabled}
            value={selectDropDownFilter}
            size="large"
            onChange={(value, opt) =>
              handleChangeSelect("select_drop_down", value, opt)
            }
            textColor={GRAY_COLOR}
            fontSize="14px"
            showSearch={false}
          >
            {selectDropDownProductCategoryMapping?.map((value, index) => {
              return (
                <>
                  <option key={index} value={value.value}>
                    {t(value.label)}
                  </option>
                </>
              );
            })}
          </Select>
          <InputGroup
            width="257px"
            height="40px"
            marginStart={3}
            backgroundColor="white"
            textColor={GRAY_COLOR}
            borderRadius={5}
          >
            <Input
              type="text"
              onChange={(e) => handleChange(e)}
              placeholder={
                selectDropDownFilter?.value
                  ? `${t("COMMON:SEARCH_BY")} ${selectDropDownFilter?.children}`
                  : ""
              }
              fontSize="14px"
              id={ID_PRODUCT_CATEGORY_MAPPING.LIST.SEARCH}
              pointerEvents={selectDropDownFilter?.value ? "auto" : "none"}
              value={form}
            />
            {renderSearchOrCloseButton()}
          </InputGroup>
          <Box>
            <ButtonPrimary
              text={t("COMMON:BUTTON_APPLY")}
              // backgroundColor={isDisabled ? GRAY_COLOR : PRIMARY_COLOR}
              backgroundColor={PRIMARY_COLOR}
              color={WHITE_COLOR}
              fontWeight={500}
              fontSize={14}
              width="93px"
              onClick={submitOk}
              marginRight={2}
              // isDisabled={isDisabled}
            />
          </Box>
          <Box>
            <Text
              color={PRIMARY_COLOR}
              fontWeight={500}
              fontSize={14}
              onClick={resetFilter}
              pointerEvents="auto"
              cursor="pointer"
            >
              {t("COMMON:BUTTON_RESET_FILTER")}
            </Text>
          </Box>
        </Stack>
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          onChange={handleTableChange}
          size="small"
          loading={table_loading}
          scroll={{ y: 400 }}
          footer={() => (
            <Box
              backgroundColor="#E5EBF1"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <Box display="flex" alignItems="center" marginX={2}>
                <Box marginX={4}>{t("COMMON:ROW_PER_PAGE")}</Box>
                <Select
                  border="1px solid"
                  borderColor="#CCCCCC"
                  size="sm"
                  width={20}
                  id={ID_PRODUCT_CATEGORY_MAPPING.LIST.ROW_PER_PAGE}
                  _focus={{ outline: "none" }}
                  value={limit}
                  onChange={(value, opt) => handleSelectPagination(value, opt)}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Select>
              </Box>
              <Box>
                <Pagination
                  size="small"
                  current={page}
                  id={ID_PRODUCT_CATEGORY_MAPPING.LIST.PAGINATION}
                  total={total}
                  // showQuickJumper
                  onChange={(value) => setPage(value)}
                  showSizeChanger={false}
                  pageSize={limit}
                  defaultCurrent={1}
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <ModalImport
        fileList={fileImport?.fileList ?? []}
        visible={isModalOpen}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        onChangeFile={(info) => onChangeModalImport(info)}
        loading={proccessLoading}
      />
      <ModalImportPreview
        visible={isModalPreviewOpen}
        // onOk={() => importConfirm()}
        dataSource={dataSourceImport}
        columns={columnsImport}
        onCancel={() => handleCancelModalPreview()}
        onUpload={() => importConfirm()}
        scrollYLength={2000}
        loading={uploadLoading}
      />
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </Box>
  );
};

export default ListProductCategoryMapping;
