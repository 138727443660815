import { Box, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";

import { PRIMARY_COLOR } from "../../../../../constant/propertiesConstant";
import { ROUTES, ROUTES_USER_BUYER } from "../../../../../constant/routeConstant";
import { detailProcessPO } from "../../../../../services/main/monitoring/monitoringListProcessPOReducer";
import TransactionDetail from "./TransactionDetail";

const DetailProcessPO = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch(); 


  const process_po = useSelector(
    (data) => data.monitoringListProcessPO?.detail_process_po?.list_process_po
  );
  const data_management_history = useSelector(
    (data) => data.monitoringListProcessPO?.detail_process_po?.data_management_history
  ); 
  const fetchData = async () => {
    dispatch(detailProcessPO(location.state));
  };
  useEffect(() => {
    fetchData();
  }, []);

 
  return (
    <Box p={4}>
      <HeadTitle
        title={t("MONITORING_LIST_PO:DETAIL_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_LIST_PO:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_LIST_PO:SUBTITLE_3")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("MONITORING_LIST_PO:TITLE_LIST")}
            </span>{" "}
            - {t("MONITORING_LIST_PO:SUBTITLE_4")}
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
          link={ROUTES.LIST_PROCCESS_PO.LIST}
          name={data_management_history?.created_name}
          date={moment(data_management_history?.created_on).format("DD/MM/YYYY")}
          modifiedDate={moment(data_management_history?.modified_on).format(
            "DD/MM/YYYY"
          )}
          modifiedBy={data_management_history?.modified_name}
        />
      {/* White Box section */}
      <Box
        backgroundColor="white"
        minHeight="277px"
        padding={4}
        display="flex"
        justifyContent="space-between"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box className="column-po">
          {/* PO Number */}
          <Box className="list-po">
            <Box>{t("MONITORING_LIST_PO:FIELD_PO_NUMBER")}</Box>
            <Box>
              <Text>
                <b>{process_po?.po_number === "" ? "-" : process_po?.po_number}</b>
              </Text>
            </Box>
          </Box>
          {/* Distributor*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("MONITORING_LIST_PO:FIELD_DISTRIBUTOR")}</Text>
              <Text>
                <b>{process_po?.distributor_name === "" ? "-" : process_po?.distributor_name}</b>
              </Text>
            </Box>
          </Box>
          {/* Transaction Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("MONITORING_LIST_PO:FIELD_STORE_NAME")}</Text>
            </Box>
            <Text>
              <b>{process_po?.store_name === "" ? "-" : process_po?.store_name}</b>
            </Text>
          </Box>
          {/* Delivery Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("MONITORING_LIST_PO:FIELD_STORE_ADDRESS")}</Text>
            </Box>
            <Text>
              <b>{process_po?.store_address === "" ? "-" : process_po?.store_address}</b>
            </Text>
          </Box>
        </Box>
        {/* Middle side */}
        <Box className="column-po">
          {/* Expired Date*/}
          <Box className="list-po">
            <Box>
              <Text>{t("MONITORING_LIST_PO:FIELD_PO_DATE")}</Text>
            </Box>
            <Text>
              <b> {moment(process_po?.date_po).format("YYYY-MM-DD HH:MM:SS") }</b>
            </Text>
          </Box>
          {/* Process Date*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("MONITORING_LIST_PO:FIELD_DELIVERY_DATE")}</Text>
            </Box>
            <Text>
              <b>{moment(process_po?.delivery_date).format("YYYY-MM-DD HH:MM:SS") }</b>
            </Text>
          </Box>
          {/* REMARK*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("MONITORING_LIST_PO:FIELD_EXPIRED_DATE")}</Text>
            </Box>
            <Text>
              <b>{moment(process_po?.expired_date).format("YYYY-MM-DD HH:MM:SS") }</b>
            </Text>
          </Box>
          {/* TOP*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("MONITORING_LIST_PO:FIELD_NET_AMOUNT")}</Text>
            </Box>
            <Text>
              <b>{process_po?.net_amount === "" ? "-" : process_po?.net_amount}</b>
            </Text>
          </Box>
        </Box>

        {/* Right side */}
        <Box className="column-po">
          {/* Address */}
          <Box className="list-po">
            <Box>{t("MONITORING_LIST_PO:FIELD_DELIVERY_FROM_WAREHOUSE")}</Box>
            <Box>
              <Text display="flex">
                <b> {process_po?.delivered_from_warehouse === "" ? "-" : process_po?.delivered_from_warehouse} </b>
              </Text>
            </Box>
          </Box>
          
        </Box>
      </Box>
      {/* Transaction Detail Section */}
      <TransactionDetail />
    </Box>
  );
};

export default DetailProcessPO;
