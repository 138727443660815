import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import * as actionType from "../../actions/actionType";
import { pageActions } from "../../reducers/pageReducer";
import { useDispatch, useSelector } from "react-redux";
import { formatError, checkRejectErrorWithValue, formatErrorResponse } from '../../helpers/errorHelper';
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";
import { useCallback } from "react";
const INITIAL_STATE = {
  isOption: false,
  list: [],
  list_option: [],
  listDistributor: [],
  listCustomer: [],
  listStore: [],
  listWarehouse: [],
  ok: false,
  data_group: {
    group_id: null,
    level: null,
    description: null,
    status: "",
  },
  data_scope_opt: {
    principal_branch: [],
    customer: [],
    store: [],
    warehouse: [],
  },
  data_scope: {
    principal: null,
    po_status: null,
    principal_branch: [],
    customer: [],
    store: [],
    warehouse: [],
  },
  errorNotification: null,
  curr_page: "",
  index_page: 0,
  detail_data_group: {},
  updated_at: "",
  count_data: 0,
  count_data_option: 0,
  is_distributor_opt: false,
  is_customer_opt: false,
  is_warehouse_opt: false,
  is_store_opt: false,
  end_distributor_opt: false,
  end_customer_opt: false,
  end_warehouse_opt: false,
  end_store_opt: false,
  has_error: false,
  is_submitting: false,
  error: null,
  response: {
    success: null,
    code: '',
    message: '',
    detail: ''
  }
};
export const getListDataGroup = createAsyncThunk(
  "getListDataGroup",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDataGroupOption = createAsyncThunk(
  "getListDataGroupOption",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getInitiateListDataGroup = createAsyncThunk(
  "getInitiateListDataGroup",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addDataGroups = createAsyncThunk(
  "addDataGroups",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group`;
    const { auth } = getState();
    const principalId = auth.principal_id;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    const groupId = data_group.group_id;
    const level = data_group.level;
    const description = data_group.description;
    const status = data_group.status;
    const data_scope = dataGroup.data_scope;
    
    const principalBranchMapping = object.principal_branch.map((val) => {
      return `farmindo.principal_branch:` + val;
    });
    const customerMapping = object.customer.map((val) => {
      return `farmindo.customer:` + val;
    });
    const storeMapping = object.store.map((val) => {
      return `farmindo.store:` + val;
    });
    const warehouseMapping = object.warehouse.map((val) => {
      return "farmindo.warehouse:" + val;
    });
    let array = [
      `farmindo.principal:${principalId}`,
      `farmindo.po_status:${object.po_status}`,
    ]
      .concat(principalBranchMapping)
      .concat(customerMapping)
      .concat(storeMapping)
      .concat(warehouseMapping);

    const token = auth.token;
    const body = {
      group_id: groupId,
      level: Number(level),
      description: description,
      status: status,
      scope: array,
    };
    try {
      const response = await baseApi.doPost(url, token, body);
      result.ok = true;
      result.response = response;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const editDataGroups = createAsyncThunk(
  "editDataGroups",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group/${object.groupId}`;
    const { auth } = getState();
    const { dataGroup } = getState();
    const updatedAt = object.updated_at;
    const principalId = auth.principal_id;
    const data_group = dataGroup.data_group;
    const groupId = data_group.group_id;
    const level = data_group.level;
    const description = data_group.description;
    const status = data_group.status;
    const data_scope = dataGroup.data_scope;
    const principalBranchMapping = object.scope.principal_branch.map((val) => {
      return `farmindo.principal_branch:` + val;
    });
    const customerMapping = object.scope.customer.map((val) => {
      return `farmindo.customer:` + val;
    });
    const storeMapping = object.scope.store.map((val) => {
      return `farmindo.store:` + val;
    });
    const warehouseMapping = object.scope.warehouse.map((val) => {
      return "farmindo.warehouse:" + val;
    });
    let array = [
      `farmindo.principal:${principalId}`,
      `farmindo.po_status:${object.scope.po_status}`,
    ]
      .concat(principalBranchMapping)
      .concat(customerMapping)
      .concat(storeMapping)
      .concat(warehouseMapping);

    const token = auth.token;
    const body = {
      group_id: groupId,
      level: Number(level),
      description: description,
      status: status,
      scope: array,
      updated_at: updatedAt,
    };
    try {
      const response = await baseApi.doPut(url, token, body);
      result.ok = true;
      result.response = response;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailDataGroups = createAsyncThunk(
  "detailDataGroups",
  async (dataGroupId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group/${dataGroupId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
export const deleteDataGroup = createAsyncThunk(
  "deleteDataGroup",
  async (object, { getState, rejectWithValue }) => {
    object = {
      updated_at: object.updated_at,
      dataGroupId: object.dataGroupId,
    };
    let result = { ok: false, response: null };

    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group/${object.dataGroupId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
/// data scope
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributorNoLimit = createAsyncThunk(
  "getListDistributorNoLimit",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content ?? [];
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const { auth } = getState();
    const token = auth.token;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomerNoLimit = createAsyncThunk(
  "getListCustomerNoLimit",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const { auth } = getState();
    const token = auth.token;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListStore = createAsyncThunk(
  "getListStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store`;
    const { auth } = getState();
    const token = auth.token;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListWarehouse = createAsyncThunk(
  "getListWarehouse",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse`;
    const { auth } = getState();
    const token = auth.token;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListWarehouseNoLimit = createAsyncThunk(
  "getListWarehouseNoLimit",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/warehouse`;
    const { auth } = getState();
    const token = auth.token;
    const { dataGroup } = getState();
    const data_group = dataGroup.data_group;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const addDataGroupAction = createAsyncThunk(
  'addDataGroupAction',
  async (formData , { getState, rejectWithValue }) => {
    const { auth } = getState();
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group`;
    try {
      const { data } = await baseApi.doPost(url, auth.token, formData);
      
      return { ok: true, response: data.nexsoft.payload.status.message, message: data.nexsoft.payload.status.message };
    } catch (error) {
      return rejectWithValue(formatError(error, formatErrorResponse));
    }
  }
);

export const editDataGroupAction = createAsyncThunk(
  'editDataGroupAction',
  async ({ data, id} , { getState ,rejectWithValue }) => {
    const { token } = getState().auth;
    try {
      const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/data-group/${id}`;
      const { data: editData, } = await baseApi.doPut(url, token, data);
      return { ok: true, response: editData.nexsoft.payload.status.message, message: editData.nexsoft.payload.status.message };
    } catch (error) {
      return rejectWithValue(formatError(error, formatErrorResponse));
    }
  }
);

const dataGroup = createSlice({
  name: "dataGroup",
  initialState: INITIAL_STATE,
  reducers: {
    setIsOption(state, action) {
      state.isOption = action.payload ?? true;
    },
    setReset(state, action) {
      state.status = null;
      state.errorNotification = null;
      state.successNotification = null;
    },
    setDataGroup(state, action) {
      state.data_group = action.payload;
    },
    setDataScope(state, action) {
      state.data_scope = action.payload;
    },
    setUpdatedAt(state, action) {
      state.updated_at = action.payload;
    },
    setDetailDataGroup(state, action) {
      state.detail_data_group = action.payload;
    },
    setListDistributor(state, action) {
      state.listCustomer = action.payload;
    },
    setDataScopeOption(state, action) {
      if (action.payload.type === "C")
        state.data_scope_opt.customer = action.payload.customer;
      else if (action.payload.type === "PB")
        state.data_scope_opt.principal_branch = action.payload.principal_branch;
      else if (action.payload.type === "S")
        state.data_scope_opt.store = action.payload.store;
      else state.data_scope_opt.warehouse = action.payload.warehouse;
    },
    setCountData(state, action) {
      state.count_data = action.payload;
    },
    setCountDataOption(state, action) {
      state.count_data_option = action.payload;
    },
    setCurrPage(state, action) {
      state.curr_page = action.payload;
    },
    setIndexPage(state, action) {
      state.index_page = action.payload;
    },
    setDistributorIsOption(state, action) {
      state.is_distributor_opt = action.payload;
    },
    setCustomerIsOption(state, action) {
      state.is_customer_opt = action.payload;
    },
    setWarehouseIsOption(state, action) {
      state.is_warehouse_opt = action.payload;
    },
    setDistributorEnd(state, action) {
      state.end_distributor_opt = action.payload;
    },
    setCustomerEnd(state, action) {
      state.end_customer_opt = action.payload;
    },
    setWarehouseEnd(state, action) {
      state.end_warehouse_opt = action.payload;
    },
    resetReduxDataGroup() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListDataGroup.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListDataGroup.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading = false;
    },
    [getListDataGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [getListDataGroupOption.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListDataGroupOption.fulfilled]: (state, action) => {
      state.status = "success";
      // let listResponse = action.payload.response || []
      // if (state.isOption) {
      //   state.list_option = [...state.list_option, ...listResponse ];
      // } else {
      //   state.list_option = listResponse;
      // }
      state.list_option = action.payload.response;
    },
    [getListDataGroupOption.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload.response;
      state.table_loading = false;
    },

    [getInitiateListDataGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListDataGroup.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListDataGroup.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addDataGroups.pending]: (state, action) => {
      state.status = "loading";
    },
    [addDataGroups.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addDataGroups.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editDataGroups.pending]: (state, action) => {
      state.status = "loading";
    },
    [editDataGroups.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editDataGroups.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [detailDataGroups.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailDataGroups.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_data_group =
        action.payload.response.nexsoft.payload.data.content;
    },
    [detailDataGroups.rejected]: (state, action) => {
      state.status = "failed";
    },
    // buat get list di data scope

    // list distributor atau principal branch
    [getListDistributor.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.status = "success";
      if (action.payload.response !== null) {
        state.listDistributor = [
          ...state.listDistributor,
          ...action.payload.response,
        ];
      } else {
        state.end_distributor_opt = true;
      }
    },
    [getListDistributor.rejected]: (state, action) => {
      state.status = "failed";
    },

    // list customer
    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      if (action.payload.response !== null) {
        state.listCustomer = [
          ...state.listCustomer,
          ...action.payload.response,
        ];
      } else {
        state.end_customer_opt = true;
      }
    },
    [getListCustomer.rejected]: (state, action) => {
      state.status = "failed";
    },

    // list store
    [getListStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListStore.fulfilled]: (state, action) => {
      state.status = "success";
      if (action.payload.response) {
        state.listStore = [...state.listStore, ...action.payload.response];
      } else {
        state.end_store_opt = true;
      }
    },
    [getListStore.rejected]: (state, action) => {
      state.status = "failed";
    },

    // list warehouse
    [getListWarehouse.pending]: (state, action) => {
      state.status = "loading";
    },
    [getListWarehouse.fulfilled]: (state, action) => {
      state.status = "success";
      if (action.payload.response !== null) {
        state.listWarehouse = [
          ...state.listWarehouse,
          ...action.payload.response,
        ];
      } else {
        state.end_warehouse_opt = true;
      }
    },
    [getListWarehouse.rejected]: (state, action) => {
      state.status = "failed";
    },

    // Add Data Group
    [addDataGroupAction.pending]: ( state, { payload }) => {
      state.is_submitting = true;
      state.has_error = false;
      state.error = null;
      state.response = null;
    },
    [addDataGroupAction.fulfilled]: ( state, { payload }) => {
      state.response = payload;
      state.is_submitting = false;
    },

    [addDataGroupAction.rejected]: ( state, { payload }) => {
      state.is_submitting = false;
      state.response = null;
      state.has_error = true;
      state.error = payload;
    },

    // Edit Data Group
    [editDataGroupAction.pending]: ( state, { payload }) => {
      state.is_submitting = true;
      state.has_error = false;
      state.error = null;
      state.response = null;
    },
    [editDataGroupAction.fulfilled]: ( state, { payload }) => {
      state.response = payload;
      state.is_submitting = false;
    },

    [editDataGroupAction.rejected]: ( state, { payload }) => {
      state.is_submitting = false;
      state.response = null;
      state.has_error = true;
      state.error = payload;
    },

    "auth/resetRedux": (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const dataGroupAction = dataGroup.actions;

export default dataGroup.reducer;

export const useWithAddDataGroup = ({ onSuccess, onError, onFinally } = {}) => {
  const { is_submitting, is_loading, error, response } = useSelector(state => state.dataGroup);
  const dispatch = useDispatch();

  const handleOnSubmit = useCallback((data) => {
    const {
      group_id,
      description,
      level,
      status,
      principal,
      principal_branch,
      warehouse,
      customer,
      store,
      po_status
    } = data;

    const dataGroupAdd = {
      group_id,
      description,
      level: level,
      status: status,
      scope: []
    }

    if (principal) {
      const selectedPrincipal = `farmindo.principal:${principal.value}`;
      dataGroupAdd.scope = [selectedPrincipal];
    }

    if (po_status?.value) {
      const selectedPoStatus = `farmindo.po_status:${po_status.value}`;
      dataGroupAdd.scope = [...dataGroupAdd.scope, selectedPoStatus];
    }

    let principalBranch = [];
    if (principal_branch && principal_branch[0]?.value === "_all") {
      principalBranch = ['farmindo.principal_branch:_all'];
    } else if (principal_branch) {
      principalBranch = principal_branch.map((branch) => {
        return `farmindo.principal_branch:${branch.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope, ...principalBranch];

    let warehouseMapped = [];
    if (warehouse && warehouse[0]?.value === "_all") {
      warehouseMapped = ['farmindo.warehouse:_all'];
    } else if (warehouse) {
      warehouseMapped = warehouse.map((wrhs) => {
        return `farmindo.warehouse:${wrhs.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope,...warehouseMapped];

    let customerMapped = [];
    if (customer && customer[0]?.value === "_all") {
      customerMapped = ['farmindo.customer:_all'];
    } else if (customer) {
      customerMapped = customer.map((cust) => {
        return `farmindo.customer:${cust.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope,...customerMapped];

    let storeMapped = [];
    if (store && store[0]?.value === "_all") {
      storeMapped = ['farmindo.store:_all'];
    } else if (store) {
      storeMapped = store.map((str) => {
        return `farmindo.store:${str.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope, ...storeMapped];

    dispatch(addDataGroupAction(dataGroupAdd))
      .then((withValue) => {
        if (withValue.error && withValue.error.message.toLowerCase() === 'rejected') {
          onError && onError(withValue.payload);
        } else {
          onSuccess && onSuccess(withValue.payload);
        }
      })
      .finally(onFinally)

  }, [dispatch, onSuccess, onError, onFinally]);

  return {
    handleOnSubmit,
    loading: is_loading,
    is_submitting,
    error,
    response
  }
}

export const useWithEditDataGroup = ({ onSuccess, onError, onFinally } = {}) => {
  const { is_submitting, is_loading, error, response } = useSelector(state => state.dataGroup);
  const dispatch = useDispatch();

  const handleOnSubmit = useCallback((data, id) => {
    const {
      group_id,
      description,
      level,
      status,
      principal,
      principal_branch,
      warehouse,
      customer,
      store,
      po_status,
      updated_at
    } = data;

    const dataGroupAdd = {
      group_id,
      description,
      level,
      status,
      scope: [],
      updated_at
    }

    if (principal) {
      const selectedPrincipal = `farmindo.principal:${principal.value}`;
      dataGroupAdd.scope = [selectedPrincipal];
    }

    if (po_status?.value) {
      const selectedPoStatus = `farmindo.po_status:${po_status.value}`;
      dataGroupAdd.scope = [...dataGroupAdd.scope, selectedPoStatus];
    }

    let principalBranch = [];
    if (principal_branch && principal_branch[0].value === "_all") {
      principalBranch = ['farmindo.principal_branch:_all'];
    } else if (principal_branch) {
      principalBranch = principal_branch.map((branch) => {
        return `farmindo.principal_branch:${branch.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope, ...principalBranch];

    let warehouseMapped = [];
    if (warehouse && warehouse[0]?.value === "_all") {
      warehouseMapped = ['farmindo.warehouse:_all'];
    } else if (warehouse) {
      warehouseMapped = warehouse.map((wrhs) => {
        return `farmindo.warehouse:${wrhs.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope,...warehouseMapped];

    let customerMapped = [];
    if (customer && customer[0].value === "_all") {
      customerMapped = ['farmindo.customer:_all'];
    } else if (customer) {
      customerMapped = customer.map((cust) => {
        return `farmindo.customer:${cust.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope,...customerMapped];

    let storeMapped = [];
    if (store && store[0].value === "_all") {
      storeMapped = ['farmindo.store:_all'];
    } else if (store) {
      storeMapped = store.map((str) => {
        return `farmindo.store:${str.value}`
      });
    }
    dataGroupAdd.scope = [...dataGroupAdd.scope, ...storeMapped];
    dispatch(editDataGroupAction({data: dataGroupAdd, id}))
      .then((withValue) => {
        if (withValue.error && withValue.error.message.toLowerCase() === 'rejected') {
          onError && onError(withValue.payload);
        } else {
          onSuccess && onSuccess(withValue.payload);
        }
      })
      .finally(onFinally)

  }, [dispatch, onSuccess, onError, onFinally]);

  return {
    handleOnSubmit,
    loading: is_loading,
    is_submitting,
    error,
    response
  }
}
