import { Box, Switch, Text } from "@chakra-ui/react";
import style from "./CustomToggle.module.css";

const CustomToggle = ({id,onChange, isChecked, namespace,textChecked ="", textUnChecked = "" , size,value} = {})  => {
  return (
    <Box
      pt={2}
      display="flex"
      flexDirection="row"
      gap="20px"
      justifyContent="flex-start"
    >
      <Switch
        colorScheme="customgreen"
        isChecked={isChecked}
        onChange={onChange}
        size={size}
        value={value}
        id={id}
      />
      <Text>{isChecked ? textChecked : textUnChecked }</Text>
    </Box>
  );
};

export default CustomToggle;
