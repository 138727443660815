import { defaultPermissionSysUserAllPermission } from "../../../constant/permissionConstant";
import ObjectUtil from "../../../utils/objectUtil";
import PermissionParser from "../PermissionParser";

export default class RoleSysuserPermissionParser extends PermissionParser {
  
  parse(permissionFromApi, permissionObject) {
    if (ObjectUtil.isEmpty(permissionFromApi) || !Array.isArray(permissionFromApi)) return {};

    return super.parse(permissionFromApi, permissionObject);
  }

  stringify(permissionObject,prefix) {
    return super.stringify(permissionObject,prefix);
  }
}
