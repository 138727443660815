import { Accordion, Box } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import CheckboxTree from "../../../../../components/CheckboxTree";
import ClickableTab from "../../../../../components/ClickableTab";
// import ELEMENT_ID from '../../../../../constant/elementIdConstant';
import { Redirect, useLocation } from "react-router-dom";
import { SavedSuccess, UnSuccess } from "../../../../../components/icons";
import ModalCustom from "../../../../../components/ModalCustom";
import {
  CheckStatus,
  defaultPermissionSysUserAdminSeller,
  defaultPermissionSysUserSeller,
} from "../../../../../constant/permissionConstant";
import { buttonVariant } from "../../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../../constant/routeConstant";
import {
  toggleExpandedNodeTree,
  toggleNodeTree,
} from "../../../../../helpers/componentHelper";
import {
  Permission,
  PermissionPrefix,
  //   FarmindoRolePermissionParser,
  RoleSysuserPermissionParser,
} from "../../../../../lib/permission";
import {
  roleAction,
  updateRole,
} from "../../../../../services/main/roleReducer";
// import RoleSysuserPermissionParser from "../../../../../lib/permission/parser/RoleSysuserPermissionParser";
// const permission = new Permission(
//   defaultPermissionSysUserAllPermission,
//   new RoleSysuserPermissionParser()
// );

const permissionParser = new RoleSysuserPermissionParser();
const BodyPermission3 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [treeRole, setTreeRole] = useState([]);
  const { detail_for_edit: data } = useSelector((state) => state.role);
  const { index_page } = useSelector((state) => state.role);
  const { role } = useSelector((state) => state.role.detail_role);
  const { principal_id } = useSelector((state) => state.auth);

  //modal
  const [dataEdited, setDataEdited] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setDataEdited(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setDataEdited(false);
    setModalOpen(false);
  };

  const handleChangeCheckboxTree = (
    event,
    node,
    permission,
    mainIndex,
    arr,
    isAllChecked
  ) => {
    const isChecked = permission.status === CheckStatus.CHECKED;
    const newTreeRole = toggleNodeTree(
      node,
      treeRole,
      permission,
      !isChecked,
      isAllChecked
    );
    setTreeRole(newTreeRole);
  };

  const handleChangeExpandedTree = (event, node) => {
    const newExpandedTree = toggleExpandedNodeTree(node, treeRole);
    setTreeRole(newExpandedTree);
  };
  const [tabPermissionIndex, setTabPermissionIndex] = useState(0);

  const permissionTabs = useMemo(() => {
    return treeRole?.map((node) => t(`ROLE:MENU_${node.label.toUpperCase()}`));
  }, [treeRole, t]);
  const selectedPermissionTab = useMemo(
    () => treeRole?.filter((_, index) => index === tabPermissionIndex),
    [treeRole, tabPermissionIndex]
  );
  const handleOnFormSubmit = () => {
    // roleAdminPermission.set(treeRole);
    // const permission
    let permissionForSubmit = null;
    let permissionPrefix = PermissionPrefix.SYSUSER_SELLER;
    const ROLE = {
      SYSUSER_SELLER: 14,
      SYSUSER_ADMIN: 13,
    };
    switch (data?.level) {
      case ROLE.SYSUSER_SELLER:
        permissionForSubmit = new Permission(
          defaultPermissionSysUserSeller,
          permissionParser
        );
        permissionPrefix = PermissionPrefix.SYSUSER_SELLER;
        break;
      case ROLE.SYSUSER_ADMIN:
        permissionForSubmit = new Permission(
          defaultPermissionSysUserAdminSeller,
          permissionParser
        );
        permissionPrefix = PermissionPrefix.SYSUSER_ADMIN;
        break;
      default:
    }
    permissionForSubmit.set(treeRole);
    const permissionObj = permissionForSubmit.stringify(permissionPrefix);

    const object = {
      id: location?.state.id,
      payload: {
        role_id: role?.role_id,
        description: role?.description,
        level: role?.level,
        status: role?.status,
        account_id: principal_id,
        permission: [
          ...permissionObj,
          "insert:insert",
          "update:update",
          "delete:delete",
          "view:view",
        ],
        updated_at: data?.updated_at,
      },
    };
    // onSubmit && onSubmit(allFormData);
    dispatch(updateRole(object)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.response.nexsoft.payload.status.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const toPreviousPage = () => {
    dispatch(roleAction.setCurrPage(t("ROLE:FIELD_ROLE")));
    dispatch(roleAction.setIndexPage(index_page - 1));
  };
  useEffect(() => {
    if (data?.permission) {
      let permission = null;
      if (data.level === 14) {
        permission = new Permission(
          defaultPermissionSysUserSeller,
          new RoleSysuserPermissionParser()
        );
      } else if (data.level === 13) {
        permission = new Permission(
          defaultPermissionSysUserAdminSeller,
          new RoleSysuserPermissionParser()
        );
      }

      permission?.parse(data?.permission);
      setTreeRole(permission?.clone());
    }
  }, [data, dispatch]);
  return dataEdited ? (
    <Redirect to={ROUTES.ROLE.LIST} />
  ) : (
    <>
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        borderRadius="10px"
      >
        <ClickableTab
          tabs={permissionTabs}
          onChangeTab={setTabPermissionIndex}
          activeTab={tabPermissionIndex}
        />

        <Box marginTop="4">
          <Accordion>
            <CheckboxTree
              translateAfterfix="ROLE_PERMISSION_"
              translateButtonAfterfix="TOGGLE_"
              translatePrefix={"ROLE"}
              data={selectedPermissionTab}
              onChange={handleChangeCheckboxTree}
              onNodeClick={handleChangeExpandedTree}
              translate={t}
            />
          </Accordion>
        </Box>
      </Box>
      <Box display="flex" marginTop={4} float="right">
        <ButtonCustom
          colorScheme="blue"
          variant={buttonVariant.outline}
          marginRight={2}
          width="9.375rem"
          onClick={toPreviousPage}
          text={t("USER:BUTTON_PREVIOUS")}
        />
        <ButtonPrimary
          text={t("COMMON:BUTTON_SAVE")}
          width="9.375rem"
          onClick={handleOnFormSubmit}
        />
      </Box>
      <ModalCustom
        message={resp}
        isOpen={modalOpen}
        onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
        icon={err ? <UnSuccess /> : <SavedSuccess />}
      />
    </>
  );
};

export default BodyPermission3;
