import { Box, Divider, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../components/HeadTitle";
import {
  BlueEditIcon, DeleteSuccess, RedDeleteIcon, UnSuccess
} from "../../../../components/icons";
import {
  PRIMARY_COLOR,
  WHITE_COLOR
} from "../../../../constant/propertiesConstant";
import { ROUTES } from "../../../../constant/routeConstant";
import BodyRole from "./body/BodyRole";
// import { DeleteRoles } from "../../../../services/main/role/roleDelete";
import moment from "moment/moment";
import BackToList from "../../../../components/BackToList";
import ModalConfirm from "../../../../components/ModalConfirm";
import ModalCustom from "../../../../components/ModalCustom";
import { deleteRoles, detailRoles } from "../../../../services/main/roleReducer";
import BodyPermission2 from "./body/BodyPermission2";
const DetailRole = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useSelector((state) => state.auth);
  const getDetailRole = useSelector((state) => state.role.detail_role.role);
  let param = useParams();
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState("Role");
  const [updatedAt, setUpdatedAt] = useState("");
  const changePageToPermission = () => {
    setCurrentPage("Permission");
  };
  const changePageToRole = () => {
    setCurrentPage("Role");
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [modalConfirmClose, setModalConfirmClose] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const payload = { updated_at: updatedAt };

  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmClose(true);
    setModalConfirmOpen(false);
  };
  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      roleId: location.state.id,
    };
    dispatch(deleteRoles(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };

  const fetchData = async () => {
    dispatch(detailRoles(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(
          response.payload.response.nexsoft.payload.data.content.updated_at
        );
      } else {
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return isDeleted ? (
    <Redirect to={ROUTES.ROLE.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("ROLE:DETAIL_ROLE")}
        subtitle={
          <>
            <span style={{ color: "#003C7C" }}>{t("COMMON:HOME")}</span> -{" "}
            <span style={{ color: "#003C7C" }}>{t("ROLE:TITLE_LIST")} </span> -{" "}
            {t("ROLE:DETAIL_ROLE")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES.ROLE.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES.ROLE.LIST}
        name={getDetailRole?.created_name}
        date={moment(getDetailRole?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(getDetailRole?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={getDetailRole?.updated_name}
      />

      {/* body */}
      <Box backgroundColor="white" minHeight="300px" padding={4}>
        <Stack direction="row" marginBottom={4}>
          <ButtonCustom
            text={t("ROLE:FIELD_ROLE")}
            backgroundcolor={
              currentPage === "Role" ? PRIMARY_COLOR : WHITE_COLOR
            }
            variant={currentPage === "Role" ? "solid" : "outline"}
            colorScheme="blue"
            borderColor={PRIMARY_COLOR}
            onClick={changePageToRole}
          />
          <Divider orientation="vertical" borderColor="blackAlpha.500" />
          <ButtonCustom
            text={t("ROLE:FIELD_PERMISSION")}
            backgroundcolor={
              currentPage === "Permission" ? PRIMARY_COLOR : WHITE_COLOR
            }
            variant={currentPage === "Permission" ? "solid" : "outline"}
            colorScheme="blue"
            onClick={changePageToPermission}
          />
        </Stack>

        {/* content of body  */}
        {/* {currentPage === "Role" ? <BodyRole /> : <BodyPermission />} */}
        {/* {currentPage === "Role" ? <BodyRole /> : <CopyBodyPermission />} */}
        {currentPage === "Role" ? <BodyRole /> : <BodyPermission2 />}
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
      />
    </Box>
  );
};
export default DetailRole;
