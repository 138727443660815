const MapperUtil = {
  StoreOption: (data) => ({ value: data.id, label: `${data.store_name} - ${data.store_code}`}),
  WarehouseOption: (data) => ({ value: data.id, label: `${data.warehouse_name} - ${data.warehouse_code}` }),
  DistributorOption: (data) => ({ value: data.id, label: `${data.name} - ${data.distributor_code}` }),
  CustomerOption: (data) => ({ value: data.id, label: `${data.name} - ${data.customer_code}` }),
  StoreMapping: (data) => ({
    value: data.store_id,
    label: data.store_name,
    code: data.store_code,
  }),
};

export default MapperUtil;
