import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_URL, API_VERSION, API_PREFIX } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  list_option: [],
  isOption: false,
  list_role_option: [],
  role_id: null,
  farmindo_account: null,
  description: null,
  level: 13,
  status_role: "A",
  status: "",
  rolesss: {},
  table_loading: false,
  detail_role: {
    role: {},
    permission: {},
  },
  detail_for_edit: {},
  count_data: 0,
  count_data_option: 0,
  curr_page: "",
  index_page: 1,
  updated_at: "",
  detail_loading: false,
};

let axiosHeader = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    Authorization: null,
  },
};

export const getListRole = createAsyncThunk(
  "getListRole",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListRoleOption = createAsyncThunk(
  "getListRoleOption",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getInitiateListRole = createAsyncThunk(
  "getInitiateListRole",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role/initiate/list`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token ,params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailRoles = createAsyncThunk(
  "detailRoles",
  async (roleId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role/${roleId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doPost(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
export const addRoles = createAsyncThunk(
  "addRoles",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role`;
    const { auth } = getState();
    const token = auth.token;
    const { role } = getState();
    const body = {
      role_id: object.role_id,
      level: object.level ? object.level : Number(role.level),
      description: object.description,
      status: object.status ? object.status : role.status_role,
    };
    try {
      const response = await baseApi.doPost(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        // message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const updateRole = createAsyncThunk(
  "updateRole",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role/${object.id}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, object.payload);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
export const deleteRoles = createAsyncThunk(
  "deleteRoles",
  async (object, { getState, rejectWithValue }) => {
    object = {
      updated_at: object.updated_at,
      roleId: object.roleId,
    };
    let result = { ok: false, response: null };

    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/role/${object.roleId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);
const role = createSlice({
  name: "role",
  initialState: INITIAL_STATE,
  reducers: {
    setIsOption(state, action) {
      state.isOption = action.payload ?? true;
    },
    setReset(state, action) {
      state.status = null;
      state.errorNotification = null;
      state.successNotification = null;
    },
    setCountData(state, action) {
      state.count_data = action.payload;
    },
    setCountDataOption(state, action) {
      state.count_data_option = action.payload;
    },
    setUpdatedAt(state, action) {
      state.updated_at = action.payload;
    },
    setCurrPage(state, action) {
      state.curr_page = action.payload;
    },
    setIndexPage(state, action) {
      state.index_page = action.payload;
    },
    setDetailRoleRole(state, action) {
      state.detail_role.role = action.payload;
    },
    setDetailRolePermission(state, action) {
      state.detail_role.permission = action.payload;
    },
    setDetailRoleForEdit(state, action) {
      state.detail_for_edit = action.payload;
    },
    resetReduxRole() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListRole.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListRole.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading = false;
    },
    [getListRole.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
      state.table_loading = false;
    },
    [getListRoleOption.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListRoleOption.fulfilled]: (state, action) => {
      state.status = "success";
      // let listResponse = action.payload.response || []
      // if (state.isOption) {
      //   state.list_option = [...state.list_option, ...listResponse ];
      // } else {
      //   state.list_option = listResponse;
      // }
      state.list_option = action.payload.response;
      state.table_loading = false;
    },
    [getListRoleOption.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
      state.table_loading = false;
    },
    [getInitiateListRole.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListRole.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListRole.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [detailRoles.pending]: (state, action) => {
      state.status = "loading";
      state.detail_loading = true;
    },
    [detailRoles.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_loading = false;
      state.detail_role.role =
        action.payload.response.nexsoft.payload.data.content;
      state.detail_role.permission =
        action.payload.response.nexsoft.payload.data.content.permission;
    },
    [detailRoles.rejected]: (state, action) => {
      state.status = "failed";
      state.detail_loading = false;
    },
    
    [addRoles.pending]: (state, action) => {
      state.status = "loading";
    },
    [addRoles.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addRoles.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [deleteRoles.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteRoles.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteRoles.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    ["auth/resetRedux"]: (state, action) => {
      return INITIAL_STATE;
    },
  },
});

export const roleAction = role.actions;

export default role.reducer;
