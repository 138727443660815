import { Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import {
  HOVER_PRIMARY,
  PRIMARY_COLOR,
} from "../../constant/propertiesConstant";

const ButtonPrimary = (props) => {
  return props.link ? (
    <Link to={props.link}>
      <Button
        leftIcon={props.icon}
        background={PRIMARY_COLOR}
        _hover={{ background: HOVER_PRIMARY }}
        _focus={{
          ringColor: "hwb(211deg 0% 51%)",
        }}
        color="white"
        isLoading={props.loading}
        {...props}
      >
        {props.text}
      </Button>
    </Link>
  ) : (
    <Button
      leftIcon={props.icon}
      background={PRIMARY_COLOR}
      _hover={{ background: HOVER_PRIMARY }}
      color="white"
      _focus={{
        ringColor: "hwb(211deg 0% 51%)",
      }}
      isLoading={props.loading}
      {...props}
    >
      {props.text}
    </Button>
  );
};

export default ButtonPrimary;
