import { Box, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import BackToList from "../../../../../components/BackToList";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  BackIcon,
  BlueEditIcon,
  DetailIcon,
  RedDeleteIcon,
} from "../../../../../components/icons";
import { PRIMARY_COLOR } from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_BUYER,
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import { TranslateDay } from "../../../../../function/TranslateDay";
import { detailBKLSummary } from "../../../../../services/main/bklSummaryReducer";
import TransactionDetailBKLSummary from "./DetailTransactionDetailBKLSummary";

// import { detailMasterBKL_SUMMARY } from "../../../../../services/main/bklSummaryReducer";

const DetailBKLSummary = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [dataBKL_SUMMARY, setDataBKL_SUMMARY] = useState([]); 

  const fetchData = () => {
    dispatch(detailBKLSummary(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setDataBKL_SUMMARY(response.payload.response);
      } else {
        setDataBKL_SUMMARY([]);
      }
    });
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box p={4}>
      <HeadTitle
        title={t("BKL_SUMMARY:TITLE_LIST")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("COMMON:FIELD_HOME")}
            </span>
            {" - "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_SUMMARY:FIELD_TRANSACTION")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_SUMMARY:FIELD_PO")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("BKL_SUMMARY:TITLE_LIST")}
            </span>
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.BKL_SUMMARY.LIST}
        name={dataBKL_SUMMARY?.created_name}
        date={moment(dataBKL_SUMMARY?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(dataBKL_SUMMARY?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={dataBKL_SUMMARY?.updated_name}
      />
      {/* White Box section */}
      <Box
        backgroundColor="white"
        minHeight="277px"
        padding={4}
        display="flex"
        justifyContent="space-between"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box className="column-po">
          {/* PO Number */}
          <Box className="list-po">
            <Box>{t("BKL_SUMMARY:FIELD_PO_NUMBER")}</Box>
            <Box>
              <Text>
                <b>
                  {dataBKL_SUMMARY?.po_number === ""
                    ? "-"
                    : dataBKL_SUMMARY?.po_number}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Distributor*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("BKL_SUMMARY:FIELD_DISTRIBUTOR")}</Text>
              <Text>
                <b>
                  {dataBKL_SUMMARY?.distributor_name === ""
                    ? "-"
                    : dataBKL_SUMMARY?.distributor_name}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Store*/}
          <Box className="list-po">
            <Box>
              <Text>{t("BKL_SUMMARY:FIELD_STORE")}</Text>
            </Box>
            <Text>
              <b>
                {dataBKL_SUMMARY?.store_name === ""
                  ? "-"
                  : dataBKL_SUMMARY?.store_name}
              </b>
            </Text>
          </Box>
          {/* Customer Code*/}
          <Box className="list-po">
            <Box>
              <Text>{t("BKL_SUMMARY:FIELD_CUSTOMER_CODE")}</Text>
            </Box>
            <Text>
              <b>
                {dataBKL_SUMMARY?.customer_code === ""
                  ? "-"
                  : dataBKL_SUMMARY?.customer_code}
              </b>
            </Text>
          </Box>
        </Box>
        {/* Middle side */}
        <Box className="column-po">
          {/* Sap Id*/}
          <Box className="list-po">
            <Box>
              <Text>{t("BKL_SUMMARY:FIELD_SAP_ID")}</Text>
            </Box>
            <Text>
              <b>
                {dataBKL_SUMMARY?.sap_id === "" ? "-" : dataBKL_SUMMARY?.sap_id}
              </b>
            </Text>
          </Box>
          {/* Customer Name*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("BKL_SUMMARY:FIELD_CUSTOMER_NAME")}</Text>
            </Box>
            <Text>
              <b>
                {dataBKL_SUMMARY?.customer_name === ""
                  ? "-"
                  : dataBKL_SUMMARY?.customer_name}
              </b>
            </Text>
          </Box>
          {/* Depo*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("BKL_SUMMARY:FIELD_DEPO")}</Text>
            </Box>
            <Text>
              <b>
                {dataBKL_SUMMARY?.depo_cover === ""
                  ? "-"
                  : dataBKL_SUMMARY?.depo_cover}
              </b>
            </Text>
          </Box>
          {/* PO Created*/}
          <Box className="list-po">
            <Box width="10rem">
              <Text>{t("BKL_SUMMARY:FIELD_PO_CREATED")}</Text>
            </Box>
            <Text>
              <b>
                {moment(dataBKL_SUMMARY?.transaction_date).format(
                  "DD/MM/YYYY"
                ) === ""
                  ? "-"
                  : moment(dataBKL_SUMMARY?.transaction_date).format(
                      "DD/MM/YYYY"
                    )}
              </b>
            </Text>
          </Box>
        </Box>

        {/* Right side */}
        <Box className="column-po">
          {/* PO Expired */}
          <Box className="list-po">
            <Box>{t("BKL_SUMMARY:FIELD_PO_EXPIRED")}</Box>
            <Box>
              <Text display="flex">
                <b>
                  {moment(dataBKL_SUMMARY?.expired_date).format(
                    "DD/MM/YYYY"
                  ) === ""
                    ? "-"
                    : moment(dataBKL_SUMMARY?.expired_date).format(
                        "DD/MM/YYYY"
                      )}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Delivery */}
          <Box className="list-po">
            <Box>{t("BKL_SUMMARY:FIELD_DELIVERY")}</Box>
            <Box>
              <Text display="flex">
                <b>{dataBKL_SUMMARY?.day}</b>
              </Text>
            </Box>
          </Box>
          {/* Week */}
          <Box className="list-po">
            <Box>{t("BKL_SUMMARY:FIELD_WEEK")}</Box>
            <Box>
              <Text display="flex">
                <b>{dataBKL_SUMMARY?.week} </b>
              </Text>
            </Box>
          </Box>
          {/* Swap  */}
          <Box className="list-po">
            <Box>{t("BKL_SUMMARY:FIELD_SWAP")}</Box>
            <Box>
              <Text display="flex">
                <b> {location?.state?.swap ? location?.state?.swap : "-"} </b>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Transaction Detail */}
      <TransactionDetailBKLSummary />
    </Box>
  );
};

export default DetailBKLSummary;
