import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import i18next from "i18next";
import * as baseApi from "../../../api/baseApi";
import {
  API_URL,
  API_VERSION,
  API_PREFIX,
} from "../../../constant/apiConstant";
import { userCategoryType } from "../../../constant/propertiesConstant";
import useCheckUserCategory from "../../../hooks/useCheckUserCategory";
import { checkUsersCategory } from "../../util";

const INITIAL_STATE = {
  list_unmapped: [],
  printed_unmapped: [],
  selectedRowKeys: [],
  table_loading: false,
};

export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListDistributorDDS = createAsyncThunk(
  "getListDistributorDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListUnmappedStore = createAsyncThunk(
  "unmapped/store",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const token = auth.token;  
    const isSeller=checkUsersCategory(auth.user_category,userCategoryType.PRINCIPAL)
    const url = isSeller
      ? `${API_URL}/${API_VERSION}/${API_PREFIX}/seller/unmapped/store`
      : `${API_URL}/${API_VERSION}/${API_PREFIX}/unmapped/store`;

    try {
      const response = await baseApi.doGet(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
      return rejectWithValue(error);
    }
    return result;
  }
);

export const getInitListUnmappedStore = createAsyncThunk(
  "unmapped/store/initiateGetList",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const token = auth.token;
    const isSeller=checkUsersCategory(auth.user_category,userCategoryType.PRINCIPAL)
    const url = isSeller
      ? `${API_URL}/${API_VERSION}/${API_PREFIX}/seller/unmapped/store/initiateGetList`
      : `${API_URL}/${API_VERSION}/${API_PREFIX}/unmapped/store/initiateGetList`;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const downloadUnmappedStore = createAsyncThunk(
  "unmapped/store/download",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const { auth } = getState();
    const userCategory = auth.user_category
    const token = auth.token;
    const url = userCategory === userCategoryType.PRINCIPAL
      ? `${API_URL}/${API_VERSION}/${API_PREFIX}/seller/unmapped/store/download`
      : `${API_URL}/${API_VERSION}/${API_PREFIX}/unmapped/store/download`;
    try {
      const response = await baseApi.doPostResponseBlob(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const unmappedStore = createSlice({
  name: "createMonitoringStore",
  initialState: INITIAL_STATE,
  reducers: {
    setSingleCheck(state, action) {
      // if (!payload || typeof payload !== 'number')
      let tempSelected = [...state.selectedRowKeys];
      const isExist = state.selectedRowKeys?.find(
        (data) => data.id === action.payload.id
      );
      if (isExist) {
        tempSelected = tempSelected.filter(
          (data) => data.id !== action.payload.id
        );
        state.selectedRowKeys = tempSelected;
        return;
      }
      state.selectedRowKeys = [...state.selectedRowKeys, action.payload];
    },
    setAllCheck(state, action) {
      let tempSelected = [...state.selectedRowKeys];
      let tempsId = action.payload;
      let isExist = false;

      for (const data of tempsId) {
        isExist = false;
        for (const existingId of state.selectedRowKeys) {
          if (data.id === existingId.id) {
            isExist = true;
            break;
          }
        }
        if (!isExist) break;
      }

      if (isExist) {
        for (const data of tempsId) {
          tempSelected = tempSelected.filter((item) => item.id !== data.id);
          state.selectedRowKeys = tempSelected;
        }
      } else {
        for (const data of tempsId) {
          const isExistOnRedux = state.selectedRowKeys?.find(
            (item) => item.id === data.id
          );
          if (!isExistOnRedux) {
            state.selectedRowKeys = [...state.selectedRowKeys, data];
          }
        }
        // state.selectedRowKeys = Array.from(new Set([...state.selectedRowKeys, ...tempsId]));
      }
    },
    resetReduxUnmapped() {
      return INITIAL_STATE;
    },
    setTableLoadingFalse(state) {
      state.table_loading = false;
    },
    setTableLoadingTrue(state) {
      state.table_loading = true;
    },
    resetCBX(state) {
      state.selectedRowKeys = [];
    },
  },
  extraReducers: {
    [getListUnmappedStore.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListUnmappedStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
      state.list_unmapped = action.payload.response;
    },
    [getListUnmappedStore.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [downloadUnmappedStore.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [downloadUnmappedStore.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = false;
    },
    [downloadUnmappedStore.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
  },
});

export const unmappedStoreReducerAction = unmappedStore.actions;

export default unmappedStore.reducer;
