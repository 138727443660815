import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  table_loading: false,
  status: "",
  detail_product: [],
};
export const getListProductPrincipal = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/product-principal/get",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getInitListProductPrincipal = createAsyncThunk(
  "http://sandbox-web.farmindo.com:4080/v1/farmindo/product-principal/initiateGetList",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      result.ok = true;
      result.response = response.data.nexsoft.payload.data.content;
    } catch (error) {
      if (error.response) {
        result.ok = false;
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const detailProductPrincipal = createAsyncThunk(
  "Get Detail Product Principal",
  async (productId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal/${productId[0]}-${productId[1]}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const addProductPrincipal = createAsyncThunk(
  "addProductPrincipal",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
        message.error(error.response.data.nexsoft.payload.status.message);
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const updateProductPrincipal = createAsyncThunk(
  "updateProductPrincipal",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false };
    const urlUserSeller = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal/${object.id}-${object.principalId}`;
    const urlUserBuyerCs = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal/${object.id}-${object.principalId}`;
    const { auth } = getState();
    const token = auth.token;
    const role = auth.role;
    let url;
    if (role.toLowerCase().includes("principal")) {
      url = urlUserSeller;
    } else {
      url = urlUserBuyerCs;
    }
    try {
      const response = await baseApi.doPut(url, token, object.values);
      if (response) {
        result.ok = true;
        result.response = response;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

export const deleteProductPrincipal = createAsyncThunk(
  "Delete Product Principal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/product-principal/${params.Id[0]}-${params.Id[1]}`;
    const { auth } = getState();
    const token = auth.token;
    const body = {
      updated_at: params.updated_at,
    };
    try {
      const response = await baseApi.doDelete(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }

    return result;
  }
);

const productPrincipal = createSlice({
  name: "productPrincipal",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxProductPrincipal() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    [getListProductPrincipal.pending]: (state, action) => {
      state.table_loading = true;
      state.status = "loading";
    },
    [getListProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.table_loading = "false";
      state.list = action.payload.response;
    },
    [getListProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
    [detailProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.detail_product = action.payload.response;
    },
    [detailProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
    },
    [addProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [addProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.update_store = action.payload.response;
     },
    [updateProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
    [deleteProductPrincipal.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteProductPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteProductPrincipal.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },
  },
});
export const productPrincipalAction = productPrincipal.actions;

export default productPrincipal.reducer;
