import {
  Box,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {useSelector } from "react-redux";
const BodyAccessSetting = () => {
  const { t } = useTranslation();
  const getUserProfile = useSelector((state)=>state.user.detail_user.access_setting);
  return (
    <Box padding={1}>
      {/* User Category */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_USER_CATEGORY")}</Box>
        <Box>
          <Text>
            <b>{getUserProfile.user_category ? getUserProfile.user_category : "user category" }</b>
          </Text>
        </Box>
      </Box>
      {/* Company Profile */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_COMPANY_PROFILE")}</Box>
        <Box>
          <Text>
            <b>{getUserProfile.company_profile_name ? getUserProfile.company_profile_name : "company profile"}</b>
          </Text>
        </Box>
      </Box>
      {/* Role */}
      <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_ROLE")}</Box>
        <Box>
          <Text>
            <b>{getUserProfile.role_name ? getUserProfile.role_name : "role name"}</b>
          </Text>
        </Box>
      </Box>
       {/* Data Group */}
       <Box padding={1} marginBottom={2}>
        <Box>{t("USER:FIELD_DATA_GROUP")}</Box>
        <Box>
          <Text>
            <b>{getUserProfile.group_name ? getUserProfile.group_name : "group name"}</b>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
export default BodyAccessSetting;
