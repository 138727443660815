import { Box, Stack, Tag, Select as SelectChakra } from "@chakra-ui/react";
import { Table, Select, Pagination } from "antd";
import React, { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  detailInitiateListBKLSummary,
  detailListBKLSummary,
} from "../../../../../services/main/bklSummaryReducer";
import {
  getInitTransactionDetailPO,
  getListTransactionDetailPO,
} from "../../../../../services/main/processedPOReducer";
import StringUtil from "../../../../../utils/stringUtil";
const TransactionDetailBKLSummary = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { table_loading_detail } = useSelector((state) => state.bklSummary);

  const [total, setTotal] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [order, setOrder] = useState("");
  //pagination
  const handleTableChange = useCallback((pagination, filter, sorter) => {
    setOrder(StringUtil.formatOrder(sorter));
  });

  const params = {
    page,
    limit,
    order,
    // filter
  };
  const object = {
    params: params,
    id: location.state.id,
  };
  const fetchDataDetail = () => {
    dispatch(detailListBKLSummary(object)).then((response) => {
      setData(response.payload.response);
    });
  };

  useEffect(() => {
    dispatch(detailInitiateListBKLSummary(object)).then((response) => {
      setTotal(response.payload.response.count_data);
    });

    fetchDataDetail();
  }, [page, limit, total, order, dispatch]);

  const handleSelectPagination = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const dataSource =
    data?.length > 0 &&
    data?.map((item, index) => ({
      key: index,
      product_code: item.product_code,
      product_name: item.product_name,
      barcode: item.barcode ? item.barcode : "-",
      qty: item.qty,
      price: item.price,
      uom: item.uom,
      status: item.status,
      net_amount: item.net_amount,
    }));

  const columns = [
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PRODUCT_CODE")}
        </Box>
      ),
      dataIndex: "product_code",
      key: "product_code",
      sorter: (a, b) => a.product_code.localeCompare(b.product_code),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PRODUCT_NAME")}
        </Box>
      ),
      dataIndex: "product_name",
      key: "product_name",
      sorter: (a, b) => a.product_name.localeCompare(b.product_name),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_BARCODE")}
        </Box>
      ),
      dataIndex: "barcode",
      key: "barcode",
      sorter: (a, b) => a.barcode.localeCompare(b.barcode),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_QTY")}
        </Box>
      ),
      dataIndex: "qty",
      key: "qty",
      sorter: (a, b) => a.qty.localeCompare(b.qty),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_UOM")}
        </Box>
      ),
      dataIndex: "uom",
      key: "uom",
      sorter: (a, b) => a.uom.localeCompare(b.uom),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_PRICE")}
        </Box>
      ),
      dataIndex: "price",
      key: "price",
      // sorter: (a, b) => a.price - b.price,
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: (
        <Box display="flex" cursor="pointer">
          {t("BKL_SUMMARY:FIELD_NET_AMOUNT")}
        </Box>
      ),
      dataIndex: "net_amount",
      key: "net_amount",
    },
  ];
  return (
    <>
      <Box pt={4} pb={4}>
        <HeadTitle titleSection={t("BKL_SUMMARY:TRANSACTION_DETAIL")} />
      </Box>
      {/* table body */}
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        onChange={handleTableChange}
        rowKey="id"
        loading={table_loading_detail}
        size="small"
        footer={() => (
          <Box
            backgroundColor="#E5EBF1"
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Box display="flex" alignItems="center" marginX={2}>
              <Box marginX={4}>Row Per Page</Box>
              <SelectChakra
                border="1px solid"
                borderColor="#CCCCCC"
                size="sm"
                width={20}
                _focus={{ outline: "none" }}
                value={limit}
                // onChange={handleSelectPagination}
                onChange={(value, opt) => handleSelectPagination(value, opt)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </SelectChakra>
            </Box>
            <Box>
              <Pagination
                size="small"
                current={page}
                total={total}
                onChange={(value) => setPage(value)}
                showSizeChanger={false}
                pageSize={limit}
              />
            </Box>
          </Box>
        )}
      />
    </>
  );
};

export default TransactionDetailBKLSummary;
