import { Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { useCallback, useMemo, forwardRef } from "react";
import { useTranslation } from "react-i18next"
import { SearchIcon } from "../icons";
import ClearIconButton from '../button/ClearIconButton';

const InputSearch = forwardRef(({
  id = 'input-search',
  name = 'input-search',
  value,
  type = 'text',
  placeholder,
  onChange,
  onSearch,
  onClear,
  searchBy,
} = {}, ref) => {
  const { t } = useTranslation();

  const searchPlaceholder = useMemo(() => {
    return placeholder ? placeholder : searchBy ? `${t('COMMON:SEARCH_BY')} ${searchBy}` : '';
  }, [t, placeholder, searchBy]);

  const handleOnChange = useCallback((event) => {;
    onChange && onChange(event);
    onSearch && onSearch(event.target?.value ?? '', event.target?.name);
  }, [onChange, onSearch]);

  const handleOnClear = useCallback(() => {
    onClear && onClear();
    onSearch && onSearch('', name)
  }, [onClear, onSearch, name]);


  return ( 
    <InputGroup ref={ref}>
      <Input
        name={name}
        value={value}
        onChange={handleOnChange}
        id={id}
        type={type}
        backgroundColor='white'
        placeholder={searchPlaceholder}
        _placeholder={{
          lineHeight: 'sm',
          fontSize: 'sm',
        }}
      />
      <InputRightElement>
          {value ? <ClearIconButton onClick={handleOnClear}  /> : <SearchIcon />}
      </InputRightElement>
    </InputGroup>
  )
});

export default InputSearch;