import { CloseButton, IconButton } from '@chakra-ui/react';
import { GRAY_COLOR, GRAY_DISABLED } from '../../constant/propertiesConstant';


/**
 * 
 * @param {import('@chakra-ui/react').IconButtonProps} props 
 * @returns 
 */
const ClearIconButton = (props) => {
  return (
    <IconButton
      padding='0'
      variant='ghost'
      minWidth='20px'
      width='20px'
      height='20px'
      borderRadius='25px'
      color={GRAY_COLOR}
      _hover={{
        color: GRAY_DISABLED
      }}
      icon={<CloseButton />}
      {...props}
    />
  );
};

export default ClearIconButton;