export const REGEX_CONSTANT = Object.freeze({
  USERNAME: /^[a-z][a-z0-9_.]{6,}/,

  PASSWORD:
    /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$`~!^&*()_=+{}:;'<,.>/%-]).{8,})/,

  // NAMA : /(^[A-Z])((([ ][A-Z]))?[a-z]?)*$/,

  NAME: /^[A-Z][a-z]+(([ ][A-Z][a-z])?[a-z]*)*$/,

  DISTRIBUTOR_NAME: /^[A-Z][a-zA-Z0-9 ]*(?<=[a-zA-Z0-9])[ ](?=[A-Z])[a-zA-Z0-9 ]*$/,


  VALID_IMAGE_JPEG: /(jpe?g)/g,

  VALID_IMAGE: /(jpg|jpeg|gif|png)/g,

  ALPHANUMERIC_WITH_SPACE: /^[\w ]*$/,

  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  PERMISSION: /(^[a-z]+[a-z.]+[a-z]+[:]+[a-z-]+$)/,

  DATA_SCOPE: /(^[a-z]+[a-z.]+[a-z]+[:]+[a-z-]+$)/,

  INTEGER: /^[0-9]*$/,

  PHONE_COUNTRY_CODE: /^[0-9]{1,3}$/,

  PHONE_NUMBER: /^[0-9]*$/,

  FLOAT: /^[+-]?\d+(\.\d+)?$/,

  STRING_CHAR: /^[a-zA-Z\s./-]+$/,

  NIK: /[0-9]{16}/,

  NPWP: /[0-9]{2}[.][0-9]{3}[.][0-9]{3}[.][0-9][-][0-9]{3}[.][0-9]{3}/,

  NIK_OR_NPWP : /(\b\d{2}\.\d{3}\.\d{3}\.\d{1}-\d{3}\.\d{3}\b)|(\b\d{2}\d{2}\d{2}\d{2}\d{2}\d{2}\d{2}\d{2}\b)/ ,

  INFORMATION_KEY: /^[a-z0-9_.-]+$/,

  INFORMATION_VALUE: /^[a-z0-9_.-]+$/,

  SPACE: /^(?!\s*$)[a-zA-Z0-9-!()@#$%%_+={}:;"'`~,.?^&*]*$/,

  DESCRIPTION: /^[a-zA-Z0-9 ]+$/,

  DESCRIPTION_EXCEPT_UNDERSCORE: /^[\w\s-]+$/,

  DESCRIPTION_DATA_GROUP : /^[a-zA-Z0-9 _-]*$/, 

  PRODUCT_CATEGORY_NAME : /^[a-zA-Z0-9 _-]*$/,

  PRODUCT_CATEGORY : /^[a-zA-Z0-9 _-]+$/,
  // regex tidak boleh mengandung spesial karakter kecuali underscore

  // for role //
  ROLE_ID: /^[A-Z][a-z0-9_]+(?:[A-Z][a-z0-9]+)*$/,

  // for data group //
  GROUP_ID: /^[A-Z][a-z0-9_]+(?:[A-Z][a-z0-9]+)*$/,

  // MAX_CHAR_12:/^{0,12}+$/

  STORE_ID: /^[a-zA-Z0-9_.-]*$/,

  STORE_CODE: /^[A-Z0-9]*$/,

  STORE_NAME: /(^[A-Z])((([_-][a-zA-Z0-9])|([ -][a-zA-Z0-9]))?[a-z0-9]?)*$/,

  // product principal

  PRODUCT_CODE: /^[a-zA-Z0-9]+[a-zA-Z0-9_-]+[a-zA-Z0-9]{1,35}$/,

  PRODUCT_NAME: /^[a-zA-Z0-9 _-]*$/,

  BARCODE: /^[a-zA-Z0-9]{0,30}$/,

  UOM: /^[a-zA-Z]+$/,

  ORDER_UNIT: /^[a-zA-Z]+$/,

  //
  CODE_SUPPLIER: /[a-zA-Z0-9.,_ ()'"#$%^&+=!@-]$/,
  SALESMAN_CODE: /^[a-zA-Z0-9 _-]*$/,
  SALESMAN_NAME: /^[A-Z0-9][a-zA-Z0-9 _.-]*$/,
  SPECIAL_CHAR: /^[a-zA-Z0-9 _-]*$/,
  MULTI_EMAIL:
    /(([^<>()[\]\\., :\s@"]+(\.[^<>()[\]\\., :\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z;]{2,}))$/,
});
