import { message } from "antd";
import i18next from "i18next";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as baseApi from "../../api/baseApi";
import { API_PREFIX, API_URL, API_VERSION } from "../../constant/apiConstant";

const INITIAL_STATE = {
  list: [],
  table_loading: false,
  list_distributor: [],
  list_customer: [],
  list_store: [],
  list_principal: [],
  status: "",
};

export const getInitiateListBKLMapping = createAsyncThunk(
  "getInitiateListBKLMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping/initiateGetList`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListBKLMapping = createAsyncThunk(
  "getListBKLMapping",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const detailBKLMapping = createAsyncThunk(
  "detailBKLMapping",
  async (bklMappingId, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping/${bklMappingId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const addBklMapping = createAsyncThunk(
  "addBklMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPost(url, token, object);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const editBKLMapping = createAsyncThunk(
  "editBKLMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      customer_id: object?.customer_id.value,
      store_id: object?.store_id.value,
      distributor_id: object?.distributor_id,
      area_code: object?.area_code,
      distribution_center: object?.distribution_center,
      store_sap_id: object?.store_sap_id,
      store_sap_name: object?.store_sap_name,
      frequency: object?.frequency,
      open_po: object?.open_po.map((val) => {
        return val;
      }),
      jwk_depo: object?.jwk_depo.map((val) => {
        return val;
      }),
      id_depo: object?.id_depo,
      status: object?.status,
      updated_at: object?.updated_at,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping/${object.bklId}`;
    const { auth } = getState();
    const token = auth.token;

    try {
      const response = await baseApi.doPut(url, token, body);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
        result.message = response.data.nexsoft.payload.status.message;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        // message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const deleteBklMapping = createAsyncThunk(
  "deleteBKLMapping",
  async (object, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const body = {
      updated_at: object.updated_at,
      bklId: object.bklId,
    };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/bkl-mapping/${body.bklId}`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doDelete(url, token, object.updated_at);
      if (response) {
        result.ok = true;
        result.response = response.data;
      }
      return result;
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

export const getListPrincipal = createAsyncThunk(
  "getListPrincipal",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/principal`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListStore = createAsyncThunk(
  "getListStore",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListStoreDDS = createAsyncThunk(
  "getListStoreDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/store-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomer = createAsyncThunk(
  "getListCustomer",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListCustomerDDS = createAsyncThunk(
  "getListCustomerDDS",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/customer-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);
export const getListDistributor = createAsyncThunk(
  "getListDistributor",
  async (params, { getState, rejectWithValue }) => {
    let result = { ok: false, response: null };
    const url = `${API_URL}/${API_VERSION}/${API_PREFIX}/distributor-dds`;
    const { auth } = getState();
    const token = auth.token;
    try {
      const response = await baseApi.doGet(url, token, params);
      if (response) {
        result.ok = true;
        result.response = response.data.nexsoft.payload.data.content;
      }
    } catch (error) {
      if (error.response) {
        result.response = error.response;
      } else {
        message.error(i18next.t("COMMON:ERROR_NETWORK"));
      }
    }
    return result;
  }
);

const bklMapping = createSlice({
  name: "bklMapping",
  initialState: INITIAL_STATE,
  reducers: {
    resetReduxBklMapping() {
      return INITIAL_STATE;
    },
  },
  extraReducers: {
    // bkl mapping
    [getInitiateListBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [getInitiateListBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [getInitiateListBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [getListBKLMapping.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
      state.list = action.payload.response;
      state.table_loading = false;
    },
    [getListBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    [detailBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [detailBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [detailBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
    },

    [addBklMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [addBklMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [addBklMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [editBKLMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [editBKLMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [editBKLMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    [deleteBklMapping.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteBklMapping.fulfilled]: (state, action) => {
      state.status = "success";
    },
    [deleteBklMapping.rejected]: (state, action) => {
      state.status = "failed";
      state.errorNotification = action.payload;
    },

    // principal
    [getListPrincipal.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListPrincipal.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_principal = action.payload.response;
      state.table_loading = false;
    },
    [getListPrincipal.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    // customer
    [getListCustomer.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListCustomer.fulfilled]: (state, action) => {
      state.status = "success";
      state.list_customer = action.payload.response;
      state.table_loading = false;
    },
    [getListCustomer.rejected]: (state, action) => {
      state.table_loading = false;
      state.status = "failed";
    },

    // store
    [getListStore.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListStore.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list_store = action.payload.response;
    },
    [getListStore.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },

    // distributor
    [getListDistributor.pending]: (state, action) => {
      state.status = "loading";
      state.table_loading = true;
    },
    [getListDistributor.fulfilled]: (state, action) => {
      state.table_loading = false;
      state.status = "success";
      state.list_distributor = action.payload.response;
    },
    [getListDistributor.rejected]: (state, action) => {
      state.status = "failed";
      state.table_loading = false;
    },
  },
});

export const bklMappingAction = bklMapping.actions;

export default bklMapping.reducer;
