import {
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  GRAY_COLOR,
} from "../../../../../constant/propertiesConstant";
import {
  ROUTES_USER_SELLER,
} from "../../../../../constant/routeConstant";
import {
  inputTheme,
  multiSelectTheme,
} from "../../../../../constant/themeConstant";
import ValidationError from "../../../../../components/validationErrorMessage/ValidationError";
import {
  selectBklTypes,
  selectStatus,
} from "../../../../../constant/selectOptConstant";
import {
  addProductBklMapping,
  getListBklCSM,
  getListCustomer,
  getListDistributor,
  getListPrincipal,
  getListProductCustomer,
  getListProductPrincipal,
} from "../../../../../services/main/productBklMappingReducer";
import DropDownCustom from "../../../../../components/DropDownCustom";
import { useFormik } from "formik";
import StringUtil from "../../../../../utils/stringUtil";
import { addProductBklMappingSchema } from "./productBklMappingSchema";
import { Redirect } from "react-router-dom";
import ModalCustom from "../../../../../components/ModalCustom";
import { DeleteSuccess, SavedSuccess } from "../../../../../components/icons";
import { ID_PRODUCT_BKL_MAPPING } from "../../../../../constant/idConstant";

const AddProductBKLMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const principal_name = useSelector((state) => state.auth.company_name);
  const principalId = useSelector((state) => state.auth.principal_id);
  const [newDataAdded, setNewDataAdded] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // page and limit
  const [pagePrincipal, setPagePrincipal] = useState(1);
  const [limitPrincipal, setLimitPrincipal] = useState(10);
  const [pageUnlimited, setPageUnlimited] = useState(-99);
  const [limitUnlimited, setLimitUnlimited] = useState(-99);
  const [pageCustomer, setPageCustomer] = useState(1);
  const [limitCustomer, setLimitCustomer] = useState(10);
  const [pageStore, setPageStore] = useState(1);
  const [limitStore, setLimitStore] = useState(10);
  const [pageProductPrincipal, setPageProductPrincipal] = useState(1);
  const [limitProductPrincipal, setLimitProductPrincipal] = useState(10);
  const [pageProductCustomer, setPageProductCustomer] = useState(1);
  const [limitProductCustomer, setLimitProductCustomer] = useState(10);
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor, setLimitDistributor] = useState(10);

  // Code(for disabled input)
  const [productCategoryCode, setproductCategoryCode] = useState("");
  const [principalCode, setPrincipalCode] = useState("");
  const [distributorCode, setDistributorCode] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [storeCode, setStoreCode] = useState([]);
  const [storeSupplierCode, setStoreSupplierCode] = useState("");
  const [productPrincipalCode, setProductPrincipalCode] = useState([]);
  const [productCustomerCode, setProductCustomerCode] = useState("");
  const [distributorId, setDistributorId] = useState([]);
  const [idCust, setIdCust] = useState(null);

  const handleClear = (name) => {
    switch (name) {
      case "customer_id":
        setCustomerCode("");
        setFieldValue("customer_id", null);
        setFieldValue("distributor_id", null);
        setDistributorCode("");
        setFieldValue("store_id", []);
        setStoreCode([]);
        setFieldValue("product_customer_id", null);
        setProductCustomerCode("");
        break;
      case "distributor_id":
        setFieldValue("distributor_id", null);
        setDistributorCode("");
        setFieldValue("store_id", []);
        setStoreCode([]);
        break;
      case "store_id":
        setFieldValue("store_id", []);
        setStoreCode([]);
        break;
      case "product_principal_id":
        setFieldValue("product_principal_code", "");
        break;
      case "product_customer_id":
        setFieldValue("product_customer_code", "");
        break;
      default:
        break;
    }
  };

  const handleChangeSelect = (name, value, option) => {
    switch (name) {
      case "customer_id":
        setFieldValue("customer_id", value);
        setIdCust(value);
        setCustomerCode(option.code);
        setFieldValue("distributor_id", null);
        setDistributorCode("");
        //clear store
        setFieldValue("store_id", []);
        setStoreCode([]);
        // clear product customer
        setFieldValue("product_customer_id", null);
        setProductCustomerCode("");
        break;
      case "distributor_id":
        setFieldValue("distributor_id", value);
        setDistributorCode(option.code);
        //clear store
        setFieldValue("store_id", []);
        setStoreCode([]);
        break;
      case "store_id":
        setFieldValue("store_id", value);
        setStoreCode(
          option?.map((val) => {
            return val.code;
          })
        );
        break;
      case "product_principal_id":
        setFieldValue("product_principal_id", value);
        setProductPrincipalCode(option?.code);
        // setProductPrincipalCode(
        //   option?.map((val) => {
        //     return val.code;
        //   })
        // );
        break;
      case "product_customer_id":
        setFieldValue("product_customer_id", value);
        setProductCustomerCode(option.code);
        break;
      case "type":
        setFieldValue("type", value);
        break;
      case "status":
        setFieldValue("status", value);
        break;
      default:
        break;
    }
  };
  /// fungsi untuk modal

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setNewDataAdded(true);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setNewDataAdded(false);
    setModalOpen(false);
  };

  // principal
  const [dataPrincipal, setDataPrincipal] = useState([]);
  const fetchDataPrincipal = () => {
    const page = pagePrincipal;
    const limit = limitPrincipal;
    const params = { page, limit };
    dispatch(getListPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataPrincipal([...dataPrincipal, ...resp]);
      setPrincipalCode(resp[0]?.principal_code);
    });
  };
  //distributor
  const [dataDistributor, setDataDistributor] = useState([]);
  const fetchDataDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (!values?.customer_id) {
      setDataDistributor(null);
    } else {
      dispatch(getListBklCSM(params)).then((response) => {
        setDataDistributor(response.payload.response);
      });
    }
  };
  const fetchDataDistributorOld = () => {
    const page = pageUnlimited;
    const limit = limitUnlimited;
    const updated_at_start = "2000-01-01T00:00:00Z";
    const params = { page, limit, updated_at_start };
    dispatch(getListDistributor(params)).then((response) => {
      setDataDistributor(response.payload.response);
    });
  };
  // customer
  const [dataCustomer, setDataCustomer] = useState([]);
  const fetchDataCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const params = { page, limit };
    dispatch(getListCustomer(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const [filterCustomer, setfilterCustomer] = useState("");
  const fetchSearchCustomer = () => {
    const page = pageCustomer;
    const limit = limitCustomer;
    const filter = `customer_name like ${filterCustomer}`;
    const params =
      filterCustomer === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListCustomer(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageCustomer(1);
      setDataCustomer(response.payload.response);
    });
  };
  const handleScrollCustomer = () => {
    setPageCustomer(pageCustomer + 1);
  };
  // store
  const [dataStore, setDataStore] = useState([]);
  const [filterStore, setfilterStore] = useState("");
  const fetchDataStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${principalId}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
    });
    const params = values?.distributor_id
      ? { page, limit, filter }
      : { page, limit };
    if (!values?.distributor_id) {
      setDataStore([]);
    } else {
      dispatch(getListBklCSM(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataStore([...dataStore, ...resp]);
      });
    }
  };
  const fetchSearchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      store_name: `like ${filterStore ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
      principal_id: `eq ${principalId}`,
      distributor_id: `eq ${values?.distributor_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (!values?.distributor_id) {
      setDataStore([]);
    } else {
      dispatch(getListBklCSM(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageStore(1);
        setDataStore(response.payload.response);
      });
    }
  };

  const handleScrollStore = () => {
    setPageStore(pageStore + 1);
  };
  // Product Principal
  const [dataProductPrincipal, setDataProductPrincipal] = useState([]);
  const fetchDataProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const params = { page, limit };
    dispatch(getListProductPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataProductPrincipal([...dataProductPrincipal, ...resp]);
    });
  };
  const [filterProductPrincipal, setfilterProductPrincipal] = useState("");
  const fetchSearchProductPrincipal = () => {
    const page = pageProductPrincipal;
    const limit = limitProductPrincipal;
    const filter = `product_code like ${filterProductPrincipal}`;
    const params =
      filterProductPrincipal === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListProductPrincipal(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageProductPrincipal(1);
      setDataProductPrincipal(response.payload.response);
    });
  };

  const handleScrollProductPrincipal = () => {
    setPageProductPrincipal(pageProductPrincipal + 1);
  };
  // Product Customer
  const [dataProductCustomer, setDataProductCustomer] = useState([]);
  const fetchDataProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      principal_id: `eq ${values?.principal_id ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    // : null;
    const params = { page, limit, filter };
    if (idCust === null) {
      setDataProductCustomer([]);
    } else {
      dispatch(getListProductCustomer(params)).then((response) => {
        if (!response.payload.ok) return;
        const resp = response.payload.response;
        if (!resp) return;
        setDataProductCustomer(resp);
      });
    }
  };
  const [filterProductCustomer, setfilterProductCustomer] = useState("");
  const fetchSearchProductCustomer = () => {
    const page = pageProductCustomer;
    const limit = limitProductCustomer;
    const filter = StringUtil.formatFilter({
      product_code: `like ${filterProductCustomer ?? ""}`,
      customer_id: `eq ${values?.customer_id ?? ""}`,
    });
    const params = { page, limit, filter };
    if (idCust === null) {
      setDataProductCustomer([]);
    } else {
      dispatch(getListProductCustomer(params)).then((response) => {
        if (!response.payload.ok) return;
        setPageProductCustomer(1);
        setDataProductCustomer(response.payload.response);
      });
    }
  };

  const handleScrollProductCustomer = () => {
    setPageProductCustomer(pageProductCustomer + 1);
  };
  const onSubmit = () => {
    const payload = {
      ...values,
      product_principal_id: [values?.product_principal_id],
    };
    dispatch(addProductBklMapping(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      customer_id: null,
      distributor_id: null,
      store_id: [],
      product_principal_id: null,
      product_customer_id: null,
      type: "",
      status: "",
    },
    validationSchema: addProductBklMappingSchema(t),
    onSubmit,
  });
  // principal
  useEffect(() => {
    fetchDataPrincipal();
  }, []);
  // distributor
  useEffect(() => {
    fetchDataDistributor();
  }, [pageDistributor, values?.customer_id]);

  // customer
  useEffect(() => {
    fetchDataCustomer();
  }, [pageCustomer]);
  useEffect(() => {
    fetchSearchCustomer();
  }, [filterCustomer]);

  // store
  useEffect(() => {
    fetchDataStore();
  }, [pageStore, values?.distributor_id]);

  useEffect(() => {
    fetchSearchStore();
  }, [filterStore]);

  // product principal
  useEffect(() => {
    fetchDataProductPrincipal();
  }, [pageProductPrincipal]);
  useEffect(() => {
    fetchSearchProductPrincipal();
  }, [filterProductPrincipal]);

  // product customer
  useEffect(() => {
    fetchDataProductCustomer();
  }, [pageProductCustomer, idCust]);

  useEffect(() => {
    fetchSearchProductCustomer();
  }, [filterProductCustomer]);
  return newDataAdded ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_BKL_MAPPING.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_BKL_MAPPING:ADD_PRODUCT_BKL_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_BKL_MAPPING:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_BKL_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_BKL_MAPPING:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_BKL_MAPPING:ADD_PRODUCT_BKL_MAPPING")}
          </>
        }
      />
      {/* white box  */}
      <form onSubmit={handleSubmit}>
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* principal code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_PRODUCT_BKL_MAPPING.ADD.PRINCIPAL_CODE}
                  style={inputTheme}
                  disabled
                  value={principalCode ? principalCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.CUSTOMER_CODE}
                  disabled
                  value={customerCode ? customerCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* distributor code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_MAPPING:FIELD_DISTRIBUTOR_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.DISTRIBUTOR_CODE}
                  disabled
                  value={distributorCode ? distributorCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* store code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_BKL_MAPPING:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.STORE_CODE}
                  disabled
                  value={storeCode ? storeCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* principal product code */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  style={inputTheme}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.PRINCIPAL_PRODUCT_CODE}
                  disabled
                  value={productPrincipalCode ? productPrincipalCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer product code  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_PRODUCT_CODE")}
                </Text>
              </Box>
              <Box width="12rem">
                <Input
                  type="text"
                  id={ID_PRODUCT_BKL_MAPPING.ADD.CUSTOMER_PRODUCT_CODE}
                  style={inputTheme}
                  disabled
                  value={productCustomerCode ? productCustomerCode : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>

            {/* bkl type */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_BKL_MAPPING:FIELD_BKL_TYPE")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  name="type"
                  id={ID_PRODUCT_BKL_MAPPING.ADD.BKL_TYPE}
                  value={values?.type}
                  onBlur={handleBlur("type")}
                  onChange={(value, opt) =>
                    handleChangeSelect("type", value, opt)
                  }
                >
                  {selectBklTypes.map((value, index) => {
                    return (
                      <Select.Option value={value} key={index.toString()}>
                        {value}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.type && touched.type && (
                  <ValidationError text={errors.type} />
                )}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="80px">
                  {t("BKL_MAPPING:FIELD_STATUS")}
                </Text>
              </Box>
              <Box>
                <Select
                  style={inputTheme}
                  size="large"
                  id={ID_PRODUCT_BKL_MAPPING.ADD.STATUS}
                  name="status"
                  value={values?.status}
                  onBlur={handleBlur("status")}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                >
                  {selectStatus.map((value, index) => {
                    return (
                      <Select.Option value={value.value} key={index}>
                        {t(value.label)}
                      </Select.Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}

          {/* right side */}
          <Box padding={1} marginRight={8}>
            {/* Principal Name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_NAME")}
                </Text>
              </Box>
              <Box>
                <Input
                  type="text"
                  id={ID_PRODUCT_BKL_MAPPING.ADD.PRINCIPAL_NAME}
                  style={inputTheme}
                  disabled
                  value={principal_name ? principal_name : ""}
                  background={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* customer  name */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  size="large"
                  onPopupScroll={handleScrollCustomer}
                  allowClear
                  value={values?.customer_id}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.CUSTOMER_NAME}
                  name="customer_id"
                  onClear={() => handleClear("customer_id")}
                  onBlur={handleBlur("customer_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataCustomer &&
                    dataCustomer?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          name={data.name}
                          code={data.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* Distributor Name*/}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text>{t("PRODUCT_BKL_MAPPING:FIELD_DISTRIBUTOR_NAME")}</Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  onClear={() => handleClear("distributor_id")}
                  size="large"
                  allowClear
                  value={values?.distributor_id}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.DISTRIBUTOR_NAME}
                  name="distributor_id"
                  onBlur={handleBlur("distributor_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                >
                  {dataDistributor &&
                    dataDistributor?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.distributor_id}
                          name={data.distributor_name}
                          code={data.distributor_code}
                          fulldata={data}
                        >
                          {data.distributor_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id && touched.distributor_id && (
                  <ValidationError text={errors.distributor_id} />
                )}
              </Box>
            </Box>
            {/* Store Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_BKL_MAPPING:FIELD_STORE_NAME")}
                </Text>
              </Box>
              <Box width="16rem">
                <Select
                  style={multiSelectTheme}
                  mode="multiple"
                  onClear={() => handleClear("store_id")}
                  showArrow
                  size="large"
                  maxTagCount={1}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.STORE_NAME}
                  onPopupScroll={handleScrollStore}
                  allowClear
                  value={values?.store_id}
                  name="store_id"
                  onBlur={handleBlur("store_id")}
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterStore(e.target.value)}
                    />
                  )}
                >
                  {dataStore &&
                    dataStore?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index.toString()}
                          value={data.store_id}
                          name={data.store_name}
                          code={data.store_code}
                        >
                          {data.store_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Principal Product Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_BKL_MAPPING:FIELD_PRINCIPAL_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  size="large"
                  // mode="multiple"
                  id={ID_PRODUCT_BKL_MAPPING.ADD.PRINCIPAL_PRODUCT_NAME}
                  showArrow
                  maxTagCount={"responsive"}
                  name="product_principal_id"
                  onPopupScroll={handleScrollProductPrincipal}
                  style={inputTheme}
                  value={values?.product_principal_id}
                  allowClear
                  onChange={(value, opt) =>
                    handleChangeSelect("product_principal_id", value, opt)
                  }
                  onBlur={handleBlur("product_principal_id")}
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductPrincipal(e.target.value)}
                    />
                  )}
                >
                  {dataProductPrincipal &&
                    dataProductPrincipal?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          principal={data.principal_name}
                          code={data.product_code}
                          title={data.product_code}
                        >
                          {data.product_code}
                          {" - "}
                          {data.product_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_principal_id &&
                  touched.product_principal_id && (
                    <ValidationError text={errors.product_principal_id} />
                  )}
              </Box>
            </Box>
            {/* Customer Product Name  */}
            <Box padding={1} display="flex">
              <Box width="10rem">
                <Text overflowWrap="break-word" width="120px">
                  {t("PRODUCT_BKL_MAPPING:FIELD_CUSTOMER_PRODUCT_NAME")}
                </Text>
              </Box>
              <Box width="12rem">
                <Select
                  style={inputTheme}
                  value={values?.product_customer_id}
                  id={ID_PRODUCT_BKL_MAPPING.ADD.CUSTOMER_PRODUCT_NAME}
                  onPopupScroll={handleScrollProductCustomer}
                  size="large"
                  allowClear
                  onBlur={handleBlur("product_customer_id")}
                  name="product_customer_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("product_customer_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      search={(e) => setfilterProductCustomer(e.target.value)}
                    />
                  )}
                >
                  {dataProductCustomer &&
                    dataProductCustomer?.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          code={data.product_code}
                          title={data.product_code}
                        >
                          {data.product_code}
                          {" - "}
                          {data.product_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.product_customer_id && touched.product_customer_id && (
                  <ValidationError text={errors.product_customer_id} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_SELLER.PRODUCT_BKL_MAPPING.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_PRODUCT_BKL_MAPPING.ADD.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_PRODUCT_BKL_MAPPING.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <DeleteSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddProductBKLMapping;
