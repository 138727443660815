import { Box, Text } from "@chakra-ui/react";
import { Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DropDownCustom from "../../../../components/DropDownCustom";
import HeadTitle from "../../../../components/HeadTitle";
import ModalCustom from "../../../../components/ModalCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import FieldMultiSelectSearchStoreProductMapping from "../../../../components/field/FieldMultiSelectSearchStoreProductMapping";
import { SavedSuccess, UnSuccess } from "../../../../components/icons";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { PRIMARY_COLOR } from "../../../../constant/propertiesConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import {
  getListCSMForDistributor,
  processCopyMapping,
} from "../../../../services/main/copyMappingReducer";
import { getListDistributorDDS } from "../../../../services/main/distributorReducer";
import StringUtil from "../../../../utils/stringUtil";
import "./ListCopyMapping.css";
import { addCopyMappingSchema } from "./copyMappingSchema";

const ListCopyMapping = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const principalId = useSelector((state) => state.auth.principal_id);
  const loading = useSelector((state)=>state.copyMapping.proccess_mapping_loading);

  // modal
  const [dataChanged, setDataChanged] = useState(false);
  const [err, setErr] = useState(false);
  const [resp, setResp] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // filter distributor
  const [pageDistributor, setPageDistributor] = useState(1);
  const [limitDistributor] = useState(10);
  const [filterDistributor, setFilterDistributor] = useState("");
  const [dataDistributor, setDataDistributor] = useState("");

  // store
  const [dataStore, setDataStore] = useState([]);

  const [pageStore, setPageStore] = useState(1);
  const [limitStore] = useState(10);
  const [filterStoreName, setfilterStoreName] = useState("");

  // Definisikan variabel refs untuk melacak perubahan inputStore
  const prevInputStore = useRef(filterStoreName);
  const isSearchAction = useRef(false);

  // hande change select
  const handleChangeSelect = (name, value, option) => {
    if (name === "distributor_id") {
      setFieldValue("distributor_id", value);
      setFieldValue("store_id", null);
      setFieldValue("store_id_target", []);
      setPageStore(1);
      setfilterStoreName("");
    } else if (name === "store_id") {
      setFieldValue("store_id", value);
    } else if (name === "store_id_target") {
      setFieldValue("store_id_target", value);
    }
    setFieldValue(name, value);
  };

  // handle submit
  const onSubmit = () => {
    const payload = {
      ...values,
      store_id_target:
        values?.store_id_target[0]?.value === "_all"
          ? [-1]
          : values?.store_id_target?.map((val) => {
              return val?.value;
            }),
    };
    dispatch(processCopyMapping(payload)).then((response) => {
      if (response.payload.ok) {
        setModalOpen(true);
        setErr(false);
        setResp(response.payload.message);
      } else {
        setModalOpen(true);
        setErr(true);
        setResp(response.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };

  // formik
  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      principal_id: principalId,
      distributor_id: null,
      store_id: null,
      store_id_target: [],
      // store : null
    },
    validationSchema: addCopyMappingSchema(t),
    isInitialValid: true,
    enableReinitialize: true,
    onSubmit,
  });
  const prevIdDistributorValue = useRef(values?.distributor_id);
  const resetPage = () => {
    setFieldValue("distributor_id", null);
    setFieldValue("store_id", null);
    setFieldValue("store_id_target", []);
    setPageDistributor(1);
    setPageStore(1);
    setFilterDistributor("");
    setfilterStoreName("");
  };

  // fungsi untuk DDS filter distributor
  const fetchDistributor = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    // const filter = `distributor_name like ${filterDistributor}`;
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      setDataDistributor([...dataDistributor, ...resp]);
    });
  };

  const fetchDistributorSearch = () => {
    const page = pageDistributor;
    const limit = limitDistributor;
    const filter = StringUtil.formatFilter({
      distributor_name: `like ${filterDistributor}`,
    });
    const params =
      filterDistributor === "" ? { page, limit } : { page, limit, filter };
    dispatch(getListDistributorDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPageDistributor(1);
      setDataDistributor(response.payload.response);
    });
  };

  const handleScrollDistributor = (event) => {
    setPageDistributor(pageDistributor + 1);
  };

  // store
  const fetchStore = () => {
    const page = pageStore;
    const limit = limitStore;
    const filter = StringUtil.formatFilter({
      "store_code||store_name": `like ${filterStoreName}`,
      distributor_id: `eq ${values?.distributor_id}`,
    });
    const params = { page, limit, filter };
    dispatch(getListCSMForDistributor(params)).then((response) => {
      if (!response.payload.ok) return;
      // setPageStore(1);

      const resp = response.payload.response;
      if (!resp) return;

      if (isSearchAction.current) {
        setDataStore(resp);
        prevIdDistributorValue.current = values?.distributor_id;
        prevInputStore.current = filterStoreName;
      } else if (
        values?.distributor_id !== prevIdDistributorValue.current ||
        filterStoreName !== prevInputStore.current
      ) {
        setDataStore(resp);
        prevIdDistributorValue.current = values?.distributor_id;
        prevInputStore.current = filterStoreName;
      } else {
        setDataStore([...dataStore, ...resp]);
      }
    });
  };

  const handleScrollStore = () => {
    isSearchAction.current = false;
    setPageStore(pageStore + 1);
  };

  const handleSearchStore = (event) =>{
    isSearchAction.current = true;
    setfilterStoreName(event);
    setPageStore(1);
  }

  // klik ketika berhasil
  const clickOkOnModalSuccess = () => {
    setDataChanged(!dataChanged);
    setModalOpen(false);
  };
  // klik ketika gagal
  const clickOkOnModalFailed = () => {
    setModalOpen(false);
  };

  /// use effect Distributor
  useEffect(() => {
    fetchDistributor();
  }, [pageDistributor]);
  useEffect(() => {
    fetchDistributorSearch();
  }, [filterDistributor]);

  // use effect Store
  useEffect(() => {
    fetchStore();
  }, [pageStore, values?.distributor_id]);

  useEffect(() => {
    if (isSearchAction.current) {
      fetchStore();
    }
  }, [filterStoreName]);

  // use effect reset page
  useEffect(() => {
    resetPage();
    fetchDistributor();
    fetchStore();
  }, [dataChanged]);

  return (
    <Box padding={4}>
      <HeadTitle
        title={t("COMMON:COPY_MAPPING")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("COMMON:FIELD_HOME")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_MAPPING:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_MAPPING:SUBTITLE_3")}
            </span>{" "}
            - {t("COMMON:COPY_MAPPING")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* white box  */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* left side */}
          <Box padding={1}>
            {/* By Distributor */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("DISTRIBUTOR:FIELD_DISTRIBUTOR")}</Text>
              </Box>
              <Box className="my-select" pb={1}>
                <Select
                  // id={ID_PROCESS_PO.LIST.DISTRIBUTOR}
                  style={{ width: 250, borderRadius: "6px" }}
                  value={values?.distributor_id}
                  onPopupScroll={handleScrollDistributor}
                  onChange={(value, opt) =>
                    handleChangeSelect("distributor_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      value={filterDistributor}
                      menu={menu}
                      search={(e) => setFilterDistributor(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear
                  //   placeholder={t("COMMON:SELECT_DISTRIBUTOR")}
                  size="large"
                >
                  {dataDistributor &&
                    dataDistributor.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.id}
                          title={data.name}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.distributor_id &&
                  touched.distributor_id &&
                  !dataChanged && (
                    <ValidationError text={errors.distributor_id} />
                  )}
              </Box>
            </Box>
            {/* store name */}
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text>{t("COMMON:FIELD_STORE")}</Text>
              </Box>
              <Box className="my-select-container" pb={1}>
                <Select
                  //   id={ID_CSM_MASTER.ADD.STORE_NAME}
                  style={inputTheme}
                  disabled={!values.distributor_id}
                  size="large"
                  value={values?.store_id}
                  onPopupScroll={handleScrollStore}
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id", value, opt)
                  }
                  dropdownRender={(menu) => (
                    <DropDownCustom
                      menu={menu}
                      value={filterStoreName}
                      search={(e) => handleSearchStore(e.target.value)}
                    />
                  )}
                  showSearch={false}
                  allowClear="true"
                >
                  {dataStore &&
                    dataStore.map((data, index) => {
                      return (
                        <Select.Option
                          key={index}
                          value={data.store_id}
                          title={data.store_name}
                        >
                          {data.store_code} - {data.store_name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && !dataChanged && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            <Box padding={1} display="flex">
              <Box width="10rem" className="form-title">
                <Text overflowWrap="break-word" width="120px">
                  {t("COMMON:FIELD_COPY_TO_STORE")}
                </Text>
              </Box>
              <Box >
                <FieldMultiSelectSearchStoreProductMapping
                  name="store_id_target"
                  isDisabled={!values?.distributor_id}
                  values={values?.store_id_target}
                  onChange={(value, opt) =>
                    handleChangeSelect("store_id_target", value, opt)
                  }
                  initialFilter={{
                    distributor_id: `eq ${values?.distributor_id ?? ""}`,
                  }}
                />
                {errors.store_id_target && touched.store_id && (
                  <ValidationError text={errors.store_id_target} />
                )}
              </Box>
            </Box>
          </Box>
          {/* end of left side  */}
        </Box>
        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonPrimary
            text={t("COMMON:BUTTON_PROCESS")}
            width="9.375rem"
            type="submit"
            loading={loading}
            disabled={loading}
            //   id={ID_PRODUCT_MAPPING_HO.ADD.BTN_SAVE}
          />
          <ModalCustom
            message={resp}
            isOpen={modalOpen}
            onOk={err ? clickOkOnModalFailed : clickOkOnModalSuccess}
            icon={err ? <UnSuccess /> : <SavedSuccess />}
          />
        </Box>
      </form>
    </Box>
  );
};

export default ListCopyMapping;
