import { Box, Text } from "@chakra-ui/react";
import { Input, Select } from "antd";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ButtonCustom from "../../../../components/button/ButtonCustom";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import HeadTitle from "../../../../components/HeadTitle";
import { DeleteSuccess, SavedSuccess } from "../../../../components/icons";
import ModalCustom from "../../../../components/ModalCustom";
import ValidationError from "../../../../components/validationErrorMessage/ValidationError";
import { ID_STORE_MASTER } from "../../../../constant/idConstant";
import {
  GRAY_COLOR,
  PRIMARY_COLOR,
} from "../../../../constant/propertiesConstant";
import { ROUTES_USER_BUYER } from "../../../../constant/routeConstant";
import { selectStatus } from "../../../../constant/selectOptConstant";
import { inputTheme } from "../../../../constant/themeConstant";
import {
  detailMasterStore,
  getListCustomerDDS,
  updateMasterStore,
} from "../../../../services/main/masterStoreReducer";
import {
  getListCountry,
  getListDistrict,
  getListIsland,
  getListPostalCode,
  getListProvince,
  getListSubDistrict,
  getListUrbanVillage,
} from "../../../../services/main/regionalReducer";
import { updateSchema } from "./storeSchema";

const EditStore = () => {
  const detailData = useSelector((data) => data.masterStore.detail_store);

  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [filterCustomerName, setfilterCustomerName] = useState("");
  const [dataCustomer, setDataCustomer] = useState([]);

  const { Option } = Select;

  const [modalOpen, setModalOpen] = useState(false);
  const [response, setResponse] = useState("");
  const [err, setErr] = useState(false);

  const history = useHistory();

  // submit data
  const onSubmit = (values, action) => {
    if (typeof values.customer_id === "object") {
      values.customer_id = values.customer_id.id;
    }
    dispatch(updateMasterStore(values)).then((resp) => {
      if (resp.payload.ok) {
        setModalOpen(true);
        setResponse(resp.payload.response.nexsoft.payload.status.message);
        setErr(false);
        action.resetForm();
      } else {
        setModalOpen(true);
        setResponse(resp.payload.response.data.nexsoft.payload.status.message);
        setErr(true);
      }
    });
  };

  // fungsi modal
  const modalSuccess = () => {
    history.push(ROUTES_USER_BUYER.STORE.LIST);
  };
  const modalFailed = () => {
    setModalOpen(false);
  };

  const fetchData = async () => {
    // local variable page dan limit, sementara masih menggunakan global variable,
    // dan masih aman, jika ditemukan bug, pisahkan page dan limit khusus untuk Customer,
    // dan gunakan variable lokal dibawah ini
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(10);
    dispatch(detailMasterStore(location.state));
    const params = { page, limit };
    dispatch(getListCountry(params));
    dispatch(getListDistrict(params));
    dispatch(getListIsland(params));
    dispatch(getListSubDistrict(params));
    dispatch(getListUrbanVillage(params));
    dispatch(getListProvince(params));
    dispatch(getListPostalCode(params));
    dispatch(getListCustomerDDS(params));
  };

  // parameter customer
  const filter = `customer_name like ${filterCustomerName}`;
  const params =
    filterCustomerName === "" ? { page, limit } : { page, limit, filter };

  // find customer code

  const [idCustomer, setIdCustomer] = useState("");
  const dataCustomerCode = useMemo(() => {
    // find customer code by selected customer id
    return dataCustomer?.find((search) => search.id === idCustomer);
  }, [idCustomer]);

  const fetchCustomer = () => {
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      const resp = response.payload.response;
      if (!resp) return;
      setDataCustomer([...dataCustomer, ...resp]);
    });
  };
  const fetchCustomerSearch = () => {
    dispatch(getListCustomerDDS(params)).then((response) => {
      if (!response.payload.ok) return;
      setPage(1);
      setDataCustomer(response.payload.response);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchCustomer();
  }, [page]);
  useEffect(() => {
    fetchCustomerSearch();
  }, [filterCustomerName]);

  // fetch data from redux
  const { list_country } = useSelector((state) => state.regional);
  const { list_island } = useSelector((state) => state.regional);
  const { list_province } = useSelector((state) => state.regional);
  const { list_district } = useSelector((state) => state.regional);
  const { list_sub_district } = useSelector((state) => state.regional);
  const { list_urban_village } = useSelector((state) => state.regional);
  const { list_postal_code } = useSelector((state) => state.regional);

  const initialDataStore = useMemo(() => {
    const [countryCode, phoneNumber] =
      detailData?.store?.phone?.split("-") ?? [];
    return {
      store_id: detailData?.store.store_id ?? "",
      store_code: detailData.store.store_code ?? "",
      store_name: detailData.store.store_name ?? "",
      customer_id: {
        id: detailData?.store.customer_id,
        value: detailData?.store.customer_name,
        code: detailData?.store.customer_code,
      },
      customerCode : detailData?.store.customer_code,
      customerId : detailData?.store.customer_id,
      phone_country_code: countryCode ?? "",
      phone: phoneNumber ?? "",
      status: detailData.store.status ?? "",
      address_1: detailData.store.address_1 ?? "",
      address_2: detailData.store.address_2 ?? "",
      address_3: detailData.store.address_3 ?? "",
      country_id: detailData.store.country_id ?? "",
      country_name: detailData.store.country_name ?? "",
      island_id: detailData.store.island_id ?? "",
      island_name: detailData.store.island_name ?? "",
      province_id: detailData.store.province_id ?? "",
      province_name: detailData.store.province_name ?? "",
      district_id: detailData.store.district_id ?? "",
      district_name: detailData.store.district_name ?? "",
      sub_district_id: detailData.store.sub_district_id ?? "",
      sub_district_name: detailData.store.sub_district_name ?? "",
      urban_village_id: detailData.store.urban_village_id ?? "",
      urban_village_name: detailData.store.urban_village_name ?? "",
      postal_code_id: detailData.store.postal_code_id ?? "",
      postal_code_name: detailData.store.postal_code_name ?? "",
      npwp: detailData.store.npwp ?? "",
      hamlet: detailData.store.hamlet ?? "",
      neighbourhood: detailData.store.neighbourhood ?? "",
      email: detailData.store.email ?? "",
      alternative_email: detailData.store.alternative_email ?? "",
      fax: detailData.store.fax ?? "",
      company_title_id: detailData.store.company_title_id ?? "",
      company_title_name: detailData.store.company_title_name ?? "",
      additional_data: detailData.store.additional_data ?? [],
      updated_at: detailData.data_management_history.modified_on,
      id: detailData.store.id ?? "",
    };
  }, [detailData]);

  // const formStore = useFormik ....
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialDataStore,

    validationSchema: updateSchema,
    onSubmit,
  });
  const handleChangeSelect = (name, value, option) => {
    if (name === "country_id") {
      setFieldValue("country_id", value);
      setFieldValue("island_id", 0);
      setFieldValue("province_id", 0);
      setFieldValue("district_id", 0);
      setFieldValue("sub_district_id", 0);
      setFieldValue("urban_village_id", 0);
      setFieldValue("postal_code_id", 0);
    } else if (name === "province_id") {
      setFieldValue("province_id", value);
      setFieldValue("district_id", 0);
      setFieldValue("sub_district_id", 0);
      setFieldValue("urban_village_id", 0);
      setFieldValue("postal_code_id", 0);
    } else if (name === "island_id") {
      setFieldValue("island_id", value);
    } else if (name === "district_id") {
      setFieldValue("district_id", value);
      setFieldValue("sub_district_id", 0);
      setFieldValue("urban_village_id", 0);
      setFieldValue("postal_code_id", 0);
    } else if (name === "sub_district_id") {
      setFieldValue("sub_district_id", value);
      setFieldValue("urban_village_id", 0);
      setFieldValue("postal_code_id", 0);
    } else if (name === "urban_village_id") {
      setFieldValue("urban_village_id", value);
      setFieldValue("postal_code_id", 0);
    } else if (name === "postal_code_id") {
      setFieldValue("postal_code_id", value);
    } else if (name === "customer_id") {
      setFieldValue("customer_id", value);
      setFieldValue("customerId", value);
      setIdCustomer(value);
      setFieldValue("customerCode" , option.code);
    } else {
      setFieldValue(name, value);
    }
  };

  const setCustomerDetail = useMemo(() => {
    setFieldValue("customer_code", dataCustomerCode?.customer_code);
    setFieldValue("customer_name", dataCustomerCode?.name);
  }, [dataCustomerCode]);
  // custome drop down search customer
  // Dropdown Custom, jadiin function component
  const dropdownCustom = (menu, search, value) => (
    <>
      <div style={{ padding: "2px 8px" }}>
        <Input
          placeholder="Search"
          size="middle"
          onChange={search}
          allowClear
          defaultValue={value}
          // onKeyDown={(e) => {
          //   _ignoreBackspace(e);
          // }}
        />
      </div>
      <div style={{ padding: 2 }}>{menu}</div>
    </>
  );

  const handleScrollStore = (event) => {
    setPage(page + 1);
  };
  const handleSearchStoreType = (event) => {
    setfilterCustomerName(event.target.value);
  };
  return (
    <Box p={4}>
      <HeadTitle
        title={t("STORE:EDIT_STORE")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("STORE:SUBTITLE_2")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("STORE:TITLE_LIST")}
            </span>{" "}
            - {t("STORE:SUBTITLE_5")}
          </>
        }
      />
      <form onSubmit={handleSubmit}>
        {/* White body */}
        <Box
          backgroundColor="white"
          minHeight="300px"
          padding={4}
          //   justifyContent="space-between"
          display="flex"
          marginTop={4}
          borderRadius="10px"
        >
          {/* Left side */}
          <Box p={1} minWidth="37vw">
            {/* Store ID */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STORE_ID")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.store_id}
                  type="text"
                  width="250px"
                  disabled
                  backgroundColor={GRAY_COLOR}
                  id={ID_STORE_MASTER.EDIT.STORE_ID}
                />
              </Box>
            </Box>
            {/* Customer ID */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_CUSTOMER_ID")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  value={values.customerId}
                  type="text"
                  width="250px"
                  disabled
                  backgroundColor={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Customer Name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_CUSTOMER_NAME")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.CUSTOMER_NAME}
                  style={inputTheme}
                  value={values.customer_id}
                  onPopupScroll={handleScrollStore}
                  name="customer_id"
                  // menggunakan onchange
                  onChange={(value, opt) =>
                    handleChangeSelect("customer_id", value, opt)
                  }
                  dropdownRender={(menu) =>
                    dropdownCustom(menu, handleSearchStoreType)
                  }
                  showSearch={false}
                  allowClear={false}
                >
                  {dataCustomer &&
                    dataCustomer.map((data) => {
                      return (
                        <Select.Option
                          key={data.id}
                          value={data.id}
                          title={data.name}
                          code={data?.customer_code}
                        >
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
                {errors.customer_id && touched.customer_id && (
                  <ValidationError text={errors.customer_id} />
                )}
              </Box>
            </Box>
            {/* Customer Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_CUSTOMER_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  type="text"
                  width="250px"
                  value={values?.customerCode}
                  disabled
                  backgroundColor={GRAY_COLOR}
                />
              </Box>
            </Box>
            {/* Store Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STORE_CODE")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  name="storeCode"
                  type="text"
                  width="250px"
                  height="40px"
                  disabled
                  backgroundColor={GRAY_COLOR}
                  value={values.store_code}
                />
              </Box>
            </Box>
            {/* Store Name */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STORE_NAME")}</Text>
              </Box>
              <Box>
                <Input
                  style={inputTheme}
                  name="store_name"
                  type="text"
                  width="250px"
                  // disabled
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.store_name}
                  id={ID_STORE_MASTER.EDIT.STORE_NAME}
                />
                {errors.store_name && touched.store_name && (
                  <ValidationError text={errors.store_name} />
                )}
              </Box>
            </Box>
            {/* Phone */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_PHONE")}</Text>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row">
                  <Box className="my-select-container-phone">
                    <Select
                      id={ID_STORE_MASTER.EDIT.COUNTRY_CODE}
                      style={{
                        width: "80px",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStyle: "normal",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: 6,
                      }}
                      size="large"
                      value={values.phone_country_code}
                      onChange={(value, opt) =>
                        handleChangeSelect("phone_country_code", value, opt)
                      }
                    >
                      {list_country?.map((value, index) => {
                        return (
                          <Option key={index} value={value.phone_code}>
                            {value.phone_code}
                          </Option>
                        );
                      })}
                    </Select>
                  </Box>
                  <Box>
                    <Input
                      id={ID_STORE_MASTER.EDIT.PHONE}
                      style={{
                        borderTopRightRadius: "6px",
                        borderBottomRightRadius: "6px",
                        width: "170px",
                        fontSize: "16px",
                        fontWeight: "500px",
                        fontStyle: "normal",
                      }}
                      size="large"
                      value={values.phone}
                      onChange={handleChange}
                      name="phone"
                    />
                  </Box>
                </Box>
                {errors.phone && <ValidationError text={errors.phone} />}
              </Box>
            </Box>
            {/* status */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_STATUS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.STATUS}
                  onBlur={handleBlur}
                  onChange={(value, opt) =>
                    handleChangeSelect("status", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px", fontWeight: 500 }}
                  size="large"
                  allowClear="true"
                  value={values.status}
                >
                  {selectStatus?.map((value, index) => {
                    return (
                      <Option key={index} value={value.value}>
                        {t(value.label)}
                      </Option>
                    );
                  })}
                </Select>
                {errors.status && touched.status && (
                  <ValidationError text={errors.status} />
                )}
              </Box>
            </Box>
          </Box>
          {/* Right Side */}
          <Box p={1}>
            {/* Address */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_ADDRESS")}</Text>
              </Box>
              <Box className="my-select-container">
                <Input
                  style={inputTheme}
                  name="address_1"
                  value={values.address_1}
                  onChange={handleChange}
                  type="text"
                  width="250px"
                  id={ID_STORE_MASTER.EDIT.ADDRESS}
                />
                {errors.address_1 && touched.address_1 && (
                  <ValidationError text={errors.address_1} />
                )}
              </Box>
            </Box>
            {/* Country */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_COUNTRY")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.COUNTRY}
                  name="country_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("country_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.country_id === 0 ? "-" : values.country_id}
                >
                  {list_country?.map((value, index) => {
                    return (
                      <Option key={index} value={value.id}>
                        {" "}
                        {value.name}
                      </Option>
                    );
                  })}
                </Select>
              </Box>
            </Box>
            {/* Island */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_ISLAND")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.ISLAND}
                  name="island_id"
                  // placeholder="Select option"
                  onChange={(value, opt) =>
                    handleChangeSelect("island_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.island_id === 0 ? "-" : values.island_id}
                >
                  {list_island
                    ?.filter(
                      (filter) => filter.country_id === values?.country_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Province */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_PROVINCE")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.PROVINCE}
                  name="province_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("province_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.province_id === 0 ? "-" : values.province_id}
                >
                  {list_province
                    ?.filter(
                      (filter) => filter.country_id === values?.country_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* District */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_DISTRICT")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.DISTRICT}
                  name="district_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("district_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={values.district_id === 0 ? "-" : values.district_id}
                >
                  {list_district
                    ?.filter(
                      (filter) => filter.province_id === values?.province_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Sub-District */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_SUB_DISTRICT")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.SUB_DISTRICT}
                  name="sub_district_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("sub_district_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={
                    values.sub_district_id === 0 ? "-" : values.sub_district_id
                  }
                >
                  {list_sub_district
                    ?.filter(
                      (filter) => filter.district_id === values.district_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Urban */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_URBAN_VILLAGE")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.URBAN_VILLAGE}
                  name="urban_village_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("urban_village_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={
                    values.urban_village_id === 0
                      ? "-"
                      : values.urban_village_id
                  }
                >
                  {list_urban_village
                    ?.filter(
                      (filter) =>
                        filter.sub_district_id === values.sub_district_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors.store_id && touched.store_id && (
                  <ValidationError text={errors.store_id} />
                )}
              </Box>
            </Box>
            {/* Postal Code */}
            <Box padding={1} display="flex">
              <Box width="10rem" p={2}>
                <Text>{t("STORE:FIELD_POSTAL_CODE")}</Text>
              </Box>
              <Box className="my-select-container">
                <Select
                  id={ID_STORE_MASTER.EDIT.POSTAL_CODE}
                  name="postal_code_id"
                  onChange={(value, opt) =>
                    handleChangeSelect("postal_code_id", value, opt)
                  }
                  style={{ width: 250, borderRadius: "6px" }}
                  size="large"
                  allowClear="true"
                  value={
                    values.postal_code_id === 0 ? "-" : values.postal_code_id
                  }
                >
                  {list_postal_code
                    ?.filter(
                      (filter) =>
                        filter.urban_village_id === values.urban_village_id
                    )
                    .map((value, index) => {
                      return (
                        <Option key={index} value={value.id}>
                          {" "}
                          {value.code}
                        </Option>
                      );
                    })}
                </Select>
                {errors.postal_code_id && touched.postal_code_id && (
                  <ValidationError text={errors.postal_code_id} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" marginTop={4} float="right" paddingBottom={4}>
          <ButtonCustom
            colorScheme="blue"
            variant="outline"
            marginRight={2}
            width="9.375rem"
            link={ROUTES_USER_BUYER.STORE.LIST}
            text={t("COMMON:BUTTON_CANCEL")}
            id={ID_STORE_MASTER.EDIT.BTN_CANCEL}
          />
          <ButtonPrimary
            text={t("COMMON:BUTTON_SAVE")}
            width="9.375rem"
            type="submit"
            id={ID_STORE_MASTER.EDIT.BTN_SAVE}
          />
        </Box>

        <ModalCustom
          message={response}
          isOpen={modalOpen}
          onOk={err ? modalFailed : modalSuccess}
          icon={err ? <DeleteSuccess /> : <SavedSuccess />}
        />
      </form>
    </Box>
  );
};

export default EditStore;
