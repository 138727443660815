import {
  Box,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonCustom from "../../../../../components/button/ButtonCustom";
import HeadTitle from "../../../../../components/HeadTitle";
import {
  PRIMARY_COLOR,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  LABEL_STATUS_INACTIVE,
  LABEL_STATUS_ACTIVE,
} from "../../../../../constant/propertiesConstant";
import { ROUTES_USER_SELLER } from "../../../../../constant/routeConstant";
import {
  BlueEditIcon,
  RedDeleteIcon,
  DeleteSuccess,
  UnSuccess,
} from "../../../../../components/icons";
import { Redirect, useLocation } from "react-router-dom";
import {
  deleteProductCategory,
  detailProductCategory,
} from "../../../../../services/main/productCategoryReducer";
import BackToList from "../../../../../components/BackToList";
import moment from "moment";
import ModalCustom from "../../../../../components/ModalCustom";
import ModalConfirm from "../../../../../components/ModalConfirm";
import { ID_PRODUCT_CATEGORY } from "../../../../../constant/idConstant";
const DetailProductCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState("");
  const [detailProduct, setDetailProduct] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [message, setMessage] = useState("");

  const renderStatus = (status) => {
    if (status === STATUS_ACTIVE) return LABEL_STATUS_ACTIVE;
    if (status === STATUS_INACTIVE) return LABEL_STATUS_INACTIVE;
    return "";
  };
  // for modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  //is deleted buat ngasih tau udah ke delete atau belum kalo udah dia otomatis refresh
  const [isDeleted, setIsDeleted] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const payload = { updated_at: updatedAt };

  /// ini buat buka modal yang confirm bakal delete atau engga
  const onSubmitDelete = () => {
    setModalConfirmOpen(true);
  };
  // ini buat nge close modal confirm kalo ga jadi delete
  const closeModalConfirm = () => {
    setModalConfirmOpen(false);
  };
  // ini buat buka modal delete yang isi nya data was successfully deleted
  const openModalDelete = () => {
    const object = {
      updated_at: payload,
      productId: Number(location.state.id),
    };
    dispatch(deleteProductCategory(object)).then((resp) => {
      if (resp.payload.ok) {
        setDeleted(true);
        setModalOpen(true);
        setModalConfirmOpen(true);
        setMessage(resp.payload.response.nexsoft.payload.status.message);
      } else {
        setDeleted(false);
        setModalOpen(true);
        setMessage(resp.payload.response.data.nexsoft.payload.status.message);
      }
    });
  };
  // ini buat klik tombol yang di dalem modal delete
  const clickOk = () => {
    setIsDeleted(true);
  };
  const clickOnFailedDelete = () => {
    setIsDeleted(false);
    setModalOpen(false);
    setModalConfirmOpen(false);
  };

  const fetchData = () => {
    setIsLoading(true);
    dispatch(detailProductCategory(location.state.id)).then((response) => {
      if (response.payload.ok) {
        setUpdatedAt(response.payload.response.updated_at);
        setDetailProduct(response.payload.response);
      }
    });
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const distributor = detailProduct?.distributor;

  return isDeleted ? (
    <Redirect to={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST} />
  ) : (
    <Box padding={4}>
      <HeadTitle
        title={t("PRODUCT_CATEGORY:DETAIL_PRODUCT_CATEGORY")}
        subtitle={
          <>
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_1")}
            </span>{" "}
            -{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              {t("PRODUCT_CATEGORY:SUBTITLE_3")}
            </span>{" "}
            <span style={{ color: PRIMARY_COLOR }}>
              - {t("PRODUCT_CATEGORY:TITLE_LIST")}
            </span>{" "}
            - {t("PRODUCT_CATEGORY:SUBTITLE_6")}
          </>
        }
        button={
          <>
            <ButtonCustom
              variant="outline"
              colorScheme="blue"
              marginRight={2}
              width="5rem"
              icon={<BlueEditIcon />}
              id={ID_PRODUCT_CATEGORY.DETAIL.BTN_EDIT}
              text={t("COMMON:BUTTON_EDIT")}
              link={{
                pathname: ROUTES_USER_SELLER.PRODUCT_CATEGORY.EDIT,
                state: {
                  id: location.state.id,
                },
              }}
            />
            <ButtonCustom
              variant="outline"
              colorScheme="red"
              marginRight={2}
              width="5rem"
              icon={<RedDeleteIcon />}
              text={t("COMMON:BUTTON_DELETE")}
              onClick={onSubmitDelete}
              id={ID_PRODUCT_CATEGORY.DETAIL.BTN_DELETE}
            />
          </>
        }
      />
      {/* Back To List , Created By ,dll  */}
      <BackToList
        link={ROUTES_USER_SELLER.PRODUCT_CATEGORY.LIST}
        name={detailProduct?.created_name}
        date={moment(detailProduct?.created_at).format("DD/MM/YYYY")}
        modifiedDate={moment(detailProduct?.updated_at).format("DD/MM/YYYY")}
        modifiedBy={detailProduct?.updated_name}
        id={ID_PRODUCT_CATEGORY.DETAIL.BTN_BACK}
      />
      {/* white box  */}
      <Box
        backgroundColor="white"
        minHeight="300px"
        padding={4}
        justifyContent="space-between"
        display="flex"
        marginTop={2}
        borderRadius="10px"
      >
        {/* left side */}
        <Box padding={1} width="404px">
          {/* Principal Code */}
          <Box padding={1}>
            <Box>{t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.principal_code
                    ? detailProduct?.principal_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Distributor Code */}
          <Box padding={1}>
            <Box>{t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_CODE")}</Box>
            <Box>
              <Text>
                <b>
                  {distributor?.map((value, i, row) => {
                    let result;
                    if (i + 1 === row.length) {
                      result = <>{value.distributor_code}</>;
                    } else {
                      result = (
                        <>
                          {value.distributor_code} {" , "}
                        </>
                      );
                    }
                    return result;
                  })}
                </b>
              </Text>
            </Box>
          </Box>
          {/* Product Category Code*/}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_CODE")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_category_code
                    ? detailProduct?.product_category_code
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* product status */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_STATUS")}</Text>
            </Box>
            <Box>
              <Text>
                <b>{renderStatus(detailProduct?.status)}</b>
              </Text>
            </Box>
          </Box>
        </Box>
        {/* end of left side  */}

        {/* right side */}
        <Box padding={1} marginRight="7.5rem" width="404px">
          {/* principal name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY:FIELD_PRINCIPAL_NAME")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {detailProduct?.principal_name
                    ? detailProduct?.principal_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
          {/* distributor name  */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY:FIELD_DISTRIBUTOR_NAME")}</Text>
            </Box>
            <Box width="12rem">
              <Text>
                <b>
                  {distributor?.map((value, i, row) => {
                    let result;
                    if (i + 1 === row.length) {
                      result = <>{value.distributor_name}</>;
                    } else {
                      result = (
                        <>
                          {value.distributor_name} {" , "}
                        </>
                      );
                    }
                    return result;
                  })}
                </b>
              </Text>
            </Box>
          </Box>
          {/* product category name */}
          <Box padding={1}>
            <Box width="10rem">
              <Text>{t("PRODUCT_CATEGORY:FIELD_PRODUCT_CATEGORY_NAME")}</Text>
            </Box>
            <Box>
              <Text>
                <b>
                  {detailProduct?.product_category_name
                    ? detailProduct?.product_category_name
                    : "-"}
                </b>
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalCustom
        icon={deleted ? <DeleteSuccess /> : <UnSuccess />}
        message={message}
        onOk={deleted ? clickOk : clickOnFailedDelete}
        isOpen={modalOpen}
      />
      <ModalConfirm
        messageModal={t("COMMON:DELETE_TITLE")}
        messageConfirm={t("COMMON:DELETE_CONFIRM")}
        onClose={closeModalConfirm}
        onOk={openModalDelete}
        isOpen={modalConfirmOpen}
        idYes={ID_PRODUCT_CATEGORY.DETAIL.BTN_YES}
        idNo={ID_PRODUCT_CATEGORY.DETAIL.BTN_NO}
      />
    </Box>
  );
};

export default DetailProductCategory;
